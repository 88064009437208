import Api from 'src/services/api';

export default class UploadService {
  static DEFAULT_URL = 'imports';

  static async uploadBatch(data: any) {
    const response = await Api.post(`${this.DEFAULT_URL}/spreadsheet`, data);
    return response;
  }

  static async getErros(jobId: number) {
    const response = await Api.get(`${this.DEFAULT_URL}/errors/${jobId}`);
    return response;
  }

  static sendLogo(body: any) {
    return Api.post('companies/logo', body);
  }
}
