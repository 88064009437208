const numberToBin = (num: number) => num.toString(2);
const invertString = (binary: string) => binary.split('').reverse().join('');
const getIndexs = (invertedBinary: string) => {
  const week = [];
  for (let i = 0; i < invertedBinary.split('').length; i += 1) {
    const value = invertedBinary.split('')[i];
    if (Number(value) === 1) {
      week.push(i);
    }
  }
  return week;
};
const getBinary = (week: Array<number>) => {
  const binaryArr = [0, 0, 0, 0, 0, 0, 0];
  week.forEach((value) => {
    binaryArr[value] = 1;
  });
  return binaryArr.reverse().join('');
};

export const numberToWeek = (
  weekNum: number,
) => {
  let binary: string = numberToBin(weekNum);
  if (binary.length < 7) {
    const numZero = 7 - binary.length;
    binary = '0'.repeat(numZero) + binary;
  }
  const invertedBinary = invertString(binary);
  const week = getIndexs(invertedBinary);
  return week;
};

export const weekToNumber = (
  week: Array<number>,
) => {
  const binary = getBinary(week);
  const number = parseInt(binary, 2);
  return number;
};
