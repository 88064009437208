export const weekDaysReq = [
  'MO', 'TU', 'WE', 'TH',
  'FR', 'SA', 'SU',
];

export const DayAdapter: any = {
  MO: 'MONDAY',
  TU: 'TUESDAY',
  WE: 'WEDNESDAY',
  TH: 'THURSDAY',
  FR: 'FRIDAY',
  SA: 'SATURDAY',
  SU: 'SUNDAY',
};
