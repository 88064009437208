// eslint-disable-next-line max-len
/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,react/require-default-props */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { State, useState as HookUseState, none } from '@hookstate/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import {
  ButtonEnd,
  HourPickerContainer,
  PositionsAvailabilityContainer,
  SingleDateContainer, WeekDay,
} from './style';
import SelectWeekDay from '../../../../components/SelectWeekDay';
import SingleDatePicker from '../../../../components/SingleDatePicker';
import Icons from '../../../../assets/icons';
import Input from '../../../../components/Input';

const OnlyOneAvailabilities = 1;
function PositionsAvailability(
  {
    fieldState,
    totalAvailabilities,
    availabilityIndex,
    onClick,
    firstSelectedDayIndex,
    placementId,
  }: {
        fieldState: State<any>,
        totalAvailabilities: number,
        availabilityIndex: number,
        firstSelectedDayIndex: number,
        onClick: (index: number) => void,
        placementId?: number,
    },
) {
  const { t } = useTranslation('pages');
  const daysTranslation = useTranslation('generic');
  const localState: any = HookUseState(fieldState);

  const weekDays = [
    daysTranslation.t('days.monday'),
    daysTranslation.t('days.tuesday'),
    daysTranslation.t('days.wednesday'),
    daysTranslation.t('days.thusday'),
    daysTranslation.t('days.friday'),
    daysTranslation.t('days.saturday'),
    daysTranslation.t('days.sunday'),
  ];

  function isDeletePeriodAvailable() {
    if (localState.id.get() && placementId) {
      return false;
    }

    return totalAvailabilities !== OnlyOneAvailabilities;
  }
  return (
    <div className="d-flex w-100 flex-column">
      <div className="mb-4">
        <div>
          <div className="row">
            <SingleDateContainer className="col-md-4 col-sm-12">
              <span>{t('AddLocation.tabs.AddPosition.start_date')}</span>
              <SingleDatePicker
                minDate={new Date()}
                disabled={(availabilityIndex !== totalAvailabilities
                    && totalAvailabilities !== OnlyOneAvailabilities) || localState.hasShift.get()
                  || (localState.endDate.get() && new Date() > new Date(
                    localState.endDate?.get()?.slice(0, 4),
                    localState.endDate?.get()?.slice(5, 7),
                    localState.endDate?.get()?.slice(8),
                  ))}
                initialValue={localState.startDate.get()}
                onChange={(value: number | Date) => {
                  localState.startDate.set(format(value, 'dd/MM/yyyy'));
                }}
              />
            </SingleDateContainer>
            <SingleDateContainer className="col-md-4 col-sm-12">
              <span>{t('AddLocation.tabs.AddPosition.final_date')}</span>
              {!localState.endDate.get() ? (
                <ButtonEnd
                  type="button"
                  onClick={() => localState.endDate.set(localState.startDate.get())}
                >
                  {t('AddLocation.tabs.AddPosition.add_final_date')}
                </ButtonEnd>
              ) : (
                <div style={{ display: 'flex' }}>
                  <SingleDatePicker
                    minDate={new Date()}
                    disabled={(availabilityIndex !== totalAvailabilities
                        && totalAvailabilities !== OnlyOneAvailabilities)
                      || new Date() > new Date(
                        localState.endDate?.get()?.slice(0, 4),
                        localState.endDate?.get()?.slice(5, 7),
                        localState.endDate?.get()?.slice(8),
                      )}
                    initialValue={localState.endDate.get()}
                    onChange={(value: number | Date) => {
                      localState.endDate.set(format(value, 'dd/MM/yyyy'));
                    }}
                  />
                  {((availabilityIndex === totalAvailabilities
                      && totalAvailabilities !== OnlyOneAvailabilities)
                    || (new Date() > new Date(
                      localState.endDate?.get()?.slice(0, 4),
                      localState.endDate?.get()?.slice(5, 7),
                      localState.endDate?.get()?.slice(8),
                    )) || totalAvailabilities === 1) && (
                    <img
                      src={Icons.close}
                      alt=""
                      onClick={() => {
                        localState.endDate.set(null);
                      }}
                    />
                  )}
                </div>
              )}
            </SingleDateContainer>
            <div className="col-md-2 col-sm-12">
              <SelectWeekDay
                disabled={localState.hasShift.get()}
                onChange={(days) => {
                  localState.selectedDays.set(none);
                  localState.set((prev: any) => ({ ...prev, selectedDays: days }));
                }}
                selectedDaysDefault={Object.values(localState.selectedDays.get()) as any}
                label={t('AddLocation.tabs.AddPosition.week_days')}
                size="small"
              />
            </div>
          </div>
          <PositionsAvailabilityContainer>
            <span>{t('AddLocation.tabs.AddPosition.position_hours')}</span>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
            >
              <FormControlLabel
                disabled={localState.hasShift.get()}
                control={(
                  <Checkbox
                    name="checkedA"
                    color="primary"
                    checked={localState.equalDivided.get()}
                  />
)}
                label={t('AddLocation.tabs.AddPosition.equal_to_all')}
                onChange={() => {
                  localState.equalDivided.set(!localState.equalDivided.get());
                }}
              />
            </div>
          </PositionsAvailabilityContainer>
        </div>
        {
              isDeletePeriodAvailable() && (
              <div
                onClick={() => {
                  onClick(availabilityIndex);
                }}
                className="my-3"
              >
                <img src={Icons.Trash} alt="" />
                <span>{t('AddLocation.tabs.AddPosition.delete')}</span>
              </div>
              )
          }
      </div>
      {localState.equalDivided.get()
            && localState.selectedDays.get().length > 0 && (
              <>
                <HourPickerContainer showDays={localState.equalDivided.get()} className="row">
                  {!localState.equalDivided.get() && <WeekDay />}
                  <div className="col-4">
                    <Input
                      styledLabel={{ display: 'block', marginBottom: '.5rem' }}
                      label={t('AddLocation.tabs.AddPosition.start')}
                      style={{ maxWidth: 160 }}
                      disabled={localState.schedule[firstSelectedDayIndex].get().fullTime
                        || localState.hasShift.get()}
                      mask="99:99"
                      placeholder="00:00"
                      defaultValue={localState.schedule[firstSelectedDayIndex].get().start}
                      onChange={(value) => {
                        localState.schedule[firstSelectedDayIndex].start.set(value.target.value);
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <Input
                      styledLabel={{ display: 'block', marginBottom: '.5rem' }}
                      label={t('AddLocation.tabs.AddPosition.end')}
                      style={{ maxWidth: 160 }}
                      disabled={localState.schedule[firstSelectedDayIndex].get().fullTime
                        || localState.hasShift.get()}
                      mask="99:99"
                      placeholder="00:00"
                      defaultValue={localState.schedule[firstSelectedDayIndex].get().end}
                      onChange={(value) => {
                        localState.schedule[firstSelectedDayIndex].end.set(value.target.value);
                      }}
                    />
                  </div>
                  <div className="w-auto" />
                </HourPickerContainer>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="checkedA"
                      color="primary"
                      checked={localState.schedule[firstSelectedDayIndex].get().fullTime}
                    />
                  )}
                  label={t('AddLocation.tabs.AddPosition.full_time')}
                  onChange={() => {
                    if (!localState.schedule[firstSelectedDayIndex].get().fullTime) {
                      localState.schedule[firstSelectedDayIndex].set({
                        id: localState.schedule[firstSelectedDayIndex].get()?.id,
                        start: '00:00:00',
                        end: '00:00:00',
                        fullTime: true,
                      });
                    } else {
                      localState.schedule[firstSelectedDayIndex].fullTime.set(
                        !localState.schedule[firstSelectedDayIndex].get().fullTime,
                      );
                    }
                  }}
                />
              </>
      )}
      {!localState.equalDivided.get()
            && localState.selectedDays.get().length > 0 && (
        [0, 1, 2, 3, 4, 5, 6].map((item, index) => {
          if (localState.selectedDays.get().includes(item)) {
            return (
              <HourPickerContainer multiple className="d-flex">
                <WeekDay className="m-auto me-2 ms-0">
                  {weekDays[item].substring(0, 3).toUpperCase()}
                </WeekDay>
                <div>
                  <Input
                    styledLabel={index === 0 && { display: 'block', marginBottom: '.5rem' }}
                    label={index === 0 && t('AddLocation.tabs.AddPosition.start')}
                    style={{ maxWidth: 75 }}
                    disabled={localState.schedule[index].get().fullTime
                    || localState.hasShift.get()}
                    mask="99:99"
                    placeholder="00:00"
                    defaultValue={localState.schedule[index].get().start}
                    onChange={(value) => {
                      localState.schedule[index].set({
                        id: localState.schedule[index].get().id,
                        start: value.target.value,
                        end: localState.schedule[index].get().end,
                        fullTime: localState.schedule[index].get().fullTime,
                      });
                    }}
                  />
                </div>
                <Input
                  styledLabel={index === 0 && { display: 'block', marginBottom: '.5rem' }}
                  label={index === 0 && t('AddLocation.tabs.AddPosition.end')}
                  disabled={localState.schedule[index].get().fullTime
                    || localState.hasShift.get()}
                  mask="99:99"
                  placeholder="00:00"
                  defaultValue={localState.schedule[index]?.get()?.end}
                  style={{ maxWidth: 75 }}
                  onChange={(value) => {
                    localState.schedule[index].set({
                      id: localState.schedule[index].get().id,
                      start: localState.schedule[index].get().start,
                      end: value.target.value,
                      fullTime: localState.schedule[index].get().fullTime,
                    });
                  }}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="checkedA"
                      color="primary"
                      checked={localState.schedule[index].get().fullTime}
                    />
                  )}
                  label={t('AddLocation.tabs.AddPosition.full_time')}
                  onChange={() => {
                    if (!localState.schedule[index].get().fullTime) {
                      localState.schedule[index].set({
                        id: localState.schedule[index].get()?.id,
                        start: '00:00:00',
                        end: '00:00:00',
                      });
                    }
                    localState.schedule[index].fullTime.set(
                      !localState.schedule[index].get().fullTime,
                    );
                  }}
                />
              </HourPickerContainer>
            );
          }
          return null;
        })
      )}
    </div>
  );
}

export default PositionsAvailability;
