import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import icons from 'src/assets/icons';
import Team from 'src/contracts/models/Team';
import {
  EmployeesCounter,
  SearchInputBox,
  SearchInput,
  Space,
  SearchIcon,
  NoneSelected,
} from './style';
import SideBar from '../SideBar';
import TeamList from './TeamList';
import { EmployeeWithCount } from './EmployeeCard';
import { homeContext } from '../../pages/Home/Context';
import Loader from '../Loader';

interface Props {
  apiTeams: Team[];
  isOpen: boolean;
  isLoading?: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTeams: any[];
  setIsOpenEmployeeEdit:
    | React.Dispatch<React.SetStateAction<boolean>>
    | undefined;
}

const TeamSideBar: React.FC<Props> = ({
  apiTeams,
  isOpen,
  setIsOpen,
  selectedTeams,
  setIsOpenEmployeeEdit,
  isLoading,
}) => {
  const searchInput: any = useRef();
  const { placement, sideBarTeams, setSideBarTeams } = useContext(homeContext);
  const [sideBarData, setSideBarData] = useState<Team[]>([]);
  const [teams, setTeams] = useState<Team[]>([]);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const { t } = useTranslation('pages');

  const saveData = useCallback(() => {
    if (Object.values(apiTeams).length > 0) {
      setSideBarData(Object.values(apiTeams));
      setTeams(apiTeams);
      setTotalEmployees(() => {
        let total = 0;
        for (let i = 0; i < Object.values(apiTeams).length; i += 1) {
          total += Object.values(apiTeams)[i].employees!.length;
        }
        return total;
      });
    } else {
      setTotalEmployees(0);
    }
  }, [apiTeams]);

  const handleInputFocus = async () => {
    await setIsOpen(true);
    searchInput.current.focus();
  };

  const handleChangeSearch = async (text: string) => {
    if (text.length > 0) {
      const newTeamList: Team[] = [];
      let total = 0;
      for (let i = 0; i < Object.values(teams).length; i += 1) {
        newTeamList[i] = {
          id: sideBarData[i]?.id,
          employees: [],
          name: sideBarData[i]?.name,
          maxReservationPerEmployee:
            sideBarData[i]?.maxReservationPerEmployee || 0,
        };
        newTeamList[i].employees = sideBarData[i].employees?.filter(
          (value) => value.name.toUpperCase().includes(text.toUpperCase())
            || value?.email?.toUpperCase().includes(text.toUpperCase())
            || String(value.id) === text,
        );
        total += newTeamList[i].employees!.length;
      }
      setTeams(newTeamList);
      setTotalEmployees(total);
    } else {
      setTeams(Object.values(apiTeams));
      setTotalEmployees(() => {
        let total = 0;
        for (let i = 0; i < sideBarData.length; i += 1) {
          total += sideBarData[i].employees!.length;
        }
        return total;
      });
    }
  };

  useEffect(() => {
    saveData();
  }, [saveData]);

  useEffect(() => {
    setTeams(Object.values(apiTeams));
  }, [apiTeams]);

  useEffect(() => {
    if (!selectedTeams.length) {
      setTotalEmployees(0);
    }
    setSideBarTeams?.(
      teams.length && selectedTeams.length
        ? teams.map((team) => {
          if (!selectedTeams) {
            return [];
          }
          if (!placement?.shifts) {
            return {
              ...team,
              employees: team.employees?.map((employee) => ({
                ...employee,
                count: 0,
              })) as any,
            } as any;
          }
          const employeesArray: number[] = Object.values(
            placement?.shifts.dates,
          ).reduce((previous: any[], current: any) => {
            if (Array.isArray(current)) return previous;
            const shiftsArray = current && current.shifts ? Object.values(current.shifts) : [];
            const shiftEmployees = shiftsArray.length > 0
              ? shiftsArray.map((shift: any) => Object.keys(shift.allocated_employees_this_shift))
              : [];
            return [...previous, ...shiftEmployees].flat();
          }, []);
          return {
            ...team,
            employees: team.employees?.map(
              (employee) => ({
                ...employee,
                count: employeesArray.filter((id) => +id === employee.id)
                  .length,
              } as EmployeeWithCount),
            ),
          };
        })
        : [],
    );
  }, [teams, placement, selectedTeams]);

  return (
    <SideBar
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    >
      <>
        {isOpen && (
          <EmployeesCounter>
            {`${t('Home.sideBar.header')} (${totalEmployees})`}
          </EmployeesCounter>
        )}
        <Space />
        <Space />
        <SearchInputBox open={isOpen}>
          {isOpen && (
            <SearchInput
              autoComplete="off"
              className="form-control"
              placeholder={t('Home.sideBar.search')}
              ref={searchInput}
              id="search"
              onChange={(e) => handleChangeSearch(e.target.value)}
              onFocus={() => setIsOpen(true)}
            />
          )}
          <SearchIcon src={icons.SearchIcon} onClick={handleInputFocus} />
        </SearchInputBox>
        <Space />
        <Space />
        {(!selectedTeams || selectedTeams?.length === 0) && (
          <NoneSelected>{t('Home.sideBar.empty')}</NoneSelected>
        )}
        <TeamList
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          teams={sideBarTeams}
          setIsOpenEmployeeEdit={setIsOpenEmployeeEdit}
        />
        {isLoading && <Loader />}
      </>
    </SideBar>
  );
};

export default TeamSideBar;
