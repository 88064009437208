/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable camelcase */
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import Option from 'src/contracts/models/Option';
import PlacementService from 'src/services/PlacementService';
import { Component, Selector, DisabledButton } from './style';
import icons from '../../assets/icons';

interface SpaceSelectorProps {
  onChange(space: Option[]): void;
  value: any;
  locationId: number;
}

const PlacementSelector: React.FC<SpaceSelectorProps> = ({
  onChange,
  value,
  locationId,
}) => {
  const { t } = useTranslation('components');

  const loadOptions = useCallback(
    async (searchQuery: string, loadedOptions: any, { page }: any) => {
      const response = await PlacementService.getPlacementsFromEmployee(
        10,
        page,
        searchQuery,
        locationId,
      );

      return {
        options: response.data.data,
        hasMore: response.data.last_page > page,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    },
    [locationId],
  );

  return (
    <Component id="placement-selector">
      <Selector
        closeMenuOnSelect
        placeholder={t('PlacementSelector.placeholder')}
        value={value || ''}
        loadOptions={loadOptions}
        getOptionValue={(option: any) => option.name}
        getOptionLabel={(option: any) => option.name}
        onChange={onChange}
        isSearchable
        debounceTimeout={500}
        isClearable
        isDisabled={!locationId}
        additional={{
          page: 1,
        }}
        cacheUniqs={[value]}
      />
      {!locationId && (
        <>
          <DisabledButton type="button" id="PlacementSelectorInfo">
            <img src={icons.Info} alt="" />
          </DisabledButton>
          <UncontrolledPopover
            flip
            target="PlacementSelectorInfo"
            trigger="hover"
          >
            <PopoverBody>{t('PlacementSelector.popover.disabled')}</PopoverBody>
          </UncontrolledPopover>
        </>
      )}
    </Component>
  );
};

export default PlacementSelector;
