import React, { useContext, useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import { createPopper } from '@popperjs/core';
import Icons from '../../../../assets/icons';
import { AddLocationContext } from '../../Context';
import ButtonLoader from '../../../../components/ButtonLoader';
import {
  Screen, Body, Forms, FormChild, InputLabel, Input,
  Buttons, AddressOptions, Option, GoogleContainer, CustomSpace,
} from './style';
import ConfirmExitModal from '../../../../components/ConfirmExitModal';
import LocationService from '../../../../services/LocationService';
import GeoLocationService from '../../../../services/GeoLocationService';

const LOCATION_NAME_MAX_CHARS = 45;

interface LocationProps {
  nextStep?: () => void
}

const LocationInformation: React.FC<LocationProps> = ({ nextStep }) => {
  const {
    locationName, address, addressComplement,
    setLocationName, setAddress, setAddressComplement,
    invalidLocationName, invalidAddress, setLocationId,
    setInvalidLocationName, setInvalidAddress, locationId,
    geolocationOptions, setGeoLocationOptions, selectedGeoLocation,
    setSelectedGeoLocation, loadLocationInformation,
  } = useContext(AddLocationContext);

  const [exitModal, setExitModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [loadingGeoLocationOptions, setLoadingGeoLocationOptions] = useState(true);

  const [showLocationOptions, setShowLocationOptions] = useState(false);
  const { t } = useTranslation('pages');
  const { addToast } = useToast();
  const component = document.getElementById('address_input')!;
  const invisibleComponent = document.getElementById('address_input_body')!;
  createPopper(component, invisibleComponent, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 1],
        },
      },
    ],
  });

  useEffect(() => {
    loadLocationInformation?.();
  }, []);
  const loadGeoLocationOptions = () => {
    GeoLocationService.get(address).then((response) => {
      setGeoLocationOptions?.(response.data.results);
      setLoadingGeoLocationOptions(false);
    }).catch(() => {
      setGeoLocationOptions?.([]);
      setLoadingGeoLocationOptions(false);
    });
  };

  const resetGeoLocation = () => {
    setSelectedGeoLocation?.({
      selected: false,
      placeId: '',
      geometry: { location: { lat: '', lng: '' } },
    });
  };
  const handleSaveInformation = () => {
    let valid = true;
    setIsloading(true);

    if (locationName.length >= LOCATION_NAME_MAX_CHARS) {
      setInvalidLocationName?.(true);
      addToast({
        description: t('Location.general_info.errors.location_max_chars'),
        type: 'error',
      });
      valid = false;
    }

    if (locationName.length === 0) {
      setInvalidLocationName?.(true);
      addToast({
        description: t('Location.general_info.errors.location'),
        type: 'error',
      });
      valid = false;
    }

    if (address.length === 0) {
      setInvalidAddress?.(true);
      addToast({
        description: t('Location.general_info.errors.address'),
        type: 'error',
      });
      valid = false;
    }

    if (!selectedGeoLocation.selected) {
      setInvalidAddress?.(true);
      addToast({
        description: t('Location.general_info.errors.address_selection'),
        type: 'error',
      });
      valid = false;
    }

    if (!valid) {
      setIsloading(false);
    }

    if (valid && !locationId) {
      const payload = {
        name: locationName,
        address,
        complement: addressComplement,
        latitude: String(selectedGeoLocation.geometry.location.lat),
        longitude: String(selectedGeoLocation.geometry.location.lng),
        radius: '5',
        google_place_id: String(selectedGeoLocation.placeId),
      };

      LocationService.create(payload).then((response) => {
        addToast({
          description: t('Location.general_info.success'),
          type: 'success',
        });
        setIsloading(false);
        setLocationId?.(response.data.id);
        if (nextStep) {
          nextStep();
        }
      }).catch(() => {
        addToast({
          description: t('Location.general_info.errors.creation_error'),
          type: 'error',
        });
        setIsloading(false);
      });
    } else if (valid) {
      const payload = {
        id: locationId,
        name: locationName,
        address,
        complement: addressComplement,
        latitude: String(selectedGeoLocation.geometry.location.lat),
        longitude: String(selectedGeoLocation.geometry.location.lng),
        google_place_id: String(selectedGeoLocation.placeId),
      };
      LocationService.editGeneralInformation(payload).then(() => {
        addToast({
          description: t('Location.general_info.edit_success'),
          type: 'success',
        });
        setIsloading(false);
        if (nextStep) {
          nextStep();
        }
      }).catch(() => {
        addToast({
          description: t('Location.general_info.errors.edit_error'),
          type: 'error',
        });
        setIsloading(false);
      });
    }
  };

  useEffect(() => {
    if (address?.length > 0) {
      setLoadingGeoLocationOptions(true);
      const delayDebounceFn = setTimeout(() => {
        loadGeoLocationOptions();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    setGeoLocationOptions?.([]);
    return () => false;
  }, [address]);

  return (
    <Screen>
      <ConfirmExitModal
        state={exitModal}
        setState={setExitModal}
        route="/spaces"
      />
      <Body>
        <Forms>
          <FormChild>
            <InputLabel>{t('Location.general_info.location_name')}</InputLabel>
            <Input
              autoComplete='"off'
              placeholder={t('Location.general_info.location_name_placeholder')}
              required={invalidLocationName}
              onFocus={() => setShowLocationOptions(false)}
              strong
              maxwidth={false}
              value={locationName}
              type="text"
              onChange={(e) => {
                if (invalidLocationName) setInvalidLocationName?.(false);
                setLocationName?.(e.target.value);
              }}
            />
          </FormChild>
          <CustomSpace size={22} />
          <FormChild>
            <InputLabel>{t('Location.general_info.address')}</InputLabel>
            <Input
              autoComplete='"off'
              onFocus={() => {
                setLoadingGeoLocationOptions(true);
                setShowLocationOptions(true);
                loadGeoLocationOptions();
              }}
              id="address_input"
              type="text"
              required={invalidAddress}
              strong={false}
              maxwidth
              value={address}
              onChange={(e) => {
                if (selectedGeoLocation.selected) resetGeoLocation();
                if (invalidAddress) setInvalidAddress?.(false);
                setAddress?.(e.target.value);
                setShowLocationOptions(true);
              }}
            />
            <GoogleContainer><img src={Icons.PoweredByGoogle} alt="google" /></GoogleContainer>
            <AddressOptions id="address_input_body" open={showLocationOptions}>
              {loadingGeoLocationOptions && <Spinner color="blue" size="sm" animation="border" />}
              {!loadingGeoLocationOptions && geolocationOptions && geolocationOptions.length === 0
                && <div>{t('Location.general_info.errors.none_address_found')}</div>}
              {!loadingGeoLocationOptions && geolocationOptions && geolocationOptions.length !== 0
                && geolocationOptions.map((result: any, index: number) => (
                  <Option
                    key={index.toString()}
                    onClick={() => {
                      setShowLocationOptions(false);
                      setSelectedGeoLocation?.({
                        geometry: { location: undefined }, place_id: '', ...{ selected: true }, ...result,
                      });
                      if (setAddress) {
                        setAddress(result.formatted_address);
                      }
                      setGeoLocationOptions?.(null);
                    }}
                  >
                    {result.formatted_address}
                  </Option>
                ))}
            </AddressOptions>
          </FormChild>
          <FormChild>
            <InputLabel>{t('Location.general_info.address_complement')}</InputLabel>
            <Input
              autoComplete="off"
              className="form-control"
              onFocus={() => setShowLocationOptions(false)}
              strong={false}
              maxwidth={false}
              value={addressComplement}
              type="text"
              onChange={(e) => {
                setAddressComplement?.(e.target.value);
              }}
            />
          </FormChild>
        </Forms>
        <Buttons>
          <ButtonLoader
            uppercase={false}
            label={t('Location.general_info.salve')}
            color="primary"
            onClick={() => handleSaveInformation()}
            isLoading={isLoading}
          />
        </Buttons>
      </Body>
    </Screen>
  );
};

export default LocationInformation;
