/* eslint-disable camelcase */
import Api from 'src/services/api';
import keysToSnake from '../utils/keysToSnakeCase';

export interface Role {
  name: string
  type_role: string
  code: number
}
export interface GetAllRoleData {
  [key: string]: Role
}

export default class EmployeeService {
  static DEFAULT_URL = 'employees';

  static async getAll() {
    const response = await Api.get('employees');
    return response;
  }

  static async getById(idEmployee: number) {
    const response = await Api.get(`employees/${idEmployee}`);
    return response;
  }

  static getUsersAll(
    {
      page,
      perPage,
      searchName,
      teamId,
      status,
      role,
      typeRole,
      access_filter,
    }: {
      page: number,
      perPage: number,
      searchName: string,
      teamId?: number, status?: boolean | undefined,
      role?: number
      typeRole?: string,
      access_filter?: string,
    },
  ) {
    return Api.get('employees', {
      params: {
        page,
        perPage,
        name: searchName,
        team_id: teamId || undefined,
        status,
        role,
        type_role: typeRole,
        access_filter,
      },
    });
  }

  static async getAllRole(
  ) {
    const response = await Api.get<GetAllRoleData>('employees/roles/all');

    return response;
  }

  static async deleteTeamsEmployee(payload: {
    employeeId: number,
    roleId: number,
    teamId: number,
    type: string,
  }) {
    const response = await Api.request({
      method: 'POST',
      url: `${this.DEFAULT_URL}/associate/team/roles?`,
      params: keysToSnake(payload),
    });
    return response;
  }

  static async createEmployee(employee: FormData) {
    const response = await Api.post('employees', employee, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }

  static async editEmployee(idEmployee: number, employee: FormData) {
    const response = await Api.post(`employees/${idEmployee}`, employee, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response;
  }

  static async changePasswordEditUser(
    data: {
      current_password: string;
      new_password: string;
      confirmation_password: string;
      employee_id: string;
    },
  ) {
    const response = await Api.post('employees/update/password', data);
    return response;
  }

  static async inviteEmployee(data : { employeeId: number }) {
    const response = await Api.post(`employees/${data.employeeId}/emails/welcome`);
    return response;
  }

  static async configPlacementsRule(data: any) {
    const response = await Api.post('employees/configuration/placements', { configurations: data });
    return response;
  }

  static async getConfigPlacementsRules(data : {idEmployee: number; idTeam: number}) {
    const response = await Api.get(`employees/configuration/placements?team_id=${data.idTeam}&employee_id=${data.idEmployee}`);
    return response;
  }

  static async associateEmployeeTeam(teamId: number, employeeId: number) {
    const response = await Api.post('employees/associate/team', {
      team_id: teamId,
      employee_id: employeeId,
    });
    return response;
  }

  static async associateEmployeesTeam(teamId: number, employeeIds: {id: number}[]) {
    const response = await Api.post('employees/multiple/associate/team', {
      team_id: teamId,
      employees: employeeIds,
    });
    return response;
  }

  static async deleteEmployee(idEmployee: number) {
    const response = await Api.delete('employees', { data: { employee_id: idEmployee } });
    return response;
  }

  static async deleteSelectedEmployees(employeesId: string) {
    const response = await Api.delete('employees/multiples', { data: { employees_id: employeesId } });
    return response;
  }

  static async restoreEmployee(idEmployee: number) {
    const response = await Api.post(`${this.DEFAULT_URL}/${idEmployee}/restore`);
    return response;
  }

  static async restoreSelectedEmployees(employeesId: string) {
    const response = await Api.post('employees/multiples/restore', { employees_id: employeesId });
    return response;
  }

  static async addSingleRoleTeam(data: any) {
    const response = await Api.post('/employees/associate/team/roles', data);
    return response;
  }

  static async listPermissonsTeam(
    idEmployee: number,
    page: number,
    perPage: number,
    name: string,
  ) {
    const response = await Api.get(
      `/employees/permissions/all/team/${idEmployee}`,
      {
        params: {
          page,
          per_page: perPage,
          name,
        },
      },
    );

    return response;
  }

  static async getEmployeeConfigurations(employeeId: number) {
    return Api.get(`/employees/${employeeId}/configurations`);
  }

  static async changeEmployeeConfigurations(
    { employeeId, isAdmin, isLoginSSO }:{employeeId: number, isAdmin: boolean, isLoginSSO: boolean},
  ) {
    return Api.post(`/employees/${employeeId}/configurations`, {
      is_admin: isAdmin,
      is_login_sso: isLoginSSO,
    });
  }

  static async updateEmployeeGuideTutorial(step: string, nextStep: string | null) {
    const response = await Api.put('/employees/update/guide/tutorial', { step, next_step: nextStep });
    return response;
  }
}
