import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@material-ui/core';
import {
  UserTableHeaderRow, UserTableHeaderRowWithDropdown, FilterOption,
} from './components';
import useSelectedUsers from '../../hooks/useSelectedUsers';
import Employee from '../../../../../../contracts/models/Employee';

export interface RoleFilterOption extends FilterOption<string>{}

export interface UsersFilterOption extends FilterOption<boolean> {}

const INACTIVE_USERS_FILTER_OPTIONS: UsersFilterOption[] = [
  { label: 'Ativos', value: false },
  { label: 'Inativos', value: true },
];

const EmptyContent = () => <div />;

export interface UserTableHeaderProps {
  selectedUserRoleNameFilter: string | undefined,
  isOpenUserRoleDropdown: boolean,
  toggleUserRoleDropdown: () => void,
  rolesFilterOptions: RoleFilterOption[],
  isFilteringByInactiveRole: boolean,
  isOpen: boolean,
  handleChangeUserRoleNameFilter: (name: string) => void,
  handleChangeIsUserInactiveFilter: (isInactive: boolean) => void,
  toggleDropdownUsersStatus: () => void,
  handleAddAllUsers: () => void
  users: Employee[];
}

const UserTableHeader = ({
  selectedUserRoleNameFilter,
  handleChangeUserRoleNameFilter,
  isOpenUserRoleDropdown,
  toggleUserRoleDropdown,
  rolesFilterOptions,
  isFilteringByInactiveRole,
  handleChangeIsUserInactiveFilter,
  isOpen,
  toggleDropdownUsersStatus,
  handleAddAllUsers,
  users = [],
}: UserTableHeaderProps) => {
  const { t } = useTranslation('pages');
  const [isAddAllUsersCheckboxChecked, setIsAddAllUsersCheckboxChecked] = useState(false);

  const [{ selectedUsers }] = useSelectedUsers();

  useEffect(() => {
    let isSelected = true;
    users?.forEach((user) => {
      if (!selectedUsers.includes(user.id)) {
        isSelected = false;
      }
    });
    setIsAddAllUsersCheckboxChecked(isSelected);
  }, [users, selectedUsers]);

  return [
    [
      <Checkbox
        name="checked-all-users"
        color="primary"
        style={{ paddingRight: 0 }}
        onChange={handleAddAllUsers}
        checked={isAddAllUsersCheckboxChecked}
        className="ps-0"
      />,
      <UserTableHeaderRow title={t('Administration.tabs.user.table_name')} />,
      <UserTableHeaderRow title={t('Administration.tabs.user.table_email')} />,
      <UserTableHeaderRowWithDropdown<string>
        actualSelectedDropdownValue={selectedUserRoleNameFilter}
        dropdownTitle={t('Administration.tabs.user.table_role')}
        handleChangeFilterOption={handleChangeUserRoleNameFilter}
        isOpen={isOpenUserRoleDropdown}
        toggleDropdown={toggleUserRoleDropdown}
        options={rolesFilterOptions}
      />,

      <UserTableHeaderRowWithDropdown<boolean>
        actualSelectedDropdownValue={isFilteringByInactiveRole}
        dropdownTitle={t('Administration.tabs.user.is_active_label')}
        handleChangeFilterOption={handleChangeIsUserInactiveFilter}
        isOpen={isOpen}
        toggleDropdown={toggleDropdownUsersStatus}
        options={INACTIVE_USERS_FILTER_OPTIONS}
      />,
      <EmptyContent />,
    ],
  ];
};

export default UserTableHeader;
