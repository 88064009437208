import styled from 'styled-components';
import { lighten } from 'polished';

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #0002;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 100px;
  left: 0;
  right: 0;
  width: 40rem;
  margin: 0 auto;
  min-height: 200px;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000027;
  transition: 1s ease-in-out;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3{
    font-size: 1.3rem;
  }
  button{
    position: absolute;
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
    top: 20px;
    right: 25px;
  }
`;

export const Title = styled.h3`
  font-weight: bold;
  font-family: lato;
  color: var(--primary-gray);
  text-align: left;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
`;

export const CancelButtom = styled.button`
  height: 35px;
  font-family: lato;
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 5px;
  width: 138px;
  cursor: pointer;
  background-color: white;
  &:hover {
      opacity: 0.7;
  }
  &:active {
      border: none !important;
  }
  &:focus {
      border: none !important;
      outline: none !important;
  }
`;

export const CloseButtoom = styled.div`
    height: 26px;
    width: 26px;
    transition: 0.4s;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--primary-gray);
    &:hover {
        background-color: rgb(33, 74, 47, 0.2);
    }
`;

export const ConfirmButton = styled.button<{isLoading: boolean}>`
  height: 35px;
  margin-left: 20px;
  font-family: lato;
  color: white;
  border: none;
  width: 138px;
  background-color: ${(props) => (props.isLoading ? lighten(0.2, '#0270bb') : '#0270bb')};
  border-radius: 5px;
  cursor: pointer;
  &:hover {
      opacity: 0.7;
  }
  &:active {
      border: none !important;
  }
  &:focus {
      border: none !important;
      outline: none !important;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
`;

export const LoaderButton = styled.img`
    height: 25px;
    width: 25px;
`;

export const Body = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
