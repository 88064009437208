import styled from 'styled-components';
import colors from '../../../colors';

export const Container = styled.div`
  width: 100%;
  overflow: scroll;
`;

export const ProfessionalImage = styled.img`
    border-radius: 184px;    
    width: 184px;
    height: 184px;
    object-fit: cover;
    border: solid 2px ${colors.white};
`;

export const ProfessionalNonImage = styled.div`
    border-radius: 184px;    
    width: 184px;
    height: 184px;
    background-color: ${colors.gray90};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
`;

export const Forms = styled.form`
`;

export const FormChild = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const InputLabel = styled.span`
  color: ${colors.gray};
  font: normal normal 600 12px/19px lato;
  max-width: 100px;
  width: 100%;
`;

export const RequiredLabel = styled.span`
  color: ${colors.gray};
  font: normal normal 600 12px/15px lato;
  font-weight: 100;
  max-width: 100px;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 35px;
  color: ${colors.gray};
  text-align: left;
  padding-left: 10px;
  border: 1px solid ${colors.white10};

    :invalid {
        box-shadow: 0 0 0.5em ${colors.red};
        border-color: ${colors.red};
    }
`;
