import styled, { css } from 'styled-components';
import colors from '../../colors';

export const Container = styled.div`
  label {
    margin-bottom: 16px;
  }
`;

export const DaysContainer = styled.div<{
  bordered?: boolean;
  disabled?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  opacity: ${(p) => (p.disabled ? 0.7 : 1)};
  ${(p) => p.bordered
    && css`
      border-radius: 100px;
      border: 1px solid #00B38E;
      padding: 7px 10px;
    `}
`;

interface DayProps {
  isSelected: boolean;
  size?: 'small' | 'medium';
}
export const Day = styled.button<DayProps>`
  margin-left: 5px;
  font-weight: bold;
  min-width: ${(p) => (p.size === 'small' ? ' 30px' : '36px')};
  min-height: ${(p) => (p.size === 'small' ? ' 30px' : '36px')};
  border-radius: 100px;
  display: flex;
  background-color: ${(p) => (p.isSelected ? `${colors.green}` : '#F5F6F8')} !important;
  align-items: center;
  border: 1px solid
    ${(p) => (p.isSelected ? '#F5F6F8' : `${colors.green}`)};
  justify-content: center;
  color: ${(p) => (p.isSelected ? '#F5F6F8' : `${colors.green}`)};
  :focus {
    outline: none;
  }
  font-size: ${(p) => (p.size === 'small' ? ' .9rem' : '1rem')};

  @media only screen and (max-width: 820px) {
    width: 25px !important;
    height: 25px !important;
    min-height: 25px !important;
    min-width: 25px !important;
    font-size: 0.5rem;
  }
`;
