/* eslint-disable camelcase */
import Api from './api';

export default class AnnounceService {
  static async postAnnounceVocancy(
    data: {
      notification_type: string;
      date: string;
      seat_id?: number;
      start: string;
      end: string;
      teams_id?: string;
      placement_id: number
    },
  ) {
    const response = await Api.post('announcements', data);
    return response;
  }
}
