import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  isPast, toDate, parseISO, isFuture, isToday,
} from 'date-fns';
import MainNavBar from '../../components/MainNavBar';
import { useGlobal } from '../../hooks/useGlobal';
import SectionTitle from '../../components/SectionTitle';
import icons from '../../assets/icons';
import ShiftService from '../../services/ShiftService';
import { useToast } from '../../hooks/toast';
import DayCard from './DayCard';
import { TextBody } from './style';

const Dashboard = () => {
  const { t } = useTranslation('pages');
  const [loading, setLoading] = useState(true);
  const { sideMenuIsOpen } = useGlobal();
  const { addToast } = useToast();
  const [myShifts, setMyShifts] = useState([]);
  const [available, setAvailable] = useState<any[]>([]);
  const [pastReservations, setPastReservations] = useState([]);
  const [futureReservations, setFutureReservations] = useState([]);

  useEffect(() => {
    ShiftService.getMyShiftsAvailable()
      .then((response) => {
        setMyShifts(response.data.shifts);
        setAvailable(response.data.validation);
      })
      .catch(() => {
        addToast({
          description: t('Dashboard.error'),
          type: 'error',
        });
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const filterPast = myShifts.filter((shifts: any) => isPast(toDate(parseISO(shifts?.start)))
      && !isToday(toDate(parseISO(shifts?.start))));
    const filterFuture = myShifts.filter(
      (shifts: any) => isFuture(toDate(parseISO(shifts?.start)))
        || isToday(toDate(parseISO(shifts?.start))),
    );
    setPastReservations(filterPast);
    setFutureReservations(filterFuture);
  }, [myShifts]);

  const verifyIsToday = (date: any) => isToday(toDate(parseISO(date)));

  const verifyAvailable = (id: any) => {
    const index = available.findIndex((reservation:any) => reservation.id === id);
    if (index > -1) {
      return available[index].is_available;
    }
    return false;
  };

  const createShift = (id: any) => {
    const index = available.findIndex((reservation:any) => reservation.id === id);
    if (index > -1) {
      const {
        // eslint-disable-next-line camelcase
        allocated_at, start, end, team_id, placement_id, seat_id, employee_id,
      } = available[index];
      const payload = {
        placementId: placement_id,
        employees: [{
          end,
          start,
          team_id,
          id: employee_id,
        }],
        seatId: seat_id,
        allocatedAt: allocated_at,
      };
      // const payload = available[index];
      ShiftService.saveShiftWithManyEmployees(payload);
    }
  };

  return (
    <>
      <MainNavBar />
      {loading
        ? (
          <div className={`d-flex row my-5 px-2 me-0 justify-center ${sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'}`}>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        )
        : (
          <div className={`row my-5 px-2 me-0 ${sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'}`}>
            <div className="col-12">
              <SectionTitle>
                <>
                  <img className="m-auto mx-2" src={icons.CheckinClock} alt="" />
                  <span className="sub-text m-auto ms-0">{t('Dashboard.my_reservations')}</span>
                </>
              </SectionTitle>
            </div>
            <div className="row mx-2">
              <div className="col-12">
                <TextBody className="mt-2 mb-0" color="#000E4C">Agora</TextBody>
              </div>
              {futureReservations.map((futureShift: any) => verifyIsToday(futureShift?.start)
                && (
                  <div className="col-3 my-2">
                    <DayCard shift={futureShift} translate={t} isToday />
                  </div>
                ))}
              <div className="col-12 mt-2">
                <TextBody className="mt-2 mb-0" color="#000E4C">Próximas reservas</TextBody>
              </div>
              {futureReservations.map((futureShift: any) => !verifyIsToday(futureShift?.start)
                && (
                  <div className="col-3 my-2">
                    <DayCard shift={futureShift} translate={t} />
                  </div>
                ))}
            </div>
            <div className="col-12 mt-5 mb-2">
              <SectionTitle>
                <>
                  <img className="m-auto mx-2" src={icons.CheckinClock} alt="" />
                  <span className="sub-text m-auto ms-0">{t('Dashboard.past_reservations')}</span>
                </>
              </SectionTitle>
            </div>
            <div className="row mx-2">
              {pastReservations.slice(pastReservations.length - 8).map((pastShift: any) => (
                <div className="col-3 my-2">
                  <DayCard
                    shift={pastShift}
                    translate={t}
                    isAvailable={verifyAvailable(pastShift.id)}
                    createShift={createShift}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
    </>
  );
};

export default Dashboard;
