import styled from 'styled-components';
import { lighten } from 'polished';
import colors from '../../colors';

interface Props {
    isLoading?: boolean;
}

export const Container = styled.div`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    height: 100vh;
`;

export const RowContainer = styled.div`
    height: 100%;
    overflow-y: auto;
    background-color: ${colors.white10};
    display: flex;
    margin-right: -15px;
    margin-left: -15px;
`;

export const LoginContainer = styled.div`
    width: 400px;
    height: 100%;
    margin: 0 0 0 auto;
    background-color: ${colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const LoginContent = styled.div`
    max-width: 400px;
    padding: 0 32px;
    color: ${colors.gray80};
    font-size: 16px;
    background-color: ${colors.white};

    @media (max-width: 645px) {
        margin: 0;
        padding: 0;
    }
`;

export const LogoContainer = styled.div`
    text-align: center;
    max-width: 250px;
    margin: auto;
`;

export const Logo = styled.img`
    width: 100%;
`;

export const SuccessText = styled.p`
    color: ${colors.green};
    text-align: center;
    font-size: 16px;
    margin: 10px 0 30px;
`;

export const ErrorText = styled.p`
    color: ${colors.red};
    text-align: center;
    font-size: 16px;
    margin: 10px 0 30px;
`;

export const InfoText = styled.p`
    color: ${colors.blue};
    text-align: center;
    font-size: 22px;
    margin: 10px 0 30px;
`;

export const ButtonContainer = styled.div`
    width: 100%;
    margin: auto;
    margin-top: 40px;
`;

export const Button = styled.button<Props>`
    padding: 15px 30px;
    text-transform: uppercase;
    width: 100%;
    font-size: 14px;
    color: ${colors.white};
    outline: 0;
    text-align: center;
    border-radius: 5px;
    background-color: ${(props) => (props.isLoading ? lighten(0.2, `${colors.blue}`) : `${colors.blue}`)};
    border: none;
`;

export const LoaderButton = styled.img`
    height: 25px;
    width: 25px;
`;

export const ForgotPasswordContainer = styled.div`
    font-size: 16px;
    color: ${colors.gray70};
    cursor: pointer;
    margin: 12px auto 32px;
    text-align: center;
`;

export const ForgotPassword = styled.a`
    color: ${colors.blue};
    text-decoration: none;
`;

export const GoBackLoginContainer = styled.div`
    font-size: 16px;
    color: ${colors.gray70};
    cursor: pointer;
    margin: 12px auto 32px;
    text-align: center;
`;

export const GoBackLogin = styled.a`
    color: ${colors.blue};
    text-decoration: none !important;
    font-weight: 500;
    font-size: 18px;
    
    &:hover {
        color: ${lighten(0.09, `${colors.blue}`)};
    }
`;
