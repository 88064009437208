import Api from './api';

export default class AuthService {
  static async apiCheck() {
    const response = await Api.get('apicheck');
    return response;
  }

  static async login(key: string, value: string, password: string) {
    const response = await Api.post('user/login', {
      [key]: value, password,
    });
    return response;
  }

  static async forgotPassword({ email }: {email:string}) {
    const response = await Api.post('password/email', {
      email,
    });
    return response;
  }

  static async resetPassword({
    email, token, password, passwordConfirmation,
  }: {email:string; token: string; password: string; passwordConfirmation: string}) {
    const response = await Api.post('password/reset', {
      email,
      token,
      password,
      password_confirmation: passwordConfirmation,
    });
    return response;
  }

  static async twoFactor(tempAccess: string, token: string) {
    const data = {
      token,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${tempAccess}`,
      },
    };

    const response = await Api.post('/two_factor', data, config);

    return response;
  }

  static async loginWithTwoFactor(tempAccess: string, token: string) {
    const data = {
      token,
    };

    const config = {
      headers: {
        Authorization: `Bearer ${tempAccess}`,
      },
    };

    const response = await Api.post('/user/twofactor', data, config);

    return response;
  }

  static async register({
    name, email, companyName, phone,
  }: { name:string, email: string, companyName:string, phone: string | undefined }) {
    const response = await Api.post('/companies/create/structure', {
      name,
      email,
      company_name: companyName,
      phone,
    });
    return response;
  }

  static async checkLogin(value: string, key?: string | null) {
    const response = await Api.post('user/email', {
      [!key ? 'email' : key]: value,
    });
    return response;
  }

  static async validateCodeSSOLogin(code: string, companyId: string) {
    const response = await Api.get(`sso/login?code=${code}&company_id=${companyId}`);
    return response;
  }

  static async logout() {
    const response = await Api.post('user/logout');
    return response;
  };

  static async sendFirstLoginEmail(email: string) {
    const response = await Api.post('user/password', {
      email,
    });
    return response;
  };
}
