/* eslint-disable camelcase */
import Api from './api';

export interface AddUserInformationRequest {
  email: string;
  fullname: string;
  password: string;
  phone: string;
  company_name: string;
  utm_source: string|null,
  utm_medium: string|null,
  utm_campaign: string|null,
  utm_term: string|null
}

interface Employee {
  email: string,
  role: string,
  name: string,
}

interface CreateShiftRequest {
  date: string,
  start: string,
  end: string,
}

export default class OnboardingService {
  static async addUserInformation(body: AddUserInformationRequest) {
    const response = await Api.post('onboarding/create/user', body);
    return response;
  }

  static async createLocationAndTeam(body: any) {
    const response = await Api.post('onboarding/informations', body);
    return response;
  }

  static async createEmployeesAndSendEmail(body: Array<Employee>) {
    const response = await Api.post('onboarding/employees', { employees: body });
    return response;
  }

  static async createShift(body: CreateShiftRequest) {
    const response = await Api.post('onboarding/shifts', body);
    return response;
  }

  static async updateStep(step: string) {
    const response = await Api.post('onboarding/update/step', { step });
    return response;
  }

  static async getAchievements() {
    const response = await Api.get('/onboarding/achievements');
    return response;
  }

  static async setTutorial(tutorialDone: boolean) {
    const response = await Api.post('/employees/update/tutorial', { tutorial_done: tutorialDone });
    return response;
  }
}
