/* eslint-disable camelcase */
/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import Employee from 'src/contracts/models/Employee';
import Team from 'src/contracts/models/Team';
import Api from 'src/services/api';

type PaginatedParams = {
  page: number;
  perPage: number;
  name?: string;
  placement_id?: number | string;
  location_id?: number | string;
};

export const teamMockData: { teams: Team[] } = {
  teams: [],
};
const sleep = (milliseconds: number) => new Promise((resolve) => setTimeout(resolve, milliseconds));
export default class TeamService {
  static async createTeam(team: Team) {
    const response = await Api.post('teams', team);
    return response;
  }

  static async deleteTeam(teamId: number) {
    const response = Api.delete(`teams/${teamId}`);
    return response;
  }

  static async getTeamFromCompany() {
    const response = await Api.get('teams');
    return response;
  }

  static async getTeamsWithoutEmployees() {
    const response = await Api.get('teams', { params: { employees_count: 0 } });
    return response;
  }

  static async getTeamEmployees(query: string) {
    const response = Api.get(`teams/with/employees?teams_id=${query}`);
    return response;
  }

  static async updateTeam(team: Team, teamId: number) {
    const response = await Api.post(`teams/${teamId}`, team);
    return response;
  }

  static async updateProfessionalWorkDays(
    professionalId: number | string,
    teamId: number | string,
    days: number[],
  ) {}

  static async findAll() {
    const response = await Api.get('teams/with/placements/employees');
    return response;
  }

  static async findPlacements(teamId: number) {
    await sleep(1000);
    return teamMockData.teams.find((team) => team.id === teamId)?.placements;
  }

  static async getTeamsFromPlacement(placementId: number) {
    const response = Api.get(`teams/from/placement/${placementId}`);
    return response;
  }

  static async getTeamsPaginated(
    page = 1,
    perPage = 10,
    name: undefined | string,
    locationId: string | number,
    placementId: string | number,
  ) {
    const params: PaginatedParams = {
      page,
      perPage,
      name,
    };

    if (locationId !== undefined) {
      params.location_id = locationId;
      params.placement_id = undefined;
    }

    if (placementId !== undefined) {
      params.placement_id = placementId;
      params.location_id = undefined;
    }

    const response = Api.get('teams/paginated', { params });
    return response;
  }

  static async getTeam(teamId: number, search: string) {
    const params = {
      search,
    };

    const response = await Api.get(`teams/${teamId}`, { params });
    return response;
  }

  static async getTeamHasEmployee() {
    return Api.get('teams/has/employee');
  }
}
