import React, { ChangeEvent, useEffect, useState } from 'react';
import { CSSProperties } from 'styled-components';
import Container from './style';

interface QuantityProps {
  label?: string;
  step?: number;
  onChange?: (number: number) => void;
  value?: number;
  limit?: number,
  disabled?: boolean;
  validator?: (_value: number|undefined) => boolean;
  style?: CSSProperties;
  className?: string;
}
const Quantity: React.FC<QuantityProps> = ({
  step,
  onChange,
  label,
  value,
  disabled,
  limit,
  validator = () => true,
  style,
  className,
}) => {
  const [number, setNumber] = useState(value ?? 0);

  const canValidate = (inputValue?: number) => {
    if (validator) {
      return validator(inputValue);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (_?: number) => true;
  };

  const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    evt.preventDefault();
    const { value: inputValue } = evt.target;
    if (Number.isNaN(+inputValue) || (limit && inputValue && +inputValue > limit)) {
      return;
    }

    if (canValidate(+inputValue)) {
      setNumber(+inputValue);
      onChange?.(+inputValue);
    }
  };

  const handleAddNumberClick = () => {
    if (disabled) {
      return;
    }
    const nextValue = (step ?? 1) + number;
    if ((limit && number < limit) || (limit && limit !== 0 && limit < number)) {
      if (canValidate(nextValue)) {
        setNumber(nextValue);
        onChange?.(nextValue);
      }
    } else if (!limit) {
      if (canValidate(nextValue)) {
        setNumber(nextValue);
        onChange?.(nextValue);
      }
    }
  };

  const handleSubNumberClick = () => {
    if (disabled) {
      return;
    }
    if (number !== 0) {
      const nextValue = number - (step ?? 1);
      if (canValidate(nextValue)) {
        setNumber(nextValue);
        onChange?.(nextValue);
      }
    }
  };

  useEffect(() => {
    if (value as number >= 0) {
      if (canValidate(value)) {
        setNumber(value as number);
      }
    }
  }, [value]);

  return (
    <Container style={style} disabled={disabled} className={className}>
      {label && <label htmlFor="select_quantity">{label}</label>}
      <div>
        <button type="button" onClick={handleSubNumberClick}>
          -
        </button>
        <input
          id="select_quantity"
          type="text"
          value={number}
          onChange={handleInputChange}
          disabled={disabled}
          style={{
            borderColor: '#0000001f',
            borderWidth: '1px',
          }}
        />
        <button type="button" onClick={handleAddNumberClick}>
          +
        </button>
      </div>
    </Container>
  );
};
export default Quantity;
