import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import reactDOM from 'react-dom';
import {
  Body,
  Container,
  ContainerOutside,
  Header,
  Overlay,
} from './style';
import CustomNavBar from '../CustomNavBar';
import EditProfessional from './EditProfessional';
import icons from '../../assets/icons';

interface ModalProfessionalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  fullName: string;
  id: number;
  internalId?: string;
  email?: string;
  cpf?: string;
  phone?: string;
  photo?: string;
  teamId?: number;
}
const ModalProfessional: React.FC<ModalProfessionalProps> = ({
  isOpen,
  onCloseModal,
  fullName,
  id,
  internalId,
  email,
  cpf,
  phone,
  photo,
  teamId,
}) => {
  const { t } = useTranslation('components');
  const pages = [
    {
      label: t('ModalProfessional.title-modal'),
      tab: <EditProfessional
        fullName={fullName}
        id={id}
        internalId={internalId}
        email={email}
        cpf={cpf}
        phone={phone}
        photo={photo}
        setIsOpen={onCloseModal}
        teamId={teamId}
      />,
    },
    // {
    //   label: 'route',
    //   tab: <div>route</div>,
    // },
  ];

  const escFunction = (event: any) => {
    const escKey = 27;
    if (event.keyCode === escKey) {
      onCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  }, []);

  return (
    reactDOM.createPortal(
      <Overlay isOpen={isOpen}>
        <ContainerOutside
          onClick={() => onCloseModal()}
          className="position-absolute"
        />
        <Container
          isOpen={isOpen}
          className="d-flex flex-column justify-content-between"
        >
          <div>
            <Header className="d-flex justify-content-end">
              <button type="button" onClick={onCloseModal}>
                <img src={icons.close} className="logo" alt={t('ModalEditUser.close')} />
              </button>
            </Header>
            <Body>
              <CustomNavBar
                pages={pages}
              />
            </Body>
          </div>
        </Container>
        ,
      </Overlay>,
      document.getElementById('root')!,
    )
  );
};

export default ModalProfessional;
