import styled from 'styled-components';

export const DarkBg = styled.div<{ isOpen: boolean }>`
  z-index: 99;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #0003;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  z-index: 100;
  padding: 25px 35px;
  box-shadow: 0 3px 6px #00000027;
  min-width: 40rem;
  background-color: white;
  border-radius: 5px;
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  .conditionType {
    width: 155px;
    margin-left: 1rem;
  }
  .DayPickerInput {
    > input {
      height: 40px;
      width: 155px !important;
      padding: 0 17px;
    }
  }

  @media (max-width: 1366px) {
    max-height: 50rem;
    overflow-y: scroll;
  }
`;

export const Body = styled.div`
  max-height: 45rem;
  
  &.overflow-body{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  > div {
    > div:nth-child(3) {
      >input {
        width: 4rem;
        border: 1px solid #e3e5eb;
        border-radius: 3px;
      }
    }
  }
  .select-week-day{
    > div > div{
      border: 1px solid #E3E5EB;
      border-radius: 26px;
      padding: 5px;
    }
  }
  .DayPicker {
    display: inline-block;
  }
  .DayPicker-Month {
    margin: 0;
  }
  .DayPicker-Week {
    .DayPicker-Day {
      font-size: 0.8rem;
      border-radius: 0;
      border: 1px solid #e5e7e7;
      width: 35px;
      height: 35px;
    }
    .DayPicker-Day--selected {
      background-color: #1074ce;
      border: 1px solid #1074ce;
    }
  }
  &.body-announce{
    overflow: initial;
  }
  
  .single-select-label{
    margin-left: 0;
    margin-bottom: 6px;
    color: #727789;
  }
  .single-select-input{
    div{
      background: #fff;
      border-color: #CCC !important;
    }
  }
  input.week-number{
    border: 1px solid #0000001F;
    min-height: 38px;
    border-radius: 4px;
  }
  @media(max-width:1366px){
    max-height: 50rem;
  }
`;

export const Resume = styled.div`
  margin: 25px 0 15px 0;
  text-transform: capitalize;
  color: #333;
  font-weight: bold;
`;

export const Header = styled.div`
  > button {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    line-height: 0;
  }
  .header-title{
      font-size: 1.2rem;
      color: var(--primary);
      margin-bottom: 15px;
  }
`;

export const Footer = styled.div`
  margin-top: 1rem;
  button {
    border: none;
    :first-child {
      display: flex;
      align-items: center;
      font-weight: 600;
      padding: 0;
      color: var(--system-blue);
      :disabled {
        opacity: .7;
      }
      > img{
        width: 17px;
      }
      span{
        font-size: 16px;
      }
    }
    &:nth-child(2){
      text-transform: uppercase;
    }
  }
  @media (max-width: 1366px) {
    margin-bottom: 1rem;
  }
`;

export const NavBar = styled.div`
  display: flex;
  justify-content: space-between;
  > span {
    font-weight: bold;
  }
  font-size: 1.2rem;
  button {
    padding: 0 0.5rem;
    border: 1px solid #e3e5eb;
    border-radius: 5px;
    background-color: white;
  }
`;

// DailyFrequency styles
export const RuleContainer = styled.div`
  display: flex;
  align-items: center;
  > button {
    border: none;
    padding: none;
    background-color: transparent;
    color: var(--system-blue);
  }
`;

export const AddRuleButton = styled.button`
  padding: 10px 20px;
  border: none;
  padding: none;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  background-color: var(--system-blue);
`;

export const ErrorContainer = styled.div`
  > div {
    padding: 0 1rem;
    width: 100%;
  ul {
    li {
      :first-child {
      }
      :last-child{
        border-radius: 0 0 4px 4px;
      }
      color: #a94442;
    }
      background-color: #f2dede;
    list-style-type: none;
    border-radius: 5px;
  }
  }
`;

export const ProfessionalContainer = styled.div`
  input {
    width: 100%
  }
  &:not(:first-child){
    margin-top: .5rem;
  }
`;

export const AddButton = styled.button`
  padding: 10px 20px;
  border: none;
  padding: none;
  border-radius: 5px;
  background-color: transparent;
  color: white;
  background-color: var(--system-blue);
`;

export const NotFilledButton = styled.button`
    border: none;
    padding: none;
    background-color: transparent;
    color: var(--system-blue);
    :disabled{
      opacity: .5;
    }
`;
