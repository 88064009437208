import React, { useEffect } from 'react';

import doFormat from '../../utils/doFormat';
import {
  InputProps, Container, StyledInput, StyledInputMask,
} from './style';

const Input: React.FC<InputProps> = ({
  label,
  id,
  required,
  mask,
  styledLabel,
  ...props
}) => {
  useEffect(() => {
    const { 'data-mask': dataMask, 'data-format': dataFormat } = props;
    if ((!dataMask as any && !dataFormat) || !id) return;
    doFormat(id);
  });

  return (
    <Container>
      {label && <label style={styledLabel} htmlFor={id}>{`${label} ${required ? '*' : ''}`}</label>}
      <div>
        {mask
          ? <StyledInputMask id={id} mask={mask} {...props} />
          : <StyledInput id={id} on {...props} />}
      </div>
    </Container>
  );
};

export default Input;
