import styled from 'styled-components';
import colors from '../../colors';

interface props {
  isOpen: boolean
  isActive?: boolean
}

export const Container = styled.div<props>`
  margin: 15px;
  margin-bottom: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 10px;
  display: ${(props) => (props.isActive ? 'block' : 'none')};
  .accordion-height {
    min-height: 100px;
    position: relative;
    max-height: ${(props) => (props.isOpen ? '311px' : '0')};
    overflow: hidden;
    display: block !important;
    padding: 15px;
    @media only screen and (max-width: 768px) {
      padding: 5px !important;
    }
  }
`;
export const ContainerButton = styled.div`
  padding: 0 15px 0 15px;
  @media only screen and (max-width: 768px) {
    padding: 5px !important;
  }
`;

export const Button = styled.button<props>`
  display: flex;
  justify-content: flex-start;
  font-weight: bold;
  color: ${colors.blue};
  background-color: transparent;
  border: none;
  font-size: 3rem;
  div {
    span {
      margin: auto;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;

      color: #626363;
      @media only screen and (max-width: 768px) {
        font-size: 14px !important;
      }
    } 
    img {
      height: 18px
    }
  }
  img {
    height: 2rem;
  }
  img:nth-of-type(1) {
    line-height: 0;
    transition: 0.4s;
  }

  .icon-rotate {
    ${(props) => !props.isOpen && 'transform: rotate(-90deg); transition: 0.4s;'}
  }
`;

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  position: relative;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
  text-align: right;
  .button-trash{
    img{
      filter: grayscale(1);
      opacity: .4;
    }
  }
`;

export const TextAccordionHeader = styled.h2`
  span {
    margin: auto 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 120%;
    color: #000E4C;
  }
`;
