import React, { TextareaHTMLAttributes } from 'react';
import { TextAreaStyled, Container } from './style';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: React.ReactNode;
}
const TextArea: React.FC<TextAreaProps> = (props) => {
  const { id, label } = props;
  return (
    <Container>
      {label && <label htmlFor={id}>{`${label}`}</label>}
      <TextAreaStyled {...props} />
    </Container>
  );
};

export default TextArea;
