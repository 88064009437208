import styled from 'styled-components';

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #0002;
  z-index: 99;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 30rem;
  height: 100vh;
  background-color: white;
  box-shadow: 0 3px 6px #00000027;
  transition: 1s ease-in-out;
`;

export const Header = styled.div`
h3{
  font-size: 1.3rem;
}
  button{
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
  }
`;

export const Body = styled.div`
  > div:first-child {
    > div {
      margin-top: 2rem;
      @media(max-width: 1366px){
        margin-top: 1.4rem;
      }
    }
  }
  .DateRangePicker{
    > div{
      > div, .DateInput, .DateInput_input{
        background: transparent;
        font-weight: normal;
        font-size: 14px;
      }
      > div {
         border-color: #CCC !important;
         .DateInput{
           padding: 8px 6px !important;
         }
         .DateInput_input{
           line-height: 18px;
           text-align: center;
         }
      }
    }
  }
  
  .single-select-input{
    > div{
      > div{
        background: #fff;
        border-color: #CCC !important;
      }
    }
  }
  .single-select-label{
    font-size: 13px;
    color: var(--secondary-gray);
    margin-bottom: 5px;
  }
`;

export const PublishSummary = styled.div`
  margin-top: 2rem;
  background: #f6f8f9 0% 0% no-repeat padding-box;
  border-radius: 5px;
`;

export const LoaderButton = styled.img`
    height: 25px;
    width: 25px !important;
    filter: brightness(1.5);
`;

export const Footer = styled.div`
  width: 100%;
`;
