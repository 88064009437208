import React, { useCallback, useState } from 'react';
import {
  FormGroup, Label, Modal, ModalBody, ModalHeader,
} from 'reactstrap';
import QRCode from 'react-qr-code';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import UserService from 'src/services/UserService';
import { useToast } from 'src/hooks/toast';
import Button from '../Button';
import Input from '../Input';
import colors from '../../colors';

export interface ModalConfirmProps {
  isOpen?: boolean;
  handleCloseButton?: () => void;
  center?: boolean;
}

const ModalQrCode: React.FC<ModalConfirmProps> = ({
  isOpen,
  center = true,
  handleCloseButton,
}) => {
  const { addToast } = useToast();
  const { t } = useTranslation('components');
  const currentUser = localStorage.getItem('espacos@user');
  const parsed = currentUser && JSON.parse(currentUser);
  const twoFactor = get(parsed, 'user[two_factor_key]', null);

  const [isGenerateToken, setIsGenerateToken] = useState(false);
  const [isDeletingToken, setIsDeletingToken] = useState(false);
  const [isVerifingToken, setIsVerifingToken] = useState(false);

  const [codeToken, setCodeToken] = useState<string>('');

  const [urlQrCode, setUrlQrCode] = useState<string>('');

  const updateUserStorage = (user: any, url: any) => {
    const userInformation = {
      access_token: user.access_token,
      user: {
        ...user.user,
        two_factor_key: url,
      },
    };

    localStorage.setItem('espacos@user', JSON.stringify(userInformation));
  };

  const saveToken = async () => {
    const { data } = await UserService.createToken();
    updateUserStorage(parsed, data.url);
    setUrlQrCode(data.url);
  };

  const deleteToken = async () => {
    await UserService.deleteToken();
    updateUserStorage(parsed, null);
  };

  const emptyFieldsToken = async () => {
    setCodeToken('');
    setUrlQrCode('');
  };

  const handleSaveToken = useCallback(async () => {
    setIsGenerateToken(true);

    try {
      await saveToken();

      addToast({
        type: 'success',
        description: t('ModalEditUser.success_generate'),
      });
    } catch (error) {
      addToast({
        type: 'error',
        description: t('ModalEditUser.error_generate'),
      });
    } finally {
      setIsGenerateToken(false);
    }
  }, []);

  const handleDeleteToken = useCallback(async () => {
    setIsDeletingToken(true);

    try {
      await deleteToken();

      addToast({
        type: 'success',
        description: t('ModalEditUser.success_remove'),
      });
    } catch (error) {
      addToast({
        type: 'error',
        description: t('ModalEditUser.error_remove'),
      });
    } finally {
      setIsDeletingToken(false);
    }
  }, []);

  const validateInputOnlyNumbers = useCallback((value) => {
    setCodeToken(value.replace(/[^\d]+/g, ''));
  }, []);

  const handleValidateToken = useCallback(async () => {
    setIsVerifingToken(true);

    try {
      await UserService.verifyToken(codeToken);

      emptyFieldsToken();

      addToast({
        type: 'success',
        description: t('ModalEditUser.success_validate'),
      });
    } catch (error) {
      addToast({
        type: 'error',
        description: t('ModalEditUser.error_validate'),
      });
    } finally {
      setIsVerifingToken(false);
    }
  }, [codeToken]);

  return (
    <Modal
      isOpen={isOpen}
      onClosed={() => {
        handleCloseButton?.();
      }}
      centered={center}
    >
      <ModalHeader toggle={handleCloseButton} style={{ color: `${colors.blue}` }}>
        {t('ModalEditUser.title_auth_token')}
      </ModalHeader>
      <ModalBody>
        {twoFactor ? (
          <>
            {urlQrCode ? (
              <>
                <p className="text-muted text-center">
                  {t('ModalEditUser.new_token_remove')}
                </p>
                <div className="text-center">
                  <QRCode value={urlQrCode} />
                  <div className="mt-4">
                    <FormGroup>
                      <Label>
                        {t('ModalEditUser.placeholder_input_verify')}
                      </Label>
                      <Input
                        type="text"
                        name="code_of_token"
                        onChange={(e) => validateInputOnlyNumbers(e.target.value)}
                        value={codeToken}
                        className="w-50"
                        maxLength={6}
                        required
                      />
                    </FormGroup>
                    <Button
                      color="primary"
                      label={t('ModalEditUser.validate_token')}
                      onClick={handleValidateToken}
                      isLoading={isVerifingToken}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="text-muted">
                  {t('ModalEditUser.exists_token_remove')}
                </p>
                <Button
                  color="primary"
                  label={t('ModalEditUser.remove_token')}
                  onClick={handleDeleteToken}
                  isLoading={isDeletingToken}
                />
              </>
            )}
          </>
        ) : (
          <>
            <p className="text-muted">
              {t('ModalEditUser.not_exists_token_instalation')}
            </p>
            <Button
              color="primary"
              label={t('ModalEditUser.generate_token')}
              onClick={handleSaveToken}
              isLoading={isGenerateToken}
            />
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default ModalQrCode;
