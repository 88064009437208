import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Option from 'src/contracts/models/Option';
import LocationService from 'src/services/LocationService';
import { Component, Selector } from './style';

interface SpaceSelectorProps {
  onChange(space: Option[]): void;
  value: any;
  isClearable: boolean;
}

const LocationSelector: React.FC<SpaceSelectorProps> = ({
  onChange,
  value,
  isClearable,
}) => {
  const { t } = useTranslation('components');

  const loadOptions = useCallback(
    async (searchQuery: string, loadedOptions: any, { page }: any) => {
      const response = await LocationService.getLocationFromEmployee(
        10,
        page,
        searchQuery,
      );

      return {
        options: response.data.data,
        hasMore: response.data.last_page > page,
        additional: {
          page: searchQuery ? 2 : page + 1,
        },
      };
    },
    [],
  );

  return (
    <Component id="location-selector">
      <Selector
        closeMenuOnSelect
        placeholder={t('LocationSelector.placeholder')}
        value={value || ''}
        loadOptions={loadOptions}
        getOptionValue={(option: any) => option.name}
        getOptionLabel={(option: any) => option.name}
        onChange={onChange}
        isSearchable
        debounceTimeout={500}
        isClearable={isClearable}
        additional={{
          page: 1,
        }}
        cacheUniqs={[value]}
      />
    </Component>
  );
};

export default LocationSelector;
