import React, { useContext, useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import DragTypes from 'src/contracts/models/DragTypes';
import { homeContext } from 'src/pages/Home/Context';
import Employee from 'src/contracts/models/Employee';
import {
  Card,
  ProfessionalCardLeftContent,
  ProfessionalImage,
  ProfessionalNonImage,
  ProfessionalName,
  Totalemployeespaces,
} from './style';
import ModalProfessional from '../../ModalProfessional';
import Team from '../../../contracts/models/Team';

export interface EmployeeWithCount extends Employee {
  count : number;
}
interface ProfessionalCardProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  employee: EmployeeWithCount;
  team: Team,
  setIsOpenEmployeeEdit: React.Dispatch<React.SetStateAction<boolean>> | undefined,
}

const EmployeeCard: React.FC<ProfessionalCardProps> = ({
  isOpen,
  employee,
  team,
  setIsOpenEmployeeEdit,
}) => {
  const { teamsMaxVacancy, selectedUserId, setSelectedUserId } = useContext(homeContext);
  const [isModalProfessionalOpen, setIsModalProfessionalOpen] = useState(
    false,
  );

  const [, dragRef] = useDrag({
    item: {
      type: DragTypes.SIDE_BAR_PROFESSIONAL,
      employee: {
        id: employee.id,
        name: employee.name,
        teamId: team.id,
        teamName: team.name,
      },
    },
    canDrag: (teamsMaxVacancy?.[team.id]?.maxVacancy),
  });

  const getProfessionalInitials = () => {
    const split = employee?.name?.split(' ');
    const firstLetter = split[0].substr(0, 1);
    const secondLetter = split[1] ? split[1].substr(0, 1) : '';
    return firstLetter + secondLetter;
  };

  const getFirstAndLastName = () => {
    const names = employee?.name?.split(' ');
    const firstName = names.shift();
    const lastName = names[names.length - 1] !== undefined ? names[names.length - 1] : '';
    const name = `${firstName} ${lastName}`;

    return name;
  };

  getProfessionalInitials();

  const handleModalProfessionalClick = () => {
    setIsModalProfessionalOpen(true);
  };

  useEffect(() => {
    if (setIsOpenEmployeeEdit) {
      setIsOpenEmployeeEdit(isModalProfessionalOpen);
    }
  }, [isModalProfessionalOpen]);

  return (
    <>
      <Card
        ref={dragRef}
        onDoubleClick={handleModalProfessionalClick}
        onClick={() => {
          if (selectedUserId?.id === employee.id) {
            setSelectedUserId?.(null);
            return;
          }
          setSelectedUserId?.({
            id: employee.id,
            teamId: team.id,
            name: employee.name,
            teamName: team.name,
          });
        }}
        selected={employee.id === selectedUserId?.id}
        open={isOpen}
      >
        <ProfessionalCardLeftContent>
          {employee.photo ? (
            <ProfessionalImage src={employee.photo} />
          ) : (
            <ProfessionalNonImage>
              {getProfessionalInitials()}
            </ProfessionalNonImage>
          )}
          {isOpen && <ProfessionalName>{getFirstAndLastName()}</ProfessionalName>}
        </ProfessionalCardLeftContent>
        <Totalemployeespaces
          color={
            employee.count ? '#2D9A36' : '#727789'
          }
        >
          {isOpen && (employee.count || 0)}
        </Totalemployeespaces>
      </Card>
      {isModalProfessionalOpen && (
      <ModalProfessional
        onCloseModal={() => setIsModalProfessionalOpen(false)}
        isOpen={isModalProfessionalOpen}
        fullName={employee.name}
        id={employee.id}
        email={employee.email}
        cpf={employee.cpf}
        phone={employee.phone}
        photo={employee.photo}
        internalId={employee.code}
        teamId={team.id}
      />
      )}
    </>
  );
};

export default EmployeeCard;
