import styled from 'styled-components';
import colors from '../../colors';

const Container = styled.div`
  > label {
    color: var(--secondary-grey);
    font-size: 0.875rem;
  }
`;

export const ErrorMessage = styled.div`
  margin-top: 11px;
  color: ${colors.red};
`;
export default Container;
