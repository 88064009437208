import styled from 'styled-components';
import colors from 'src/colors';

export const ActiveContainer = styled.div`
  display: flex;
  align-items: center;
`;

type IsActiveIconType = {
  active: boolean;
};

export const IsActiveIcon = styled.div<IsActiveIconType>`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${(p) => (p.active ? `${colors.green}` : `${colors.orange}`)};
  margin-right: 10px;
`;
