const calculateLimitPerPositions = (
  maximumOccupationLimitPercentageValue: number,
  positionsAmount: number,
) => {
  if (!maximumOccupationLimitPercentageValue) {
    return positionsAmount;
  }

  return Math.ceil(
    (maximumOccupationLimitPercentageValue * positionsAmount) / 100,
  );
};

const MAX_PERCENTAGE_VALUE = 100;
const DEFAULT_PERCENTAGE_VALUE = 0;

type UseCalculateMaxOccupationPercentParams = {
  employeesTotal: number,
  limitHeadcount: number,
  limitMaximumOccupation: number
};

const useCalculateMaxOccupationPercent = ({
  employeesTotal,
  limitHeadcount,
  limitMaximumOccupation = 0,
}:UseCalculateMaxOccupationPercentParams) => {
  const areTotalAndLimitHeadcountValid = employeesTotal !== 0 && limitHeadcount !== 0;

  if (!areTotalAndLimitHeadcountValid) {
    return DEFAULT_PERCENTAGE_VALUE;
  }

  const perPositionsLimit = calculateLimitPerPositions(
    limitMaximumOccupation,
    limitHeadcount,
  );

  const finalPercentageValue = (employeesTotal * 100) / perPositionsLimit;

  if (finalPercentageValue > MAX_PERCENTAGE_VALUE) {
    return MAX_PERCENTAGE_VALUE;
  }

  const finalPercentageValueWithTwoPointOfPrecision = Number(finalPercentageValue.toFixed(2));

  return Math.abs(finalPercentageValueWithTwoPointOfPrecision);
};

export default useCalculateMaxOccupationPercent;
