import styled from 'styled-components';
import colors from '../../../../colors';

interface Props {
  center?: boolean | undefined;
  spaced?: boolean | string;
  nowrap?: boolean;
  open?: boolean;
  left?: boolean;
  right?: boolean;
  noMargin?: boolean;
}
interface ColumnProps {
  content?: string;
  flex?: boolean;
}

export const Screen = styled.div`
  @media only screen and (max-width: 768px) {
    padding-top: 63.40px;
  }
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .collapse {
    &:nth-child(2) {
      display: none;
    }
  }
`;

export const DisabledButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
`;

export const PositionCard = styled.div<Props>`
  margin-top: 15px;
  border-top: 1px solid ${colors.gray25};
  border-bottom: 1px solid ${colors.gray25};
  background-color: ${colors.white13};
  padding: 15px 10px 15px 15px;
  display: flex;
  align-items: center;
  height: 90px;
  width: 100%;
  cursor: pointer;

  > img {
    height: auto;
    width: 35px;
    transform: rotate(${(props) => props.open && '180deg'});
    transition: 0.3s;
  }
  > div {
    &:nth-child(2) {
      display: none;
    }
  }
`;

export const PositionCardContent = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
`;

export const PositionCardActions = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  flex: 1;
`;
export const Map = styled.div`
  display: flex;
  flex: 1;
`;

export const Location = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 100px;
  flex: 1;
`;

export const LocationTitle = styled.span`
  font: normal normal 600 17px/21px lato;
  line-height: 1.5;
  color: var(--primary-gray);
`;

export const LocationSubTitle = styled.span`
  font: normal normal medium 15px/18px lato;
  line-height: 1.5;
  color: ${colors.gray};
`;

export const TotalLocations = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font: normal normal 600 17px/21px lato;
  color: ${colors.gray};
  margin-right: 90px;
  text-align: right;
  flex: 1;
`;

export const Teams = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Team = styled.div`
  font: normal normal medium 15px/18px lato;
  color: ${colors.gray};
`;

export const MapIcon = styled.img`
  margin-right: 10px;
  filter: invert(32%) sepia(99%) saturate(1106%) hue-rotate(185deg)
    brightness(94%) contrast(91%);
`;
export const MapLabel = styled.span`
  font: normal normal medium 15px/18px lato;
  color: ${colors.blue};
`;

export const EditIcon = styled.img`
  margin-right: 46px;
`;

export const RemoveIcon = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
  width: 23px;
  color: ${colors.gray20};
  font: normal normal medium 15px/18px lato;
  border: 2px solid ${colors.gray20};
  border-radius: 50%;
`;

export const Buttons = styled.div`
  min-height: 100px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  align-self: center;
`;

export const Cancel = styled.div`
  text-align: left;
  font: normal normal normal 14px lato;
  letter-spacing: 0px;
  color: ${colors.gray};
  opacity: 1;

  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const CollapsedBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  background-color: ${colors.white13};
  padding: 52px 52px 0 38px;
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const SectionTitle = styled.span`
  font: normal normal 600 17px/21px lato;
  color: ${colors.gray};
  margin-bottom: 28px;
`;

export const Subtitle = styled.span<Props>`
  font: normal normal medium 15px/25px lato;
  color: ${colors.gray90};
  margin: ${(props) => !props.noMargin && '20px 38px'};
  white-space: ${(props) => (props.nowrap ? 'nowrap' : 'normal')};
`;

export const Column = styled.span<ColumnProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => props.content && props.content};
  flex: ${(props) => props.flex && 1};
`;

export const Row = styled.span<Props>`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: ${(props) => props.center && 'center'};
  justify-content: ${(props) => props.spaced && 'space-between'};
`;

export const CheckBox = styled.input.attrs({
  type: 'checkbox',
})`
  width: 18px;
  height: 18px;
  margin-right: 14px;
  cursor: pointer;
`;

export const NumericInput = styled.input`
  width: 75px;
  height: 40px;
  border: 1px solid ${colors.white10};
  border-radius: 4px;
  padding-right: 10px;
  font: normal normal 500 17px/29px lato;
  color: ${colors.gray};
  text-align: right;
  margin: auto 10px;
`;

export const Label = styled.span`
  font: normal normal medium 15px/18px lato;
  color: ${colors.gray};
`;

export const Space = styled.div`
  height: 35px;
  width: 35px;
`;

export const OptionSelectorSpace = styled.div`
  display: flex;
  padding-left: 2%;
`;

export const HourLabels = styled.span<Props>`
  font: normal normal medium 15px/18px lato;
  color: ${colors.gray};
  margin-right: ${(props) => (props.left ? '10px' : '0')};
  margin-left: ${(props) => (props.right ? '10px' : '0')};
  white-space: nowrap;
`;
