import styled from 'styled-components';
import { Collapse } from 'reactstrap';
import colors from '../../../../colors';

interface Props {
  multiple?: boolean,
  showDays?: boolean,
  isOpen?: boolean,
}

const Container = styled.div`
  color: ${colors.gray};
  width: 100%;

  h3 {
    font-size: 1.4rem;
    font-weight: bold;
  }
`;

export const DeleteButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 12px;
  gap: 8px;
  background: #E6454C;
  border-radius: 4px;
  border: 0;
  
  img {
    filter: invert(99%) sepia(85%) saturate(0%) hue-rotate(207deg) brightness(104%) contrast(103%);
  }
  span {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
  }
`;

export const HeadCount = styled.div`
  min-width: 133px;
  display: flex;

  span {
    font-size: 0.875rem;
    color: ${colors.gray};
  }

  > div {
    > div {
      height: 38px;
      line-height: 38px;
      color: ${colors.gray};
    }
  }
`;

export const MaxOccupation = styled.div`
  display: flex;

  span {
    font-size: 0.875rem;
    color: var(--primary-gray);
  }

  > div {
    > div {
      margin-bottom: 0.8rem;
      color: ${colors.green};
    }
  }
`;

export const ButtonBackWard = styled.button`
  width: max-content;
  background-color: transparent;
  border: none;
`;

export const RadioGroup = styled.div``;

export const PositionsAvailabilityRow = styled.div`
  display: flex !important;
  justify-content: space-between;
  width: 100%;

  > div {
    :first-child {
      display: flex !important;
      align-items: center !important;
      justify-content: flex-start !important;
      width: 100%;
    }

    :nth-child(2) {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      > img {
        height: auto;
        width: 15px;
      }

      > span {
        color: ${colors.blue};
        margin-left: 10px;
        white-space: nowrap;
        @media (max-width: 1366px) {
          display: none;
        }
      }
    }
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    display: block !important;
  }

  > * {
    margin-right: 40px;
  }

  span {
    font-size: 0.875rem;
  }
`;

export const ButtonEnd = styled.button`
  border: none;
  background-color: transparent;
  color: ${colors.blue};
`;

export const SingleDateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 65px !important;
  min-width: 100px;
  margin-top: 15px;

  > span {
    font: normal normal normal 13px/18px lato;
  }

  > button {
    margin-bottom: 8%;
  }

  > div {
    > img {
      :last-child {
        padding-left: 5%;
        cursor: pointer;
      }
    }
  }
`;

export const PositionsAvailabilityContainer = styled.div`
  display: flex !important;
  flex-direction: column;
`;

export const BackButtonLabel = styled.span`
  color: ${colors.blue};
  font: normal normal bold 15px/18px lato;
`;

export const HourPickerContainer = styled.div<Props>`
  height: max-content;
  margin-top: 10px;
  padding-bottom: 10px;
`;
export const HourPickerContainerHeader = styled(HourPickerContainer)<Props>`
  display: flex;
  align-items: start;
  justify-content: space-between;
  margin-top: 20px;

  > * {
    height: 10px;

    :nth-child(1) {
      width: ${(props) => props.showDays && '150px'};
    }

    :nth-child(2) {
      width: 150px;
    }
  }
`;

export const WeekDay = styled.div`
  font: normal normal bold 15px/18px lato;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: auto;
  margin-top: 40px;
`;

export const ButtonsSeatTools = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  flex-direction: row !important;
`;

export const PositionsColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > div {
    > button {
      &.btnOutlinePrimary {
        border: 1px dashed ${colors.green70};
        color: ${colors.green70};
        width: 10%;
        padding: 0.9375rem;
        border-radius: 5px;
        background-color: transparent;
        @media only screen and (max-width: 768px) {
          padding: 0 !important;
        }
      }
    }

    > h3 {
      @media only screen and (max-width: 768px) {
        font-size: 15px;
      }
    }
  }
`;

export const AvailabilityHeader = styled.div<Props>`
  background-color: ${colors.green70};
  font: normal normal normal 15px/18px lato;
  color: white;
  padding: 30px 20px 30px 30px;
  @media only screen and (max-width: 768px) {
    padding: 15px !important;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  width: 100%;
  cursor: pointer;
  border-radius: ${(props) => (props.isOpen ? '10px 10px 0 0' : '10px')};
  
  
  > img {
    height: auto;
    width: 30px;
    transition: all 0.3s;
    filter: grayscale(100%);
    transform: rotate(${(props) => (props.isOpen ? '180deg' : '0deg')})
  }
`;

export const AvailabilityBody = styled(Collapse)`
  padding: 30px 20px 30px 30px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  width: 100%;
  border: 1px solid ${colors.gray15};
  @media only screen and (max-width: 768px) {
    padding: 15px !important;
  }
`;

export const InputLabel = styled.span`
  font-size: 0.875rem;
  line-height: 0;
  margin-bottom: .6rem;
  color: ${colors.gray};
  display: block;
`;

export default Container;
