import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActionButtonSection } from './components';
import { SelectedEmployeesAmount } from './style';
import useSelectedUsers from '../../../../hooks/useSelectedUsers';

export interface ManageDeleteOrRestoreUsersSectionProps {
  showConfirmActionModal: () => void
  isFilteringByInactiveRole: boolean
  actualItemsCount: number;
}

const ManageDeleteOrRestoreUsersSection = ({
  showConfirmActionModal,
  isFilteringByInactiveRole,
  actualItemsCount,
}: ManageDeleteOrRestoreUsersSectionProps) => {
  const { t } = useTranslation('pages');

  const [{ selectedUsers }] = useSelectedUsers();

  const selectedUsersAmount = selectedUsers.length;

  const showSelectedEmployeesAmount = selectedUsersAmount > 0
    && actualItemsCount === selectedUsersAmount;
  const showActionButton = selectedUsersAmount > 0;

  return (
    <div className="row">
      <div className="col-12">
        {showSelectedEmployeesAmount && (
          <SelectedEmployeesAmount>
            {t('Administration.tabs.user.selected_employees_amount', {
              selected_employees_amount: selectedUsersAmount,
            })}
          </SelectedEmployeesAmount>
        )}
      </div>
      <div className="col-12 mt-2">
        {showActionButton && (
          <ActionButtonSection
            isFilteringByInactiveRole={isFilteringByInactiveRole}
            showConfirmActionModal={showConfirmActionModal}
          />
        )}
      </div>
    </div>
  );
};

export default ManageDeleteOrRestoreUsersSection;
