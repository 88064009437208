/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { isLogged } from 'src/services/auth';
import { useGlobal } from './hooks/useGlobal';
import { getData } from './services/LocalStorageService';

const ADMIN_ROUTES = [
  '/management',
  '/space/:id?',
  '/spaces/:tab?',
];

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const { user, setUser } = useGlobal();

  if (!isLogged()) {
    return <Redirect to="/login" />;
  }

  if (!user) {
    setUser(getData('espacos@user').user);
  }

  if (!user?.isAdmin) {
    if (ADMIN_ROUTES.includes(props.path as string)) {
      return null;
    }
  }

  return (isLogged() ? <Route {...props} /> : <Redirect to="/login" />);
};

export default PrivateRoute;
