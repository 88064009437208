import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import { useForm } from 'react-hook-form';
import EmployeeService from 'src/services/EmployeeService';
import Employee from 'src/contracts/models/Employee';
import Input from 'src/components/InputWithRef';
import ButtonLoader from 'src/components/ButtonLoader';
import ConfirmModal from 'src/components/ConfirmModal';
import {
  Checkbox, FormControlLabel,
} from '@material-ui/core';
import { mixpanelTrack } from 'src/services/MixPanelService';
import EmployeeRoles from 'src/contracts/constants/employee.roles.constants';
import Validator from 'src/utils/input.valitors';
import {
  Container,
  FormChild,
  Forms,
  InputLabel,
  ProfessionalImage,
  ProfessionalNonImage,
  RequiredLabel,
} from './style';
import { useAlert } from '../../../hooks/useAlert';
import trimString, { concatenateCompanyCode } from '../../../utils/trimString';
import { validateCode } from '../../../utils/validate';
import { getUser } from '../../../services/auth';
import removeExif from '../../../utils/removeExif';

interface CreateEmployeeProps {
  handleSuccess: (createdEmployeeId: any, newlyCreatedEmployee: boolean) => void;
}

const CreateEmployee: React.FC<CreateEmployeeProps> = ({ handleSuccess }) => {
  const { addToast } = useToast();
  const { t } = useTranslation('components');
  const {
    register, errors, handleSubmit, watch,
  } = useForm<FormData>({
    reValidateMode: 'onSubmit',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [confirmEmptyEmail, setConfirmEmptyEmail] = useState(false);
  const [photoFileState, setPhotoFileState] = useState<any>(undefined);
  const [photoBase64State, setPhotoBase64State] = useState<any>(undefined);

  const { openAlert } = useAlert();

  const appendFormData = async (body: FormData, employee: Employee) => {
    const roles = isAdmin
      ? [
        { role_id: EmployeeRoles.ADMIN, action: isAdmin ? 'ADD' : 'DELETE' },
      ]
      : [];

    const CPF = employee.cpf || '';
    const PHONE = employee.phone || '';

    await body.append('name', employee.name);
    await body.append('phone', PHONE.replace(/[^\d]+/g, ''));
    await body.append('roles', JSON.stringify(roles));

    if (employee.email && employee.email.length > 0) {
      body.append('email', employee.email);
    }
    if (CPF && CPF.length > 0) {
      body.append('cpf', CPF.replace(/[^\d]+/g, ''));
    }
    if (employee.code && employee.code.length > 0) {
      const user = getUser();
      if (user) {
        const { code } = user.employee.company;
        body.append('code', employee.code.replace(`${code}-`, '') || '');
      }
    }
    try {
      const dataApi = await EmployeeService.createEmployee(body);
      addToast({
        description: t('ModalTeamAddEmployee.new_employee_tab.create_success'),
        type: 'success',
      });
      const createdEmployeeId = dataApi.data.id;
      mixpanelTrack('user@create', null);
      handleSuccess(createdEmployeeId, true);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        addToast({
          description: err.response.data.message,
          type: 'error',
        });
      } else if (err.response && err.response.status === 422) {
        addToast({
          description: t('ModalTeamAddEmployee.new_employee_tab.create_error_exists'),
          type: 'error',
        });
      } else {
        addToast({
          description: t('ModalTeamAddEmployee.new_employee_tab.create_error'),
          type: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmit = (createdEmployee: Employee) => {
    const onConfirm = async () => {
      setIsLoading(true);
      const body = new FormData();
      if (!photoFileState) await appendFormData(body, createdEmployee);
      else {
        await removeExif(photoFileState, async (params: any) => {
          await body.append('photo', params?.file ?? '');
          await appendFormData(body, createdEmployee);
        });
      }
    };

    openAlert({
      handleConfirm: onConfirm,
      message: !createdEmployee.email ? t('ModalAdminUser.no_email') : t('ModalCreateUser.confirm_create_user'),
    });
  };

  const getBase64 = (file: Blob) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

  const handleChange = async (target: HTMLInputElement) => {
    const file = target.files![0];
    setPhotoFileState(file);
    getBase64(file).then((result) => setPhotoBase64State(result));
  };

  const buildFiledSelect = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.type = 'file';
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', 'image/*');
    fileSelector.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      handleChange(target);
    };
    return fileSelector;
  };

  const photoSelector = buildFiledSelect();

  function handleFileSelect(e: { preventDefault: () => void; }) {
    e.preventDefault();
    photoSelector.click();
  }

  function handleFileRemove() {
    setPhotoBase64State(undefined);
  }

  const handleCloseButtonEmptyEmailModal = useCallback(() => {
    setConfirmEmptyEmail(false);
  }, []);

  const handleConfirmEmptyEmailModal = useCallback(() => {
    handleSubmit(onSubmit)();
    setConfirmEmptyEmail(false);
  }, []);

  return (
    <Container>
      <div className="employee-info w-100 d-flex p-5">
        <div className="employee-left w-25 text-center">
          {photoBase64State ? (
            <>
              <ProfessionalImage src={photoBase64State} />
              <div className="d-flex justify-content-center">
                <button
                  className="btn btn-link mt-2 p-1"
                  type="button"
                  onClick={handleFileSelect}
                >
                  {t('ModalProfessional.EditProfessional.update_photo')}
                </button>
                <button
                  className="btn btn-link mt-2 p-1"
                  type="button"
                  onClick={handleFileRemove}
                >
                  {t('ModalProfessional.EditProfessional.remove_photo')}
                </button>
              </div>
            </>
          ) : (
            <ProfessionalNonImage onClick={handleFileSelect}>
              {t('ModalProfessional.EditProfessional.load_photo')}
            </ProfessionalNonImage>
          )}
        </div>
        <div className="employee-body w-75 ps-md-5 ps-0">
          <Forms onSubmit={handleSubmit(onSubmit)} method="post" encType="multipart/form-data">
            <FormChild>
              <InputLabel>
                {t('ModalProfessional.EditProfessional.input_name')}
                *
              </InputLabel>
              <Input
                error={errors}
                errorMsg={t('ModalTeamAddEmployee.new_employee_tab.name_error')}
                name="name"
                register={register({ required: true, setValueAs: trimString })}
              />
            </FormChild>
            <FormChild>
              <InputLabel>
                {t('ModalProfessional.EditProfessional.input_email')}

              </InputLabel>
              <Input
                error={errors}
                errorMsg={t('ModalTeamAddEmployee.new_employee_tab.email_error')}
                name="email"
                register={register({
                  validate: (value) => !value || Validator.validateEmail(trimString(value)),
                  setValueAs: trimString,
                })}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_id')}</InputLabel>
              <Input
                error={errors}
                value={watch('code')}
                name="code"
                register={register({
                  setValueAs: concatenateCompanyCode,
                  validate: (value) => validateCode(value, addToast, t),
                })}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_phone')}</InputLabel>
              <Input
                error={errors}
                name="phone"
                mask="(99) 99999-9999"
                register={register}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_cpf')}</InputLabel>
              <Input
                error={errors}
                errorMsg={t('ModalTeamAddEmployee.new_employee_tab.cpf_error')}
                mask="999.999.999-99"
                name="cpf"
                register={register({
                  validate: (value) => !value || Validator.validateCPF(value.replace(/[^\d]/g, '')),
                })}
              />
            </FormChild>
            <FormChild>
              <InputLabel />
              <FormControlLabel
                control={<Checkbox name="checkedB" color="primary" />}
                label={t('ModalAdminUser.new_tab.admin_permissions_label')}
                onChange={() => setIsAdmin(!isAdmin)}
              />
            </FormChild>
            <FormChild>
              <InputLabel />
              <RequiredLabel>
                *
                {t('ModalTeamAddEmployee.required_item')}
              </RequiredLabel>
            </FormChild>
            <FormChild>
              <InputLabel />
              <ButtonLoader
                label={t('ModalTeamAddEmployee.new_employee_tab.advance')}
                type="submit"
                isLoading={isLoading}
              />
            </FormChild>
            {confirmEmptyEmail && (
            <ConfirmModal
              isOpen={confirmEmptyEmail}
              message={t('ModalAdminUser.no_email')}
              handleConfirm={handleConfirmEmptyEmailModal}
              handleCloseButton={handleCloseButtonEmptyEmailModal}
            />
            )}
          </Forms>
        </div>
      </div>

    </Container>
  );
};

export default CreateEmployee;
