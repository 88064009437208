import React, { InputHTMLAttributes } from 'react';

import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';
import colors from '../../colors';

export interface InputProps extends InputHTMLAttributes<any> {
  label?: React.ReactNode;
  hasError?: string;
  ref?: any;
  mask?: string | Array<(string | RegExp)>;
  maskPlaceholder?: string | null;
  alwaysShowMask?: boolean;
  maskChar?: string | null,
  styledLabel?: any,
  'data-mask'?: string,
  'data-format'?: string

}

export const StyledInput = styled.input<InputProps | any>`
  height: 40px;
  max-width: 100%;
  padding: 0 5px;
  background: ${colors.white} 0% 0% no-repeat padding-box;
  border: 1px solid ${colors.dark90};
  border-radius: 4px;
  opacity: 1;
  color: ${colors.gray};
  font-size: 1rem;
  vertical-align: 0;
  :disabled {
    background: ${colors.white10} 0% 0% no-repeat padding-box;
    border: 1px solid ${colors.white15};
  }

  ${(p) => p.hasError
    && css`
      border: 1px solid ${colors.red};
      box-shadow: 0px 0px 4px ${colors.red90};
    `}
`;

export const StyledInputMask = styled(InputMask)`
  height: 40px;
  padding: 0 17px;
  background: ${colors.white} 0% 0% no-repeat padding-box;
  border: 1px solid ${colors.dark90};
  border-radius: 4px;
  opacity: 1;
  color: ${colors.gray};
  font-size: 1rem;
  vertical-align: 0;
  :disabled {
    background: ${colors.white10} 0% 0% no-repeat padding-box;
    border: 1px solid ${colors.white13};
  }

  ${(p) => p['aria-errormessage']
    && css`
      border: 1px solid ${colors.red};
      box-shadow: 0px 0px 4px ${colors.red90};
    `}
`;
export const Container = styled.div`
  > div {
    display: initial;
  }
  > label {
    margin-bottom: 0;
    color: var(--secondary-grey);
    font-size: 0.875rem;
  }
`;
