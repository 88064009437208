import { Instance, createPopper } from '@popperjs/core';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import Employee from 'src/contracts/models/Employee';
import Team from 'src/contracts/models/Team';
import { useToast } from 'src/hooks/toast';
import JustificationService from 'src/services/JustificationService';
import Button from '../Button';
import SelectWeekDay from '../SelectWeekDay';
import TextArea from '../TextArea';
import {
  DarkBg, ModalBody, ModalContainer, ModalHeader,
} from './style';

interface JustificationModalProps {
  dayRequestData: {
    team: Team;
    professional: Employee;
  };
  setDayRequestData: React.Dispatch<any>;
}

const JustificationModal: React.FC<JustificationModalProps> = ({
  dayRequestData,
  setDayRequestData,
}) => {
  const { t } = useTranslation('components');
  useEffect(() => {
    const ParentReference = document.getElementById(
      `${dayRequestData.team.id}-${dayRequestData.professional.id}`,
    );
    const modalReference = document.getElementById('tooltip')!;
    const popper: Instance = createPopper(ParentReference!, modalReference!, {
      placement: 'right-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    });

    const hideModal = (evt: any) => {
      if (evt.target.id === 'darkBG') {
        setDayRequestData(null);
      }
    };
    window.onclick = hideModal;

    return () => {
      popper?.destroy();
    };
  }, []);

  const { addToast } = useToast();
  const onRefuseJustificationClick = async () => {
    try {
      await JustificationService.update(
        dayRequestData.team.id,
        dayRequestData.professional.id,
        dayRequestData.professional?.justification?.id as number,
        {
          accepted: 0,
        },
      );
      addToast({
        type: 'success',
        title: '',
        description: t('JustificationModal.justification_success_refused'),
      });
      setDayRequestData(null);
    } catch (error) {
      if (error?.response?.data?.message) {
        addToast({
          description: error.response.data.message,
          type: 'error',
        });
      }
    }
  };
  const onAcceptJustificationClick = async () => {
    try {
      JustificationService.update(
        dayRequestData.team.id,
        dayRequestData.professional.id,
        dayRequestData.professional?.justification?.id as number,
        {
          accepted: 1,
        },
      );
      addToast({
        type: 'success',
        title: '',
        description: t('JustificationModal.justification_success_accepted'),
      });
      setDayRequestData(null);
    } catch (error) {
      if (error.response) {
        addToast({
          description: error.response.data.error.message,
          type: 'error',
        });
      }
    }
  };
  return createPortal(
    <DarkBg id="darkBG">
      {createPortal(
        <ModalContainer className="p-4" id="tooltip">
          <ModalHeader>
            <h3>{dayRequestData.professional.name}</h3>
          </ModalHeader>
          <ModalBody>
            <div>
              <div>{t('JustificationModal.current_availability')}</div>
              <div className="d-flex justify-content-between align-items-center mt-2">
                <SelectWeekDay
                  size="small"
                  disabled
                  selectedDaysDefault={
                    dayRequestData.professional.justification
                      ?.currentAvailability as any
                  }
                />
                <span>
                  {t('JustificationModal.totalDays', {
                    total:
                      dayRequestData.professional.justification
                        ?.currentAvailability.length,
                  })}
                </span>
              </div>
            </div>
            <div className="mt-4">
              <div>{t('JustificationModal.availability_request')}</div>
              <div className="d-flex justify-content-between align-items-center mt-2">
                <SelectWeekDay
                  size="small"
                  disabled
                  selectedDaysDefault={
                    dayRequestData.professional?.justification
                      ?.availabilityRequest as any
                  }
                />
                <span>
                  {t('JustificationModal.totalDays', {
                    total:
                      dayRequestData.professional.justification
                        ?.availabilityRequest.length,
                  })}
                </span>
              </div>
            </div>
            <div className="mt-4">
              <div>{t('JustificationModal.justification')}</div>
              <TextArea
                className="w-100"
                disabled
                value={dayRequestData.professional.justification?.text}
              >
                Hello world
              </TextArea>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button label="Recusar" onClick={onRefuseJustificationClick} />
              <Button
                label="Aceitar"
                color="primary"
                onClick={onAcceptJustificationClick}
              />
            </div>
          </ModalBody>
          <div id="arrow" data-popper-arrow />
        </ModalContainer>,
        document.getElementById(
          `${dayRequestData.team.id}-${dayRequestData.professional.id}`,
        )!,
      )}
    </DarkBg>,
    document.getElementById('root')!,
  );
};
export default JustificationModal;
