import React, {
  useContext, useEffect, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Input from 'src/components/InputWithRef';
import EmployeeService from 'src/services/EmployeeService';
import Validator from 'src/utils/input.valitors';
import Employee from 'src/contracts/models/Employee';
import {
  Container,
  FormChild,
  Forms,
  InputLabel,
  ProfessionalImage,
  ProfessionalNonImage,
  LoaderButton,
} from './style';
import { useToast } from '../../../hooks/toast';
import Loader from '../../../assets/imgs/loader_animation.gif';
import Button from '../../Button';
import trimString, { concatenateCompanyCode } from '../../../utils/trimString';
import { homeContext } from '../../../pages/Home/Context';
import { validateCode } from '../../../utils/validate';
import { getUser } from '../../../services/auth';
import removeExif from '../../../utils/removeExif';

interface EditProfessionalProps {
  fullName: string;
  id: number;
  internalId?: string;
  email?: string;
  cpf?: string;
  phone?: string;
  photo?: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  teamId?: number;
}
const EditProfessional: React.FC<EditProfessionalProps> = ({
  fullName,
  id,
  internalId,
  email,
  cpf,
  phone,
  photo,
  setIsOpen,
  teamId,
}) => {
  const { t } = useTranslation('components');
  const { addToast } = useToast();
  const {
    register, errors, handleSubmit, setError, watch,
  } = useForm<FormData>();
  const [photoFileState, setPhotoFileState] = useState<any>(undefined);
  const [photoBase64State, setPhotoBase64State] = useState<any>(photo);
  const [isLoading, setIsLoading] = useState(false);
  const { updateLocalEmployeesOnTeamsSideBar } = useContext(homeContext);

  const professionalInitials = useMemo(() => {
    const splited = fullName.split(' ');
    const firstLetter = splited[0].substr(0, 1);
    const secondLetter = splited[1] ? splited[1].substr(0, 1) : '';
    return firstLetter + secondLetter;
  }, [fullName]);

  const appendFormData = async (body: FormData, newEmployee: Employee) => {
    const CPF = newEmployee.cpf || '';
    const PHONE = newEmployee.phone || '';
    if (CPF.length > 0) {
      if (!Validator.validateCPF(CPF.replace(/[^\d]+/g, ''))) {
        setError('cpf', { type: 'manual', message: '' });
        setIsLoading(false);
        return;
      }
    }

    await body.append('name', newEmployee.name);
    await body.append('phone', PHONE.replace(/[^\d]+/g, ''));

    if (newEmployee.email && newEmployee.email.length > 0) {
      body.append('email', newEmployee.email);
    }
    if (CPF && CPF.length > 0) {
      body.append('cpf', CPF.replace(/[^\d]+/g, ''));
    }
    if (newEmployee.code && newEmployee.code.length > 0) {
      const user = getUser();
      if (user) {
        const { code } = user.employee.company;
        body.append('code', newEmployee.code.replace(`${code}-`, '') || '');
      }
    }

    if (teamId !== undefined) {
      body.append('team_id', teamId.toString());
    }

    EmployeeService.editEmployee(id, body).then(() => {
      addToast({
        description: t('ModalProfessional.EditProfessional.success'),
        type: 'success',
      });
      setIsLoading(false);
      setIsOpen(true);

      const data = { ...newEmployee, id };

      if (photoFileState) {
        data.photo = photoBase64State;
      }

      updateLocalEmployeesOnTeamsSideBar(data);
    }).catch((err) => {
      setIsLoading(false);
      if (err.response && err.response.status === 400) {
        return addToast({
          description: err.response.data.message,
          type: 'error',
        });
      }
      return addToast({
        description: t('ModalProfessional.EditProfessional.errors'),
        type: 'error',
      });
    });
    setIsLoading(false);
  };
  const submitEditProfessional = async (newEmployee: Employee) => {
    setIsLoading(true);
    const body = new FormData();
    if (!photoFileState) await appendFormData(body, newEmployee);
    else {
      await removeExif(photoFileState, async (params: any) => {
        await body.append('photo', params?.file ?? '');
        await appendFormData(body, newEmployee);
      });
    }
  };

  const getBase64 = (file: Blob) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

  const handleChange = async (e: Event) => {
    // @ts-ignore
    const file = e.target?.files?.[0];
    setPhotoFileState(file);
    getBase64(file).then((result) => setPhotoBase64State(result));
  };

  useEffect(() => {
  }, [photoFileState]);

  const buildFiledSelect = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', 'image/*');
    fileSelector.onchange = (e) => handleChange(e);
    return fileSelector;
  };

  const photoSelector = buildFiledSelect();

  function handleFileSelect(e: { preventDefault: () => void; }) {
    e.preventDefault();
    photoSelector.click();
  }

  return (
    <Container>
      <div className="employee-info w-100 d-flex p-5">
        <div className="employee-left w-25 text-center">
          {photoBase64State ? (
            <ProfessionalImage src={photoBase64State} />
          ) : (
            <ProfessionalNonImage>
              {professionalInitials}
            </ProfessionalNonImage>
          )}

          <div className="d-flex justify-content-center">
            <Button
              onClick={handleFileSelect}
              label={t('ModalProfessional.EditProfessional.edit_photo')}
              icon="alterarFoto"
              className="btn btn-link edit-button mt-2 border-0"
            />
          </div>
        </div>
        <div className="employee-body w-75 ps-md-5 ps-0">
          <Forms onSubmit={handleSubmit(submitEditProfessional)} method="post" encType="multipart/form-data">
            <FormChild>
              <InputLabel>
                *
                {t('ModalProfessional.EditProfessional.input_name')}
              </InputLabel>

              <Input
                error={errors}
                defaultValue={fullName}
                errorMsg={t('ModalTeamAddEmployee.new_employee_tab.name_error')}
                name="name"
                register={register({ required: true, setValueAs: trimString })}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_email')}</InputLabel>
              <Input
                error={errors}
                defaultValue={email}
                errorMsg={t('ModalTeamAddEmployee.new_employee_tab.email_error')}
                name="email"
                register={register({
                  validate: (value) => {
                    if (!value) {
                      return true;
                    }
                    return Validator.validateEmail(value);
                  },
                  setValueAs: trimString,
                })}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_id')}</InputLabel>
              <Input
                error={errors}
                value={watch('code')}
                defaultValue={internalId}
                name="code"
                register={register({
                  setValueAs: concatenateCompanyCode,
                  validate: (value) => validateCode(value, addToast, t),
                })}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_phone')}</InputLabel>
              <Input
                error={errors}
                defaultValue={phone}
                name="phone"
                mask="(99) 99999-9999"
                register={register}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_cpf')}</InputLabel>
              <Input
                error={errors}
                defaultValue={cpf}
                errorMsg={t('ModalTeamAddEmployee.new_employee_tab.cpf_error')}
                mask="999.999.999-99"
                name="cpf"
                register={register}
              />
            </FormChild>
            <FormChild>
              <InputLabel />
              <button
                type="submit"
                className="btn btn-primary text-uppercase"
              >

                {isLoading ? (
                  <LoaderButton
                    src={Loader}
                  />
                ) : t('ModalProfessional.EditProfessional.save')}
              </button>
            </FormChild>
          </Forms>
        </div>
      </div>
    </Container>
  );
};

export default EditProfessional;
