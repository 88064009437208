import Api from './api';

export default class MetabaseService {
  static async getExecutedDailyOccupancyRateChart() {
    const response = await Api.get('metabases/daily/executed');
    return response;
  };

  static async getPlannedDailyOccupancyRate() {
    const response = await Api.get('metabases/planned/daily');
    return response;
  }

  static async getCheckInReport() {
    const response = await Api.get('metabases/checkin');
    return response;
  }

  static async getShiftReport() {
    const response = await Api.get('metabases/shift');
    return response;
  }
}
