import React, {
  useMemo, useContext, useState,
} from 'react';
import { Stage, Layer } from 'react-konva';
import URLImage from 'src/components/CanvasComponents/URLImage';
import SeatCellCanvas from 'src/components/CanvasComponents/SeatCellCanvas';
import SeatModel from 'src/contracts/models/Seats';
import { homeContext } from 'src/pages/Home/Context';
import { ModalMapViewContext } from './Context';

interface CanvasProps {
  src: string;
  scale: number;
  seats: SeatModel[];
}

const Canvas: React.FC<CanvasProps> = ({
  src, scale, seats,
}) => {
  const { placement } = useContext(homeContext);

  const { selectedSeat, setSelectedSeat } = useContext(ModalMapViewContext);
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>({
    width: 0, height: 0,
  });
  const formatedMarkers = useMemo(() => seats.map((seat) => {
    const marker: SeatModel = {
      id: seat.id,
      name: seat.name,
      position: seat.position!,
      employees: seat.employees,
    };
    return marker;
  }), [seats]);

  return (
    <Stage width={imageSize.width} height={imageSize.height}>
      <Layer>
        <URLImage
          src={src}
          onLoadImage={(newImageSize) => {
            setImageSize(newImageSize);
          }}
          onClick={() => setSelectedSeat?.(null)}
        />

        {formatedMarkers.map((seat, index) => {
          if (seat.position?.positionX) {
            return (
              <SeatCellCanvas
                radius={placement?.seatsSize || 25}
                index={index}
                seat={seat}
                scale={scale + (window.innerHeight / imageSize.height)}
                onClick={() => {
                  if (selectedSeat?.id === seat.id) {
                    setSelectedSeat?.(null);
                    return;
                  }
                  setSelectedSeat?.(seat);
                }}
                selected={selectedSeat?.id === seat.id}
              />
            );
          }
          return null;
        })}
      </Layer>
    </Stage>
  );
};

export default Canvas;
