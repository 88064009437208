import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PasswordStrength from 'src/utils/passwordStrength';
import {
  Container,
  Pills,
  PassLabel,
  PillWeak,
  PillMedium,
  PillStrong,
  PillStronger,
} from './style';

interface PassStrengthProps {
    password?: string;
}

interface StrengthProps {
    name?: string;
    value?: number;
}

const PassStrength: React.FC<PassStrengthProps> = ({ password = '' }) => {
  const [strength, setStrength] = useState<StrengthProps | any>({ name: '', value: 0 });
  const { t } = useTranslation('components');
  useEffect(() => {
    setStrength(PasswordStrength.checkPassStrength(password));
  }, [password]);

  const strengthText = () => {
    switch (strength.name) {
      case 'stronger':
        return t('PassStrength.pass_stronger');
      case 'strong':
        return t('PassStrength.pass_strong');
      case 'medium':
        return t('PassStrength.pass_medium');
      case 'weak':
        return t('PassStrength.pass_weak');
      default:
        return t('PassStrength.pass_weak');
    }
  };

  return (
    <Container>
      <Pills>
        <PillWeak active={strength.value >= 1} />
        <PillMedium active={strength.value >= 2} />
        <PillStrong active={strength.value >= 3} />
        <PillStronger active={strength.value >= 4} />
      </Pills>
      <PassLabel>{strength !== '' && strengthText() }</PassLabel>
    </Container>
  );
};

export default PassStrength;
