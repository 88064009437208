import React from 'react';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { BiInfoCircle } from 'react-icons/bi';
import * as PopperJS from '@popperjs/core';
import COLORS from 'src/contracts/constants/colors.constants';

const DEFAULT_ICON_SIZE = 18;
const DEFAULT_POPOVER_PLACEMENT: PopperJS.Placement = 'bottom';

export type CircleInformationProps = {
  text: string
  size?: number
  popoverPlacement?: PopperJS.Placement
  id: string
};

const CircleInformation = ({
  text,
  size = DEFAULT_ICON_SIZE,
  popoverPlacement = DEFAULT_POPOVER_PLACEMENT,
  id,
}: CircleInformationProps) => (
  <>
    <UncontrolledPopover
      flip
      target={id}
      trigger="hover"
      placement={popoverPlacement}
    >
      <PopoverBody>
        {text}
      </PopoverBody>
    </UncontrolledPopover>
    <BiInfoCircle
      size={size}
      color={COLORS.mutedColor}
      id={id}
      style={{
        cursor: 'pointer',
        marginLeft: '10px',
      }}
    />
  </>
);

export default CircleInformation;
