import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import UploadService from 'src/services/UploadService';
import { waitForCompletion } from 'src/utils/jobFunctions';
import User from '../contracts/models/User';
import { logout as logoutService } from '../services/auth';
import { getData, USER_STORAGE } from '../services/LocalStorageService';

interface GlobalContextInterface {
  user: User | null;
  currentJobId: number | null;
  setUser: (user: User) => void;
  startUploadBatch: (file:File) => void;
  clearUpload: Function;
  isUploading: boolean;
  uploadResponse: {
    status: number;
    data: any;
  } | null;
  sideMenuIsOpen: boolean;
  changeSideMenuState: Function;
  isFreemium: boolean
  setIsFreemium: React.Dispatch<React.SetStateAction<boolean>>;
}

const GlobalContext = createContext<GlobalContextInterface>(null as any);

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  return context;
};

const GlobalContextProvider: React.FC = ({ children }) => {
  const [userState, setUserState] = useState<User | null>(null);
  const [currentJobId, setCurrentJobId] = useState<number | null>(null);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [sideMenuIsOpen, setSideMenuIsOpen] = useState<boolean>(false);
  const [uploadResponse, setUploadResponse] = useState<{
    status: number;
    data: any;
  } | null>(null);
  const [isFreemium, setIsFreemium] = useState(false);
  const logout = () => {
    logoutService();
  };
  const setUserWithAuthMethods = (u: User) => {
    const userCopy = { ...u };
    userCopy.logout = logout;
    userCopy.isAdmin = userCopy.roles?.some((role) => role.code === 'ADMIN');
    setUserState(userCopy);
  };

  const clearUpload = () => {
    setIsUploading(false);
    setUploadResponse(null);
    setCurrentJobId(null);
  };

  const setErrosIfExists = async (jobId: number, paylod: any = {}) => {
    try {
      const response = await UploadService.getErros(jobId);
      setUploadResponse({
        status: 1,
        data: {
          ...paylod,
          rows: response.data,
        },
      });
    } catch (error) {
      setUploadResponse({
        status: 0,
        data: {},
      });
    }
  };

  const startUploadBatch = async (file:File) => {
    if (file) {
      setIsUploading(true);
      const formdata = new FormData();
      formdata.append('file', file, file.name);
      let response: any;
      try {
        response = await UploadService.uploadBatch(formdata);
        setCurrentJobId(response.data.jobId);
      } catch (err) {
        setIsUploading(false);
      }

      if (response) {
        waitForCompletion(2, (data) => {
          setIsUploading(false);
          setErrosIfExists(response.data.jobId, JSON.parse(data.payload));
        }, (err) => {
          setUploadResponse({
            status: 0,
            data: err,
          });
        }, response.data.jobId);
      }
    }
  };

  const changeSideMenuState = () => (
    setSideMenuIsOpen(!sideMenuIsOpen)
  );

  useEffect(() => {
    const userStorage = getData(USER_STORAGE);

    if (userStorage) {
      if (userStorage.user.company.payment_type) setIsFreemium(userStorage.user.company.payment_type === 'FREE');
    } else setIsFreemium(false);
  }, [getData(USER_STORAGE)]);

  return (
    <GlobalContext.Provider value={{
      currentJobId,
      user: userState,
      setUser: setUserWithAuthMethods,
      isUploading,
      startUploadBatch,
      uploadResponse,
      clearUpload,
      sideMenuIsOpen,
      changeSideMenuState,
      isFreemium,
      setIsFreemium,
    }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
