import React from 'react';
import { Checkbox } from '@material-ui/core';
import Employee from 'src/contracts/models/Employee';
import IsActiveLabel from '../is_active_label';
import {
  ProfessionalDetail,
  IconContainer,
} from './style';
import { UserPicture, EditAndDeleteButtons, EmployeeRoleText } from './components';
import useSelectedUsers from '../../hooks/useSelectedUsers';

export interface UserTableBodyProps {
  users: Employee[];
  isFilteringByInactiveRole: boolean;
  onEdit: Function;
  startRestore: Function;
  startDelete: Function;
}

const UserTableBody = ({
  users, isFilteringByInactiveRole, onEdit, startRestore, startDelete,
}: UserTableBodyProps) => {
  const [{ selectedUsers }, { addUser, removeUser }] = useSelectedUsers();

  const handleSelectUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const userId = Number(event.target.value);

    if (!selectedUsers.includes(userId)) {
      addUser(userId);
      return;
    }

    removeUser(userId);
  };

  if (!users) {
    return [];
  }

  return users.map((user) => {
    const isUserActive = !isFilteringByInactiveRole;
    const userId = user.id;

    const onEditUser = () => {
      onEdit(userId);
    };
    const onDeleteUser = () => {
      startDelete(userId);
    };
    const onRestoreUser = () => {
      startRestore(userId);
    };

    return [
      <Checkbox
        name="checked-user"
        color="primary"
        value={user.id}
        checked={selectedUsers.includes(user.id)}
        onChange={handleSelectUser}
        className="ps-0"
      />,
      <ProfessionalDetail className="d-flex">
        <UserPicture user={user} />
        <p>{user.name}</p>
      </ProfessionalDetail>,
      <div>{user.email}</div>,
      <EmployeeRoleText role={user.role} />,
      <IsActiveLabel active={isUserActive} />,
      <IconContainer>
        <EditAndDeleteButtons
          isFilteringByInactiveRole={isFilteringByInactiveRole}
          onEdit={onEditUser}
          onDelete={onDeleteUser}
          onRestore={onRestoreUser}
        />
      </IconContainer>,
    ];
  });
};

export default UserTableBody;
