import React, { useState, useEffect } from 'react';
import icons from 'src/assets/icons';
import Button from 'src/components/Button';
import Team from 'src/contracts/models/Team';
import { Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ModalTeamAddEmployee from 'src/components/ModalTeamAddEmployee';
import TeamService from 'src/services/TeamService';
import { employees2array, placements2array } from 'src/utils/team.functions';
import KeysToCamelCase from 'src/utils/keysToCamelCase';
import EmployeeTable from './EmployeeTable';
import OrganizersTable from './OrganizersTable';
import PlacementTable from './PlacementTable';
import {
  Body,
  Container,
  Header,
  LocationList,
  ProfessionalList,
  NameContainer,
} from './style';

interface TeamInfoProps {
  team: Team;
  onEdit: Function;
  onDelete: Function;
  defaultOpen: boolean,
  refreshEmployees: () => void,
  search: string,
  index?: number
}

const TeamInfo: React.FC<TeamInfoProps> = ({
  team, defaultOpen, onEdit, refreshEmployees, search,
  onDelete, index,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const { t } = useTranslation('components');
  const [modalEmployee, setModalEmployee] = useState(false);
  const [teamSearched, setTeamSearch] = useState<any>();
  const EMPLOYEE_ROLE_COLLABORATOR = 1;
  const EMPLOYEE_ROLE_ORGANIZER = 2;

  function getTeam() {
    TeamService.getTeam(team.id, search).then((res: any) => {
      const contributors = () => {
        let employees: any[] = [];

        if (res.data.employees[EMPLOYEE_ROLE_COLLABORATOR]) {
          employees = KeysToCamelCase(
            employees2array(res.data.employees[EMPLOYEE_ROLE_COLLABORATOR]),
          );
        }

        return employees;
      };

      const organizers = () => {
        let employees: any[] = [];

        if (res.data.employees[EMPLOYEE_ROLE_ORGANIZER]) {
          employees = KeysToCamelCase(
            employees2array(res.data.employees[EMPLOYEE_ROLE_ORGANIZER]),
          );
        }

        return employees;
      };

      const placements = res.data.placements
        ? KeysToCamelCase(placements2array(res.data.placements))
        : [];

      setTeamSearch({
        id: res.data.id,
        name: res.data.name,
        contributors: contributors(),
        organizers: organizers(),
        placements,
      });
    });
  }

  useEffect(() => {
    if (isOpen) {
      getTeam();
    }
  }, [isOpen]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isOpen) {
        getTeam();
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [search]);

  return (
    <Container id={`team-info-${String(index)}`}>
      <Header isOpen={isOpen} className="d-flex justify-content-between align-items-center">
        <NameContainer onClick={() => setIsOpen((prev) => !prev)}>
          <button className="icon-rotate" type="button">
            <img src={icons.CollapseArrow} alt="" />
          </button>
          <span>
            {team.name}
            {' '}
            (
            {team.employeesCount}
            )
          </span>
        </NameContainer>
        <div>
          <button onClick={() => onEdit()} type="button">
            <img src={icons.EditBlueDark} alt="edit" />
          </button>
          <button onClick={() => onDelete()} type="button">
            <img src={icons.TrashRed} alt="delete" />
          </button>
        </div>
      </Header>
      <Collapse isOpen={isOpen}>
        {teamSearched && (
          <Body>
            <div className="d-flex justify-content-between">
              <div>
                <h3>{t('TeamInfo.locations')}</h3>
              </div>
              <div>
                <div>
                  <Button
                    onClick={() => setModalEmployee(true)}
                    color="primary"
                    label={t('TeamInfo.add_employees')}
                    icon="GroupAdd"
                    id="add_new_employee"
                  />
                </div>
              </div>
            </div>
            <LocationList className="mt-3">
              {teamSearched.placements && (
                <div>
                  <PlacementTable teamState={teamSearched} />
                </div>
              )}
            </LocationList>
            <ProfessionalList className="mt-5">
              <div>
                <h3>
                  {t('TeamInfo.employees')}
                  <img
                    className="ms-2"
                    src={icons.Info}
                    alt={t('TeamInfo.tooltips.employees')}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t('TeamInfo.tooltips.employees')}
                  />
                </h3>
              </div>
              <div className="mt-3">
                {teamSearched.contributors && (
                  <EmployeeTable teamState={teamSearched} refreshEmployees={getTeam} />
                )}
              </div>
            </ProfessionalList>
            <ProfessionalList className="mt-5">
              <div>
                <h3>
                  {t('TeamInfo.organizers')}
                  <img
                    className="ms-2"
                    src={icons.Info}
                    alt={t('TeamInfo.tooltips.organizers')}
                    data-toggle="tooltip"
                    data-placement="top"
                    title={t('TeamInfo.tooltips.organizers')}
                  />
                </h3>
              </div>
              {teamSearched.organizers && (
                <OrganizersTable teamState={teamSearched} refreshEmployees={getTeam} />
              )}
            </ProfessionalList>
          </Body>
        )}
      </Collapse>
      {modalEmployee && (
        <ModalTeamAddEmployee
          isOpen={modalEmployee}
          handleSuccessEmployee={() => {
            getTeam();
            refreshEmployees();
            setModalEmployee(false);
          }}
          handleCloseButton={() => setModalEmployee(false)}
          teamId={team.id}
        />
      )}
    </Container>
  );
};
export default TeamInfo;
