import React, { ReactElement } from 'react';
import {
  HideButton, HideButtonIcon, SideBarElement, Body,
} from './style';
import icons from '../../assets/icons';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  children: ReactElement
}

const SideBar: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  children,
}) => {
  const handlerOpenSidebar = () => setIsOpen(!isOpen);
  return (
    <SideBarElement open={isOpen}>
      <HideButton onClick={handlerOpenSidebar}>
        <HideButtonIcon src={icons.SideBarArrow} open={isOpen} />
      </HideButton>
      <Body open={isOpen}>
        {children}
      </Body>
    </SideBarElement>
  );
};

export default SideBar;
