import React, { useState } from 'react';

export const ModalMapSelectorContext = React.createContext<{
  selectedSeatId: number | string | null,
  setSelectedSeatId?: React.Dispatch<React.SetStateAction<number | string | null>>;
    }>({
      selectedSeatId: null,
    });
const MapSelectorProvider: React.FC = ({ children }) => {
  const [selectedSeatId, setSelectedSeatId] = useState<number | string | null>(null);

  return (
    <ModalMapSelectorContext.Provider
      value={{
        selectedSeatId,
        setSelectedSeatId,
      }}
    >
      {children}
    </ModalMapSelectorContext.Provider>
  );
};

export default MapSelectorProvider;
