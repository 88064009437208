import styled from 'styled-components';

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #0002;
  left: 0;
  top: 0;
  z-index: 99;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  top: 30px;
  margin: 0 auto;
  width: 900px;
  @media only screen and (max-width: 820px) {
    width: 100%;
  }
  background-color: white;
  box-shadow: 0px 3px 6px #00000027;
  transition: 1s ease-in-out;
`;

export const Header = styled.div`
  h3{
    font-size: 1.3rem;
  }
  button{
    position: absolute;
    font-size: 1.2rem;
    border: none;
    top: 20px;
    right: 25px;
  }
`;

export const Body = styled.div`
  > div:first-child {
    > div {
      background: none;
      > div{
      }
    }
  }
`;

export const CloseButtom = styled.button`
    height: 26px;
    width: 26px;
    transition: 0.4s;
    cursor: pointer;
    padding: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: var(--primary-gray);
    background-color: transparent;
    &:hover {
        background-color: rgb(33, 74, 47, 0.2);
    }
`;
