import React, { useEffect, useState } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import { Label } from 'reactstrap';
import CircleInformation from 'src/components/CircleInformation';
import Loader from 'src/components/Loader';
import {
  Container,
  FormChild,
  Title,
  FormGroup,
} from './style';
import EmployeeService from '../../../services/EmployeeService';
import { useToast } from '../../../hooks/toast';

interface ConfigurationsProps {
  employeeId: number;
}

interface EmployeeConfigurations {
  companyHasSSO: boolean;
  isAdmin: boolean;
  isLoginSSO: boolean;
}

const Configurations: React.FC<ConfigurationsProps> = ({ employeeId }) => {
  const { t } = useTranslation('components');
  const [employeeConfigurations, setEmployeeConfigurations] = useState<EmployeeConfigurations>({
    companyHasSSO: false,
    isAdmin: false,
    isLoginSSO: false,
  });
  const [shouldShowOptions, setShouldShowOptions] = useState(false);
  const [
    isLoadingChangeEmployeeConfiguration,
    setIsLoadingChangeEmployeeConfiguration,
  ] = useState(false);
  const { addToast } = useToast();

  useEffect(() => {
    EmployeeService.getEmployeeConfigurations(employeeId)
      .then((response) => {
        const { data } = response;

        setEmployeeConfigurations({
          companyHasSSO: data.company_has_sso,
          isAdmin: data.is_admin,
          isLoginSSO: data.is_login_sso,
        });

        setShouldShowOptions(true);
      });
  }, []);

  const onChangeUserAdminPermission = () => {
    setIsLoadingChangeEmployeeConfiguration(true);

    EmployeeService.changeEmployeeConfigurations({
      employeeId,
      isAdmin: !employeeConfigurations.isAdmin,
      isLoginSSO: employeeConfigurations.isLoginSSO,
    }).then(() => {
      setEmployeeConfigurations((prev) => ({
        ...prev,
        isAdmin: !employeeConfigurations.isAdmin,
      }));

      addToast({
        description: t('ModalAdminUser.is_admin_success'),
        type: 'success',
      });
    }).catch(() => {
      addToast({
        description: t('ModalAdminUser.is_admin_fail'),
        type: 'error',
      });
    }).finally(() => {
      setIsLoadingChangeEmployeeConfiguration(false);
    });
  };

  const onChangeSsoPermission = () => {
    setIsLoadingChangeEmployeeConfiguration(true);

    EmployeeService.changeEmployeeConfigurations({
      employeeId,
      isAdmin: employeeConfigurations.isAdmin,
      isLoginSSO: !employeeConfigurations.isLoginSSO,
    }).then(() => {
      setEmployeeConfigurations((prev) => ({
        ...prev,
        isLoginSSO: !employeeConfigurations.isLoginSSO,
      }));

      addToast({
        description: t('ModalAdminUser.is_login_sso_success'),
        type: 'success',
      });
    }).catch(() => {
      addToast({
        description: t('ModalAdminUser.is_login_sso_fail'),
        type: 'error',
      });
    }).finally(() => {
      setIsLoadingChangeEmployeeConfiguration(false);
    });
  };

  return (
    <Container>
      <div className="employee-info w-100 p-5">
        {!shouldShowOptions && (
          <Loader />
        )}
        {shouldShowOptions && employeeConfigurations.isAdmin !== null && (
        <FormGroup>
          <Title>
            {t('ModalAdminUser.permissions_configuration_label')}
          </Title>
          <FormChild>
            <Checkbox
              disabled={isLoadingChangeEmployeeConfiguration}
              id="is_admin"
              name="is_admin"
              color="primary"
              value={employeeConfigurations.isAdmin}
              checked={employeeConfigurations.isAdmin}
              onChange={onChangeUserAdminPermission}
            />
            <Label style={{ margin: 0 }}>
              {t('ModalAdminUser.new_tab.admin_permissions_label')}
            </Label>
            <CircleInformation
              text={t('ModalAdminUser.is_admin_info')}
              id="is-user-admin-info"
            />
          </FormChild>
        </FormGroup>
        )}

        {shouldShowOptions && employeeConfigurations.companyHasSSO && (
          <FormGroup>
            <Title>
              {t('ModalAdminUser.login_configuration_label')}
            </Title>
            <FormChild>
              <Checkbox
                disabled={isLoadingChangeEmployeeConfiguration}
                id="is_login_sso"
                name="is_login_sso"
                value={employeeConfigurations.isLoginSSO}
                color="primary"
                checked={employeeConfigurations.isLoginSSO}
                onChange={onChangeSsoPermission}
              />
              <Label style={{ margin: 0 }}>
                {t('ModalAdminUser.login_sso_option')}
              </Label>
              <CircleInformation
                text={t('ModalAdminUser.is_login_sso_info')}
                id="is-login-sso-info"
              />
            </FormChild>
          </FormGroup>
        )}
      </div>
    </Container>
  );
};

export default Configurations;
