import React, {
  useEffect, useState, useCallback, useRef, /* useContext */
} from 'react';
import icons from 'src/assets/icons';
import Team from 'src/contracts/models/Team';
import {
  List,
  Team as TeamContainer,
  TeamHeader,
  CollapseContainer,
  CollapseIcon,
  TeamLabel,
  LeftContent,
} from './style';
import EmployeeCard from '../EmployeeCard';
import Employee from '../../../contracts/models/Employee';

export interface TeamWithEmployeeCount extends Team {
  employees: Employee[] & { count: number };
}
interface ProfessionalListProps {
  isOpen: boolean;
  teams: TeamWithEmployeeCount[];
  setIsOpenEmployeeEdit: React.Dispatch<React.SetStateAction<boolean>> | undefined,
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const TeamList: React.FC<ProfessionalListProps> = ({
  isOpen,
  setIsOpen,
  teams,
  setIsOpenEmployeeEdit,
}) => {
  const [teamsCollapseOpen, setTeamsCollapseOpen] = useState<boolean[]>(
    Array(teams.length).fill(true),
  );

  const teamRef = useRef(null);

  const handleTeamsCollapse = useCallback((index: number) => {
    setTeamsCollapseOpen((previousTeamCollapseOpen) => {
      const statesCopy = [...previousTeamCollapseOpen];
      statesCopy[index] = !statesCopy[index];
      return statesCopy;
    });
  }, []);

  useEffect(() => {
    setTeamsCollapseOpen(Array(teams.length).fill(true));
  }, [teams]);

  return (
    <List ref={teamRef} id="list-teams">
      {teams.map((team, index) => (
        <TeamContainer>
          <TeamHeader>
            <LeftContent onClick={() => handleTeamsCollapse(index)}>
              <CollapseIcon
                src={icons.CollapseArrow}
                open={teamsCollapseOpen[index]}
              />
              <TeamLabel>
                <span style={{ fontWeight: 'bold' }}>
                  {isOpen ? `${team.name}` : `${team.name.substr(0, 3)}.`}
                </span>
                {isOpen && ` (${team.employees!.length})`}
              </TeamLabel>
            </LeftContent>
          </TeamHeader>
          <CollapseContainer isOpen={teamsCollapseOpen[index]}>
            {team?.employees
              && team.employees.length > 0
              && team.employees!.map((employee) => (
                <EmployeeCard
                  setIsOpen={setIsOpen}
                  isOpen={isOpen}
                  team={team}
                  employee={employee as any}
                  setIsOpenEmployeeEdit={setIsOpenEmployeeEdit}
                />
              ))}
          </CollapseContainer>
        </TeamContainer>
      ))}
    </List>
  );
};

export default TeamList;
