const COLORS = [
  '#f8b2b4',
  '#efcd9f',
  '#fcf0bd',
  '#ccf7ce',
  '#a9c7c9',
  '#b4e6e4',
  '#8eaed0',
  '#b4dbf8',
  '#cfb1d3',
  '#ccbff8',
  '#c9b08b',
  '#f8d8fc',
  '#ffffff',
];

export default COLORS;
