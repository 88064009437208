import api from './api';

export default class AdministrationService {
  static async sendFirstAccessInvite() {
    const response = await api.post('/companies/send/email/all/users');
    return response;
  }

  static async getJobStatusPerId(jobId: number) {
    const response = await api.get(`/jobs/${jobId}`);
    return response;
  }
}
