import { createGlobalStyle } from 'styled-components';
import colors from './colors';

export default createGlobalStyle`


@font-face {
    font-family: 'lato';
         url('./fonts/lato-regular.woff2') format('woff2'),
         url('./fonts/lato-regular.woff') format('woff'),
         url('./fonts/lato-bold.woff2') format('woff2'),
         url('./fonts/lato-bold.woff') format('woff'),
         url('./fonts/lato-bold.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

*{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: lato, sans-serif;
    outline: none;

  &::-webkit-scrollbar {
    background: transparent;
    border-radius: 150px;
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #B3B6B8;
    border-radius: 150px;
  }
  button{
    &:focus{
      outline: none;
    }
  }
  
  font-size: 14px;
  @media(max-width: 1500px){
    font-size: 13px;
  }

  table { 
    -moz-user-select: none;
    user-select: none;
     }
}

.fade-in {
  @keyframes fade-in {
    0% {
      opacity: 0
    }
    100% {
      opacity: 1
    }
  }
  animation: fade-in 0.5s;
}

.roll-out {
  @keyframes rollout {
    0% {
      transform: translateY(-100px);
    }
    100% {
      transform: translateY(0);
    }
  }
  animation: rollout 0.5s;
}

.container-spinner{
    width: 100%;
    height: 100%;
    background: ${colors.white13};
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    
    .spinner-border {
        width: 4rem;
        height: 4rem;
        animation: spinner-border 1.5s linear infinite;
    }
}

.invert-y{
  transform: rotate(180deg);
  transition: transform .3s ease-in;
}

  [aria-disabled="true"]: {
    opacity: .5;
  }

  .hidden {
    transition: .3s;
    display: none;
  }


  .modal-semifullscreen {
    max-width: none;
    margin: 0;
    height: 100vh !important;
    width: calc(100vw - 270px);
    margin-left: 270px;
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

    @media (max-width: 1400px) {
      width: 100vw;
      margin-left: 0px;
    }
  }
  
  .modal-fullscreen {
    max-width: none;
    margin: 0;
    height: 100vh !important;
    width: 100vw;
    z-index: 98 !important;
  }

:root{
    --primary: ${colors.blue};
    --primary-dark: ${colors.blue80};
    --primary-gray: ${colors.gray};
    --secondary-gray: ${colors.gray90};
    --terciary-gray: ${colors.gray20};
    --quaternary-gray: ${colors.gray25};
    --calendar--border: ${colors.gray30};
    --bg-light: ${colors.white5};
    --border-light: ${colors.white8};
    --edit-not-allowed-border: ${colors.gray28};
    --edit-not-allowed-bg: ${colors.white10};
    --edit-not-allowed-color: ${colors.gray30};
    --shift-vacancy-border: ${colors.blue};
    --system-blue: ${colors.blue};
    --background-field: ${colors.white10};
    --striped: ${colors.white13};
}
.MuiCheckbox-colorPrimary-2422.Mui-checked {
  color: ${colors.blue} !important;
}
.dropdown-item.active, .dropdown-item:active {
  background-color: ${colors.blue} !important;
}
.text-primary {
  color: ${colors.blue} !important;
}
.margin-menu-open {
  transition: 0.3s;
  margin-left: 190px;
  @media only screen and (max-width: 768px) {
    margin-left: 55px;
  }
}
.margin-menu-close {
  transition: 0.3s;
  margin-left: 55px;
}
.pointer-menu-event {
  pointer-events: none;
}
.__floater__open {
  margin: 0 10px;
}
.d-none-mobile {
  @media only screen and (max-width: 821px) {
    display: none !important;
  }
}
.sidebar-container {
  position: fixed;
  grid-area: pb;
  height: calc(100vh);
  z-index: 98;
  @media only screen and (max-width: 768px) {
    padding-top: 63.40px;
    width: 100%;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
  }
  margin-left: 55px;
}
.sidebar-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  width: 85%;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  img {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    filter: invert(51%) sepia(6%) saturate(1025%) hue-rotate(190deg) brightness(89%) contrast(82%);
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
  button {
    color: var(--secondary-gray);
    font: normal normal bold 13px/15px lato;
  }
}
.modal-min-width-800 {
  @media only screen and (min-width: 820px) {
    min-width: 800px;
  }
}

.button-min-width span {
  min-width: 90px;
}
.margin-left-sidebar-is-open {
  @media only screen and (min-width: 820px) {
    margin-left: 200px !important;
    transition: 0.3s;
  }
}
.margin-left-sidebar-is-close {
  @media only screen and (min-width: 820px) {
    margin-left: 10px !important;
    transition: 0.3s;
  }
}
.filter-primary-color {
  filter: invert(12%) sepia(39%) saturate(3578%) hue-rotate(215deg) brightness(50%) contrast(119%);
}
.filter-secondary-color {
  img {
    filter: invert(50%) sepia(77%) saturate(7451%) hue-rotate(214deg) brightness(30%) contrast(119%);
  }
  :hover {
    img {
      filter: brightness(106%);
    }
  }
}
.filter-white {
  img {
    filter: brightness(1000%);
  }
}
.background-blue-10 {
  background: #F2F4FF !important;
}
.color-blue {
  color: ${colors.blue};
  :hover {
    color: white;
  }
}
`;
