import React, { useContext, useEffect, useState } from 'react';
import { endOfWeek } from 'date-fns';
import { useTranslation } from 'react-i18next';
import PlaceAnnouncementLot from './PlaceAnnouncementLot';
import PlaceAnnouncementAuto from './PlaceAnnouncementAuto';
import PlaceAnnouncement from './PlaceAnnouncement';
import Option from '../../contracts/models/Option';
import { homeContext } from '../../pages/Home/Context';
import {
  Overlay,
  Container,
  ContainerOutside,
} from './style';
import icons from '../../assets/icons';

interface ModalPlaceAnnouncementProps {
  isOpen: boolean;
  selectedTeamsDefault: Option[];
  handleCloseButton: () => void;
}

const ModalPlaceAnnouncement: React.FC<ModalPlaceAnnouncementProps> = ({
  isOpen,
  selectedTeamsDefault,
  handleCloseButton,
}) => {
  const { t } = useTranslation('components');
  const {
    locations, week,
  } = useContext(homeContext);
  const [OnPlaceAnnouncementLot, setOnPlaceAnnouncementLot] = useState(true);
  const [OnPlaceAnnouncementAuto, setOnPlaceAnnouncementAuto] = useState(false);

  const onClickBackButton = () => {
    setOnPlaceAnnouncementLot(false);
    setOnPlaceAnnouncementAuto(false);
  };

  const onCloseModal = () => {
    setOnPlaceAnnouncementAuto(false);
    handleCloseButton();
  };

  const onClickOpenAnnouncementAuto = () => {
    setOnPlaceAnnouncementAuto(true);
  };
  const onClickOpenAnnouncementLot = () => {
    setOnPlaceAnnouncementLot(true);
  };

  const escFunction = (event: any) => {
    const escKey = 27;
    if (event.keyCode === escKey) {
      onCloseModal();
    }
  };

  const choosePlaceAnnouncementComponent = () => {
    if (OnPlaceAnnouncementLot) {
      return (
        <PlaceAnnouncementLot
          locations={locations}
          onSuccess={() => {
            handleCloseButton();
          }}
          onClickBackButton={onClickBackButton}
        />
      );
    } if (OnPlaceAnnouncementAuto) {
      return (
        <PlaceAnnouncementAuto
          selectedTeamsDefault={selectedTeamsDefault}
          locations={locations}
          period={{ from: week, to: endOfWeek(week, { weekStartsOn: 1 }) }}
          onClickBackButton={onClickBackButton}
        />
      );
    }
    return (
      <div>
        <PlaceAnnouncement
          onClickOpenAnnouncementAuto={onClickOpenAnnouncementAuto}
          onClickOpenAnnouncementLot={onClickOpenAnnouncementLot}
        />
      </div>
    );
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <Overlay isOpen={isOpen}>
      <ContainerOutside
        onClick={() => onCloseModal()}
        className="d-flex flex-column"
      />
      <Container
        isOpen={isOpen}
        className="d-flex flex-column"
      >
        <button type="button" className="close-button" onClick={handleCloseButton}>
          <img src={icons.close} className="logo" alt={t('ModalEditUser.close')} />
        </button>

        {choosePlaceAnnouncementComponent()}

      </Container>
    </Overlay>
  );
};

export default ModalPlaceAnnouncement;
