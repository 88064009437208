import LogoEscalaEspacos from 'src/assets/images/escala-escacos_logo.svg';
import LogoEscalaEspacosBranco from 'src/assets/images/logo-espacos-branco.svg';
import LogoEscalaEspacosMin from 'src/assets/images/logo-espacos-min.svg';
import BackgroundOnboarding from 'src/assets/images/bg_onboarding.png';
import CreateLocation from 'src/assets/images/createLocation.gif';
import CreateSpaceGif from 'src/assets/images/CriarSpace.gif';
import Invite from 'src/assets/images/invite.gif';
import Location from 'src/assets/images/location.gif';
import HomeOnboarding from 'src/assets/images/homeOnboarding.png';

const images = {
  LogoEscalaEspacos,
  LogoEscalaEspacosBranco,
  LogoEscalaEspacosMin,
  BackgroundOnboarding,
  CreateLocation,
  CreateSpaceGif,
  Invite,
  Location,
  HomeOnboarding,
};

export default images;
