import styled from 'styled-components';
import { Collapse } from 'reactstrap';

interface SideBarProps {
    open?: Boolean;
    color?: string;
}

export const List = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar { display: none;}
    -ms-overflow-style: none;  
    scrollbar-width: none;  
    cursor: pointer;
`;

export const Team = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
`;

export const TeamHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 100%;
    border: none;
`;

export const LeftContent = styled.button`
    display: flex;
    align-items: center;
    justify-content: end;
    border: none;
    padding:0;
    margin:0;
    background-color: transparent;
    :focus {
        outline: none;
    }
`;

export const CollapseIcon = styled.img<SideBarProps>`
    transform: ${(props) => (props.open ? 'rotate(360deg)' : 'rotate(270deg)')};
    transition: 0.3s;
`;

export const CollapseContainer = styled(Collapse)``;

export const TeamLabel = styled.span`
    color: #3B4252 ;
`;

export const MoreOptions = styled.img``;
