import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'src/components/ButtonLoader';
import EmployeeService from 'src/services/EmployeeService';
import Employee from 'src/contracts/models/Employee';
import PlacementDetails from './PlacementDetails';
import { useToast } from '../../hooks/toast';
import {
  Overlay,
  Container,
  ContainerOutside,
  Header,
  Body,
  IconContainer,
  EmptyContainer,
  EmptyText,
} from './style';
import icons from '../../assets/icons';

interface ModalLimitReservationPerEmployeeProps {
  isOpen: boolean;
  teamId: number;
  employee: Employee;
  handleCloseButton: () => void;
}

const ModalLimitReservationPerEmployee: React.FC<ModalLimitReservationPerEmployeeProps> = ({
  isOpen,
  teamId,
  employee,
  handleCloseButton,
}) => {
  const { t } = useTranslation('components');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [updatedPlacements, setUpdatedPlacements] = useState<Array<any>>([]);
  const [configs, setConfigs] = useState<Array<any>>([]);
  const { addToast } = useToast();

  const onCloseModal = () => {
    handleCloseButton();
  };

  const escFunction = (event: any) => {
    const escKey = 27;
    if (event.keyCode === escKey) {
      onCloseModal();
    }
  };

  const getConfigs = () => {
    EmployeeService.getConfigPlacementsRules({
      idEmployee: employee.id,
      idTeam: teamId,
    }).then((res) => {
      setConfigs(Object.values(res.data));
    });
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  useEffect(() => {
    getConfigs();
  }, []);

  const existsOnList = (editedPlacement: any) => {
    const exist = updatedPlacements.find((p) => p.placement_id === editedPlacement.placement_id);
    return !!exist;
  };

  const updateList = (
    editedPlacement: any,
  ) => {
    if (editedPlacement) {
      const updatedTeams = updatedPlacements.map((p) => (
        p.placement_id === editedPlacement.placement_id
          ? {
            ...p,
            type_reservation: editedPlacement.type_reservation,
            max_reservation: editedPlacement.max_reservation,
          }
          : p));
      setUpdatedPlacements(updatedTeams);
    }
  };

  const handleChange = (editedPlacement: any) => {
    if (existsOnList(editedPlacement)) {
      updateList(editedPlacement);
    } else {
      setUpdatedPlacements([...updatedPlacements,
        {
          ...editedPlacement,
          team_id: teamId,
          employee_id: employee.id,
        },
      ]);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    EmployeeService.configPlacementsRule(updatedPlacements).then(() => {
      setIsLoading(false);
      addToast({
        description: t('ModalLimitReservationPerEmployee.update_success'),
        type: 'success',
      });
      handleCloseButton();
    }).catch(() => {
      setIsLoading(false);
      addToast({
        description: t('ModalLimitReservationPerEmployee.update_error'),
        type: 'error',
      });
    });
  };

  return (
    <Overlay isOpen={isOpen}>
      <ContainerOutside
        onClick={() => onCloseModal()}
        className="d-flex flex-column"
      />
      <Container
        isOpen={isOpen}
        className="d-flex flex-column"
      >
        <button type="button" className="close-button" onClick={handleCloseButton}>
          <img src={icons.close} className="logo" alt={t('ModalEditUser.close')} />
        </button>
        <Header className="d-flex align-items-center px-5 py-4">
          <h5 className="mb-1">
            {t('ModalLimitReservationPerEmployee.title')}
          </h5>
        </Header>
        <hr className="mt-0" />
        <Body className=" px-5">
          {configs.map((item) => (
            <PlacementDetails
              onChange={handleChange}
              item={item}
            />
          ))}

          {configs.length === 0 && !isLoading && (
            <EmptyContainer>
              <EmptyText>
                {t('ModalLimitReservationPerEmployee.empty_message')}
              </EmptyText>
            </EmptyContainer>
          )}
        </Body>
        <IconContainer className="py-5 px-5">
          <ButtonLoader
            bordered
            style={{ minWidth: 120 }}
            label={t('ModalLimitReservationPerEmployee.cancel')}
            onClick={() => handleCloseButton()}
          />
          <ButtonLoader
            style={{ minWidth: 120, marginLeft: 10 }}
            isLoading={isLoading}
            label={t('ModalLimitReservationPerEmployee.save')}
            onClick={() => handleSubmit()}
          />
        </IconContainer>
      </Container>
    </Overlay>
  );
};

export default ModalLimitReservationPerEmployee;
