import React from 'react';
import { useTranslation } from 'react-i18next';
import ICONS from 'src/assets/icons';
import CustomSideBarTab from '../../components/CustomSideBarTab';
import OccupationReportsTab from './tabs/OccupationReports';
import CheckinReportsTab from './tabs/CheckinReports';
import ShiftsReportsTab from './tabs/ShiftsReports';
import MainNavBar from '../../components/MainNavBar';
import { useGlobal } from '../../hooks/useGlobal';

export default function Reports() {
  const { sideMenuIsOpen } = useGlobal();

  const { t } = useTranslation('pages');

  const tabs = [
    {
      label: t('Reports.tabs.occupation.label'),
      Tab: OccupationReportsTab,
      icon: ICONS.BarChart,
    },
    {
      label: t('Reports.tabs.checkin.label'),
      Tab: CheckinReportsTab,
      icon: ICONS.CheckinClock,
    },
    {
      label: t('Reports.tabs.shifts.label'),
      Tab: ShiftsReportsTab,
      icon: ICONS.calendarCheck,
    },
  ];

  return (
    <>
      <MainNavBar />
      <CustomSideBarTab tabs={tabs} className={sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'} />
    </>
  );
}
