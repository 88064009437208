/* eslint-disable import/no-duplicates */
import { createPopper, Instance } from '@popperjs/core';
import React, {
  useContext, useEffect, useState, useMemo,
} from 'react';
import reactDOM from 'react-dom';
import { useToast } from 'src/hooks/toast';
import { useTranslation } from 'react-i18next';
import {
  format,
  parse,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import ButtonLoader from 'src/components/ButtonLoader';
import { homeContext } from 'src/pages/Home/Context';
import ShiftService from 'src/services/ShiftService';
import ModalConfirm from '../ConfirmModal';
import './arrowPosition.css';
import {
  Body,
  Container,
  DarkBg,
  Footer,
  Header,
  TeamsContainer,
  TeamText,
  TeamTitle,
} from './style';

interface ModalDeleteAnnoucementProps {
  modalData: {
    announcement?: any;
    dayName?: string;
  };
  onClose: Function;
  onSuccess: Function;
};
const ModalDeleteAnnoucement: React.FC<ModalDeleteAnnoucementProps> = ({
  modalData,
  onSuccess,
  onClose,
}) => {
  const { t } = useTranslation('components');
  const { addToast } = useToast();
  const { announcement } = modalData;
  const { placement, loadCalendar } = useContext(homeContext);
  const [loading, setLoading] = useState(false);

  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const formatDate = (date: string) => {
    const day = parse(date, 'yyyy-MM-dd', new Date());
    const dayFormatted = format(day, 'dd/MM/yyyy', {
      locale: ptBR,
    }).toUpperCase();
    return dayFormatted;
  };

  const handleCloseButton = () => {
    const closeModal = () => {
      onClose({});
      setIsOpenConfirm(false);
      setLoading(false);
    };
    closeModal();
  };

  const teams = useMemo(() => {
    const announcementTeams = announcement?.teams as Array<any> || [];
    const announcementTeamsIds = announcementTeams.map((team) => Number(team.team_id));
    return placement ? placement.teams!.filter(
      (team) => announcementTeamsIds.includes(Number(team.id)),
    ) : [];
  }, [placement, modalData]);

  useEffect(() => {
    const closeOnEsc = (evt: any) => {
      if (evt.key === 'Escape' && document.getElementById('darkBGAnnouncement')) {
        handleCloseButton();
      }
    };

    const closeOnBlur = (evt: any) => {
      if (evt.target?.id === 'darkBGAnnouncement') {
        handleCloseButton();
      }
    };
    document.addEventListener('keydown', closeOnEsc);
    document.addEventListener('click', closeOnBlur);

    return () => {
      document.removeEventListener('keydown', closeOnEsc);
      document.removeEventListener('click', closeOnBlur);
    };
  });

  useEffect(() => {
    let popper: Instance;
    if (Object.keys(modalData).length) {
      const shiftReference = document.getElementById(
        announcement?.code as string,
      )!;
      const modalReference = document.getElementById('tooltipAnnouncement')!;

      popper = createPopper(shiftReference!, modalReference!, {
        placement: 'right-start',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
        ],
      });
    }
    return () => {
      popper?.destroy();
    };
  }, [announcement]);

  const handleExcludeAnnouncement = async () => {
    setIsOpenConfirm(true);
  };

  const onModalConfirmClick = async () => {
    let teamsIds: string = '';
    const announcementTeams = announcement?.teams as Array<any> || [];
    announcementTeams.forEach((team, index) => {
      if (index === 0) teamsIds += `${team.team_id}`;
      else teamsIds += `,${team.team_id}`;
    });

    ShiftService.deleteAnnouncement(announcement.id, teamsIds).then(() => {
      setIsOpenConfirm(false);
      addToast({
        description: t('ModalDeleteAnnoucement.success'),
        type: 'success',
      });
      loadCalendar?.();
      onSuccess(modalData);
    }).catch(() => {
      setIsOpenConfirm(false);
      addToast({
        description: t('ModalDeleteAnnoucement.error'),
        type: 'error',
      });
    });
  };

  const onModalCloseClick = () => {
    setIsOpenConfirm(false);
  };

  return Object.keys(modalData).length ? (
    reactDOM.createPortal(
      <DarkBg id="darkBGAnnouncement" isOpen>
        {reactDOM.createPortal(
          <Container
            role="tooltip"
            isOpen
            className="container p-4"
            id="tooltipAnnouncement"
          >
            <Header className="row">
              <div className="col-11">
                {t('ModalDeleteAnnoucement.title')}
              </div>
              <button
                className="col-1"
                type="button"
                onClick={handleCloseButton}
              >
                ×
              </button>
            </Header>
            <Body className="row">
              <div className="container">
                <TeamsContainer>
                  <TeamText>
                    {t('ModalDeleteAnnoucement.date_label')}
                    :
                    {formatDate(announcement.allocatedAt)}
                  </TeamText>
                  <TeamTitle>
                    {t('ModalDeleteAnnoucement.team_label')}
                    :
                  </TeamTitle>
                  {teams && teams.map((team) => <TeamText>{team.name}</TeamText>)}
                  {teams.length === 0 && <TeamText>{t('Announcement.all_teams_announcement')}</TeamText>}
                </TeamsContainer>
              </div>
            </Body>
            <Footer className="row mt-2">
              <div className="col-12 d-flex justify-content-between">
                <ButtonLoader
                  transparent
                  bordered
                  label={t('ModalDeleteAnnoucement.cancel')}
                  onClick={handleCloseButton}
                  withHover={false}
                />
                <ButtonLoader
                  isLoading={loading}
                  label={t('ModalDeleteAnnoucement.delete')}
                  onClick={handleExcludeAnnouncement}
                />
              </div>
            </Footer>
            <div id="arrow" data-popper-arrow />
          </Container>,
          document.getElementById(announcement?.code as string)!,
        )}
        {isOpenConfirm && (
        <ModalConfirm
          isOpen={isOpenConfirm}
          handleConfirm={onModalConfirmClick}
          handleCloseButton={onModalCloseClick}
          title={t('ModalDeleteAnnoucement.confirm_modal.title')}
          message={t('ModalDeleteAnnoucement.confirm_modal.message')}
        />
        )}
      </DarkBg>,
      document.getElementById('root')!,
    )
  ) : (
    <></>
  );
};

export default ({
  modalData,
  onSuccess,
  onClose,
}: ModalDeleteAnnoucementProps) => (
  <ModalDeleteAnnoucement
    modalData={modalData}
    onClose={onClose}
    onSuccess={onSuccess}
  />
);
