import styled from 'styled-components';
import colors from '../../colors';

interface TabProps {
    active: boolean;
}
export const Screen = styled.div`
    display: flex;
    height: calc(100vh - 64px);
    flex-direction: row;
`;

export const SideBar = styled.div`
    position: fixed;
    height: 100vh;
    width: 270px;
    background-color: ${colors.white};
    border: 1px solid ${colors.white15};
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
`;

export const Body = styled.div`
    display: flex;
    width: 100vw;
    overflow-y: auto;
    margin-left: 270px;
`;

export const Tab = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    width: 85%;
    border-bottom: 1px solid ${colors.white20};
    cursor: pointer;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 

`;
export const TabIcon = styled.img<TabProps>`
    height: 16px;
    width: 16px;
    margin-right: 10px;
    filter: ${(props) => (props.active
    ? 'brightness(0) saturate(100%) invert(7%) sepia(31%) saturate(7050%) hue-rotate(224deg) brightness(109%) contrast(113%);'
    : 'invert(51%) sepia(6%) saturate(1025%) hue-rotate(190deg) brightness(89%) contrast(82%)')};
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
`;
export const TabLabel = styled.div<TabProps>`
     font: normal normal bold 13px/15px lato;
     color: ${(props) => (props.active ? 'var(--system-blue)' : 'var(--secondary-gray)')};
`;
