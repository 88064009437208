import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  
  .edit-button{
    position: relative;
    bottom: 24px;
    padding: 0;
    
    img{
      width: 24px;
    }
    span{
      display: none;
    }
    
    &:focus{
      box-shadow: none;
    }
  }
`;

export const ProfessionalImage = styled.img`
    border-radius: 184px;    
    width: 184px;
    height: 184px;
    object-fit: cover;
    border: solid 2px #fff;
`;

export const ProfessionalNonImage = styled.div`
    border-radius: 184px;    
    width: 184px;
    height: 184px;
    background-color: #676C7D;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 50px;
`;

export const Forms = styled.form`
`;

export const FormChild = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  
  button{
    height: 45px;
    width: 100px;
  }
`;

export const InputLabel = styled.span`
  color: #727789;
  font: normal normal 600 12px/19px lato;
  max-width: 100px;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 35px;
  color: var(--primary-gray);
  text-align: left;
  padding-left: 10px;
  border: 1px solid #e8e8e8;

    :invalid {
        box-shadow: 0 0 0.5em red;
        border-color: red;
    }
`;

export const LoaderButton = styled.img`
    height: 25px;
    width: 25px !important;
    filter: brightness(1.5);
`;
