import styled, { css, CSSProperties } from 'styled-components';
import BackgroundOnboarding from '../../assets/images/bg_onboarding.png';
import colors from '../../colors';

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: absolute;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;

export const BackgroundImage = styled.div`
  background: url(${BackgroundOnboarding});
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(3px);
  -webkit-filter: blur(3px);
  width: 100vw;
  height: 100%;
  position: fixed;
`;

export const Blur = styled.div`
  filter: blur(3px);
  -webkit-filter: blur(3px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgb(25, 32, 56, 0.4);
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: -webkit-fill-available;
  min-height: -webkit-fill-available;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  @media (max-width: 767px) {
    height: auto!important;
  }
`;

export const CardContainer = css`
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 3px 6px #00000027;
  transition: 1s ease-in-out;
`;

export const InputContainer = styled.div`
`;

export const DefaultContainer = styled.div`
  padding: 5%;
  width: 100%;
`;

export const Content = styled.div`
  background-color: white;
  width: 100%;
  height: 100vh;
  padding: 20%;
`;

export const Title = styled.h1`
  font-size: 30px;
  color: #3B4252;
  text-align: center;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

export const Text = styled.p`
  font-size: 20px;
  color: #3B4252;
  text-align: center;
`;

export const AlternativeText = styled.p`
  font-size: 16px;
  color: #3B4252;
  text-align: left;
  margin: 1.5vw 0 0 0;
`;

export const Paragraph = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #626363;

  @media (max-width: 767px) {
    font-size: 12px;
  }
`;

export const ImageCalendar = styled.img`
  width: 100%;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  height: 100%;
`;

export const ButtonContainer = styled.div`
  text-align: right;
`;

export const ContainerInput = styled.div`
  display: flex;
  margin-bottom: 30px;
  margin-top: 20px;
  span {
    color: var(--secondary-grey);
    font-size: 0.875rem;
    margin: auto;
    margin-left: 0;
  }

  @media (max-width: 767px) {
    display: block;

    .quantity {
      margin-bottom: 20px;
    }
    .map-input {
      padding-left: 0!important;
    }
    .quantity input {
      width: 100%;
    }
  }
`;

export const LabelSelectStyle: CSSProperties = {
  marginTop: '10px',
  color: '#3B4252',
  fontSize: '16px',
};

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5%;
`;

export const ActionTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: ${colors.blue};
`;

export const ActionText = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: ${colors.blue};
  text-align: left;
  margin-left: 5%;
`;
