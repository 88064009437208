import styled from 'styled-components';
import colors from '../../colors';

interface SideBarProps {
  isOpen?: boolean;
}

export const Container = styled.main<SideBarProps>`
  margin-left: ${(props) => (props.isOpen ? '245px' : '86px')};
  transition: 0.3s;
  height: calc(100vh - 64px);
  @media only screen and (max-width: 768px) {
    padding-top: 99px; 
  }
`;

export const AchievementsContainer = styled.div`
  float: right;
  width: 100%;
`;

export const LearnAndBeInspiredContainer = styled.div`
  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
    color: ${colors.gray10};
  }
`;

export const CardPanel = styled.div`
  padding: 15px 0 15px 0;
  background: ${colors.white};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  height: auto;
  
  .itens-accordion {
    background: #F2F4FF;
  }
`;

export const ContainerUserPanel = styled.div`
  margin: 15px;
  margin-left: 30px;
  border-radius: 10px;
`;

export const Button = styled.button`
  padding: 4px 8px;
  background: #000E4C;
  border: 1px solid #000E4C;
  border-radius: 3.2px;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
  margin: auto;
  margin-right: 0;
`;

export const ItensName = styled.p`
  margin-bottom: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #626363;
`;
