import { useTranslation } from 'react-i18next';

interface UseDaysOptions {
  onlyFirstLetter?: boolean;
  capitalize?: boolean;
}
export default function useDays(options?: UseDaysOptions) {
  const { t } = useTranslation('generic');
  const dayNames = [
    { name: t('days.sunday'), id: 6 },
    { name: t('days.monday'), id: 0 },
    { name: t('days.tuesday'), id: 1 },
    { name: t('days.wednesday'), id: 2 },
    { name: t('days.thusday'), id: 3 },
    { name: t('days.friday'), id: 4 },
    { name: t('days.saturday'), id: 5 },
  ];
  let formattedDays = dayNames;
  if (options?.onlyFirstLetter) {
    formattedDays = formattedDays.map((day) => ({
      ...day,
      name: day.name[0],
    }));
  }
  if (options?.capitalize) {
    formattedDays = formattedDays.map((day) => ({
      ...day,
      name: `${day.name.slice(0, 1).toUpperCase()}${day.name.slice(
        1,
        day.name.length,
      )}`,
    }));
  }
  return formattedDays;
}
