import { useTranslation } from 'react-i18next';

interface UseMonthsOptions {
  onlyFirstLetter?: boolean;
  capitalize: boolean;
}
export default function useDays(options?: UseMonthsOptions) {
  const { t } = useTranslation('generic');
  const monthNames = [
    t('months.january'),
    t('months.february'),
    t('months.march'),
    t('months.april'),
    t('months.may'),
    t('months.june'),
    t('months.july'),
    t('months.august'),
    t('months.september'),
    t('months.october'),
    t('months.november'),
    t('months.december'),
  ];
  let formattedMonths = monthNames.map((month, index) => ({
    id: index,
    name: month,
  }));

  if (options?.onlyFirstLetter) {
    formattedMonths = monthNames.map((monthName, index) => ({
      id: index,
      name: monthName[0].toUpperCase(),
    }));
  }

  if (options?.capitalize) {
    formattedMonths = formattedMonths.map((month) => ({
      ...month,
      name: `${month.name.slice(0, 1).toUpperCase()}${month.name.slice(
        1,
        month.name.length,
      )}`,
    }));
  }
  return formattedMonths;
}
