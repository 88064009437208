/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { getToken, logout } from './auth';

const api = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}spaces/`,
});

api.interceptors.response.use(
  (response) => {
    localStorage.removeItem('PAYMENT_REQUIRED');
    return response;
  },
  (error) => {
    if (error?.response?.status === 402) {
      localStorage.setItem('PAYMENT_REQUIRED', 'true');
    }
    if (error?.response?.status !== 402) {
      localStorage.removeItem('PAYMENT_REQUIRED');
    }
    throw error;
  },
);

api.interceptors.request.use((config) => {
  const token = getToken();
  if (token) config.headers.Authorization = `Bearer ${token}`;
  config.headers.common['User-Client'] = 'WEB';
  if (process.env.REACT_APP_ENV !== 'PROD') {
    config.params = { ...config.params, XDEBUG_SESSION_START: 'DEBUG_CLIENT' };
  }
  return config;
});

const unauthentcatedUrls = [
  'user/login',
  'password/reset',
];

const logoutRequest = async () => { await api.post('user/logout').then(() => logout(true)).catch(() => logout(true)); };

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error?.response?.status === 401
      && !unauthentcatedUrls.includes(error?.response?.config.url)) {
      logout(true);
      logoutRequest();
    }
    return Promise.reject(error);
  },
);

export default api;
