import React, { FC, useState } from 'react';
import Joyride, {
  CallBackProps, STATUS,
} from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { Button, Skip } from '../GuidedOnboarding/style';
import icons from '../../assets/icons';
import EmployeeService from '../../services/EmployeeService';
import { addData } from '../../services/LocalStorageService';

interface props {
  run: boolean;
  setRun: React.Dispatch<React.SetStateAction<boolean>>;
  steps: any;
  actualStep: string,
  nextStep: string,
}

const Guidance:FC<props> = ({
  run, setRun, steps, actualStep, nextStep,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('components');

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setIsLoading(true);
      EmployeeService.updateEmployeeGuideTutorial(actualStep, nextStep)
        .then((response) => addData('espacos@guide', response.data))
        .finally(() => setIsLoading(false));
      const element = document.querySelector(`#${actualStep}-menu`);
      if (element) element.classList.remove('animation-focus-item');
      return setRun(false);
    }
    return true;
  };

  const locale = {
    skip: <Skip disabled={isLoading} aria-label="skip">{t('Guidance.Button.skip')}</Skip>,
    back: (
      <Button disabled={isLoading} type="button" className="bg-white" back>
        <img className="me-2" src={icons.backIconArrow} alt="Back" />
        {t('Guidance.Button.back')}
      </Button>),
    next: (
      <Button disabled={isLoading} type="button">
        {t('Guidance.Button.next')}
        <img className="ms-2" src={icons.nextIcon} alt="Next" />
      </Button>),
  };

  return (
    <Joyride
      locale={locale}
      callback={(data) => handleJoyrideCallback(data)}
      continuous
      hideCloseButton={false}
      run={run}
      scrollToFirstStep
      showProgress={false}
      showSkipButton
      disableOverlayClose
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
          width: 550,
        },
        buttonNext: {
          background: 'transparent',
        },
        buttonClose: {
          width: 15,
          height: 15,
          background: '#00D4A9',
          borderRadius: '100%',
          right: -20,
          top: -15,
          color: 'white',
        },
      }}
    />
  );
};

export default Guidance;
