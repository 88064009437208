import React, { useMemo, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { homeContext } from 'src/pages/Home/Context';
import { AnnouncementContainer, AnnouncementTagContainer, AnnouncementTag } from './style';

interface AnnouncementProps {
  shiftData: any;
  onDoubleClick: ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void);
}

function Announcement({ shiftData, onDoubleClick }: AnnouncementProps) {
  const { t } = useTranslation('components');
  const { placement } = useContext(homeContext);

  const teams = useMemo(() => {
    const announcementTeams = shiftData?.teams as Array<any> || [];
    const announcementTeamsIds = announcementTeams.map((team) => Number(team.team_id));
    return placement ? placement.teams!.filter(
      (team) => announcementTeamsIds.includes(Number(team.id)),
    ) : [];
  }, [placement, shiftData]);

  return (
    <AnnouncementContainer onDoubleClick={onDoubleClick} className="bordered cell">
      {t('Announcement.available_announcement')}
      <AnnouncementTagContainer>
        {teams && teams.slice(0, 2).map((team) => (
          <AnnouncementTag>
            <span>{team.name}</span>
          </AnnouncementTag>
        ))}
        {teams && teams.length > 2 && <AnnouncementTag>. . .</AnnouncementTag>}
        {teams.length === 0 && <AnnouncementTag>{t('Announcement.all_teams_announcement')}</AnnouncementTag>}
      </AnnouncementTagContainer>
    </AnnouncementContainer>
  );
}

export default Announcement;
