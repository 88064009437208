import styled from 'styled-components';
import colors from '../../colors';

interface Props {
  isTheCurrent?: boolean;
}

export const Card = styled.div`
  margin-bottom: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  word-wrap: break-word;
  background-color: ${colors.white};
  background-clip: border-box;
  margin-top: 50px;
  &:hover {
    border: 1px solid ${colors.green30};
    margin-top: 0;
    #plan-price {
      color: ${colors.green30} !important;
    }
    #plan-card-header {
      display: flex !important;
    }
    #plan-confirm-btn {
      background: ${colors.green30};
    }
    #plan-info {
      background: ${colors.green30};
      span {
        color: ${colors.white};
      }
    }
  }
  border-radius: 1rem;
`;

export const PlanCardHeader = styled.div`
  display: none;
  justify-content: center;
  height: 50px;
  
  font-weight: 700;
  font-size: 19px;
  line-height: 23px;
  
  align-items: center;
  text-align: center;
  color: #000B4C;
  border-bottom: 1px solid ${colors.green30};
`;

export const BodyPlanCard = styled.div`
  min-height: 700px;
  background: #F5F1FE;
  border-radius: 10px;
  margin: 15px 15px 15px 15px;
  padding: 20px 20px 20px 20px;
`;

export const PlanTitle = styled.h5`
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: #000B4C;
  
  justify-content: space-between;
`;

export const PlanPrice = styled(PlanTitle)`
  margin-top: 10px;
  padding-bottom: 20px;
  margin-bottom: 30px;
  font-size: 52px;
  @media only screen and (max-width: 1150px) {
    font-size: 35px !important;
  }
  span {
    margin-top: 20px;
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
  }
  justify-content: start;
  box-shadow: 0 11px 13px rgba(124, 81, 219, 0.03);
`;

export const ProductInfo = styled.div`
  background: #E9E3FB;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 27px;
  border-radius: 4px;
  margin-right: 12px;
  
  span {
    font-size: 22px;
    color: #000B4C;
  }
`;

export const Text = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #000B4C;
`;

export const PlanButton = styled.button<Props>`
  width: 100%;
  height: 45px;
  border-radius: 5px;
  margin-top: 40px;
  border: 0;
  padding-left: 27px;
  padding-right: 27px;
  background: #000B4C;
  
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.white};
  
  img {
    position: absolute;
    right: 0;
    padding-right: 50px;
    padding-top: 5px;
  }
`;

export const ContentPlanBox = styled.div`
  margin: 15px 5px 15px 0;
  font-weight: 400;
  font-size: 17px;
  line-height: 21px;

  display: flex;
  align-items: center;

  color: #000B4C;
`;

export const ContentPlanIcon = styled.div`
  width: 28px;
  height: 28px;
  margin-right: 10px;
  background: rgba(198, 184, 245, 0.3);
  border-radius: 5em;
  display: flex;
  justify-content: center;
  
  img {
    width: 20px;
    filter: invert(71%) sepia(30%) saturate(5326%) hue-rotate(121deg) brightness(95%) contrast(102%);
  }
`;

export const ComingSoon = styled.span`
  display: flex;
  justify-content: center;
  color: ${colors.green30};
  margin-left: 15px;

  width: 100px;
  height: 20px;

  background: rgba(124, 81, 219, 0.13);
  border-radius: 3px;
`;
