import { Slider } from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Container, FormGroup, Input, Row,
} from 'reactstrap';
import { AddLocationContext } from 'src/pages/AddLocation/Context';
import { List } from './style';

const RangeInputSeatsSize: React.FC = () => {
  const { t } = useTranslation('pages');
  const { radius, handleSetRadius } = useContext(AddLocationContext);

  const handleChange = (event: any, newValue: number | number[]) => {
    handleSetRadius(newValue as number);
  };

  return (
    <List>
      <Container className="mt-4">
        <Row>
          <Col>
            <label htmlFor="seat_size">
              {t('AddLocation.tabs.AddPosition.input_size')}
            </label>
          </Col>
        </Row>
        <Row>
          <Col md="9">
            <FormGroup>
              <Slider
                value={radius}
                min={1}
                step={0.5}
                max={100}
                onChange={handleChange}
                aria-labelledby="non-linear-slider"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <Input
                name="seat_size"
                type="number"
                min="1"
                max="100"
                step="0.5"
                className="w-100"
                value={radius}
                onChange={(e) => handleSetRadius(Number(e.target.value))}
              />
            </FormGroup>
          </Col>
        </Row>
      </Container>
    </List>
  );
};

export default RangeInputSeatsSize;
