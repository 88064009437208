import {
  DateRangeInput,
} from 'escala-components';
import React, {
  useContext,
  useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Placement from 'src/contracts/models/Placement';
import Team from 'src/contracts/models/Team';
import moment, { Moment } from 'moment';
import Option from 'src/contracts/models/Option';
import { mixpanelTrack } from 'src/services/MixPanelService';
import { format } from 'date-fns';
import ButtonLoader from 'src/components/ButtonLoader';
import TeamSelector from '../TeamSelector';
import {
  Body,
  Container,
  Footer,
  Header,
  Overlay,
} from './style';
import icons from '../../assets/icons';
import TeamService from '../../services/TeamService';
import PublishService from '../../services/PublishService';
import { useToast } from '../../hooks/toast';
import { homeContext } from '../../pages/Home/Context';
import { useAlert } from '../../hooks/useAlert';
import Select from '../Select';
import PositionSelector from '../PositionSelector';

interface ModalSchedulePublishProps {
  period: {
    from: Date;
    to: Date;
  };
  apiTeams: Team[];
  selectedTeamsDefault: Option[];
  placement?: Placement;
  selectedLocationsDefault?: Option;
  isOpen: boolean;
  onCloseModal: () => void;
}
const ModalSchedulePublish: React.FC<ModalSchedulePublishProps> = ({
  isOpen,
  onCloseModal,
  selectedLocationsDefault: selectedPlacementsDefault,
  selectedTeamsDefault,
  period,
}) => {
  const { t } = useTranslation('components');
  const { waitForPublishConclusion } = useContext(homeContext);
  const notifyTypesOptions: Option[] = [
    {
      label: t('ModalSchedulePublish.notify_types.all_in_team'),
      value: 'ALL_TEAM',
    },
    {
      label: t('ModalSchedulePublish.notify_types.all_allocated'),
      value: 'ALL_ALLOCATED',
    },
    {
      label: t('ModalSchedulePublish.notify_types.nobody'),
      value: 'NONE',
    },
  ];
  const { openAlert } = useAlert();

  const { addToast } = useToast();
  const [placementOptions] = useState<Option[]>([]);
  const [selectedTeams, setSelectedTeams] = useState(selectedTeamsDefault);
  const [selectTypesOptions, setSelectTypesOptions] = useState<Option >(notifyTypesOptions[2]);
  const [selectedPlacements, setSelectedPlacements] = useState<Option | null>(
    selectedPlacementsDefault
      ? {
        value: selectedPlacementsDefault.id as string,
        label: selectedPlacementsDefault.label,
      } : null,

  );
  const [availableTeams, setAvailableTeams] = useState<Team[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(period);

  useEffect(() => {
  }, [selectedPlacements, selectedTeams]);

  const escFunction = (event: any) => {
    const escKey = 27;
    if (event.keyCode === escKey) {
      onCloseModal();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  }, []);

  useEffect(() => {
    TeamService.getTeamFromCompany()
      .then((r) => setAvailableTeams?.(r.data))
      .catch(() => setAvailableTeams?.([]));
  }, []);

  const teamOptions = useMemo(
    () => Object.values(availableTeams!).map(
      (team) => ({ value: String(team.id), label: team.name, id: String(team.id) }),
    ),
    [availableTeams],
  );

  const sendSchedulePublish = () => {
    const placementId = selectedPlacements?.value?.split('_')[0];
    const teamsIds = Object.values(selectedTeams).map((item) => item.id);
    if (placementId) {
      const payload = {
        week_start: format(selectedPeriod.from, 'yyyy-MM-dd'),
        week_end: format(selectedPeriod.to, 'yyyy-MM-dd'),
        teams_id: teamsIds.toString(),
        placement_id: Number(placementId),
        notification_type: selectTypesOptions.value,
      };
      const handleConfirm = () => {
        setIsLoading(true);
        PublishService.postSchedulePublish(payload).then((res) => {
          setIsLoading(false);
          mixpanelTrack('publish@send', null);
          mixpanelTrack('publish@notification-type', {
            notification_type: selectTypesOptions.value,
          });
          addToast({
            description: t('ModalSchedulePublish.pending'),
            type: 'info',
          });
          waitForPublishConclusion?.(res.data.jobId);
          onCloseModal();
        }).catch(() => {
          setIsLoading(false);
          addToast({
            description: t('ModalSchedulePublish.error'),
            type: 'error',
          });
        });
      };
      openAlert({ handleConfirm, message: t('ModalSchedulePublish.confirm_message') });
    }
  };

  return (
    <Overlay isOpen={isOpen}>
      <Container
        isOpen={isOpen}
        className="d-flex flex-column justify-content-between"
      >
        <div>
          <Header className="d-flex justify-content-between align-items-center py-4 px-5">
            <h3 className="text-primary m-0">{t('ModalSchedulePublish.schedule_publish')}</h3>
            <button type="button" onClick={onCloseModal}>
              <img src={icons.close} className="logo" alt={t('ModalEditUser.close')} />
            </button>
          </Header>
          <hr className="mt-0" />
          <Body className="px-5">
            <div>
              <div>
                <DateRangeInput
                  startDate={moment(selectedPeriod.from)}
                  endDate={moment(selectedPeriod.to)}
                  label={t('ModalSchedulePublish.period')}
                  minimumNights={-1}
                  handleChangeDate={
                    ({ endDate, startDate }:
                       { endDate: Moment, startDate: Moment }) => {
                      mixpanelTrack('publish@period-change', null);
                      if (startDate > endDate) {
                        setSelectedPeriod({
                          to: startDate && startDate.toDate(),
                          from: startDate && startDate.toDate(),
                        });
                      } else {
                        setSelectedPeriod({
                          to: endDate && endDate.toDate(),
                          from: startDate && startDate.toDate(),
                        });
                      }
                    }
                  }
                />
              </div>
              <div>
                <TeamSelector
                  allOption={{
                    label: t('Home.all_options'),
                    value: teamOptions,
                  }}
                  options={teamOptions}
                  defaultValue={selectedTeams}
                  onChange={(changedSelectedTeams) => setSelectedTeams?.(changedSelectedTeams)}
                />
              </div>
              <div>
                <PositionSelector
                  enabled={false}
                  options={placementOptions}
                  defaultValue={selectedPlacements}
                  onChange={setSelectedPlacements as any}
                />
              </div>
              <div>
                <Select
                  label={t('ModalSchedulePublish.notify')}
                  options={notifyTypesOptions}
                  defaultValue={selectTypesOptions}
                  onChange={setSelectTypesOptions as any}
                />
              </div>
            </div>
          </Body>
        </div>
        <Footer>
          <div className="py-4 px-5">
            <ButtonLoader
              isLoading={isLoading}
              width="100%"
              icon={icons.calendarCheck}
              label={t('ModalSchedulePublish.publish')}
              onClick={sendSchedulePublish}
            />
          </div>
        </Footer>
      </Container>
    </Overlay>
  );
};

export default ModalSchedulePublish;
