/* eslint-disable import/no-cycle */
import React, { useEffect, useRef } from 'react';
import {
  FiAlertCircle,
  FiInfo,
  FiCheckCircle,
  FiXCircle,
} from 'react-icons/fi';

import { ToastMessage, useToast } from '../../../hooks/toast';

import { Container, TitleContainer, ButtonClose } from './style';

interface ToastProps {
  message: ToastMessage;
  style: object;
}

const icons = {
  info: <FiInfo size={24} />,
  default: <FiInfo size={24} />,
  success: <FiCheckCircle size={24} />,
  error: <FiAlertCircle size={24} />,
  warning: <FiAlertCircle size={24} />,
};

const Toast: React.FC<ToastProps> = ({ message, style }) => {
  const { removeToast } = useToast();
  const isHover = useRef(false);
  isHover.current = false;

  const handleTimeRemove = (time: number) => {
    const timer = setTimeout(() => {
      if (isHover.current) {
        clearTimeout(timer);
        handleTimeRemove(2000);
        return;
      }
      removeToast(message.id);
    }, time);
  };

  useEffect(() => {
    handleTimeRemove(5000);
  }, [message.id, removeToast]);

  return (
    <Container
      type={message.type}
      hasdescription={Number(!!message.description)}
      onMouseEnter={() => {
        isHover.current = true;
      }}
      onMouseLeave={() => {
        isHover.current = false;
      }}
      style={style}
    >

      {message.type !== 'default' && icons[message.type || 'info']}

      <div className="pe-5">
        <TitleContainer>
          <strong>{message.title}</strong>
          {message.title && (
            <ButtonClose type="button" onClick={() => removeToast(message.id)}>
              <FiXCircle size={18} />
            </ButtonClose>
          )}
        </TitleContainer>
        {message.description && <p>{message.description}</p>}
      </div>
      {!message.title && (
        <button type="button" onClick={() => removeToast(message.id)}>
          <FiXCircle size={18} />
        </button>
      )}
    </Container>
  );
};

export default Toast;
