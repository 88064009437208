import styled from 'styled-components';
import colors from '../../colors';

export const PaginationContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (max-width: 768px) {
        display: grid; !important;
        justify-content: center !important;
    }
    align-items: center;
    margin-top: 16px;
    margin-bottom: 60px;
    
    .pagination {
        @media only screen and (min-width: 768px) {
            display: flex;
            width: 300px;
            justify-content: flex-end;
            align-items: center;
            justify-items: center;
            display: flex;
            flex-direction: row;
            padding-left: 0;
            list-style: none;
            border-radius: 0.25rem;
            margin: 0;
        }
    }
  
    .page-link:focus {
        z-index: 3;
        outline: 0;
        box-shadow: 0 0 0 0.2rem ${colors.blue};
    }

    .page-item:first-child .page-link {
        margin-left: 0;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    .page-item:last-child .page-link {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
    }

    .page-item.active .page-link {
        z-index: 3;
        color: ${colors.white};
        background-color: ${colors.blue};
        border-color: ${colors.blue};
    }

    .page-item.disabled .page-link {
        color: ${colors.gray18};
        pointer-events: none;
        cursor: auto;
        background-color: ${colors.white};
        border-color: ${colors.gray25};
    }

    .pagination-lg .page-link {
        padding: 0.75rem 1.5rem;
        font-size: 1.25rem;
        line-height: 1.5;
    }

    .pagination-lg .page-item:first-child .page-link {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
    }

    .pagination-lg .page-item:last-child .page-link {
        border-top-right-radius: 0.3rem;
        border-bottom-right-radius: 0.3rem;
    }

    .pagination-sm .page-link {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
        line-height: 1.5;
    }

    .pagination-sm .page-item:first-child .page-link {
        border-top-left-radius: 0.2rem;
        border-bottom-left-radius: 0.2rem;
    }

    .pagination-sm .page-item:last-child .page-link {
        border-top-right-radius: 0.2rem;
        border-bottom-right-radius: 0.2rem;
    }

    .item {
        align-items: center;
        color: ${colors.white};
        cursor: pointer;
        display: flex;
        font-size: 14px;
        height: 40px;
        justify-content: center;
        width: 40px;
        border-width: 0px;
    }

    .disabled-page {
        color: black;
    }

    .next {
        display: none;
    }

    .pagination {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        padding-left: 0;
        border-radius: 4px;
    }

    .pagination > li {
        border-width: 0px;
        border-style: none !important;
    }

    .pagination-page {
        font-weight: 700;
        border-width: 0px;
    }

    .previous {
        display: none;
    }

    .pagination > .active > a,
    .pagination > .active > a:focus,
    .pagination > .active > a:hover,
    .pagination > .active > span,
    .pagination > .active > span:focus,
    .pagination > .active > span:hover {
        z-index: 3;
        color: ${colors.white};
        border: 1px solid ${colors.gray25};
        background-color: ${colors.blue};
        font-weight: bold;
        font-size: 13px;
        cursor: default;
        font-family: lato;
    }

    .pagination > li > a,
    .pagination > li > span {
        position: relative;
        display: flex;
        float: left;
        padding: 6px 12px;
        line-height: 1.42857143;
        text-decoration: none;
        color: ${colors.blue};
        font-weight: 500;
        background-color: ${colors.white20};
        border: 1px solid ${colors.gray25};
        margin: 2px;
        border-radius: 5px;
        border-style: none;
        font-size: 13px;
        align-items: center;
        font-family: lato;
        height: 30px;
        width: 40px;
        min-width: 15px;
        justify-content: center;
        align-items: center;
    }

    .pagination > li > a:hover {
        border: 1px solid ${colors.gray25};
    }
`;

export const TotalText = styled.span`
    text-align: left;
    font-size: 13px;
    letter-spacing: 0px;
    font-family: lato;
    color: ${colors.gray75};
`;

interface IconPros {
    rotated?: boolean;
}

export const Icon = styled.img<IconPros>`
    margin:0;
    width: 10px;
    height: 10px;
    ${(props) => props.rotated && 'transform: rotate(180deg);'}
`;
