import { useEffect, useState } from 'react';

export default function useAsync(
  asyncFunction: any,
  deps: any[],
): [boolean, () => Promise<void>, Error | null] {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const functionWithLoading = async () => {
    setLoading(true);
    try {
      await asyncFunction();
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    functionWithLoading();
  }, deps);

  return [loading, functionWithLoading, error];
}
