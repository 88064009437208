import React, { useCallback } from 'react';
import { useState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'src/components/ButtonLoader';
import useHandleKeyDown from 'src/hooks/useHandleKeyDown';
import icons from '../../assets/icons';
import { IconModal } from '../ConfirmModal/style';
import {
  Title, Message, ContainerButtons,
  Modal, ModalBody, ModalFooter,
} from './style';

export interface ConfirmManageSelectedUsersModalProps {
  isOpen?: boolean;
  handleCloseButton?: () => void;
  handleConfirm: () => void;
  modalType: 'restore' | 'delete' | 'invite'
}

const ConfirmManageSelectedUsersModal: React.FC<ConfirmManageSelectedUsersModalProps> = ({
  isOpen,
  handleCloseButton = () => null,
  handleConfirm,
  modalType,
}) => {
  const { t } = useTranslation('components');
  const isLoading = useState<boolean>(false);

  const onEventKeyIsEnter = useCallback(() => {
    if (isOpen) {
      handleConfirm();
      isLoading.set(true);
    }
  }, [isOpen]);

  const getComponentTranslationByKey = (key: string) => {
    const COMPONENT_NAME = 'ConfirmManageSelectedUsersModal';

    return t(`${COMPONENT_NAME}.${modalType}.${key}`);
  };

  useHandleKeyDown({
    onEventKeyIsEnter,
    onEventKeyIsEscape: handleCloseButton,
  });

  return (
    <Modal
      centered
      isOpen={isOpen}
      onClosed={() => {
        isLoading.set(false);
        handleCloseButton?.();
      }}
    >
      <ModalBody>
        <IconModal src={icons.Error} alt="" />
        <Title>{t(getComponentTranslationByKey('title'))}</Title>
        <Message>
          {t(getComponentTranslationByKey('message'))}
        </Message>
      </ModalBody>
      <ModalFooter>
        <ContainerButtons>
          <ButtonLoader
            label={t(getComponentTranslationByKey('cancel'))}
            bordered
            transparent
            withHover={false}
            onClick={handleCloseButton}
            disabled={isLoading.get()}
          />
          <ButtonLoader
            label={t(getComponentTranslationByKey('confirm'))}
            isLoading={isLoading.get()}
            onClick={() => {
              handleConfirm();
              isLoading.set(true);
            }}
          />
        </ContainerButtons>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmManageSelectedUsersModal;
