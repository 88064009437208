import styled from 'styled-components';
import colors from '../../colors';

interface SideMenu {
  open?: boolean;
}

interface NavItemProps {
  isCurrent?: boolean
}

export const SideBar = styled.div<SideMenu>`
  z-index: 99;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.open ? '190px' : '55px')};
  background: ${colors.blue} 0% 0% no-repeat padding-box;
  padding: ${(props) => (props.open ? '23px' : '23px 5px 23px 5px')};
  transition: 0.3s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
`;

export const HideButton = styled.button`
  position: absolute;
  top: 15px;
  right: -21px;
  width: 30px;
  height: 30px;
  background: ${colors.blue};
  border: 2px solid #FFFFFF;
  border-radius: 50%;
  transition: 0.5s;
  :focus {
    outline: none;
  }
`;

export const Body = styled.div<SideMenu>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  align-items: ${(props) => (props.open ? 'start' : 'center')};
  justify-content: start;
`;

export const EmployeesCounter = styled.span`
  font: normal normal bold 17px/21px lato;
  text-align: left;
  color: #3b4252;
  opacity: 1;
`;

export const Loader = styled.img`
  width: 3em;
  height: 3em;
  align-self: center;
  margin-top: 10px;
`;

export const HideButtonIcon = styled.img<SideMenu>`
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: 0.3s;
  filter: invert(100%) sepia(0%) saturate(22%) hue-rotate(357deg) brightness(104%) contrast(1000%);
`;

export const NavItems = styled.div<NavItemProps>`
  display: flex;
  height: 5%;
  button, a {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: ${(props) => (props.isCurrent ? 'linear-gradient(90deg, rgb(0, 212, 169) 5%, rgb(18, 28, 86) 5%)' : 'transparent')}; 
    border-radius: 0;
    border: 0;
    text-transform: initial;
    padding: 0 15px;
    
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: ${(props) => (props.isCurrent ? colors.green30 : colors.white)};
    white-space: nowrap;
    text-decoration: none;

    .animation-focus-item {
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      transform: scale(1.25);
      animation: pulse 2s infinite;
      @keyframes pulse {
        0% {
          transform: scale(0.80);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
        }

        70% {
          transform: scale(1.25);
          border-radius: 50%;
          box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
        }

        100% {
          transform: scale(0.80);
          box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
        }
      }
    }
    
    img {
      margin-right: 4px;
      width: 20px;
      height: 17px;
      object-fit: scale-down;
      filter: ${(props) => (props?.isCurrent
    ? 'invert(54%) sepia(75%) saturate(1260%) hue-rotate(126deg) brightness(100%) contrast(101%)'
    : 'invert(91%) sepia(96%) saturate(2%) hue-rotate(193deg) brightness(1000%) contrast(101%)')};
    }

    &:hover {
      background: ${(props) => (props.isCurrent ? 'invert(54%) sepia(75%) saturate(1260%) hue-rotate(126deg) brightness(100%) contrast(101%)' : 'linear-gradient(90deg, rgb(0, 212, 169) 5%, rgb(18, 28, 86) 5%)')};
      text-decoration: none;
      color: ${(props) => (props.isCurrent ? colors.green30 : colors.white)};
      img {
        filter: ${(props) => (props?.isCurrent ? 'invert(54%) sepia(75%) saturate(1260%) hue-rotate(126deg) brightness(100%) contrast(101%)'
    : 'invert(91%) sepia(96%) saturate(2%) hue-rotate(193deg) brightness(1000%) contrast(101%)')};
      }
    }
    
    @media all and (max-width: 1150px){
      font-size: 10px;
      padding: 0 7px;
    }
  }
  .popper {
    z-index: 10;
    
    > div{
      border-radius: 0;
      box-shadow: 0 3px 6px ${colors.dark80};
    }
    
    ul{
      li{
        font-size: 15px;
        color: ${colors.gray};
        a{
          color: ${colors.gray};
          padding: 6px 10px;
          width: 100%;
        }
        img{
          margin-right: 10px;
        }
      }
    }
  }
`;
export const NavbarBrand = styled.div`
  margin-bottom: 15px;
  a {
    padding: 0;
  }
`;
