import React from 'react';
import { useTranslation } from 'react-i18next';
import Option from 'src/contracts/models/Option';
import { Component, Selector } from './style';
import AdminFilters from './AdminFilters';

interface SpaceSelectorProps {
  onChange(space: Option[]): void;
  value: any;
  isClearable: boolean;
}

const AccessFilterSelector: React.FC<SpaceSelectorProps> = ({
  onChange,
  value,
  isClearable,
}) => {
  const { t } = useTranslation('components');

  async function loadOptions() {
    const { options, hasMore } = AdminFilters(t);
    return {
      options,
      hasMore,
    };
  }

  return (
    <Component>
      <Selector
        closeMenuOnSelect
        placeholder={t('AccessFilterSelector.placeholder')}
        value={value || ''}
        loadOptions={loadOptions}
        getOptionValue={(option: any) => option.name}
        getOptionLabel={(option: any) => option.name}
        onChange={onChange}
        isSearchable
        debounceTimeout={500}
        isClearable={isClearable}
        additional={{
          page: 1,
        }}
        cacheUniqs={[value]}
      />
    </Component>
  );
};

export default AccessFilterSelector;
