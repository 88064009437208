import React from 'react';
import { useTranslation } from 'react-i18next';
import { Body, Header } from '../style';
import Button from '../../Button';

interface PlaceAnnouncentProps {
  onClickOpenAnnouncementAuto: Function;
  onClickOpenAnnouncementLot: Function;
}

const PlaceAnnouncent: React.FC<PlaceAnnouncentProps> = ({
  onClickOpenAnnouncementLot,
}) => {
  const { t } = useTranslation('components');

  return (
    <div>
      <Header className="d-flex align-items-center px-5 py-4">
        <h5 className="mb-1">
          {t('ModalAnnoucement.schedule_announcement')}
        </h5>
      </Header>
      <hr className="mt-0" />
      <Body className="py-4 px-5">
        <p>{t('ModalAnnoucement.description_announcement')}</p>
        <Button
          label={t('ModalAnnoucement.lot_schedule')}
          icon="bullhornPrimary"
          onClick={() => onClickOpenAnnouncementLot()}
          className="btn-announcent my-2 w-100"
        />
      </Body>
    </div>
  );
};

export default PlaceAnnouncent;
