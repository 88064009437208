import { format, parse } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { isWeekend } from 'date-fns/esm';

const filterShifts = (placement: any, searchInput: string) => {
  const keys = Object.keys(placement?.shifts.dates);
  const dates = placement?.shifts.dates;
  const filtered: any = {};
  keys.forEach((key) => {
    if (!dates[key].shifts) {
      return;
    }
    const shifts = Object.values(dates[key].shifts);
    const filteredShifts: any = {};
    shifts.forEach((shift: any) => {
      function compareEmployeeNameOrTeam(employee: any) {
        return (
          String(employee.name)
            .toUpperCase()
            .includes(searchInput.toUpperCase())
          || String(employee.team_name)
            .toUpperCase()
            .includes(searchInput.toUpperCase())
        );
      }
      const hasEmployeeWhithNameOrTeam = Object.values(
        shift.allocated_employees_this_shift,
      ).some(compareEmployeeNameOrTeam);

      if (hasEmployeeWhithNameOrTeam) {
        filteredShifts[shift.id] = shift;
      }
    });
    const newDates = {
      ...dates[key],
      shifts: filteredShifts,
    };
    filtered[key] = newDates;
  });
  const newDates = {
    ...dates,
    ...filtered,
  };
  return newDates;
};

const getTotalEmployeesTitle = (totalEmployees: number, translate: Function) => {
  switch (totalEmployees) {
    case 0:
      return translate('Calendar.without_employee');
    case 1:
      return translate('Calendar.single_employee');
    default:
      return translate('Calendar.employees', { totalEmployees });
  }
};

const buildCalendarWeekdayHeaderValue = (dateString: string, openDays: any) => {
  const day = parse(dateString, 'yyyy-MM-dd', new Date());
  const dayName = format(day, 'E').toUpperCase();
  const isAllowedDay = Boolean(openDays?.[dayName]);
  const dayFormatted = format(day, 'E dd/MM', {
    locale: ptBR,
  }).toUpperCase();

  return {
    dayFormatted,
    dayName,
    isAllowed: isAllowedDay,
    isWeekend: isWeekend(day),
    day,
    formattedDay: format(day, 'yyyy-MM-dd'),
  };
};

const DayAdapter: any = {
  MON: 'MONDAY',
  TUE: 'TUESDAY',
  WED: 'WEDNESDAY',
  THU: 'THURSDAY',
  FRI: 'FRIDAY',
  SAT: 'SATURDAY',
  SUN: 'SUNDAY',
};

type OpenDays = {
  [x: string]: {
    dateEnd: string;
    dateStart: string;
    maxVacancy: number;
    openDayId: number;
    timeEnd: string;
    timeStart: string;
    weekDay: string;
  };
};

export interface CalendarProps {
  selectedTeamsId: number[];
  showPositionsName: boolean;
  date: Date;
  isOpenEmployeeEdit: boolean | undefined;
}

export {
  filterShifts, buildCalendarWeekdayHeaderValue, getTotalEmployeesTitle, DayAdapter,
};
export type { OpenDays };
