/* eslint-disable */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import PositionSelector from 'src/components/PositionSelector';
import TeamSelector from 'src/components/TeamSelector';
import WeekPeriodSelector from 'src/components/WeeklyPeriodSelector';
import { useTranslation } from 'react-i18next';
import Calendar from 'src/components/Calendar';
import Team from 'src/contracts/models/Team';
import ModalSchedulePublish from 'src/components/ModalSchedulePublish';
import ModalPlacesAnnoucement from 'src/components/ModalPlacesAnnouncement';
import { endOfWeek } from 'date-fns';
import { formatTeamsSidebar } from 'src/utils/team.functions';
import CustomNavBar from 'src/components/CustomNavBar';
import { useToast } from '../../hooks/toast';
import {
  CalendarContainer,
  Container,
  TeamSideBarContainer,
  Empty,
  EmptyIcon,
  EmptyMessage,
  LabelButton,
  Tabs,
} from './style';
import TeamSideBar from '../../components/TeamSideBar';
import HomeContextProvider, { homeContext } from './Context';
import Icons from '../../assets/icons';
import { addData, deleteData } from '../../services/LocalStorageService';
import MainNavBar from '../../components/MainNavBar';
import TeamService from '../../services/TeamService';
import PlacementService from '../../services/PlacementService';
import Option from '../../contracts/models/Option';
import { useGlobal } from '../../hooks/useGlobal';
import icons from '../../assets/icons';
import {
  GuideEmptyCalendar, GuideOpenMapButton,
  GuideTeamAndPosition,
  GuideTeamSelectDate,
  GuideUsersSelect,
} from '../../components/Guidance/CalendarSteps';
import Guidance from '../../components/Guidance';
import { getGuide, getUser } from '../../services/auth';

function Home() {
  const guideSteps = getGuide();
  const user = getUser();
  const status = (guideSteps?.nextStep === 'calendar' && guideSteps.steps.calendar === false);
  const { setIsOpenEmployeeEdit } = useContext(homeContext);
  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [sideBarData, setSideBarData] = useState<Team[]>([]);
  const [runGuidance, setRunGuidance] = useState(false);
  const {
    week, setWeek, placement, selectedTeams,
    setSelectedTeams, selectedLocations, setSelectedLocations,
    availableTeams, setAvailableTeams, showLabels, setShowLabels,
    isOpenEmployeeEdit,
  } = useContext(homeContext);

  const [locationOptions, setLocationOptions] = useState<Option[]>([]);
  const [isLoadingTeams, setIsLoadingTeams] = useState<boolean>(true);
  const [isLoadingLocations, setIsLoadingLocations] = useState<boolean>(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [isModalSchedulePublishOpen, setIsModalSchedulePublishOpen] = useState(
    false,
  );
  const [isModalPlacesAnnouncementsOpen, setIsModalPlacesAnnouncementsOpen] = useState(
    false,
  );

  const { sideMenuIsOpen } = useGlobal();

  const teamOptions = useMemo(
    () => Object.values(availableTeams!).map(
      (team) => ({ value: String(team.id), label: team.name, id: String(team.id) }),
    ),
    [availableTeams],
  );

  const { t } = useTranslation('pages');
  const translation = useTranslation('components');
  const { addToast } = useToast();

  const buildLocationsOption = (placements: any) => {
    const placementOptions: any[] = [
      { options: [] },
      { options: [] },
    ];
    if (placements) {
      if (placements.table) {
        placementOptions[0].options = Object.values(placements.table).map(
          (item: any) => ({
            label: `${item.location_name} - ${item.placement_name}`,
            value: `table_${item.location_name}-${item.placement_name}`,
            id: `${item.placement_id}_${item.location_id}`,
          }),
        );
      }
      if (placements.room) {
        placementOptions[1].options = Object.values(placements.room).map(
          (item: any) => ({
            label: `${item.location_name} - ${item.placement_name}`,
            value: `room_${item.location_name}-${item.placement_name}`,
            id: `${item.placement_id}_${item.location_id}`,
          }),
        );
      }
    }
    return placementOptions;
  };

  const handleShowLabels = (state: boolean) => {
    if (placement?.seatsName?.[0].name) setShowLabels?.(state);
    else {
      addToast({
        description: t('Home.labels.none'),
        type: 'error',
      });
    }
  };

  useEffect(() => {
    TeamService.getTeamFromCompany()
      .then((r) => {
        setAvailableTeams?.(r.data);
        setIsLoadingTeams(false);
      })
      .catch(() => {
        setAvailableTeams?.([]);
        setIsLoadingTeams(false);
      });
  }, []);

  useEffect(() => {
    setIsLoadingUsers(true);
    const loadSideBarData = async (query: string) => {
      TeamService.getTeamEmployees(query)
        .then((r) => {
          setIsLoadingUsers(false);
          setSideBarData(formatTeamsSidebar(r.data));
        })
        .catch(() => setSideBarData([]));
    };

    const loadPlacements = async (query: string) => {
      PlacementService.getPlacementsFromTeam(query).then((response) => {
        setIsLoadingLocations(false);
        setLocationOptions(buildLocationsOption(response?.data?.placements));
      });
    };

    if (selectedTeams?.length > 0) {
      const query = selectedTeams.map((item) => item.id).toString();
      loadPlacements(query).then(() => null);
      loadSideBarData(query).then(() => null);
      addData('selected_teams', selectedTeams);
    } else {
      setIsLoadingUsers(false);
      setSideBarData([]);
      setLocationOptions([]);
      deleteData('selected_locations');
      deleteData('selected_teams');
    }
  }, [selectedTeams]);

  useEffect(() => {
    if (selectedTeams.length > 0 && selectedLocations) {
      addData('selected_locations', selectedLocations);
    } else {
      deleteData('selected_locations');
    }
  }, [selectedLocations]);

  const [initialTab, setInitialTab] = useState(0);
  const [tabs, setTabs] = useState<any[]>([]);

  const Shifts = () => (
    <Calendar
      selectedTeamsId={selectedTeams ? selectedTeams.map((item) => Number(item.id)) : []}
      showPositionsName={showLabels!}
      date={week}
      isOpenEmployeeEdit={isOpenEmployeeEdit}
    />
  );

  useEffect(() => {
    if (selectedLocations && selectedLocations?.label?.length) {
      setTabs([
        {
          label: (
            <span>
              Reservas
              <img className="ms-2" src={icons.Info} alt={t('Home.tooltips.reservations')} data-toggle="tooltip" data-placement="top" title={t('Home.tooltips.reservations')} />
            </span>
          ),
          tab: <Shifts />
        },
      ]);
    } else setTabs([]);
  }, [week, selectedLocations]);

  useEffect(() => {
    if (teamOptions?.length === 1) {
      setSelectedLocations?.(null);
      setSelectedTeams?.(teamOptions);
    }
  }, [teamOptions]);

  useEffect(() => {
    let locationsOptionsByIndex: any = locationOptions[0];
    if (teamOptions?.length === 1) {
      if (locationsOptionsByIndex) {
        if (Array.isArray(locationsOptionsByIndex.options)) {
          if (locationsOptionsByIndex.options.length === 1) {
            setSelectedLocations?.(locationsOptionsByIndex.options[0] as any)
          }
        }
      }
    }
  }, [locationOptions]);

  useEffect(() => {
    setRunGuidance(status);
  }, []);

  useEffect(() => {
    if (status) setSideBarOpen(true);
  }, [status]);

  const steps = [
    {
      content: <GuideTeamAndPosition translate={translation.t} currentStep={1} finishStep={5} />,
      placement: 'top',
      target: '#team-and-position',
      disableBeacon: true,
    },
    {
      content: <GuideTeamSelectDate translate={translation.t} currentStep={2} finishStep={5} />,
      placement: 'auto',
      target: '#date-selector',
    },
    {
      content: <GuideUsersSelect translate={translation.t} currentStep={3} finishStep={5} />,
      placement: 'auto',
      target: '#list-teams',
    },
    {
      content: <GuideEmptyCalendar translate={translation.t} currentStep={4} finishStep={5} />,
      placement: 'auto',
      target: '#show-shifts',
    },
    {
      content: <GuideOpenMapButton translate={translation.t} currentStep={5} finishStep={5} />,
      placement: 'auto',
      target: '#open-map-button',
      styles: {
        buttonNext: {
          display: 'none',
        },
      },
    },
  ];

  return (
    <>
      <MainNavBar />
      <Guidance
        run={runGuidance}
        setRun={setRunGuidance}
        steps={steps}
        actualStep="calendar"
        nextStep="calendar"
      />
      <Container isOpen={sideBarOpen} className={sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'}>
        <TeamSideBarContainer className={`${user?.isAdmin? '': 'd-none'}`}>
          <TeamSideBar
            isOpen={sideBarOpen}
            setIsOpen={setSideBarOpen}
            apiTeams={sideBarData}
            isLoading={isLoadingUsers}
            selectedTeams={selectedTeams}
            setIsOpenEmployeeEdit={setIsOpenEmployeeEdit}
          />
        </TeamSideBarContainer>
        <CalendarContainer className={`row me-0 ${user?.isAdmin? sideBarOpen ? 'margin-left-sidebar-is-open' : 'margin-left-sidebar-is-close' : ''}`}>
          <div className='col-12 d-flex justify-content-end px-2 d-none'>
            <LabelButton
              type='button'
              onClick={() => handleShowLabels(!showLabels)}
              selected={showLabels}
              className='bg-transparent border-0 me-2'
            >
              {t('Home.show_label')}
              <img
                src={Icons.awesomeEye}
                alt={t('Home.show_label')}
                title={t('Home.show_label')}
              />
            </LabelButton>
            <button type='button' className='bg-transparent border-0 d-none'>
              <img
                src={Icons.awesomeMagic}
                alt={t('Home.generate_schedule')}
                title={t('Home.generate_schedule')}
              />
            </button>
          </div>
          <div className="col-12 d-flex justify-content-start mb-4">
            {(!selectedLocations || !selectedTeams?.length) && (
              <EmptyMessage className="mb-0">
                <span className="mb-0">{t('Home.empty_message.top')}</span>
              </EmptyMessage>
            )}
          </div>
          <div className='col-12 col-lg-6 d-flex justify-content-between' id="team-and-position">
            <TeamSelector
              tooltipLabel={t('Home.tooltips.teams')}
              allOption={{
                label: t('Home.all_options'),
                value: teamOptions,
              }}
              isLoading={isLoadingTeams}
              options={teamOptions}
              defaultValue={selectedTeams}
              onChange={(changedSelectedTeams) => {
                setSelectedLocations?.(null);
                setSelectedTeams?.(changedSelectedTeams);
              }}
              className="w-50 me-3"
            />
            <PositionSelector
              tooltipLabel={t('Home.tooltips.positions')}
              options={locationOptions}
              isLoading={isLoadingLocations}
              defaultValue={selectedLocations}
              onChange={(location) => setSelectedLocations?.(location as any)}
              className="w-50"
              enabled={selectedTeams?.length > 0}
            />
          </div>
          <div className="col-12 col-lg-6 mt-2 mt-lg-4 d-flex">
            <WeekPeriodSelector
              startDate={week}
              onChange={(date) => {
                setWeek?.(date);
              }}
            />
            <img className="ms-2 m-auto"
                 src={icons.Info} alt={''}
                 data-toggle="tooltip"
                 data-placement="top"
                 title={t('Home.tooltips.week')}
                 style={{width: '20px', height:'20px' }}
            />
          </div>
          <Tabs>
            <CustomNavBar
              pages={tabs}
              backColor="#fff"
              page={initialTab}
              leftSpace="5px"
              onChange={(page) => setInitialTab(page)}
            />
          </Tabs>
          {(!selectedLocations || !selectedTeams?.length) && (
            <Empty>
              <EmptyMessage>
                <span>{t('Home.empty_message.start')}</span>
                <span id="middle">{t('Home.empty_message.middle')}</span>
                <span>{t('Home.empty_message.end')}</span>
              </EmptyMessage>
              <EmptyIcon src={Icons.Empty} />
            </Empty>
          )}
        </CalendarContainer>
        {isModalSchedulePublishOpen
          && (
            <ModalSchedulePublish
              selectedLocationsDefault={selectedLocations as Option}
              selectedTeamsDefault={selectedTeams}
              placement={placement}
              apiTeams={sideBarData}
              onCloseModal={() => setIsModalSchedulePublishOpen(false)}
              period={{ from: week, to: endOfWeek(week, { weekStartsOn: 1 }) }}
              isOpen={isModalSchedulePublishOpen}
            />
          )}
        <ModalPlacesAnnoucement
          selectedTeamsDefault={selectedTeams}
          handleCloseButton={() => setIsModalPlacesAnnouncementsOpen(false)}
          isOpen={isModalPlacesAnnouncementsOpen}
        />
      </Container>
    </>
  );
}

export default () => (
  <HomeContextProvider>
    <Home />
  </HomeContextProvider>
);
