/* eslint-disable camelcase */
import Api from './api';

export default class GeoLocationService {
  static async get(address: string) {
    const response = Api.get(`geocoding/getAddressInfo?address=${address}`);
    return response;
  }

  static async getCities(stateId: number) {
    const response = Api.get(`geolocation/cities?state_id=${stateId}`);
    return response;
  }

  static async getStates() {
    const response = Api.get('geolocation/states');
    return response;
  }
}
