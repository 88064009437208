import Api from './api';

export default class CheckInService {
  static DEFAULT_PATH = 'checkin';

  public static async getCheckIn(date: string) {
    return Api.get(`${this.DEFAULT_PATH}`, {
      params: {
        date,
      },
    });
  }

  public static async doCheckIn(shiftEmployeeId: number) {
    return Api.post(`${this.DEFAULT_PATH}`, {
      shift_employee_id: shiftEmployeeId,
    });
  }

  public static async doCheckOut(shiftEmployeeId: number) {
    return Api.post('checkout', {
      shift_employee_id: shiftEmployeeId,
    });
  }
};
