import React, { useCallback } from 'react';
import { useState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ButtonLoader from 'src/components/ButtonLoader';
import useHandleKeyDown from 'src/hooks/useHandleKeyDown';
import {
  Title, Message, IconModal, ContainerButtons,
} from './style';
import icons from '../../assets/icons';
import colors from '../../colors';

export interface ModalConfirmProps {
  isOpen?: boolean;
  handleCloseButton?: () => void;
  handleConfirm: () => void;
  message?: string;
  messageConfirm?: string;
  title?: string;
  center?: boolean;
}
const ModalConfirm: React.FC<ModalConfirmProps> = ({
  isOpen,
  handleCloseButton = () => null,
  handleConfirm,
  message,
  messageConfirm,
  title,
  center = true,
}) => {
  const { t } = useTranslation('components');
  const isLoading = useState<boolean>(false);

  const onEventKeyIsEnter = useCallback(() => {
    if (isOpen) {
      handleConfirm();
      isLoading.set(true);
    }
  }, [isOpen]);

  useHandleKeyDown({
    onEventKeyIsEnter,
    onEventKeyIsEscape: handleCloseButton,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClosed={() => {
        isLoading.set(false);
        handleCloseButton?.();
      }}
      style={{
        maxWidth: 500,
        borderRadius: '4px',
      }}
      centered={center}
    >
      <ModalBody
        style={{
          borderTop: `6px solid ${colors.orange}`,
          borderWidth: '100%',
          borderRadius: '3px',
          paddingTop: '32px',
        }}
      >
        <IconModal src={icons.Error} alt="" />
        <Title>{title || t('ModalConfirm.default-confirm-action')}</Title>
        {message && <Message dangerouslySetInnerHTML={{ __html: message }} />}
        {messageConfirm && (
          <Message
            className="mt-4"
          >
            {messageConfirm}
          </Message>
        )}
      </ModalBody>
      <ModalFooter style={{ border: 'none', paddingBottom: '32px' }}>
        <ContainerButtons>
          <ButtonLoader
            label={t('ModalConfirm.cancel')}
            bordered
            transparent
            withHover={false}
            onClick={handleCloseButton}
            disabled={isLoading.get()}
          />
          <ButtonLoader
            label={t('ModalConfirm.confirm')}
            isLoading={isLoading.get()}
            onClick={() => {
              handleConfirm();
              isLoading.set(true);
            }}
          />
        </ContainerButtons>
      </ModalFooter>
    </Modal>
  );
};

export default ModalConfirm;
