import React, {
  useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import LogoSvg from 'src/assets/imgs/Logo_Espaços_Login.svg';
import Input from 'src/components/InputWithRef';
import ButtonLoader from 'src/components/ButtonLoader';
import AuthService from 'src/services/AuthService';
import {
  Col, Row,
} from 'reactstrap';
import { useToast } from 'src/hooks/toast';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import {
  startMixpanel,
  mixpanelTrack,
} from 'src/services/MixPanelService';

import {
  Container,
  RowContainer,
  BackgroundContainer,
  LoginContainer,
  LoginContent,
  LogoContainer,
  Logo,
  ButtonContainer,
  AlreadyAccountContainer,
  AlreadyAccount,
} from './style';

interface IRegisterForm {
  name: string;
  email: string;
  phone: string | undefined;
  companyName: string;
}

const Register: React.FC = () => {
  const { t } = useTranslation('pages');
  const { register, errors, handleSubmit } = useForm<FormData>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  async function doRegister(credentials: IRegisterForm) {
    const phone = credentials.phone?.replace(/[^\d]+/g, '');

    AuthService.register({
      name: credentials.name,
      email: credentials.email,
      companyName: credentials.companyName,
      phone,
    }).then((response) => {
      setIsLoading(false);

      if (response.status === 200) {
        mixpanelTrack('Cadastro efetuado com sucesso', {
          email: credentials.email,
          name: credentials.name,
          companyName: credentials.companyName,
        });

        addToast({
          description: t('Register.success'),
          type: 'success',
        });

        history.push('/login');
      }
    })
      .catch((error) => {
        setIsLoading(false);
        return addToast({
          description: error.response.data.message,
          type: 'error',
        });
      });
  }

  const onSubmit = async (credentials: IRegisterForm) => {
    setIsLoading(true);
    await doRegister(credentials);
  };

  useEffect(() => {
    startMixpanel();
  }, []);

  return (
    <Container>
      <RowContainer>
        <BackgroundContainer />
        <LoginContainer>
          <LoginContent>
            <LogoContainer>
              <Logo src={LogoSvg} />
            </LogoContainer>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Row className="d-flex align-items-center justify-content-between pe-2">
                <Col md="12" sm="12" style={{ paddingRight: 5 }}>
                  <Input
                    required
                    error={errors}
                    errorMsg={t('Register.Required.name')}
                    name="name"
                    register={register({ required: true })}
                    label={t('Register.name')}
                  />
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-between pe-2">
                <Col md="12" sm="12">
                  <Input
                    required
                    error={errors}
                    errorMsg={t('Register.Required.email')}
                    name="email"
                    register={register({ required: true })}
                    label={t('Register.email')}
                  />
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-between pe-2">
                <Col md="12" sm="12">
                  <Input
                    required
                    error={errors}
                    errorMsg={t('Register.Required.company_name')}
                    name="companyName"
                    register={register({ required: true })}
                    label={t('Register.company_name')}
                  />
                </Col>
              </Row>
              <Row className="d-flex align-items-center justify-content-between pe-2">
                <Col md="12" sm="12">
                  <Input
                    error={errors}
                    name="phone"
                    label={t('Register.phone')}
                    mask="(99) 99999-9999"
                    register={register({ required: false })}
                  />
                </Col>
              </Row>
              <ButtonContainer>
                <ButtonLoader
                  label={t('Register.send')}
                  isLoading={isLoading}
                  style={{ width: '100%' }}
                />
              </ButtonContainer>
              <AlreadyAccountContainer>
                <Link to="/login">
                  <AlreadyAccount>
                    {t('Register.i_have_account')}
                  </AlreadyAccount>
                </Link>
              </AlreadyAccountContainer>
            </form>
          </LoginContent>
        </LoginContainer>
      </RowContainer>
    </Container>
  );
};

export default Register;
