import styled from 'styled-components';
import colors from '../../colors';

interface NavItemProps {
  isCurrent?: boolean
  isFinish?: boolean,
}

export const NavBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 98;
  width: 100%;
  height: 64px;
  background: ${colors.white};
  box-shadow: 0 -2px 6px ${colors.dark75};
  
  display: flex;
  align-items: center;

  &:hover{
    text-decoration: none;
  }
  @media only screen and (max-width: 768px) { 
    position: fixed !important;
  }
`;

export const NavbarBrand = styled.div`

  img {
    height: 32px;
  }
  
`;

export const NavItems = styled.div<NavItemProps>`

  display: flex;
  
  button, a {
    display: inline-flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: transparent;
    border-radius: 0;
    border: 0;
    text-transform: initial;
    padding: 0 15px;
    color: ${colors.blue80};

    font-size: 15px;
    font-weight: ${(props) => (props.isCurrent ? 'bold' : 'normal')};
    text-decoration: none;
    
    img {
      margin-right: 4px;
      width: 20px;
      height: 17px;
      object-fit: scale-down;
      filter: ${(props) => (props.isCurrent
    ? 'brightness(0) saturate(100%) invert(7%) sepia(31%) saturate(7050%) hue-rotate(224deg) brightness(109%) contrast(113%);'
    : 'invert(51%) sepia(6%) saturate(1025%) hue-rotate(190deg) brightness(89%) contrast(82%)')};
    }

    &:hover {
      background: transparent;
    }
    
    @media all and (max-width: 1150px){
      font-size: 10px;
      padding: 0 7px;
    }
  }
  .popper {
    z-index: 10;
    
    > div{
      border-radius: 0;
      box-shadow: 0px 3px 6px ${colors.dark80};
    }
    
    ul{
      li{
        font-size: 15px;
        color: ${colors.gray};
        a{
          color: ${colors.gray};
          padding: 6px 10px;
          width: 100%;
        }
        img{
          margin-right: 10px;
        }
      }
    }
  }
`;

export const ProfileNav = styled.div`
  display: flex;
  align-items: center;
  margin: 0 10px;
  position: relative;
  cursor: pointer;
`;

export const ProfileImage = styled.img`
  width: 28px;
  height: 28px;
  border-radius: 28px;
  object-fit: cover;
`;

export const ProfileNonImage = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: ${colors.gray85};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  font-size: 15px;
`;

export const TestDaysRemaining = styled.div<NavItemProps>`
  width: auto;
  padding-right: 15px;
  padding-left: 15px;
  height: 32px;
  right: 304px;
  top: 12px;
  margin-right: 15px;

  border-radius: 15px 15px 15px 15px;

  background: ${(props) => (props.isFinish ? '#F26167' : '#00B38E')};

  font-weight: 700;
  font-size: 14px;
  line-height: 17px;

  display: flex;
  align-items: center;
  text-align: right;

  color: #FFFFFF;

  img {
    margin: 10px 15px 10px 0px;
  }
`;
export const Dropdown = styled.div`
  #manual-user-header{
    filter: invert(53%) sepia(93%) saturate(1340%) hue-rotate(126deg) brightness(85%) contrast(101%);
  }

  img {
    cursor: pointer;
  }
  padding-top: 4px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  display: inline-block;
`;
export const OpenOnboarding = styled.button`
  background: transparent;
  border: 0;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const CompanyLogo = styled.div`
  margin-right: 10px;
  img {
    height: 32px;
    width: 32px;
  }
`;
export const ButtonLogout = styled.button`
  @media only screen and (max-width: 768px) {
    display: none !important;
  }
`;
