import React from 'react';
import { Group, Image } from 'react-konva';

interface RoundedImageProps {
    x: number;
    y: number;
    width: number;
    height: number;
    radius: number;
    image: any;
}

const RoundedImage: React.FC<RoundedImageProps> = ({
  x, y, width, height, radius, image,
}) => (
  <Group
    clipFunc={(ctx: any) => {
      ctx.beginPath();
      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height,
      );
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
      ctx.closePath();
    }}
  >
    <Image
      x={x - 5}
      y={y}
      width={width + 10}
      height={height}
      image={image}
    />
  </Group>
);

export default RoundedImage;
