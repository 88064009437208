function hasDuplicates(arr: any[]) {
  const originalLength = arr.length;
  const newArrayStructure = arr.map((item) => String(item.get().name).toLowerCase());

  const removedDuplicated = newArrayStructure.filter(
    (item, pos) => newArrayStructure.indexOf(item) === pos,
  );
  return !(originalLength === removedDuplicated.length);
}

function PlacementCreationValidator(
  positionName: string,
  limitHeadcount: number,
  selectedTeams: {
    label: string,
    value: string | number
  }[],
  selectedType: {
    label: string,
    value: string | number
  },
  multipleTeamsOptionSelected: number,
  Occupations: { get: () => number; }[],
  positionsHasId: boolean,
  SeatsIdentifiersState: {
    id?: number,
    name: string,
  }[],
  PositionsAvailability: any,
  addToast: Function,
  t: Function,
  firstSelectedDayIndex: number,
  limitMaximumOccupation: number | undefined,
) {
  let hasError = false;

  if (positionName.length < 1) {
    hasError = true;
    addToast({
      description: t('AddLocation.tabs.AddPosition.error.position'),
      type: 'error',
    });
  }

  if (limitHeadcount === 0) {
    hasError = true;
    addToast({
      description: t('AddLocation.tabs.AddPosition.error.limit_headcount'),
      type: 'error',
    });
  }

  if (!selectedTeams || selectedTeams.length === 0) {
    hasError = true;
    addToast({
      description: t('AddLocation.tabs.AddPosition.error.team'),
      type: 'error',
    });
  }

  if (multipleTeamsOptionSelected === 1) {
    let hasZero = false;
    Occupations?.map((content: { get: () => number; }) => {
      if (Number(content.get()) === 0) hasZero = true;
      return true;
    });
    if (hasZero) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.max_team_headcount'),
        type: 'error',
      });
    }
  }

  if (positionsHasId) {
    let hasEmpty = false;
    SeatsIdentifiersState.map((item: any) => {
      if (item.get().name.length === 0) hasEmpty = true;
      return true;
    });

    if (hasEmpty) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.positions_id'),
        type: 'error',
      });
    }

    if (hasDuplicates(SeatsIdentifiersState)) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.equal_position'),
        type: 'error',
      });
    }
  }

  PositionsAvailability.get().forEach((availability: any) => {
    if (availability.endDate
        && new Date(availability.endDate) <= new Date(availability.startDate)) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.final_date'),
        type: 'error',
      });
    }

    if (availability.selectedDays.length === 0) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.selected_days'),
        type: 'error',
      });
    }

    let isEmpty = false;
    let isSameHour = false;
    let missingValue = false;
    let timeExceed = false;

    availability.selectedDays.forEach((day: number) => {
      if (!availability.equalDivided) {
        if (!availability?.schedule[day]?.end && !availability?.schedule[day]?.fullTime) {
          isEmpty = true;
        }
        if (availability?.schedule[day]?.start === availability?.schedule[day]?.end
            && !availability?.schedule[day]?.fullTime) {
          isSameHour = true;
        }
        if (availability?.schedule[day]?.start?.includes('_')
            || (availability?.schedule[day]?.end && availability?.schedule[day]?.end.includes('_'))) {
          missingValue = true;
        }

        if (!availability.schedule[day].fullTime) {
          const start = availability?.schedule[day]?.start.replace(/[^0-9]/g, '');
          const end = availability?.schedule[day]?.end ? availability.schedule[day].end.replace(/[^0-9]/g, '') : '00:00:00';

          const startHour = start.slice(0, 2);
          const startMinutes = start.replace(/[^0-9]/g, '').slice(2, 4);
          const endHour = end.replace(/[^0-9]/g, '').slice(0, 2);
          const endMinutes = end.replace(/[^0-9]/g, '').slice(2, 4);

          if ((Number(startHour) > 23 || Number(startMinutes) > 59)
              || (end && (Number(endHour) > 23 || Number(endMinutes) > 59))) {
            timeExceed = true;
          }
        }
      }
    });

    if (availability.equalDivided) {
      if (!availability?.schedule[firstSelectedDayIndex]?.end
          && !availability?.schedule[firstSelectedDayIndex]?.fullTime) {
        isEmpty = true;
      }
      if (availability?.schedule[firstSelectedDayIndex]?.start
          === availability?.schedule[firstSelectedDayIndex]?.end
          && !availability?.schedule[firstSelectedDayIndex]?.fullTime) {
        isSameHour = true;
      }

      if (availability?.schedule[firstSelectedDayIndex]?.start.includes('_')
          || (availability?.schedule[firstSelectedDayIndex]?.end && availability?.schedule[firstSelectedDayIndex]?.end.includes('_'))) {
        missingValue = true;
      }

      if (!availability?.schedule[firstSelectedDayIndex]?.fullTime) {
        const start = availability?.schedule[firstSelectedDayIndex]?.start.replace(/[^0-9]/g, '');
        const end = availability?.schedule[firstSelectedDayIndex]?.end ? availability?.schedule[firstSelectedDayIndex]?.end.replace(/[^0-9]/g, '') : null;

        const startHour = start.slice(0, 2);
        const startMinutes = start.replace(/[^0-9]/g, '').slice(2, 4);
        const endHour = end ? end.replace(/[^0-9]/g, '').slice(0, 2) : null;
        const endMinutes = end ? end.replace(/[^0-9]/g, '').slice(2, 4) : null;

        if ((Number(startHour) > 23 || Number(startMinutes) > 59)
            || (end && (Number(endHour) > 23 || Number(endMinutes) > 59))) {
          timeExceed = true;
        }
      }
    }

    if (isEmpty) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.all_weekdays_hour'),
        type: 'error',
      });
    }

    if (isSameHour) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.different_hour'),
        type: 'error',
      });
    }

    if (missingValue) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.missing_hour_value'),
        type: 'error',
      });
    }

    if (timeExceed) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.time_exceed'),
        type: 'error',
      });
    }

    if (availability.schedule.length === 0) {
      hasError = true;
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.selected_days'),
        type: 'error',
      });
    }
  });

  if (limitMaximumOccupation !== undefined) {
    if (Number.isNaN(limitMaximumOccupation)
        || limitMaximumOccupation < 0
        || limitMaximumOccupation > 100) {
      hasError = true;
    }
  }

  return hasError;
}

export default PlacementCreationValidator;
