import styled from 'styled-components';

interface Error {
  error?: any;
}

const Image = styled.img<Error>`
    position: relative;
    float: right;
    margin-left: -25px;
    margin-right: 15px;
    margin-top: ${(props) => (Object(props.error).password ? '-50px' : '-28px')};
    cursor: pointer;
    z-index: 2;
`;

export default Image;
