export default function abbreviate(
  word: string,
  maxLength: number,
  options?: { showLastWorld: boolean },
) {
  const wordSplit = word.split(' ');
  const firstWord = wordSplit[0];
  const lastWorld = wordSplit.length > 1 ? wordSplit[wordSplit.length - 1] : '';
  const joinedWords = `${firstWord}${
    options?.showLastWorld ? ` ${lastWorld}` : ''
  }`;
  let finalWord;

  if (joinedWords.length > maxLength) {
    finalWord = `${joinedWords.slice(0, maxLength)}...`;
  } else {
    finalWord = joinedWords;
  }

  return finalWord;
}
