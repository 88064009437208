import React, { useMemo } from 'react';
import { useDrag } from 'react-dnd';
import DragTypes from 'src/contracts/models/DragTypes';
import { DayKeys } from 'src/contracts/models/Day';
import ShiftModel from 'src/contracts/models/Shift';
import dateFnsFormat from 'date-fns/format';
import { ButtonNewReservation, ShiftContainer, ShiftText } from './style';
import { useCalendarContext } from './context';

interface ShiftProps {
  shiftData: ShiftModel;
  day: Date;
  dayName: DayKeys;
  color: string;
  onDoubleClick?:
    | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void)
    | undefined;
  isAllowFractionalShift: boolean;
}
const Shift: React.FC<ShiftProps> = ({
  shiftData, color, onDoubleClick, isAllowFractionalShift,
}) => {
  const [dragCollected, dragRef] = useDrag({
    item: {
      type: DragTypes.SHIFT,
      employee: {
        id: shiftData?.employees?.[0].id,
        name: shiftData?.employees?.[0].name,
        teamId: shiftData?.employees?.[0].teamId,
        teamName: shiftData?.employees?.[0].teamName,
      },
      shift: shiftData,
    },

    canDrag: Boolean(shiftData !== null && shiftData?.isManageable),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const { selectedShifts } = useCalendarContext();

  const isSelected = useMemo(() => {
    if (!shiftData.employees?.length && !selectedShifts?.[shiftData.id]) {
      return false;
    }

    return Boolean(selectedShifts[shiftData.id]?.id);
  }, [selectedShifts?.[shiftData.id]]);

  const formatDateString = (firstDate: any, secondDate: any) => {
    const firstFormat = dateFnsFormat(new Date(firstDate), 'HH:mm');
    const secondFormat = dateFnsFormat(new Date(secondDate), 'HH:mm');
    return `${firstFormat} - ${secondFormat}`;
  };

  const orderDate = (employees: any) => (
    // @ts-ignore
    employees.sort((a:any, b: any) => new Date(a.start) - new Date(b.start))
  );

  return (
    <ShiftContainer
      id={String(shiftData.id)}
      isSelected={isSelected}
      className="bordered cell"
      onDoubleClick={onDoubleClick}
      isPublished={shiftData?.isPublished}
      isDragging={dragCollected.isDragging}
      ref={dragRef}
      isManegeable={shiftData.isManageable}
      color={color}
      onClick={onDoubleClick}
    >
      {shiftData.employees && orderDate(shiftData?.employees).map((employee: any) => (
        <div className="card mb-2">
          <div className="card-body p-2">
            <ShiftText>
              {employee.name}
              {' '}
              {`(${employee.team_name})`}
            </ShiftText>
            <ShiftText>
              {formatDateString(employee.start, employee.end)}
            </ShiftText>
          </div>
        </div>
      ))}
      {isAllowFractionalShift && (
      <div className="d-flex justify-content-between mt-2">
        <span className="text-muted">Vaga disponível</span>
        <ButtonNewReservation
          className="button rounded-circle"
          type="button"
          onClick={() => onDoubleClick}
        >
          +
        </ButtonNewReservation>
      </div>
      )}
    </ShiftContainer>
  );
};

export default Shift;
