import React from 'react';
import Pagination from 'react-js-pagination';
import { useTranslation } from 'react-i18next';
import icons from 'src/assets/icons';
import { PaginationContainer, TotalText, Icon } from './style';

interface PaginationProps {
    activePage: number;
    itemsCountPerPage: number;
    actualItemsCount: number;
    totalItemsCount: number;
    onPageChange: (pageNumber: number) => void;
  }

const PaginationCustom: React.FC<PaginationProps> = ({
  activePage,
  itemsCountPerPage,
  actualItemsCount,
  totalItemsCount,
  onPageChange,
}) => {
  const { t } = useTranslation('components');

  const onChange = (pageNumber: number) => {
    onPageChange(pageNumber);
  };

  return (
    <PaginationContainer>
      <TotalText>
        {t('Pagination.total_text', {
          actualItemsCount,
          totalItemsCount,
        })}
      </TotalText>
      <Pagination
        activePage={activePage}
        itemsCountPerPage={Number(itemsCountPerPage)}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={3}
        onChange={onChange}
        nextPageText={<Icon src={icons.SingleArrow} />}
        prevPageText={<Icon rotated src={icons.SingleArrow} />}
        firstPageText={<Icon rotated src={icons.DoubleArrow} />}
        lastPageText={<Icon src={icons.DoubleArrow} />}
      />
    </PaginationContainer>
  );
};

export default PaginationCustom;
