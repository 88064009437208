import styled from 'styled-components';
import colors from '../../colors';

interface TabProps {
    active: boolean;
}
export const Screen = styled.div`
    display: flex;
    flex-direction: row;
`;

export const SideBar = styled.div`
    position: fixed;
    height: 100vh;
    width: 270px;
    background-color: ${colors.white};
    border: 1px solid ${colors.white15};
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
`;

export const Body = styled.div`
    display: flex;
    width: 100vw;
    height: 100vh;
    margin-left: 270px;
`;

export const Tab = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 60px;
    width: 85%;
    border-bottom: 1px solid ${colors.white20};

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 

`;
export const TabIcon = styled.img<TabProps>`
    height: 15px;
    margin-right: 10px;
    filter: ${(props) => (props.active
    ? 'invert(32%) sepia(99%) saturate(1106%) hue-rotate(185deg) brightness(94%) contrast(91%)'
    : 'invert(51%) sepia(6%) saturate(1025%) hue-rotate(190deg) brightness(89%) contrast(82%)')};
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
`;
export const TabLabel = styled.div<TabProps>`
     font: normal normal bold 13px/15px lato;
     color: ${(props) => (props.active ? 'var(--system-blue)' : 'var(--secondary-gray)')};
`;

export const LocationName = styled.h5`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  color: #000E4C;
`;

export const SubTitle = styled.p`
    font: normal normal 400 15px lato;
    line-height: 22px;

    color: #575757;
`;

export const SectionTitle = styled.div`
    width: 100%;
    min-height: 43px;
    background: #F2F4FF !important;
    border-radius: 4px;
    
    .sub-text {
        margin-left: 5px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #000E4C;
    }
`;
