import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ProfessionalImage = styled.img`
  border-radius: 184px;
  width: 184px;
  height: 184px;
  object-fit: cover;
  border: solid 2px #fff;
`;

export const ProfessionalNonImage = styled.div`
  border-radius: 184px;
  width: 184px;
  height: 184px;
  font-size: 50px;
  @media only screen and (max-width: 768px) {
    border-radius: 50px !important;
    width: 50px !important;
    height: 50px !important;
    font-size: 25px !important;
  }
  background-color: #676C7D;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

export const Forms = styled.form`
`;

export const FormChild = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const InputLabel = styled.span`
  color: #727789;
  font: normal normal 600 12px/19px lato;
  max-width: 100px;
  width: 100%;
`;

export const RequiredLabel = styled.span`
  color: #727789;
  font: normal normal 600 12px/15px lato;
  font-weight: 100;
  max-width: 100px;
  width: 100%;
`;

export const DisableLabel = styled.span`
  color: #ee0707;
  font: normal normal 600 12px/15px lato;
  font-weight: 100;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 35px;
  color: var(--primary-gray);
  text-align: left;
  padding-left: 10px;
  border: 1px solid #e8e8e8;

  :invalid {
    box-shadow: 0 0 0.5em red;
    border-color: red;
  }
`;
