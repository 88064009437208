import React, { FC, useEffect, useState } from 'react';
import Joyride, {
  ACTIONS, CallBackProps, LIFECYCLE, STATUS,
} from 'react-joyride';
import { useTranslation } from 'react-i18next';
import { Button, Skip } from './style';
import icons from '../../assets/icons';
import {
  CalendarStep, ChatStep, MenuStep, PanelStep, SpacesStep, TeamsStep, WelcomeStep,
} from './Steps';
import { useToast } from '../../hooks/toast';
import { getUser } from '../../services/auth';
import OnboardingService from '../../services/OnboardingService';

interface props {
  run: boolean
  setRun: (value: boolean) => void;
}
const GuidedOnboarding:FC<props> = ({ run, setRun }) => {
  const [tutorialDone, setTutorialDone] = useState(false);
  const { t } = useTranslation('components');
  const { addToast } = useToast();
  const user = getUser();

  const showMessage = () => {
    if (!tutorialDone && !localStorage.getItem('espacos@tutorial_done')) {
      OnboardingService.setTutorial(true);
    }
    localStorage.setItem('espacos@tutorial_done', 'FINISHED');
    addToast({
      title: t('GuidedOnboarding.SkipMessage.title'),
      description: t('GuidedOnboarding.SkipMessage.description'),
      type: 'default',
    });
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, action, lifecycle } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if ((action === ACTIONS.CLOSE
      && lifecycle === LIFECYCLE.COMPLETE)
      || status === STATUS.FINISHED) {
      setRun(false);
      showMessage();
    }

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };

  const locale = {
    skip: <Skip aria-label="skip" onClick={() => showMessage()}>{t('GuidedOnboarding.Button.skip')}</Skip>,
    back: (
      <Button type="button" className="bg-white" back>
        <img className="me-2" src={icons.backIconArrow} alt="Back" />
        {t('GuidedOnboarding.Button.back')}
      </Button>),
    next: (
      <Button type="button">
        {t('GuidedOnboarding.Button.next')}
        <img className="ms-2" src={icons.nextIcon} alt="Next" />
      </Button>),
  };

  const defaultStepProps = {
    locale,
    floaterProps: {
      disableAnimation: false,
    },
    spotlightPadding: 20,
  };

  useEffect(() => {
    if (user) {
      setTutorialDone(user.employee?.tutorial_done === 1);
    }
  }, []);

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      hideCloseButton={false}
      run={user?.isAdmin ? run : false}
      scrollToFirstStep
      showProgress={false}
      showSkipButton
      disableOverlayClose
      steps={[
        {
          content: <WelcomeStep translate={t} currentStep={1} finishStep={7} />,
          locale,
          placement: 'center',
          target: 'body',
        },
        {
          content: <TeamsStep translate={t} currentStep={2} finishStep={7} />,
          placement: 'right',
          ...defaultStepProps,
          target: '#teams-menu',
        },
        {
          content: <SpacesStep translate={t} currentStep={3} finishStep={7} />,
          placement: 'right',
          ...defaultStepProps,
          target: '#spaces-menu',
        },
        {
          content: <CalendarStep translate={t} currentStep={4} finishStep={7} />,
          placement: 'right',
          ...defaultStepProps,
          target: '#calendar-menu',
        },
        {
          content: <PanelStep translate={t} currentStep={5} finishStep={7} />,
          placement: 'right',
          ...defaultStepProps,
          target: '#panel-menu',
        },
        {
          content: <MenuStep translate={t} currentStep={6} finishStep={7} />,
          ...defaultStepProps,
          target: '#manual-user-header',
          placement: 'right-end',
          styles: {
            spotlight: {
              marginTop: 5,
            },
          },
        },
        {
          content: <ChatStep translate={t} currentStep={7} finishStep={7} />,
          placement: 'left-end',
          ...defaultStepProps,
          target: '.md-chat-widget-btn-icon',
          styles: {
            spotlight: {
              marginTop: 15,
              marginLeft: 17,
            },
            buttonNext: {
              display: 'none',
            },
          },
        },
      ]}
      styles={{
        options: {
          zIndex: 10000,
          width: 550,
        },
        buttonNext: {
          background: 'transparent',
        },
        buttonClose: {
          width: 15,
          height: 15,
          background: '#00D4A9',
          borderRadius: '100%',
          right: -20,
          top: -15,
          color: 'white',
        },
        spotlight: {
          marginLeft: 2,
          minHeight: 55,
          minWidth: 55,
          width: 55,
          height: 55,
          maxHeight: 55,
          maxWidth: 55,
          borderRadius: '100%',
        },
      }}
    />
  );
};
export default GuidedOnboarding;
