/* eslint-disable camelcase */
import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import Team from 'src/contracts/models/Team';
import TeamService from 'src/services/TeamService';
import {
  Row, Col, Container,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import ModalTeam from 'src/components/ModalTeam';
import { formatTeamsSimple } from 'src/utils/team.functions';
import KeysToCamelCase from 'src/utils/keysToCamelCase';

import useDebounce from 'src/hooks/useDebounce';
import ModalTeamDelete from 'src/components/ModalTeamDelete';
import LocationSelector from 'src/components/LocationSelector';
import PlacementSelector from 'src/components/PlacementSelector';
import ButtonLoader from 'src/components/ButtonLoader';
import Loader from 'src/components/Loader';
import CustomSearchInput from '../../components/CustomSearchInput';
import TeamInfo from '../../components/TeamInfo';
import MainNavBar from '../../components/MainNavBar';

import Pagination from '../../components/Pagination';

import icons from '../../assets/icons';
import {
  Screen, Header, Body, BodyPaginate, LoaderContainer,
} from './style';
import { useGlobal } from '../../hooks/useGlobal';
import Guidance from '../../components/Guidance';
import {
  GuideAddEmployeeTeam,
  GuideCreateTeamButtom,
  GuideLocationSelector,
  GuideTeamInfoAccordion,
} from '../../components/Guidance/TeamsSteps';
import { getGuide } from '../../services/auth';

function Teams() {
  const guideSteps = getGuide();
  const status = (guideSteps?.nextStep === 'teams' && guideSteps.steps.teams === false);
  const searchParams = new URLSearchParams(useLocation().search);
  const addingTeam = searchParams.get('addingTeam');
  const { addToast } = useToast();
  const { sideMenuIsOpen } = useGlobal();
  const { t } = useTranslation('pages');
  const translation = useTranslation('components');
  const [modalTeam, setModalTeam] = useState(false);
  const [modalTeamDelete, setModalTeamDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState<Team | null>(null);
  const [selectedTeamToDelete, setSelectedTeamToDelete] = useState<Team | null>(
    null,
  );
  const [search, setSearch] = useState<string>('');
  const [teams, setTeams] = useState<Team[] | null>(null);
  const [paginateData, setPaginateData] = useState<any>(null);
  const [runGuidance, setRunGuidance] = useState(status);
  const [page, setPage] = useState(1);
  const perPage = 10;
  const debouncedSearch = useDebounce(search, 500);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState<any>();
  const [selectedPlacement, setSelectedPlacement] = useState<any>();

  async function getTeams() {
    const locationId = selectedLocation?.location_id
      ? selectedLocation.location_id
      : undefined;

    const placementId = selectedPlacement?.placement_id
      ? selectedPlacement.placement_id
      : undefined;

    const searchValue = debouncedSearch.length ? debouncedSearch : undefined;

    setIsLoading(true);

    TeamService.getTeamsPaginated(
      page,
      perPage,
      searchValue,
      locationId,
      placementId,
    )
      .then((res: any) => {
        setPaginateData(KeysToCamelCase(res.data));
        setTeams(formatTeamsSimple(res.data.data));
      })
      .catch(() => {
        addToast({
          description: t('Teams.error_load_teams'),
          type: 'error',
        });
      }).finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getTeams();
  }, [page, perPage, debouncedSearch, selectedLocation, selectedPlacement]);

  useEffect(() => {
    if (addingTeam) {
      setModalTeam(true);
    }
  }, [addingTeam]);

  const onEditTeamSuccess = (editedTeam: Team) => {
    if (teams) {
      setTeams((prev) => prev!.map((team) => (team.id === editedTeam.id
        ? {
          ...team,
          name: editedTeam.name,
        }
        : team)));
    }
  };

  const onDeleteTeam = useCallback(async () => {
    setDeleteLoading(true);
    try {
      await TeamService.deleteTeam(Number(selectedTeamToDelete?.id));
      setModalTeamDelete(false);
      setSelectedTeamToDelete(null);
      await getTeams();
      addToast({
        description: t('Teams.deleted_team_success'),
        type: 'success',
      });
    } catch (error: any) {
      addToast({
        description: error?.response?.data?.message || t('Teams.deleted_team_error'),
        type: 'error',
      });
    } finally {
      setDeleteLoading(false);
    }
  }, [selectedTeamToDelete, addToast]);

  if (isLoading) {
    <Loader />;
  }

  const steps = [
    {
      content: <GuideLocationSelector translate={translation.t} currentStep={1} finishStep={4} />,
      placement: 'right-start',
      target: '#location-selector',
      disableBeacon: true,
    },
    {
      content: <GuideTeamInfoAccordion translate={translation.t} currentStep={2} finishStep={4} />,
      placement: 'auto',
      target: '#team-info-0',
    },
    {
      content: <GuideCreateTeamButtom translate={translation.t} currentStep={3} finishStep={4} />,
      placement: 'right-start',
      target: '#create-team',
    },
    {
      content: <GuideAddEmployeeTeam translate={translation.t} currentStep={4} finishStep={4} />,
      placement: 'right-start',
      target: '#add_new_employee',
      styles: {
        buttonNext: {
          display: 'none',
        },
      },
    },
  ];

  useEffect(() => {
    setRunGuidance(status);
    window.scrollTo(0, 0);
  });

  return (
    <>
      <MainNavBar />
      <Guidance
        run={runGuidance}
        setRun={setRunGuidance}
        steps={steps}
        actualStep="teams"
        nextStep="calendar"
      />
      <Screen className={sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'}>
        <Header>
          <Container fluid style={{ paddingRight: 0, paddingLeft: 0 }}>
            <Row>
              <Col md="12" xs="12" lg="6" sm="12">
                <Row>
                  <Col md="6" xs="12" lg="6" sm="12" className="my-2">
                    <LocationSelector
                      onChange={(changedSelectedLocation) => {
                        setSelectedLocation(changedSelectedLocation);
                        setSelectedPlacement(undefined);
                      }}
                      value={selectedLocation}
                      isClearable={!selectedPlacement}
                    />
                  </Col>

                  <Col md="6" xs="12" lg="6" sm="12" className="my-2">
                    <PlacementSelector
                      onChange={(changedSelectedPlacement) => {
                        setSelectedPlacement(changedSelectedPlacement);
                      }}
                      value={selectedPlacement}
                      locationId={selectedLocation?.location_id}
                    />
                  </Col>
                </Row>
              </Col>

              <Col md="12" xs="12" lg="6" sm="12" className="my-2">
                <Row>
                  <Col md="8" xs="12" lg="7" sm="12" className="my-2">
                    <CustomSearchInput
                      onChange={(value) => setSearch(value)}
                      placeholder={t('Teams.search_placeholder')}
                      width="100%"
                    />
                  </Col>

                  <Col
                    md="4"
                    sm="12"
                    xs="12"
                    lg="5"
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                    className="my-2"
                  >
                    <ButtonLoader
                      id="create-team"
                      label={t('Teams.create')}
                      icon={icons.AddIcon}
                      onClick={() => setModalTeam(!modalTeam)}
                      style={{ maxHeight: '38px', paddingTop: '10px', paddingBottom: '10px' }}
                      uppercase={false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Header>
        {!teams || isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : (
          <>
            <Body>
              {!teams || isLoading ? (
                <Loader />
              ) : (
                teams.map((team, index) => (
                  <TeamInfo
                    search={search}
                    refreshEmployees={getTeams}
                    onEdit={() => {
                      setSelectedTeam(team);
                      setModalTeam(true);
                    }}
                    onDelete={() => {
                      setSelectedTeamToDelete(team);
                      setModalTeamDelete(true);
                    }}
                    key={team.id}
                    defaultOpen={index === 0 && runGuidance}
                    team={team}
                    index={index}
                  />
                ))
              )}
              {paginateData && (
                <BodyPaginate>
                  <div className="d-flex border-top flex-column py-1 px-4">
                    <Pagination
                      activePage={Number(paginateData.currentPage)}
                      itemsCountPerPage={Number(paginateData.perPage)}
                      actualItemsCount={teams ? teams.length : 0}
                      onPageChange={(newPage: number) => setPage(newPage)}
                      totalItemsCount={paginateData.total}
                    />
                  </div>
                </BodyPaginate>
              )}
            </Body>
            {modalTeam && (
              <ModalTeam
                isOpen={modalTeam}
                editMode={!!selectedTeam}
                team={selectedTeam}
                handleCreateSuccess={() => {
                  getTeams();
                  setModalTeam(false);
                }}
                handleEditSuccess={(team: Team) => {
                  setModalTeam(false);
                  onEditTeamSuccess(team);
                  setSelectedTeam(null);
                }}
                handleCloseButton={() => {
                  setModalTeam(false);
                  setSelectedTeam(null);
                }}
              />
            )}

            {modalTeamDelete && (
              <ModalTeamDelete
                messageConfirm={t('Teams.ModalTeamDelete.message_confirm')}
                isLoading={deleteLoading}
                isOpen={modalTeamDelete}
                team={selectedTeamToDelete}
                onDelete={onDeleteTeam}
                handleCloseButton={() => {
                  setModalTeamDelete(false);
                  setSelectedTeamToDelete(null);
                }}
              />
            )}
          </>
        )}
      </Screen>
    </>
  );
}

export default Teams;
