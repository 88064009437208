import { useContext } from 'react';
import SelectedUserProvider from '../providers/selected_users_provider';

const useSelectedUsers = () => {
  const context = useContext(SelectedUserProvider.Context);

  return context;
};

export default useSelectedUsers;
