/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import Loader from 'src/components/Loader';
import metabaseService from '../../../../services/metabaseService';
import {
  Container,
  Column,
  Body,
  CardLarge,
} from '../../style';

const ShiftsReports = () => {
  const [shiftReport, setShiftReport] = useState<string>('');

  const loadUrls = async () => {
    const executedUrl = await metabaseService.getShiftReport();
    setShiftReport(executedUrl.data);
  };

  useEffect(() => {
    loadUrls();
  }, []);

  if (!shiftReport) {
    return <Loader />;
  }

  return (
    <Container>
      {shiftReport && (
        <Body>
          <Column>
            <CardLarge>
              <iframe
                src={shiftReport!}
                frameBorder="0"
                width="100%"
                height="600"
                allowTransparency
              />
            </CardLarge>
          </Column>
        </Body>
      )}
    </Container>
  );
};

export default ShiftsReports;
