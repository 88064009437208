import React, { useCallback, useEffect } from 'react';
import { useState } from '@hookstate/core';
import ButtonLoader from 'src/components/ButtonLoader';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import LightTable from 'src/components/LightTable';
import CustomSearchInput from 'src/components/CustomSearchInput';
import Pagination from 'src/components/Pagination';
import EmployeeService from 'src/services/EmployeeService';
import useDebounce from 'src/hooks/useDebounce';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { formatTeamsPermissons } from 'src/utils/team.functions';
import TeamRoles from 'src/contracts/constants/team.roles.constants';
import employeeRoles from 'src/contracts/constants/employee.roles.constants';
import { Container, NotListTeams, TextHeader } from './style';
import keysToCamel from '../../../utils/keysToCamelCase';
import Employee from '../../../contracts/models/Employee';
import ModalConfirm from '../../ConfirmModal';

interface PermissionsProps {
  employeeId: number;
}

const Permissions: React.FC<PermissionsProps> = ({ employeeId }) => {
  const header = {
    colab: false,
    orga: false,
  };
  const { t } = useTranslation('components');
  const { addToast } = useToast();
  const checkboxs = useState<
    {
      id: number;
      name: string;
      colab: boolean;
      orga: boolean;
    }[]
  >([]);

  const checkboxsHeader = useState<{ colab: boolean; orga: boolean }>(header);
  const teams = useState<any>(null);
  const page = useState(1);
  const searchName = useState<null | string>(null);
  const paginateData = useState<any>(null);
  const sendInviteCheck = useState<Boolean>(false);
  const PER_PAGE = 10;
  const isLoading = useState<boolean>(true);
  const isBottonLoading = useState<boolean>(false);
  const debouncedSearch = useDebounce(searchName.get(), 500);
  const handleChange = (newState: any) => {
    checkboxs.set((prev) => prev.map((team) => (team.id === newState.id ? newState : team)));
  };
  const isAdmin = useState<Boolean>(false);
  const employeeInfos = useState<any>({});
  const [isOneRoleOpen, setIsOneRoleOpen] = React.useState(false);
  const [isAllRoleOpen, setIsAllRoleOpen] = React.useState(false);
  const [oneRoleState, setOneRoleState] = React.useState<{
    newState: any | null;
    role: number;
    type: boolean;
  }>();
  const [allRoleState, setAllRoleState] = React.useState<{
    role: number;
    type: boolean;
  }>();

  const handleChangeHeader = (role: number, type: boolean) => {
    checkboxsHeader.set((prev) => {
      if (role === TeamRoles.COLABORATOR) {
        return {
          ...prev,
          colab: type,
        };
      }
      if (role === TeamRoles.ORGANIZER) {
        return {
          ...prev,
          orga: type,
        };
      }
      return prev;
    });
  };

  const checkUserHasPermissionAnyTeam = (data: any) => {
    const hasRoles = Object.values(data).some(
      (item: any) => item.roles.length > 0,
    );
    return hasRoles;
  };

  function checkCanSendInvite(employee: Employee, responseListPermission: any) {
    employeeInfos.set(employee);

    if (employee.email === undefined || employee.email === null) {
      sendInviteCheck.set(false);
      return;
    }

    const employeeIsAdmin = employee?.employeeRoles?.some(
      (role) => role?.id === employeeRoles.ADMIN,
    );
    isAdmin.set(employeeIsAdmin ?? false);
    const sendInvite = employeeIsAdmin || checkUserHasPermissionAnyTeam(responseListPermission);
    sendInviteCheck.set(sendInvite);
  }

  const getRoles = async () => {
    isLoading.set(true);
    try {
      let employee = (await EmployeeService.getById(employeeId))
        .data as Employee;
      employee = keysToCamel(employee);
      const responseListPermission = await EmployeeService.listPermissonsTeam(
        employeeId,
        page.get(),
        PER_PAGE,
        searchName.get() as string,
      );
      const formatedTeams = formatTeamsPermissons({
        teams: responseListPermission.data.data,
        all_roles_associated: responseListPermission.data.all_roles_associated,
      });
      checkboxs.set(formatedTeams.teams);
      teams.set(responseListPermission.data.data);
      checkboxsHeader.set(formatedTeams.header);
      paginateData.set(responseListPermission.data);
      checkCanSendInvite(employee, responseListPermission.data.data);
    } catch (error) {
      addToast({
        description: t('ModalAdminUser.permissions_tab.error_load_message'),
        type: 'error',
      });
    } finally {
      isLoading.set(false);
    }
  };

  useEffect(() => {
    if (checkboxs.get().length > 0) getRoles();
  }, [page.get()]);

  useEffect(() => {
    if (debouncedSearch?.length) {
      page.set(1);
      getRoles();
      return;
    }
    getRoles();
  }, [debouncedSearch]);

  const handleChangeOneRole = (newState: any, role: number, type: boolean) => {
    handleChange(newState);
    if (!type) {
      handleChangeHeader(role, false);
    }

    EmployeeService.addSingleRoleTeam({
      employee_id: employeeId,
      role_id: role,
      team_id: newState.id,
      type: type ? 'ADD' : 'DELETE',
    })
      .then(() => {
        addToast({
          description: t('ModalAdminUser.permissions_tab.success_save_message'),
          type: 'success',
        });
      })
      .catch(() => {
        if (role === TeamRoles.COLABORATOR) {
          handleChange({ ...newState, colab: !type });
        } else if (role === TeamRoles.ORGANIZER) {
          handleChange({ ...newState, orga: !type });
        }
        addToast({
          description: t('ModalAdminUser.permissions_tab.error_save_message'),
          type: 'error',
        });
      });

    if (
      employeeInfos.get().email === undefined
      || employeeInfos.get().email === null
    ) {
      sendInviteCheck.set(false);
    } else {
      const sendInvite = checkboxs
        .get()
        .some((checkbox: any) => checkbox.colab || checkbox.orga);
      sendInviteCheck.set(sendInvite || isAdmin.get());
    }

    setIsOneRoleOpen(false);
    setIsAllRoleOpen(false);
  };

  const handleChangeAllRole = (role: number, type: boolean) => {
    handleChangeHeader(role, type);
    isLoading.set(true);
    EmployeeService.addSingleRoleTeam({
      employee_id: employeeId,
      role_id: role,
      type: type ? 'ADD' : 'DELETE',
    })
      .then(() => {
        getRoles();
        addToast({
          description: t('ModalAdminUser.permissions_tab.success_save_message'),
          type: 'success',
        });
      })
      .catch(() => {
        isLoading.set(false);
        handleChangeHeader(role, !type);
        addToast({
          description: t('ModalAdminUser.permissions_tab.error_save_message'),
          type: 'error',
        });
      });
    setIsAllRoleOpen(false);
  };

  const handleInviteEmailWelcome = () => {
    isBottonLoading.set(true);
    EmployeeService.inviteEmployee({
      employeeId,
    })
      .then(() => {
        addToast({
          description: t('ModalAdminUser.permissions_tab.success_invite'),
          type: 'success',
        });
      })
      .catch(() => {
        addToast({
          description: t('ModalAdminUser.permissions_tab.error_invite'),
          type: 'error',
        });
      })
      .finally(() => {
        isBottonLoading.set(false);
      });
  };

  function handleChangeRole(newState: any, role: number, type: boolean) {
    setOneRoleState({
      newState,
      role,
      type,
    });

    if (!type && role === 1) {
      setIsOneRoleOpen(true);
      return;
    }

    handleChangeOneRole(newState, role, type);
  }

  function handleClickAllRoles(role: number, type: boolean) {
    setAllRoleState({
      role,
      type,
    });

    if (!type && role === 1) {
      setIsAllRoleOpen(true);
      return;
    }

    handleChangeAllRole(role, type);
  }

  const handleConfirmIsOneRoleOpenModal = useCallback(() => {
    if (oneRoleState) {
      handleChangeOneRole(
        oneRoleState.newState,
        oneRoleState.role,
        oneRoleState.type,
      );
    }
  }, [oneRoleState]);

  const handleCloseIsOneRoleOpenModal = useCallback(() => {
    setIsOneRoleOpen(false);
  }, []);

  return (
    <Container>
      <div
        style={{ maxHeight: '50vh' }}
        className=" overflow-auto employee-info w-100 d-flex flex-column py-4 px-4"
      >
        <LightTable
          align="left"
          layout="fixed"
          isLoading={isLoading.get()}
          header={[
            [
              <div>
                <CustomSearchInput
                  width="100%"
                  placeholder={t(
                    'ModalAdminUser.permissions_tab.placeholder_team',
                  )}
                  onChange={(value) => searchName.set(value)}
                />
              </div>,
              <FormControlLabel
                disabled={isLoading.get()}
                control={(
                  <Checkbox
                    checked={checkboxsHeader.get().colab}
                    color="secondary"
                    onChange={() => handleClickAllRoles(
                      TeamRoles.COLABORATOR,
                      !checkboxsHeader.get().colab,
                    )}
                  />
                )}
                label={(
                  <TextHeader>
                    {t('ModalAdminUser.permissions_tab.colab_title')}
                  </TextHeader>
                )}
              />,
              <FormControlLabel
                disabled={isLoading.get()}
                control={(
                  <Checkbox
                    checked={checkboxsHeader.get().orga}
                    color="secondary"
                    onChange={() => handleClickAllRoles(
                      TeamRoles.ORGANIZER,
                      !checkboxsHeader.get().orga,
                    )}
                  />
                )}
                label={(
                  <TextHeader>
                    {t('ModalAdminUser.permissions_tab.orga_title')}
                  </TextHeader>
                )}
              />,
            ],
          ]}
          body={
            teams
              ? checkboxs.get().map((checkbox) => [
                <div>{checkbox.name}</div>,
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={checkbox.colab}
                      color="primary"
                      onChange={() => handleChangeRole(
                        {
                          ...checkbox,
                          colab: !checkbox.colab,
                        },
                        TeamRoles.COLABORATOR,
                        !checkbox.colab,
                      )}
                    />
                    )}
                  label={t('ModalAdminUser.permissions_tab.colab_title')}
                />,
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={checkbox.orga}
                      color="primary"
                      onChange={() => handleChangeRole(
                        {
                          ...checkbox,
                          orga: !checkbox.orga,
                        },
                        TeamRoles.ORGANIZER,
                        !checkbox.orga,
                      )}
                    />
                    )}
                  label={t('ModalAdminUser.permissions_tab.orga_title')}
                />,
              ])
              : [
                [
                  <NotListTeams>
                    <div className="p-3 font-weight-bold d-flex flex-column align-items-center bg-white">
                      {t('TeamInfo.empty_teams')}
                      <a className="btn btn-primary mt-2" href="/teams">
                        {t('TeamInfo.button_create_teams')}
                      </a>
                    </div>
                  </NotListTeams>,
                ],
              ]
          }
        />
      </div>
      {paginateData.get() && (
        <div className="d-flex border-top flex-column py-1 px-4">
          <Pagination
            activePage={Number(paginateData.get().current_page)}
            itemsCountPerPage={Number(paginateData.get().per_page)}
            actualItemsCount={checkboxs.get().length}
            onPageChange={(newPage: number) => page.set(newPage)}
            totalItemsCount={paginateData.get().total}
          />
        </div>
      )}
      {sendInviteCheck.get() && (
        <ButtonLoader
          onClick={handleInviteEmailWelcome}
          isLoading={isBottonLoading.get()}
          color="primary"
          label={t('ModalAdminUser.button_send_invite')}
          className="m-4"
        />
      )}
      {oneRoleState && isOneRoleOpen && (
        <ModalConfirm
          center
          isOpen={isOneRoleOpen}
          handleConfirm={handleConfirmIsOneRoleOpenModal}
          handleCloseButton={handleCloseIsOneRoleOpenModal}
          message={t(
            'ModalAdminUser.permissions_tab.confirm_disassociate_user',
          )}
        />
      )}
      {allRoleState && isAllRoleOpen && (
        <ModalConfirm
          isOpen={isAllRoleOpen}
          handleConfirm={() => handleChangeAllRole(allRoleState.role, allRoleState.type)}
          handleCloseButton={() => setIsAllRoleOpen(false)}
          message={t(
            'ModalAdminUser.permissions_tab.confirm_disassociate_all_user',
          )}
        />
      )}
    </Container>
  );
};

export default Permissions;
