import React, {
  useContext, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import LocationInformation from './tabs/GeneralInformations';
import AddLocationProvider, { AddLocationContext } from './Context';
import MainNavBar from '../../components/MainNavBar';
import icons from '../../assets/icons';
import { LocationName, SectionTitle, SubTitle } from './style';
import ButtonLoader from '../../components/ButtonLoader';
import Accordion from '../../components/Accordion/Accordion';
import PlacementService from '../../services/PlacementService';
import KeysToCammel from '../../utils/keysToCamelCase';
import { useToast } from '../../hooks/toast';
import SubAccordion from '../../components/Accordion/SubAccordion';
import AddPositionTab from './tabs/AddPosition';
import ConfigVacancies from './ConfigVacancies';
import Icons from '../../assets/icons';
import ModalConfirm from '../../components/ConfirmModal';

function AddLocation({ id }: {id: string}) {
  const { addToast } = useToast();
  const history = useHistory();
  const [selectedPlacementId, setSelectedPlacementId] = useState(0);
  const [placementIdEdited, setPlacementIdEdited] = useState(0);
  const [newPlacement, setNewPlacement] = useState<any[]>([]);
  const [placements, setPlacements] = useState<any[]>([]);
  const [spaceName, setSpaceName] = useState<string>('');
  const [spaceVacancie, setSpaceVacancie] = useState<number>(0);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [placementToDelete, setPlacementToDelete] = useState<number | null>(null);
  const { t } = useTranslation('pages');

  const {
    setLocationId, locationName, setSelectedPlacement, setEditMode, locationId,
  } = useContext(AddLocationContext);

  const loadData = async () => {
    PlacementService.getPlacementsFromLocation(id).then((response) => {
      setPlacements(KeysToCammel(response.data.placements));
    }).catch(() => {
      addToast({
        description: t('Location.all_positions.errors.load'),
        type: 'error',
      });
    });
  };

  useEffect(() => {
    setSpaceVacancie(0);
    setSpaceName('');
  }, [placementIdEdited]);

  useEffect(() => {
    if (id) {
      setLocationId?.(Number(id));
      loadData();
    }
  }, [id]);

  const goToCreation = async (placementId?: number) => {
    if (placementId) {
      setSelectedPlacement?.(placementId);
      setEditMode?.(true);
      setPlacementIdEdited(placementId);
    } else {
      setEditMode?.(false);
    }
  };

  const addNewVacancie = () => {
    const newVacancie = {
      config: {
        hasConfig: false,
        configId: null,
      },
      locationId: id,
      locationName: '',
      placementId: null,
      placementMaxVacancy: 0,
      placementName: '',
      placementType: '',
      teams: [],
    };
    setNewPlacement([newVacancie]);
    setPlacementIdEdited(0);
    goToCreation(Number(newVacancie.placementId));
  };

  const getSubTitle = (vacancie: number, spaceId: number) => {
    if (placementIdEdited === spaceId) {
      if (spaceVacancie === 1) return `${spaceVacancie || vacancie} Vaga`;
      if (spaceVacancie > 1) return `${spaceVacancie || vacancie} Vagas`;
    }
    if (vacancie === 1) return `${vacancie} Vaga`;
    if (vacancie > 1) return `${vacancie} Vagas`;
    return '';
  };

  const deletePlacement = () => {
    if (placementToDelete) {
      PlacementService.deletePlacement(placementToDelete.toString(), locationId.toString()).then(
        () => {
          loadData().then(() => {});
          setModalOpen(false);
          addToast({
            description: t('Location.all_positions.success_delete'),
            type: 'success',
          });
        },
      ).catch(() => {
        addToast({
          description: t('Location.all_positions.errors.delete'),
          type: 'error',
        });
      });
    }
  };

  const getTitle = (name: string, spaceId: number) => {
    if (placementIdEdited === spaceId) {
      return spaceName || name;
    }
    if (name) return name;
    return 'Nome do espaço';
  };

  return (
    <>
      {modalOpen && (
        <ModalConfirm
          isOpen={modalOpen}
          handleConfirm={() => deletePlacement()}
          handleCloseButton={() => {
            setPlacementToDelete(0);
            setModalOpen(false);
          }}
          title={t('Location.all_positions.warning')}
          message={t('Location.all_positions.delete_message')}
        />
      )}
      <MainNavBar />
      <div className="sidebar-container pe-5 overflow-auto">
        <div className="row m-lg-5 mt-5 me-1">
          <div className="col-12 d-flex">
            <button
              type="button"
              className="bg-transparent border-0"
              onClick={() => history.goBack()}
            >
              <img
                src={icons.ArrowRoundBack}
                alt="Back"
                className="filter-primary-color"
              />
            </button>
            <LocationName className="ms-3 mb-0">{locationName.length > 0 ? locationName : 'Nome do espaço'}</LocationName>
          </div>
          <div className="col-12 mt-3">
            <SectionTitle className="d-flex">
              <img className="m-auto mx-2" src={icons.locationTitleIcon} alt="" />
              <span className="sub-text m-auto ms-0">{t('Location.general_info.location')}</span>
            </SectionTitle>
          </div>
          <div className="col-8 mt-2">
            <SubTitle className="mb-0">
              {t('Location.general_info.location_description.first')}
            </SubTitle>
            <SubTitle>
              {t('Location.general_info.location_description.second')}
            </SubTitle>
          </div>
          <div className="col-12 mt-1">
            <LocationInformation />
          </div>
          <div className="col-12 mt-3">
            <SectionTitle className="d-flex">
              <img className="m-auto mx-2" src={icons.vacancies} alt="" />
              <span className="sub-text m-auto ms-0">{t('Location.general_info.vacancies', { name: locationName.length > 0 ? locationName : 'Nome do espaço' })}</span>
              <ButtonLoader
                className="my-2"
                uppercase={false}
                label={t('Location.general_info.new_vacancies')}
                color="primary"
                icon={icons.addIconsSquare}
                onClick={() => addNewVacancie()}
              />
            </SectionTitle>
          </div>
          <div className="col-8 mt-2">
            <SubTitle className="mb-0">
              {t('Location.general_info.space_description')}
            </SubTitle>
          </div>
          <div className="col-12 my-1 py-4">
            {newPlacement
              && Object.values(newPlacement).length > 0 && Object.values(newPlacement).map((item: {
              placementId: string,
              placementName: string,
              locationName: string,
              placementMaxVacancy: number,
              placementType: string,
              teams: Object,
              config: any,
            }) => (
              <Accordion
                headerTitle={getTitle(item.placementName, Number(item.placementId))}
                headerSubTitle={getSubTitle(item.placementMaxVacancy, Number(item.placementId))}
                accordionID={`accordion-vacancies-${item.placementId}`}
                key={`accordion-vacancies-${item.placementId}-key`}
                isOpenAccordion
                callback={() => setPlacementIdEdited(Number(item.placementId))}
              >
                <>
                  <AddPositionTab
                    setSelectedPlacementId={setSelectedPlacementId}
                    selectedPlacementId={selectedPlacementId}
                    setSpaceName={setSpaceName}
                    setSpaceVacancie={setSpaceVacancie}
                    setPlacementIdEdited={setPlacementIdEdited}
                    spaceId={Number(item.placementId)}
                  />
                  {selectedPlacementId !== 0 && (
                    <SubAccordion
                      icon={icons.locationBlue}
                      headerTitle={t('Location.tabs.config')}
                      callback={() => goToCreation(Number(selectedPlacementId))}
                      accordionID={`sub-accordion-config-${selectedPlacementId}`}
                    >
                      <ConfigVacancies
                        placementId={Number(selectedPlacementId)}
                        config={item.config}
                        vacancie={item}
                      />
                    </SubAccordion>
                  )}
                </>
              </Accordion>
            ))}
          </div>
          <div className="col-12 my-1 mb-5 py-4">
            {placements
              && Object.values(placements).length > 0 && Object.values(placements).map((item: {
              placementId: string,
              placementName: string,
              locationName: string,
              placementMaxVacancy: number,
              placementType: string,
              teams: Object,
              config: any,
            }) => (
              <Accordion
                headerTitle={getTitle(item.placementName, Number(item.placementId))}
                headerSubTitle={getSubTitle(item.placementMaxVacancy, Number(item.placementId))}
                accordionID={`accordion-vacancies-${item.placementId}`}
                key={`accordion-vacancies-${item.placementId}-key`}
                callback={() => goToCreation(Number(item.placementId))}
                secondAction={(
                  <ButtonLoader
                    withHover={false}
                    transparent
                    icon={Icons.TrashRed}
                    onClick={() => {
                      setPlacementToDelete(Number(item.placementId));
                      setModalOpen(true);
                    }}
                  />
                )}
              >
                <>
                  <AddPositionTab
                    setSpaceName={setSpaceName}
                    setSpaceVacancie={setSpaceVacancie}
                  />
                  <SubAccordion
                    icon={icons.locationBlue}
                    headerTitle={t('Location.tabs.config')}
                    callback={() => goToCreation(Number(item.placementId))}
                    accordionID={`sub-accordion-config-${item.placementId}`}
                  >
                    <ConfigVacancies
                      placementId={Number(item.placementId)}
                      config={item.config}
                      vacancie={item}
                    />
                  </SubAccordion>
                </>
              </Accordion>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default ({ match }: {
  match : { params: { id: string }}
}) => (
  <AddLocationProvider>
    <AddLocation id={match.params.id} />
  </AddLocationProvider>
);
