import styled from 'styled-components';
import BackgroundImage from '../../assets/imgs/Ilustracao-erro-404-espacos.svg';
import colors from '../../colors';

export const Container = styled.div<{hasNav: boolean}>`
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    height: calc(100vh - ${(props) => (props.hasNav ? '62px' : '0px')});
`;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 40% 60%;

    @media (max-width: 870px) {
      grid-template-columns: 100% 0%;
    }
`;

export const Header = styled.div`
    width: 100%;
    padding: 14px 0;
    display: flex;
    justify-content: flex-start;
`;

export const BannerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${BackgroundImage});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

export const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const InfoContent = styled.div`
    display: flex;
    width: 70%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    img {
      margin-bottom: 20px;
    }
`;

export const Title = styled.span`
  color: ${colors.gray};
  font-size: 4rem;
  font-weight: bold;
  text-align: left;
`;

export const SubTitle = styled.p`
  display: flex;
  text-align: left;
  color: ${colors.gray};
  font-size: 3rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 50px;
`;

export const GoBackText = styled.p`
  display: flex;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  color: ${colors.blue};
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
`;
