import React, { ReactElement, useState } from 'react';
import { SectionTitle } from '../../pages/AddLocation/style';
import icons from '../../assets/icons';

export interface AccordionProps{
  headerTitle?: string;
  accordionID?: string;
  children?: ReactElement;
  icon: string;
  callback: () => void;
}

const SubAccordion: React.FC<AccordionProps> = ({
  headerTitle = 'Nova vaga', icon = icons.positions, accordionID = 'accordion-sub', children, callback,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion my-2" id={accordionID}>
      <div className="accordion-item border-0">
        <h2 className="accordion-header" id={`${accordionID}-headingOne`}>
          <button
            className="accordion-button collapsed background-blue-10 shadow-none text-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${accordionID}-collapseOne`}
            aria-expanded="false"
            aria-controls={`${accordionID}-collapseOne`}
            onClick={() => {
              callback();
              setIsOpen(true);
            }}
          >
            <SectionTitle className="d-flex">
              <img className="m-auto mx-2" src={icon} alt="" />
              <span className="m-auto ms-0">{headerTitle}</span>
            </SectionTitle>
          </button>
        </h2>
        <div
          id={`${accordionID}-collapseOne`}
          className="accordion-collapse collapse"
          aria-labelledby={`${accordionID}-headingOne`}
          data-bs-parent={`#${accordionID}`}
        >
          <div className="accordion-body">
            {isOpen && children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubAccordion;
