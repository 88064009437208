/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import {
  Screen,
  Body,
  NavContainer,
  LinksContaier,
  LinkBox,
  LinkIcon,
  LinkLabel,
} from './style';

interface CustomNavBarProps {
  pages: { label: string; icon?: string; tab: React.ReactNode }[];
  backColor?: string;
  leftSpace?: string;
  page?: number;
  justify?: string;
  onChange?: (page: number) => void;
  className?: string
  classNameBody?: string
}
function CustomNavBar({
  pages,
  backColor,
  leftSpace,
  page,
  onChange,
  justify,
  className,
  classNameBody,
}: CustomNavBarProps) {
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    if (page !== undefined) {
      setSelectedPage(page);
    } else {
      setSelectedPage(0);
    }
  }, [page]);

  useEffect(() => {
    if (onChange) {
      onChange(selectedPage);
    }
  }, [selectedPage]);

  return (
    <Screen className={className}>
      <NavContainer backColor={backColor} leftSpace={leftSpace}>
        <LinksContaier leftSpace={leftSpace} justify={justify}>
          {pages.map((item: any, index: number) => (
            <LinkBox
              selected={Number(selectedPage) === index}
              onClick={() => setSelectedPage(index)}
            >
              {pages[index].icon && (
                <LinkIcon
                  selected={Number(selectedPage) === index}
                  src={pages[index].icon}
                />
              )}
              <LinkLabel selected={Number(selectedPage) === index}>
                {item.label}
              </LinkLabel>
            </LinkBox>
          ))}
        </LinksContaier>
      </NavContainer>
      <Body className={classNameBody} leftSpace={leftSpace}>{pages[selectedPage]?.tab}</Body>
    </Screen>
  );
}

export default CustomNavBar;
