import { getUser } from '../services/auth';

export default function trimString(string?: string) {
  if (!string) return '';
  return string.replace(/\s{2,}/g, ' ').trim();
}

const concatenateCompanyCode = (string?: string) => {
  const user = getUser();
  if (user) {
    const { code } = user.employee.company;
    if (!string) return `${code}-`;
    if (string.search(`${code}-`) === -1) {
      if (string === code) {
        return `${code}-`;
      }
      return `${code}-${string.replace(/\s{2,}/g, ' ').trim()}`;
    }
    return string.replace(/\s{2,}/g, ' ').trim();
  } return trimString(string);
};

export { concatenateCompanyCode };
