/* eslint-disable import/no-duplicates */
import { addMonths, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import React from 'react';
import { NavBar } from './style';

interface NavBarProps {
    nextMonth: Date;
    previousMonth: Date;
    onPreviousClick: Function;
    onNextClick: () => void;
    className: string;
    localeUtils: any;
  }

const CalendarNavbar: React.FC<NavBarProps> = ({
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
}) => (
  <NavBar className={className}>
    <button type="button" onClick={() => onPreviousClick()}>
      ←
    </button>
    {format(addMonths(previousMonth, 1), 'MMMM yyyy', { locale: ptBR })}
    <button type="button" onClick={() => onNextClick()}>
      →
    </button>
  </NavBar>
);

export default CalendarNavbar;
