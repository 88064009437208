/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import Loader from 'src/components/Loader';
import metabaseService from '../../../../services/metabaseService';
import {
  Container,
  Column,
  Body,
  CardLarge,
} from '../../style';

const OccupationReports = () => {
  const [executedCheckInReport, setExecutedCheckInReport] = useState<string>('');

  const loadUrls = async () => {
    const checkInUrl = await metabaseService.getCheckInReport();

    setExecutedCheckInReport(checkInUrl.data);
  };

  useEffect(() => {
    loadUrls();
  }, []);

  if (!executedCheckInReport) {
    return <Loader />;
  }

  return (
    <Container>
      {executedCheckInReport && (
        <Body>
          <Column>
            <CardLarge>
              <iframe
                src={executedCheckInReport!}
                frameBorder="0"
                width="100%"
                height="600"
                allowTransparency
              />
            </CardLarge>
          </Column>
        </Body>
      )}
    </Container>
  );
};

export default OccupationReports;
