import Api from 'src/services/api';

export default class ExportService {
  static DEFAULT_URL = 'exports';

  static async downloadErrors(jobId: number) {
    const response = await Api.get(`${this.DEFAULT_URL}/imports/spreadsheet/errors/${jobId}`, {
      responseType: 'blob',
    });
    return response;
  }
}
