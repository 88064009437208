import styled from 'styled-components';
import colors from '../../../../colors';

export const Container = styled.div`
  margin-left: 10px;
  padding: 2rem 1rem;
  flex: 1;

  button {
    text-transform: initial;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 2rem;
  min-height: 80px;
  margin: 0 auto;
  background-color: ${colors.white10};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const FloatLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: black;
  font-weight: 600;
  font-family: lato;
  margin-top: 30px;
  @media only screen and (max-width: 768px) {
    margin-top: 95px !important;
  }
`;

export const Content = styled.div`
  display: flex;
  padding: 0 2rem;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const DescriptionContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10%;
`;

export const Description = styled.span`
  color: ${colors.gray};
  font-weight: 500;
  max-width: 60ch;
  text-align: left;
  font-size: 1.1rem;
  font-family: lato;
`;
