import styled from 'styled-components';
import { AsyncPaginate } from 'react-select-async-paginate';
import filter from 'src/assets/icons/filter.svg';
import colors from '../../colors';

export const Component = styled.div`
  height: 49.25px;
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 15px;
`;

export const Selector = styled(AsyncPaginate)`
  .select__control {
    overflow: auto;
    max-height: 85px;
  }
  
  > div {
    width: 100%;
    min-height: 49.25px;
    border-color: ${colors.gray28} !important;
    :nth-child(2) {
      position: absolute;
      background-image: url(${filter});
      background-repeat: no-repeat;
      background-position: left;
      background-position-x: 10px;
      box-shadow: none;
      padding-left: 35px;
    }
    .select__indicators {
      align-items: flex-start;
      padding-left: 90px;
    }
    .select__loading-indicator {
      span {
        height: 5px;
        width: 5px;
      }
    }
  }
`;
