import React, { createContext, useContext, useState } from 'react';
import { v4 } from 'uuid';
import ConfirmModal, { ModalConfirmProps } from '../components/ConfirmModal';

export interface AlertContextProps {
  openAlert: (alertProps: ModalConfirmProps) => string
  closeAlert: (alertId: string) => void
}
export const AlertContext = createContext<AlertContextProps>(null as any);

export const useAlert = () => useContext(AlertContext);

export type ModalTypes = 'confirm' | 'alert';

const AlertContextProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [alerts, setAlerts] = useState<(ModalConfirmProps & { id: string })[]>(
    []);
  const [openedAlerts, setOpenedAlerts] = useState<{ [x: string]: boolean }>({});

  const closeAlert = (alertId: string) => {
    setOpenedAlerts((prev) => ({ ...prev, [alertId]: false }));

    setTimeout(() => {
      setAlerts((prev) => prev.filter((alert) => alert.id !== alertId));
      setOpenedAlerts((prev) => {
        const copy = { ...prev };
        return copy;
      });
    }, 200);
  };

  const openAlert = (props: ModalConfirmProps) => {
    const alertId = v4();
    setAlerts((prev) => [
      ...prev,
      {
        ...props,
        id: alertId,
        handleCloseButton: () => {
          props?.handleCloseButton?.();

          closeAlert(alertId);
        },
        handleConfirm: () => {
          Promise.resolve(props?.handleConfirm?.()).then(() => {
            closeAlert(alertId);
          });
        },
      },

    ]);

    setTimeout(() => {
      setOpenedAlerts((prev) => ({ ...prev, [alertId]: true }));
    }, 100);

    return alertId;
  };

  return (
    <AlertContext.Provider
      value={{
        openAlert,
        closeAlert,
      }}
    >
      {children}
      <div>
        {Boolean(alerts.length)
          && alerts.map((alertProps) => (
            openedAlerts[alertProps.id] && (
            <ConfirmModal
              key={alertProps.id}
              {...alertProps}
              isOpen={openedAlerts[alertProps.id]}
            />
            )
          ))}
      </div>
    </AlertContext.Provider>
  );
};
export default AlertContextProvider;
