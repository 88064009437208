import alterarFoto from 'src/assets/icons/alterar-foto.svg';
import lock from 'src/assets/icons/lock.svg';
import reports from 'src/assets/icons/reports.svg';
import close from 'src/assets/icons/close.svg';
import team from 'src/assets/icons/team.svg';
import manage from 'src/assets/icons/manage.svg';
import nounGift from 'src/assets/icons/nounGift.svg';
import chartTimeline from 'src/assets/icons/chart-timeline.svg';
import featherEdit from 'src/assets/icons/feather-edit.svg';
import iconCog from 'src/assets/icons/icon-cog.svg';
import fileUpload from 'src/assets/icons/metro-file-upload.svg';
import plus from 'src/assets/icons/feather-plus.svg';
import awesomeMagic from 'src/assets/icons/awesome-magic.svg';
import awesomeEye from 'src/assets/icons/awesome-eye.svg';
import bullhorn from 'src/assets/icons/bullhorn.svg';
import bullhornPrimary from 'src/assets/icons/bullhorn-primary.svg';
import calendar from 'src/assets/icons/calendar.svg';
import calendarCheck from 'src/assets/icons/calendar-check.svg';
import calendarCheckGray from 'src/assets/icons/calendar-check-gray.svg';
import calendarClock from 'src/assets/icons/calendar-clock.svg';
import calendarClockWhite from 'src/assets/icons/calendar-clock-white.svg';
import doubt from 'src/assets/icons/doubt.svg';
import keyboard from 'src/assets/icons/keyboard.svg';
import materialChevronLeft from 'src/assets/icons/material-chevron-left.svg';
import materialChevronRight from 'src/assets/icons/material-chevron-right.svg';
import printer from 'src/assets/icons/printer.svg';
import mapSearch from 'src/assets/icons/map-search.svg';
import featherMoreHorizontal from 'src/assets/icons/feather-more-horizontal.svg';
import AddIcon from 'src/assets/icons/add.svg';
import CollapseArrow from 'src/assets/icons/collapse-arrow.svg';
import WhiteCollapseArrow from 'src/assets/icons/collapse-arrow-white.svg';
import SearchIcon from 'src/assets/icons/search-icon.svg';
import SideBarArrow from 'src/assets/icons/sidebar-arrow-icon.svg';
import OptionsDots from 'src/assets/icons/ThreeDots.svg';
import FeatherMap from 'src/assets/icons/feather-map.svg';
import PositionAdm from 'src/assets/icons/position_adm.svg';
import LocationGear from 'src/assets/icons/location-gear.svg';
import CalendarSearch from 'src/assets/icons/calendar-search.svg';
import CalendarSearchGray from 'src/assets/icons/calendar-search-gray.svg';
import Location from 'src/assets/icons/location.svg';
import Info from 'src/assets/icons/info.svg';
import Gear from 'src/assets/icons/gear.svg';
import Empty from 'src/assets/icons/ic_empty_escala.svg';
import LocationPlus from 'src/assets/icons/map-marker-plus.svg';
import Edit from 'src/assets/icons/Icon feather-edit.svg';
import ArrowRoundBack from 'src/assets/icons/arrow-round-back.svg';
import PushPin from 'src/assets/icons/push_pin.svg';
import Image from 'src/assets/icons/image.svg';
import Trash from 'src/assets/icons/trash.svg';
import TrashRed from 'src/assets/icons/trash-red.svg';
import Comment from 'src/assets/icons/material-comment.svg';
import GroupAdd from 'src/assets/icons/material-group-add.svg';
import Download from 'src/assets/icons/Icon metro-file-download.svg';
import OpenPencil from 'src/assets/icons/open-pencil.svg';
import PoweredByGoogle from 'src/assets/icons/PoweredByGoogle.png';
import DoubleArrow from 'src/assets/icons/double_arrow-24px.svg';
import SingleArrow from 'src/assets/icons/single_arrow-24px.svg';
import Minus from 'src/assets/icons/minus.svg';
import Plus from 'src/assets/icons/plus.svg';
import ZoomIn from 'src/assets/icons/zoom_in.svg';
import FitScreen from 'src/assets/icons/fit-screen.svg';
import Folder from 'src/assets/icons/folder.svg';
import Error from 'src/assets/icons/error_outline.svg';
import BarChart from 'src/assets/icons/bar-chart.svg';
import CheckinClock from 'src/assets/icons/checkin-clock.svg';
import Restore from 'src/assets/icons/restore.svg';
import RestoreGreen from 'src/assets/icons/restore-green.svg';
import Mail from 'src/assets/icons/email.svg';
import ArrowDropdown from 'src/assets/icons/arrow-dropdown.svg';
import TrashGray from 'src/assets/icons/trash-gray.svg';
import QrCode from 'src/assets/icons/qrcode-scan.svg';
import EditBlueDark from 'src/assets/icons/edit-blue-dark.svg';
import Manual from 'src/assets/icons/manual.svg';
import Panel from 'src/assets/icons/panel-icon.svg';
import eyeOpen from 'src/assets/icons/open-eye.svg';
import eyeClosed from 'src/assets/icons/closed-eye.svg';
import filter from 'src/assets/icons/filter.svg';
import materialChevronDown from 'src/assets/icons/material-chevron-down.svg';
import subscription from 'src/assets/icons/subscription.svg';
import buy from 'src/assets/icons/buy.svg';
import curvedArrow from 'src/assets/icons/curvedArrow.svg';
import star from 'src/assets/icons/star.svg';
import check from 'src/assets/icons/check.svg';
import whatsapp from 'src/assets/icons/WhatsApp.svg';
import email from 'src/assets/icons/email.svg';
import Hands from 'src/assets/icons/handsup.svg';
import CalendarOnboarding from 'src/assets/icons/calendar_onboarding.svg';
import AddSecondary from 'src/assets/icons/addIcon.svg';
import apple from 'src/assets/icons/apple.svg';
import playStore from 'src/assets/icons/playstore.svg';
import rocket from 'src/assets/icons/rocket.svg';
import question from 'src/assets/icons/question.svg';
import userOnboarding from 'src/assets/icons/userOnboarding.svg';
import firstUserOnboarding from 'src/assets/icons/firstUserOnboarding.svg';
import secondUserOnboarding from 'src/assets/icons/secondUerOnboarding.svg';
import thirdUserOnboarding from 'src/assets/icons/thirdUserOnboarding.svg';
import fourthUserOnboarding from 'src/assets/icons/fourthUserOnboarding.svg';
import fiftUserOnboarding from 'src/assets/icons/fifthUserOnboarding.svg';
import logout from 'src/assets/icons/logout.svg';
import checkin from 'src/assets/icons/checkin.svg';
import checkout from 'src/assets/icons/checkout.svg';
import TrashRedLine from './trash-red-line.svg';
import locationCheckin from './checkIn.svg';
import backIcon from './back-icon.svg';
import notify from './notify.svg';
import exclamation from './exclamation.svg';
import onboardingCheck from './onboardingCheck.svg';
import nextIcon from './nextIcon.svg';
import backIconArrow from './backIcon.svg';
import infoIcon from './infoIcon.svg';
import vacancies from './vacancies.svg';
import positions from './positions.svg';
import addIconsSquare from './addIconSquare.svg';
import locationBlue from './locationBlue.svg';
import locationTitleIcon from './locationTitleIcon.svg';
import vacanciesIcon from './vacanciesIcon.svg';

const icons = {
  BarChart,
  CheckinClock,
  Folder,
  lock,
  Minus,
  FitScreen,
  Plus,
  ZoomIn,
  alterarFoto,
  DoubleArrow,
  SingleArrow,
  close,
  reports,
  team,
  nounGift,
  manage,
  chartTimeline,
  featherEdit,
  iconCog,
  fileUpload,
  plus,
  awesomeEye,
  awesomeMagic,
  bullhorn,
  bullhornPrimary,
  calendar,
  calendarClock,
  calendarClockWhite,
  calendarCheck,
  calendarCheckGray,
  doubt,
  keyboard,
  materialChevronLeft,
  materialChevronRight,
  mapSearch,
  printer,
  featherMoreHorizontal,
  AddIcon,
  CollapseArrow,
  WhiteCollapseArrow,
  SearchIcon,
  SideBarArrow,
  OptionsDots,
  FeatherMap,
  PositionAdm,
  LocationGear,
  CalendarSearch,
  CalendarSearchGray,
  Location,
  Info,
  Gear,
  Empty,
  LocationPlus,
  Edit,
  ArrowRoundBack,
  PushPin,
  Image,
  Trash,
  TrashRed,
  Comment,
  GroupAdd,
  Download,
  OpenPencil,
  PoweredByGoogle,
  Error,
  Restore,
  RestoreGreen,
  Mail,
  ArrowDropdown,
  TrashGray,
  QrCode,
  EditBlueDark,
  Manual,
  Panel,
  eyeOpen,
  eyeClosed,
  filter,
  materialChevronDown,
  subscription,
  buy,
  curvedArrow,
  star,
  check,
  whatsapp,
  email,
  Hands,
  CalendarOnboarding,
  AddSecondary,
  playStore,
  apple,
  rocket,
  question,
  userOnboarding,
  firstUserOnboarding,
  secondUserOnboarding,
  thirdUserOnboarding,
  fourthUserOnboarding,
  fiftUserOnboarding,
  logout,
  checkin,
  checkout,
  locationCheckin,
  backIcon,
  TrashRedLine,
  notify,
  exclamation,
  onboardingCheck,
  nextIcon,
  backIconArrow,
  infoIcon,
  vacancies,
  addIconsSquare,
  positions,
  locationBlue,
  locationTitleIcon,
  vacanciesIcon,
};

export default icons;
