import React from 'react';
import { useTranslation } from 'react-i18next';
import MainNavBar from '../../components/MainNavBar';
import LocationListing from './ManageLocation/LocationListing';
import { useGlobal } from '../../hooks/useGlobal';
import Icons from '../../assets/icons';
import { ContainerLocation, IconTitle, Title } from './style';

const Reservation = () => {
  const { t } = useTranslation('pages');
  const { sideMenuIsOpen } = useGlobal();

  return (
    <>
      <MainNavBar />
      <ContainerLocation className={`row px-2 me-0 ${sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'}`}>
        <div className="col-12 d-flex mb-2">
          <IconTitle src={Icons.LocationGear} alt="Location" />
          <Title>{t('Reservation.tabs.manager')}</Title>
        </div>
        <LocationListing />
      </ContainerLocation>
    </>
  );
};

export default Reservation;
