import styled from 'styled-components';

interface props {
  active: boolean
}

export const Card = styled.div`
  min-width: 28.25rem;
  min-height: 18.688rem;
  padding: 15px 0 15px 0;

  background: #FFFFFF;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
`;

export const SkeletonContainer = styled.div`
  padding: 15px;
`;

export const ContentFinishedIcon = styled.div<props>`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: ${(props) => (props.active ? '#00B38E' : '#FFFFFF')};
  border-radius: 5em;
  display: flex;
  justify-content: center;
  border: ${(props) => (props.active ? '1px solid #00B38E' : '1px solid #7A7E80')};
  img {
    width: 12px;
  }
`;

export const TextAndIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconsStore = styled.div`
  display: flex;
`;

export const Text = styled.span`
  margin: auto;
  margin-left: 0!important;
`;

export const StoreIcon = styled.a`
  width: 54px;
  height: 44px;
  border: 1px solid #C4C9CC;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  padding: 5px;
  margin-left: 15px;
`;

export const ContentFinishedBox = styled.div<props>`
  font-style: normal;
  margin: 15px 5px 15px 0;
  font-weight: bolder;
  font-size: 16px;
  line-height: 19px;
  padding: 0 15px 0 15px;

  display: flex;
  align-items: center;

  color: ${(props) => (props.active ? '#00B38E' : '#7A7E80')};
`;

export const CardFooter = styled.div`
  border-top: 0.5px solid #ABB0B3;;
`;
