import styled from 'styled-components';
import colors from '../../colors';

export const InputBox = styled.div<{width?: string, height?:string, borderColor?:string}>`
    padding: 0 10px;
    border: 1px solid ${(props) => props.borderColor || colors.white15};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    width: ${(props) => props.width || '440px'};
    height:  ${(props) => props.height || '38px'};
`;
export const Input = styled.input`
    height: 100%;
    width: 100%;
    outline: none;
    border: none;

    text-align: left;
    font: normal normal normal 14px/17px lato;
    letter-spacing: 0;
    color: ${colors.gray};
`;

export const Icon = styled.img`
    height: 18px;
    margin-right: 13px;
`;
