export type Validation = {
  isValid: boolean;
  fieldName: string;
  message: string
};
export default function validate(validations: Validation[]) {
  const invalidFields : {[x:string]: string} = {};

  validations.forEach((validation) => {
    if (!invalidFields[validation.fieldName] && !validation.isValid) {
      invalidFields[validation.fieldName] = validation.message;
    }
  });
  return Object.keys(invalidFields).length > 0 ? invalidFields : null;
}

export const validateEmail = (email: string) => email.match(
  /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

export const emailOrCode = (param: string) => {
  if (validateEmail(param)) {
    return 'email';
  }
  if (param.indexOf('-', 0) > -1) {
    return 'code';
  }
  return 'error';
};

export const validateCode = (value: string, addToast: Function, t: Function) => {
  const occurrences = value.split('-').length - 1;
  if (occurrences <= 1) {
    return true;
  }
  addToast({
    description: t('ModalProfessional.EditProfessional.code_error'),
    type: 'error',
  });
  return false;
};

export const verifyEmail = (query: any) => {
  const getEmail = query.get('email');
  const arrayParams = query?.toString().split('&');
  const key = arrayParams[0].substr(0, 6);
  const keyValue = arrayParams[0].substr(6);
  if (key === 'email=') {
    if (getEmail !== arrayParams[0].substr(6)) {
      return decodeURIComponent(keyValue);
    }
    return getEmail;
  }
  return getEmail;
};
