import React, { useMemo } from 'react';
import { Circle, Text, Group } from 'react-konva';
import Konva from 'konva';
import { calcFontSize, calcRadius } from 'src/utils/canvasFunctions';
import colors from '../../colors';

interface SeatMarker {
  id: string | number,
  x: number,
  y: number,
  name: string,
  rotation?: number,
  isDragging?: boolean,
}

interface SeatEditableCellCanvasProps {
  seat: SeatMarker;
  index: number;
  scale: number;
  selected: boolean;
  radius: number;
  onClick?: Function;
  onDragMarker?: (isDragging: boolean) => void;
  onDragMarkerEnd?: (event: Konva.KonvaEventObject<DragEvent>) => void;
}

const SeatEditableCellCanvas: React.FC<SeatEditableCellCanvasProps> = ({
  seat, index, selected, onClick, onDragMarker, onDragMarkerEnd, scale, radius,
}) => {
  const calculedRadius = useMemo(() => calcRadius(radius, scale), [scale, radius]);
  const calculateHeightAndWidth = useMemo(() => {
    const calc = ((calculedRadius * 2) - 20);

    if (calc < 0) {
      return calc * -1;
    }

    return calc;
  }, [calculedRadius]);

  return (
    <Group
      draggable={selected}
      onDragStart={() => onDragMarker?.(true)}
      onDragEnd={(dragEvent) => {
        onDragMarkerEnd?.(dragEvent);
        onDragMarker?.(false);
      }}
      x={seat.x}
      y={seat.y}
      onClick={() => onClick?.()}
    >
      <Circle
        key={seat.id}
        radius={calculedRadius}
        numPoints={5}
        innerRadius={20}
        outerRadius={40}
        fill={selected ? `${colors.blue30}` : `${colors.blue15}`}
        strokeWidth={2}
        stroke={selected ? `${colors.blue15}` : `${colors.blue30}`}
      />
      <Circle
        radius={calculedRadius}
        width={calculateHeightAndWidth}
        height={calculateHeightAndWidth}
        fill={selected ? `${colors.blue35}` : `${colors.blue20}`}
      />

      <Text
        x={(calculedRadius / 2) * -1}
        y={(calculedRadius / 4) * -1}
        text={(index + 1).toString()}
        width={calculedRadius}
        align="center"
        fontSize={calcFontSize(calculedRadius / 2, scale)}
        fontStyle="bold"
        fill={selected ? `${colors.white}` : `${colors.blue30}`}
      />
    </Group>
  );
};

export default SeatEditableCellCanvas;
