export default function getNameInitials(name: string) {
  if (!name) return '';
  const names = name.trim().split(' ');
  if (names.length < 2) {
    return names[0][0].toUpperCase();
  }
  const letterfirstName = names[0][0];
  const letterlastName = names[names.length - 1][0];

  return `${letterfirstName}${letterlastName}`;
}
