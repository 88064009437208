/* eslint-disable react/require-default-props */
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';
import dateFnsAddDays from 'date-fns/addDays';
import { DateUtils } from 'react-day-picker';
import { Wrapper, Container, PickerContainer } from './style';
import Icons from '../../assets/icons';
import useMonths from '../../hooks/useMonths';
import useDays from '../../hooks/useDays';
import Button from '../ButtonLoader';

interface SingleDatePickerInterface {
    onChange: (value: Date) => void
    initialValue?: string,
    value?: Date,
    minDate?: Date,
    disabled?: boolean,
    showArrows?: boolean,
}

const MONDAY = 1;
function SingleDatePicker({
  onChange, initialValue, value, minDate, disabled, showArrows = false,
}: SingleDatePickerInterface) {
  const months = useMonths({ capitalize: true });
  const days = useDays({ capitalize: true });

  const daysOnlyFirstLetter = useDays({
    capitalize: true,
    onlyFirstLetter: true,
  });
  const parseDate = (str: string, format: string, locale: any) => {
    const parsed = dateFnsParse(str, format, new Date(), { locale });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  };

  const formatDate = (date: number | Date, format: string, locale: any) => (
    dateFnsFormat(date, format, { locale })
  );

  return (
    <Container disabled={disabled}>
      {showArrows && (
      <Button
        style={{ marginRight: 5 }}
        transparent
        withHover={false}
        iconSize={16}
        icon={Icons.materialChevronLeft}
        onClick={() => {
          onChange(dateFnsAddDays(value!, -1));
        }}
      />
      )}
      <PickerContainer disabled={disabled}>
        <img src={Icons.calendar} alt="" />
        <Wrapper disabled={disabled}>
          {disabled ? <input value={initialValue} disabled /> : (
            <DayPickerInput
              value={initialValue}
              inputProps={{ readOnly: true }}
              dayPickerProps={{
                firstDayOfWeek: MONDAY,
                disabledDays: minDate && { before: minDate || new Date() },
                months: months.map((month) => month.name),
                weekdaysLong: days.map((day) => day.name),
                weekdaysShort: daysOnlyFirstLetter.map(
                  (day) => day.name,
                ),
              }}
              style={{ width: 100 }}
              formatDate={formatDate}
              format="dd/MM/yyyy"
              parseDate={parseDate}
              placeholder={dateFnsFormat(new Date(), 'dd/MM/yyyy')}
              onDayChange={onChange}
            />
          )}
        </Wrapper>
      </PickerContainer>
      {showArrows && (
      <Button
        style={{ marginLeft: 5 }}
        transparent
        withHover={false}
        iconSize={16}
        icon={Icons.materialChevronRight}
        onClick={() => {
          onChange(dateFnsAddDays(value!, 1));
        }}
      />
      )}
    </Container>
  );
}

export default SingleDatePicker;
