import React from 'react';
import { useTranslation } from 'react-i18next';
import { getUser } from 'src/services/auth';
import { Link } from 'react-router-dom';
import MainNavBar from 'src/components/MainNavBar';
import images from '../../assets/images';
import {
  Container,
  Content,
  BannerContainer,
  InfoContainer,
  InfoContent,
  Title,
  SubTitle,
  GoBackText,
} from './style';
import { useGlobal } from '../../hooks/useGlobal';

const Page404: React.FC = () => {
  const { t } = useTranslation('pages');
  const { sideMenuIsOpen } = useGlobal();
  const user = getUser();

  return (
    <>
      {user && <MainNavBar />}
      <Container hasNav={Boolean(user)} className={sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'}>
        <Content>
          <InfoContainer>
            <InfoContent>
              {!user && <img src={images.LogoEscalaEspacos} className="logo" alt="Escala Espaços" />}
              <Title>{t('404.title')}</Title>
              <SubTitle>{t('404.sub_title')}</SubTitle>
              <Link to={user ? '/' : '/login'}>
                <GoBackText>{t('404.go_back_label')}</GoBackText>
              </Link>
            </InfoContent>
          </InfoContainer>
          <BannerContainer />
        </Content>
      </Container>
    </>
  );
};

export default Page404;
