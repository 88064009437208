import React from 'react';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import icons from 'src/assets/icons';
import {
  Row,
} from './style';

export interface FilterOption<Value = any> {
  label: string
  value: Value
}

export interface UserTableHeaderRowWithDropdownProps<FilterOptionValue> {
  isOpen: boolean;
  toggleDropdown: () => void;
  options: Array<FilterOption<FilterOptionValue>>;
  actualSelectedDropdownValue?: FilterOptionValue | undefined;
  handleChangeFilterOption: (value: FilterOptionValue) => void
  dropdownTitle?: string
  children?: React.ReactNode
}

const UserTableHeaderRowWithDropdown = <FilterOptionValue extends unknown>({
  isOpen,
  toggleDropdown,
  options,
  actualSelectedDropdownValue,
  handleChangeFilterOption,
  dropdownTitle,
  children,
}: UserTableHeaderRowWithDropdownProps<FilterOptionValue>) => (
  <Row>
    <Dropdown isOpen={isOpen} toggle={toggleDropdown} style={{ alignSelf: 'center', cursor: 'pointer' }}>
      <DropdownToggle
        tag="span"
        onClick={toggleDropdown}
        data-toggle="dropdown"
        aria-expanded={isOpen}
      >
        {dropdownTitle ?? children}
        <img src={icons.ArrowDropdown} alt="" />
      </DropdownToggle>
      <DropdownMenu>
        {options.map((option) => (
          <DropdownItem
            key={option.label}
            active={actualSelectedDropdownValue === option.value}
            onClick={() => {
              handleChangeFilterOption(option.value);
            }}
          >
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  </Row>
  );
export default UserTableHeaderRowWithDropdown;
