import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  button{
    text-transform: initial;
  }
`;

export const TextHeader = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  font-family: lato;
`;

export const NotListTeams = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
`;
