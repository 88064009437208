import styled from 'styled-components';

interface SideBarProps {
  open?: boolean;
}

export const Body = styled.div<SideBarProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: ${(props) => (props.open ? 'start' : 'center')};
  justify-content: start;
`;

export const EmployeesCounter = styled.span`
  font: normal normal bold 17px/21px lato;
  text-align: left;
  color: #3b4252;
  opacity: 1;
`;

export const Space = styled.div`
  display: flex;
  height: 1%;
`;

export const Loader = styled.img`
  width: 3em;
  height: 3em;
  align-self: center;
  margin-top: 10px;
`;

export const SearchInputBox = styled.div<SideBarProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  width: 100%;
  padding-right: ${(props) => (props.open ? '0' : '10px')};
  background: ${(props) => (props.open ? '#FFFFFF' : 'transparent')} 0% 0%
    no-repeat padding-box;
  padding-left: 10px;
  border: ${(props) => (props.open ? '1px solid #E3E5EB;' : 'none')};
  border-radius: 4px;
`;

export const SearchInput = styled.input`
  width: 85%;
  border-radius: 4px;
  text-decoration: none;
  outline: none;
  border: none;
  
  :focus{
    box-shadow: none;
  }
  
`;

export const SearchIcon = styled.img`
  cursor: pointer;
`;

export const NoneSelected = styled.div`
  color: var(--secondary-gray);
  margin-top: 25px;
  text-align: center;
  font: normal normal 500 17px/26px lato;
  display: flex;
  width: 90%;
  align-self: center;
`;
