import { lighten } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  button {
    border: none;
    background-color: transparent;
  }
`;

export const DateContainer = styled.div`
  padding: 0.5625rem 1.438rem 0.5625rem 1.0625rem;
  border: 1px solid #e1e5e6;
  border-radius: 4px;
  img {
    margin-right: 12.38px;
    cursor: pointer;
  }
  
  @media (max-width: 1366px) {
    img {
      width: 0.75rem;
    }
  }
`;
export const CalendarContainer = styled.div`
  z-index: 99;
  position: absolute;
  margin-top: 10px;
  width: 100%;
  background-color: white;
  box-shadow: 0 5px 10px #21212130;
  padding: 0.3125rem;
  border-radius: 5px;
  .DayPicker-Week {
    .DayPicker-Day {
      border-radius: 0;
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: ${lighten('#1075CE', 0.2)};
      color: white;
    }

    .DayPicker-Day--selected:last-child:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: #1075ce;
      border-radius: 0 0.24rem 0.24rem 0;
    }

    .DayPicker-Day--selected:first-child:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      border-radius: 0.25rem 0 0 0.25rem;
      background-color: #1075ce;
    }
  }
`;

export const IconContainer = styled.div`
  @media (max-width: 1366px) {
    button {
      img {
        width: 10px;
      }
    }
  }
`;
