import colors from 'src/colors';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

export const ActionButtonSectionContainer = styled.div`
  margin-bottom: 5px;
`;

export const SelectedEmployeesAmount = styled.div`
  color: ${colors.gray90};
`;
