/* eslint-disable import/no-duplicates */
import React, { ChangeEvent, useMemo, useState } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import { ptBR } from 'date-fns/locale';
import {
  addDays, addMonths, addWeeks, endOfMonth, format, isBefore,
} from 'date-fns';

import { endOfWeek, startOfWeek } from 'date-fns/esm';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsParse from 'date-fns/parse';
import dateFnsFormat from 'date-fns/format';
import SelectWeekDay from '../SelectWeekDay';
import { NavBar } from './style';
import useMonths from '../../hooks/useMonths';
import useDays from '../../hooks/useDays';
import Select from '../Select';
import { useModalReservationEditContext } from './context';
import maskDate from '../../utils/maskDate';
import { formatStringToDate } from '../../utils/date.functions';

interface NavBarProps {
    nextMonth: Date;
    previousMonth: Date;
    onPreviousClick: Function;
    onNextClick: () => void;
    className: string;
    localeUtils: any;
}

const Navbar: React.FC<NavBarProps> = ({
  previousMonth,
  onPreviousClick,
  onNextClick,
  className,
}) => (
  <NavBar className={className}>
    <button type="button" onClick={() => onPreviousClick()}>
      ←
    </button>
    {format(addMonths(previousMonth, 1), 'MMMM yyyy', { locale: ptBR })}
    <button type="button" onClick={() => onNextClick()}>
      →
    </button>
  </NavBar>
);

type WeeklyFrequencyProps = {
    currentDate?: Date;
    weekInterval: string;
    setWeekInterval: Function;
    onChangeDateUtil: Function;
    type?: 'edit' | 'create'
};

const WeeklyFrequency: React.FC<WeeklyFrequencyProps> = ({
  weekInterval,
  onChangeDateUtil,
  type,
  currentDate,
}) => {
  const { t } = useTranslation('components');

  const {
    setPayload,
    payload,
    selectedWeekDays,
    setSelectedWeekDays,
  } = useModalReservationEditContext();

  const [viewedMonth, setViewedMonth] = useState(currentDate || new Date());
  const months = useMonths({ capitalize: true });
  const days = useDays({ capitalize: true });

  const updateJourney = (newDays: any) => {
    setPayload({ ...payload, journeys: newDays });
  };

  const selectedDays = useMemo(() => {
    let weekDays = type === 'edit' ? payload.journeys as any : selectedWeekDays;
    weekDays = weekDays.filter((j: number) => typeof j === 'number');

    if (!weekInterval || !weekDays.length || +weekInterval < 1) {
      return [];
    }
    let startOfWeekDay = startOfWeek(new Date(), { weekStartsOn: 1 });
    const endOfWeekDay = endOfWeek(endOfMonth(viewedMonth));
    let showDays: Date[] = [];
    const getDaysOfWeekDates = (day: number) => addDays(startOfWeekDay, day);

    let durationCount = 0;

    const endCondition = payload.duration === 'until' ? formatStringToDate(payload.datetimeUntil, 'yyyy-MM-dd') : endOfWeekDay;
    while (isBefore(startOfWeekDay, endCondition)) {
      const daysOfWeekDates = [];
      for (let i = 0; i < weekDays.length; i += 1) {
        if (payload.duration === 'count') {
          durationCount += 1;
        }

        const newDate = getDaysOfWeekDates(weekDays[i]);
        if (newDate <= endCondition) {
          daysOfWeekDates.push(newDate);
        }

        if (payload.duration === 'count' && durationCount === payload.count) {
          break;
        }
      }

      showDays = [...showDays, ...daysOfWeekDates];
      startOfWeekDay = addWeeks(startOfWeekDay, +weekInterval);

      if (payload.duration === 'count' && durationCount === payload.count) {
        break;
      }
    }
    const lastDay = payload.duration === 'until' ? payload.datetimeUntilUnformated : showDays[showDays.length - 1];
    onChangeDateUtil(lastDay);
    return showDays;
  }, [
    weekInterval,
    selectedWeekDays,
    viewedMonth,
    payload.duration,
    payload.journeys,
    payload.count, payload.datetimeUntil,
  ]);

  const daysOnlyFirstLetter = useDays({
    capitalize: true,
    onlyFirstLetter: true,
  });

  const stopConditionOptions = [
    {
      label: t('ModalShiftEdit.duration_types.never'),
      value: 'forever',
    },
    {
      label: t('ModalShiftEdit.duration_types.count'),
      value: 'count',
    },
    {
      label: t('ModalShiftEdit.duration_types.until'),
      value: 'until',
    },
  ];
  const afterOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1} ${t('ModalShiftEdit.times')}`,
  }));

  function parseDate(str: string, fmt: string) {
    const parsed = dateFnsParse(str, fmt, new Date(), { locale: ptBR });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  function formatDate(date: Date, fmt: string) {
    return dateFnsFormat(date, fmt, { locale: ptBR });
  }

  // @ts-ignore
  return (
    <>
      <div className="select-week-day row mt-4">
        <div className="col-9">
          <SelectWeekDay
            allowedDays={[0, 1, 2, 3, 4, 5, 6]}
            disabled={type === 'edit'}
            selectedDaysDefault={payload.journeys as any || selectedWeekDays as any}
            onChange={(daysSelected) => {
              setSelectedWeekDays(daysSelected as number[]);
              updateJourney(daysSelected);
            }}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex align-items-center">
            <span className="me-4">
              {t('ModalShiftEdit.stop_repetition')}
            </span>
            <div className="w-25">
              <Select
                onChange={(option: any) => {
                  setPayload({
                    ...payload,
                    duration: option.value,
                    count: null,
                    datetimeUntil: null,
                  });
                }}
                options={stopConditionOptions}
                defaultValue={
                  payload.duration
                    ? {
                      value: payload.duration,
                      label: stopConditionOptions.find(
                        (cond) => cond.value === payload.duration,
                      )?.label || '',
                    }
                    : stopConditionOptions[0]
                }
              />
            </div>
            {payload.duration === 'count' && (
              <div className="conditionType">
                <Select
                  name="stopCondition"
                  value={payload.count && {
                    value: payload.count,
                    label: `${payload.count} ${t('ModalShiftEdit.times')}`,
                  }}
                  onChange={(option: any) => {
                    setPayload({
                      ...payload,
                      datetimeUntil: null,
                      count: option.value,
                    });
                  }}
                  options={[
                    {
                      options: afterOptions,
                    },
                  ]}
                />
              </div>
            )}
            <div>
              {payload.duration === 'until' && (
                <div className="conditionType">
                  <DayPickerInput
                    onDayChange={(day) => {
                      if (day) {
                        setPayload((prev: any) => ({
                          ...prev,
                          count: null,
                          datetimeUntil: format(day, 'yyyy-MM-dd'),
                          datetimeUntilUnformated: day,
                        }));
                      }
                    }}
                    value={new Date(`${payload.datetimeUntil}T00:00:00`)}
                    parseDate={parseDate}
                    formatDate={formatDate}
                    placeholder="DD/MM/AAAA"
                    format="dd/MM/yyyy"
                    dayPickerProps={{
                      disabledDays: [{ before: currentDate || new Date() }],
                      months: months.map((month) => month.name),
                      weekdaysLong: days.map((day) => day.name),
                      weekdaysShort: daysOnlyFirstLetter.map((day) => day.name),
                    }}
                    inputProps={{
                      style: {
                        height: '40px',
                        padding: '0 17px',
                        background: '#ffffff 0% 0% no-repeat padding-box',
                        borderRadius: '4px',
                        opacity: 1,
                        color: 'var(--primary-gray)',
                        fontSize: '1rem',
                        verticalAlign: 0,
                      },
                      maxLength: 10,
                      onChange: (evt: ChangeEvent<HTMLInputElement>) => {
                        // eslint-disable-next-line no-param-reassign
                        evt.target.value = maskDate(evt.target.value);
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex flex-column">
            <div>
              <span>{t('ModalShiftEdit.result')}</span>
            </div>
            <div className="mt-3">
              <DayPicker
                disabledDays={[{ before: currentDate || new Date() }]}
                month={viewedMonth}
                firstDayOfWeek={1}
                months={months.map((month) => month.name)}
                weekdaysLong={days.map((day) => day.name)}
                weekdaysShort={daysOnlyFirstLetter.map((day) => day.name)}
                selectedDays={selectedDays}
                onMonthChange={setViewedMonth}
                captionElement={<></>}
                enableOutsideDaysClick={false}
                showOutsideDays
                navbarElement={(props) => (
                  <Navbar {...props} />
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WeeklyFrequency;
