import styled, { css } from 'styled-components';
import colors from '../../colors';

export const Container = styled.div`
  h3 {
    color: ${colors.blue};
    font-size: 1.2rem;
    font-weight: bold;
  }
  background-color: ${colors.white};

  > div {
  }
  @media (max-width: 1024px) {
    font-size: 10px;
  }
`;

export const Header = styled.div<{ isOpen: boolean }>`
  padding: 1.1rem 2rem 1.1rem 1rem;
  line-height: 0;
  border-bottom: 1px solid ${colors.white10};
  font-size: 1.2rem;
  color: ${colors.blue};
  font-weight: bold;

  @media (max-width: 1024px) {
    line-height: 1.5;
    padding: 0 2vw 0 0;
  }
  
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
    font-weight: bold;
    color: ${colors.blue};
    background-color: transparent;
    border: none;
    font-size: 1rem;
      img {
        height: 1rem;
      }
      img:nth-of-type(1) {
      line-height: 0;
      margin-left: 0.5rem;
      transition: 0.4s;
      }
      & + button {
        margin-left: 20px;
      }
    }
  }

  
  span {
    margin-left: 15px;
  }

  .icon-rotate {
    ${(props) => !props.isOpen && 'transform: rotate(-90deg); transition: 0.4s; margin-top: 10px;'}
  }

  .icon-rotate {
    transition: 0.4s;
  }

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

export const NameContainer = styled.div`
  cursor: pointer;
`;

export const Body = styled.div`
  padding: 2rem 4.4rem;
  @media only screen and (max-width: 768px) {
    padding: 2rem 1.4rem !important;
  }
    button{
      img{
        width: auto;
      }
    }
`;

export const LocationList = styled.div``;

export const ProfessionalList = styled.div`
  table {
    width: 100%;
  }
  tr {
    text-align: center;
  }
  td {
  }
`;

export const LimitCount = styled.div<{ bordered?: boolean; transparent?: boolean }>`
  border: 1px solid #0000001f;
  ${(props) => !props.bordered && 'border: none;'}
  border-radius: 5px;
  background-color: ${(props) => (props.transparent ? 'transparent' : 'white')};
  width: 48%;
  padding: 7.5px 10px;
  ${(props) => !props.bordered && 'padding: 7.5px 0px;'}
  height: 38px;
  input {
    border: none;
    margin: 0;
    width: 25px;
    background: transparent;
  }
  input, span{
    padding-top: 1px;
  }
`;

export const AvailabilityForPresenceHeader = styled.div`
  position: relative;
  > div {
    :first-child {
      color: var(--primary-gray);
      position: absolute;
      top: -0.5rem;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;

      @media (max-width: 1024px) {
        top: -3rem;
      }
    }
  }
`;

export const ReservationLimitHeader = styled.div`
  position: relative;
  > div {
    :first-child {
      color: var(--primary-gray);
      position: absolute;
      top: -1rem;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      @media (max-width: 1024px) {
        top: -3rem;
      }
    }
  }
`;

export const ModalContainer = styled.div`
  z-index: 2;
  position: absolute;
  box-shadow: 0px 3px 4px #00000017;
  background-color: white;
  border-radius: 5px;
  width: 26rem;
  padding: 2rem;
  text-align: left;
`;

export const ModalHeader = styled.div``;

export const ModalBody = styled.div``;

export const DarkBg = styled.div<{ isOpen?: boolean }>`
  z-index: 1;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: #0003;
`;

type JustificationButtonProps = {
  hasJustification: boolean;
};
export const JustificationButton = styled.button<JustificationButtonProps>`
  border: none;
  background-color: transparent;
  position: relative;
  ${(p) => p.hasJustification
    && css`
      ::after {
        content: '';
        width: 1rem;
        height: 1rem;
        border-radius: 15px;
        background-color: red;
        position: absolute;
        border: 2px solid white;
        top: -0.5rem;
        right: -0.5rem;
      }
    `}
`;

export const IconButton = styled.button`
  border: none;
  background-color: transparent;
  position: relative;
`;

export const ProfessionalDetail = styled.div`
  > img {
    width: 24px;
    height: 24px;
    border-radius: 18px;
    margin-right: 1rem;
    object-fit: cover;
  }
  span {
    background-color: #e3e3e3;
  }
  p {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }
`;

export const ProfessionalWithoutProfileImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: #676C7D;
  color: white;
  font-size: 0.8rem;
  margin-right: 1rem;
  text-transform: uppercase;
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-right: 10px;
  text-align: right;
  .button-trash{
    img{
      filter: grayscale(1);
      opacity: .4;
    }
  }
`;
