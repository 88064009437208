import React, { FC } from 'react';
import { format, parseISO, toDate } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { TextBody, TextHeader } from './style';
import Badge from '../../components/Badge';
import Icons from '../../assets/icons';
import colors from '../../colors';
import ButtonLoader from '../../components/ButtonLoader';
import CheckInService from '../../services/CheckInService';
import { useToast } from '../../hooks/toast';

const DayCard:FC<{
  shift: any,
  isToday?: boolean,
  translate?: any,
  isAvailable?:
    boolean,
  createShift?: (id: any) => void
}> = ({
  shift,
  isToday,
  translate,
  isAvailable,
  createShift,
}) => {
  const { addToast } = useToast();

  const doCheckIn = async (shiftEmployeeId : number) => {
    try {
      await CheckInService.doCheckIn(shiftEmployeeId);

      addToast({
        description: translate('CheckIn.success'),
        type: 'success',
      });
    } catch (error) {
      addToast({
        description: error.response.data.message,
        type: 'error',
      });
    }
  };

  const doCheckOut = async (shiftEmployeeId : number) => {
    try {
      await CheckInService.doCheckOut(shiftEmployeeId);

      addToast({
        description: translate('CheckIn.checkOut_success'),
        type: 'success',
      });
    } catch (error) {
      addToast({
        description: error.response.data.message,
        type: 'error',
      });
    }
  };

  return (
    <div className="card shadow-sm h-100" style={{ borderRadius: '8px' }}>
      <div className="bg-transparent border-0 card-header d-flex justify-content-between">
        <TextHeader>
          {format(toDate(parseISO(shift.start)), "dd MMM',' eeee'-feira'", {
            locale: ptBR,
          })}
        </TextHeader>
        <Badge text={`Vaga ${shift.vaga}`} />
      </div>
      <div className="card-body">
        <TextBody className="mb-0">
          {shift.location_name}
          {' - '}
          {shift.placement_name}
        </TextBody>
        <TextBody className="mb-0">
          {format(toDate(parseISO(shift.start)), 'HH:mm')}
          {' - '}
          {format(toDate(parseISO(shift.end)), 'HH:mm')}
        </TextBody>
      </div>
      {(isToday && shift.is_register_checkin_checkout) && (
        <div className="card-footer border-0 d-flex justify-content-between bg-transparent">
          {!shift.checkin && (
            <ButtonLoader
              className="p-2 mb-2 color-blue"
              iconSize={15}
              disabled={Boolean(shift.checkin)}
              icon={Icons.checkin}
              onClick={() => doCheckIn(shift.shift_employee_id)}
              label={translate('CheckIn.checkIn')}
              color={colors.green30}
              iconClass="filter-secondary-color"
            />
          )}
          {!shift.checkout && shift.checkin && (
            <ButtonLoader
              className="p-2 mb-2"
              disabled={!shift.checkin || Boolean(shift.checkout)}
              icon={Icons.checkout}
              onClick={() => doCheckOut(shift.shift_employee_id)}
              label={translate('CheckIn.checkOut')}
              color={colors.red}
              iconSize={15}
            />
          )}
        </div>
      )}
      {isAvailable && (
        <div className="card-footer d-none border-0 d-flex justify-content-between bg-transparent d-none">
          <ButtonLoader
            icon={Icons.Restore}
            label={translate('Dashboard.reservation.new')}
            iconSize={15}
            className="p-2 mb-2"
            uppercase={false}
            iconClass="filter-white"
            onClick={() => createShift && createShift(shift.id)}
          />
        </div>
      )}
    </div>
  );
};

export default DayCard;
