import { Instance, createPopper } from '@popperjs/core';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import {
  DarkBg, ColorTooltip, ColorOption, ColorListContainer,
} from './style';

interface ColorModalProps {
    colors: Array<string>;
    selectedColor: string | null;
    onSelect: Function;
    onClose: Function;
}

const ColorModal: React.FC<ColorModalProps> = ({
  colors,
  selectedColor,
  onSelect,
  onClose,
}) => {
  useEffect(() => {
    const selectorReference = document.getElementById('selector')!;
    const modalReference = document.getElementById('color-tooltip')!;
    const popper: Instance = createPopper(selectorReference!, modalReference!, {
      placement: 'top-start',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ],
    });

    const hideModal = (evt: any) => {
      if (evt.target.id === 'darkBG') {
        onClose();
      }
    };
    window.onclick = hideModal;

    return () => {
      popper?.destroy();
    };
  }, []);

  return createPortal(
    <DarkBg id="darkBG">
      {createPortal(
        <ColorTooltip id="color-tooltip" role="tooltip">
          <ColorListContainer>
            {colors.map((color) => (
              <ColorOption onClick={() => onSelect(color)} key={`color-${color}`} color={color}>
                {selectedColor === color ? 'X' : ''}
              </ColorOption>
            ))}
          </ColorListContainer>
          <div id="arrow" data-popper-arrow />
        </ColorTooltip>,
                document.getElementById('selector')!,
      )}
    </DarkBg>,
        document.getElementById('root')!,
  );
};
export default ColorModal;
