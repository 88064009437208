import styled from 'styled-components';
import { Modal as ModalReactstrap, ModalBody as ModalBodyReactstrap, ModalFooter as ModalFooterReactstrap } from 'reactstrap';
import colors from '../../colors';

const MODAL_WIDTH = 500;

export const Modal = styled(ModalReactstrap)`
  width: ${MODAL_WIDTH};
  border-radius: 4px;
`;

export const ModalBody = styled(ModalBodyReactstrap)`
  border-radius: 3px;
  padding-top: 32px;
  border-top: 6px solid ${colors.orange};
`;

export const ModalFooter = styled(ModalFooterReactstrap)`
  border: none;
  padding-bottom: 32px;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-family: lato;
  color: ${colors.gray};
  text-align: center;
  margin-top: 25px;
`;

export const Message = styled.span`
  font-size: 15px;
  font-weight: 500;
  font-family: lato;
  color: ${colors.gray};
  padding: 0px 110px;
  text-align: center;
  @media (max-width: 768px) {
    padding: 0px;
  }
`;

export const ContainerButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  button:first-child {
    margin-right: 16px;
  }
`;
