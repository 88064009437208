import React from 'react';

export interface EmployeeRoleTextProps {role?: string}

const EmployeeRoleText = ({ role = '' }: EmployeeRoleTextProps) => (
  <div>
    {role}
  </div>
);

export default EmployeeRoleText;
