import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import LocationService from '../../services/LocationService';
import Geolocation from '../../contracts/models/GeoLocation';

type AddLocationProps = {
  locationId: number,
  setLocationId?: React.Dispatch<React.SetStateAction<number>>;
  locationName: string;
  setLocationName?: React.Dispatch<React.SetStateAction<string>>;
  invalidLocationName: boolean;
  setInvalidLocationName?: React.Dispatch<React.SetStateAction<boolean>>;
  address: string;
  setAddress?: React.Dispatch<React.SetStateAction<string>>;
  invalidAddress: boolean;
  setInvalidAddress?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedGeoLocation: Geolocation;
  setSelectedGeoLocation?: React.Dispatch<React.SetStateAction<Geolocation>>;
  geolocationOptions: Geolocation[] | null;
  setGeoLocationOptions?: React.Dispatch<React.SetStateAction<Geolocation[] | null>>;
  addressComplement: string;
  setAddressComplement?: React.Dispatch<React.SetStateAction<string>>;
  selectedPlacement: number;
  setSelectedPlacement?: React.Dispatch<React.SetStateAction<number>>;
  editMode: boolean;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  radius: number;
  handleSetRadius: (value: number) => void;
  loadLocationInformation?: () => void;
};

export const AddLocationContext = React.createContext<AddLocationProps>({} as AddLocationProps);

const AddLocationProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [locationId, setLocationId] = useState<number>(0);
  const [selectedPlacement, setSelectedPlacement] = useState<number>(0);
  const [locationName, setLocationName] = useState<string>('');
  const [invalidLocationName, setInvalidLocationName] = useState<boolean>(false);
  const [address, setAddress] = useState<string>('');
  const [invalidAddress, setInvalidAddress] = useState<boolean>(false);
  const [addressComplement, setAddressComplement] = useState<string>('');
  const [geolocationOptions, setGeoLocationOptions] = useState<Geolocation[] | null>(null);
  const [selectedGeoLocation, setSelectedGeoLocation] = useState<Geolocation>({
    selected: false,
    placeId: '',
    geometry: { location: { lat: '', lng: '' } },
  });

  const [radius, setRadius] = useState<number>(25);

  async function loadLocationInformation() {
    if (locationId) {
      try {
        const response = await LocationService.getLocation(locationId.toString());
        setLocationName(response.data.name);
        setAddress(response.data.address);
        setSelectedGeoLocation({
          placeId: response.data.google_place_id,
          selected: true,
          geometry: {
            location: {
              lat: response.data.latitude,
              lng: response.data.longitude,
            },
          },
        });
        if (response.data.complement) setAddressComplement(response.data.complement);
      } catch (error: any) {
        if (error?.response?.status === 404) {
          history.push('/page404');
        }
      }
    }
  }

  function handleSetRadius(value: number) {
    setRadius(value);
  }

  useEffect(() => {
    loadLocationInformation();
  }, [locationId]);
  return (
    <AddLocationContext.Provider
      value={{
        locationId,
        setLocationId,
        locationName,
        setLocationName,
        invalidLocationName,
        setInvalidLocationName,
        address,
        setAddress,
        invalidAddress,
        setInvalidAddress,
        addressComplement,
        setAddressComplement,
        selectedGeoLocation,
        setSelectedGeoLocation,
        geolocationOptions,
        setGeoLocationOptions,
        selectedPlacement,
        setSelectedPlacement,
        editMode,
        setEditMode,
        loadLocationInformation,
        radius,
        handleSetRadius,
      }}
    >
      {children}
    </AddLocationContext.Provider>
  );
};

export default AddLocationProvider;
