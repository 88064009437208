import React, { ReactElement, useEffect, useState } from 'react';
import { TextAccordionHeader } from './style';

export interface AccordionProps{
  headerTitle?: string;
  headerSubTitle?: string;
  accordionID?: string;
  children?: ReactElement;
  secondAction?: ReactElement;
  callback: () => void;
  isOpenAccordion?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  headerTitle = 'Nova vaga',
  headerSubTitle,
  accordionID = 'accordion-vacancies',
  children,
  callback,
  isOpenAccordion = false,
  secondAction,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (isOpenAccordion) setIsOpen(isOpenAccordion);
  }, [isOpenAccordion]);

  return (
    <div className="accordion my-2" id={accordionID}>
      <div className="accordion-item border-bottom-0">
        <TextAccordionHeader className="accordion-header d-flex justify-content-between border-bottom" id={`${accordionID}-headingOne`}>
          <span>{headerTitle}</span>
          {headerSubTitle && <span>{headerSubTitle}</span> }
          <div className="d-flex">
            {secondAction && secondAction}
            <button
              className="accordion-button collapsed bg-white shadow-none text-dark w-auto"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${accordionID}-collapseOne`}
              aria-expanded="false"
              aria-controls={`${accordionID}-collapseOne`}
              onClick={() => {
                callback();
                setIsOpen(true);
              }}
            >
              {' '}
            </button>
          </div>
        </TextAccordionHeader>
        <div
          id={`${accordionID}-collapseOne`}
          className={`accordion-collapse collapse ${isOpenAccordion ? 'show' : ''}`}
          aria-labelledby={`${accordionID}-headingOne`}
          data-bs-parent={`#${accordionID}`}
        >
          <div className="accordion-body">
            {isOpen && children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
