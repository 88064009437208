function doFormat(x: string, pattern: string, mask?: string) {
  const strippedValue = x.replace(/[^0-9]/g, '');
  const chars = strippedValue.split('');
  let count = 0;

  let formatted = '';
  for (let i = 0; i < pattern.length; i += 1) {
    const c = pattern[i];
    if (chars[count]) {
      if (/\*/.test(c)) {
        formatted += chars[count];
        count += 1;
      } else {
        formatted += c;
      }
    } else if (mask) {
      if (mask.split('')[i]) formatted += mask.split('')[i];
    }
  }
  return formatted;
}
export default (id: string) => {
  const e = document.getElementById(id) as HTMLInputElement;
  function format(elem : any) {
    // eslint-disable-next-line no-param-reassign
    return doFormat(elem.value, elem.getAttribute('data-format'), elem.getAttribute('data-mask'));
  }
  e?.addEventListener('keyup', () => {
    e.value = format(e);
  });
  e?.addEventListener('keydown', () => {
    e.value = format(e);
  });
  e.value = format(e);
};
