import styled from 'styled-components';
import colors from '../../colors';

export const DarkBg = styled.div<{ isOpen: boolean }>`
  z-index: 99;
  top: 0;
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: ${colors.dark95};
`;

export const Container = styled.div<{ isOpen: boolean }>`  
  z-index: 100;
  max-width: 400px;
  box-shadow: 0 3px 6px ${colors.dark90};
  width: 45rem;
  background-color: ${colors.white};
  border-radius: 5px;
  display: ${(p) => (p.isOpen ? 'block' : 'none')};

  .conditionType {
    width: 155px;
    margin-left: 1rem;
  }

  .DayPickerInput {
    > input {
      height: 40px;
      width: 155px !important;
      padding: 0 17px;
    }
  }
`;

export const Body = styled.div`
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Header = styled.div`
  div {
    font-weight: bold;
    font-size: 1.3rem;
  }
  button {
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: 1.5rem;
    line-height: 0;
  }
`;

export const Footer = styled.div`
  margin-top: 1rem;
 
  @media (max-width: 1366px) {
    margin-bottom: 1rem;
  }
`;

export const TeamsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const TeamTitle = styled.h6`
  font-family: lato;
  font-weight: 600;
`;

export const TeamText = styled.span`
  font-family: lato;
  text-transform: capitalize;
`;
