import React, { useState } from 'react';
import Select from 'src/components/Select';
import LightTable from 'src/components/LightTable';
import { useTranslation } from 'react-i18next';
import Option from 'src/contracts/models/Option';
import ButtonLoader from 'src/components/ButtonLoader';
import { useToast } from 'src/hooks/toast';
import icons from 'src/assets/icons';
import PlacementService from 'src/services/PlacementService';
import Placement from 'src/contracts/models/Placement';
import Button from '@material-ui/core/Button';
import { useGlobal } from 'src/hooks/useGlobal';
import {
  IconContainer,
} from './style';

interface PlacementTableProps {
  teamState: any;
}
const PlacementTable: React.FC<PlacementTableProps> = ({ teamState }) => {
  const { addToast } = useToast();
  const { user } = useGlobal();
  const { t } = useTranslation('components');
  const [team, setTeam] = useState<any>(teamState);
  const [editRow, setEditRow] = useState<number | null>(null);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [isLoading, setIsLoadig] = useState<boolean>(false);
  const [reservationLimitType, setReservationLimitType] = useState<any>(null);
  const [reservationLimitValue, setReservationLimitValue] = useState<number>(0);
  const [isRelay, setIsRelay] = useState<any>(null);

  const reservationLimit: Option[] = [
    {
      label: t('TeamInfo.weekly'),
      value: 'weekly',
    },
    {
      label: t('TeamInfo.monthly'),
      value: 'monthly',
    },
  ];
  const relayScale: Option[] = [
    {
      label: t('TeamInfo.yes'),
      value: '1',
    },
    {
      label: t('TeamInfo.no'),
      value: '0',
    },
  ];

  const setEditMode = (index: number) => {
    if (editRow !== null) {
      return;
    }
    if (selectedRow === null) {
      setSelectedRow(index);
      return;
    }

    if (editRow === null && index === selectedRow) {
      const placement: Placement = team.placements![index];
      setEditRow(index);
      setReservationLimitType(reservationLimit.find(
        (option) => option.value === placement
          .teamHasPlacements
          .typeReservationEmployee,
      ));
      setReservationLimitValue(placement.teamHasPlacements.maxReservationPerEmployee);
      setIsRelay(relayScale.find(
        (relay) => relay.value === placement
          .teamHasPlacements
          .isRelay?.toString(),
      ));
    } else {
      setSelectedRow(index);
    }
  };

  const closeEditMode = () => {
    if (!isLoading) {
      setEditRow(null);
      setSelectedRow(null);
      setReservationLimitType('');
      setReservationLimitValue(0);
      setIsRelay(0);
    }
  };

  const onEditPlacementSuccess = (
    idPlacement: number,
  ) => {
    const placement: Placement = {
      id: idPlacement,
      teamHasPlacements: {
        typeReservationEmployee: reservationLimitType.value,
        maxReservationPerEmployee: reservationLimitValue,
        isRelay: isRelay.value,
      },
    };
    setTeam((prev: any) => ({
      ...prev,
      placements: prev.placements?.map((p: any) => (p.id === placement.id
        ? {
          ...p,
          teamHasPlacements: placement.teamHasPlacements,
        }
        : p)),
    }));
    closeEditMode();
  };

  const handleSubmitEdit = (idPlacement: number) => {
    setIsLoadig(true);

    const data = {
      team_id: team.id,
      placement_id: idPlacement,
      type_reservation_employee: reservationLimitType.value,
      max_reservation_per_employee: reservationLimitValue,
      is_relay: Number(isRelay.value),
    };

    PlacementService.editRelayConfiguration(data).then(() => {
      setIsLoadig(false);

      onEditPlacementSuccess(idPlacement);
      addToast({
        description: t('TeamInfo.success_update_placement_config'),
        type: 'success',
      });
    }).catch(() => {
      setIsLoadig(false);
      addToast({
        description: t('TeamInfo.error_update_placement_config'),
        type: 'error',
      });
    });
  };

  return (
    <div>
      {!team.placements?.length ? (
        <div className="p-3 font-weight-bold d-flex flex-column align-items-center">
          {t('TeamInfo.empty_spaces')}
          {user?.isAdmin && (
          <Button color="primary" href="/spaces">
            {t('TeamInfo.create_spaces')}
          </Button>
          )}
        </div>
      ) : (
        <LightTable
          align="left"
          selectedRow={selectedRow}
          onClickRow={(row: number) => setEditMode(row)}
          header={[
            [
              <span>
                {t('TeamInfo.position_name')}
                <img
                  className="ms-2"
                  src={icons.Info}
                  alt={t('TeamInfo.tooltips.positions')}
                  data-toggle="tooltip"
                  data-placement="top"
                  title={t('TeamInfo.tooltips.positions')}
                />
              </span>,
              '',
              '',
              t('TeamInfo.is_relay_scale'),
              '',
            ],
          ]}
          headersWithTooltip={[
            null,
            null,
            null,
            t('TeamInfo.info_relay'),
          ]}
          body={team.placements ? team?.placements.map((placement: any, index: number) => [
            <div aria-hidden="true">
              {`${placement.name} - ${placement.location}`}
            </div>,
            <div aria-hidden="true" />,
            <>
            </>,
            <>
              {editRow !== index ? (
                <span aria-hidden="true">
                  {relayScale.find(
                    (relay) => relay.value === placement
                      .teamHasPlacements
                      .isRelay?.toString(),
                  )?.label}
                </span>
              )
                : (
                  <Select
                    options={relayScale}
                    defaultValue={relayScale.find(
                      (relay) => relay.value === placement
                        .teamHasPlacements
                        .isRelay?.toString(),
                    )}
                    onChange={(value) => setIsRelay(value)}
                  />
                )}
            </>,
            <>
              {editRow === index && (
                <IconContainer>
                  <ButtonLoader
                    isLoading={isLoading}
                    label={t('TeamInfo.save')}
                    onClick={() => handleSubmitEdit(Number(placement.id))}
                  />
                  <ButtonLoader
                    style={{ marginLeft: 10 }}
                    transparent
                    withHover={false}
                    icon={icons.close}
                    onClick={() => closeEditMode()}
                  />
                </IconContainer>
              )}
            </>,
          ]) : []}
        />
      )}
    </div>
  );
};

export default PlacementTable;
