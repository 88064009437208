/* eslint-disable import/prefer-default-export */
import JobService from 'src/services/JobService';

export function waitForCompletion(
  waitForSeconds: number,
  onComplete: (data: any) => void,
  onError: (error: any)=> void,
  jobIb: number,
) {
  setTimeout(async () => {
    try {
      const response = await JobService.getJobStatus(jobIb);
      if (String(response.data.status).toUpperCase() !== JobService.STATUS.SUCCESS) {
        const newTimer = waitForSeconds < 32 ? waitForSeconds * 2 : waitForSeconds;
        waitForCompletion(newTimer, onComplete, onError, jobIb);
      } else {
        onComplete(response.data);
      }
    } catch (error) {
      onError(error);
    }
  }, waitForSeconds * 1000);
}
