const scorePassword = (pass: string) => {
  let score = 0;
  if (!pass) return score;

  const letters : any = {};
  for (let i = 0; i < pass.length; i += 1) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  const variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = 0;

  variationCount += variations.digits === true ? 1 : 0;
  variationCount += variations.lower === true ? 1 : 0;
  variationCount += variations.upper === true ? 1 : 0;
  variationCount += variations.nonWords === true ? 1 : 0;

  score += (variationCount - 1) * 10;

  return score;
};

const checkPassStrength = (pass: string) => {
  const score = scorePassword(pass);
  if (score > 80) return { name: 'stronger', value: 4 };
  if (score > 60) return { name: 'strong', value: 3 };
  if (score > 50) return { name: 'medium', value: 2 };
  if (score >= 30) return { name: 'weak', value: 1 };

  return { name: '', value: 0 };
};

const validatePasswordWeak = (
  passwordInput: string,
  onStrongPassword: () => void,
  onMediumPassword: () => void,
  onDefaultPassword: () => void,
) => {
  const regexStrongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})');
  const regexMediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))');

  if (regexStrongPassword.test(passwordInput)) {
    onStrongPassword();
    return;
  }

  if (regexMediumPassword.test(passwordInput)) {
    onMediumPassword();
    return;
  }

  onDefaultPassword();
};

export default {
  checkPassStrength,
  validatePasswordWeak,
};
