import styled from 'styled-components';

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: absolute;
  width: 100vw;
  background-color: #0002;
  left: 0;
  top: 0;
  z-index: 99;
  height: 100%;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  top: 100px;
  margin: 0 auto;
  width: 900px;
  background-color: white;
  box-shadow: 0 3px 6px #00000027;
  transition: 1s ease-in-out;
`;

export const ContainerOutside = styled.div`
  flex: 1;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  h3{
    font-size: 1.3rem;
  }
  button{
    position: absolute;
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
    top: 20px;
    right: 25px;
  }
`;

export const Body = styled.div`
  > div:first-child {
    > div {
      background: none;
      > div{
      }
    }
  }
`;
