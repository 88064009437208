/* eslint-disable import/no-cycle */
/* eslint-disable react/require-default-props */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { useDrop } from 'react-dnd';
import Spinner from 'react-bootstrap/Spinner';
import { DayKeys } from 'src/contracts/models/Day';
import DragTypes from 'src/contracts/models/DragTypes';
import Employee from 'src/contracts/models/Employee';
import { useTranslation } from 'react-i18next';
import { homeContext } from 'src/pages/Home/Context';
import { NotAllocatedDragType } from './ProfessionalNotAllocated';
import { NulledCellContainer, AutomaticAnnouncmentText, ButtonNewReservation } from './style';
import Placement from '../../contracts/models/Placement';
import OpenDay from '../../contracts/models/OpenDay';
import { useCalendarContext } from './context';
import abbreviate from '../../utils/abbreviate';
import colors from '../../colors';

interface NulledCellProps {
  dayName: DayKeys;
  isAutoAnnouncement: boolean;
  placement: Placement;
  day: string;
  section: string;
  onDoubleClick?: | ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | undefined;
  data?: {
    id?: string
    seatId?:number
  }
  handleFractionalShiftDrop?: Function;
  openDay?: OpenDay
}
function NulledCell({
  dayName,
  day,
  onDoubleClick,
  placement,
  section,
  handleFractionalShiftDrop,
  data,
  isAutoAnnouncement,
  openDay,
}: NulledCellProps) {
  const { t } = useTranslation('components');

  const [droppedResult, setDroppedResult] = useState<{
    type: string;
    employee: Employee & {teamId: number; teamName: string};
    shift?: {
      id?: number;
      allocatedAt: string
    };
  }>();
  const {
    submitShiftAllocation, deleteShiftAllocation, shiftLoading, setShiftLoading, selectedUserId,
  } = useContext(
    homeContext,
  );

  const { selectedNulledShifts } = useCalendarContext();
  const [collected, dropRef] = useDrop<
    NotAllocatedDragType,
    any,
    { isOver: boolean; canDrop: boolean; droppedEmployee: Employee, isDragging: boolean}
  >({
    accept: [
      `${DragTypes.PROFESSIONAL_NOT_ALLOCATED}-${dayName}`,
      DragTypes.SHIFT,
      DragTypes.SIDE_BAR_PROFESSIONAL,
    ],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
      isDragging: Boolean(monitor.getItem()),
      droppedEmployee: monitor.getItem(),
    }),
    drop: (item) => {
      setDroppedResult(item);
    },
  });

  const getCellStyle = () => {
    if (!collected.canDrop && collected.isDragging) {
      return {
        backgroundImage: `linear-gradient(141deg, ${colors.red20} 20%, ${colors.white} 20%, ${colors.white} 50%, ${colors.red20} 50%, ${colors.red20} 70%, ${colors.white} 70%, ${colors.white} 100%)`,
        backgroundSize: '7.95px 6.43px',
      };
    }
    if (collected.isOver) {
      return {
        backgroundColor: `${colors.gray25}`,
      };
    }
    return {
      backgroundColor: `${colors.white}`,
    };
  };
  useEffect(() => {
    async function saveShift() {
      if (placement.isAllowFractionalShift) {
        handleFractionalShiftDrop?.(droppedResult?.employee);
      } else {
        setShiftLoading?.(true);

        const employee: any = collected.droppedEmployee;

        const payload = {
          placement_id: placement.id,
          allocated_at: day,
          employee_id: employee?.employee?.id,
          start: `${day} ${openDay?.timeStart || '00:00:00'}`,
          end: `${day} ${openDay?.timeEnd || '00:00:00'}`,
          seat_id: data?.seatId || null,
          team_id: droppedResult?.employee.teamId,
        };
        submitShiftAllocation(payload);
      }
    }

    async function deleteShift() {
      const employeeId = droppedResult?.employee.id;
      const shiftId = droppedResult?.shift?.id;
      const teamId = droppedResult?.employee.teamId;
      deleteShiftAllocation({
        employee_id: Number(employeeId),
        shift_id: Number(shiftId),
        team_id: teamId,
      });
    }

    if (!droppedResult) {
      return () => {};
    }

    if (droppedResult?.type === DragTypes.SHIFT) {
      switch (section) {
        case 'alocation':
          break;
        case 'desalocation':
          deleteShift();
          break;
        default:
          break;
      }
    } else {
      saveShift();
    }

    return () => {};
  },
  [droppedResult]);

  const isSelected = useMemo(
    () => (Boolean(selectedNulledShifts?.[(data?.id ?? '')])),
    [selectedNulledShifts],
  );

  const content = useMemo(() => {
    if (shiftLoading) {
      return <Spinner color="primary" size="sm" animation="border" />;
    }
    if (isSelected && selectedUserId) {
      return (
        <div>
          <strong>{abbreviate(selectedUserId.name, 15, { showLastWorld: true })}</strong>
        </div>
      );
    }

    if (isAutoAnnouncement) {
      return <AutomaticAnnouncmentText>{t('Announcement.auto_announcement')}</AutomaticAnnouncmentText>;
    }

    return <>&nbsp;</>;
  }, [shiftLoading, isAutoAnnouncement, isSelected, selectedUserId]);

  return (
    <NulledCellContainer
      id={day}
      className="bordered cell"
      ref={dropRef}
      onClick={onDoubleClick}
      isOver={collected.isOver}
      style={getCellStyle() as any}
      onDoubleClick={onDoubleClick}
      isSelected={isSelected}
    >
      {content}
      <ButtonNewReservation
        className="button rounded-circle"
        type="button"
        onClick={() => onDoubleClick}
      >
        +
      </ButtonNewReservation>
    </NulledCellContainer>
  );
}

export default NulledCell;
