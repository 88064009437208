/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import colors from 'src/colors';

export const Row = styled.div`
  display: flex;
  
  img {
    margin-left: 10px;
    height: 10px;
    width: 10px;
    background-color: ${colors.white13};
    padding: 2px;
    cursor: pointer;
  }
`;
