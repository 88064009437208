import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  overflow: scroll;
`;

export const FormChild = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const Title = styled.h3`
  font-size: 1.4rem;
  font-weight: bold;
  margin-top: 0;
`;

export const FormGroup = styled.div`
  margin-bottom: 30px;
  margin-top: 15px;
`;
