import styled from 'styled-components';

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: #0002;
  top: 0;
`;

export const ErrorContainer = styled.div`
  > div {
    padding: 0 1rem;
    width: 100%;
  ul {
    li {
      :first-child {
      }
      :last-child{
        border-radius: 0 0 4px 4px;
      }
      color: #a94442;
    }
      background-color: #f2dede;
    list-style-type: none;
    border-radius: 5px;
  }

  }
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  width: 30rem;
  height: 100vh;
  background-color: white;
  box-shadow: 0px 3px 6px #00000027;
  transition: 1s ease-in-out;
  
  .btn-announcent{
    background: var(--bg-light);
    border: solid 1px var(--border-light);
    font-size: 16px;
    padding: 14px 25px;
    text-align: left;
    
    img{
      width: 17px;
    }
    
    span{
      color: var(--primary-gray);
      display: initial;
      margin-left: 15px;
    }
  }
  
  .btn-close{
    position: absolute;
    right: 25px;
    top: 13px;
  }
  
  button.close-button{
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
    position: absolute;
    right: 35px;
    top: 17px;
  }
`;

export const ContainerOutside = styled.div`
  flex: 1;
  height: 100%;
`;

export const Header = styled.div`
  > h3{         
    font-size: 1.3rem;
  }
  > h5{
    color: var(--primary);
  }
  > button{
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
  }
`;

export const Body = styled.div`
  > div:first-child {
    > div {
      margin-top: 2rem;
      @media(max-width: 1366px){
        margin-top: 1.4rem;
      }
    }
  }
  button{
    &:nth-child(2){
      display: none;
    }
  }
  
  .DateRangePicker{
    > div{
      > div, .DateInput, .DateInput_input{
        background: transparent;
        font-weight: normal;
        font-size: 14px;
      }
      > div {
         border-color: #CCC !important;
         .DateInput{
           padding: 8px 6px !important;
         }
         .DateInput_input{
           line-height: 18px;
           text-align: center;
         }
      }
    }
  }
  
  .single-select-input{
    > div{
      > div{
        background: #fff;
        border-color: #CCC !important;
      }
    }
  }
  .single-select-label{
    font-size: 13px;
    color: var(--secondary-gray);
    margin-bottom: 5px;
  }
`;

export const Footer = styled.div`
  width: 100%;
`;
