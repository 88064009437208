import styled from 'styled-components';
import { AsyncPaginate } from 'react-select-async-paginate';
import colors from '../../colors';

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Label = styled.span`
  font: normal normal medium 5px/5px lato;
  font-size: 13px !important;
  color: ${colors.gray90};
  margin-bottom: 5px;
`;

export const Selector = styled(AsyncPaginate)`
  .select__control {
    overflow: auto;
    max-height: 85px;
  }
  > div {
    border-color: ${colors.gray28} !important;
    :nth-child(2) {
      box-shadow: none;
    }
    
    .select__indicators {
      align-items: flex-start;
    }
    .select__loading-indicator {
      span {
        height: 5px;
        width: 5px;
      }
    }
  }
`;
