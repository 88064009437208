import React, { useState, useEffect } from 'react';
import { useToast } from 'src/hooks/toast';
import EmployeeService from 'src/services/EmployeeService';
import Employee from 'src/contracts/models/Employee';
import Option from 'src/contracts/models/Option';
import ButtonLoader from 'src/components/ButtonLoader';
import EmployeeSelector from 'src/components/EmployeeSelector';
import { useTranslation } from 'react-i18next';
import { formatEmployee } from 'src/utils/employee.functions';
import {
  Container,
  Content,
  ButtomContainer,
} from './style';

interface NewEmployeeProps {
  teamId: number;
  handleSuccess: Function;
  setTotalUsers: React.Dispatch<React.SetStateAction<number>>;
}

const AssociateEmployee: React.FC<NewEmployeeProps> = ({
  teamId,
  handleSuccess,
  setTotalUsers,
}) => {
  const { addToast } = useToast();
  const { t } = useTranslation('components');
  const [isLoading, setIsLoading] = useState(false);
  const [employees, setEmployees] = useState<Array<Employee>>([]);
  const [searchName, setSearchName] = useState<string>('');
  const [selectedEmployees, setSelectedEmployees] = useState<Option[]>([]);

  const getEmployees = () => {
    EmployeeService.getUsersAll({
      page: 1,
      perPage: 10,
      searchName,
      teamId,
    }).then((res: any) => {
      if (res.data.data === null) {
        setIsLoading(false);
        return;
      }
      setEmployees(formatEmployee(res.data.data));
      setTotalUsers(res.data.data.length);
    }).catch(() => {
      addToast({
        description: t('ModalTeamAddEmployee.associate_employee_tab.error_load_employee'),
        type: 'error',
      });
    });
  };

  useEffect(() => {
    if (searchName.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        getEmployees();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    getEmployees();

    return () => false;
  }, [searchName]);

  const onSubmit = () => {
    setIsLoading(true);
    if (!selectedEmployees.length) {
      addToast({
        description: t('ModalTeamAddEmployee.associate_employee_tab.no_employee_error'),
        type: 'error',
      });
      setIsLoading(false);
      return;
    }
    EmployeeService.associateEmployeesTeam(
      teamId,
      selectedEmployees.map((item) => ({ id: Number(item.value) })),
    ).then((res) => {
      setIsLoading(false);
      handleSuccess(res);
      addToast({
        description: t('ModalTeamAddEmployee.associate_employee_tab.associate_success'),
        type: 'success',
      });
    }).catch((err) => {
      if (err.response && err.response.status === 422) {
        addToast({
          description: t('ModalTeamAddEmployee.associate_employee_tab.associate_error_exists'),
          type: 'error',
        });
      } else {
        addToast({
          description: t('ModalTeamAddEmployee.associate_employee_tab.associate_error'),
          type: 'error',
        });
      }

      setIsLoading(false);
    });
  };

  return (
    <Container>
      <Content>
        <EmployeeSelector
          options={
            employees.map((item) => ({
              label: item.name,
              value: String(item.id),
              photo: item.photo,
              email: item.email,
            }))
}
          isLoading={isLoading}
          onInputChange={(value: string) => setSearchName(value)}
          value={selectedEmployees}
          onChange={(newEmployees) => setSelectedEmployees(newEmployees)}
        />
      </Content>
      <ButtomContainer>
        <ButtonLoader
          type="button"
          onClick={(e) => {
            e.preventDefault();
            onSubmit();
          }}
          label={t('ModalTeamAddEmployee.associate_employee_tab.send')}
          isLoading={isLoading}
        />
      </ButtomContainer>
    </Container>
  );
};

export default AssociateEmployee;
