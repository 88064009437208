import styled from 'styled-components';

export const SideBarContainer = styled.div`
  position: fixed;
  grid-area: pb;
  height: calc(100vh);
  z-index: 98;
  @media only screen and (max-width: 768px) {
    padding-top: 63.40px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 60px;
  width: 85%;
  cursor: pointer;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  img {
    height: 16px;
    width: 16px;
    margin-right: 10px;
    filter: invert(51%) sepia(6%) saturate(1025%) hue-rotate(190deg) brightness(89%) contrast(82%);
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
  }
  button {
    color: var(--secondary-gray);
    font: normal normal bold 13px/15px lato;
  }
`;
