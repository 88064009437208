import styled from 'styled-components';
import colors from '../../colors';

export const ConfirmButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border-radius: 10px;
  background-color: ${colors.blue};
  color: ${colors.white};
  outline: none;
  border: none;
  font: normal normal bold 12px/29px lato;
`;
export const RefuseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 30px;
  border-radius: 10px;
  background-color: transparent;
  color: ${colors.blue};
  outline: none;
  border: 1px solid ${colors.gray25};
  font: normal normal bold 12px/29px lato;
`;
