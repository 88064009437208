import styled from 'styled-components';

export const Container = styled.div`
  > div {
    display: initial;
  }
  > label {
    margin-bottom: 0;
    color: var(--secondary-grey);
    font-size: 0.875rem;
  }
`;

export const TextAreaStyled = styled.textarea`
  background: var(---striped) 0% 0% no-repeat padding-box;
  background: #fdfdfe 0% 0% no-repeat padding-box;
  border: 1px solid #e3e5eb;
  border-radius: 5px;
  padding: .8rem 1.2rem;
  resize: none;
`;
