import React from 'react';
import ButtonLoader, { ButtonLoaderProps } from 'src/components/ButtonLoader';

const ICON_BUTTONS_SIZE = 20;
const FULL_PADDING_SIZE = 10;
const NONE_PADDING_SIZE = 0;

export interface ActionButtonProps extends ButtonLoaderProps {
  hasPadding?:boolean
  tooltipTitle?: string
}

const ActionButton = ({
  tooltipTitle,
  hasPadding = false, ...props
}: ActionButtonProps) => {
  const style: React.CSSProperties = {
    padding: hasPadding ? FULL_PADDING_SIZE : NONE_PADDING_SIZE,
  };

  return (
    <ButtonLoader
      {...props}
      transparent
      iconSize={ICON_BUTTONS_SIZE}
      withHover={false}
      style={style}
      data-toggle="tooltip"
      data-placement="top"
      title={tooltipTitle}
    />
  );
};

export default ActionButton;
