import React, { useState } from 'react';

export type SelectedUser = number;
export type State = {
  selectedUsers: SelectedUser[]
};
export type Action = {
  addUser: (id: number) => void
  removeUser: (id: number) => void
  verifyIfUserAlreadySelected: (id: number) => boolean
  resetList: () => void
};
export type SelectedUsers = [State, Action];

export type SelectedUsersProviderProps = {
  children: React.ReactNode
};

const SelectedUsersContext = React.createContext<SelectedUsers>(
  [
    {
      selectedUsers: [],
    },
    {
      addUser: () => null,
      removeUser: () => null,
      resetList: () => null,
      verifyIfUserAlreadySelected: () => false,
    },
  ],
);

const SelectedUsersProvider = (props: SelectedUsersProviderProps) => {
  const [selectedUsers, setSelectedUsers] = useState<SelectedUser[]>([]);

  const addUser = (id: number) => setSelectedUsers((prevState) => [...prevState, id]);

  const removeUser = (id: number) => setSelectedUsers(
    selectedUsers.filter((selectedUserId) => selectedUserId !== id),
  );

  const resetList = () => setSelectedUsers([]);

  const verifyIfUserAlreadySelected = (id: number) => selectedUsers.includes(id);

  return (
    <SelectedUsersContext.Provider
      {...props}
      value={[{ selectedUsers }, {
        addUser, removeUser, resetList, verifyIfUserAlreadySelected,
      }]}
    />
  );
};

SelectedUsersProvider.Context = SelectedUsersContext;

export default SelectedUsersProvider;
