import styled from 'styled-components';

interface ITypeColor {
  color?: '#575757' | '#000E4C'
}
export const TextHeader = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #575757;
`;

export const TextBody = styled.p<ITypeColor>`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
`;
