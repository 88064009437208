import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/Select';
import Option from 'src/contracts/models/Option';
import {
  ContainerPlacementDetails,
  FormPlacementDetails,
  LimitCount,
} from './style';

interface PlacementDetailsProps {
    item: any;
    onChange: Function;
}

const PlacementDetails: React.FC<PlacementDetailsProps> = ({ item, onChange }) => {
  const { t } = useTranslation('components');
  const reservationLimit: Option[] = [
    {
      label: t('TeamInfo.weekly'),
      value: 'weekly',
    },
    {
      label: t('TeamInfo.monthly'),
      value: 'monthly',
    },
  ];
  const [reservationLimitType, setReservationLimitType] = useState<any>(reservationLimit.find(
    (option) => option.value === item
      .type_reservation,
  ));
  const [reservationLimitValue, setReservationLimitValue] = useState<number>(
    item.max_reservation,
  );

  useEffect(() => {
    onChange({
      placement_id: item.placement_id,
      max_reservation: reservationLimitValue,
      type_reservation: reservationLimitType.value,
    });
  }, [reservationLimitValue, reservationLimitType]);

  return (
    <ContainerPlacementDetails>
      <h6>
        <strong>
          {`${item.name_placement} (${t(
            `TeamInfo.placement_types.${item.placement_types}`,
          )})`}
        </strong>
      </h6>
      <FormPlacementDetails className="d-flex justify-content-between align-items-end">
        <div style={{ width: '49%' }}>
          <Select
            options={reservationLimit}
            defaultValue={reservationLimitType}
            onChange={(option) => setReservationLimitType(option)}
          />
        </div>
        <LimitCount bordered className="d-flex align-items-center">
          <input
            type="text"
            value={reservationLimitValue}
            onChange={(e) => setReservationLimitValue(Number(e.target.value))}
          />
          <span>{t('TeamInfo.times')}</span>
        </LimitCount>
      </FormPlacementDetails>
    </ContainerPlacementDetails>
  );
};

export default PlacementDetails;
