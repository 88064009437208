import { useCallback, useEffect } from 'react';

export interface UseHandleKeyDownParams {
  onEventKeyIsEnter: () => void
  onEventKeyIsEscape: () => void
}

const useHandleKeyDown = ({ onEventKeyIsEnter, onEventKeyIsEscape }: UseHandleKeyDownParams) => {
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      onEventKeyIsEscape();
      return;
    }

    if (event.key === 'Enter') {
      onEventKeyIsEnter();
    }
  }, [onEventKeyIsEnter, onEventKeyIsEscape]);

  useEffect(() => {
    document.onkeydown = handleKeyDown;

    return () => {
      document.onkeydown = null;
    };
  }, [handleKeyDown]);
};

export default useHandleKeyDown;
