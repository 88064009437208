import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import LightTable from 'src/components/LightTable';
import ConfirmModal from 'src/components/ConfirmModal';
import Employee from 'src/contracts/models/Employee';
import EmployeeService from 'src/services/EmployeeService';
import UserTableBody from './user_table_body';
import UserTableHeader, { RoleFilterOption } from './user_table_header';

export interface UserListProps {
  users: Employee[];
  isFilteringByInactiveRole: boolean;
  isLoading: boolean;
  handleChangeIsUserInactiveFilter: (isActive: boolean) => void;
  handleChangeUserRoleNameFilter: (name: string) => void
  onEdit: Function;
  onDelete: Function;
  onRestore: Function;
  selectedUserRoleNameFilter?: string;
  rolesFilterOptions: RoleFilterOption[],
  handleAddAllUsers: () => void
}

const UserList: React.FC<UserListProps> = ({
  users = [],
  isLoading,
  isFilteringByInactiveRole,
  handleChangeIsUserInactiveFilter,
  onEdit,
  onDelete,
  onRestore,
  selectedUserRoleNameFilter,
  handleChangeUserRoleNameFilter,
  rolesFilterOptions,
  handleAddAllUsers,
}) => {
  const { t } = useTranslation('pages');
  const { addToast } = useToast();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState<boolean>(false);
  const [showConfirmRestoreModal, setShowConfirmRestoreModal] = useState<boolean>(false);

  const [selectedDeleteId, setSelectedDeleteId] = useState<number>(0);
  const [selectedRestoreId, setSelectedRestoreId] = useState<number>(0);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenUserRoleDropdown, setIsOpenUserRoleDropdown] = useState<boolean>(false);

  const startDelete = (id: number) => {
    setShowConfirmDeleteModal(true);
    setSelectedDeleteId(id);
  };

  const startRestore = (id: number) => {
    setShowConfirmRestoreModal(true);
    setSelectedRestoreId(id);
  };

  const handleDeleteEmployee = () => {
    EmployeeService.deleteEmployee(selectedDeleteId)
      .then(() => {
        onDelete();
        setShowConfirmDeleteModal(false);
      })
      .catch(() => {
        setShowConfirmDeleteModal(false);
        addToast({
          description: t('Administration.tabs.user.error_delete_message'),
          type: 'error',
        });
      });
  };

  const handleRestoreEmployee = () => {
    EmployeeService.restoreEmployee(selectedRestoreId)
      .then(() => {
        onRestore();
        setShowConfirmRestoreModal(false);
      })
      .catch(() => {
        setShowConfirmRestoreModal(false);
        addToast({
          description: t('Administration.tabs.user.error_restore_message'),
          type: 'error',
        });
      });
  };

  const toggleDropdownUsersStatus = () => {
    setIsOpen(!isOpen);
  };

  const toggleUserRoleDropdown = () => {
    setIsOpenUserRoleDropdown(!isOpenUserRoleDropdown);
  };

  const handleCloseButtonUserConfirmDeleteModal = useCallback(() => {
    setShowConfirmDeleteModal(false);
  }, []);

  const handleCloseButtonUserConfirmRestoreUserModal = useCallback(() => {
    setShowConfirmRestoreModal(false);
  }, []);

  return (
    <div className="overflow-auto">
      <LightTable
        align="left"
        iconled
        isLoading={isLoading}
        header={UserTableHeader({
          handleChangeIsUserInactiveFilter,
          handleChangeUserRoleNameFilter,
          isOpen,
          isOpenUserRoleDropdown,
          isFilteringByInactiveRole,
          rolesFilterOptions,
          selectedUserRoleNameFilter,
          toggleDropdownUsersStatus,
          toggleUserRoleDropdown,
          handleAddAllUsers,
          users,
        })}
        body={
          UserTableBody({
            isFilteringByInactiveRole, onEdit, startDelete, startRestore, users,
          })
        }
      />
      <ConfirmModal
        center
        isOpen={showConfirmDeleteModal}
        handleCloseButton={handleCloseButtonUserConfirmDeleteModal}
        handleConfirm={handleDeleteEmployee}
        message={t('Administration.tabs.user.confirm_message')}
        title={t('Administration.tabs.user.confirm_title')}
      />
      <ConfirmModal
        center
        isOpen={showConfirmRestoreModal}
        handleCloseButton={handleCloseButtonUserConfirmRestoreUserModal}
        handleConfirm={handleRestoreEmployee}
        message={t('Administration.tabs.user.confirm_restore_message')}
        title={t('Administration.tabs.user.confirm_restore_title')}
      />
    </div>
  );
};

export default UserList;
