import styled from 'styled-components';

const ContainerLocation = styled.div`
  transition: 0.3s;
  padding-top: 25px;
  padding-bottom: 25px;
  @media only screen and (max-width: 768px) {
    padding-top: 99px !important;
  }
  background: rgb(248, 248, 248)
`;

const IconTitle = styled.img`
  width: 25px;
  height: 20px;
  color: rgb(59, 66, 82);
  filter: brightness(0) saturate(100%) invert(7%) sepia(31%) saturate(7050%) hue-rotate(224deg) brightness(109%) contrast(113%);
`;

const Title = styled.h3`
  color: rgb(0, 11, 76);
`;

export {
  ContainerLocation,
  IconTitle,
  Title,
};
