const toSnakeCase = (string: string) => string.split('').map((letter, index) => (letter.toUpperCase() === letter
  ? `${index !== 0 ? '_' : ''}${letter.toLowerCase()}`
  : letter)).join('');

export default function keysToSnake<ReturnType = any>(object : any) : ReturnType {
  if (typeof object === 'object' && object !== null) {
    const result : any = {};

    Object.keys(object)
      .forEach((key) => {
        result[toSnakeCase(key) as any] = keysToSnake(object[key]);
      });

    return result;
  } if (Array.isArray(object)) {
    return (object.map((value) => keysToSnake(value)) as any) as ReturnType;
  }

  return object;
};
