/* eslint-disable no-param-reassign */
/* eslint-disable import/no-duplicates */
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  /* isAfter, */ isBefore,
} from 'date-fns';
import React, {
  ChangeEvent, useEffect, useMemo, useState,
} from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import { useTranslation } from 'react-i18next';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFnsParse from 'date-fns/parse';
import { ptBR } from 'date-fns/esm/locale';
import dateFnsFormat from 'date-fns/format';
import { isEqual } from 'date-fns/esm';
import Select from '../Select';
import CalendarNavbar from './CalendarNavbar';
import { useModalReservationEditContext, Journey } from './context';
import { AddRuleButton, RuleContainer } from './style';
import maskDate from '../../utils/maskDate';
import Input from '../Input';

type DailyFrequencyProps = {
  currentDate?: Date;
  type?: 'edit' | 'create'
};
const DaiLyFrequency: React.FC<DailyFrequencyProps> = ({ currentDate, type }) => {
  const { t } = useTranslation('components');

  const {
    months,
    days,
    daysOnlyFirstLetter,
    setDates,
    payload,
    setPayload,
  } = useModalReservationEditContext();

  const [viewedMonth, setViewedMonth] = useState(currentDate || new Date());

  const selectedDays = useMemo(() => {
    let iterationDate = currentDate || new Date();
    let dates: Date[] = [];

    const applyRule = (journey: Journey) => {
      for (let i = 1; i <= journey.days; i += 1) {
        if (payload.duration === 'count' && dates.length >= payload.count) {
          break;
        }
        if (
          payload.duration === 'until'
          && (isAfter(
            iterationDate,
            new Date(`${payload.datetimeUntil}T00:00:00`),
          )
            || isEqual(
              iterationDate,
              new Date(`${payload.datetimeUntil}T00:00:00`),
            ))
        ) {
          break;
        }
        dates = dates.concat(iterationDate);
        iterationDate = addDays(iterationDate, 1);
      }

      // add rule's interval to next iteration day
      iterationDate = addDays(iterationDate, journey.rest);
    };

    const handleCountDuration = () => {
      for (let i = 0; i < (payload.count || 0); i += 1) {
        payload.journeys.forEach(applyRule);
      }
    };

    const handleUntilDuration = () => {
      while (
        isBefore(
          iterationDate,
          (new Date(`${payload.datetimeUntil}T00:00:00`) as Date)
            || viewedMonth,
        )
      ) {
        payload.journeys.forEach(applyRule);
      }
    };

    const handleForeverDuration = () => {
      while (
        isBefore(
          iterationDate,
          endOfWeek(endOfMonth(viewedMonth), { weekStartsOn: 1 }),
        )
        || dates.length === 30
      ) {
        payload.journeys.forEach(applyRule);
      }
    };

    switch (payload.duration) {
      case 'count': {
        handleCountDuration();
        break;
      }
      case 'until': {
        handleUntilDuration();
        break;
      }
      case 'forever': {
        handleForeverDuration();
        break;
      }
      default: {
        break;
      }
    }

    return dates;
  }, [viewedMonth, payload]);

  useEffect(() => {
    setDates(selectedDays);
  }, [selectedDays]);

  const stopConditionOptions = [
    {
      label: t('ModalShiftEdit.duration_types.never'),
      value: 'forever',
    },
    {
      label: t('ModalShiftEdit.duration_types.count'),
      value: 'count',
    },
    {
      label: t('ModalShiftEdit.duration_types.until'),
      value: 'until',
    },
  ];

  const afterOptions = Array.from({ length: 31 }, (_, i) => ({
    value: i + 1,
    label: `${i + 1} ${t('ModalShiftEdit.times')}`,
  }));

  const handleDayChange = (index: number) => (
    evt: ChangeEvent<HTMLInputElement>,
  ) => {
    setPayload((prev: any) => {
      const journeys = prev.journeys.map((el: any, i: number) => (i === index
        ? {
          ...el,
          days: +evt.target.value > 99 ? el.days : +evt.target.value,
        }
        : el));

      return {
        ...prev,
        journeys,
      };
    });
  };

  const handleRestChange = (index: number) => (
    evt: ChangeEvent<HTMLInputElement>,
  ) => {
    setPayload((prev: any) => {
      const journeys = prev.journeys.map((el: any, i: number) => (i === index
        ? {
          ...el,
          rest: +evt.target.value > 99 ? el.rest : +evt.target.value,
        }
        : el));

      return {
        ...prev,
        journeys,
      };
    });
  };

  const handleAddRuleClick = () => {
    setPayload((prev: any) => ({
      ...prev,
      journeys: [...prev.journeys, { days: 1, rest: 1 }],
    }));
  };

  const handleRemoveRuleClick = (index: number) => () => {
    setPayload((prev: any) => ({
      ...prev,
      journeys: prev.journeys.filter((_: any, i: number) => index !== i),
    }));
  };

  function parseDate(str: string, fmt: string) {
    const parsed = dateFnsParse(str, fmt, new Date(), { locale: ptBR });
    if (DateUtils.isDate(parsed)) {
      return parsed;
    }
    return undefined;
  }

  function formatDate(date: Date, fmt: string) {
    return dateFnsFormat(date, fmt, { locale: ptBR });
  }

  return (
    <div>
      {payload.journeys.map((rule, i, arr) => (
        <RuleContainer className="row mt-4">
          <div className="col-10">
            <span>{t('ModalShiftEdit.in_each')}</span>
            <input
              style={{ width: '50px' }}
              min="1"
              type="number"
              maxLength={2}
              max={99}
              value={rule.days}
              onChange={handleDayChange(i)}
              className="mx-2 p-1"
              disabled={type === 'edit'}
            />
            <span>{t('ModalShiftEdit.days')}</span>
            <span>{` ${t('ModalShiftEdit.with_interval')}`}</span>
            <input
              style={{ width: '50px' }}
              min="1"
              type="number"
              maxLength={2}
              value={rule.rest}
              onChange={handleRestChange(i)}
              className="mx-2 p-1"
              disabled={type === 'edit'}
            />
            <span>{t('ModalShiftEdit.days')}</span>
          </div>
          {type === 'create' && arr.length !== 1 && (
            <div className="col-2">
              <button type="button" onClick={handleRemoveRuleClick(i)}>
                &#x2715;
              </button>
            </div>
          )}
        </RuleContainer>
      ))}
      <div className="row mt-4">
        <div className="col-12">
          {
            type === 'create' && (
            <AddRuleButton type="button" onClick={handleAddRuleClick}>
              {t('ModalShiftEdit.new_rule')}
            </AddRuleButton>
            )
          }
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex align-items-center">
            <span>{t('ModalShiftEdit.stop_repetition')}</span>
            <div className="conditionType">
              <Select
                isDisabled={
                type === 'edit'
              }
                onChange={(option: any) => {
                  setPayload({
                    ...payload,
                    duration: option.value,
                    count: null,
                    datetimeUntil: null,
                  });
                }}
                options={stopConditionOptions}
                defaultValue={stopConditionOptions[0]}
              />
            </div>
            {payload.duration === 'count' && (
              <div className="conditionType">
                <Select
                  isDisabled={type === 'edit'}
                  name="stopCondition"
                  value={payload.count && {
                    value: payload.count,
                    label: `${payload.count} ${t('ModalShiftEdit.times')}`,
                  }}
                  onChange={(option: any) => {
                    setPayload({
                      ...payload,
                      datetimeUntil: null,
                      count: option.value,
                    });
                  }}
                  options={[
                    {
                      options: afterOptions,
                    },
                  ]}
                />
              </div>
            )}
            <div>
              {payload.duration === 'until' && (
                <div className="conditionType">
                  {
                    type === 'edit'
                      ? <Input disabled value={new Date(`${payload.datetimeUntil}T00:00:00`).toLocaleDateString('pt-BR')} />
                      : (
                        <DayPickerInput
                          onDayChange={(day) => {
                            if (day) {
                              setPayload((prev: any) => ({
                                ...prev,
                                count: null,
                                datetimeUntil: format(day, 'yyyy-MM-dd'),
                              }));
                            }
                          }}
                          parseDate={parseDate}
                          formatDate={formatDate}
                          placeholder="DD/MM/AAAA"
                          format="dd/MM/yyyy"
                          dayPickerProps={{
                            disabledDays: [{ before: currentDate || new Date() }],
                            months: months.map((month) => month.name),
                            weekdaysLong: days.map((day) => day.name),
                            weekdaysShort: daysOnlyFirstLetter.map((day) => day.name),
                          }}
                          inputProps={{
                            style: {
                              height: '40px',
                              padding: '0 17px',
                              background: '#ffffff 0% 0% no-repeat padding-box',
                              borderRadius: '4px',
                              opacity: 1,
                              color: 'var(--primary-gray)',
                              fontSize: '1rem',
                              verticalAlign: 0,
                            },
                            maxLength: 10,
                            onChange: (evt: ChangeEvent<HTMLInputElement>) => {
                              evt.target.value = maskDate(evt.target.value);
                            },
                          }}
                        />
                      )
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex flex-column">
            <div>
              <span>{t('ModalShiftEdit.result')}</span>
            </div>
            <div className="mt-3">
              <DayPicker
                disabledDays={[{ before: currentDate || new Date() }]}
                month={viewedMonth}
                firstDayOfWeek={1}
                months={months.map((month) => month.name)}
                weekdaysLong={days.map((day) => day.name)}
                weekdaysShort={daysOnlyFirstLetter.map((day) => day.name)}
                selectedDays={selectedDays}
                onMonthChange={setViewedMonth}
                captionElement={<></>}
                enableOutsideDaysClick={false}
                showOutsideDays
                navbarElement={(props) => <CalendarNavbar {...props} />}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DaiLyFrequency;
