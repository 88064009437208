import React, { useState, useEffect } from 'react';
import LightTable from 'src/components/LightTable';
import Employee from 'src/contracts/models/Employee';
import SelectWeekDay from 'src/components/SelectWeekDay';
import ButtonLoader from 'src/components/ButtonLoader';
import ModalLimitReservationPerEmployee from 'src/components/ModalLimitReservationPerEmployee';
import { useToast } from 'src/hooks/toast';
import { numberToWeek, weekToNumber } from 'src/utils/binaryWeekDays';
import getNameInitials from 'src/utils/getNameInitials';
import TeamRoles from 'src/contracts/constants/team.roles.constants';
import EmployeeService from 'src/services/EmployeeService';
import icons from 'src/assets/icons';
import Input from 'src/components/Input';
import { useTranslation } from 'react-i18next';
import { mixpanelTrack } from 'src/services/MixPanelService';
import JustificationModal from './JustificationModal';
import {
  AvailabilityForPresenceHeader,
  ProfessionalDetail,
  ProfessionalWithoutProfileImage,
  IconContainer,
} from './style';
import { useAlert } from '../../hooks/useAlert';

interface EmployeeInfoProps {
  teamState: any;
  refreshEmployees: () => void;
}
const EmployeeInfo: React.FC<EmployeeInfoProps> = ({
  teamState,
  refreshEmployees,
}) => {
  const { openAlert } = useAlert();
  const { addToast } = useToast();
  const { t } = useTranslation('components');
  const [justificationData, setJustificationData] = useState<{
    team: any;
    professional: Employee;
  }>();
  const [editRow, setEditRow] = useState<number | null>(null);
  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const [team, setTeam] = useState<any>(teamState);
  const [isLoading, setIsLoadig] = useState<boolean>(false);
  const [modalLimit, setModalLimit] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(
    null,
  );
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [days, setDays] = useState<number>(0);

  useEffect(() => {
    setTeam(teamState);
  }, [teamState]);

  const handleDelete = (employee: any) => () => {
    const onConfirm = async () => {
      try {
        const response = await EmployeeService.deleteTeamsEmployee({
          employeeId: employee.id,
          roleId: TeamRoles.COLABORATOR,
          teamId: teamState.id,
          type: 'DELETE',
        });

        if (response.status === 200) {
          refreshEmployees();
          addToast({
            description: t('TeamInfo.user.success_delete_message'),
            type: 'success',
          });
        } else {
          addToast({
            description: t('TeamInfo.user.error_delete_message'),
            type: 'error',
          });
        }
      } catch (e) {
        addToast({
          description: t('TeamInfo.user.error_delete_message'),
          type: 'error',
        });
      }
    };
    openAlert({
      title: t('TeamInfo.user.confirm_title'),
      handleConfirm: onConfirm,
      message: t('TeamInfo.user.confirm_message', {
        name: employee.name,
        teamName: teamState.name,
      }),
      messageConfirm: t('TeamInfo.user.confirm_message_reforce'),
      center: true,
    });
  };

  const setEditMode = (index: number) => {
    if (editRow !== null) {
      return;
    }
    if (selectedRow === null) {
      setSelectedRow(index);
      return;
    }

    if (editRow === null && index === selectedRow) {
      const professional: Employee = team.contributors![index];
      setEditRow(index);
      setName(professional.name);
      setEmail(professional.email!);
      setDays(professional.days!);
    } else {
      setSelectedRow(index);
    }
  };

  const closeEditMode = () => {
    if (!isLoading) {
      setEditRow(null);
      setSelectedRow(null);
      setName('');
      setEmail('');
      setDays(0);
    }
  };

  const onEditEmployeeSuccess = (idEmployee: number) => {
    const employee: Employee = {
      id: idEmployee,
      name,
      email,
      days,
    };
    setTeam((prev: any) => ({
      ...prev,
      contributors: prev.contributors?.map((e: any) => (e.id === employee.id
        ? {
          ...e,
          name: employee.name,
          days: employee.days,
          email: employee.email,
        }
        : e)),
    }));
    closeEditMode();
  };

  const handleSubmitEdit = (employee: any) => {
    setIsLoadig(true);
    const idEmployee = Number(employee.id);
    const isEditAvailability = employee.days !== days;
    if (isEditAvailability) {
      mixpanelTrack('availability@editing', null);
    }

    const body = new FormData();
    body.append('days', String(days) || '');
    body.append('role_team', String(TeamRoles.COLABORATOR));
    body.append('name', name);
    body.append('email', email || '');
    body.append('team_id', String(team.id) || '');

    if (email && email.length > 0) {
      body.append('email', email);
    }

    EmployeeService.editEmployee(idEmployee, body)
      .then(() => {
        setIsLoadig(false);
        if (isEditAvailability) {
          mixpanelTrack('availability@edited', null);
        }
        addToast({
          description: t('TeamInfo.success_update_placement_config'),
          type: 'success',
        });
        onEditEmployeeSuccess(idEmployee);
      })
      .catch((err) => {
        setIsLoadig(false);
        if (err.response && err.response.status === 400) {
          return addToast({
            description: err.response.data.message,
            type: 'error',
          });
        }
        return addToast({
          description: t('TeamInfo.error_update_placement_config'),
          type: 'error',
        });
      });
  };

  return (
    <div className="overflow-auto">
      <LightTable
        align="left"
        selectedRow={selectedRow}
        onClickRow={(row: number) => setEditMode(row)}
        onClickOutside={() => {
          closeEditMode();
        }}
        header={[
          [
            t('TeamInfo.name'),
            t('TeamInfo.email'),
            <AvailabilityForPresenceHeader>
              <div>
                <strong>{t('TeamInfo.availability_for_presence')}</strong>
              </div>
              <div>
                <span>{t('TeamInfo.week_days')}</span>
              </div>
            </AvailabilityForPresenceHeader>,
            t('TeamInfo.total_days'),
            '',
            '',
          ],
        ]}
        body={
          team.contributors
            ? team?.contributors.map((professional: any, index: number) => [
              <ProfessionalDetail className="d-flex">
                {editRow !== index ? (
                  <>
                    {professional.photo ? (
                      <img src={professional?.photo} alt="" />
                    ) : (
                      <ProfessionalWithoutProfileImage>
                        {getNameInitials(professional.name)}
                      </ProfessionalWithoutProfileImage>
                    )}
                    <p>{professional.name}</p>
                  </>
                ) : (
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                )}
              </ProfessionalDetail>,
              <>
                {editRow !== index ? (
                  <div aria-hidden="true">{professional.email || '-'}</div>
                ) : (
                  <Input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                )}
              </>,
              <>
                {editRow !== index ? (
                  <div aria-hidden="true" style={{ maxWidth: 250 }}>
                    <SelectWeekDay
                      size="small"
                      disabled
                      selectedDaysDefault={
                        numberToWeek(
                          professional.days ? professional.days : 0,
                        ) as any[]
                      }
                    />
                  </div>
                ) : (
                  <SelectWeekDay
                    size="small"
                    selectedDaysDefault={
                      numberToWeek(
                        professional.days ? professional.days : 0,
                      ) as any[]
                    }
                    onChange={(values) => {
                      mixpanelTrack('availability@change', null);
                      setDays(weekToNumber(values));
                    }}
                  />
                )}
              </>,
              <div aria-hidden="true">
                {editRow !== index
                  ? t('TeamInfo.days', {
                    total: numberToWeek(
                      professional.days ? professional.days : 0,
                    )?.length,
                  })
                  : t('TeamInfo.days', {
                    total: numberToWeek(days || 0)?.length,
                  })}
              </div>,
              <>
                {editRow === index && (
                  <IconContainer>
                    <ButtonLoader
                      isLoading={isLoading}
                      label={t('TeamInfo.save')}
                      onClick={() => {
                        handleSubmitEdit(professional);
                      }}
                    />
                    <ButtonLoader
                      style={{ marginLeft: 10 }}
                      transparent
                      withHover={false}
                      icon={icons.close}
                      onClick={() => closeEditMode()}
                    />
                  </IconContainer>
                )}
              </>,
              <>
                <IconContainer>
                  <ButtonLoader
                    uppercase={false}
                    label={t('TeamInfo.remove_employee')}
                    transparent
                    style={{ marginLeft: 10 }}
                    icon={icons.TrashRed}
                    onClick={handleDelete(professional)}
                    withHover={false}
                  />
                </IconContainer>
              </>,
            ])
            : []
        }
      />
      {justificationData && (
        <JustificationModal
          setDayRequestData={setJustificationData}
          dayRequestData={justificationData}
        />
      )}
      {modalLimit && selectedEmployee && (
        <ModalLimitReservationPerEmployee
          isOpen={modalLimit}
          teamId={team.id}
          employee={selectedEmployee}
          handleCloseButton={() => {
            setModalLimit(false);
            setSelectedEmployee(null);
          }}
        />
      )}
    </div>
  );
};

export default EmployeeInfo;
