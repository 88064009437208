import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import Spinner from 'react-bootstrap/Spinner';
import icons from '../../assets/icons';
import colors from '../../colors';

interface ButtonProps {
  color?: 'primary' | 'default';
  label?: React.ReactElement | string;
  icon?: keyof typeof icons;
  isLoading?: boolean;
  tooltip?: String,
  classIcon?: String,
}

type allButtonProps = ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>;

const StyledButton = styled.button<{
  isLoading: boolean;
  colorBackGround: string;
  colorFont: string;
  icon?: keyof typeof icons;
  color?: string;
}>`
  padding: 0.9375rem 1.75rem;
  border-radius: 5px;
  background-color: ${(p) => (p.isLoading ? lighten(0.09, p.colorBackGround) : p.colorBackGround)} !important;
  color: ${(p) => (p.colorFont)};
  border: ${(p) => (p.color === 'default' ? `1px solid ${colors.gray20}` : 'none')};

  &:hover {
    background-color: ${(p) => (p.color === 'default' ? `${colors.white5}` : lighten(0.09, p.colorBackGround))} !important;
  }

  .disabled {
    pointer-events: none;
  }

  > img {
    margin-right: 6px;
  }

  :disabled{
    opacity: .7;
    cursor: not-allowed;
  }
  
  img {
    margin-right: 6px;
    max-width: 22px;
    max-height: 22px;
  }
  @media (max-width: 1784px) {
    ${(p) => p?.icon
      && css`
        span {
          display: none;
        }
      `}
    img {
      width: 0.95em;
    }
  }
  @media (max-width: 1366px){
    span{
      font-size: 14px;
    }
    
    img {
      width: auto;
    }
  }
`;

const Button: React.FC<allButtonProps> = ({
  icon,
  isLoading = false,
  label,
  color,
  tooltip,
  classIcon,
  ...rest
}) => (
  <StyledButton
    {...rest}
    isLoading={isLoading}
    colorBackGround={color === 'primary' ? `${colors.blue}` : `${colors.white}`}
    colorFont={color === 'primary' ? `${colors.white}` : `${colors.gray20}`}
    icon={icon}
    color={color}
    // @ts-ignore
    title={rest.disabled ? tooltip : null}
  >
    {isLoading && <Spinner color={color === 'primary' ? `${colors.white}` : `${colors.gray20}`} size="sm" animation="border" />}
    {(icon && !isLoading) && <img src={icons[icon]} className={typeof classIcon === 'string' ? classIcon : ''} alt={typeof label === 'string' ? label : ''} />}
    <span>
      { isLoading ? '' : label}
    </span>
  </StyledButton>
);

export default Button;
