import styled from 'styled-components';
import colors from '../../colors';

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: fixed;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.dark95};
  top: 0;
  left: 0;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  width: 30rem;
  height: 100%;;
  background-color: white;
  box-shadow: 0px 3px 6px ${colors.dark75};
  transition: 1s ease-in-out;
  box-sizing: border-box;
  
  .btn-announcent{
    background: ${colors.white5};
    border: solid 1px  ${colors.white8};
    font-size: 16px;
    padding: 14px 25px;
    text-align: left;
    
    img{
      width: 17px;
    }
    
    span{
      color:  ${colors.gray};
      display: initial;
      margin-left: 15px;
    }
  }
  
  .btn-close{
    position: absolute;
    right: 25px;
    top: 13px;
  }
  
  button.close-button{
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
    position: absolute;
    right: 35px;
    top: 17px;
  }
`;

export const ContainerOutside = styled.div`
  flex: 1;
  height: 100%;
`;

export const ContainerPlacementDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
`;

export const FormPlacementDetails = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

export const Header = styled.div`
  > h3{         
    font-size: 1.3rem;
  }
  > h5{
    color:  ${colors.blue};
  }
  > button{
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
  }
`;

export const Body = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 110px);
  position: relative;
  flex-direction: column;
  overflow-y: scroll;
`;

export const Footer = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    img{
      width: 17px;
    }
    span{
      display: block;
      font-size: 16px;
    }
  }
`;

export const LimitCount = styled.div<{bordered?: boolean}>`
  border: 1px solid ${colors.dark90};
  ${(props) => !props.bordered && 'border: none;'}
  border-radius: 5px;
  width: 48%;
  padding: 7.5px 10px;
  ${(props) => !props.bordered && 'padding: 7.5px 0px;'}
  height: 40px;
  input {
    border: none;
    margin: 0;
    width: 25px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  justify-content: flex-end;
  align-items: center;
  box-shadow: -1px -6px 9px -6px ${colors.dark75};
  -webkit-box-shadow: -1px -6px 9px -6px ${colors.dark75};
  -moz-box-shadow: -1px -6px 9px -6px ${colors.dark75};
`;

export const EmptyContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const EmptyText = styled.h5`
  text-align: center;
  font-weight: 500;
`;
