/* eslint-disable camelcase */
import Api from './api';

export default class PublishService {
  static async postSchedulePublish(
    data: {
      week_start: string;
      week_end: string;
      teams_id: string;
      placement_id: number;
      notification_type: string
    },
  ) {
    const response = await Api.post('shifts/publish', data);
    return response;
  }
}
