export const ADD_INFORMATION_USER: string = 'ADD_INFORMATION_USER';
export const CREATE_SPACE: string = 'CREATE_SPACE';
export const CREATE_POSITION: string = 'CREATE_POSITION';
export const CREATE_TEAM: string = 'CREATE_TEAM';
export const INVITE_PERSONS: string = 'INVITE_PERSONS';
export const CREATE_SHIFT: string = 'CREATE_SHIFT';
export const FINISHED: string = 'FINISHED';
export const ONBOARDING_MESSAGE: string = 'ONBOARDING_MESSAGE';
export const CREATE_POSITION_TRANSLATE = 'Onboarding.CreatePosition';
export const CREATE_SHIFT_TRANSLATE = 'Onboarding.CreateShift';
export const CREATE_SPACE_TRANSLATE = 'Onboarding.CreateSpace';
export const CREATE_TEAM_TRANSLATE = 'Onboarding.CreateTeam';
export const LAYOUT_PRIMARY_TRANSLATE = 'Onboarding.Layouts.Primary';
export const LAYOUT_ALTERNATIVE_TRANSLATE = 'Onboarding.Layouts.Alternative';
export const INFORMATION_USER_TRANSLATE = 'Onboarding.InformationUser';
export const INVITE_PERSONS_TRANSLATE = 'Onboarding.InvitePersons';
export const INPUT_LABEL_TRANSLATE = 'Onboarding.InputLabel';
export const EMPLOYEE_ROLE_COLLABORATOR = 'USER';
export const EMPLOYEE_ROLE_ORGANIZER = 'ORGANIZER';
export const MAP_LIMIT_MB = 1048576;
