import styled from 'styled-components';
import { ModalHeader, ModalBody, Modal } from 'reactstrap';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

export const TransformWrapperStyled = styled(TransformWrapper)`
  width: 100% !important;
  height: 100% !important;
`;
export const TransformComponentStyled = styled(TransformComponent)`
  width: 100% !important;
  height: 100% !important;
`;

export const ModalStyled = styled(Modal)`
  .react-transform-wrapper {
    height: 100% !important;
  }
`;

export const ModalBodyStyled = styled(ModalBody)`
  height: calc(100vh - 75px);
  max-width: 100%;
  box-sizing: border-box;
  background-color: #eff2f5;
  display: block;

  .react-transform-component {
    max-width: 100%;
    max-height: 100%;
  }
  .react-transform-element {
    max-width: 100%;
    max-height: 100%;
  }

  .react-transform-wrapper.transform-component-module_wrapper__1_Fgj {
    width: 100% !important;
  }
`;

export const ModalHeaderStyled = styled(ModalHeader)`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 75px;
  padding: 0px 30px;

  .modal-title {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ToolContainer = styled.div<{ expanded: boolean }>`
  display: flex;
  position: absolute;
  bottom: 30px;
  right: ${({ expanded }) => (expanded ? '554px' : '20px')};
  justify-content: flex-end;
  background-color: rgb(0, 0, 0, 0.3);
  border-radius: 12px;
  z-index: 1;
`;

export const ToolExpandContainer = styled.div<{ expanded: boolean }>`
  display: flex;
  position: absolute;
  top: 30px;
  right: ${({ expanded }) => (expanded ? '554px' : '100px')};
  justify-content: flex-end;
  background-color: rgb(0, 0, 0, 0.3);
  border-radius: 12px;
  z-index: 1;
  padding: 0px 5px;
`;

export const Title = styled.span`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-family: lato;
  color: var(--primary);
  text-align: left;
  font-size: 19px;

  svg {
    height: 24px;
    width: 24px;
    margin-right: 20px;
  }
`;

export const TitlePositions = styled.span`
  font-weight: bold;
  font-family: lato;
  color: var(--primary-gray);
  text-align: center;
  width: 100%;
  font-size: 17px;
  padding: 25px 0;
`;

export const Image = styled.img`
  object-fit: cover;
  width: 100%;
`;

export const ImageCanvas = styled.canvas`
  object-fit: cover;
  width: 100%;
`;

export const PositionsContent = styled.div<{ active: boolean }>`
  width: 460px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029;
  box-sizing: border-box !important;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  ${(props) => !props.active && 'transform: translate3d(460px, 0, 0);'}

  &:hover {
    ${ToolContainer} {
      -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
      ${(props) => !props.active && 'transform: translate3d(460px, 0, 0); !important'}
    }
  }

  ${ToolExpandContainer} {
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    ${(props) => !props.active && 'transform: translate3d(460px, 0, 0);'}
  }
`;

export const SideButtonToggler = styled.div<{ active: boolean }>`
  position: absolute;
  right: 480px;
  top: 20px;
  -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  ${(props) => !props.active && 'transform: translate3d(460px, 0, 0);'}

  img {
    -webkit-transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    ${(props) => (!props.active
    ? 'transform: rotate(90deg);'
    : 'transform: rotate(-90deg);')}
  }
`;

export const SeatItem = styled.div<{ selected: boolean }>`
  border-bottom-width: 1px;
  border-bottom-color: #ededed;
  border-bottom-style: solid;
  cursor: pointer;

  ${(props) => props.selected
    && `
  border: 1px #136AC3 solid;
  border-radius: 5px;
  input {
    border: 1px #136AC3 solid;
  }
`}
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 20px;
  height: calc(100vh - 250px);
  position: relative;
  overflow-y: scroll;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  padding: 15px 0;
  border-bottom-width: 1px;
  border-bottom-color: #ededed;
  border-bottom-style: solid;
  font: normal normal medium 15px/18px lato;
  color: #727789;

  > div {
    width: 100%;
    :nth-child(1) {
      width: 20%;
      padding-right: 2%;
    }
    :nth-child(2) {
      width: 40%;
      padding-right: 2%;
    }
    :last-child {
      width: 40%;
    }
  }
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font: normal normal normal 17px/29px lato;
  font-size: 0.8vmax;
  color: #3b4252;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: 100%;
    :nth-child(odd) {
      background-color: #fdfdfe;
    }

    line-height: 1;
    > div {
      width: 100%;
      :nth-child(1) {
        width: 20%;
        padding-right: 2%;
        padding-left: 2%;
      }
      :nth-child(2) {
        width: 40%;
        padding-right: 2%;
      }
      :last-child {
        width: 40%;
      }
    }
    padding: 2px 0;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 10px 10px;
  box-shadow: -1px -6px 9px -6px rgba(0, 0, 0, 0.11);
  -webkit-box-shadow: -1px -6px 9px -6px rgba(0, 0, 0, 0.11);
  -moz-box-shadow: -1px -6px 9px -6px rgba(0, 0, 0, 0.11);
`;
