import styled, { css } from 'styled-components';

interface Props {
    active?: boolean;
}

export const Container = styled.div`
    display: flex;
    min-height: 26px;
    flex-direction: row;
    width: max-content;
    justify-content: end;
    align-items: center;
    margin: 0 0 0 auto;
`;

export const Pills = styled.div`
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const Pill = css`
    width: 20px;
    height: 7px;
    margin-right: 5px;
    border-radius: 5px;
    float: left;
    background-color: #E0E0E0;
`;

export const PillWeak = styled.div<Props>`
    ${Pill}
    ${(props) => props.active && 'background-color:#DA3D21;'}
`;
export const PillMedium = styled.div<Props>`
    ${Pill}
    ${(props) => props.active && 'background-color:#FBC944;'}
`;
export const PillStrong = styled.div<Props>`
    ${Pill}
    ${(props) => props.active && 'background-color:#BFE05C;'}
`;
export const PillStronger = styled.div<Props>`
    ${Pill}
    ${(props) => props.active && 'background-color:#58AF41;'}
`;

export const PassLabel = styled.p`
    font-size: 14px;
    text-transform: capitalize;
    margin: 0;
`;
