const types = {
  empty: 1,
  announcement: 2,
  allocated: 3,
};

const states = {
  published: 1,
  notPublished: 2,
};

export default {
  types,
  states,
};
