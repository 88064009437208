import icons from 'src/assets/icons';

const AllAccess = ['Collaborator', 'Admin', 'Organizer'];
const OrganizerAndAdminAccess = ['Admin', 'Organizer'];
const AdminAccess = ['Admin'];
const CollaboratorAccess = ['Collaborator'];

const Links = [
  {
    path: '/panel',
    icons: icons.Panel,
    translation: 'MainNavBar.panel',
    access: AdminAccess,
    needPayment: true,
    id: 'panel-menu',
  },
  {
    path: '/dashboard',
    icons: icons.Panel,
    translation: 'MainNavBar.dashboard',
    access: CollaboratorAccess,
    needPayment: true,
    id: 'collaborator-menu',
  },
  {
    path: '/calendar',
    icons: icons.calendar,
    translation: 'MainNavBar.reservations',
    access: AllAccess,
    needPayment: true,
    id: 'calendar-menu',
  },
  {
    path: '/checkin',
    icons: icons.locationCheckin,
    translation: 'MainNavBar.checkIn',
    access: AllAccess,
    needPayment: true,
    id: 'checkIn-menu',
  },
  {
    path: '/teams',
    icons: icons.team,
    translation: 'MainNavBar.team',
    access: OrganizerAndAdminAccess,
    needPayment: true,
    id: 'teams-menu',
  },
  {
    path: '/spaces',
    icons: icons.manage,
    translation: 'MainNavBar.manage_spaces',
    access: AdminAccess,
    needPayment: true,
    id: 'spaces-menu',
  },
  {
    path: '/reports',
    icons: icons.reports,
    translation: 'MainNavBar.report',
    access: AdminAccess,
    needPayment: true,
    id: 'reports-menu',
  },
  {
    path: '/management',
    icons: icons.Gear,
    translation: 'MainNavBar.admin',
    access: AdminAccess,
    needPayment: true,
    id: 'management-menu',
  },
  {
    path: '/logout',
    icons: icons.logout,
    translation: 'MainNavBar.logout',
    access: AllAccess,
    needPayment: false,
    id: 'logout-menu',
  },
];

export default Links;
