import styled from 'styled-components';
import { AsyncPaginate } from 'react-select-async-paginate';

export const Component = styled.div`
  display: flex;
  flex: 1;
  height: 40px;
`;

export const Label = styled.span`
  font: normal normal medium 5px/5px lato;
  font-size: 13px !important;
  color: #727789;
  margin-bottom: 5px;
`;

export const DisabledButton = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  img {
    width: auto;
    height: 22px;
  }
`;

export const Selector = styled(AsyncPaginate)`
  flex: 1;
  margin-right: 10px;
  .select__control {
    overflow: auto;
    max-height: 85px;
  }
  > div {
    border-color: #cccccc !important;
    :nth-child(2) {
      box-shadow: none;
    }

    .select__indicators {
      align-items: flex-start;
    }
    .select__loading-indicator {
      span {
        height: 5px;
        width: 5px;
      }
    }
  }
`;
