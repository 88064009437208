import Team from 'src/contracts/models/Team';
import Employee from 'src/contracts/models/Employee';
import Placement from 'src/contracts/models/Placement';
import TeamRoles from 'src/contracts/constants/team.roles.constants';

export const employees2array = (data: any): Employee[] => {
  if (Array.isArray(data)) return data;
  return Object.values(data);
};

export const placements2array = (data: any): Placement[] => {
  if (Array.isArray(data)) return data;
  return Object.values(data).map((item: any) => ({
    maxVacancy: item.max_vacancy,
    teamHasPlacements: item.team_has_placements,
    locationName: item.location_name,
    ...item,
  }));
};

export const teams2array = (data: any): Team[] => {
  if (Array.isArray(data)) return data;
  return Object.values(data);
};

export const formatTeams = (data: any) => {
  const teams = teams2array(data);
  const formatedTeams = teams.map((item: any) => {
    const employees: Employee[] = employees2array(item.employees);
    const placements: Placement[] = placements2array(item.placements);
    const team: Team = {
      id: item.id,
      name: item.name,
      employees,
      placements,
    };
    return team;
  });
  return formatedTeams;
};

export const formatTeamsSidebar = (data: any) => {
  const teams = teams2array(data);
  const formatedTeams = teams.map((item: any) => {
    const employees: Employee[] = employees2array(item.employees);
    const team: Team = {
      ...item,
      employees,
    };
    return team;
  });
  return formatedTeams;
};

export const formatTeamsPermissons = (permissions: any) => {
  const teams = Object.values(permissions.teams);
  const header = Object.keys(permissions.all_roles_associated);
  const formatedTeams = teams.map((item: any) => {
    const team = {
      id: item.id,
      name: item.name,
      colab: item.roles && item.roles.includes(TeamRoles.COLABORATOR),
      orga: item.roles && item.roles.includes(TeamRoles.ORGANIZER),
    };
    return team;
  });
  const formatedHeader = {
    colab: header.includes(String(TeamRoles.COLABORATOR))
      && permissions.all_roles_associated[TeamRoles.COLABORATOR],
    orga: header.includes(String(TeamRoles.ORGANIZER))
      && permissions.all_roles_associated[TeamRoles.ORGANIZER],
  };
  return {
    teams: formatedTeams,
    header: formatedHeader,
  };
};

export const formatTeamsSimple = (data: any) => {
  const teams = Object.values(data);
  const formatedTeams = teams.map((item: any) => {
    const team: Team = {
      id: item.id,
      name: item.name,
      employeesCount: item.total_employees,
    };
    return team;
  });
  return formatedTeams;
};
