import styled, { css } from 'styled-components';

import colors from '../../colors';

const dragActive = css`
   border-color: ${colors.green};
`;

const dragReject = css`
   border-color: ${colors.red};
`;

interface ITypeMessageColor {
    type?: 'default' | 'error' | 'success';
}

type IDropZone = {
    isDragActive?: boolean;
    isDragReject?: boolean;
};

const messageColors : any = {
  default: css`
      color:  ${colors.gray90};
    `,
  success: css`
      color: ${colors.green};
    `,
  error: css`
      color: ${colors.red};
    `,
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DropZone = styled.div<IDropZone>`
    background-color: #F5F6F8;
    width: 100%;
    max-width: 400px;
    border: 1px dashed #B7C1C8;
    border-radius: 5px;
    height: 170px;
    margin-top: .5rem;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
 
    transition: height 0.2s ease;
 
    ${(props: any) => props.isDragActive && dragActive};
    ${(props: any) => props.isDragReject && dragReject};
`;

export const DropContainer = styled.div`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
 
    transition: height 0.2s ease;
`;

export const Label = styled.span`
    margin-bottom: 0;
    color: var(--secondary-grey);
    font-size: 0.875rem;
`;

export const LinkLabel = styled.span`
   cursor: pointer;
   padding: 10px 0;
   color: #727789;
   font-size: 0.875rem;
   text-align: center;
`;

export const LinkContainer = styled.div`
  max-width: 400px;
`;

export const UploadMessage = styled.p<ITypeMessageColor>`
    ${(props) => messageColors[props.type || 'default']};
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 10px;
    margin: 0;
    font-size: 0.875rem;
    svg {
        color: ${colors.blue};
        width: 0.875rem;
        height: 0.875rem;
        margin-right: 8px;
    }
`;

export const Row = styled.div`
    display: flex;
    align-items: center;
`;
