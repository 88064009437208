import api from './api';

export default class UserService {
  static DEFAULT_URL = 'two_factor';

  static async createToken() {
    const response = await api.get(`${this.DEFAULT_URL}`);
    return response;
  }

  static async deleteToken() {
    const response = await api.delete(`${this.DEFAULT_URL}`);
    return response;
  }

  static async verifyToken(token: string) {
    const data = {
      token,
    };
    const response = await api.post(`${this.DEFAULT_URL}`, data);
    return response;
  }
}
