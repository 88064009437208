import styled, { css } from 'styled-components';
import { animated } from 'react-spring';
import colors from '../../../colors';

interface ContainerProps {
  type?: 'info' | 'success' | 'error' | 'warning' | 'default';
  hasdescription: number;
}

const toastTypeVariations : any = {
  info: css`
    background: ${colors.blue30};
    color: ${colors.blue5};
  `,
  default: css`
    background: ${colors.green};
    color: ${colors.blue};
  `,
  success: css`
    background: ${colors.green};
    color: ${colors.white};
  `,
  error: css`
    background: ${colors.red20};
    color: ${colors.red70};
  `,
  warning: css`
    background: ${colors.orange};
    color: ${colors.orange80};
  `,
};

const Container = styled(animated.div)<ContainerProps>`
  z-index:99;
  width: 360px;
  position: relative;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  & + div {
    margin-top: 8px;
  }
  color: #fff;
  ${(props) => toastTypeVariations[props.type || 'info']}
  > svg {
    margin: 0 12px 0 0;
  }
  div {
    flex: 1;
    p {
      margin-bottom: 0;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }
  button {
    position: absolute;
    right: 16px;
    top: 0;
    height: 100%;
    opacity: .6;
    border: 0;
    background: transparent;
    color: inherit;
  }
  ${(props) => Number(!props.hasdescription)
    && css`
      align-items: center;
      svg {
        margin-top: 0;
      }
    `}
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonClose = styled.button`
  background: transparent;
  position: inherit !important;
`;

export { Container, TitleContainer, ButtonClose };
