/* eslint-disable import/no-duplicates */
import React, { useEffect, useMemo, useState } from 'react';
import {
  startOfWeek,
  endOfWeek,
  format,
  subWeeks,
  addDays,
  differenceInDays,
  addWeeks,
  startOfMonth,
} from 'date-fns';

import ptBR from 'date-fns/locale/pt-BR';
import DayPicker from 'react-day-picker';
import icons from 'src/assets/icons';
import { useTranslation } from 'react-i18next';
import calendarIcon from '../../assets/icons/calendar.svg';
import {
  CalendarContainer,
  Container,
  DateContainer,
  IconContainer,
} from './style';

interface WeekPeriodSelectorProps {
  startDate: Date;
  onChange: (day: Date) => void;
}

const formatDayString = (date: Date) => format(date, 'dd MMM yyyy', { locale: ptBR }).toUpperCase();

function WeeklyPeriodSelector({
  onChange,
  startDate,
}: WeekPeriodSelectorProps) {
  const { t } = useTranslation('components');
  const [viewCalendar, setviewCalendar] = useState(false);

  const startWeekDay = useMemo(
    () => startOfWeek(startDate, { weekStartsOn: 1 }),
    [startDate],
  );

  const endWeekDay = useMemo(() => endOfWeek(startDate, { weekStartsOn: 1 }), [
    startDate,
  ]);

  const formatStartWeekDay = useMemo(() => formatDayString(startWeekDay), [
    startWeekDay,
  ]);

  const formatEndWeekDay = useMemo(() => formatDayString(endWeekDay), [
    endWeekDay,
  ]);

  const handleNextWeekButtonClick = () => {
    onChange(addWeeks(startWeekDay, 1));
  };

  const handlePreviousWeekButtonClik = () => {
    onChange(subWeeks(startWeekDay, 1));
  };

  const handleButtonViewCalendar = () => setviewCalendar((previous) => !previous);
  const CloseSelectorCalendar = () => {
    setviewCalendar(false);
  };

  const selectedCalendarDays = useMemo(() => {
    const dateRange = [];
    const distance = differenceInDays(endWeekDay, startWeekDay);

    for (let i = 0; i <= distance; i += 1) {
      dateRange.push(addDays(startWeekDay, i));
    }

    return dateRange;
  }, [startWeekDay, endWeekDay]);

  const escFunction = (event: any) => {
    const escKey = 27;
    if (event.keyCode === escKey) {
      CloseSelectorCalendar();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  }, []);

  return (
    <Container id="date-selector">
      <div className="d-flex align-items-center">
        <IconContainer>
          <button type="button" onClick={handlePreviousWeekButtonClik}>
            <img
              src={icons.materialChevronLeft}
              alt={t('TeamSelector.alt_materialChevronLeft')}
            />
          </button>
        </IconContainer>
        <DateContainer className="d-flex justify-content-between mx-2">
          <button type="button" onClick={handleButtonViewCalendar}>
            <img src={calendarIcon} alt={t('TeamSelector.alt_calendar')} />
          </button>
          <div>
            {formatStartWeekDay}
            -
            {formatEndWeekDay}
          </div>
        </DateContainer>
        <IconContainer>
          <button type="button" onClick={handleNextWeekButtonClick}>
            <img
              src={icons.materialChevronRight}
              alt={t('TeamSelector.alt_materialChevronRight')}
            />
          </button>
        </IconContainer>
      </div>
      <CalendarContainer style={{ display: viewCalendar ? 'block' : 'none' }}>
        <DayPicker
          firstDayOfWeek={1}
          selectedDays={selectedCalendarDays}
          onDayClick={(day) => onChange(startOfWeek(day, { weekStartsOn: 1 }))}
          initialMonth={startDate}
          month={startOfMonth(startDate)}
          className="w-100"
        />
      </CalendarContainer>
    </Container>
  );
}

export default WeeklyPeriodSelector;
