import styled from 'styled-components';

interface Props {
  percentage: number;
}

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0 0 0;
  width: 100%;
`;

export const ProgressBarContainer = styled.div`
  width: 80%;
  height: 15px;
  border-radius: 2rem;
  position: relative;
  overflow: hidden;
  transition: all 0.5s;
  will-change: transform;
  box-shadow: 0 0 5px #00D4A9;
`;

export const Progress = styled.div<Props>`
  position: absolute;
  height: 100%;
  width: 100%;
  content: "";
  background-color: #00D4A9;
  top: 0;
  bottom: 0;
  left: ${(props) => (props.percentage)}%;
  border-radius: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: sans-serif;
`;

export const ProgressBarText = styled.span`
  display: none;
`;
