/* eslint-disable react/jsx-no-bind */
import React, {
  useRef, useState,
} from 'react';
import ButtonLoader from 'src/components/ButtonLoader';
import { useState as HookUseState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import icons from 'src/assets/icons';
import { BsArrowLeft } from 'react-icons/bs';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import Canvas from './Canvas';
import {
  Title,
  ToolContainer,
  ToolExpandContainer,
  ModalStyled,
  ModalBodyStyled,
  ModalHeaderStyled,
  PositionsContent,
  SideButtonToggler,
  TransformWrapperStyled,
  TransformComponentStyled,
  ButtonContainer,
} from './style';
import MapSelectorProvider from './Context';
import CustomNavBar from '../CustomNavBar';
import RangeInputSeatsSize from './RangeInputSeatsSize';
import { GetSeatsList } from './GetSeatsList';

export interface ModalMapSelectProps {
  isOpen?: boolean;
  title: string;
  link?: string | null;
  seats: any[];
  handleCloseButton?: () => void;
}
const ModalMapSelect: React.FC<ModalMapSelectProps> = ({
  isOpen,
  title,
  link,
  seats,
  handleCloseButton,
}) => {
  const zoomRef = useRef<ReactZoomPanPinchRef>(null);
  const { t } = useTranslation('pages');
  const isLoading = HookUseState<boolean>(false);
  const isDragMarker = HookUseState<boolean>(false);
  const cursorType = HookUseState<string>('default');
  const sideOpen = HookUseState<boolean>(true);
  const [initialTab, setInitialTab] = useState(0);

  const pages = [
    {
      label: t('AddLocation.tabs.AddPosition.tab_maps'),
      tab: <GetSeatsList seats={seats} />,
    },
    {
      label: t('AddLocation.tabs.AddPosition.tab_settings'),
      tab: <RangeInputSeatsSize />,
    },
  ];

  return (
    <ModalStyled
      isOpen={isOpen}
      onClosed={() => {
        isLoading.set(false);
        handleCloseButton?.();
        setInitialTab(0);
      }}
      className="modal-fullscreen"
    >
      <ModalHeaderStyled>
        <Title onClick={handleCloseButton}>
          <BsArrowLeft color="#1075CE" />
          {title}
        </Title>
        <ButtonLoader
          transparent
          withHover={false}
          icon={icons.close}
          iconSize={18}
          onClick={handleCloseButton}
        />
      </ModalHeaderStyled>
      <ModalBodyStyled>
        <TransformWrapperStyled
          velocityAnimation={{
            animationType: 'easeInCubic',
            disabled: true,
          }}
          alignmentAnimation={{
            disabled: true,
          }}
          centerOnInit
          limitToBounds
          centerZoomedOut
          minScale={0.2}
          maxScale={20}
          initialScale={0.8}
          wheel={{
            step: 0.2,
            excluded: [],
          }}
          doubleClick={{
            disabled: true,
          }}
          panning={{
            disabled: isDragMarker.get(),
            excluded: [],
          }}
          pinch={{
            step: 5,
          }}
          onPanningStart={() => cursorType.set('grabbing')}
          onPanningStop={() => cursorType.set('default')}
          ref={zoomRef}
        >
          {() => (
            <>
              <ToolContainer expanded={!!sideOpen.get()}>
                <ButtonLoader
                  transparent
                  withHover={false}
                  icon={icons.Minus}
                  style={{ marginRight: 10 }}
                  onClick={() => zoomRef.current?.zoomOut(1, 200, 'easeInCubic')}
                  iconSize={12}
                />
                <ButtonLoader
                  transparent
                  withHover={false}
                  disabled
                  icon={icons.ZoomIn}
                  style={{ marginRight: 10 }}
                  iconSize={16}
                />
                <ButtonLoader
                  transparent
                  withHover={false}
                  icon={icons.Plus}
                  style={{ marginRight: 10 }}
                  onClick={() => zoomRef.current?.zoomIn(1, 200, 'easeInCubic')}
                  iconSize={12}
                />
              </ToolContainer>
              <ToolExpandContainer expanded={!!sideOpen.get()}>
                {zoomRef.current?.state?.scale! !== 1 && (
                  <ButtonLoader
                    transparent
                    withHover={false}
                    icon={icons.FitScreen}
                    onClick={() => zoomRef.current?.centerView()}
                    iconSize={12}
                  />
                )}
              </ToolExpandContainer>
              <TransformComponentStyled>
                <div
                  style={{
                    cursor: cursorType.get(),
                    width: '100%',
                    height: '100%',
                    zIndex: -1,
                  }}
                >
                  {link && (
                    <Canvas
                      src={link}
                      seats={seats}
                      scale={zoomRef.current?.state?.scale || 1}
                      onDragMarker={(isActive: boolean) => isDragMarker.set(isActive)}
                    />
                  )}
                </div>
              </TransformComponentStyled>
            </>
          )}
        </TransformWrapperStyled>
        <SideButtonToggler active={sideOpen.get()}>
          <ButtonLoader
            color="#fff"
            bordered
            iconSize={26}
            icon={icons.CollapseArrow}
            onClick={() => {
              sideOpen.set((prev) => !prev);
            }}
          />
        </SideButtonToggler>

        <PositionsContent active={sideOpen.get()}>
          <CustomNavBar
            pages={pages}
            backColor="#fff"
            page={initialTab}
            onChange={(page) => setInitialTab(page)}
            justify="space-around"
          />

          <ButtonContainer>
            <ButtonLoader
              width="100%"
              label={t('AddLocation.tabs.AddPosition.finalize')}
              onClick={handleCloseButton}
            />
          </ButtonContainer>
        </PositionsContent>
      </ModalBodyStyled>
    </ModalStyled>
  );
};

export default (props: ModalMapSelectProps) => (
  <MapSelectorProvider>
    <ModalMapSelect {...props} />
  </MapSelectorProvider>
);
