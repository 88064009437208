/* eslint-disable react/no-this-in-sfc,no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable import/no-duplicates */
import {
  format,
  startOfMonth,
} from 'date-fns';
import { endOfWeek, startOfWeek } from 'date-fns/esm';
import { ptBR } from 'date-fns/locale';
import {
  MdDelete, MdDone, MdEdit,
} from 'react-icons/md';
import { mixpanelTrack } from 'src/services/MixPanelService';
import React, {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
import reactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import { AxiosError } from 'axios';
import Employee from 'src/contracts/models/Employee';
import { weekDaysReq, DayAdapter } from 'src/contracts/constants/days.constants';
import useDays from 'src/hooks/useDays';
import useMonths from 'src/hooks/useMonths';
import * as yup from 'yup';
import Placement from 'src/contracts/models/Placement';
import Shift from 'src/contracts/models/Shift';
import ConfirmModal from 'src/components/ConfirmModal';
import { useToast } from 'src/hooks/toast';
import { homeContext } from 'src/pages/Home/Context';
import RuleService from 'src/services/RuleService';
import ShiftService from 'src/services/ShiftService';
import { formatDateStringToDateString, formatStringToDate } from 'src/utils/date.functions';
import Button from '../Button';
import HourSelector from '../HourSelector';
import Input from '../Input';
import Select from '../Select';
import SelectWeekDay from '../SelectWeekDay';
import './arrowPosition.css';
import ModalReservationEditContextProvider, {
  useModalReservationEditContext,
} from './context';
import DaiLyFrequency from './DailyFrequency';
import WeeklyFrequency from './WeeklyFrequency';
import {
  Body,
  Container,
  DarkBg,
  ErrorContainer,
  Footer,
  Header,
  NavBar,
  ProfessionalContainer,
  AddButton,
  NotFilledButton,
} from './style';
import useAsync from '../../hooks/useAsync';
import icons from '../../assets/icons';
import AnnounceVocancy from './announceVacancy';
import PlacementService from '../../services/PlacementService';
import OpenDay from '../../contracts/models/OpenDay';
import { useAlert } from '../../hooks/useAlert';
import { DEFAULT_END_TIME } from '../../contracts/constants/times.constants';
import Loader from '../Loader';
import { getUser } from '../../services/auth';

const datetimeRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0]{2}/;

interface ModalShiftEditProps {
  modalData: ModalEdiShiftDataAttr
  calendarData: any;
  type?: 'edit' | 'create';
  placement?: Placement;
  onClose: Function;
}

export type ModalEdiShiftDataAttr = {
  employees?: Employee[];
  defaultEmployee?: Employee[];
  shiftData?: Shift | null;
  type?: 'edit' | 'create';
  dayName?: string;
  openDay?: OpenDay
  isEnableDeleteButton?: boolean
};
const ModalShiftEdit: React.FC<ModalShiftEditProps> = ({
  modalData,
  onClose,
  type,
  calendarData,
}) => {
  const user = getUser();
  const { defaultEmployee, shiftData } = modalData;
  const { placement } = useContext(homeContext);
  const daysTranslation = useTranslation('generic');
  const { addToast } = useToast();
  const {
    setPayload,
    payload,
    fieldErrors,
    setFieldErrors,
    clearPayload,
  } = useModalReservationEditContext();
  const {
    loadCalendar, week, selectedLocations, selectedTeams, sideBarTeams,
  } = useContext(
    homeContext,
  );
  const [defaultEmployeeState, setDefaultEmployeeState] = useState(
    defaultEmployee,
  );

  const formattedDefaultEmployee = useMemo(
    () => (Boolean(defaultEmployeeState?.length) as any)
      && defaultEmployeeState?.map(
        (employee) => ({
          value: `${employee?.id}_${employee?.teamId}`,
          label: `${employee?.name} (${employee?.teamName})`,
        } as any),
      ),
    [defaultEmployeeState],
  );
  const [exceptionErrorRule, setExceptionErrorRule] = useState('');
  const [weekInterval, setWeekInterval] = useState('1');
  const [lastDay, setLastDay] = useState<string | null>('1');
  const [selectedWeekDays, setSelectedWeekDays] = useState<number[]>([]);
  const [viewedMonth, setViewedMonth] = useState(startOfMonth(new Date()));
  const [loading, setLoading] = useState(false);
  const [isOpenAnnounceVocancy, setIsOpenAnnounceVocancy] = useState(false);
  const [employees, setEmployees] = useState<any[]>([]);

  const editedOrAddedEmployees = useMemo(() => payload.employees
    ?.filter((employee) => employee.added || employee.edited), [payload.employees]);

  const [employeeEditMode, setEmployeeEditMode] = useState<boolean[]>([]);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  const [loadingGetNotAllocatedEmployees, loadGetNotAllocatedEmployees] = useAsync(async () => {
    const payloadNotAllocatedEmployeesFromTeam = {
      placementId: placement?.id as number,
      teamsId: selectedTeams.map((t) => t.id).join(','),
      from: shiftData?.allocatedAt,
      to: shiftData?.allocatedAt,
    };

    const employeesTeams = () => {
      const allEmployees: { id: number, name: string, teamName:string, teamId:number }[] = [];

      Object.values(sideBarTeams).map((team: any) => {
        team.employees.map((employee: any) => {
          allEmployees.push({
            id: employee.id,
            name: employee.name,
            teamName: team.name,
            teamId: team.id,
          });

          return 0;
        });

        return 0;
      });

      return allEmployees;
    };
    setEmployees(employeesTeams());
    setEmployeeEditMode(Array.from({ length: employeesTeams().length }).fill(false) as boolean[]);
  }, [modalData, type]);

  const closeModal = () => {
    onClose({});
    clearPayload?.();
    setSelectedWeekDays([]);
    setWeekInterval('');
    setViewedMonth(new Date());
    setIsOpenConfirm(false);
    setFieldErrors(null);
    setLoading(false);
    setIsOpenAnnounceVocancy(false);
    clearPayload?.();
  };

  const [loadingShiftDetails, loadShiftDetailsFunction] = useAsync(async () => {
    if (!modalData || type !== 'edit') return;

    await ShiftService.getShiftDetailsAdmin(shiftData?.id as number).then((resp) => {
      const shiftDetailEmployees = resp.data.all_related_employees.map(
        ({
          id, name, period, team_id: teamId, team_name: teamName,
        }: any) => ({
          shiftEmployeesId: period.id,
          id: `${id}_${teamId}`,
          name,
          teamName,
          start: format(new Date(period.start), 'HH:mm:ss'),
          end: format(new Date(period.end), 'HH:mm:ss'),
        }),
      );

      setPayload((prev: any) => ({
        ...prev,
        employees: shiftDetailEmployees,
      }));
    }).catch(async (error) => {
      if (error.response.data.errorCode === 'SHIFT_HAS_BEEN_DELETED') {
        addToast({
          title: error.response.data.message,
          type: 'error',
        });
        closeModal();
        await loadCalendar?.();
      }
    });
  }, [modalData, type]);

  const { t } = useTranslation('components');
  const { openAlert } = useAlert();

  const ruleTypes = useMemo(() => [
    {
      label: t(
        'ModalShiftEdit.rule_types.not_repeat',
      ),
      value: 'not_repeat',
    },
    {
      label: t('ModalShiftEdit.rule_types.weekly'),
      value: 'weekly',
    },
  ], []);

  const closeModalAndLoadCalendarIfNeeded = async () => {
    if (payload.hasChanged) {
      try {
        await loadCalendar?.();
        closeModal();
      } catch (error) {
        addToast({
          type: 'error',
          title: t('ModalShiftEdit.success'),
          description: t('ModalShiftEdit.load_calendar_error'),
        });
      }
    } else {
      closeModal();
    }
  };

  const employeeOptions = useMemo(
    () => Object.values(employees).map((employee) => ({
      label: `${employee.name} (${employee.teamName})`,
      value: `${employee.id}_${employee.teamId}`,
    })),
    [employees],
  );

  const formatErrorConflictsRules = (error: string) => {
    const errorArr = error.split(':');
    return {
      title: errorArr[0],
      conflicts: errorArr[1].split(',').map((conflict) => conflict.trim()),
    };
  };

  const handleCreateButtonClick = async () => {
    const timeRegex = /[0-2][0-9]:[0-5][0-9]:[0-9]{2}/;
    const employeesSchema = yup.array().of(
      yup.object().shape({
        id: yup
          .string()
          .matches(/[0-9]+_[0-9]+/, {
            message: t('ModalShiftEdit.validations.required_employee'),
          })
          .required(t('ModalShiftEdit.validations.required_employee'))
          .typeError(t('ModalShiftEdit.validations.required_employee')),
        start: yup
          .string()
          .required(t('ModalShiftEdit.validations.required_start_time'))
          .matches(
            timeRegex,
            t('ModalShiftEdit.validations.required_start_time'),
          )
          .test('match', t('ModalShiftEdit.validations.start_must_be_before_after'),
            function (this: any, end: any) {
              return end && end < this.parent.end;
            } as any),
        end: yup
          .string()
          .required(t('ModalShiftEdit.validations.required_end_time'))
          .matches(
            timeRegex,
            t('ModalShiftEdit.validations.required_end_time'),
          ),
      }),
    );

    const notFractionSchema = yup.object().shape({
      employeeId: yup
        .string()
        .matches(/[0-9]+_[0-9]+/, {
          message: t('ModalShiftEdit.validations.required_employee'),
        })
        .required(t('ModalShiftEdit.validations.required_employee'))
        .typeError(t('ModalShiftEdit.validations.required_employee')),
    });

    const fractionalSchema = yup.object().shape({
      employees: employeesSchema
        .required(t('ModalShiftEdit.validations.required_employee'))
        .typeError(t('ModalShiftEdit.validations.required_employee')),
    });

    const commonSchema = yup
      .object()
      .shape({
        lastDay: yup
          .string()
          .nullable()
          .when('duration', {
            is: 'until',
            then: yup
              .string()
              .required(t('ModalShiftEdit.validations.required_datetime_until'))
              .typeError(
                t('ModalShiftEdit.validations.required_datetime_until'),
              ),
          }),
        count: yup
          .number()
          .nullable()
          .when('duration', {
            is: 'count',
            then: yup
              .number()
              .required(t('ModalShiftEdit.validations.required_count'))
              .typeError(t('ModalShiftEdit.validations.required_count')),
          }),
      })
      .concat(
        placement?.isAllowFractionalShift
          ? fractionalSchema
          : notFractionSchema,
      );

    try {
      const validatedPayload = await commonSchema.validate({ ...payload, lastDay }, {
        abortEarly: false,
      });
      const {
        employeeId: employeeIdWithTeamId,
        duration,
        frequency,
        start,
        datetimeStart,
        end,
        journeys,
        count,
      } = validatedPayload;
      setLoading(true);
      if (frequency !== 'not_repeat') {
        try {
          const employeesData = placement?.isAllowFractionalShift
            ? validatedPayload.employees.map((employee) => {
              const [employeeId, teamId] = employee.id.split('_');

              return {
                ...employee,
                id: +employeeId,
                team_id: teamId,
                start: employee.start || modalData.openDay?.timeStart,
                end: employee.end || modalData.openDay?.timeEnd,
              };
            })
            : ([
              {
                id: +employeeIdWithTeamId.split('_')[0],
                start: modalData.openDay?.timeStart,
                end: modalData.openDay?.timeStart,
                team_id: +employeeIdWithTeamId.split('_')[1],
              },
            ] as any);
          const formatedJourneys = journeys as number[];
          const filtered = formatedJourneys.map((j) => weekDaysReq[j]).filter((j) => typeof j === 'string');
          const payloadRule = {
            placement_id: placement?.id as number,
            seat_id: shiftData?.seatId ? +shiftData?.seatId : null,
            duration,
            frequency,
            create_with_exceptions: payload.createWithExceptions,
            datetime_start: datetimeStart,
            datetime_until: duration === 'forever' ? null : lastDay,
            interval: 1,
            journeys: [filtered],
            count,
            employees: employeesData,
          };
          const response = await RuleService.createRule(payloadRule);
          if (response.status === 201) {
            addToast({
              type: 'success',
              title: t('ModalShiftEdit.success'),
              description: t('ModalShiftEdit.default_success_message'),
            });
            await loadCalendar?.();
            closeModal();
          } else {
            addToast({
              type: 'error',
              title: 'Erro!',
              description: t('ModalShiftEdit.default_error_message'),
            });
          }
        } catch (error) {
          if ((error as AxiosError).response?.status === 400) {
            const response = (error as AxiosError).response?.data;
            if (response.type === 'DATE_CONFLICT') {
              const formatedError = formatErrorConflictsRules(response.message);
              const formatedDates = formatedError.conflicts.map((date) => formatDateStringToDateString(date, 'yyyy-MM-dd', 'dd/MM/yyyy'));
              setExceptionErrorRule([formatedError.title, formatedDates.join(', ')].join(': '));
            }

            if (response.type === 'WEEKDAY_CONFLICT') {
              const formatedError = formatErrorConflictsRules(response.message);
              const formatedDates = formatedError.conflicts.map((day) => daysTranslation.t(`days.${String(DayAdapter[day]).toLowerCase()}`));
              setFieldErrors([
                [formatedError.title, formatedDates.join(', ')].join(': '),
              ]);
            }
          }
          addToast({
            type: 'error',
            title: t('ModalShiftEdit.error'),
            description: t('ModalShiftEdit.default_error_message'),
          });
        } finally {
          setLoading(false);
        }
      } else {
        try {
          let response;
          if (placement?.isAllowFractionalShift) {
            response = await ShiftService.saveShiftWithManyEmployees({
              placementId: placement.id as number,
              allocatedAt: shiftData?.allocatedAt as string,
              employees: validatedPayload.employees.map((employee) => ({
                ...employee,
                id: employee.id.split('_')[0],
                start: `${shiftData?.allocatedAt} ${employee.start || modalData.openDay?.timeStart}`,
                end: `${shiftData?.allocatedAt} ${employee.end || modalData.openDay?.timeEnd}`,
                team_id: employee.id.split('_')[1],
              })) as any,
              seatId: shiftData?.seatId || null,
            });
          } else {
            const employeeId = employeeIdWithTeamId.split('_')[0];
            const teamId = employeeIdWithTeamId.split('_')[1];
            response = await ShiftService.saveShift({
              placement_id: placement?.id as number,
              allocated_at: shiftData?.allocatedAt as string,
              employee_id: +employeeId,
              start: `${shiftData?.allocatedAt} ${start || modalData.openDay?.timeStart}`,
              end: `${shiftData?.allocatedAt} ${end || modalData.openDay?.timeEnd}`,
              seat_id: shiftData?.seatId || null,
              team_id: +teamId,
            });
          }
          if (response.status === 201) {
            mixpanelTrack('shift@create', {
              allocatedAt: shiftData?.allocatedAt ? new Date(shiftData?.allocatedAt) : null,
            });
            addToast({
              type: 'success',
              title: t('ModalShiftEdit.success'),
              description: t('ModalShiftEdit.save_shift_success'),
            });
            await loadCalendar?.();
            closeModal();
          } else {
            addToast({
              type: 'error',
              title: 'Erro!',
              description: t('ModalShiftEdit.load_calendar_error'),
            });
          }
        } catch (error) {
          if ((error as AxiosError)?.response?.data?.message) {
            if ((error as AxiosError)?.response?.data?.message) {
              setFieldErrors([(error as AxiosError).response?.data.message]);
            } else {
              addToast({
                type: 'error',
                description: (error as AxiosError)?.response?.data?.message,
              });
            }
          }
        } finally {
          setLoading(false);
        }
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setFieldErrors(error.errors);
      }
    }
  };

  const [ruleLoading] = useAsync(async () => {
    if (!shiftData?.ruleId || shiftData?.id) {
      return;
    }
    const { data } = await RuleService.getRuleById(shiftData.ruleId);
    setSelectedWeekDays(data.journeys as any);
    setPayload((previousState: any) => ({
      ...previousState,
      frequency: data.frequency,
      duration: data.duration,
      count: data.count,
      journeys: data.journeys,
      datetimeUntil: data.datetimeUntil,
      datetimeUntilUnformated: formatStringToDate(data.datetimeUntil as string, 'yyyy-MM-dd'),
      employees: data.employees,
    }));
  }, [shiftData]);

  const handleExcludeShift = async () => {
    const onConfirm = async () => {
      try {
        const response = await ShiftService.deleteShiftWithAllEmployees(
          shiftData?.id as number,
        );

        if (response.status === 200) {
          addToast({
            type: 'success',
            title: t('ModalShiftEdit.success'),
            description: t('ModalShiftEdit.delete_shift_succes'),
          });
          await loadCalendar?.();
          closeModal();
        } else {
          addToast({
            type: 'error',
            title: t('ModalShiftEdit.error'),
            description: t('ModalShiftEdit.delete_shift_error'),
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: t('ModalShiftEdit.error'),
          description: t('ModalShiftEdit.delete_shift_error'),
        });
      }
    };

    openAlert({
      handleConfirm: onConfirm,
      title: t('ModalShiftEdit.delete_shift_modal_title'),
      message: t('ModalShiftEdit.delete_shift_modal_message'),
    });
    setIsOpenConfirm(true);
  };

  const handleRemoveDayRule = async () => {
    const onConfirm = async () => {
      try {
        const response = await RuleService.removeDay(
          {
            rule_id: shiftData?.ruleId!,
            date: shiftData?.allocatedAt!,
          },
        );

        if (response.status === 201) {
          addToast({
            type: 'success',
            title: t('ModalShiftEdit.success'),
            description: t('ModalShiftEdit.remove_day_rule_succes'),
          });
          await loadCalendar?.();
          closeModal();
        } else {
          addToast({
            type: 'error',
            title: t('ModalShiftEdit.error'),
            description: t('ModalShiftEdit.remove_day_rule_error'),
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: t('ModalShiftEdit.error'),
          description: t('ModalShiftEdit.delete_shift_error'),
        });
      }
    };

    openAlert({
      handleConfirm: onConfirm,
      title: t('ModalShiftEdit.remove_day_rule_modal_title'),
      message: t('ModalShiftEdit.remove_day_rule_modal_message'),
    });
    setIsOpenConfirm(true);
  };

  const isEditingSomeEmployee = useMemo(() => employeeEditMode.includes(true), [employeeEditMode]);

  const isEditingRule = useMemo(() => {
    if (payload.frequency !== 'not_repeat' && type === 'edit') {
      return true;
    }
    return false;
  }, [payload, type]);

  const updateRule = async () => {
    const payloadRule = {
      rule_id: shiftData?.ruleId,
      duration: payload.duration,
      count: payload.duration === 'count' ? payload.count : null,
      datetime_until: payload.duration !== 'forever' ? lastDay : null,
    };
    try {
      const response = await RuleService.editRule(payloadRule);
      if (response.status === 200) {
        addToast({
          type: 'success',
          title: t('ModalShiftEdit.success'),
          description: t('ModalShiftEdit.default_update_message'),
        });
      } else {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: t('ModalShiftEdit.default_error_update_message'),
        });
      }
    } catch (error) {
      if ((error as AxiosError).response?.status === 400) {
        const response = (error as AxiosError).response?.data;
        if (response.type === 'DATE_CONFLICT') {
          const formatedError = formatErrorConflictsRules(response.message);
          const formatedDates = formatedError.conflicts.map((date) => formatDateStringToDateString(date, 'yyyy-MM-dd', 'dd/MM/yyyy'));
          setExceptionErrorRule([formatedError.title, formatedDates.join(', ')].join(': '));
        }

        if (response.type === 'WEEKDAY_CONFLICT') {
          const formatedError = formatErrorConflictsRules(response.message);
          const formatedDates = formatedError.conflicts.map((day) => daysTranslation.t(`days.${String(DayAdapter[day]).toLowerCase()}`));
          setFieldErrors([
            [formatedError.title, formatedDates.join(', ')].join(': '),
          ]);
        }
      }
      addToast({
        type: 'error',
        title: t('ModalShiftEdit.error'),
        description: t('ModalShiftEdit.default_error_update_message'),
      });
    }
  };

  const createShiftExceptionRule = async (employeesEditeds: any[]) => {
    try {
      let response;
      if (placement?.isAllowFractionalShift) {
        response = await ShiftService.saveShiftWithManyEmployees({
          placementId: placement.id as number,
          allocatedAt: shiftData?.allocatedAt as string,
          ruleId: shiftData?.ruleId,
          employees: employeesEditeds.map((employee) => ({
            ...employee,
            id: employee.id,
            team_id: employee.teamId,
            start: `${employee.start}`,
            end: `${employee.end}`,
          })) as any,
          seatId: shiftData?.seatId || null,
        });
      } else {
        response = await ShiftService.saveShift({
          rule_id: shiftData?.ruleId,
          placement_id: placement?.id as number,
          allocated_at: shiftData?.allocatedAt as string,
          employee_id: employeesEditeds[0].id,
          team_id: employeesEditeds[0].teamId,
          start: `${employeesEditeds[0].start}`,
          end: `${employeesEditeds[0].end}`,
          seat_id: shiftData?.seatId || null,
        });
      }
      if (response.status === 201) {
        addToast({
          type: 'success',
          title: t('ModalShiftEdit.success'),
          description: t('ModalShiftEdit.save_shift_success'),
        });
      } else {
        addToast({
          type: 'error',
          title: 'Erro!',
          description: t('ModalShiftEdit.load_calendar_error'),
        });
      }
    } catch (error) {
      if ((error as AxiosError).response?.data?.message) {
        if ((error as AxiosError).response?.data?.message) {
          setFieldErrors([(error as AxiosError).response?.data.message]);
        } else {
          addToast({
            type: 'error',
            description: (error as AxiosError).response?.data?.message,
          });
        }
      }
    }
  };

  const handleSaveShiftClick = async () => {
    const editedEmployees = editedOrAddedEmployees
      ?.map((employee) => {
        const splittedEmployeeId = typeof employee.id === 'number' ? [employee.id, employee.teamId] : employee.id?.split('_');

        return {
          shiftEmployeesId: employee.shiftEmployeesId,
          id: Number(splittedEmployeeId?.[0]),
          teamId: Number(splittedEmployeeId?.[1]),
          start: `${shiftData?.allocatedAt} ${employee.start}`,
          end: `${shiftData?.allocatedAt} ${employee.end}`,
          action: employee.added ? 'ADD' : 'EDIT',
        };
      });

    if (isEditingRule) {
      setLoading(true);
      await updateRule();
      if (editedEmployees?.length! > 0) {
        await createShiftExceptionRule(editedEmployees!);
      }
      setLoading(false);
      await loadCalendar?.();
      closeModal();
      return;
    }

    const saveValidator = yup.array().of(yup.object().shape({
      action: yup.string()
        .oneOf(['EDIT', 'ADD'])
        .required(),
      start: yup.string()
        .required()
        .matches(datetimeRegex, { message: t('ModalShiftEdit.validations.required_start_time') })
        .test('match', t('ModalShiftEdit.validations.start_must_be_before_after'),
          function (this: any, end: any) {
            return end && end < this.parent.end;
          } as any),
      end: yup.string()
        .required()
        .matches(datetimeRegex, { message: t('ModalShiftEdit.validations.required_end_time') }),
      id: yup.number()
        .required()
        .typeError(t('ModalShiftEdit.validations.required_employee')),
    }));
    try {
      setLoading(true);
      const validatedPayload = await saveValidator.validate(editedEmployees, { abortEarly: false });
      const response = await ShiftService.editShiftEmployees(
        shiftData?.id as number,
        { employees: validatedPayload as any },
      );

      if (response.status === 200) {
        addToast({
          type: 'success',
          description: t('ModalShiftEdit.shift_updated_success'),
        });

        setFieldErrors([]);

        await loadShiftDetailsFunction();
        await loadGetNotAllocatedEmployees;
      }
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setFieldErrors(error.errors);
        return;
      }
      if ((error as AxiosError).response?.data?.message) {
        setFieldErrors([(error as AxiosError).response?.data.message]);
      } else {
        addToast({
          description: t('ModalShiftEdit.shift_updated_error'),
          type: 'error',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const getPageTitle = () => t(
    `ModalShiftEdit.${
      type === 'create' ? 'shift_create' : 'reservation_edit'
    }`,
  );

  const errorsArray = useMemo(() => {
    const settedErrors = new Set(fieldErrors);
    return Array.from(settedErrors);
  }, [fieldErrors]);

  const handleExcludeEmployeeFromShift = (employee: any) => () => {
    const onConfirm = async () => {
      try {
        const response = await ShiftService.removeEmployeeFromShift(
          shiftData?.id as number,
          { shiftEmployeeId: employee.shiftEmployeesId },
        );

        if (response.status === 200) {
          addToast({
            type: 'success',
            title: 'success',
            description: t('ModalShiftEdit.deallocate_employee_success'),
          });
          setPayload((previousState: any) => ({
            ...previousState,
            employees: [...previousState.employees.filter(
              (e: any) => e.id !== employee.id,
            )],
            hasChanged: true,
          }));
          await loadGetNotAllocatedEmployees();
        } else {
          addToast({
            type: 'error',
            title: 'Erro',
            description: t('ModalShiftEdit.deallocate_employee_error'),
          });
        }
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: t('ModalShiftEdit.deallocate_employee_error'),
        });
      }
    };
    openAlert({
      title: t('ModalShiftEdit.delete_shift_employee_modal_title'),
      handleConfirm: onConfirm,
      message: t('ModalShiftEdit.delete_shift_employee_modal_message'),
    });
  };

  const handleEditEmployee = (employeeIndex: number) => () => {
    setEmployeeEditMode((previousState) => {
      const copyPreviousState = [...previousState];
      copyPreviousState[employeeIndex] = true;

      return copyPreviousState;
    });
  };

  const handleAnnounceVacancy = () => {
    setIsOpenAnnounceVocancy(true);
  };

  const onClickBackButton = () => {
    setIsOpenAnnounceVocancy(false);
  };

  const handleExcludeAddedEmployee = (employeeIndex: number) => () => {
    setPayload(
      (previousState: any) => ({
        ...previousState,
        employees: previousState.employees.filter(
          (_: any, i: any) => i !== employeeIndex,
        ),
      }),
    );

    setDefaultEmployeeState(
      (previousState) => previousState?.filter(
        (_, i) => i !== employeeIndex,
      ),
    );
  };

  const onEmployeeSelectChange = (index: number) => (option: any) => {
    setPayload((previousState: any) => ({
      ...previousState,
      employees: previousState.employees.map(
        (p: any, i: number) => (i === index
          ? { ...p, id: option.value }
          : p),
      ),
    }));
  };

  const onInputStartChange = (index: number) => (event: any) => {
    setPayload((previousState: any) => ({
      ...previousState,
      employees: previousState.employees.map(
        (p: any, i: number) => (i === index
          ? { ...p, start: `${event.target.value}:00`, edited: true }
          : p),
      ),
      hanChanged: true,
    }));
  };

  const onInputEndChange = (index: number) => (event: any) => {
    const { value } = event.target;
    setPayload((previousState: any) => ({
      ...previousState,
      employees: previousState.employees.map(
        (p: any, i: number) => (i === index
          ? { ...p, end: value === '__:__' ? null : `${value}:00`, edited: true }
          : p),
      ),
      hasChanged: true,
    }));
  };

  const onAddEmployeeButtonClick = () => {
    mixpanelTrack('shift@add-fractional', null);
    setPayload((previousState: any) => ({
      ...previousState,
      employees: [
        ...previousState.employees,
        {
          id: null,
          start: modalData.openDay?.timeStart,
          end: modalData.openDay?.timeEnd || DEFAULT_END_TIME,
          added: true,
          key: String(Math.random()),
        },
      ],
      hasChanged: true,
    }));
  };

  const onSingleEmployeeSelectChange = (option: any) => {
    setPayload((previousState: any) => ({
      ...previousState,
      employeeId: option.value,
      hasChanged: true,
    }));
  };

  const handleSaveEditEmployee = (employeeIndex: number) => () => {
    setEmployeeEditMode((previousState) => {
      const copyPreviousState = [...previousState];
      copyPreviousState[employeeIndex] = false;

      return copyPreviousState;
    });
  };

  useEffect(() => {
    const keyDownEvent = (evt: any) => {
      if (evt.key === 'Escape' && document.getElementById('darkBG')) {
        closeModalAndLoadCalendarIfNeeded();
        setIsOpenAnnounceVocancy(false);
      }

      if (evt.key === 'Enter') {
        if (type === 'edit') {
          handleSaveShiftClick();
        } else {
          handleCreateButtonClick();
        }
      }
    };

    const closeOnBlur = (evt: any) => {
      if (evt.target?.id === 'darkBG') {
        closeModalAndLoadCalendarIfNeeded();
      }
    };
    document.addEventListener('keydown', keyDownEvent);
    document.addEventListener('click', closeOnBlur);

    return () => {
      document.removeEventListener('keydown', keyDownEvent);
      document.removeEventListener('click', closeOnBlur);
    };
  });

  function isLoadings() {
    return loadingGetNotAllocatedEmployees
    || loadingShiftDetails
    || ruleLoading;
  }

  const handleCloseButtonExceptionModal = useCallback(
    () => {
      setExceptionErrorRule('');
    },
    [],
  );

  const handleConfirmButtonExceptionModal = useCallback(
    () => {
      setExceptionErrorRule('');
      setPayload((prev: any) => ({ ...prev, createWithExceptions: true }));
    },
    [],
  );

  const checkIfItsMyReservation = (id: string | null) => {
    if (modalData.isEnableDeleteButton) return true;
    if (!id) return false;
    const index = id.indexOf('_');
    return Number(user?.employee.id) === Number(id.slice(0, index));
  };

  return Object.keys(modalData).length ? (
    reactDOM.createPortal(
      <DarkBg id="darkBG" isOpen className="fade-in">
        <Container isOpen className="roll-out fade-in">
          {isLoadings()
            ? <div className="d-flex w-100 align-items-center justify-content-center"><Loader /></div>
            : (
              <>
                {isOpenAnnounceVocancy ? (
                  <AnnounceVocancy
                    onClickBackButton={onClickBackButton}
                    selectedLocationsDefault={[selectedLocations!]}
                    period={{
                      from: week,
                      to: endOfWeek(week, { weekStartsOn: 1 }),
                    }}
                    handleCloseButton={closeModalAndLoadCalendarIfNeeded}
                    modalData={modalData}
                  />
                ) : (
                  <>
                    <Header className="row align-items-baseline">
                      <div className="col-11 header-title">{t('ModalShiftEdit.title')}</div>
                      <button
                        className="col-1"
                        type="button"
                        onClick={closeModalAndLoadCalendarIfNeeded}
                      >
                        <img
                          src={icons.close}
                          className="logo"
                          alt=""
                          title={t('ModalShiftEdit.announcent.close')}
                        />
                      </button>
                    </Header>
                    <Body className={`row ${payload.employees
                      && payload.employees?.length > 7 && 'overflow-body'}`}
                    >
                      <div className="container">
                        {placement?.isAllowFractionalShift ? (
                          <div>
                            {payload.employees?.map((employee, index) => (
                              <ProfessionalContainer className="row" key={employee.key}>
                                <div className="col-6" key={employee.key}>
                                  {employee.added || type === 'create' ? (
                                    <Select
                                      key={employee.key}
                                      required
                                      label={t('ModalShiftEdit.professional')}
                                      options={employeeOptions}
                                      defaultValue={(
                                          (index === 0 && defaultEmployeeState)
                                            ? (formattedDefaultEmployee?.[
                                              index as any
                                            ] as any)
                                            : null
                                        )}
                                      onChange={onEmployeeSelectChange(index)}
                                    />
                                  ) : (
                                    <Input
                                      label={t('ModalShiftEdit.professional')}
                                      disabled
                                      value={`${employee?.name} (${employee?.teamName})`}
                                    />
                                  )}
                                </div>
                                <div className="col-6">
                                  {placement?.isAllowFractionalShift ? (
                                    <div className="d-flex align-items-center">
                                      <div className="pe-3">
                                        <Input
                                          style={{
                                            width: '71.6px',
                                          }}
                                          label="Inicio *"
                                          styledLabel={{ display: 'block', marginBottom: '.5rem' }}
                                          disabled={!employee?.added && type === 'edit' && !employeeEditMode[index]}
                                          mask="99:99"
                                          placeholder="08:00"
                                          defaultValue={employee.start}
                                          onChange={onInputStartChange(index)}
                                        />
                                      </div>
                                      <div>
                                        <Input
                                          style={{
                                            width: '71.6px',
                                          }}
                                          label="Fim"
                                          styledLabel={{ display: 'block', marginBottom: '.5rem' }}
                                          disabled={!employee?.added && type === 'edit' && !employeeEditMode[index]}
                                          mask="99:99"
                                          placeholder="12:00"
                                          defaultValue={employee.end as string}
                                          onChange={onInputEndChange(index)}
                                        />
                                      </div>
                                      {(user?.isAdmin
                                        || checkIfItsMyReservation(employee.id)) && (
                                        <div className="d-flex justify-content-between pt-3">
                                          { type === 'edit' && !employee.added && (
                                            <>
                                              {employeeEditMode[index]
                                                ? (
                                                  <NotFilledButton
                                                    className="ms-3"
                                                    onClick={handleSaveEditEmployee(index)}
                                                  >
                                                    <MdDone size={18} />
                                                  </NotFilledButton>
                                                )
                                                : (
                                                  <NotFilledButton
                                                    className="ms-3"
                                                    onClick={handleEditEmployee(
                                                      index,
                                                    )}
                                                  >
                                                    <MdEdit size={18} />
                                                  </NotFilledButton>
                                                )}
                                            </>
                                          )}
                                          { payload.employees?.length !== 1 && (
                                            <NotFilledButton
                                              className="ms-3"
                                              disabled={employeeEditMode[index]}
                                              onClick={employee.added || type === 'create' ? handleExcludeAddedEmployee(index) : handleExcludeEmployeeFromShift(
                                                employee,
                                              )}
                                            >
                                              <MdDelete size={18} />
                                            </NotFilledButton>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                  ) : (
                                    <Input
                                      label={t('ModalShiftEdit.schedule')}
                                      styledLabel={{ display: 'block', marginBottom: '.5rem' }}
                                      disabled
                                      className="w-100"
                                      value={
                                    t(
                                      'ModalShiftEdit.schedule_types.all_day',
                                    ) as string
                                  }
                                    />
                                  )}
                                </div>
                              </ProfessionalContainer>
                            ))}
                            <AddButton
                              className="mt-3"
                              onClick={onAddEmployeeButtonClick}
                            >
                              {!user?.onlyCollaborator ? 'Adicionar profissional' : 'Novo Horário'}
                            </AddButton>
                          </div>
                        ) : (
                          <ProfessionalContainer className="row">
                            <div className="col-6">
                              {type === 'create' ? (
                                <Select
                                  required
                                  label={t('ModalShiftEdit.professional')}
                                  options={employeeOptions}
                                  defaultValue={
                                      defaultEmployeeState
                                        ? formattedDefaultEmployee?.[0]
                                        : null
                                    }
                                  onChange={onSingleEmployeeSelectChange}
                                />
                              ) : (
                                <Input
                                  label={t('ModalShiftEdit.professional')}
                                  disabled
                                  style={{ padding: '0 5px' }}
                                  value={`${payload.employees?.[0].name} (${payload.employees?.[0].teamName})`}
                                />
                              )}
                            </div>
                            <div className="col-6">
                              <Input
                                label={t('ModalShiftEdit.schedule')}
                                styledLabel={{ display: 'block', marginBottom: '.5rem' }}
                                disabled
                                className="w-100"
                                style={{ padding: '0 5px' }}
                                value={
                              t(
                                'ModalShiftEdit.schedule_types.all_day',
                              ) as string
                            }
                              />
                            </div>
                          </ProfessionalContainer>
                        )}
                        {payload.frequency === 'daily' && (
                          <DaiLyFrequency
                            type={type}
                            currentDate={
                          new Date(
                            `${shiftData?.allocatedAt}T00:00:00`
                              || format(new Date(), 'yyyy-MM-dd'),
                          )
                        }
                          />
                        )}
                        {payload.frequency === 'weekly' && (
                          <WeeklyFrequency
                            type={type}
                            weekInterval={weekInterval}
                            setWeekInterval={setWeekInterval}
                            onChangeDateUtil={(date: Date) => {
                              try {
                                setLastDay(format(date, 'yyyy-MM-dd'));
                              } catch (error) {
                                setLastDay(format(new Date(), 'yyyy-MM-dd'));
                              }
                            }}
                            currentDate={
                              new Date(
                                `${shiftData?.allocatedAt}T00:00:00`
                                  || format(new Date(), 'yyyy-MM-dd'),
                              )
                            }
                          />
                        )}
                        {Boolean(errorsArray?.length) && (
                          <ErrorContainer className="row mt-4">
                            <div>
                              <ul className="col-12">
                                {errorsArray?.map((error) => (
                                  <li className="py-2">{`- ${error}`}</li>
                                ))}
                              </ul>
                            </div>
                          </ErrorContainer>
                        )}
                      </div>
                    </Body>
                    <Footer className="row mt-4">
                      <div className="col-12 d-flex justify-content-between">
                        {type === 'create' && !placement?.isAutomaticAnnouncements ? (
                          <Button
                            label={t('ModalShiftEdit.announce_vacancy')}
                            type="button"
                            className=""
                            icon="bullhornPrimary"
                            onClick={handleAnnounceVacancy}
                          />
                        ) : type !== 'create' && (user?.isAdmin || modalData.isEnableDeleteButton) ? (
                          <Button
                            type="button"
                            label={
                              isEditingRule
                                ? t('ModalShiftEdit.remove_day_rule')
                                : t('ModalShiftEdit.exclude_reservation')
                            }
                            className=""
                            onClick={() => {
                              if (isEditingRule) {
                                handleRemoveDayRule();
                                return;
                              }
                              handleExcludeShift();
                            }}
                          />
                        ) : <div />}
                        {type === 'create' ? (
                          <Button
                            onClick={handleCreateButtonClick}
                            label={(
                              <span>
                                {loading ? (
                                  <Spinner
                                    style={{ width: '12px', height: '12px' }}
                                    animation="border"
                                    size="sm"
                                  />
                                ) : t('ModalShiftEdit.create') }
                              </span>
                            )}
                            disabled={loading}
                            color="primary"
                          />
                        ) : (
                          <Button
                            onClick={handleSaveShiftClick}
                            disabled={
                              loading
                              || (!editedOrAddedEmployees?.length && !shiftData?.ruleId)
                              || isEditingSomeEmployee
                            }
                            label={(
                              <span>
                                {loading ? (
                                  <Spinner
                                    style={{ width: '12px', height: '12px' }}
                                    animation="border"
                                    size="sm"
                                  />
                                ) : t('ModalShiftEdit.save') }
                              </span>
                            )}
                            color="primary"
                          />
                        )}
                      </div>
                    </Footer>
                  </>
                )}
              </>
            )}
          {!!exceptionErrorRule && (
          <ConfirmModal
            isOpen={!!exceptionErrorRule}
            title={t('ModalShiftEdit.title_exception')}
            message={exceptionErrorRule}
            handleCloseButton={handleCloseButtonExceptionModal}
            handleConfirm={handleConfirmButtonExceptionModal}
          />
          )}
        </Container>
      </DarkBg>,
      document.getElementById('root')!,
    )
  ) : (
    <></>
  );
};

export default ({
  placement,
  modalData,
  type,
  onClose,
  calendarData,
}: ModalShiftEditProps) => (
  <ModalReservationEditContextProvider
    placement={placement}
    employee={modalData.defaultEmployee}
    shift={modalData.shiftData as Shift}
    dayName={modalData.dayName}
    openDay={modalData.openDay}
    type={type}
  >
    <ModalShiftEdit
      modalData={modalData}
      placement={placement}
      type={type}
      onClose={onClose}
      calendarData={calendarData}
    />
  </ModalReservationEditContextProvider>
);
