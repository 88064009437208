import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 10px;
  padding: 2rem 1rem;
  flex: 1;
`;

export const HeaderContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const SpaceBetween = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const FloatRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  > button {
    img {
      width: auto;
    }
  }
`;

export const FloatLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

export const CenterDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h1`
  margin-top: 30px;
  @media only screen and (max-width: 768px) {
    margin-top: 95px !important;
  }
  font-size: 18px;
  color: black;
  font-weight: 600;
  font-family: lato;
`;
