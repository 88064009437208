const toCamel = (s : string) => s.replace(/([-_][a-z])/ig, ($1) => $1.toUpperCase()
  .replace('-', '')
  .replace('_', ''));

export default function keysToCamel<ReturnType = any>(o : ReturnType) : ReturnType {
  if (typeof o === 'object' && o !== null) {
    let n : any = {};

    if (Array.isArray(o)) {
      n = [];
    }

    Object.keys(o)
      .forEach((k) => {
        n[toCamel(k) as any] = keysToCamel(o[k as keyof typeof o]);
      });

    return n;
  } if (Array.isArray(o)) {
    return (o.map((i) => keysToCamel(i)) as any) as ReturnType;
  }

  return o;
};
