import React, { FC } from 'react';
import {
  Container, IconsRounded,
} from './style';
import icons from '../../assets/icons';

interface props {
  iconLabel: string
  buttonText: string
  onClick: Function
}

const PanelOptions:FC<props> = ({
  iconLabel, onClick,
}) => (
  <>
    <Container>
      <IconsRounded>
        <button
          onClick={() => onClick()}
          type="button"
          className="bg-transparent border-0 ms-2"
        >
          <img
            src={icons.plus}
            alt=""
          />
        </button>
        <span>{iconLabel}</span>
      </IconsRounded>
    </Container>
  </>
);

export default PanelOptions;
