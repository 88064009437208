import styled from 'styled-components';
import colors from '../../../../colors';

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ListHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom-width: 1px;
  border-bottom-color:  ${colors.white13};
  border-bottom-style: solid;
  font: normal normal medium 15px/18px lato;
  color: ${colors.gray90};
  
  >div {
    width: 30%;
    :nth-child(1){
      width: 40%;
      padding-right: 2%;
    }
    :nth-child(2){
      width: 40%;
      padding-right: 2%;
    }
    :last-child {
      width: 50%;
    }
  }
  
`;

export const LoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    min-height: 100vh;
    display: flex;
`;

export const ListBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font: normal normal normal 17px/29px lato;
  font-size: 0.8vmax;
  color: ${colors.blue};
  
  >div {
    display: flex;
    justify-content: space-between;
    width: 100%;
    :nth-child(odd){
      background-color: ${colors.white13};
    }
    
    line-height: 1;
    >div{
      display: flex;
      align-items: center;
      width: 30%;
      border-bottom-width: 1px;
      border-bottom-color:  ${colors.white13};
      border-bottom-style: solid;
      
      :nth-child(1){
        width: 38%;
        font: normal normal bold 17px/21px lato;
        padding-right: 2%;
        padding-left: 2%;
      }
      :nth-child(2){
        width: 40%;
        align-items: flex-start;
        justify-content: center;
        font: normal normal normal 15px/24px lato;
        padding-right: 2%;
      }
      :nth-child(3){
        font: normal normal normal 15px/29px lato;
      }
      :nth-child(4){
        font: normal normal normal 15px/29px lato;
      }
      :last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 48%;
        padding-right: 2%;
        font: normal normal 600 16px/19px lato;
        color: ${colors.blue};
        :hover{
          cursor: pointer;
        }
        >img{
          margin-right: 10px;
        }
      }
      a {
        color: ${colors.blue};
      }
    }
    height: 90px;
  }
`;

export const LocationButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  button{
    &:nth-child(1),
    &:nth-child(2){
      display: none;
    }
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
 align-items: center;
`;

export const LocationName = styled.span`
  font: normal normal bolder 15px/21px lato;
  color: ${colors.gray90};
`;

export const LocationAddress = styled.span`
  font: normal normal medium 17px/21px lato;
`;
export const Options = styled.div`
  @media only screen and (max-width: 768px) {
    margin: auto !important;
  }
`;
