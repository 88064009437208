import styled, { css } from 'styled-components';
import colors from '../../colors';

export const Container = styled.div`
  width: 100%;
  position: relative;
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: calc(100vh);
  border-top: 1px solid ${colors.gray30};
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border-top: 1px solid ${colors.gray19};
    tr {
      td {
        border-right: 1px solid ${colors.gray19};
        border-bottom: 1px solid ${colors.gray19};
        .bordered {
          border-bottom: 1px solid ${colors.gray19};
        }
        .cell {
          min-width: 170.95px;
        }
      }
    }
    td {
      vertical-align: top;
      border-left: 1px solid ${colors.gray30};
    }
  }

  [aria-disabled="true"] {
    opacity: 0.5;
  }
`;

export const MapButtom = styled.button`
  color: ${colors.blue};
  font-family: lato;
  font-size: 15px;
  border: none;
  background-color: ${colors.green30};
  font-weight: bold;
  width: 137px;
  height: 32px;
  border-radius: 4px;
  svg {
    margin-right: 16px;
  }
`;

export const SearchInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 0.7rem 0.6rem;
  font-size: 0.9rem;
  img {
    width: 14px;
    height: 14px;
  }
  input {
    border: none;
    width: 100%;

    ::placeholder {
      color: var(--terciary-gray);
      opacity: 1;
    }
  }
`;

interface ButtonCollapseProps {
  isCollapsed: boolean;
}
export const ButtonCollapse = styled.button<ButtonCollapseProps>`
  border: none;
  background-color: transparent;
  img {
    transform: ${(props) => (props.isCollapsed ? 'rotate(360deg)' : 'rotate(270deg)')};
    transition: 0.3s;
  }
  margin-right: 1rem;
`;

interface ShiftContainerProps {
  isDragging: boolean;
  isSelected?: boolean;
  isPublished?: boolean;
  isOver?: boolean;
  isManegeable?: boolean;
}

const selectedStyle = css`
  border: solid 2px ${colors.blue} !important;
  color: ${colors.blue} !important;
`;

export const ShiftContainer = styled.div<ShiftContainerProps>`
  padding: 0.625rem 1.143rem;
  white-space: nowrap;
  align-items: center;
  min-height: 50px;
  overflow: hidden;
  text-overflow: "...";
  opacity: ${(p) => (p.isDragging ? 0 : 1)};
  min-width: 171px;
  cursor: pointer;
  ${(p) => p.isSelected && selectedStyle}
  ${(p) => {
    if (!p.isManegeable) {
      return css`
        border-left: 6px solid ${colors.gray28};
        background-color: ${colors.white13};
        color: ${colors.gray30};
      `;
    }

    if (p.isPublished) {
      return css`
        border-left: 6px solid ${p.color};
      `;
    }

    return css`
      background-color: ${p.color};
    `;
  }}
`;

export const AutomaticAnnouncmentText = styled.span`
  opacity: 0.35;
  font-weight: bold;
  font-style: italic;
`;

export const ButtonNewReservation = styled.button`
  width: 25px !important;
  height: 25px !important;
  text-align: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  border: 0;
  background: ${colors.blue};
  color: #ffffff;
`;

export const NulledCellContainer = styled.div<{
  isOver?: boolean;
  isSelected?: boolean;
}>`
  padding: 0 1.143rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  min-height: 30px;
  text-overflow: "...";
  min-width: 171px;
  transition: background-color 0.1s ease-in-out;
  cursor: pointer;
  background-color: ${(p) => (p.isOver ? `${colors.white13}` : 'white')};

  :hover {
    background-color: ${colors.white13} !important;
    ${AutomaticAnnouncmentText} {
      opacity: 0.6;
    }
  }

  ${(p) => p.isSelected
    && css`
      border: solid 2px ${colors.blue} !important;
      color: ${colors.blue} !important;
      opacity: 0.4;
    `}
`;

export const AnnouncementContainer = styled.div`
  cursor: pointer;
  min-height: 50px;
  padding: 0.325rem 1.143rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: "...";
  font-style: italic;
  font-weight: bold;
  background-color: ${colors.white13};
  color: var(--system-blue);
`;

export const AnnouncementTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: "...";
`;

export const AnnouncementTag = styled.div`
  background: ${colors.blue};
  border-radius: 16px;
  padding: 0 6px;
  display: inline;
  line-height: 12px;
  font-size: 10px;
  border: 1px solid ${colors.blue};
  margin: 2px;
  font-style: normal;
  color: ${colors.dark};
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  max-width: 45px;
  span {
    font-size: 10px;
    font-style: normal;
    color: ${colors.dark};
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
  }
`;

export const ShiftText = styled.p`
  width: 100%;
  font-size: 12px;
  margin: 0;
`;
interface ProfessionalNotAllocatedProps {
  isSelected: boolean;
  isDragging: boolean;
  isPublished: boolean;
}
export const ProfessionalNotAllocatedContainer = styled.div<
  ProfessionalNotAllocatedProps
>`
  opacity: ${(p) => (p.isDragging ? 0 : 1)};
  white-space: nowrap;
  overflow: hidden;
  min-height: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-overflow: "...";
  padding: 0.625rem 1.143rem;
  color: ${colors.gray};
  cursor: ${(p) => (p.isDragging ? 'grabbing' : 'grab')};
  min-width: 171px;
  max-width: 220px;
  ${(p) => p.isSelected && selectedStyle}
  ${(p) => {
    if (p.isPublished) {
      return css`
        border-left: 6px solid ${colors.blue5};
        background-color: ${p.color};
      `;
    }

    return css`
      background-color: ${colors.blue5};
    `;
  }}
`;

export const DaysName = styled.tr`
  > td {
    border-bottom: 1px solid ${colors.gray18} !important;
  }
  > td:first-child {
    @media (max-width: 1500px) {
      max-width: 290px;
      min-width: inherit;
    }
  }
`;

export const Search = styled.td`
  width: 320px;
  padding: 0 1rem;
  background-color: white;
`;

export const Day = styled.td<{isNow?: boolean}>`
  width: 180px;
  color: ${colors.gray};
  padding: 0.7rem 1.25rem 0.6rem;
  background-color: ${(p) => (p.isNow ? colors.green30 : colors.white5)};
  font-weight: bold;
`;

export const Location = styled.tr``;

export const Name = styled.td<{ borderColor: string }>`
  border-right: 6px solid ${(p) => p.borderColor} !important;
  > div {
    margin-top: 0.5rem;
    display: flex;
    white-space: nowrap;
    text-overflow: "...";
    align-items: center;
    padding: 0 1rem;
    color: ${colors.gray18};
    font-weight: bold;
  }
  background-color: ${colors.white13};
`;

export const TotalEmployees = styled.td`
  background-color: ${colors.white13};
  border: 1px solid ${colors.gray30};

  > div {
    min-width: 171px;
    max-width: 220px;
    padding: 0.7rem 1.25rem 0.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      margin-right: 0.5rem;
    }
  }
`;

export const SubtitleTd = styled(Day)`
  overflow: hidden;
`;
export const SubtitleLabel = styled(TotalEmployees)`
  align-items: center;
  justify-content: center;
  border: 1px solid ${colors.gray30};
  padding: 0.7rem 1.25rem 0.6rem;
  background-color: ${colors.white13};
  overflow: hidden;
  white-space: nowrap;
`;
export const SubtitleContainer = styled.span`
  padding: 0 1.143rem;
  white-space: nowrap;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  text-overflow: "...";
  font-family: lato;
  font-weight: bold;
`;
export const Shifts = styled.tr``;

export const LocationList = styled.td<{ borderColor: string }>`
  vertical-align: top;
  border-right: 1px solid ${colors.gray30};
  background-color: white;
  padding: 1rem 1rem 0 1rem;
  border-right: 6px solid ${(p) => p.borderColor} !important;
  ul {
    list-style-type: none;
    li {
      a {
        color: ${colors.gray};
      }
      color: ${colors.gray};
      margin-bottom: 1rem;
      img {
        margin-right: 1rem;
      }
    }
  }
`;

export const DayShifts = styled.td`
`;

export const CalendarOverlay = styled.div`
  position: absolute;
  background-color: ${colors.white};
  opacity: 0.5;
  overflow: hidden;
  height: calc(100vh - 64px);
  &,
  & > div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const ShowMoreOrLessContainer = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    button {
      color: ${colors.blue};
      font-size: 10px;
      background-color: transparent;
      border: none;

      :disabled {
        opacity: 0.5;
      }
    }
    :nth-child(2) {
      margin-bottom: 5px;
    }
  }
  @media (max-width: 1489px) {
    align-items: end;
  }
`;
