/* eslint-disable no-param-reassign */
import Axios from 'axios';
import { getToken } from './auth';

const apiPayments = Axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}spaces/`,
});

apiPayments.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${getToken()}`;
  config.headers.common['User-Client'] = 'WEB';
  config.params = { ...config.params, XDEBUG_SESSION_START: 'DEBUG_CLIENT' };
  return config;
});

export default apiPayments;
