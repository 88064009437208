/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FileIcon } from 'src/assets/icons/metro-file-upload.svg';

import {
  Container,
  Label,
  DropZone,
  DropContainer,
  UploadMessage,
  LinkLabel,
  MessageContainer,
} from './style';

type UploadCustomType = {
    label?: string;
    file: File | null;
    isDragActiveMessage?: string;
    isDragRejectMessage?: string;
    deafaultMessage?: string;
    accept?: string[];
    defaultLink?: string | null;
    onChange: Function;
    onDelete: Function;
    onLinkClick?: Function;
};

const UploadCustom: React.FC<UploadCustomType> = ({
  label,
  isDragActiveMessage,
  isDragRejectMessage,
  deafaultMessage,
  defaultLink = null,
  onChange,
  onDelete,
  onLinkClick,
  file,
  accept = ['*'],
  ...rest
}) => {
  const { t } = useTranslation('components');

  const onDrop = useCallback(
    (files) => {
      onChange(files[0]);
    },
    [onChange],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept,
    multiple: false,
    onDrop,
  });

  const renderDragMessage = useCallback(() => {
    if (file) {
      return (
        <MessageContainer>
          <UploadMessage loaded type="default">
            <FileIcon fill="#A8ADC0" color="#A8ADC0" />
            {isDragActiveMessage || t('UploadCustom.is_drag_active_message')}
          </UploadMessage>
          <UploadMessage type="default">{file.name}</UploadMessage>
        </MessageContainer>
      );
    }

    if (!isDragActive) {
      return (
        <UploadMessage type="default">
          <FileIcon fill="#A8ADC0" color="#A8ADC0" />
          {isDragActiveMessage || t('UploadCustom.is_drag_active_message')}
        </UploadMessage>
      );
    }

    if (isDragReject) {
      return (
        <UploadMessage type="error">
          { isDragRejectMessage || t('UploadCustom.is_drag_reject_message')}
        </UploadMessage>
      );
    }

    return (
      <UploadMessage type="success">
        { deafaultMessage || t('UploadCustom.default_message')}
      </UploadMessage>
    );
  }, [isDragActive, isDragReject, file]);

  return (
    <Container {...rest}>
      {label && <Label>{label}</Label>}
      <DropContainer>
        <DropZone {...getRootProps()}>
          {defaultLink
            ? <LinkLabel onClick={() => onLinkClick?.()}>{t('UploadCustom.default_file')}</LinkLabel>
            : (
              <>
                <input {...getInputProps()} />
                {renderDragMessage()}
              </>
            )}
        </DropZone>
      </DropContainer>
    </Container>
  );
};

export default UploadCustom;
