import React, { createContext, useContext, useState } from 'react';
import Shift from '../../contracts/models/Shift';

type SelectedNulledShift = {
  id: string;
  start: string;
  end: string;
  allocatedAt: string;
  seatId?: number;
};
export interface CalendarContextInterface {
  selectedShifts: { [x: string]: Shift };
  setSelectedShifts: React.Dispatch<
    React.SetStateAction<{ [x: string]: Shift }>
  >;
  selectedNulledShifts: { [x: string]: SelectedNulledShift };
  setSelectedNulledShifts: React.Dispatch<
    React.SetStateAction<{ [x: string]: SelectedNulledShift }>
  >;
}
export const calendarContext = createContext<CalendarContextInterface>({} as any);

export const useCalendarContext = () => useContext(calendarContext);

const CalendarContextProvider: React.FC = ({ children }) => {
  const [selectedShifts, setSelectedShifts] = useState<{ [x: string]: Shift }>({});
  const [selectedNulledShifts, setSelectedNulledShifts] = useState<{
    [x: string]: SelectedNulledShift;
  }>({});
  return (
    <calendarContext.Provider
      value={{
        selectedShifts,
        setSelectedShifts,
        selectedNulledShifts,
        setSelectedNulledShifts,
      }}
    >
      {children}
    </calendarContext.Provider>
  );
};

export default CalendarContextProvider;
