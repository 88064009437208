import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Button,
  CardPanel, Container, ContainerUserPanel, ItensName,
} from './style';
import MainNavBar from '../../components/MainNavBar';
import { mixpanelTrack } from '../../services/MixPanelService';
import { useGlobal } from '../../hooks/useGlobal';
import StepProgress from '../../components/StepProgress';
import OnboardingService from '../../services/OnboardingService';
import Accordion from '../../components/Accordion';
import PanelOptions from '../../components/PanelOptions';
import UserPanel from '../../components/PanelOptions/UserPanel';
import { getUser } from '../../services/auth';
import { useToast } from '../../hooks/toast';
import TeamService from '../../services/TeamService';
import LocationService from '../../services/LocationService';
import Loader from '../../components/Loader';
import icons from '../../assets/icons';
import { getData, USER_STORAGE } from '../../services/LocalStorageService';

const Panel = () => {
  const getOnlyCollaborator = () => {
    const userStorage = getData(USER_STORAGE);

    if (userStorage) {
      return userStorage.only_collaborator;
    }

    return null;
  };
  const onlyCollaborator = getOnlyCollaborator();
  const { t } = useTranslation('pages');
  const [steps, setSteps] = useState<Array<{
    type: string | null | undefined,
    value: boolean | number | string | object,
    name: string | undefined
  }>>([]);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isFinishSteps, setIsFinishSteps] = useState(true);
  const [locations, setLocations] = useState([]);
  const [teams, setTeams] = useState([]);
  const [loadings, setLoadings] = useState({
    teams: true,
    locations: true,
  });
  const { sideMenuIsOpen } = useGlobal();
  const history = useHistory();
  const { addToast } = useToast();

  const getLoggedUser = () => {
    const user = getUser();
    if (user) {
      setIsAdmin(user.isAdmin);
    }
  };

  useEffect(() => {
    if (onlyCollaborator) history.push('/calendar');
  }, [onlyCollaborator]);

  useEffect(() => {
    mixpanelTrack('panel@init', null);
    OnboardingService.getAchievements()
      .then((r) => setSteps(r.data))
      .catch(() => addToast({
        type: 'error',
        description: t('Panel.request_error'),
      }))
      .finally(() => setLoading(false));
    getLoggedUser();
    TeamService.getTeamsWithoutEmployees()
      .then((res) => setTeams(res.data))
      .finally(() => setLoadings((prevState) => ({
        ...prevState,
        teams: false,
      })));
    LocationService.getAllLocations(100, 1)
      .then((res) => setLocations(res.data.data))
      .finally(() => setLoadings((prevState) => ({
        ...prevState,
        locations: false,
      })));
  }, []);

  useEffect(() => {
    if (steps.length > 0) {
      const doneStepsIndex = steps.findIndex((value: any) => value.name === 'done_percent');
      setIsFinishSteps(steps[doneStepsIndex]?.value === 100);
    }
  }, [steps]);

  return (
    <>
      <MainNavBar />
      <Container className={`${sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'} row px-2 me-0`}>
        <CardPanel className={`col-sm-12 ${isFinishSteps ? 'col-md-12' : 'col-md-9'} my-3`}>
          <ContainerUserPanel className="d-flex justify-content-end">
            <UserPanel />
          </ContainerUserPanel>
          <Accordion
            extraIcon={icons.team}
            title={t('Panel.accordion.my_teams')}
            open
            tooltipLabel={t('Panel.accordion.teams_tooltip')}
            extraOptions={(
              <PanelOptions
                iconLabel={t('Panel.accordion.teams_title')}
                buttonText={t('Panel.accordion.button')}
                onClick={() => history.push('/teams?addingTeam=new_team')}
              />
          )}
          >
            <>
              <div className="overflow-auto" style={{ height: teams.length > 0 ? '312px' : 'auto' }}>
                {
                  loadings.teams ? (
                    <div className="container-spinner">
                      <Loader />
                    </div>
                  )
                    : (

                      teams.map((teamValue: {name: string, id: number}, index) => (
                        <div key={teamValue?.id} className={`card my-2 border-0 ${index % 2 === 0 && 'itens-accordion'}`}>
                          <div className="card-body">
                            <ItensName>
                              {teamValue?.name}
                            </ItensName>
                          </div>
                        </div>
                      )))
}
              </div>
            </>
          </Accordion>
          <Accordion
            extraIcon={icons.Location}
            title={t('Panel.accordion.my_spaces')}
            open
            active={isAdmin}
            tooltipLabel={t('Panel.accordion.space_tooltip')}
            extraOptions={(
              <PanelOptions
                iconLabel={t('Panel.accordion.space_title')}
                buttonText={t('Panel.accordion.button')}
                onClick={() => history.push('/space')}
              />
            )}
          >
            <>
              <div className="overflow-auto" style={{ height: locations.length > 0 ? '312px' : 'auto' }}>
                {
                  loadings.locations
                    ? (
                      <div className="container-spinner">
                        <Loader />
                      </div>
                    )
                    : (
                      locations?.map((locationsValue:
                                        {
                                          id:number,
                                          address: string,
                                          name: string}, index) => (
                                            <div
                                              key={locationsValue?.id}
                                              className={`card my-2 border-0 ${index % 2 === 0 && 'itens-accordion'}`}
                                            >
                                              <div className="card-body">
                                                <div className="row">
                                                  <div className="col-lg-10 col-12">
                                                    <ItensName>
                                                      {locationsValue.name}
                                                    </ItensName>
                                                    <span>
                                                      Endereço:
                                                      {' '}
                                                      {locationsValue.address}
                                                    </span>
                                                  </div>
                                                  <div className="col-12 col-lg-2 d-flex justify-content-end">
                                                    <Button type="button" onClick={() => history.push(`space/${locationsValue?.id}`)}>
                                                      Editar espaço
                                                    </Button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                      ))
                    )
                }
              </div>
            </>
          </Accordion>
        </CardPanel>
        <div className={`${isFinishSteps ? 'd-none' : 'col-sm-12 col-md-3 my-3 pb-3 px-0 px-md-2'}`}>
          <StepProgress steps={steps} isLoading={loading} />
        </div>
      </Container>
    </>
  );
};
export default Panel;
