import styled from 'styled-components';
import { darken } from 'polished';
import colors from '../../colors';

export const Container = styled.div`
  
`;

export const Selector = styled.div`
    width: 20px;
    height: 30px;
    background-color: red;
`;

export const ColorTooltip = styled.div`
    background: ${colors.white};
    z-index: 2;
    color: #333;
    font-weight: bold;
    font-size: 13px;
    border-radius: 4px;
    position: absolute;
    box-shadow: 0px 3px 4px ${colors.dark85};
    background-color: ${colors.white};
    border-radius: 5px;
    min-width: 6rem;
    padding: 1rem;
    text-align: left;
`;

export const ColorListContainer = styled.div`
    flex: 1;
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    grid-gap: 16px;
`;

export const ColorOption = styled.div<{ color?: string | null}>`
    background: ${(props) => (props.color ? props.color : `${colors.white}`)};
    width: 30px;
    height: 30px;
    border-radius: 2px;
    border-color: ${(props) => (props.color ? darken(0.2, props.color) : `${colors.white}`)};
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    justify-content:center;
    align-items: center;
`;

export const DarkBg = styled.div<{ isOpen?: boolean }>`
    z-index: 1;
    top: 0;
    position: fixed;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: ${colors.dark95};
`;
