import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { removeLocalStorageInfoAndResetMixPanelAndZendesk } from '../../services/auth';
import { useGlobal } from '../../hooks/useGlobal';
import ModalConfirm from '../../components/ConfirmModal';
import { BackgroundImage } from '../../components/Onboarding/style';

const Logout = () => {
  const [isOpen, setIsOpen] = useState(true);
  const { user } = useGlobal();
  const { t } = useTranslation('components');
  const history = useHistory();

  const logout = () => {
    if (user) {
      AuthService.logout().then((response) => {
        removeLocalStorageInfoAndResetMixPanelAndZendesk();

        if (response.data.url_logout !== null) {
          setTimeout(() => {
            window.location.href = response.data.url_logout;
          }, 10);

          return false;
        }

        return true;
      });

      user?.logout?.();
    }
  };

  return (
    <>
      <BackgroundImage style={{ filter: 'blur(15px)' }} />
      <ModalConfirm
        isOpen={isOpen}
        handleConfirm={logout}
        handleCloseButton={() => {
          history.goBack();
          setIsOpen(false);
        }}
        message={t('MainNavBar.logout_confirm')}
      />
    </>
  );
};

export default Logout;
