import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    width: 100%;
    min-height: 43px;
    background: #F2F4FF !important;
    border-radius: 4px;
    
    .sub-text {
        margin-left: 5px;
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        color: #000E4C;
    }
    img {
      filter: invert(6%) sepia(77%) saturate(7451%) hue-rotate(214deg) brightness(30%) contrast(119%);
    }
`;

export default Container;
