import React, {
  FC, ReactElement, useEffect, useState,
} from 'react';
import { Collapse } from 'reactstrap';
import icons from '../../assets/icons';
import {
  Button, Container, ContainerButton,
} from './style';

interface AccordionProps {
  title: string
  children: ReactElement
  open: boolean | null
  active?: boolean
  tooltipLabel?: string
  extraOptions?: ReactElement
  extraIcon?: string
}

const Accordion:FC<AccordionProps> = ({
  title, open, active = true, children, tooltipLabel, extraOptions, extraIcon,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsOpen(open);
    }
  }, [open]);

  return (
    <Container isOpen={isOpen} isActive={active} className="card accordion-border">
      <ContainerButton className="mt-2 d-flex justify-content-between">
        <Button
          type="button"
          onClick={() => setIsOpen(!isOpen)}
          isOpen={isOpen}
        >
          <img src={icons.CollapseArrow} alt="" className="icon-rotate" />
          <div>
            <span>{title}</span>
            {extraIcon && <img className="ms-2" src={extraIcon} alt="" />}
            {tooltipLabel && <img src={icons.Info} className="tooltip" alt={tooltipLabel} data-toggle="tooltip" data-placement="top" title={tooltipLabel} />}
          </div>
        </Button>
        {extraOptions}
      </ContainerButton>
      <Collapse isOpen={isOpen} className="accordion-height">
        {children}
        {' '}
      </Collapse>
    </Container>
  );
};

export default Accordion;
