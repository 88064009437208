import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmployeeService from 'src/services/EmployeeService';
import Input from 'src/components/InputWithRef';
import { useForm } from 'react-hook-form';
import Validator from 'src/utils/input.valitors';
import {
  Container,
  ContainerOutside,
  Header,
  Body,
  Footer,
  Overlay,
  ProfileImage,
  ProfileNonImage,
  Forms,
  ButtonReset,
  InputLabel,
  LoaderButton,
} from './style';
import Button from '../Button';
import icons from '../../assets/icons';
import { useToast } from '../../hooks/toast';
import Loader from '../../assets/imgs/loader_animation.gif';
import PassStrength from '../PassStrength';
import { addData } from '../../services/LocalStorageService';
import trimString from '../../utils/trimString';
import { useGlobal } from '../../hooks/useGlobal';
import { useAlert } from '../../hooks/useAlert';
import Employee from '../../contracts/models/Employee';
import User from '../../contracts/models/User';
import ModalQrCode from './ModalQrCode';
import ButtonLoader from '../ButtonLoader';
import removeExif from '../../utils/removeExif';

interface ModalEditUserProps {
  isOpen: boolean;
  onCloseModal: () => void;
  refresh: () => void;
}

const ModalEditUser: React.FC<ModalEditUserProps> = ({
  isOpen,
  onCloseModal,
  refresh,
}) => {
  const { t } = useTranslation('components');
  const { addToast } = useToast();
  const [photoFileState, setPhotoFileState] = useState<any>(undefined);
  const [photoBase64State, setPhotoBase64State] = useState<any>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenChangePassword, setIsOpenChangePassword] = useState(false);
  const [password, setPassword] = useState<any>('');

  const [isOpenQrModal, setIsOpenQrModal] = useState(false);

  const {
    register: passwordFormRegister,
    errors: passwordFormErrors,
    handleSubmit: passwordFormHandleSubmit,
    reset: passwordFormReset,
  } = useForm<FormData>();

  const {
    register: editUserRegister,
    handleSubmit: editUserhandleSubmit,
    errors: editUserErrors,
  } = useForm<FormData>();
  const { user, setUser } = useGlobal();
  const { openAlert } = useAlert();
  const professionalInitials = useMemo(() => {
    const splited = user?.name.split(' ');
    const firstLetter = splited?.[0].substr(0, 1);
    const secondLetter = splited?.[1] ? splited?.[1].substr(0, 1) : '';
    return firstLetter + secondLetter;
  }, [user?.name]);

  const appendFormData = async (body: FormData, data: Employee) => {
    body.append('name', data?.name as string);
    body.append('phone', data?.phone?.replace(/[^\d]+/g, '') || '');

    if (data?.email && data?.email.length > 0) {
      body.append('email', data?.email);
    }
    if (data?.cpf && data?.cpf.length > 0) {
      body.append('cpf', data?.cpf.replace(/[^\d]+/g, ''));
    }

    try {
      const employee = await EmployeeService.editEmployee(user?.id as number, body);
      addToast({
        description: t('ModalEditUser.success'),
        type: 'success',
      });
      const localStorageUser = localStorage.getItem('espacos@user');
      if (localStorageUser) {
        const employeeData = JSON.parse(localStorageUser);
        employeeData.user = {
          ...employeeData.user,
          ...data,
          photo: employee.data.photo,
        };
        addData('espacos@user', employeeData);
      }
      setUser({ ...(user as User), ...(data as Employee) });
      onCloseModal();
      refresh();
    } catch (err: any) {
      if (err.response && err.response.status === 400) {
        addToast({
          description: err.response.data.message,
          type: 'error',
        });
      } else {
        addToast({
          description: t('ModalEditUser.errors'),
          type: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const submitEditProfile = (data: Employee) => {
    const onConfirm = async () => {
      setIsLoading(true);
      const body = new FormData();
      if (!photoFileState) await appendFormData(body, data);
      else {
        await removeExif(photoFileState, async (params: any) => {
          await body.append('photo', params?.file || '');
          await appendFormData(body, data);
        });
      }
    };

    openAlert({
      handleConfirm: onConfirm,
      message: !data?.email ? t('ModalAdminUser.no_email') : t('ModalEditUser.confirm_update_my_data'),
    });
  };

  const clearPasswordStats = () => {
    passwordFormReset();
  };

  const submitChangedPassword = async (data: {
    currentPassword?: string;
    newPassword?: string;
    passwordConfirmation?: string
  }) => {
    const onConfirm = async () => {
      setIsLoading(true);
      const body = {
        current_password: data.currentPassword as string,
        new_password: data.newPassword as string,
        confirmation_password: data.passwordConfirmation as string,
        employee_id: String(user?.id),
      };
      try {
        await EmployeeService.changePasswordEditUser(body);
        addToast({
          description: t('ModalEditUser.password_success'),
          type: 'success',
        });
        clearPasswordStats();
        setIsOpenChangePassword(false);
      } catch (err: any) {
        if (err.response && err.response.status === 400) {
          addToast({
            description: t('ModalEditUser.current_password_error'),
            type: 'error',
          });
        } else if (err.response && err.response.status === 422) {
          addToast({
            description: t('ModalEditUser.compare_password_error'),
            type: 'error',
          });
        } else {
          addToast({
            description: t('ModalEditUser.errors'),
            type: 'error',
          });
        }
      } finally {
        setIsLoading(false);
      }
    };
    openAlert({
      message: 'Tem certeza que deseja alterar sua senha?',
      handleConfirm: onConfirm,
    });
  };

  const getBase64 = (file: Blob) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

  const handleChange = async (target: HTMLInputElement) => {
    const file = target.files![0];
    setPhotoFileState(file);
    getBase64(file).then((result) => setPhotoBase64State(result));
  };

  const buildFiledSelect = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.type = 'file';
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', 'image/*');
    fileSelector.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      handleChange(target);
    };
    return fileSelector;
  };
  const photoSelector = buildFiledSelect();

  function handleFileSelect(e: { preventDefault: () => void; }) {
    e.preventDefault();
    photoSelector.click();
  }

  const escFunction = (event: any) => {
    const escKey = 27;
    if (event.keyCode === escKey) {
      onCloseModal();
      setIsOpenChangePassword(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
  }, []);

  const handleChangePassword = () => {
    setIsOpenChangePassword(true);
  };

  const onClickBackButton = () => {
    setIsOpenChangePassword(false);
  };

  const onCloseModalViews = () => {
    setIsOpenChangePassword(false);
    onCloseModal();
  };

  return (
    <>
      <Overlay isOpen={isOpen}>
        <ContainerOutside
          onClick={() => onCloseModalViews()}
          className="d-flex flex-column"
        />
        <Container
          isOpen={isOpen}
          className="d-flex flex-column justify-content-between"
        >
          {isOpenChangePassword ? (
            <div className="d-flex flex-column justify-content-between h-100">
              <div>
                <Header className="d-flex align-items-center justify-content-between py-4 px-5">
                  <button type="button" className="p-0" onClick={() => onClickBackButton()}>
                    <img className="me-3" src={icons.ArrowRoundBack} alt="ArrowRoundBack" />
                    {t('ModalEditUser.change_password')}
                  </button>
                  <button type="button" onClick={onCloseModalViews}>
                    <img src={icons.close} className="logo" alt={t('ModalEditUser.close')} />
                  </button>
                </Header>
                <hr className="mt-0" />
                <Body className="p-5">
                  <Forms onSubmit={passwordFormHandleSubmit(submitChangedPassword)}>
                    <div className="mb-4">
                      <InputLabel>{t('ModalEditUser.current_password')}</InputLabel>
                      <Input
                        name="currentPassword"
                        register={
                        passwordFormRegister({
                          required: true,
                          setValueAs: trimString,
                        })
                      }
                        type="password"
                      />
                    </div>
                    <div className="mb-4">
                      <Input
                        error={passwordFormErrors}
                        errorMsg={t('ModalEditUser.password_error')}
                        name="newPassword"
                        label={t('ModalEditUser.new_password')}
                        type="password"
                        register={
                        passwordFormRegister({
                          required: true,
                          minLength: 6,
                          setValueAs: trimString,
                        })
                        }
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <PassStrength password={password} />
                    </div>
                    <div className="mb-4">
                      <Input
                        error={passwordFormErrors}
                        errorMsg={t('ModalEditUser.no_match_password_error')}
                        name="passwordConfirmation"
                        label={t('ModalEditUser.repeat_password')}
                        type="password"
                        register={passwordFormRegister({
                          required: true,
                          validate: (value) => value === password,
                          setValueAs: trimString,
                        })}
                      />
                    </div>
                  </Forms>
                </Body>
              </div>
              <div>
                <Footer className="px-5 pb-4">
                  <ButtonReset
                    isLoading={isLoading}
                    onClick={passwordFormHandleSubmit(submitChangedPassword)}
                    className="btn-block"
                  >
                    {isLoading ? (
                      <LoaderButton
                        src={Loader}
                        alt={t('ModalEditUser.confirm_button')}
                      />
                    ) : t('ModalEditUser.confirm_button')}
                  </ButtonReset>
                </Footer>
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-between h-100">
              <form onSubmit={editUserhandleSubmit(submitEditProfile as any)}>
                <div>
                  <Header className="d-flex justify-content-between align-items-center py-4 px-5">
                    <h3 className="m-0">{t('ModalEditUser.title')}</h3>
                    <button type="button" onClick={onCloseModal}>
                      <img src={icons.close} className="logo" alt={t('ModalEditUser.close')} />
                    </button>
                  </Header>
                  <hr className="mt-0" />
                  <Body className="my-5">
                    <div className="position-relative">
                      { photoBase64State || user?.photo ? (
                        <ProfileImage
                          src={photoBase64State || user?.photo}
                          alt={user?.name}
                        />
                      ) : (
                        <ProfileNonImage>
                          {professionalInitials}
                        </ProfileNonImage>
                      )}
                      <div className="d-flex justify-content-center">
                        <Button
                          onClick={handleFileSelect}
                          label={t('ModalEditUser.edit_photo')}
                          icon="alterarFoto"
                          className="btn btn-link edit-button mt-2 border-0"
                        />
                      </div>
                    </div>

                    <Forms method="post" encType="multipart/form-data">
                      <div className="row px-5">
                        <div className="col-12">
                          <div className="form-group">
                            <InputLabel>{t('ModalEditUser.input_name')}</InputLabel>
                            <Input
                              error={editUserErrors}
                              defaultValue={user?.name}
                              errorMsg={t('ModalTeamAddEmployee.new_employee_tab.name_error')}
                              name="name"
                              register={editUserRegister({
                                required: true,
                                setValueAs: trimString,
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <InputLabel>{t('ModalEditUser.input_email')}</InputLabel>
                            <Input
                              error={editUserErrors}
                              defaultValue={user?.email}
                              errorMsg={t('ModalTeamAddEmployee.new_employee_tab.email_error')}
                              name="email"
                              register={editUserRegister({
                                validate: (value) => !value || Validator.validateEmail(value),
                                setValueAs: trimString,
                              })}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <InputLabel>{t('ModalEditUser.input_phone')}</InputLabel>
                            <Input
                              error={editUserErrors}
                              defaultValue={user?.phone}
                              name="phone"
                              mask="(99) 99999-9999"
                              register={editUserRegister}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group">
                            <InputLabel>{t('ModalEditUser.input_cpf')}</InputLabel>
                            <Input
                              error={editUserErrors}
                              defaultValue={user?.cpf}
                              errorMsg={t('ModalTeamAddEmployee.new_employee_tab.cpf_error')}
                              mask="999.999.999-99"
                              name="cpf"
                              register={editUserRegister({
                                validate: (value) => !value || Validator.validateCPF(value.replace(/[^\d]/g, '')),
                              })}
                            />
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <button
                                type="button"
                                className="btn button-change-password"
                                onClick={handleChangePassword}
                              >
                                <img src={icons.lock} alt={t('ModalEditUser.change_password')} />
                                {t('ModalEditUser.change_password')}
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <button
                                type="button"
                                className="btn button-change-password"
                                onClick={() => setIsOpenQrModal(true)}
                              >
                                <img src={icons.QrCode} alt={t('ModalEditUser.add_auth_token')} />
                                {t('ModalEditUser.add_auth_token')}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Forms>
                  </Body>
                </div>
                <Footer className="px-5 pb-4">
                  <ButtonLoader
                    type="submit"
                    isLoading={isLoading}
                    label={t('ModalEditUser.input_save')}
                    className="btn-block"
                  />
                </Footer>
              </form>
            </div>
          )}
        </Container>
      </Overlay>
      <ModalQrCode
        isOpen={isOpenQrModal}
        handleCloseButton={() => {
          setIsOpenQrModal(false);
        }}
      />
    </>
  );
};

export default ModalEditUser;
