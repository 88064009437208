import styled, { css } from 'styled-components';

interface Props{
    disabled?: boolean,
}
export const Wrapper = styled.div<Props>`
  .DayPickerInput {
    >input{
      display: flex;
      outline: none;
      border: none;
      max-width: 80px;
      color: #3b4252;
      background-color: ${(props) => (props.disabled ? '#F6F7F8' : '#FFFFFF')}
    }
  }
  >input{
    display: flex;
    outline: none;
    border: none;
    max-width: 90px;
    color: #3b4252;
    text-align: left;
    margin-left: 10px;
    overflow: hidden;
    background-color: ${(props) => (props.disabled ? '#F6F7F8' : '#FFFFFF')};
  }
`;

const disabledStyle = css`
  opacity: 0.6;
  pointer-events: none;
`;

export const Container = styled.div<Props>`
  background-color: #FFFFFF;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 40px !important;

  ${(props) => (props.disabled && disabledStyle)}
`;

export const PickerContainer = styled.div<Props>`
  background-color: ${(props) => (props.disabled ? '#F6F7F8' : '#FFFFFF')};
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.disabled ? 'flex-start' : 'space-between')};
  padding-left: 20px;
  border: 1px solid #0000001F;
  border-radius: 4px;
  width: 150px;
  height: 40px !important;
`;
