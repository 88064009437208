import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from 'src/components/InputWithRef';
import Loader from 'src/assets/imgs/loader_animation.gif';
import Team from 'src/contracts/models/Team';
import TeamService from 'src/services/TeamService';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import {
  Body,
  Container,
  Header,
  Overlay,
  Title,
  CloseButtoom,
  Footer,
  ConfirmButton,
  CancelButtom,
  LoaderButton,
} from './style';

interface ModalTeamProps {
  isOpen: boolean;
  editMode: boolean;
  team? : Team | null;
  handleCloseButton: () => void;
  handleEditSuccess: Function;
  handleCreateSuccess: Function;
}

const ModalTeam: React.FC<ModalTeamProps> = ({
  isOpen,
  editMode,
  team,
  handleCloseButton,
  handleEditSuccess,
  handleCreateSuccess,
}) => {
  const { t } = useTranslation('components');
  const { register, errors, handleSubmit } = useForm<FormData>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const createTeam = (data: Team) => {
    TeamService.createTeam(data).then(() => {
      setIsLoading(false);
      addToast({
        description: t('ModalTeam.create_success'),
        type: 'success',
      });
      handleCreateSuccess();
    }).catch(() => {
      setIsLoading(false);
      addToast({
        description: t('ModalTeam.create_error'),
        type: 'error',
      });
    });
  };

  const updateTeam = (data: Team) => {
    const teamId = team?.id || 0;
    TeamService.updateTeam(data, teamId).then(() => {
      setIsLoading(false);
      addToast({
        description: t('ModalTeam.update_success'),
        type: 'success',
      });
      handleEditSuccess({ ...data, ...{ id: teamId } });
      handleCloseButton();
    }).catch(() => {
      setIsLoading(false);
      addToast({
        description: t('ModalTeam.update_error'),
        type: 'error',
      });
    });
  };

  const onSubmit = (data: Team) => {
    setIsLoading(true);
    if (editMode) {
      updateTeam(data);
    } else {
      createTeam(data);
    }
  };

  const getButtonLabel = () => (editMode ? t('ModalTeam.update') : t('ModalTeam.send'));

  return (
    <Overlay isOpen={isOpen}>
      <Container
        isOpen={isOpen}
        className="d-flex flex-column justify-content-between"
      >
        <Header className="d-flex">
          <Title>
            {editMode ? t('ModalTeam.update_title') : t('ModalTeam.create_title')}
          </Title>
          <CloseButtoom onClick={handleCloseButton}>x</CloseButtoom>
        </Header>
        <Body>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Input
              error={errors}
              errorMsg={t('ModalTeam.name_error')}
              name="name"
              defaultValue={team ? team?.name : ''}
              label={t('ModalTeam.name_label')}
              required
              register={register}
            />
            <Footer>
              <CancelButtom type="button" onClick={handleCloseButton}>
                {' '}
                {t('ModalTeam.cancel')}
              </CancelButtom>
              <ConfirmButton isLoading={isLoading} type="submit">
                {isLoading ? (
                  <LoaderButton
                    src={Loader}
                  />
                ) : (
                  getButtonLabel()
                )}
              </ConfirmButton>
            </Footer>
          </form>
        </Body>
      </Container>
    </Overlay>
  );
};

export default ModalTeam;
