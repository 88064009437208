import React, { useState } from 'react';
import Input from 'src/components/InputWithRef';
import Select from 'src/components/Select';
import { mixpanelTrack } from 'src/services/MixPanelService';
import { useTranslation } from 'react-i18next';
import {
  Body,
  Container,
  Header,
  Overlay,
  Title,
  CloseButtoom,
  Footer,
  ConfirmButton,
  CancelButtom,
  Row,
} from './style';

interface ModalPrefixGeneratorProps {
  isOpen: boolean;
  handleCloseButton: () => void;
  handleCreateSuccess: Function;
}

const OPTIONS = [
  {
    label: '1,2,3,4...',
    name: '1',
    value: 1,
  },
  {
    label: '01,02,03,04...',
    name: '01',
    value: 2,
  },
  {
    label: '001,002,003,004...',
    name: '001',
    value: 3,
  },
  {
    label: '0001,0002,0003,0004...',
    name: '0001',
    value: 4,
  },
];

const ModalPrefixGenerator: React.FC<ModalPrefixGeneratorProps> = ({
  isOpen,
  handleCloseButton,
  handleCreateSuccess,
}) => {
  const { t } = useTranslation('components');
  const [prefix, setPrefix] = useState('');
  const [type, setType] = useState(OPTIONS[0]);

  const onSubmit = () => {
    mixpanelTrack('prefix@create', {
      pattern: type.name,
    });
    handleCreateSuccess(prefix, type.value);
  };

  const pad = (num: number, size: number) => {
    let numStr = num.toString();
    while (numStr.length < size) numStr = `0${numStr}`;
    return numStr;
  };

  return (
    <Overlay isOpen={isOpen}>
      <Container
        isOpen={isOpen}
        className="d-flex flex-column justify-content-between"
      >
        <Header className="d-flex">
          <Title>
            {t('ModalPrefixGenerator.create_title')}
          </Title>
          <CloseButtoom onClick={handleCloseButton}>x</CloseButtoom>
        </Header>
        <Body>
          <Row>
            <Input
              marginTop="0px"
              errorMsg={t('ModalPrefixGenerator.name_error')}
              name="name"
              defaultValue=""
              label={t('ModalPrefixGenerator.name_label')}
              required
              value={prefix}
              onChange={(e) => setPrefix(e.target.value)}
            />
            <Select
              label={t('ModalPrefixGenerator.type_label')}
              value={type}
              onChange={(newType: any) => setType(newType)}
              options={[
                {
                  label: '1,2,3,4...',
                  value: 1,
                },
                {
                  label: '01,02,03,04...',
                  value: 2,
                },
                {
                  label: '001,002,003,004...',
                  value: 3,
                },
              ]}
            />
          </Row>
          <Input
            name="preview"
            disabled
            value={`${prefix} - ${pad(1, type.value)}`}
            label={t('ModalPrefixGenerator.preview_label')}
          />
          <Footer>
            <CancelButtom type="button" onClick={handleCloseButton}>
              {t('ModalPrefixGenerator.cancel')}
            </CancelButtom>
            <ConfirmButton isLoading={false} onClick={onSubmit} type="button">
              {t('ModalPrefixGenerator.send')}
            </ConfirmButton>
          </Footer>
        </Body>
      </Container>
    </Overlay>
  );
};

export default ModalPrefixGenerator;
