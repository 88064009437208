import React from 'react';
import {
  Container, Avatar, AvatarNonImage, InfoArea, NameText, EmailText,
} from './style';

interface OptionEmployeeProps {
    value: string;
    label: string;
    photo: string;
    email: string;
}

const OptionEmployee: React.FC<OptionEmployeeProps> = ({ label, photo, email }) => {
  const getEmployeeInitials = (name: string) => {
    const splited = name.split(' ');
    const firstLetter = splited[0].substr(0, 1);
    const secondLetter = splited[1] ? splited[1].substr(0, 1) : '';
    return firstLetter + secondLetter;
  };

  return (
    <Container>
      {photo ? (
        <Avatar src={photo} />
      ) : (
        <AvatarNonImage>
          {getEmployeeInitials(label)}
        </AvatarNonImage>
      )}
      <InfoArea>
        <NameText>{label}</NameText>
        <EmailText>{email}</EmailText>
      </InfoArea>
    </Container>
  );
};

export default OptionEmployee;
