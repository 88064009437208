import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainNavBar from '../../components/MainNavBar';
import {
  Container,
  BackButton,
  Picture,
  ProfileTitle,
  ProfileName,
  ProfileOffice,
  ProfileContact,
  AttendantButton,
} from './style';
import { useGlobal } from '../../hooks/useGlobal';
import icons from '../../assets/icons';

const Attendant = () => {
  const { t } = useTranslation('pages');
  type RouteParams = {
    plan: string;
  };
  const { sideMenuIsOpen } = useGlobal();
  const history = useHistory();
  const { plan } = useParams<RouteParams>();

  const whatsUrl = `https://api.whatsapp.com/send?phone=+55419969257190&text=${t('Attendant.body_contact', { plan })}`;

  return (
    <>
      <MainNavBar />
      <Container className={`${sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'} row me-0`}>
        <div className="col-12">
          <BackButton onClick={() => history.goBack()}>
            <img src={icons.ArrowRoundBack} alt="" />
          </BackButton>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <Picture />
        </div>
        <div className="col-sm-12 text-center">
          <ProfileTitle>{t('Attendant.speak_to_a_specialist')}</ProfileTitle>
          <ProfileName>Christopher Charles</ProfileName>
          <ProfileOffice>{t('Attendant.business_development')}</ProfileOffice>
          <ProfileContact href={`mailto:christopher.charles@escala-app.com?subject=${plan}&body=${t('Attendant.body_contact', { plan })}`} target="_blank">
            <img src={icons.email} alt="" />
            {' '}
            christopher.charles@escala-app.com
          </ProfileContact>
          <ProfileContact href={whatsUrl} target="_blank">
            <img src={icons.whatsapp} alt="" />
            (41) 99692-5719
          </ProfileContact>
          <AttendantButton onClick={() => window.open(whatsUrl, '_blank')}>{t('Attendant.talk_to_the_expert')}</AttendantButton>
        </div>
      </Container>
    </>
  );
};

export default Attendant;
