import React, { ReactElement, FC } from 'react';
import Container from './style';

interface SectionProps {
  children?: ReactElement
}
const SectionTitle:FC<SectionProps> = ({ children }) => (
  <Container>
    {children}
  </Container>
);

export default SectionTitle;
