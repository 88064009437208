/* eslint-disable import/no-duplicates */
import {
  format,
  parse,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

export const formatDateToString = (date: Date, pattern: 'dd/MM/yyyy') => format(date, pattern, { locale: ptBR }).toUpperCase();

export const formatStringToDate = (date: string, pattern: string = 'dd/MM/yyyy') => {
  const newDate = parse(date, pattern, new Date());
  return newDate;
};

export const formatDateStringToDateString = (date: string, oldPattern: string, pattern: string = 'dd/MM/yyyy') => {
  const newDate = parse(date, oldPattern, new Date());
  const dateString = format(newDate, pattern, {
    locale: ptBR,
  });

  return dateString;
};
