import React, { FC, useEffect, useState } from 'react';
import {
  Container, ProgressBarContainer, Progress, ProgressBarText,
} from './style';

interface ProgressBarProps {
  percentage: boolean | number | string | object,
}

const ProgressBar:FC<ProgressBarProps> = ({ percentage }) => {
  const [remaining, setRemaining] = useState(-100);

  const getRemainingPercentage = () => {
    if (typeof percentage === 'number') {
      setRemaining(percentage - 100);
    }
  };

  useEffect(() => {
    getRemainingPercentage();
  }, [percentage]);

  return (
    <Container>
      <ProgressBarContainer>
        <Progress percentage={remaining}>
          <ProgressBarText>
            {' '}
          </ProgressBarText>
        </Progress>
      </ProgressBarContainer>
    </Container>
  );
};

export default ProgressBar;
