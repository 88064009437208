import styled from 'styled-components';
import colors from '../../colors';

interface NavProps {
    selected?: boolean;
    backColor?: string;
    leftSpace?: string;
    justify?: string;
}
export const Screen = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`;

export const Body = styled.div<NavProps>`
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: ${(props) => (props.leftSpace ? props.leftSpace : '0')};
`;

export const NavContainer = styled.div<NavProps>`
   display: flex;
   align-items: flex-end;
   justify-content: center;
   width: 100%;
   padding-left: ${(props) => (props.leftSpace ? props.leftSpace : '0')};
   background: ${(props) => (props.backColor ? props.backColor : `${colors.white10}`)} 0% no-repeat padding-box;
   border-bottom: 1px solid var(--quaternary-gray);
`;

export const LinksContaier = styled.div<NavProps>`
    height: 5rem;
    width: ${(props) => (props.leftSpace ? '100%' : '95%')};
    display: flex;
    justify-content: ${(props) => (props.justify)};
`;

export const LinkBox = styled.div<NavProps>`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-right: 30px;
    margin-bottom: -1px;
    border-bottom: 1px solid ${(props) => (props.selected ? `${colors.blue}` : 'var(--quaternary-gray)')};
    -webkit-touch-callout: none; 
    -webkit-user-select: none; 
    -khtml-user-select: none; 
    -moz-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    padding-bottom: 1rem;
    :hover{
        color: ${colors.blue};
        cursor: pointer;
    }   
`;

export const LinkIcon = styled.img<NavProps>`
    margin-right: 7px;
    height: 15px;
    fill: '${colors.blue}';
    filter: ${(props) => (props.selected
    ? 'brightness(0) saturate(100%) invert(7%) sepia(31%) saturate(7050%) hue-rotate(224deg) brightness(109%) contrast(113%);'
    : 'invert(51%) sepia(6%) saturate(1025%) hue-rotate(190deg) brightness(89%) contrast(82%)')};
`;
export const LinkLabel = styled.span<NavProps>`
    font: normal normal medium 15px/18px lato;
    color: ${(props) => (props.selected ? `${colors.blue}` : 'var(--secondary-gray)')};
    :hover{
        cursor: pointer;
    }
`;
