import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ICONS from 'src/assets/icons';
import UsersTab from './tabs/User';
import ConfigTab from './tabs/Configs';
import Upload from './tabs/Upload';
import MainNavBar from '../../components/MainNavBar';
import SelectedUsersProvider from './tabs/User/providers/selected_users_provider';
import SideBar from '../../components/SideBar';
import { ButtonContainer, SideBarContainer } from './style';
import { useGlobal } from '../../hooks/useGlobal';

const BodyComponent = ({ tab = 'users' }) => {
  switch (tab) {
    case 'users':
      return <UsersTab />;
    case 'upload':
      return <Upload />;
    case 'config':
      return <ConfigTab />;
    default:
      return <UsersTab />;
  }
};

export default function Administration() {
  const { t } = useTranslation('pages');
  const [isOpen, setIsOpen] = useState(true);
  const [componentSelected, setComponentSelected] = useState('users');
  const { sideMenuIsOpen } = useGlobal();

  return (
    <SelectedUsersProvider>
      <MainNavBar />
      <div className={sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'}>
        <SideBarContainer>
          <SideBar isOpen={isOpen} setIsOpen={setIsOpen}>
            <>
              <ButtonContainer className="my-2 border-bottom">
                <img src={ICONS.team} alt="" />
                <button type="button" className="w-100 mx-1 border-0 bg-transparent text-start" onClick={() => setComponentSelected('users')}>
                  {t('Administration.tabs.user.label')}
                </button>
              </ButtonContainer>
              <ButtonContainer className="my-2 border-bottom">
                <img src={ICONS.fileUpload} alt="" />
                <button type="button" className="w-100 mx-1 border-0 bg-transparent text-start" onClick={() => setComponentSelected('upload')}>
                  {t('Administration.tabs.upload.label')}
                </button>
              </ButtonContainer>
              <ButtonContainer className="my-2">
                <img src={ICONS.iconCog} alt="" />
                <button type="button" className="w-100 mx-1 border-0 bg-transparent text-start" onClick={() => setComponentSelected('config')}>
                  {t('Administration.tabs.configs.label')}
                </button>
              </ButtonContainer>
            </>
          </SideBar>
        </SideBarContainer>
        <div className={isOpen ? 'margin-left-sidebar-is-open' : 'margin-left-sidebar-is-close'}>
          <BodyComponent tab={componentSelected} />
        </div>
      </div>
    </SelectedUsersProvider>
  );
}
