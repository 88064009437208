import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  Card,
  PlanCardHeader,
  BodyPlanCard,
  PlanTitle,
  PlanPrice,
  ProductInfo,
  Text,
  PlanButton,
  ContentPlanIcon,
  ContentPlanBox,
  ComingSoon,
} from './style';
import icons from '../../assets/icons';

interface SpaceSelectorProps {
  planName: string
  planPrice: string
  planCurrency: string,
  stars: number,
  btnText: string,
  featuresLimited: Array<{name: string, limit: string, unlimited: boolean}>,
  features: Array<{name: string, exists: boolean}>,
}

function ShowStars({ stars = 0 }) {
  return (
    <div>
      {[...Array(stars)].map(() => <img key={stars} src={icons.star} className="me-1" alt="" />)}
    </div>
  );
}

function ShowInfo({ value = '', label = '', unlimited = false }) {
  return (
    <div className="d-none mt-2">
      <ProductInfo id="plan-info">
        {unlimited
          ? <span>&#8734;</span>
          : <span>{value}</span>}
      </ProductInfo>
      <Text>
        {label}
      </Text>
    </div>
  );
}

const PlanCard: FC<SpaceSelectorProps> = ({
  planName,
  planPrice,
  planCurrency,
  stars,
  btnText,
  features,
  featuresLimited,
}) => {
  const { t } = useTranslation('pages');
  const history = useHistory();

  const PriceInfo = `${planCurrency} ${planPrice}`;
  const isPremium = planName === 'Premium';
  const path = `/plans/${planName.toLowerCase()}`;

  return (
    <Card id="plan-card">
      <PlanCardHeader id="plan-card-header">
        <h5 className="card-title">{t('Plans.current_plan')}</h5>
      </PlanCardHeader>
      <BodyPlanCard>
        <PlanTitle id="plan-title">
          {t(planName)}
          <ShowStars stars={stars} />
        </PlanTitle>
        <PlanPrice id="plan-price">
          {PriceInfo}
          <span>
            /
            {t('Plans.month')}
          </span>
        </PlanPrice>
        {featuresLimited.map((limits) => (
          <ShowInfo
            value={limits.limit}
            label={limits.name}
            unlimited={limits.unlimited}
          />
        ))}
        <PlanButton isTheCurrent={isPremium} id="plan-confirm-btn" onClick={() => history.push(path)}>
          {btnText}
          <img src={icons.check} alt="" />
        </PlanButton>

        {features.map((value) => (
          <ContentPlanBox>
            <ContentPlanIcon>
              {value.exists && <img src={icons.check} alt="" /> }
            </ContentPlanIcon>
            {t(value.name)}
            {' '}
            {!value.exists && <ComingSoon>Em breve</ComingSoon>}
          </ContentPlanBox>
        ))}
      </BodyPlanCard>
    </Card>
  );
};

export default PlanCard;
