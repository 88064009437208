import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import useClickOutside from 'src/hooks/useClickOutside';
import { CSSProperties } from 'styled-components';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { BiInfoCircle } from 'react-icons/bi';
import {
  Container,
  Row,
  EmptyContainer,
  EmptyText,
  EmptyContent,
} from './style';
import Loader from '../Loader';
import COLORS from '../../contracts/constants/colors.constants';

interface LightTableProps {
  header?: React.ReactNode[][];
  body: React.ReactNode[][];
  onClickRow?: Function;
  onClickOutside?: Function;
  selectedRow?: number | null;
  isLoading?: boolean;
  striped?: boolean;
  layout?: string;
  grid?: boolean;
  iconled?: boolean;
  align?: 'right' | 'left' | 'center' | 'justify' | 'start';
  styleRow?: CSSProperties;
  headersWithTooltip?: React.ReactNode[];
}

const sizeIcon = 18;
const IconTooltipStyle = {
  cursor: 'pointer',
  marginLeft: '10px',
};

function CustomHeader(index: number, headColumn: any, headersWithTooltip?: any | null) {
  const isNecessaryShowTooltipInHeader = headersWithTooltip !== undefined
  && headersWithTooltip[index] !== undefined
  && headersWithTooltip[index] !== null;

  if (isNecessaryShowTooltipInHeader) {
    const id = `info-${index}`;

    return (
      <th>
        {headColumn}
        <BiInfoCircle
          size={sizeIcon}
          color={COLORS.mutedColor}
          id={id}
          style={IconTooltipStyle}
        />
        <UncontrolledPopover
          flip
          target={id}
          trigger="hover"
        >
          <PopoverBody>{headersWithTooltip[index]}</PopoverBody>
        </UncontrolledPopover>
      </th>
    );
  }

  return (
    <th>
      {headColumn}
    </th>
  );
};

const LightTable: React.FC<LightTableProps> = ({
  header,
  body,
  onClickRow = () => {},
  onClickOutside = () => {},
  selectedRow = null,
  isLoading = false,
  striped,
  layout,
  grid,
  iconled,
  align,
  styleRow,
  headersWithTooltip,
}) => {
  const { t } = useTranslation('components');
  const tableRef = useRef(null);
  useClickOutside(tableRef, () => {
    onClickOutside(true);
  });

  return !isLoading ? (
    <Container
      ref={tableRef}
      layout={layout}
      grid={grid}
      iconled={iconled}
      striped={striped}
      align={align}
    >
      {header && (
        <thead>
          {header.map((headRow) => (
            <tr>
              {headRow.map((headColumn, key) => CustomHeader(key, headColumn, headersWithTooltip))}
            </tr>
          ))}
        </thead>
      )}
      <tbody>
        {body.map((bodyRow, index) => (
          <Row
            selected={index === selectedRow}
            align={align}
            onClick={() => onClickRow(index)}
          >
            {bodyRow.map((bodyColum) => (
              <td style={styleRow}>{bodyColum}</td>
            ))}
          </Row>
        ))}

        {!body.length && (
          <EmptyContainer>
            <EmptyContent>
              <EmptyText>{t('LightTable.empty_message')}</EmptyText>
            </EmptyContent>
          </EmptyContainer>
        )}
      </tbody>
    </Container>
  ) : (
    <Loader />
  );
};

export default LightTable;
