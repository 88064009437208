import styled from 'styled-components';
import colors from '../../colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 150px;
`;
export const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 10px 0 10px 10px;
  border-radius: 9px;
`;

export const IconAndTextContainer = styled.div`
  display: flex;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    @media only screen and (max-width: 768px) {
      font-size: 12px !important;
    }
    color: #626363;
  }
  img {
    margin-right: 15px;
    width: 25px;
    height: 25px;
  }
`;

export const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 8px;
  background: ${colors.blue};
  border: 1px;
  border-radius: 10px;

  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  
  color: #FFFFFF;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-right: 10px;
`;

export const Line = styled.div`
  margin-top: 15px;
  border: 1px solid #ABB0B3;
  margin-bottom: 15px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  margin: auto;
  margin-left: 0;
  span {
    margin-left: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    color: #626363;
  }
`;

export const ProfilePicture = styled.div`
  height: 45px;
  width: 45px;
  margin-right: 10px;
  img {
    border-radius: 50%;
    display: block;
    height: 45px;
    width: 45px;
  }
`;

export const ProfileNonImage = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 100px;
  background-color: ${colors.gray85};
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${colors.white};
  font-size: 15px;
`;

export const ButtonInvite = styled.button`
  padding: 6px 12px;
  gap: 8px;
  background: #000B4C;
  margin: auto;
  color: #FFFFFF;
  border: 0;
  border-radius: 4px;
  img {
    margin-left: 10px;
    filter: invert(100%) sepia(94%) saturate(0%) hue-rotate(170deg) brightness(105%) contrast(100%);
  }
`;

export const IconsRounded = styled.div`
  display: flex;
  margin: auto;
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  span {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    @media only screen and (max-width: 768px) {
      font-size: 12px !important;
    }

    color: #7A7E80;
  }
  button {
    img {
      padding: 8px;
      border-radius: 50%;
      margin-right: 15px;
      width: 30px;
      height: 30px;
      background: #000B4C;
    } 
  }
`;
