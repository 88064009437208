/* eslint-disable camelcase */
import keysToSnakeCase from 'src/utils/keysToSnakeCase';
import Api from './api';

export default class ShiftService {
  static DEFAULT_PATH = 'shifts';

  static async saveShift(payload: {
    placement_id: number;
    employee_id: number;
    seat_id?: number | null;
    rule_id?: number | null;
    allocated_at: string;
    start: string;
    end: string;
    team_id?: number
  }) {
    return Api.post(this.DEFAULT_PATH, payload);
  }

  static async saveShiftWithManyEmployees({
    placementId,
    employees,
    seatId,
    ruleId,
    allocatedAt,
  }: {
    placementId: number;
    employees: { id: number, start: string, end: string }[];
    seatId?: number | null;
    ruleId?: number | null;
    allocatedAt: string;
  }) {
    return Api.post('shifts/many-employees', {
      placement_id: placementId,
      employees,
      seat_id: seatId,
      rule_id: ruleId,
      allocated_at: allocatedAt,
    });
  }

  static async deleteShift(payload: { employee_id: number, shift_id: number, team_id: number }) {
    return Api.delete('shifts', { data: payload });
  }

  public static async removeEmployeeFromShift(shiftId: number, payload: {shiftEmployeeId: number}) {
    return Api.delete(`${this.DEFAULT_PATH}/${shiftId}/employees`, {
      data: keysToSnakeCase(payload),
    });
  }

  static async announcementBatch(payload: {
    week_end: string;
    week_start: string;
    teams_id?: string;
    placement_id: number;
    notification_type?: string;
  }) {
    const response = await Api.post('announcements/batch', payload);
    return response;
  }

  static async deleteAnnouncement(annoucementId: number, teams: string) {
    const response = await Api.delete('announcements', {
      data: {
        teams_id: teams,
        announcement_id: annoucementId,
      },
    });
    return response;
  }

  public static async deleteShiftWithAllEmployees(shiftId: number) {
    return Api.delete(`${this.DEFAULT_PATH}/delete/${shiftId}`);
  }

  public static async getShiftDetailsAdmin(shiftId: number) {
    return Api.get(`${this.DEFAULT_PATH}/${shiftId}/details/admin`);
  }

  public static async editShiftEmployees(
    shiftId: number,
    payload: {
      employees: {
        id: number;
        teamId: number;
        start: string;
        end: string;
        action: 'ADD' | 'EDIT';
      }[];
    },
  ) {
    return Api.post(
      `${this.DEFAULT_PATH}/${shiftId}/employees`,
      keysToSnakeCase(payload),
    );
  }

  public static async batchDeleteShifts(payload: {
    shifts: {
      id: number;
      teamId: number;
    }[];
    placementId: number;
  }) {
    return Api.post(`${this.DEFAULT_PATH}/batch/delete`, keysToSnakeCase(payload));
  }

  public static async batchCreateShifts(payload: {
    placementId: number,
    shifts: {
      employeeId: number;
      teamId: number;
      seatId: number | null;
      start: string;
      end: string;
      allocatedAt: string;
    }[]
  }) {
    return Api.post(`${this.DEFAULT_PATH}/batch/create`, keysToSnakeCase(payload));
  }

  public static async getMyShiftsAvailable() {
    return Api.get(`${this.DEFAULT_PATH}/my/available`);
  }
}
