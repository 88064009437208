import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import LogoSvg from 'src/assets/imgs/Logo_Espaços_Login.svg';
import Input from 'src/components/InputWithRef';
import AuthService from 'src/services/AuthService';
import { useToast } from 'src/hooks/toast';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'src/components/ButtonLoader';
import {
  Container,
  RowContainer,
  LoginContainer,
  LoginContent,
  LogoContainer,
  Logo,
  SuccessText,
  InfoText,
  ButtonContainer,
  GoBackLoginContainer,
  GoBackLogin,
  BackgroundContainer,
} from './style';

interface IForgotPasswordForm {
  email: string
}

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation('pages');
  const { register, errors, handleSubmit } = useForm<FormData>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState(null);

  const onSubmit = (credentials: IForgotPasswordForm) => {
    setIsLoading(true);
    AuthService.forgotPassword(credentials).then((response) => {
      if (response.data) {
        setText(response.data.message);
        setIsLoading(false);
      }
    }).catch(() => {
      setIsLoading(false);
      addToast({
        description: t('ForgotPassword.forgot_error'),
        type: 'error',
      });
    });
  };

  return (
    <Container>
      <RowContainer>
        <BackgroundContainer />
        <LoginContainer>
          <LoginContent>
            <LogoContainer>
              <Logo src={LogoSvg} />
            </LogoContainer>
            {text ? <SuccessText>{text}</SuccessText>
              : <InfoText>{t('ForgotPassword.info_text')}</InfoText>}
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                error={errors}
                errorMsg={t('ForgotPassword.email_error')}
                name="email"
                label={t('ForgotPassword.email_label')}
                required
                register={register}
              />
              <GoBackLoginContainer>
                <Link to="/login">
                  <GoBackLogin>{t('ForgotPassword.go_back_login')}</GoBackLogin>
                </Link>
              </GoBackLoginContainer>
              <ButtonContainer>
                <ButtonLoader
                  label={t('ForgotPassword.redefine_password')}
                  isLoading={isLoading}
                  style={{ width: '100%' }}
                />
              </ButtonContainer>
            </form>
          </LoginContent>
        </LoginContainer>
      </RowContainer>
    </Container>
  );
};

export default ForgotPassword;
