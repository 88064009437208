import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 3rem!important;
`;

export const Content = styled.div`
   margin-bottom: 20px;
   display: flex;
   flex-direction: column;
`;

export const ButtomContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const Forms = styled.form`
`;

export const FormChild = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const InputLabel = styled.span`
  color: #727789;
  font: normal normal 600 12px/19px lato;
  max-width: 100px;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 35px;
  color: var(--primary-gray);
  text-align: left;
  padding-left: 10px;
  border: 1px solid #e8e8e8;

    :invalid {
        box-shadow: 0 0 0.5em red;
        border-color: red;
    }
`;
