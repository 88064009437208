import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import { DEFAULT_END_TIME } from '../../contracts/constants/times.constants';
import Employee from '../../contracts/models/Employee';
import OpenDay from '../../contracts/models/OpenDay';
import Placement from '../../contracts/models/Placement';
import Shift from '../../contracts/models/Shift';
import useDays from '../../hooks/useDays';
import useMonths from '../../hooks/useMonths';

export type Journey = {
  days: number;
  rest: number;
};

type ModalReservationEditContextType = {
  reservationInterval: string;
  setReservationInterval?: any;
  selectedWeekDays: number[];
  setSelectedWeekDays?: any;
  viewedMonth: Date;
  setViewedMonth?: any;
  totalWeeksInputRef?: React.MutableRefObject<HTMLInputElement | null>;
  frequency: number;
  setFrequency?: any;
  months: {
    id: number;
    name: string;
  }[];
  days: {
    id: number;
    name: string;
  }[];
  daysOnlyFirstLetter: {
    name: string;
    id: number;
  }[];
  formHandler?: UseFormMethods<Record<string, any>>;
  dates: Date[];
  setDates?: any;
  payload: {
    [x: string]: any;
    journeys: Journey[];
    createWithExceptions: boolean;
    employees:
      | {
          id: string | null;
          start: string;
          end: string;
          name?: string;
          teamId: number | null;
          teamName?: string;
          added?: boolean;
          edited?: boolean;
          shiftEmployeesId?: number;
          key: string
        }[]
      | null;
  };
  hasChanged?:boolean
  setPayload?: any;
  placement?: Placement;
  fieldErrors?: string[];
  setFieldErrors?: any;
  clearPayload?: () => void
};

const ModalReservationEditContext = createContext<ModalReservationEditContextType>(
  {
    days: [],
    daysOnlyFirstLetter: [],
    frequency: 0,
    months: [],
    reservationInterval: '0',
    selectedWeekDays: [],
    viewedMonth: new Date(),
    dates: [],
    payload: {
      journeys: [],
      createWithExceptions: false,
      employees: [],
    },
    hasChanged: false,
  },
);

export const useModalReservationEditContext = () => {
  const context = useContext(ModalReservationEditContext);
  return context;
};

type ModalReservationEditContextProviderProps = {
  placement?: Placement;
  employee?:Employee[];
  shift: Shift | null;
  dayName?: string;
  type?: 'edit' | 'create';
  openDay?: OpenDay
};
const ModalReservationEditContextProvider: React.FC<ModalReservationEditContextProviderProps> = ({
  children,
  employee,
  shift,
  openDay,
}) => {
  const [reservationInterval, setReservationInterval] = useState('0');
  const [selectedWeekDays, setSelectedWeekDays] = useState<number[]>([]);
  const [viewedMonth, setViewedMonth] = useState(new Date());
  const totalWeeksInputRef = useRef<HTMLInputElement | null>(null);
  const [frequency, setFrequency] = useState(0);
  const [dates, setDates] = useState<Date[]>([]);
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const months = useMonths({ capitalize: true });
  const days = useDays({ capitalize: true });
  const daysOnlyFirstLetter = useDays({
    capitalize: true,
    onlyFirstLetter: true,
  });
  const [payload, setPayload] = useState<{
    [x: string]: any;
    journeys: {
      days: number;
      rest: number;
    }[];
    createWithExceptions: boolean;
    employees: {
       id: string | null;
       start: string;
       end: string,
       teamId: number | null,
       edited?: boolean,
       added?: boolean;
       shiftEmployeesId?: number;
       key: string
    }[] | null;
    hasChanged?:boolean
  }>({
    journeys: [
      {
        days: 1,
        rest: 1,
      },
    ],
    createWithExceptions: false,
    duration: 'forever',
    employees: employee?.length ? [{
      ...employee[0],
      start: openDay?.timeStart,
      end: openDay?.timeEnd,
    }] : [
      {
        id: null,
        start: openDay?.timeStart,
        end: openDay?.timeEnd || DEFAULT_END_TIME,
        teamId: null,
      },
    ] as any,
    frequency: 'not_repeat',
    datetimeStart: shift?.allocatedAt,
    hasChanged: false,
  });

  function clearPayload() {
    setPayload({
      journeys: [
        {
          days: 1,
          rest: 1,
        },
      ],
      createWithExceptions: false,
      employeeId: employee?.[0].id || null,
      frequency: 'not_repeat',
      duration: 'forever',
      employees: [
        {
          id: null,
          start: openDay?.timeStart as string,
          end: openDay?.timeEnd || DEFAULT_END_TIME,
          teamId: null,
          key: String(Math.random()),
        },
      ],
      errors: [],
    });
  }

  useEffect(() => {
    if (!employee?.length) return;
    setPayload((prev) => ({
      ...prev,
      datetimeStart: shift?.allocatedAt,
      employees: [
        {
          start: openDay?.timeStart as string,
          end: openDay?.timeEnd || DEFAULT_END_TIME,
          id: `${employee[0].id}_${employee[0].teamId}`,
          teamId: null,
          key: String(Math.random()),
        },
      ],
    }));
  }, [employee, shift, openDay]);
  const formHandler = useForm();
  return (
    <ModalReservationEditContext.Provider
      value={{
        reservationInterval,
        setReservationInterval,
        selectedWeekDays,
        setSelectedWeekDays,
        viewedMonth,
        setViewedMonth,
        totalWeeksInputRef,
        frequency,
        setFrequency,
        months,
        days,
        daysOnlyFirstLetter,
        formHandler,
        dates,
        setDates,
        payload,
        setPayload,
        fieldErrors: fieldErrors as any,
        setFieldErrors,
        clearPayload,
      }}
    >
      {children}
    </ModalReservationEditContext.Provider>
  );
};
export default ModalReservationEditContextProvider;
