import React, {
  useEffect, useRef, useState, useMemo,
} from 'react';
import {
  Circle,
  Text,
  Group,
  Line,
} from 'react-konva';
import Konva from 'konva';
import SeatModel from 'src/contracts/models/Seats';
import getNameInitials from 'src/utils/getNameInitials';
import { calcFontSize, calcRadius } from 'src/utils/canvasFunctions';
import RoundedImage from './RoundedImage';
import colors from '../../colors';

interface SeatCellCanvasProps {
  seat: SeatModel;
  index: number;
  scale: number;
  selected: boolean;
  radius: number;
  onClick?: Function;
  onDragMarker?: (isDragging: boolean) => void;
  onDragMarkerEnd?: (event: Konva.KonvaEventObject<DragEvent>) => void;
}

const SeatCellCanvas: React.FC<SeatCellCanvasProps> = ({
  seat, selected, index, onClick, scale, radius,
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [image, setImage] = useState<any>(null);
  const [text, setText] = useState<string>('');

  const handleLoad = () => {
    setImage(imageRef.current);
  };

  const loadImage = () => {
    if (seat.employees?.length === 1) {
      if (seat.employees[0].photo) {
        const img = new window.Image();
        img.src = seat.employees[0].photo!;
        img.width = 30;
        img.height = 30;
        imageRef.current = img;
        imageRef.current.addEventListener('load', handleLoad);
      }
      setText(getNameInitials(seat.employees[0].name).toLocaleUpperCase());
    }
    if (seat.employees?.length === 0) {
      setText((index + 1).toString());
    }
    if (seat?.employees?.length! > 1) {
      setText(`${seat.employees?.length}`);
    }
  };

  useEffect(() => {
    loadImage();
    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleLoad);
      }
    };
  }, []);

  const getBackColor = () => (seat.employees?.length === 0 ? `${colors.white}` : `${colors.blue20}`);

  const calculedRadius = useMemo(() => calcRadius(radius, scale), [scale, radius]);
  const calculateHeightAndWidth = useMemo(() => {
    const calc = ((calculedRadius * 2) - 20);

    if (calc < 0) {
      return calc * -1;
    }

    return calc;
  }, [calculedRadius]);

  return (
    <Group
      x={seat.position?.positionX}
      y={seat.position?.positionY}
      onClick={() => onClick?.()}
    >
      <Circle
        key={seat.id}
        radius={calculedRadius}
        numPoints={5}
        innerRadius={20}
        outerRadius={40}
        fill={selected ? `${colors.blue30}` : `${colors.blue15}`}
        strokeWidth={2}
        stroke={selected ? `${colors.blue15}` : `${colors.blue30}`}
      />
      <Circle
        radius={calculedRadius}
        width={calculateHeightAndWidth}
        height={calculateHeightAndWidth}
        fill={selected ? `${colors.blue35}` : getBackColor()}
      />

      {seat?.employees?.length! > 1
        ? (
          <>
            <Text
              x={(calculedRadius / 2) * -1}
              y={(calculedRadius / 2) * -1}
              text="Frac."
              width={calculedRadius}
              align="center"
              fontSize={calcFontSize(12, scale)}
              fontStyle="bold"
              fill={selected ? `${colors.white}` : `${colors.blue30}`}
            />
            <Line
              x={calculedRadius / 10}
              y={calculedRadius / 3}
              points={[
                calculedRadius / 1.5,
                (calculedRadius / 3) * -1,
                (calculedRadius / 1.2) * -1,
                (calculedRadius / 3) * -1,
              ]}
              tension={0.5}
              closed
              fill={selected ? `${colors.white}` : `${colors.blue30}`}
              fillLinearGradientStartPoint={{ x: -50, y: -50 }}
              fillLinearGradientEndPoint={{ x: 50, y: 50 }}
              fillLinearGradientColorStops={[0, 'red', 1, 'yellow']}
            />
            <Text
              x={(calculedRadius / 2) * -1}
              y={calculedRadius / 5}
              text={text}
              width={calculedRadius}
              align="center"
              fontSize={calcFontSize(calculedRadius / 2, scale)}
              fontStyle="bold"
              fill={selected ? `${colors.white}` : `${colors.blue30}`}
            />
          </>
        )
        : (
          <Text
            x={(calculedRadius / 2) * -1}
            y={(calculedRadius / 4) * -1}
            text={text}
            width={calculedRadius}
            align="center"
            fontSize={calcFontSize(calculedRadius / 3, scale)}
            fontStyle="bold"
            fill={selected ? `${colors.white}` : `${colors.blue30}`}
          />
        )}
      {image
        && (
          <RoundedImage
            x={(((calculedRadius * 2) - 20) / 2) * -1}
            y={(((calculedRadius * 2) - 20) / 2) * -1}
            radius={calculedRadius}
            width={(calculedRadius * 2) - 20}
            height={(calculedRadius * 2) - 20}
            image={image}
          />
        )}
    </Group>
  );
};

export default SeatCellCanvas;
