/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect, { Props } from 'react-select';
import colors from 'src/colors';

import Container from './style';

interface SelectProps extends Props<{ value: string | number; label: string }, boolean> {
  label?: React.ReactNode;
  width?: string;
  hasError?:string;
  required?:boolean;
  styleSelect?: CSSProperties,
  stylesControl?: CSSProperties,
  styleLabel?: CSSProperties
}

const Select = ({
  label, id, width, hasError, styleSelect, stylesControl, styleLabel, ...propsRest
}: SelectProps) => {
  const { t } = useTranslation('components');
  return (
    <Container style={styleSelect}>
      {label && <label style={styleLabel} htmlFor={id}>{`${label} ${propsRest.required ? '*' : ''}`}</label>}
      <ReactSelect
        id={id}
        noOptionsMessage={() => t('Select.no_options')}
        menuPosition="fixed"
        styles={{
          control: (base) => ({
            ...base,
            width,
            position: 'relative',
            height: '40px',
            maxHeight: '85px',
            overflowY: 'scroll',
            background: !propsRest.disabled ? '#FFFFFF 0% 0% no-repeat padding-box' : `${colors.white10} 0% 0% no-repeat padding-box`,
            border: `1px solid ${hasError ? '#da1f50' : '#0000001F'}`,
            boxShadow: hasError ? '0 0 4px #da1f50' : 'none',
            borderRadius: ' 4px',
            ...stylesControl,
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        placeholder={propsRest.placeholder ?? t('Select.select')}
        {...propsRest}
      />
    </Container>
  );
};

export default Select;
