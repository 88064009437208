/* eslint-disable react/require-default-props */
import React from 'react';
import Icons from '../../assets/icons';
import { InputBox, Input, Icon } from './style';

interface CustomSearchInputPorps{
    placeholder: string,
    width?: string;
    height?: string;
    borderColor?: string;
    defaultValue?: string;
    onChange?: (value: string) => void,
}

function CustomSearchInput({
  placeholder, defaultValue, onChange, width, height, borderColor,
}: CustomSearchInputPorps) {
  return (
    <InputBox width={width} height={height} borderColor={borderColor}>
      <Icon src={Icons.SearchIcon} />
      <Input
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </InputBox>
  );
}

export default CustomSearchInput;
