import styled from 'styled-components';
import colors from '../../../../../colors';

export const Header = styled.div`
  
`;

export const DownloadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  margin-bottom: 50px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem 2rem;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-family: lato;
  color: ${colors.gray};
  text-align: center;
`;

export const TableLabel = styled.h6`
  font-weight: bold;
  font-family: lato;
  color: ${colors.gray};
  text-align: left;
`;

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
`;
