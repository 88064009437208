/* eslint-disable import/no-duplicates */
import React, {
  useContext, useEffect, useMemo, useState, useRef,
} from 'react';
import ButtonLoader from 'src/components/ButtonLoader';
import SingleDatePicker from 'src/components/SingleDatePicker';
import SeatsModel from 'src/contracts/models/Seats';
import EmployeeModel from 'src/contracts/models/Employee';
import { useState as HookUseState } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import icons from 'src/assets/icons';
import { mixpanelTrack } from 'src/services/MixPanelService';
import PlacementService from 'src/services/PlacementService';
import { homeContext } from 'src/pages/Home/Context';
import { format, getWeek } from 'date-fns';
import pt from 'date-fns/locale/pt';
import Spinner from 'react-bootstrap/Spinner';
import { BsArrowLeft } from 'react-icons/bs';
import { ReactZoomPanPinchRef } from 'react-zoom-pan-pinch';
import Canvas from './Canvas';
import DetailsModal from './DetailsModal';
import {
  Title,
  ToolContainer,
  ToolExpandContainer,
  ModalStyled,
  ModalBodyStyled,
  ModalHeaderStyled,
  PositionsContent,
  List,
  ListHeader,
  ListBody,
  SideButtonToggler,
  TransformWrapperStyled,
  TransformComponentStyled,
  SeatItem,
  LabelContainer,
} from './style';
import MapViewProvider, { ModalMapViewContext } from './Context';

export interface ModalMapViewProps {
  isOpen?: boolean;
  title: string;
  handleCloseButton?: () => void;
}
const ModalMapView: React.FC<ModalMapViewProps> = ({
  isOpen,
  title,
  handleCloseButton,
}) => {
  const zoomRef = useRef<ReactZoomPanPinchRef>(null);
  const { selectedSeat, setSelectedSeat } = useContext(ModalMapViewContext);
  const { placement, week } = useContext(homeContext);
  const getInitialDay = () => {
    if (getWeek(new Date()) === getWeek(week)) {
      return new Date();
    }
    return week;
  };
  const { t } = useTranslation('pages');
  const isLoading = HookUseState<boolean>(false);
  const cursorType = HookUseState<string>('default');
  const sideOpen = HookUseState<boolean>(true);
  const seats = HookUseState<SeatsModel[]>([]);
  const isDragMarker = HookUseState<boolean>(false);
  const employeesSeat = HookUseState<any>({});
  const coordinates = HookUseState<any>({});
  const link = HookUseState<string>('');
  const [date, setDate] = useState<Date>(getInitialDay());

  const formatStringDate = (
    dateUnformated: Date,
    pattern: string = 'dd/MM/yyyy',
  ) => {
    const dateString = format(dateUnformated, pattern, {
      locale: pt,
    });
    return dateString;
  };

  useEffect(() => {
    const loadData = async () => {
      isLoading.set(true);
      try {
        const response = await PlacementService.getMapViewInfo({
          placementId: placement?.id!,
          date: formatStringDate(date, 'yyyy-MM-dd'),
        });
        seats.set(Object.values(response.data.seats));
        employeesSeat.set(response.data.employees || {});
        coordinates.set(response.data.coordinates);
        link.set(response.data.map);
        isLoading.set(false);
      } catch (error) {
        isLoading.set(false);
      }
    };
    loadData();
  }, [placement, date]);

  const formatedSeats = useMemo(
    () => seats.get().map((seat) => {
      const position = coordinates.get()[seat.id];
      const employees = Array.isArray(employeesSeat.get())
        ? []
        : (Object.values(
          employeesSeat.get()[seat.id] || {},
        ) as EmployeeModel[]);
      const newSeat: SeatsModel = {
        ...seat,
        position,
        employees,
      };
      return newSeat;
    }),
    [seats.get(), employeesSeat.get(), coordinates.get()],
  );

  const formatLegend = (quant: number) => (quant === 1
    ? `${quant} ${t('AddLocation.tabs.AddPosition.employee')}`
    : `${quant} ${t('AddLocation.tabs.AddPosition.employees')}`);

  return (
    <ModalStyled
      isOpen={isOpen}
      onClosed={() => {
        isLoading.set(false);
        handleCloseButton?.();
      }}
      className="modal-fullscreen"
    >
      <ModalHeaderStyled>
        <Title onClick={handleCloseButton}>
          <BsArrowLeft color="#1075CE" />
          {title}
        </Title>
        <SingleDatePicker
          showArrows
          onChange={(newDate: Date) => {
            setSelectedSeat?.(null);
            setDate(newDate);
            mixpanelTrack('map@view-date-change', null);
          }}
          value={date}
          initialValue={formatStringDate(date)}
        />
        <ButtonLoader
          transparent
          withHover={false}
          icon={icons.close}
          iconSize={18}
          onClick={handleCloseButton}
        />
      </ModalHeaderStyled>
      <ModalBodyStyled>
        {isLoading.get() ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              padding: '2%',
            }}
          >
            <Spinner
              color="primary"
              style={{ width: '3rem', height: '3rem' }}
              size="sm"
              animation="border"
            />
          </div>
        ) : (
          <TransformWrapperStyled
            velocityAnimation={{
              animationType: 'easeInCubic',
              disabled: true,
            }}
            alignmentAnimation={{
              disabled: true,
            }}
            centerOnInit
            limitToBounds
            centerZoomedOut
            minScale={0.2}
            maxScale={20}
            initialScale={0.8}
            wheel={{
              step: 0.2,
              excluded: [],
            }}
            panning={{
              disabled: isDragMarker.get(),
              excluded: [],
            }}
            doubleClick={{
              disabled: true,
            }}
            pinch={{
              step: 5,
            }}
            onPanningStart={() => cursorType.set('grabbing')}
            onPanningStop={() => cursorType.set('default')}
            ref={zoomRef}
          >
            {() => (
              <>
                <ToolContainer expanded={!!sideOpen.get()}>
                  <ButtonLoader
                    transparent
                    withHover={false}
                    icon={icons.Minus}
                    style={{ marginRight: 10 }}
                    onClick={() => zoomRef.current?.zoomOut(1, 200, 'easeInCubic')}
                    iconSize={12}
                  />
                  <ButtonLoader
                    transparent
                    withHover={false}
                    disabled
                    icon={icons.ZoomIn}
                    style={{ marginRight: 10 }}
                    iconSize={16}
                  />
                  <ButtonLoader
                    transparent
                    withHover={false}
                    icon={icons.Plus}
                    style={{ marginRight: 10 }}
                    onClick={() => zoomRef.current?.zoomIn(1, 200, 'easeInCubic')}
                    iconSize={12}
                  />
                </ToolContainer>
                {zoomRef.current?.state?.scale! !== 1 && (
                  <ToolExpandContainer expanded={!!sideOpen.get()}>
                    <ButtonLoader
                      transparent
                      withHover={false}
                      icon={icons.FitScreen}
                      onClick={() => zoomRef.current?.centerView()}
                      iconSize={12}
                    />
                  </ToolExpandContainer>
                )}
                <TransformComponentStyled>
                  <div
                    style={{
                      cursor: cursorType.get(),
                      width: '100%',
                      height: '100%',
                      zIndex: -1,
                    }}
                  >
                    {!!formatedSeats.length && (
                      <Canvas
                        src={link.get()}
                        seats={formatedSeats}
                        scale={zoomRef.current?.state?.scale || 1}
                      />
                    )}
                  </div>
                </TransformComponentStyled>
              </>
            )}
          </TransformWrapperStyled>
        )}
        <SideButtonToggler active={sideOpen.get()}>
          <ButtonLoader
            color="#fff"
            bordered
            iconSize={26}
            icon={icons.CollapseArrow}
            onClick={() => {
              sideOpen.set((prev) => !prev);
            }}
          />
        </SideButtonToggler>
        <DetailsModal />
        <PositionsContent active={sideOpen.get()} className="col-12">
          <List>
            <ListHeader>
              <div>{t('AddLocation.tabs.AddPosition.position')}</div>
              <div>{t('AddLocation.tabs.AddPosition.table_num')}</div>
              <div>{t('AddLocation.tabs.AddPosition.map_localization')}</div>
            </ListHeader>
            <ListBody>
              {isLoading.get() ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    padding: '2%',
                  }}
                >
                  <Spinner
                    color="primary"
                    style={{ width: '3rem', height: '3rem' }}
                    size="sm"
                    animation="border"
                  />
                </div>
              ) : (
                formatedSeats.map((seat: SeatsModel, index: number) => (
                  <SeatItem
                    selected={selectedSeat?.id === seat.id}
                    onClick={() => {
                      if (selectedSeat?.id === seat.id) {
                        setSelectedSeat?.(null);
                        return;
                      }
                      setSelectedSeat?.(seat);
                    }}
                  >
                    <div>{index + 1}</div>
                    <div>
                      <span>{seat.name}</span>
                      <LabelContainer color={placement?.color!}>
                        <span>{formatLegend(seat.employees?.length!)}</span>
                      </LabelContainer>
                    </div>
                    <ButtonLoader
                      transparent
                      withHover={false}
                      disabled
                      uppercase={false}
                      label={
                        seat.position?.positionX
                          ? t('AddLocation.tabs.AddPosition.localized')
                          : t('AddLocation.tabs.AddPosition.not_localized')
                      }
                      icon={
                        seat.position?.positionX
                          ? icons.PushPin
                          : icons.FeatherMap
                      }
                    />
                  </SeatItem>
                ))
              )}
            </ListBody>
          </List>
        </PositionsContent>
      </ModalBodyStyled>
    </ModalStyled>
  );
};

export default (props: ModalMapViewProps) => (
  <MapViewProvider>
    <ModalMapView {...props} />
  </MapViewProvider>
);
