import React from 'react';
import icons from 'src/assets/icons';
import { useTranslation } from 'react-i18next';
import { ActionButton } from './components';

export interface ActionButtonSectionProps {
  isFilteringByInactiveRole: boolean;
  showConfirmActionModal: () => void
}
const ActionButtonSection = ({
  isFilteringByInactiveRole,
  showConfirmActionModal,
}: ActionButtonSectionProps) => {
  const { t } = useTranslation('components');
  if (isFilteringByInactiveRole) {
    return (
      <ActionButton
        icon={icons.Restore}
        onClick={showConfirmActionModal}
      />
    );
  }

  return (
    <ActionButton
      icon={icons.TrashGray}
      onClick={showConfirmActionModal}
      tooltipTitle={t('ConfirmExcludeSelectedUsersModal.button')}
    />
  );
};

export default ActionButtonSection;
