import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import EmployeeService, { GetAllRoleData, Role } from 'src/services/EmployeeService';
import Pagination from 'src/components/Pagination';
import CustomSearchInput from 'src/components/CustomSearchInput';
import Button from 'src/components/Button';
import ModalAdminUser from 'src/components/ModalAdminUser';
import Employee from 'src/contracts/models/Employee';
import { formatEmployee } from 'src/utils/employee.functions';
import { useState as hookState } from '@hookstate/core';
import Loader from 'src/components/Loader';
import AdministrationService from 'src/services/AdministrationService';
import { AxiosError } from 'axios';
import { waitForCompletion } from 'src/utils/jobFunctions';
import ConfirmManageSelectedUsersModal from 'src/components/ConfirmManageSelectedUsersModal';
import { useLocation } from 'react-router-dom';
import {
  CenterDiv, Container, FloatLeft, FloatRight, HeaderContainer, Title,
} from './style';
import UserList from './components/UserList';
import { RoleFilterOption } from './components/user_table_header';
import AccessFilterSelector from '../../../../components/LoginFilter';
import colors from '../../../../colors';
import Option from '../../../../contracts/models/Option';
import { ManageDeleteOrRestoreUsersSection } from './components/user_table_header/components';
import useSelectedUsers from './hooks/useSelectedUsers';
import { getData, USER_STORAGE } from '../../../../services/LocalStorageService';

const convertRolesToArray = (roles: GetAllRoleData) => Object.values(roles);

const formatRolesFromRequestToFilterOptions = (
  roles: GetAllRoleData,
  listAllRolesFilterOptionName: string,
): RoleFilterOption[] => {
  const rolesToArray = convertRolesToArray(roles);

  const rolesToOptionsFormat = rolesToArray.map(
    (role) => ({
      label: role.name,
      value: role.name,
    }),
  );

  return [
    {
      label: listAllRolesFilterOptionName,
      value: listAllRolesFilterOptionName,
    },
    ...rolesToOptionsFormat,
  ];
};

enum JobStatus {
  ERROR = 'error',
  SUCCESS = 'success',
  PENDING = 'pending',
}

interface CurrentJobType {
  id: number;
  status: JobStatus;
}

interface GetRoleParamsToGetAllUsersRequest {
  typeRole?: string;
  role?: number;
}

const Users = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const newUser = searchParams.get('newUser');
  const { t } = useTranslation('pages');
  const listAllRolesFilterOptionName = t('Administration.tabs.user.all_roles_filter');

  const { addToast } = useToast();

  const [users, setUsers] = useState<Employee[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [
    isFilteringByInactiveRole,
    setIsFilteringByInactiveRole,
  ] = useState<boolean>(false);
  const [selectedUserRoleNameFilter, setSelectedUserRoleFilter] = useState<string>(
    listAllRolesFilterOptionName,
  );
  const [isLoadingSendInvite, setIsLoadingSetInvite] = useState<boolean>(false);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [currentJob, setCurrentJob] = useState<CurrentJobType | null>(null);
  const [userModal, setUserModal] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [paginationData, setPaginationData] = useState<any>(null);
  const [newlyCreatedEmployee, setNewlyCreatedEmployee] = useState<any>(false);
  const [allRolesFromRequest, setAllRolesFromRequest] = useState<GetAllRoleData>({});
  const [selectedFilter, setSelectedFilter] = useState<Option[]>([]);
  const [usersObject, setUsersObject] = useState<[{ page: number, users: number[] }]>(
    [{ page: 0, users: [] }],
  );

  const [
    isOpenConfirmDeleteSelectedEmployees,
    setIsOpenConfirmDeleteSelectedEmployees,
  ] = useState<boolean>(
    false,
  );
  const [
    isOpenConfirmRestoreSelectedEmployees,
    setIsOpenConfirmRestoreSelectedEmployees,
  ] = useState<boolean>(
    false,
  );

  const page = hookState<number>(1);
  const searchName = hookState<string>('');

  const [{ selectedUsers }, {
    addUser,
    resetList,
  }] = useSelectedUsers();

  const checkStatusFromIsFilteringByInactiveRole = () => (
    isFilteringByInactiveRole ? true : undefined);

  const rolesFilterOptions = useMemo(() => formatRolesFromRequestToFilterOptions(
    allRolesFromRequest,
    listAllRolesFilterOptionName,
  ), [allRolesFromRequest]);

  const foundSelectedUserRole = useMemo(() => convertRolesToArray(allRolesFromRequest).find(
    (roleElement) => roleElement.name === selectedUserRoleNameFilter,
  ), [selectedUserRoleNameFilter]);

  const getRoleParamsToGetAllUsersRequest = (
    selectedUserRole?: Role,
  ): GetRoleParamsToGetAllUsersRequest => {
    if (selectedUserRoleNameFilter === listAllRolesFilterOptionName) {
      return {
        typeRole: undefined,
        role: undefined,
      };
    }

    const typeRole = selectedUserRole?.type_role;
    const role = selectedUserRole?.code;

    return {
      typeRole,
      role,
    };
  };

  const getUsers = () => {
    const { role, typeRole } = getRoleParamsToGetAllUsersRequest(foundSelectedUserRole);
    setIsLoading(true);
    EmployeeService.getUsersAll(
      {
        page: page.get(),
        perPage: 10,
        searchName: searchName.get(),
        teamId: undefined,
        status: checkStatusFromIsFilteringByInactiveRole(),
        role,
        typeRole,
        access_filter: Object(selectedFilter).value,
      },
    )
      .then((res) => {
        setUsers(formatEmployee(res.data.data));
        setPaginationData({ ...res.data, data: [] });
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        addToast({
          description: t('Administration.tabs.user.error_load_message'),
          type: 'error',
        });
      });
  };

  const doExistsCurrentJob = (jobId: number) => {
    waitForCompletion(2, () => {
      setIsLoadingSetInvite(false);
      addToast({
        description: t('Administration.tabs.job.send_success'),
        type: 'success',
      });
    }, () => {
      setIsLoadingSetInvite(false);
      addToast({
        description: t('Administration.tabs.job.already_exists'),
        type: 'error',
      });
    }, jobId);
  };

  const setCurrentJobData = (jobId: number) => {
    const payload = {
      id: Number(jobId),
      status: JobStatus.PENDING,
    };
    setCurrentJob(payload);
  };

  const handleSendInvite = useCallback(async () => {
    setIsLoadingSetInvite(true);

    if (currentJob?.id) {
      doExistsCurrentJob(currentJob?.id);
      addToast({
        description: t('Administration.tabs.job.already_exists'),
        type: 'error',
      });
      return;
    }

    await AdministrationService.sendFirstAccessInvite()
      .then((resp) => {
        const { jobId } = resp.data;
        setCurrentJobData(jobId);
        doExistsCurrentJob(jobId);
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status === 409) {
          const jobId = error?.response?.data.jobId;
          doExistsCurrentJob(jobId);
          return;
        }
        setIsLoadingSetInvite(false);
        addToast({
          description: t('Administration.tabs.job.send_error'),
          type: 'error',
        });
      })
      .finally(() => setShowInviteModal(false));
  }, []);

  const handleChangeInactiveUsers = (isInactive: boolean) => {
    setIsFilteringByInactiveRole(isInactive);
    page.set(1);
  };

  const handleChangeUserRoleNameFilter = (name: string) => {
    setSelectedUserRoleFilter(name);
  };

  const handleAddAllUsers = () => {
    const pageData = paginationData.page;
    if (pageData) {
      resetList();
      const index = usersObject.findIndex(((value) => value?.page === pageData));
      const updateObject = usersObject;
      if (index === -1) {
        if (users) {
          const userIdList: number[] = [];
          users?.forEach((user) => {
            userIdList.push(Number(user.id));
          });
          updateObject.push({ page: pageData, users: userIdList });
          setUsersObject(updateObject);
        }
      }
      if (index > -1) {
        const countUsers = usersObject[index].users.length;
        let allSelected = true;
        usersObject[index].users.map((selectedIdMap) => {
          if (!selectedUsers.includes(selectedIdMap)) allSelected = false;
          return true;
        });
        if (countUsers === users?.length && allSelected) {
          updateObject.splice(index, 1);
          setUsersObject(updateObject);
        } else {
          users?.forEach((user) => {
            if (!updateObject[index].users.includes(user.id)) {
              updateObject[index].users.push(user.id);
            }
          });
          setUsersObject(updateObject);
        }
      }
      usersObject.map((value) => value.users.map((id) => addUser(id)));
    }
  };

  const handleDeleteSelectedEmployees = () => {
    const selectedUsersIdsToString = selectedUsers.toString();

    EmployeeService.deleteSelectedEmployees(selectedUsersIdsToString)
      .then(() => {
        getUsers();
        setIsOpenConfirmDeleteSelectedEmployees(false);
      })
      .catch(() => {
        setIsOpenConfirmDeleteSelectedEmployees(false);
        addToast({
          description: t('Administration.tabs.user.error_delete_selected_employees_message'),
          type: 'error',
        });
      }).finally(() => resetList());
  };

  const handleRestoreSelectedEmployees = () => {
    const selectedUsersIdsToString = selectedUsers.toString();

    EmployeeService.restoreSelectedEmployees(selectedUsersIdsToString)
      .then(() => {
        getUsers();
        setIsOpenConfirmRestoreSelectedEmployees(false);
      })
      .catch(() => {
        setIsOpenConfirmRestoreSelectedEmployees(false);
        addToast({
          description: t('Administration.tabs.user.error_restore_selected_employees_message'),
          type: 'error',
        });
      }).finally(() => resetList());
  };

  const showConfirmDeleteOrRestoreUsersModal = () => {
    if (isFilteringByInactiveRole) {
      setIsOpenConfirmRestoreSelectedEmployees(true);
      return;
    }

    setIsOpenConfirmDeleteSelectedEmployees(true);
  };

  const onChangeCustomSearchInput = (value: string) => {
    searchName.set(value);
  };

  useEffect(() => {
    const name = searchName.get();

    if (name.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        page.set(1);
        getUsers();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    getUsers();

    return () => false;
  }, [searchName.get()]);

  useEffect(() => {
    if (users) {
      getUsers();
    }
  }, [page.get(), isFilteringByInactiveRole, selectedUserRoleNameFilter, selectedFilter]);

  useEffect(() => {
    EmployeeService.getAllRole()
      .then((response) => setAllRolesFromRequest(response.data));
  }, []);

  useEffect(() => {
    if (newUser) {
      setUserModal(true);
    }
  }, []);

  if (!users || !paginationData) {
    return <Loader />;
  }

  const verifyUsers = () => {
    const userStorage = getData(USER_STORAGE);
    const employeesLimit = userStorage.employees_limit;
    if (userStorage) {
      if (users.length < employeesLimit
        || employeesLimit === null || employeesLimit === undefined) setUserModal(true);
      else {
        addToast({
          title: t('Administration.tabs.user.error_add_new_user_limit'),
          description: t('Administration.tabs.user.error_add_new_user_limit_description'),
          type: 'warning',
        });
      }
    }
  };

  const height = '49.25px';
  const width = '50% !important';

  return (
    <Container>
      <HeaderContainer className="clearfix">
        <FloatLeft>
          <Title>{t('Administration.tabs.user.title')}</Title>
        </FloatLeft>
        <FloatRight />
      </HeaderContainer>
      <div className="clearfix row">
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2 d-flex">
          <CustomSearchInput
            onChange={onChangeCustomSearchInput}
            placeholder={t('Administration.tabs.user.search_placeholder')}
            height={height}
            borderColor={colors.gray28}
            width={width}
          />
          <AccessFilterSelector
            onChange={(filter) => setSelectedFilter(filter)}
            value={selectedFilter}
            isClearable={false}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2 d-flex">
          <div className="w-50">
            <Button
              color="default"
              icon="Mail"
              label={t('Administration.tabs.user.send_invite')}
              onClick={() => setShowInviteModal(true)}
              isLoading={isLoadingSendInvite}
              disabled={isLoadingSendInvite}
              className="w-100"
            />
          </div>
          <div style={{ marginLeft: '15px' }} className="d-flex flex-fill">
            <Button
              color="primary"
              icon="GroupAdd"
              label={t('Administration.tabs.user.new_user')}
              onClick={() => verifyUsers()}
              className="w-100"
            />
          </div>
        </div>
      </div>

      <ManageDeleteOrRestoreUsersSection
        showConfirmActionModal={showConfirmDeleteOrRestoreUsersModal}
        isFilteringByInactiveRole={isFilteringByInactiveRole}
        actualItemsCount={users.length}
      />

      {isLoading ? (
        <CenterDiv>
          <Loader />
        </CenterDiv>
      ) : (
        <>
          <UserList
            users={users}
            isLoading={isLoading}
            isFilteringByInactiveRole={isFilteringByInactiveRole}
            handleChangeIsUserInactiveFilter={handleChangeInactiveUsers}
            onDelete={() => getUsers()}
            onRestore={() => getUsers()}
            onEdit={(id: number) => {
              setSelectedId(id);
              setUserModal(true);
            }}
            selectedUserRoleNameFilter={selectedUserRoleNameFilter}
            handleChangeUserRoleNameFilter={handleChangeUserRoleNameFilter}
            rolesFilterOptions={rolesFilterOptions}
            handleAddAllUsers={handleAddAllUsers}
          />
          <Pagination
            activePage={paginationData.page}
            itemsCountPerPage={Number(paginationData.perPage)}
            actualItemsCount={users.length}
            onPageChange={(newPage: number) => page.set(newPage)}
            totalItemsCount={paginationData.total}
          />
        </>
      )}

      <ModalAdminUser
        isOpen={userModal}
        employeeId={selectedId}
        handleSuccess={(
          createdEmployeeId: any,
          newlyCreatedEmployeeResult: boolean,
        ) => {
          getUsers();
          setSelectedId(createdEmployeeId);
          setNewlyCreatedEmployee(newlyCreatedEmployeeResult);
        }}
        handleCloseButton={() => {
          setUserModal(false);
          setSelectedId(null);
        }}
        newlyCreatedEmployee={newlyCreatedEmployee}
      />
      <ConfirmManageSelectedUsersModal
        isOpen={isOpenConfirmDeleteSelectedEmployees}
        handleCloseButton={() => setIsOpenConfirmDeleteSelectedEmployees(false)}
        handleConfirm={handleDeleteSelectedEmployees}
        modalType="delete"
      />
      <ConfirmManageSelectedUsersModal
        isOpen={isOpenConfirmRestoreSelectedEmployees}
        handleCloseButton={() => setIsOpenConfirmRestoreSelectedEmployees(false)}
        handleConfirm={handleRestoreSelectedEmployees}
        modalType="restore"
      />
      <ConfirmManageSelectedUsersModal
        isOpen={showInviteModal}
        handleCloseButton={() => setShowInviteModal(false)}
        handleConfirm={handleSendInvite}
        modalType="invite"
      />
    </Container>
  );
};

export default Users;
