import React, { Suspense, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import GlobalStyle from './GlobalStyle';
import Router from './Router';
import 'react-day-picker/lib/style.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastProvider } from './hooks/toast';
import AlertContextProvider from './hooks/useAlert';
import GlobalContextProvider from './hooks/useGlobal';
import Loader from './components/Loader';
import colors from './colors';
import { getUser } from './services/auth';
import { getData, USER_STORAGE } from './services/LocalStorageService';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'lato, sans-serif',
    fontSize: 13,
  },
  palette: {
    primary: {
      main: colors.blue,
    },
  },
});

const ENVIRONMENT_PROD = 'PROD';
const tagManagerArgs = {
  gtmId: 'GTM-MFR5LJJ',
};

if (process.env.REACT_APP_ENV === ENVIRONMENT_PROD) {
  ReactGA.initialize('UA-100769791-4', { titleCase: false });
  ReactGA.pageview(window.location.pathname + window.location.search);
  TagManager.initialize(tagManagerArgs);
}

function App() {
  const [isFreemium, setIsFreemium] = useState(true);

  useEffect(() => {
    const userStorage = getData(USER_STORAGE);

    if (userStorage) {
      if (userStorage.user.company.payment_type) {
        setIsFreemium(userStorage.user.company.payment_type === 'FREE');
      } else setIsFreemium(false);
    } else setIsFreemium(false);
  }, [getData(USER_STORAGE)]);

  useEffect(() => {
    const user = getUser();

    if (!isFreemium && user) {
      const firstScript = document.createElement('script');
      firstScript.text = 'var mdChatClient="72A03752165C4120B42CCDC9D4C30B9B"';
      firstScript.type = 'text/javascript';
      firstScript.id = 'movidesk-script';
      firstScript.async = true;

      const movideskScript = document.createElement('script');
      movideskScript.src = 'https://chat.movidesk.com/Scripts/chat-widget.min.js';
      movideskScript.async = true;
      movideskScript.id = 'movidesk-snippet';

      if (user) {
        document.body.append(firstScript);
        document.body.append(movideskScript);

        movideskScript.addEventListener('load', () => {
          const configScript = document.createElement('script');

          configScript.text = `movideskLogin({
          name: "${user.employee.name}",
          email: "${user.employee.email}",
          stayConnected: false,
          emptySubject: false,
          startChat: false,
        });`;
          configScript.type = 'text/javascript';
          configScript.id = 'movidesk-login';
          configScript.async = true;

          document.body.append(configScript);
        });
      }
    }
  }, [isFreemium]);

  return (
    <GlobalContextProvider>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Suspense
          fallback={(
            <div className="container-spinner">
              <Loader />
            </div>
          )}
        >
          <DndProvider backend={HTML5Backend}>
            <ToastProvider>
              <AlertContextProvider>
                <Router />
              </AlertContextProvider>
            </ToastProvider>
          </DndProvider>
        </Suspense>
      </ThemeProvider>
    </GlobalContextProvider>
  );
}

export default App;
