import styled, { css } from 'styled-components';
import colors from '../../colors';

const dragActive = css`
   border-color: ${colors.green};
`;

const dragReject = css`
   border-color: ${colors.red};
`;

interface ITypeMessageColor {
    type: 'default' | 'error' | 'success';
    loaded?: boolean;
}

type IDropZone = {
    isDragActive?: boolean;
    isDragReject?: boolean;
};

const messageColors : any = {
  default: css`
      color: ${colors.blue};
    `,
  success: css`
      color: ${colors.green};
    `,
  error: css`
      color: ${colors.red};
    `,
};

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const DropZone = styled.div<IDropZone>`
    background-color: #F5F6F8;
    width: 100%;
    position: relative;
    border: 1px dashed #B7C1C8;
    border-radius: 5px;
    height: 100%;
    min-height: 150px;
    margin-bottom: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
 
    transition: height 0.2s ease;
 
    ${(props: any) => props.isDragActive && dragActive};
    ${(props: any) => props.isDragReject && dragReject};
`;

export const DropContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
 
    transition: height 0.2s ease;
`;

export const Label = styled.span`
    margin-bottom: 0;
    color: var(--secondary-grey);
    font-size: 0.875rem;
`;

export const LinkLabel = styled.span`
   cursor: pointer;
`;

export const UploadMessage = styled.p<ITypeMessageColor>`
    display: flex;
    justify-content:  space-evenly;
    align-items: center;
    padding: 15px 0;
    margin: 0;
    font-size: 14px;
    ${(props) => messageColors[props.type]};
    ${(props) => props.loaded && 'opacity: 0.6'};
    font-weight: bold;

    span {
        font-weight: 100;
    }

    svg {
        color: ${colors.blue};
        width: 14px;
        height: 14px;
        margin-right: 8px;
    }
`;

export const MessageContainer = styled.div`
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content:  center;
    align-items: center;
`;
