/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import {
  Screen, SideBar, Body, Tab,
  TabIcon, TabLabel,
} from './style';

interface CustomSideBarTabProps {
    tabs: {label: string, icon?: string, Tab: any, }[],
    initialIndex?: number,
    steps?: boolean,
    className?: string,
}
export interface TabProps {
    forward: () => void,
    backward: () => void
}

function CustomSideBarTab({
  tabs, initialIndex, steps, className,
}: CustomSideBarTabProps) {
  const [step, setStep] = useState(initialIndex || 0);
  const [highIndex, setHighIndex] = useState(0);

  const goTo = (index: number) => {
    if (index <= highIndex || !steps) setStep(index);
  };

  const forward = () => {
    if (step !== tabs.length - 1) {
      setStep(step + 1);
      if (step === highIndex) setHighIndex(highIndex + 1);
    }
  };

  const backward = () => {
    if (step !== 0) setStep(step - 1);
  };

  return (
    <Screen className={className}>
      <SideBar>
        {tabs.map((tab, index) => (
          <Tab onClick={() => goTo(index)}>
            {tab?.icon && <TabIcon src={tab.icon} active={step === index} />}
            <TabLabel active={step === index}>{tab.label}</TabLabel>
          </Tab>
        ))}
      </SideBar>
      <Body>
        {React.createElement(tabs[step].Tab, { forward, backward })}
      </Body>
    </Screen>
  );
}

export default CustomSideBarTab;
