import React, { FC } from 'react';
import {
  ContainerIcon,
  Description, Steps, Title,
} from './style';
import icons from '../../assets/icons';

interface props {
  translate: Function
  currentStep?: number,
  finishStep?: number,
}
export const WelcomeStep:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('GuidedOnboarding.Steps.welcome_description')}</Title>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const PanelStep:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('GuidedOnboarding.Steps.panel_title')}</Title>
    <Description>
      {translate('GuidedOnboarding.Steps.panel_description.first')}
      <br />
      {translate('GuidedOnboarding.Steps.panel_description.second')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const CalendarStep:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('GuidedOnboarding.Steps.calendar_title')}</Title>
    <Description>
      {translate('GuidedOnboarding.Steps.calendar_description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const TeamsStep:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('GuidedOnboarding.Steps.teams_title')}</Title>
    <Description>
      {translate('GuidedOnboarding.Steps.teams_description.first')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const SpacesStep:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('GuidedOnboarding.Steps.spaces_title')}</Title>
    <Description>
      {translate('GuidedOnboarding.Steps.spaces_description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const MenuStep:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('GuidedOnboarding.Steps.menu_title')}</Title>
    <Description>
      {translate('GuidedOnboarding.Steps.menu_description.first')}
    </Description>
    <div className="d-flex">
      <ContainerIcon>
        <img src={icons.question} alt="icon" />
      </ContainerIcon>
      <Description>
        {translate('GuidedOnboarding.Steps.menu_description.second')}
      </Description>
    </div>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const ChatStep:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('GuidedOnboarding.Steps.chat_title')}</Title>
    <Description>
      {translate('GuidedOnboarding.Steps.chat_description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);
