/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import { useGlobal } from 'src/hooks/useGlobal';
import ICONS from 'src/assets/icons';
import ButtonLoader from 'src/components/ButtonLoader';
import ExportService from 'src/services/ExportService';
import LightTable from 'src/components/LightTable';
import {
  Modal, ModalHeader, ModalBody,
} from 'reactstrap';
import {
  Title,
  Header,
  TableLabel,
  TableContainer,
  DownloadContainer,
  Footer,
} from './style';

interface ErrorRow {
  row: number;
  message: string;
  page?: string;
  value: string;
}
interface ErrorModalProps {
  isOpen?: boolean;
  handleCloseButton?: () => void;
}
const ErrorModal: React.FC<ErrorModalProps> = ({
  isOpen,
  handleCloseButton,
}) => {
  const { addToast } = useToast();
  const { t } = useTranslation('pages');
  const {
    uploadResponse, currentJobId,
  } = useGlobal();

  const PAGES = {
    COLABORATORS: t('Administration.tabs.upload.error_modal.colaborators'),
    TEAMS: t('Administration.tabs.upload.error_modal.teams'),
    SPACES: t('Administration.tabs.upload.error_modal.spaces'),
    LOCATIONS: t('Administration.tabs.upload.error_modal.locations'),
  };

  const handleDownload = async () => {
    if (currentJobId) {
      try {
        const response = await ExportService.downloadErrors(currentJobId);
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      } catch (error) {
        addToast({
          description: t('Administration.tabs.upload.error_modal.download_fail'),
          type: 'error',
        });
      }
    }
  };

  const data = useMemo(() => {
    const colaborators: ErrorRow[] = [];
    const teams: ErrorRow[] = [];
    const spaces: ErrorRow[] = [];
    const locations: ErrorRow[] = [];
    if (uploadResponse === null || uploadResponse?.data.rows.length === 0) {
      return [
        {
          label: PAGES.COLABORATORS,
          data: colaborators,
        },
        {
          label: PAGES.TEAMS,
          data: teams,
        },
        {
          label: PAGES.SPACES,
          data: spaces,
        },
        {
          label: PAGES.LOCATIONS,
          data: locations,
        },
      ];
    }
    const erros = uploadResponse?.data.rows as ErrorRow[];
    erros.forEach((error: ErrorRow) => {
      switch (error.page) {
        case PAGES.TEAMS:
          teams.push(error);
          break;
        case PAGES.SPACES:
          spaces.push(error);
          break;
        case PAGES.LOCATIONS:
          locations.push(error);
          break;
        case PAGES.COLABORATORS:
          colaborators.push(error);
          break;
        default:
          break;
      }
    });

    return [
      {
        label: PAGES.COLABORATORS,
        data: colaborators,
      },
      {
        label: PAGES.TEAMS,
        data: teams,
      },
      {
        label: PAGES.SPACES,
        data: spaces,
      },
      {
        label: PAGES.LOCATIONS,
        data: locations,
      },
    ];
  }, [uploadResponse]);

  const renderTable = (table: {label: string; data: ErrorRow[]}) => {
    if (table.data.length === 0) {
      return <></>;
    }
    return (
      <TableContainer>
        <TableLabel>
          {t('Administration.tabs.upload.error_modal.table_title', {
            page_name: table.label,
          })}
        </TableLabel>
        <LightTable
          align="left"
          striped
          grid
          header={[[
            t('Administration.tabs.upload.error_modal.row'),
            t('Administration.tabs.upload.error_modal.value'),
            t('Administration.tabs.upload.error_modal.error'),
          ]]}
          body={table.data.map((error: ErrorRow) => [
            error.row + 2,
            error.value,
            <b style={{ color: '#E01E5A' }}>{error.message}</b>,
          ])}
        />
      </TableContainer>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onClosed={() => {
        handleCloseButton?.();
      }}
      style={{
        minWidth: '70vw',
      }}
    >
      <ModalHeader
        toggle={handleCloseButton}
        style={{
          padding: '2rem',
          border: 'none',
          height: 45,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Title>
          {t('Administration.tabs.upload.error_modal.title')}
        </Title>
      </ModalHeader>
      <ModalBody style={{
        padding: '2rem',
        maxHeight: '60vh',
        overflow: 'scroll',
      }}
      >
        <Header>
          <span>
            {t('Administration.tabs.upload.error_modal.successful_uploads', { value: uploadResponse?.data.rows_success })}
            <b style={{ color: '#2D9A36' }}>{uploadResponse?.data.rows_success}</b>
          </span>
          <span style={{
            marginLeft: 20,
          }}
          >
            {t('Administration.tabs.upload.error_modal.unsuccessful_uploads')}
            <b style={{ color: '#E01E5A' }}>{uploadResponse?.data.rows_fail}</b>
          </span>
        </Header>
        {data.map((table) => renderTable(table))}
      </ModalBody>
      {uploadResponse?.data.rows_fail > 0 && (
      <DownloadContainer>
        {t('Administration.tabs.upload.error_modal.model_description')}
        <ButtonLoader
          transparent
          withHover={false}
          bordered
          onClick={handleDownload}
          uppercase={false}
          icon={ICONS.Download}
          label={t('Administration.tabs.upload.error_modal.download_model')}
        />
      </DownloadContainer>
      )}
      <Footer>
        <ButtonLoader
          label={t('Administration.tabs.upload.error_modal.close')}
          onClick={handleCloseButton}
        />
      </Footer>
    </Modal>
  );
};
export default ErrorModal;
