import React from 'react';

const BreakText = ({ text }: { text: string }) => (
  <>
    {text.split('{break}').map((t) => (
      <>
        {t}
        <br />
      </>
    ))}
  </>
);

export default BreakText;
