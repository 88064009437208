/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
export default class JustificationService {
  static async update(
    teamId: number,
    professionalId: number,
    justificationId: number,
    payload: any,
  ) {}
}
