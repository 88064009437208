/* eslint-disable import/no-duplicates */
import React, { useContext } from 'react';
import Button from 'src/components/ButtonLoader';
import Icons from 'src/assets/icons';
import getNameInitials from 'src/utils/getNameInitials';
import { format, parse } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { useTranslation } from 'react-i18next';
import { ModalMapViewContext } from '../Context';
import {
  DetailsContainer,
  Header,
  Body,
  Title,
  SubTitle,
  EmployeeItem,
  EmployeeWithoutProfileImage,
  EmployeePhoto,
  Tag,
  EmployeeInfo,
} from './style';

const DetailsModal: React.FC = () => {
  const { selectedSeat, setSelectedSeat } = useContext(ModalMapViewContext);
  const { t } = useTranslation('pages');

  const formatStringDate = (date: string, pattern: string = 'dd/MM/yyyy') => {
    let newDate = new Date();
    let dateString = '';
    try {
      if (date.trim().split(' ').length > 1) {
        newDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
      } else {
        newDate = parse(date, 'HH:mm:ss', new Date());
      }
      dateString = format(newDate, pattern, {
        locale: pt,
      });
    } catch (error) {
      dateString = '--/--';
    }
    return dateString;
  };

  if (!selectedSeat) {
    return null;
  }

  return (
    <DetailsContainer>
      <Header>
        <Title>{selectedSeat.name}</Title>
        <Button
          icon={Icons.close}
          iconSize={16}
          transparent
          withHover={false}
          onClick={() => setSelectedSeat?.(null)}
        />
      </Header>
      <Tag localized={!!selectedSeat.position?.positionX}>
        {selectedSeat.position?.positionX
          ? t('AddLocation.tabs.AddPosition.localized')
          : t('AddLocation.tabs.AddPosition.not_localized')}
      </Tag>
      <Body>
        <SubTitle>Profissionais</SubTitle>
        {selectedSeat.employees?.map((employee) => (
          <EmployeeItem key={[employee.id, 'employee', 'details'].join('_')}>
            <EmployeeInfo>
              {employee.photo
                ? <EmployeePhoto src={employee.photo} />
                : (
                  <EmployeeWithoutProfileImage>
                    {getNameInitials(employee.name)}
                  </EmployeeWithoutProfileImage>
                )}
              <p>{employee.name}</p>
            </EmployeeInfo>
            <span>
              {`
            ${formatStringDate(employee.start!, 'HH:mm')}
              - ${formatStringDate(employee.end!, 'HH:mm')}
            `}
            </span>
          </EmployeeItem>
        ))}

        {!selectedSeat.employees?.length && (
          <p>{t('AddLocation.tabs.AddPosition.details_employee_map.empty_list')}</p>
        )}
      </Body>
    </DetailsContainer>
  );
};

export default DetailsModal;
