import React, { useEffect } from 'react';

export default function useClickOutside<T = Element, E= MouseEvent>(
  ref: any, action: (event?: React.MouseEvent<T, E>) => void,
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        action(event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return null;
}
