import styled, { css } from 'styled-components';

interface SideBarProps {
    open?: Boolean;
    selected?: Boolean;
    color?: string;
}

const selectedStyle = css`
    border: solid 1px #1075CE;
    background-color: #136AC31A;
`;

export const Card = styled.div<SideBarProps>`
    display: flex;
    padding: 0px 5px;
    align-items: center;
    justify-content: ${(props) => (props.open ? 'space-between' : 'center')};
    height: 48px;
    border-bottom: 1px solid #e6e9eb;
    border-radius: 5px;
    ${(props) => props.selected && selectedStyle}
`;

export const ProfessionalName = styled.span`
    overflow: hidden;
    margin-left: 10px;
`;

export const ProfessionalImage = styled.img`
    width: 30px;
    height: 30px;
    border-radius: 100px;
    object-fit: cover;
    min-height: 30px;
    max-height: 30px;
`;

export const ProfessionalNonImage = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 100px;
    background-color: #676C7D;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 15px;
    min-height: 30px;
    max-height: 30px;
    min-width: 30px;
    max-width: 30px;
    text-transform: uppercase;
`;

export const ProfessionalCardLeftContent = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const Totalemployeespaces = styled.span<SideBarProps>`
    color: ${(props) => props.color};
`;
