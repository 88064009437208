const AdminFilters = (t) => ({
  options: [
    {
      value: 'order_by_last_login',
      name: t('AccessFilterSelector.filter.order_by_last_login'),
    },
    {
      value: 'order_by_last_login_in_mobile',
      name: t('AccessFilterSelector.filter.order_by_last_login_in_mobile'),
    },
    {
      value: 'no_login_app',
      name: t('AccessFilterSelector.filter.no_login_app'),
    },
    {
      value: 'no_login',
      name: t('AccessFilterSelector.filter.no_login'),
    },
    {
      value: '',
      name: t('AccessFilterSelector.filter.reset_filter'),
    },
  ],
  hasMore: false,
});

export default AdminFilters;
