import styled from 'styled-components';

interface SideBarProps {
  open?: boolean;
}

export const SideBarElement = styled.div<SideBarProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.open ? '200px' : '0px')};
  background: #f6f8f9 0% 0% no-repeat padding-box;
  border: 1px solid #c4c7cf;
  padding: ${(props) => (props.open ? '23px' : '23px 5px 23px 5px')};
  transition: 0.3s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 100%;
`;

export const HideButton = styled.button`
  position: absolute;
  top: 15px;
  @media only screen and (max-width: 768px) {
    top: 80px !important;
  }
  right: -21px;
  width: 36px;
  height: 36px;
  background: #f6f8f9 0% 0% no-repeat padding-box;
  border: 1px solid #c4c7cf;
  border-radius: 6px;
  opacity: 1;
  transition: 0.5s;
  color: #676c7d;
  :focus {
    outline: none;
  }
`;

export const HideButtonIcon = styled.img<SideBarProps>`
  transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: 0.3s;
`;

export const Body = styled.div<SideBarProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: ${(props) => (props.open ? 'start' : 'center')};
  justify-content: start;
`;
