import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import ButtonLoader from 'src/components/ButtonLoader';
import ICONS from 'src/assets/icons';
import { useGlobal } from 'src/hooks/useGlobal';
import UploadButton from 'src/components/UploadButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrosModal from './ErrosModal';
import {
  Container,
  HeaderContainer,
  FloatLeft,
  Title,
  Content,
  Description,
  DescriptionContainer,
} from './style';

const Users = () => {
  const { t } = useTranslation('pages');
  const { addToast } = useToast();

  const {
    startUploadBatch, isUploading, uploadResponse, clearUpload,
  } = useGlobal();
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  useEffect(() => {
    if (uploadResponse) {
      if (uploadResponse.status === 1) {
        setOpenErrorModal(true);
        if (uploadResponse.data.rows.length === 0) {
          addToast({
            description: t('Administration.tabs.upload.success_message'),
            type: 'success',
          });
        }
      }
      if (uploadResponse.status === 0) {
        addToast({
          description: t('Administration.tabs.upload.error_message'),
          type: 'error',
        });
      }
      setUploadFile(null);
    }
  }, [uploadResponse]);

  const onSubmitUpload = async () => {
    if (uploadFile) {
      clearUpload();
      startUploadBatch(uploadFile);
    }
  };

  return (
    <Container>
      <HeaderContainer className="clearfix">
        <FloatLeft>
          <Title>{t('Administration.tabs.upload.title')}</Title>
        </FloatLeft>
      </HeaderContainer>
      <Content>
        <DescriptionContainer>
          <Description>
            {t('Administration.tabs.upload.description')}
          </Description>
          <a href="/files/exemplo.xlsx" download>
            <ButtonLoader
              transparent
              bordered
              icon={ICONS.Download}
              label={t('Administration.tabs.upload.download_label')}
              withHover={false}
            />
          </a>
        </DescriptionContainer>

        <UploadButton
          file={uploadFile}
          isDragActiveMessage={t('Administration.tabs.upload.drag_active_message')}
          accept={[
            '.xlsx',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]}
          onChange={(file: File | null) => {
            // If it has more than 5 mB
            if (file && file!.size / 1048576 > 5) {
              addToast({
                description: t('Administration.tabs.upload.size_error'),
                type: 'error',
              });
              return;
            }

            setUploadFile(file);
          }}
          onDelete={() => {
            setUploadFile(null);
          }}
        />
        <LinearProgress
          color="primary"
          style={{
            visibility: isUploading ? 'visible' : 'hidden',
            width: '100%',
            borderRadius: 5,
          }}
        />
      </Content>
      <div>
        <ButtonLoader
          label={t('Administration.tabs.upload.import_button')}
          className="m-4"
          disabled={!uploadFile || isUploading}
          isLoading={isUploading}
          onClick={onSubmitUpload}
        />
      </div>
      <ErrosModal
        isOpen={openErrorModal}
        handleCloseButton={() => {
          clearUpload();
          setOpenErrorModal(false);
        }}
      />
    </Container>
  );
};

export default Users;
