import styled from 'styled-components';
import colors from '../../colors';
import ProfilePicture from '../../assets/images/profilePicture.svg';

export const Container = styled.div`
  @media only screen and (max-width: 768px) {
    padding-top: 99px;
  }
`;

export const PanelAttendant = styled.div`
  background-color: ${colors.white};
  height: 100%;
  padding-top: 15px;
`;

export const BackButton = styled.div`
  cursor: pointer;
  @media only screen and (min-width: 769px) {
    margin: 30px 30px 30px 30px ;
  }
`;

export const AttendantCard = styled.div`
  display: flex;
  width: 100%;
  margin: 30px 30px 30px 30px;
`;

export const Picture = styled.div`
  width: 197px;
  height: 196.31px;
  border-radius: 100%;
  background: url(${ProfilePicture});
`;

export const ProfileTitle = styled.h3`
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;


  letter-spacing: 0.225em;
  color: #0025FC;
  opacity: 0.5;
`;

export const ProfileName = styled.h1`
  font-weight: 600;
  font-size: 48px;
  line-height: 67px;

  color: #000000;
`;

export const ProfileOffice = styled.p`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #000000;
`;

export const ProfileContact = styled.a`
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  display: block;
  color: #000000;
  margin-bottom: 30px;

  img {
    margin-right: 15px;
  }
`;

export const ContainerText = styled.div`
  margin-left: 40px;
`;

export const AttendantButton = styled.button`
  height: 55px;
  background: #000B4C;
  border-radius: 10px;
  border: 0;
  padding: 0 15px 0 15px;

  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #FFFFFF;
`;
