import React, {
  useEffect, useRef, useState,
} from 'react';
import { Image } from 'react-konva';
import Konva from 'konva';

interface URLImageProps {
    src: string;
    x?:number;
    y?:number;
    onClick?: (evt: Konva.KonvaEventObject<MouseEvent>) => void;
    onLoadImage?: (scale: {width: number; height: number}) => void;
}

const URLImage: React.FC<URLImageProps> = ({
  src, x, y, onClick, onLoadImage,
}) => {
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [image, setImage] = useState<any>(null);

  const handleLoad = () => {
    const imageWidth = imageRef?.current!.width;
    const imageHeight = imageRef?.current!.height;
    onLoadImage?.({
      width: imageWidth,
      height: imageHeight,
    });
    setImage(imageRef.current);
  };

  const loadImage = () => {
    const img = new window.Image();
    img.src = src;
    imageRef.current = img;
    imageRef.current.addEventListener('load', handleLoad);
  };

  useEffect(() => {
    loadImage();
    return () => {
      if (imageRef.current) {
        imageRef.current.removeEventListener('load', handleLoad);
      }
    };
  }, []);

  useEffect(() => {
    loadImage();
  }, [src]);

  return (
    <Image
      x={x}
      y={y}
      image={image}
      onClick={onClick}
    />
  );
};

URLImage.defaultProps = {
  x: 0,
  y: 0,
};

export default URLImage;
