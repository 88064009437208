import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import icons from 'src/assets/icons';
import Input from 'src/components/Input';
import { ModalMapSelectorContext } from './Context';
import {
  List, ListBody, ListHeader, SeatItem, TitlePositions,
} from './style';
import ButtonLoader from '../ButtonLoader';

export interface ModalMapSelectProps {
  seats: any[];
}

export const GetSeatsList: React.FC<ModalMapSelectProps> = ({ seats }) => {
  const { t } = useTranslation('pages');

  const { selectedSeatId, setSelectedSeatId } = useContext(
    ModalMapSelectorContext,
  );
  return (
    <List>
      <TitlePositions>
        {t('AddLocation.tabs.AddPosition.add_localization_label')}
      </TitlePositions>
      <ListHeader>
        <div>{t('AddLocation.tabs.AddPosition.position')}</div>
        <div>{t('AddLocation.tabs.AddPosition.table_num')}</div>
        <div>{t('AddLocation.tabs.AddPosition.map_localization')}</div>
      </ListHeader>
      <ListBody>
        {seats.map((fieldState: any, index: number) => (
          <SeatItem
            selected={selectedSeatId === fieldState.get().id}
            onClick={() => {
              if (selectedSeatId === fieldState.get().id) {
                setSelectedSeatId?.(null);
                return;
              }
              setSelectedSeatId?.(fieldState.get().id);
            }}
          >
            <div>{index + 1}</div>
            <Input
              key={['seat', 'selector', index].join('_')}
              onChange={(e) => fieldState.set((prev: any) => ({
                ...prev,
                name: e.target.value,
              }))}
              value={`${fieldState.get().name}`}
            />
            <ButtonLoader
              transparent
              disabled={false}
              withHover={false}
              uppercase={false}
              label={
                fieldState.get().position?.positionX
                  ? t('AddLocation.tabs.AddPosition.localized')
                  : t('AddLocation.tabs.AddPosition.add_localization')
              }
              icon={
                fieldState.get().position?.positionX
                  ? icons.PushPin
                  : icons.FeatherMap
              }
            />
          </SeatItem>
        ))}
      </ListBody>
    </List>
  );
};
