import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import Pagination from 'src/components/Pagination';
import CustomSearchInput from 'src/components/CustomSearchInput';
import KeysToCamelCase from 'src/utils/keysToCamelCase';
import Button from 'src/components/Button';
import { FiEdit } from 'react-icons/fi';
import Loader from 'src/components/Loader';
import { useToast } from '../../../../hooks/toast';
import LocationService from '../../../../services/LocationService';
import {
  LocationButtons, LoaderContainer, LocationName, LocationAddress, Options,
} from './style';
import icons from '../../../../assets/icons';
import ModalConfirm from '../../../../components/ConfirmModal';
import { mixpanelTrack } from '../../../../services/MixPanelService';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LocationListing: React.FC = () => {
  const { t } = useTranslation('pages');
  const history = useHistory();
  const { addToast } = useToast();
  const query = useQuery();
  const [locationList, setLocationList] = useState<Location[]
  >([]);
  const PER_PAGE = 10;
  const [paginateData, setPaginateData] = useState<any>(null);
  const [page, setPage] = useState(1);
  const [searchName, setSearchName] = useState<string>(query.get('name') || '');
  const [isLoading, setIsLoading] = useState(false);
  const [deleteLocationId, setDeleteLocationId] = useState<string | null>();

  const getLocations = async () => {
    setIsLoading(true);
    try {
      const response = await LocationService.getAll(page, PER_PAGE, searchName);
      setLocationList(Object.values(response.data.data));
      setPaginateData(KeysToCamelCase(response.data));
    } catch (error) {
      addToast({
        description: t('Reservation.error.load_locations'),
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const deleteLocation = () => {
    setIsLoading(true);
    if (deleteLocationId) {
      LocationService.deleteLocation(deleteLocationId).then(() => {
        const index = locationList.findIndex(
          (value: any) => Number(value.id) === Number(deleteLocationId),
        );
        if (index > -1) {
          locationList.splice(index, 1);
        }
        mixpanelTrack('locations@deleteSpace', null);
        addToast({
          description: t('Reservation.delete.delete_location_success'),
          type: 'success',
        });
        setDeleteLocationId(null);
      }).catch(() => {
        addToast({
          description: t('Reservation.delete.delete_location_error'),
          type: 'error',
        });
      }).finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    getLocations();
  }, [page]);

  useEffect(() => {
    if (searchName?.length) {
      const delayDebounceFn = setTimeout(() => {
        setPage(1);
        window.history.pushState('', '', `/spaces?name=${searchName}`);
        getLocations();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
    getLocations();
    window.history.pushState('', '', '/spaces');
    return () => false;
  }, [searchName]);

  const handleCloseButton = () => setDeleteLocationId(null);

  return (
    <>
      <div className="col-12 d-flex justify-content-between">
        <CustomSearchInput
          height="44px"
          onChange={(value) => {
            setSearchName(value);
          }}
          defaultValue={searchName}
          placeholder={t('Reservation.manage.search_placeholder')}
        />
        <LocationButtons className="ms-2">
          <Button
            label={t('Reservation.manage.config_reservation')}
            className="btnLight"
            icon="iconCog"
          />
          <Button
            label={t('Reservation.manage.import_space')}
            className="btnOutlinePrimary"
            icon="fileUpload"
          />
          <Button
            label={t('Reservation.manage.new_space')}
            color="primary"
            icon="plus"
            classIcon="m-0"
            onClick={() => history.push('/space')}
          />
        </LocationButtons>
      </div>
      <div className="col-12">
        {isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : locationList.map((location: any) => (
          <div className="card my-3 shadow border-0" style={{ borderRadius: '10px' }}>
            <div className="card-header d-flex justify-content-between bg-white border-0" style={{ borderRadius: '10px' }}>
              <div className="w-75">
                <LocationName>{location.name}</LocationName>
              </div>
              <Options className="d-flex m-0">
                <FiEdit
                  className="me-2"
                  filter="brightness(0) saturate(100%) invert(7%) sepia(31%) saturate(7050%) hue-rotate(224deg) brightness(109%) contrast(113%)"
                />
                <button
                  type="button"
                  onClick={() => history.push(`/space/${location.id}`)}
                  style={{ color: 'rgb(0, 11, 76) !important' }}
                  tabIndex={0}
                  className="bg-transparent border-0"
                >
                  {t('Reservation.manage_list.location_edit')}
                </button>
                <button
                  type="button"
                  className="bg-transparent border-0 pb-1 ms-4"
                  onClick={() => setDeleteLocationId(location.id)}
                >
                  <img src={icons.TrashRed} alt="delete" />
                </button>
              </Options>
            </div>
            <div className="card-body">
              <LocationAddress>{location.address}</LocationAddress>
            </div>
          </div>
        ))}
      </div>
      {paginateData && (
        <div className="col-12">
          <div className="d-flex border-top flex-column py-1 px-4">
            <Pagination
              activePage={Number(paginateData.currentPage)}
              itemsCountPerPage={Number(paginateData.perPage)}
              actualItemsCount={locationList.length}
              onPageChange={(newPage: number) => setPage(newPage)}
              totalItemsCount={paginateData.total}
            />
          </div>
        </div>
      )}
      {deleteLocationId && (
        <ModalConfirm
          messageConfirm={t('Reservation.delete.confirm_confirm')}
          isOpen={!!deleteLocationId}
          handleConfirm={deleteLocation}
          handleCloseButton={handleCloseButton}
          message={t('Reservation.delete.confirm_body')}
        />
      )}
    </>
  );
};

export default LocationListing;
