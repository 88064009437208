/* eslint-disable import/no-duplicates */
import {
  DateRangeInput,
  SingleSelectInput,
} from 'escala-components'; import {
  format,
  endOfWeek,
} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import React, {
  useContext, useState, useMemo, useEffect,
} from 'react';
import { mixpanelTrack } from 'src/services/MixPanelService';
import { useTranslation } from 'react-i18next';
import Location from 'src/contracts/models/Location';
import moment from 'moment';
import Option from 'src/contracts/models/Option';
import ShiftService from 'src/services/ShiftService';
import ButtonLoader from 'src/components/ButtonLoader';
import * as yup from 'yup';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { homeContext } from '../../../pages/Home/Context';
import { useToast } from '../../../hooks/toast';
import {
  Body,
  Header,
  Footer,
  ErrorContainer,
} from '../style';
import icons from '../../../assets/icons';
import Select from '../../Select';

interface PlaceAnnouncementProps {
  locations: Location[];
  onClickBackButton: Function;
  onSuccess: Function;
}
const ModalPlacesAnnoucementLot: React.FC<PlaceAnnouncementProps> = ({
  onSuccess,
}) => {
  const { addToast } = useToast();
  const {
    placement,
    waitForAnnouncementBatchConclusion,
    week,
  } = useContext(homeContext);
  const { t } = useTranslation('components');
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<string[]>([]);
  const [selectedPeriod, setSelectedPeriod] = useState<{from: Date, to: Date} | null>(null);
  const [sendToAllTeams, setSendToAllTeams] = useState(true);
  const notifyTypesOptions: Option[] = [
    {
      label: t('ModalSchedulePublish.notify_types.all_in_team'),
      value: 'ALL_TEAM',
    },
    {
      label: t('ModalSchedulePublish.notify_types.all_allocated'),
      value: 'ALL_ALLOCATED',
    },
    {
      label: t('ModalSchedulePublish.notify_types.nobody'),
      value: 'NONE',
    },
  ];

  const [selectedTeams, setSelectedTeams] = useState<Option[]>([]);
  const [selectTypesOptions, setSelectTypesOptions] = useState<Option>(notifyTypesOptions[0]);

  const teamOptions = useMemo(
    () => (placement ? placement.teams!.map(
      (team) => ({ value: String(team.id), label: team.name, id: String(team.id) }),
    ) as Option[] : []),
    [placement],
  );

  useEffect(() => {
    const newWeek = { from: week, to: endOfWeek(week, { weekStartsOn: 1 }) };
    setSelectedPeriod(newWeek);
  }, [week]);

  const errorsArray = useMemo(() => fieldErrors, [fieldErrors]);

  const onSubmit = async () => {
    setIsLoading(true);
    setFieldErrors([]);
    const formatDate = (date: Date) => {
      const dayFormatted = format(date, 'yyyy-MM-dd', {
        locale: ptBR,
      }).toUpperCase();
      return dayFormatted;
    };

    const teams = sendToAllTeams ? undefined : selectedTeams.map((o) => o.value).join(',');

    const body = {
      notification_type: selectTypesOptions.value,
      placement_id: placement?.id!,
      teams_id: teams,
      week_start: formatDate(selectedPeriod?.from as Date),
      week_end: formatDate(selectedPeriod?.to as Date),
    };
    try {
      const validatorSchema = yup
        .object()
        .shape({
          placement_id: yup.number().required(t('ModalPlacesAnnoucementLot.placement_validator')),
          teams_id: yup.string().nullable(),
          week_start: yup.string().required(t('ModalPlacesAnnoucementLot.week_start_validator')).typeError(''),
          week_end: yup.string().required(t('ModalPlacesAnnoucementLot.week_end_validator')).typeError(''),
          notification_type: yup.string().nullable(),
        });
      await validatorSchema.validate(body, {
        abortEarly: false,
      });
      ShiftService.announcementBatch(body).then((res) => {
        setIsLoading(false);
        mixpanelTrack('announcement@batch-send', null);
        mixpanelTrack('announcement@batch-notification-type', {
          notification_type: selectTypesOptions.value,
        });
        addToast({
          description: t('ModalPlacesAnnoucementLot.info_pending_message'),
          type: 'info',
        });
        waitForAnnouncementBatchConclusion?.(res.data.jobId);
        onSuccess();
      }).catch(() => {
        addToast({
          description: t('ModalPlacesAnnoucementLot.error_create_message'),
          type: 'error',
        });
        setIsLoading(false);
      });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setFieldErrors(error.errors);
        setIsLoading(false);
      }
    }
  };

  const today = new Date();
  return (
    <>
      <div>
        <Header className="d-flex align-items-center py-4 px-5">
          <h3 className="text-primary mb-1">
            {t('ModalPlacesAnnoucementLot.schedule_announcement')}
          </h3>
        </Header>
        <hr className="mt-0" />
      </div>
      <div className="d-flex flex-column justify-content-between h-100">
        <Body className="px-5">
          <div>
            <p>{t('ModalPlacesAnnoucementLot.description_announcement')}</p>
            <div>
              <DateRangeInput
                startDate={moment(selectedPeriod?.from || new Date())}
                endDate={moment(selectedPeriod?.to || moment(new Date()).add(7, 'days'))}
                label={t('ModalAnnoucement.period')}
                isOutsideRange={(day) => day.isBefore(today)}
                minimumNights={-1}
                handleChangeDate={
                  (({ endDate, startDate }) => {
                    mixpanelTrack('announcement@batch-period-change', null);
                    if (startDate && endDate && startDate > endDate) {
                      setSelectedPeriod({
                        to: startDate && startDate.toDate(),
                        from: startDate && startDate.toDate(),
                      });
                    } else {
                      setSelectedPeriod({
                        to: endDate && endDate.toDate(),
                        from: startDate && startDate.toDate(),
                      });
                    }
                  })
                }
              />
            </div>
            <div>
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    checked={sendToAllTeams}
                    onChange={() => setSendToAllTeams((previousState) => !previousState)}
                    name="Enviar"
                  />
                )}
                label={t('ModalAnnoucement.send_to_all_teams')}
              />
              {
                !sendToAllTeams && (
                  <Select
                    isMulti
                    options={teamOptions}
                    defaultValue={selectedTeams}
                    onChange={
                      (changedSelectedTeams:any) => setSelectedTeams?.(changedSelectedTeams)
                    }
                  />
                )
              }
            </div>
            <div>
              <SingleSelectInput
                label={t('ModalAnnoucement.notify')}
                options={notifyTypesOptions}
                selectedOption={selectTypesOptions}
                onChange={(changeOptions) => setSelectTypesOptions(changeOptions)}
              />
            </div>
            {Boolean(errorsArray?.length) && (
              <ErrorContainer className="row mt-4">
                <div>
                  <ul className="col-12">
                    {errorsArray?.map((error) => (
                      <li className="py-2">{`- ${error}`}</li>
                    ))}
                  </ul>
                </div>
              </ErrorContainer>
            )}
          </div>
        </Body>
        <Footer>
          <div className="py-4 px-5">
            <ButtonLoader
              isLoading={isLoading}
              width="100%"
              icon={icons.bullhorn}
              label={t('ModalAnnoucement.publish')}
              onClick={onSubmit}
            />
          </div>
        </Footer>
      </div>
    </>
  );
};

export default ModalPlacesAnnoucementLot;
