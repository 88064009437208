import styled from 'styled-components';
import colors from '../../colors';

export const Container = styled.div`
    flex: 1;
    min-height: 100vh;
    background-color: ${colors.white10};
`;

export const HeaderContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    background-color: ${colors.white};
    padding:2rem 2rem;
    border-bottom: solid 1px ${colors.white15};
`;

export const Row = styled.div`
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
`;

export const Column = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const TitleContainer = styled.div`
    width: 40%;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const DateContainer = styled.div`
    width: 100%;
    flex: 1;
    margin: 0 auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

export const FilterContainer = styled.div`
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    display: grid;
    margin-top: 34px;
    align-items: flex-end;
    grid-gap: 10px;
    grid-template-columns: auto auto auto auto;

    @media (max-width: 1090px) {
      grid-template-columns: auto auto;
    }
`;

export const Title = styled.h1`
    font-size: 18px;
    color: black;
    font-weight: 600;
    font-family: lato;
`;

export const SubTitle = styled.span`
    color: ${colors.blue};
    font: normal normal bold 13px/19px lato;
`;

export const Card = styled.div`
    min-height: 400px;
    padding: 10px;
    min-width: 280px;
    background-color: ${colors.white};
    box-shadow: 0px 2px 6px ${colors.dark80};
    display: flex;
    flex-direction: column;
    margin-right: 20px;

    &:last-child {
        margin-right: 0px;
    }
`;

export const CardLarge = styled.div`
    min-height: 400px;
    margin-top: 20px;
    padding: 10px;
    width: 100%;
    background-color: ${colors.white};
    box-shadow: 0px 2px 6px ${colors.dark80};
    display: flex;
    flex-direction: column;
    margin-right: 20px;
`;

export const CardBody = styled.div`
   flex: 1;
   padding: 10px 0px;
   border-bottom: 0.5px solid ${colors.white15};
   border-top: 0.5px solid ${colors.white15};
   position: relative;
`;

export const CardTop = styled.div`
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`;

export const CardFooter = styled.div`
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Body = styled.div`
    padding: 1px 2rem 2rem;
    display: flex;
    flex-direction: column;
    background-color:  ${colors.white10};
`;

export const LabelCard = styled.span`
    color: ${colors.gray};
    font: normal normal bold 19px/19px lato;
`;

export const SubLabelCard = styled.span`
    color: ${colors.gray90};
    font-weight: 500;
    font-size: 13px;
`;

export const Value = styled.span`
    color: ${colors.gray90};
    font-weight: bold;
    font-size: 13px;
`;

export const DataRow = styled.div`
    display: flex;
    padding: 5px 0px;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

export const ContainerInsideChart = styled.div`
    display: flex;
    position: absolute;
    top: 40%;
    left: 50%;
    right: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    -webkit-touch-callout: none;  /* iPhone OS, Safari */
    -webkit-user-select: none;    /* Chrome, Safari 3 */
    -khtml-user-select: none;     /* Safari 2 */
    -moz-user-select: none;       /* Firefox */
    -ms-user-select: none;        /* IE10+ */
    user-select: none;            /* Possível implementação no futuro */
`;

export const LabelInsideChart = styled.span`
    color: ${colors.gray90};
    font-weight: bold;
    font-size: 16px;
`;
