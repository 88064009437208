import styled from 'styled-components';
import { lighten } from 'polished';
import colors from '../../colors';

interface Props {
  isLoading?: boolean;
}

export const Overlay = styled.div<{ isOpen: boolean }>`
  opacity: ${(p) => (p.isOpen ? '1' : '0')};
  pointer-events: ${(p) => (p.isOpen ? 'initial' : 'none')};
  position: fixed;
  transition: .3s;
  width: 100vw;
  height: 100vh;
  background-color: ${colors.dark95};
  z-index: 100;
  top: 0;
`;

export const Container = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
  width: 30rem;
  height: 100vh;
  background-color: ${colors.white};
  box-shadow: 0 3px 6px ${colors.dark90};
  transition: 1s ease-in-out;
  @media only screen and (max-width: 768px) {
    width: auto;
  }
`;

export const ContainerOutside = styled.div`
  flex: 1;
  height: 100%;
`;

export const Header = styled.div`
h3{
  font-size: 1.3rem;
}
  button{
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
  }
`;

export const Body = styled.div`
  > div:first-child {
    > div {
      margin-top: 2rem;
    }
  }
  .edit-button{
    position: absolute;
    bottom: 18px;
    padding: 0;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    border: 0;
    background-color: ${colors.blue} !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    
    img{
      width: 14px;
      margin: 0;
      padding: 0;
    }
    span{
      display: none;
    }
    
    &:focus{
      box-shadow: none;
    }
  }
`;

export const Footer = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    span{
      font-size: 16px;
    }
  }
`;

export const LoaderButton = styled.img`
    height: 25px;
    width: 25px;
    filter: brightness(1.5);
`;

export const ProfileImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 120px;
  margin: 20px auto;
  display: block;
  object-fit: cover;
  border: solid 1px ${colors.white10};
`;

export const ProfileNonImage = styled.div`
    width: 120px;
    height: 120px;
    margin: 20px auto;
    border-radius: 100px;
    background-color: ${colors.gray90};
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.white};
    font-size: 40px;
`;

export const Forms = styled.form`
  .button-change-password{
    outline: none;
    padding: 0;
    img{
      width: 17px;
      margin-right: 4px;
    }
  }
  label, span{
    color: ${colors.gray90};
    font: normal normal 600 12px/19px lato;
    display: contents;
  }
`;

export const ButtonReset = styled.button<Props>`
    padding: 15px 30px;
    text-transform: uppercase;
    min-width: 130px;
    font-size: 14px;
    color: ${colors.white};
    outline: 0;
    text-align: center;
    border-radius: 5px;
    background-color: ${(props) => (props.isLoading ? lighten(0.09, `${colors.blue}`) : `${colors.blue}`)};
    border: none;
`;

export const InputLabel = styled.span``;

export const FormInput = styled.input`
  width: 100%;
  height: 35px;
  color: var(--primary-gray);
  text-align: left;
  padding-left: 10px;
  border: 1px solid ${colors.white10};

    :invalid {
        box-shadow: 0 0 0.5em ${colors.red};
        border-color: ${colors.red};
    }
`;
