import styled from 'styled-components';
import { CheckBox } from '@material-ui/icons';
import colors from '../../colors';

interface Props {
  annualPlan?: boolean;
}

export const Container = styled.div`
  background-color: ${colors.gray27};
  transition: 0.3s;
  height: calc(100vh - 64px);
  @media only screen and (max-width: 768px) {
    padding-top: 99px;
  }
`;

export const PanelPlans = styled.div`
  background-color: ${colors.white};
  padding-top: 15px;
`;

export const OptionsPlanIcon = styled.div`
  display: flex;
  justify-content: center;
  color: #000000;

  h1 {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
  }

  h3 {
    font-weight: 400;
    font-size: 19px;
    line-height: 25px;
    text-align: center;
  }

  align-items: center;
  text-align: center;


`;

export const OptionsPlanText = OptionsPlanIcon;

export const BuyIconContainer = styled.div`
  background-color: ${colors.green};
  border-radius: 100%;
  overflow: hidden;
  height: 58px;
  width: 58px;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    padding: 15px 15px 15px 15px;
  }
`;

export const OptionsBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const OptionsBoxCheck = styled.div<Props>`
  margin-top: 30px;
  background-color: ${(props) => (props.annualPlan ? `${colors.green}` : '#F5F1FE')};
  color: ${(props) => (props.annualPlan ? '#FFFFFF' : '#000B4C')};
  display: flex;
  justify-content: start;
  padding: 11px 11px 11px 11px;
  margin-left: 15px;
  border-radius: 5px;
  min-width: 170px;
  min-height: 46px;

  span {
    display: flex;
    justify-content: start;
    font-size: 18px;
    font-weight: 600;
  }
`;

export const SelectPlan = styled(CheckBox)`
  &.MuiSvgIcon-root {
    width: 30px !important;
    height: 30px !important;
  }
`;

export const Discount = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 170px;
  margin-top: 5px;

  span {
    padding-top: 10px;
    padding-right: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    display: flex;
    align-items: center;
  }
`;

export const PlanIsLoading = styled.div`
  min-height: 700px;
  margin-top: 50px;
  margin-bottom: 15px;
  padding: 20px 20px 20px 20px;
`;
