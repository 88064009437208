import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import getPlansInfo from 'src/services/PlansService';
import Skeleton from 'react-loading-skeleton';
import MainNavBar from '../../components/MainNavBar';
import { useGlobal } from '../../hooks/useGlobal';
import {
  Container,
  PanelPlans,
  BuyIconContainer,
  OptionsPlanIcon,
  OptionsPlanText,
  OptionsBox,
  OptionsBoxCheck,
  SelectPlan,
  Discount,
  PlanIsLoading,
} from './style';
import icons from '../../assets/icons';
import PlanCard from '../../components/PlanCard';

const defaultPlanValues = {
  name: '',
  price: '',
  stars: 1,
  currency: '',
  recurrence_type: '',
  button: '',
  features_limited: [],
  features: [],
};

const SkeletonPlans = ({ showCards = 3 }) => (
  <>
    {[...Array(showCards)].map(() => (
      <PlanIsLoading className="col-4">
        <Skeleton height={50} enableAnimation style={{ marginRight: '15px', marginLeft: '15px' }} />
        <Skeleton height={623} enableAnimation style={{ marginRight: '15px', marginLeft: '15px' }} />
      </PlanIsLoading>
    ))}
  </>
);

const Plans: FC = () => {
  const { sideMenuIsOpen } = useGlobal();
  const { t } = useTranslation('pages');
  const [listPlans, setListPlans] = useState([defaultPlanValues]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getPlansInfo().then((response) => setListPlans(response.data))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <>
      <MainNavBar />
      <Container className={sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'}>
        <PanelPlans>
          <OptionsPlanIcon>
            <BuyIconContainer>
              <img src={icons.buy} alt="buy" />
            </BuyIconContainer>
          </OptionsPlanIcon>
          <OptionsPlanText className="mt-2">
            <h1>{t('Plans.choose_plan')}</h1>
          </OptionsPlanText>
          <OptionsPlanText className="mt-2">
            <h3>{t('Plans.cancel_anytime')}</h3>
          </OptionsPlanText>
          <OptionsBox className="d-none">
            <OptionsBoxCheck annualPlan>
              <SelectPlan name="checked-annualPlan" />
              <span>{t('Plans.annual_plan')}</span>
            </OptionsBoxCheck>
            <OptionsBoxCheck>
              <SelectPlan name="checked-plan" />
              <span>{t('Plans.monthly_plan')}</span>
            </OptionsBoxCheck>
          </OptionsBox>
          <Discount className="d-none">
            <span>
              {t('Plans.discount')}
              {' '}
              15%
            </span>
            <img src={icons.curvedArrow} alt="Curved" />
          </Discount>
          <div className="row m-lg-5 m-md-5">
            {isLoading
              ? (
                <SkeletonPlans />
              )
              : listPlans.map((infoPlan) => (
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <PlanCard
                    planName={infoPlan.name}
                    planPrice={infoPlan.price}
                    planCurrency={infoPlan.currency}
                    stars={infoPlan.stars}
                    featuresLimited={infoPlan.features_limited}
                    btnText={infoPlan.button}
                    features={infoPlan.features}
                  />
                </div>
              ))}
          </div>
        </PanelPlans>
      </Container>
    </>
  );
};

export default Plans;
