/* eslint-disable max-len */
/* eslint-disable camelcase,import/no-duplicates */
import React, {
  useState, useEffect, useContext, useCallback, FC,
} from 'react';
import { useState as HookUseState, State } from '@hookstate/core';
import { useTranslation } from 'react-i18next';
import Icons from 'src/assets/icons';
import { mixpanelTrack } from 'src/services/MixPanelService';
import UploadCustom from 'src/components/UploadCustom';
import generateNames from 'src/utils/generateNames';
import ModalMapSelect from 'src/components/ModalMapSelect';
import ModalPrefixGenerator from 'src/components/ModalPrefixGenerator';
import Quantity from 'src/components/Quantity';
import Radio from '@material-ui/core/Radio';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import {
  format,
  addDays,
  parse,
} from 'date-fns';
import {
  Checkbox, FormControlLabel,
} from '@material-ui/core';
import Select from 'src/components/Select';
import Input from 'src/components/Input';
import Api from 'src/services/api';
import { v4 } from 'uuid';
import Loader from 'src/components/Loader';
import { BiInfoCircle } from 'react-icons/bi';
import COLORS from 'src/contracts/constants/colors.constants';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import icons from 'src/assets/icons';
import Container, {
  HeadCount,
  MaxOccupation,
  RadioGroup,
  Buttons,
  PositionsColumn,
  AvailabilityHeader,
  AvailabilityBody,
  ButtonsSeatTools, DeleteButton,
} from './style';
import KeyToCamel from '../../../../utils/keysToCamelCase';
import ConfirmExitModal from '../../../../components/ConfirmExitModal';
import { useToast } from '../../../../hooks/toast';
import ColorPicker from '../../../../components/ColorPicker';
import ButtonLoader from '../../../../components/ButtonLoader';
import TeamService from '../../../../services/TeamService';
import PlacementService from '../../../../services/PlacementService';
import { AddLocationContext } from '../../Context';
import PositionsAvailabilityContainer from './PositionsAvailability';
import FormValidator from './FormValidator';
import ModalConfirm from '../../../../components/ConfirmModal';
import { DEFAULT_END_TIME } from '../../../../contracts/constants/times.constants';
import useCalculateMaxOccupationPercent from './hooks/useCalculateMaxOccupationPercent';
import { MAP_LIMIT_MB } from '../../../../components/Onboarding/contracts/constants';
import TextArea from '../../../../components/TextArea';
import { SubTitle } from '../../style';

interface SubmitInterface {
  id?: number,
  name: string,
  location_id: string | number,
  type: string,
  max_vacancy: string | number,
  open_days: string,
  color: string,
  days: any,
  teams: any,
  seats: any,
  identified_shifts: boolean,
  teams_vacancy_limit_type: number,
  maximum_occupation: number | undefined,
  seats_size: number,
  description?: string,
}

const DEFAULT_LIMIT_MAXIMUM_OCCUPATION_VALUE: number = 0;

interface addPositionProps {
  setSelectedPlacementId?: React.Dispatch<React.SetStateAction<number>>;
  setSpaceName?: React.Dispatch<React.SetStateAction<string>>;
  setSpaceVacancie?: React.Dispatch<React.SetStateAction<number>>;
  setPlacementIdEdited?: React.Dispatch<React.SetStateAction<number>>;
  spaceId?: number;
  selectedPlacementId?: number
}
const AddPositionTab: FC<addPositionProps> = ({
  setSelectedPlacementId, setSpaceVacancie, setSpaceName, spaceId, setPlacementIdEdited, selectedPlacementId,
}) => {
  const { t } = useTranslation('pages');
  const { addToast } = useToast();
  const {
    locationId, editMode, selectedPlacement, setSelectedPlacement, locationName,
  } = useContext(AddLocationContext);
  const [availableTeams, setAvailableTeams] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState<boolean>(false);

  const weekDaysAbbreviations = [
    'MON', 'TUE', 'WED', 'THU',
    'FRI', 'SAT', 'SUN',
  ];

  const { radius, handleSetRadius } = useContext(AddLocationContext);

  const [exitModal, setExitModal] = useState(false);
  const [prefixModal, setPrefixModal] = useState(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isDeleteMap, setIsDeleteMap] = useState(false);
  const [isPlacementIdentifiedShifts, setIsPlacementIdentifiedShifts] = useState(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [availabilityToRemove, setAvailabilityToRemove] = useState<any>(null);
  const [positionName, setPositionName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [selectedType, setSelectedType] = useState<any>({
    label: t('AddLocation.tabs.AddPosition.position_type_options.table'),
    value: 'TABLE',
  });
  const [selectedTeams, setSelectedTeams] = useState<any>();
  const [limitHeadcount, setLimitHeadcount] = useState<number>(1);
  const [multipleTeamsOptionSelected, setMultipleTeamsOptionSelected] = useState(2);
  const [positionsHasId, setPositionsHasId] = useState(false);
  const [positionColor, setPositionColor] = useState('#f8b2b4');
  const [mapFile, setMapFile] = useState<{
    uri: string | null;
    file: File | null;
  }>();
  const [hasMap, setHasMap] = useState<boolean>(false);
  const [openMapSelector, setOpenMapSelector] = useState<boolean>(false);
  const [limitMaximumOccupation, setLimitMaximumOccupation] = useState<number>(DEFAULT_LIMIT_MAXIMUM_OCCUPATION_VALUE);
  const [teamsRegistered, setTeamsRegistered] = useState<any>([]);

  const seatsIdentifiersState: any = HookUseState([]);
  const defaultMapLink = HookUseState<string | null>(null);
  const provisoryMapLink = HookUseState<string | null>(null);
  const seatsIdentifiersBackupState: any = HookUseState([]);
  const occupations: any = HookUseState([]);
  const positionsAvailability: any = HookUseState([{
    id: undefined,
    selectedDays: [0, 1, 2, 3, 4],
    equalDivided: true,
    schedule: Array(7).fill({
      start: '08:00:00', end: '18:00:00', fullTime: false, id: null,
    }),
    startDate: format(new Date(), 'dd/MM/yyyy'),
    endDate: null,
    hasShift: false,
  }]);

  const openedAvailability = HookUseState([true]);

  const [firstSelectedDayIndex, setFirstSelectedDayIndex] = useState(0);
  const [
    defaultIdentifiersAssociatedReservations,
    setDefaultIdentifiersAssociatedReservations,
  ] = useState<any>([]);

  const findWithAttr = (array: any, attr: string, value: boolean) => {
    for (let i = 0; i < array.length; i += 1) {
      if (array[i][attr] === value) {
        return i;
      }
    }
    return 0;
  };

  const ValidateLimitVacancies = (valueInput: number, indexInput: number) => {
    if (multipleTeamsOptionSelected && selectedTeams?.length && occupations) {
      let total = 0;
      occupations.map((value: { get: () => any; }, index: number) => {
        if (index === indexInput) {
          total += valueInput;
        } else {
          total += Number(value.get());
        }
        return true;
      });
      const newLimit = limitHeadcount - total;
      return newLimit >= 0;
    }

    return false;
  };

  const getLimitVacancies = () => {
    let total = 0;

    occupations.map((value: { get: () => any; }) => {
      total += Number(value.get());
      return true;
    });

    return limitHeadcount - total;
  };

  const buildDefaultInputValues = (object: any) => {
    setPositionName(object.name);
    setLimitHeadcount(object.maxVacancy);
    setPositionColor(`#${object.color}`);
    setHasMap(Boolean(object.map));
    defaultMapLink.set(object.map);
    const teamsVacancyLimitType = object?.teamsVacancyLimitType ? object?.teamsVacancyLimitType : 0;
    setMultipleTeamsOptionSelected(teamsVacancyLimitType);
    setSelectedType({ label: t('AddLocation.tabs.AddPosition.position_type_options.table'), value: 'TABLE' });
    setLimitMaximumOccupation(object.maximumOccupation ?? DEFAULT_LIMIT_MAXIMUM_OCCUPATION_VALUE);

    const defaultTeamsKeys = Object.keys(object.teams);
    const defaultTeamsValues: any = Object.values(object.teams);
    const defaultSelectedTeams: any = [];
    const defaultOccupations: any[] = [];
    availableTeams.forEach((team: { id: number }) => {
      if (defaultTeamsKeys.includes(team.id.toString())) {
        defaultSelectedTeams.push(team);
      }
    });

    availableTeams.forEach((a: any, index: any) => {
      defaultOccupations.push(defaultTeamsValues?.[index]?.maxVacancy);
    });
    occupations.set(defaultOccupations);

    setSelectedTeams(availableTeams.filter(
      (team: { id: number }) => defaultTeamsKeys.includes(team.id.toString()),
    ));

    const selectedTeamsWithIndex: any = {};
    defaultTeamsValues.forEach((team: any) => {
      selectedTeamsWithIndex[team.id] = team;
    });
    setTeamsRegistered(selectedTeamsWithIndex);

    handleSetRadius(object.seatsSize !== null ? object.seatsSize : radius);

    if (object.placementIdentifiedShifts) {
      setPositionsHasId(object.placementIdentifiedShifts);
      const identifiersAssociatedReservations = Object.values(object.seatsAssociatedReservations);
      const defaultIdentifiersValues = Object.values(object.seatsName);
      const defaultIdentifiersKeys = Object.keys(object.seatsName);
      const defaultIdentifiersCoordinates = Object.values(object.seatsCoordinates);
      const defaultIdentifiers: any = [];
      for (let iterator = 0; iterator < defaultIdentifiersValues.length; iterator += 1) {
        defaultIdentifiers.push({
          id: defaultIdentifiersKeys[iterator],
          name: defaultIdentifiersValues[iterator],
          position: defaultIdentifiersCoordinates[iterator],
        });
      }
      seatsIdentifiersState.set(defaultIdentifiers);
      seatsIdentifiersBackupState.set(defaultIdentifiers);
      setDefaultIdentifiersAssociatedReservations(identifiersAssociatedReservations);
    }

    const defaultPositionsAvailability: any = [];
    Object.values(object.openDays).forEach((item: any) => {
      const newObject: any = {
        id: item.id || undefined,
        equalDivided: true,
        startDate: format(parse(item.start, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy'),
        endDate: item.end ? format(parse(item.end, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy') : null,
        selectedDays: null,
        hasShift: item.hasShift,
      };

      const defaultSelectedDays: number[] = [];
      const defaultDaysHour: any = Array(7).fill({
        start: '00:00:00', end: null, fullTime: false,
      });
      let initialStartHour: any = null;
      let initialEndHour: any = null;
      let hasDiffStartHour = false;
      let hasDiffEndHour = false;

      Object.values(item.days).forEach((dayData: any, position: number) => {
        if (position === 0) {
          initialStartHour = dayData.start;
          initialEndHour = dayData.end;
        } else {
          if (initialStartHour !== dayData.start) hasDiffStartHour = true;
          if (initialEndHour !== dayData.end) hasDiffEndHour = true;
        }
        switch (dayData.weekday) {
          case 'MON':
            defaultSelectedDays.push(0);
            defaultDaysHour[0] = {
              id: dayData?.id,
              start: dayData.start,
              end: dayData.end,
              fullTime: !(dayData.end),
              exists: true,
            };
            break;
          case 'TUE':
            defaultSelectedDays.push(1);
            defaultDaysHour[1] = {
              id: dayData?.id,
              start: dayData.start,
              end: dayData.end,
              fullTime: !(dayData.end),
              exists: true,
            };
            break;
          case 'WED':
            defaultSelectedDays.push(2);
            defaultDaysHour[2] = {
              id: dayData?.id,
              start: dayData.start,
              end: dayData.end,
              fullTime: !(dayData.end),
              exists: true,
            };
            break;
          case 'THU':
            defaultSelectedDays.push(3);
            defaultDaysHour[3] = {
              id: dayData?.id,
              start: dayData.start,
              end: dayData.end,
              fullTime: !(dayData.end),
              exists: true,
            };
            break;
          case 'FRI':
            defaultSelectedDays.push(4);
            defaultDaysHour[4] = {
              id: dayData?.id,
              start: dayData.start,
              end: dayData.end,
              fullTime: !(dayData.end),
              exists: true,
            };
            break;
          case 'SAT':
            defaultSelectedDays.push(5);
            defaultDaysHour[5] = {
              id: dayData?.id,
              start: dayData.start,
              end: dayData.end,
              fullTime: !(dayData.end),
              exists: true,
            };
            break;
          case 'SUN':
            defaultSelectedDays.push(6);
            defaultDaysHour[6] = {
              id: dayData?.id,
              start: dayData.start,
              end: dayData.end,
              fullTime: !(dayData.end),
              exists: true,
            };
            break;
          default:
            break;
        }
      });

      if (hasDiffStartHour || hasDiffEndHour) {
        newObject.equalDivided = false;
      }
      newObject.selectedDays = defaultSelectedDays;
      newObject.schedule = defaultDaysHour;
      defaultPositionsAvailability.push(newObject);
      setFirstSelectedDayIndex(findWithAttr(defaultDaysHour, 'exists', true));
    });
    positionsAvailability.set(defaultPositionsAvailability);
    setIsLoading(false);
  };

  const getTotalemployees = (object: any[]) => {
    let total = 0;
    if (object) {
      object.map((item) => {
        total += item.headcount;
        return true;
      });
    }
    return total;
  };

  const calculateIndividualMaxOccupationPercent = (object: any[], max: number) => {
    const total = getTotalemployees(object);
    if (total !== 0 && max !== 0) {
      const calc = (total * 100) / max;
      return Math.round(calc);
    }
    return total;
  };

  const dateConverter = (dateString: string) => {
    const Day = dateString?.split('/')[0];
    const Month = dateString?.split('/')[1];
    const Year = dateString?.split('/')[2];
    return parse(`${Year}-${Month}-${Day}`, 'yyyy-MM-dd', new Date());
  };

  const hourFormatter = (hour: string) => {
    if (hour.length === 5) {
      return `${hour}:00`;
    }
    return hour;
  };

  const submit = async (payload: any) => {
    try {
      const response = await Api.post('placements', payload);
      if (setSelectedPlacementId) setSelectedPlacementId(Number(response.data.id));
      if (!editMode) {
        setSelectedPlacement?.(response.data.id);
      }
      if (mapFile && mapFile.file) {
        const mapForm = new FormData();
        mapForm.append('map', mapFile.file);
        await mapForm.append('placement_id', editMode ? selectedPlacement : response.data.id);
        await PlacementService.createEditMap(mapForm).finally(() => setIsLoadingSubmit(false));
      } else {
        setIsLoadingSubmit(false);
      }
      addToast({
        description: t('AddLocation.tabs.AddPosition.success'),
        type: 'success',
      });
    } catch (error) {
      setIsLoadingSubmit(false);
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.creation'),
        type: 'error',
      });
    }
  };

  const setPositionsCoordinatesNull = () => {
    seatsIdentifiersState.get().map((_: any, index: number) => {
      seatsIdentifiersState[index].position.set({ position_x: null, position_y: null });
      return null;
    });
  };
  const handleDeleteMap = async () => {
    if (defaultMapLink.get()) {
      try {
        const placementId = selectedPlacement;
        await PlacementService.deleteMap(placementId);
        addToast({
          description: t('AddLocation.tabs.AddPosition.success_delete_map'),
          type: 'success',
        });
        defaultMapLink.set(null);
        provisoryMapLink.set(null);
        setHasMap(false);
        setPositionsCoordinatesNull();
      } catch (error) {
        addToast({
          description: t('AddLocation.tabs.AddPosition.error_delete_map'),
          type: 'error',
        });
      }
    }
    setIsDeleteMap(false);
  };

  const getMaxVacancy = () => {
    if (multipleTeamsOptionSelected === 0) {
      return Math.round(limitHeadcount / selectedTeams.length);
    }

    if (selectedTeams?.length <= 1 || multipleTeamsOptionSelected === 2) {
      return 0;
    }

    return 0;
  };

  const equalEndOrStart = (
    firstStart: Date,
    firstEnd: Date,
    secondStart: Date,
    secondEnd: Date,
  ) => {
    if (firstStart.toDateString() === secondEnd.toDateString()
      || firstStart.toDateString() === secondStart.toDateString()) {
      return true;
    }

    return secondStart.toDateString() === firstEnd.toDateString()
      || firstEnd.toDateString() === secondEnd.toDateString();
  };

  const hasOverLappingOnPeriods = () => {
    try {
      const dates: { start: string, end: string | null }[] = [];

      positionsAvailability.get().forEach((availability: any) => {
        dates.push(
          {
            start: format(dateConverter(availability.startDate), 'yyyy-MM-dd'),
            end: availability.endDate ? format(dateConverter(availability.endDate), 'yyyy-MM-dd') : null,
          },
        );
      });

      for (let i = 0; i < dates.length; i += 1) {
        const startAsDate = new Date(dates[i].start);
        const endAsDate = dates[i]?.end ? new Date(dates[i].end!) : startAsDate;
        for (let j = i + 1; j < dates.length; j += 1) {
          const secondStartAsDate = new Date(dates[j].start);
          const secondEndAsDate = dates[j]?.end ? new Date(dates[j].end!) : secondStartAsDate;
          const hasOverlapping = areIntervalsOverlapping(
            { start: startAsDate, end: endAsDate },
            {
              start: secondStartAsDate,
              end: secondEndAsDate,
            },
          );

          const hasEqualDates = equalEndOrStart(
            startAsDate,
            endAsDate,
            secondStartAsDate,
            secondEndAsDate,
          );
          if (hasOverlapping || hasEqualDates) {
            return true;
          }
        }
      }
      return false;
    } catch (e) {
      return true;
    }
  };

  const canDecreaseNumberSeatsOrChangeNumberMaxVacancy = (maxVacancy: number | undefined) => {
    if (maxVacancy === undefined) {
      return false;
    }
    const seatsCountWithAssociatedReservations = defaultIdentifiersAssociatedReservations?.length;
    const result = seatsCountWithAssociatedReservations <= maxVacancy;
    const canChangeMaxVacancy = maxVacancy > 0 && maxVacancy < 106;

    if (!result) {
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.decrease_number'),
        type: 'error',
      });

      return result;
    }

    if (!canChangeMaxVacancy) {
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.limit_vacancy'),
        type: 'error',
      });

      return canChangeMaxVacancy;
    }

    return true;
  };

  const HandleSubmit = () => {
    let limitMaximumOccupationFloat;

    if (limitMaximumOccupation !== undefined && limitMaximumOccupation !== null) {
      limitMaximumOccupationFloat = limitMaximumOccupation;

      if (limitMaximumOccupationFloat === 0) {
        limitMaximumOccupationFloat = undefined;
      }
    }

    setIsLoadingSubmit(true);
    const isInvalid = FormValidator(
      positionName,
      limitHeadcount,
      selectedTeams,
      selectedType,
      multipleTeamsOptionSelected,
      occupations,
      positionsHasId,
      seatsIdentifiersState,
      positionsAvailability,
      addToast,
      t,
      firstSelectedDayIndex,
      limitMaximumOccupationFloat,
    );

    if (!isInvalid) {
      if (hasOverLappingOnPeriods()) {
        setIsLoadingSubmit(false);
        return addToast({
          description: t('AddLocation.tabs.AddPosition.error.period_overlapping_error'),
          type: 'error',
        });
      }

      const body: SubmitInterface = {
        id: editMode ? selectedPlacement : selectedPlacementId || undefined,
        name: positionName,
        location_id: locationId as number,
        type: 'table',
        max_vacancy: limitHeadcount,
        open_days: '1',
        color: positionColor.replace('#', ''),
        days: [],
        teams: null,
        seats: [],
        identified_shifts: positionsHasId,
        teams_vacancy_limit_type: multipleTeamsOptionSelected,
        maximum_occupation: limitMaximumOccupationFloat,
        seats_size: radius,
        description,
      };

      const teams: { [key: string]: any } = {};
      let maxVacancy = getMaxVacancy();

      selectedTeams.map((team: { id: string | number; }, index: number) => {
        if (multipleTeamsOptionSelected === 1) {
          maxVacancy = Number(occupations?.[index].get());
        }

        if (!Object.prototype.hasOwnProperty.call(teamsRegistered, team.id)) {
          teams[team.id] = {
            max_vacancy: maxVacancy,
            is_relay: true,
            max_reservation_per_employee: 0,
          };
        }

        if (Object.prototype.hasOwnProperty.call(teamsRegistered, team.id)) {
          teams[team.id] = {
            max_vacancy: maxVacancy,
            is_relay: teamsRegistered[team.id as number].isRelay,
            max_reservation_per_employee: 0,
          };
        }
        return true;
      });
      body.teams = teams;
      if (positionsHasId) {
        const seats: {
          name: any;
          position_x?: any;
          position_y?: any;
          id: string | number | null
        }[] = [];
        seatsIdentifiersState.map((item: any) => seats.push({
          id: item.get().id ? Number(item.get().id) : null,
          name: item.get().name,
          position_x: item.get()?.position?.positionX,
          position_y: item.get()?.position?.positionY,
        }));
        body.seats = seats;
      }

      positionsAvailability.get().forEach((availabilities: any) => {
        const structure: any = {
          id: availabilities.id || undefined,
          start: availabilities.startDate && format(dateConverter(availabilities.startDate), 'yyyy-MM-dd'),
          end: availabilities.endDate ? format(dateConverter(availabilities.endDate), 'yyyy-MM-dd') : null,
          days: [],
        };

        const { schedule, selectedDays } = availabilities;
        selectedDays.forEach((day: number) => {
          const defaultIndex = availabilities.equalDivided ? firstSelectedDayIndex : day;
          const days = {
            id: schedule[day].id,
            weekday: weekDaysAbbreviations[day],
            start: hourFormatter(schedule[defaultIndex].start),
            end: !schedule[defaultIndex].fullTime
              ? hourFormatter(schedule[defaultIndex].end)
              : DEFAULT_END_TIME,
          };
          structure.days.push(days);
        });
        body.days.push(structure);
      });

      return submit(body);
    }
    return setIsLoadingSubmit(false);
  };

  const addNewPositionsAvailability = () => {
    const lastChild = positionsAvailability.get().length;
    if (!positionsAvailability.get()[lastChild - 1].endDate) {
      const todayDay = new Date();
      const lastStartDay = positionsAvailability.get()[lastChild - 1].startDate;
      const lastStartDayDate = parse(lastStartDay, 'dd/MM/yyyy', new Date());
      if (todayDay >= lastStartDayDate) {
        positionsAvailability[lastChild - 1].endDate.set(format(addDays(lastStartDayDate, 1), 'dd/MM/yyyy'));
      } else {
        positionsAvailability[lastChild - 1].endDate.set(format(new Date(), 'dd/MM/yyyy'));
      }
    }

    const separatedDateValues = positionsAvailability[lastChild - 1].endDate.get().split('/');

    positionsAvailability.merge([{
      id: undefined,
      selectedDays: [0, 1, 2, 3, 4],
      equalDivided: true,
      schedule: Array(7).fill({ start: '00:00:00', end: null, fullTime: true }),
      startDate: format(
        addDays(new Date(separatedDateValues[2],
          Number(separatedDateValues[1]) - 1,
          separatedDateValues[0]), 1),
        'dd/MM/yyyy',
      ),
      endDate: null,
      hasShift: false,
    }]);
  };

  useEffect(() => {
    TeamService.getTeamFromCompany().then((res) => {
      const dataArray: any = [];
      Object.values(res.data).map((responseTeam: any) => dataArray.push({
        label: responseTeam.name,
        id: responseTeam.id,
        value: String(responseTeam.id),
        headcount: responseTeam.employeesCount || 0,
      }));
      setAvailableTeams(dataArray);
    });
  }, []);

  const removeAvailability = () => {
    const total = positionsAvailability.get().length;
    const newPositionsAvailability = [];
    const originalIndex = total - availabilityToRemove - 1;

    for (let i = 0; i < total; i += 1) {
      if (i !== originalIndex) {
        const availability = positionsAvailability.get()[i];
        newPositionsAvailability.push({
          id: availability.id,
          startDate: availability.startDate,
          endDate: availability.endDate,
          equalDivided: availability.equalDivided,
          hasShift: availability.hasShift,
          schedule: availability.schedule.map((item: any) => ({
            id: item?.id,
            start: item.start,
            end: item.end,
            fullTime: item.fullTime,
          })),
          selectedDays: availability.selectedDays.map((item: any) => (item)),
        });
      }
    }
    setIsOpenConfirm(false);
    return positionsAvailability.set(newPositionsAvailability);
  };

  useEffect(() => {
    if (!isLoadingSubmit) {
      if (availableTeams && editMode) {
        PlacementService.getPlacementsFromId(selectedPlacement.toString()).then((response) => {
          buildDefaultInputValues(KeyToCamel(response.data));
        }).catch(() => {
          addToast({
            description: t('AddLocation.tabs.AddPosition.error.error_on_load'),
            type: 'error',
          });
        });
      } else {
        setPositionsHasId(true);
        setIsLoading(false);
      }
    }
  }, [availableTeams, editMode, isLoadingSubmit]);

  useEffect(() => {
    const newOccupation: any = [];

    if (selectedTeams?.length) {
      for (let i = 0; i < selectedTeams.length; i += 1) {
        if (occupations?.[i]?.get()) {
          newOccupation.push(occupations?.[i]?.get());
        } else {
          newOccupation.push(0);
        }
      }
    }

    occupations.set(newOccupation);
  }, [selectedTeams]);

  useEffect(() => {
    const newIdentifiers = [];
    for (let i = 0; i < limitHeadcount; i += 1) {
      newIdentifiers.push({
        id: seatsIdentifiersState[i]?.get()?.id
          || seatsIdentifiersBackupState[i]?.get()?.id
          || v4(),
        name: seatsIdentifiersState[i]?.get()?.name
          || seatsIdentifiersBackupState[i]?.get()?.name
          || String(i + 1),
        position: seatsIdentifiersState[i]?.get()?.position
          ? seatsIdentifiersBackupState[i]?.get()?.position
          : null,
      });
    }
    return seatsIdentifiersState.set(newIdentifiers);
  }, [limitHeadcount]);

  const handlePrefixSeats = (prefix: string, size: number) => {
    const names = generateNames(prefix, size, seatsIdentifiersState.get().length);
    seatsIdentifiersState.set(
      (prev: any) => prev.map((seat: any, index: number) => ({
        ...seat,
        name: names[index],
      })),
    );
  };

  function TeamOccupationInput({ fieldState, index }: { fieldState: State<string>, index: number }) {
    const scoped = HookUseState(fieldState);
    const limitVacancies = getLimitVacancies();
    return (
      <Quantity
        onChange={(value) => {
          if (ValidateLimitVacancies(value, index)) {
            scoped.set(String(value));
          }
        }}
        value={Number(scoped.get()) || 0}
        limit={Number(scoped.get()) + limitVacancies}
      />
    );
  }

  const buildModalPhrase = (id: number) => {
    let phrase = '';
    const total = positionsAvailability.get().length;
    const originalIndex = total - (id - 1) - 1;
    const availability = positionsAvailability.get()[originalIndex];
    phrase = `${t('AddLocation.tabs.AddPosition.delete_advise_start')} ${availability.startDate} `;
    if (availability.endDate) {
      phrase += `${t('AddLocation.tabs.AddPosition.delete_advise_end')} ${availability.endDate}`;
    }
    setAvailabilityToRemove(originalIndex);
    setModalMessage((phrase += '?'));
  };

  useEffect(() => {
    if (modalMessage.length > 0) {
      setIsOpenConfirm(true);
    }
  }, [modalMessage]);

  function deleteSeat(seatId: number): void {
    const indexSeat = seatsIdentifiersState.findIndex((seat: any) => Number(seat.get().id) === +seatId);
    if (indexSeat > -1) {
      seatsIdentifiersState.set((seat: any) => {
        seat.splice(+indexSeat, 1);
        return seat;
      });
      setLimitHeadcount(limitHeadcount - 1);
    }
  }

  function canShowOptionToDeleteSeat(seatId: number): boolean {
    return !defaultIdentifiersAssociatedReservations.includes(+seatId);
  }

  const handlePlacementIdentifiedShifts = useCallback((value: boolean) => {
    if (value) {
      setIsPlacementIdentifiedShifts(true);
      return;
    }
    setPositionsHasId(true);
    setIsPlacementIdentifiedShifts(false);
  }, []);

  const handleCloseButtonRemoveAvailabilityModal = useCallback(() => {
    setAvailabilityToRemove(null);
    setIsOpenConfirm(false);
    setModalMessage('');
  }, []);

  const handleCloseButtonDeleteMapModal = useCallback(() => {
    setIsDeleteMap(false);
  }, []);

  const handleConfirmPlacementIdentifiedShiftsModal = useCallback(() => {
    setPositionsHasId(false);
    setIsPlacementIdentifiedShifts(false);
  }, []);

  const handleCloseButtonPlacementIdentifiedShiftsModal = useCallback(() => {
    setPositionsHasId(true);
    setIsPlacementIdentifiedShifts(false);
  }, []);

  const handleSetMap = (file: File | null) => {
    if (file && file!.size / MAP_LIMIT_MB > 5) {
      addToast({
        description: t('AddLocation.tabs.AddPosition.error.file_too_large'),
        type: 'error',
      });
      return;
    }

    if (file) {
      setMapFile({ file, uri: null });
      provisoryMapLink.set(URL.createObjectURL(file));
      defaultMapLink.set(URL.createObjectURL(file));
    }
  };

  useEffect(() => {
    if (setPlacementIdEdited) {
      setPlacementIdEdited(spaceId ?? 0);
    }
    if (setSpaceName) {
      setSpaceName(positionName);
    }
  }, [positionName]);

  return (
    <div>
      {!isLoading ? (
        <>
          <div className="row">
            <div className="col-8 mt-2">
              <SubTitle className="mb-0">
                {t('Location.general_info.vacancie_description')}
              </SubTitle>
            </div>
          </div>
          <Container className="d-flex flex-column">
            <ConfirmExitModal
              state={exitModal}
              setState={setExitModal}
              route="/spaces"
            />
            {isOpenConfirm && (
              <ModalConfirm
                isOpen={isOpenConfirm}
                handleConfirm={removeAvailability}
                handleCloseButton={handleCloseButtonRemoveAvailabilityModal}
                message={modalMessage}
              />
            )}
            {isDeleteMap && (
              <ModalConfirm
                isOpen={isDeleteMap}
                handleConfirm={handleDeleteMap}
                handleCloseButton={handleCloseButtonDeleteMapModal}
                message={t('AddLocation.tabs.AddPosition.remove_confirm_map')}
              />
            )}
            {positionsHasId && isPlacementIdentifiedShifts && (
              <ModalConfirm
                isOpen={isPlacementIdentifiedShifts}
                handleConfirm={handleConfirmPlacementIdentifiedShiftsModal}
                handleCloseButton={handleCloseButtonPlacementIdentifiedShiftsModal}
                message={t('AddLocation.tabs.AddPosition.checkbox.reservations_with_identification_info')}
              />
            )}
            <div className="row mt-4">
              <div className="col-md-4 col-sm-12">
                <Input
                  autoFocus
                  value={positionName}
                  placeholder={t('AddLocation.tabs.AddPosition.placeholder')}
                  onChange={(e) => {
                    setPositionName(e.target.value);
                  }}
                  label={t('AddLocation.tabs.AddPosition.position_names')}
                  style={{
                    width: '100%',
                  }}
                />
              </div>
              <div className="col-md-5 col-sm-12">
                <TextArea
                  label={t('AddLocation.tabs.AddPosition.space_description')}
                  className="w-100"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
              <div className="col-md-3 col-sm-12">
                <Select
                  isMulti
                  options={availableTeams}
                  value={selectedTeams}
                  label={t('AddLocation.tabs.AddPosition.teams_with_access')}
                  allOption={{
                    label: t('AddLocation.tabs.AddPosition.all_teams'),
                    value: '',
                  }}
                  onChange={(selected) => {
                    setSelectedTeams(selected);
                  }}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4 col-sm-12">
                <div>
                  <h3>
                    {t('AddLocation.tabs.AddPosition.color_of_positions')}
                  </h3>
                </div>
                <ColorPicker
                  color={positionColor}
                  onChangeColor={(color: string) => setPositionColor(color)}
                />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4 col-sm-12">
                <div>
                  <h3>{t('AddLocation.tabs.AddPosition.location_map')}</h3>
                </div>
                <div className="mt-3">
                  <div>
                    <FormControlLabel
                      checked={hasMap}
                      control={<Checkbox name="checkedM" color="primary" />}
                      label={(
                        <div className="w-100 d-flex justify-content-between align-self-end">
                          {t('AddLocation.tabs.AddPosition.checkbox.attach_space_map_image')}
                          <BiInfoCircle
                            size={18}
                            color={COLORS.mutedColor}
                            id="checkedM"
                            style={{
                              cursor: 'pointer',
                              marginLeft: '10px',
                            }}
                          />
                        </div>
                      )}
                      onChange={() => setHasMap(!hasMap)}
                    />
                    <article>
                      <UncontrolledPopover
                        flip
                        target="checkedM"
                        trigger="hover"
                        placement="bottom"
                      >
                        <PopoverBody>
                          {t('AddLocation.tabs.AddPosition.info_attach_map')}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </article>
                  </div>
                  {hasMap && (
                    <div>
                      <UploadCustom
                        label={t('AddLocation.tabs.AddPosition.map_image')}
                        popoverLabelInfo={t('AddLocation.tabs.AddPosition.map_image_popover_label')}
                        defaultLink={defaultMapLink.get()}
                        accept={['image/jpeg', 'image/pjpeg']}
                        onChange={(file: File | null) => {
                          handleSetMap(file);
                        }}
                        onLinkClick={() => {
                          window.open(
                            defaultMapLink.get()!,
                            'Image',
                          );
                        }}
                        onDelete={() => {
                          setMapFile({ file: null, uri: null });
                          if (defaultMapLink.get()) {
                            setIsDeleteMap(true);
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <PositionsColumn className="row mt-4">
              <div className="col-12 d-flex justify-content-between w-100 mb-2">
                <h3 className="align-self-end">{t('AddLocation.tabs.AddPosition.space_availability')}</h3>
                <ButtonLoader
                  onClick={() => addNewPositionsAvailability()}
                  color="primary"
                  uppercase={false}
                  label={t('AddLocation.tabs.AddPosition.add')}
                  icon={icons.addIconsSquare}
                />
              </div>
              <div className="col-md-8 col-sm-12 mt-2">
                <SubTitle className="mb-0">
                  {t('Location.general_info.period_description')}
                </SubTitle>
              </div>
              {positionsAvailability.map((object: State<any>, index: number) => (
                <div key={v4()} className="col-12 w-100 my-2">
                  <AvailabilityHeader
                    isOpen={openedAvailability[index].get()}
                    onClick={() => openedAvailability[index].set(!openedAvailability[index].get())}
                  >
                    <span>
                      {`${t('AddLocation.tabs.AddPosition.header')}
                      ${object.get().startDate}
                      ${object.get().endDate ? t('AddLocation.tabs.AddPosition.to') : ''}
                      ${object.get().endDate ? object.get().endDate : ''}
                      `}
                    </span>
                    <img src={Icons.WhiteCollapseArrow} alt="" />
                  </AvailabilityHeader>
                  <AvailabilityBody isOpen={openedAvailability[index].get()}>
                    <PositionsAvailabilityContainer
                      placementId={selectedPlacement}
                      firstSelectedDayIndex={firstSelectedDayIndex}
                      fieldState={object}
                      totalAvailabilities={positionsAvailability.length}
                      availabilityIndex={index + 1}
                      onClick={(value) => buildModalPhrase(value)}
                    />
                  </AvailabilityBody>
                </div>
              ))}
              <div style={{
                display: 'flex',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '2%',
              }}
              />
            </PositionsColumn>
            <div className="w-100 mt-3 d-lg-flex">
              <div className="mt-3 mt-lg-0">
                <Quantity
                  disabled={!selectedTeams?.length}
                  label={t('AddLocation.tabs.AddPosition.total_daily_positions')}
                  step={1}
                  onChange={(value: number) => {
                    mixpanelTrack('placement@edit-headcount', {
                      value,
                      headcount: getTotalemployees(selectedTeams),
                    });
                    if (setPlacementIdEdited) {
                      setPlacementIdEdited(spaceId ?? 0);
                    }
                    setLimitHeadcount(value);
                    if (setSpaceVacancie) {
                      setSpaceVacancie(value);
                    }
                  }}
                  limit={106}
                  value={limitHeadcount}
                  validator={canDecreaseNumberSeatsOrChangeNumberMaxVacancy}
                />
              </div>
              <div className="ms-lg-4 mt-3 mt-lg-0">
                <Quantity
                  label={t('AddLocation.tabs.AddPosition.limit_maximum_occupation')}
                  step={1}
                  onChange={(value: number) => {
                    mixpanelTrack('placement@edit-limit-maximum-occupation', {
                      percentage: value,
                    });
                    setLimitMaximumOccupation(value);
                  }}
                  limit={100}
                  value={limitMaximumOccupation}
                />
              </div>
            </div>
            <div className="w-100 mt-3 d-lg-flex">
              <div className="mt-3 mt-lg-0">
                <HeadCount>
                  <div className="d-flex flex-column justify-content-between">
                    <span>{t('AddLocation.tabs.AddPosition.headcount')}</span>
                    <div>
                      {t(
                        'AddLocation.tabs.AddPosition.total_headcount_employees',
                        {
                          totalemployees: getTotalemployees(selectedTeams),
                        },
                      )}
                    </div>
                  </div>
                </HeadCount>
              </div>
              <div className="ms-lg-4 mt-3 mt-lg-0">
                <MaxOccupation>
                  <div className="d-flex flex-column justify-content-between">
                    <span>{t('AddLocation.tabs.AddPosition.max_occupation')}</span>
                    <div>
                      {`${useCalculateMaxOccupationPercent(
                        {
                          employeesTotal: getTotalemployees(selectedTeams),
                          limitHeadcount,
                          limitMaximumOccupation,
                        },
                      )}%`}
                    </div>
                  </div>
                </MaxOccupation>
              </div>
            </div>
            {selectedTeams?.length > 1 && (
              <div className="mt-3">
                <div>
                  <h3>
                    {t('AddLocation.tabs.AddPosition.multiple_teams')}
                    <BiInfoCircle
                      size={18}
                      color={COLORS.mutedColor}
                      id="multiple_teams_info"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    />
                  </h3>
                  <UncontrolledPopover
                    flip
                    target="multiple_teams_info"
                    trigger="hover"
                    placement="auto"
                  >
                    <PopoverBody>
                      {t('AddLocation.tabs.AddPosition.multiple_teams_info')}
                    </PopoverBody>
                  </UncontrolledPopover>
                </div>
                <div className="mt-3">
                  <div>
                    <RadioGroup className="d-flex flex-column justify-content-start align-items-start mt-4">
                      <FormControlLabel
                        value="1"
                        checked={multipleTeamsOptionSelected === 0}
                        onChange={() => setMultipleTeamsOptionSelected(0)}
                        disabled={!selectedTeams || selectedTeams?.length <= 1}
                        control={<Radio color="primary" />}
                        label={t(
                          'AddLocation.tabs.AddPosition.radio.divide_vacancies_equally',
                        )}
                      />
                      <FormControlLabel
                        value="2"
                        checked={multipleTeamsOptionSelected === 1}
                        onChange={() => setMultipleTeamsOptionSelected(1)}
                        disabled={!selectedTeams || selectedTeams?.length <= 1}
                        control={<Radio color="primary" />}
                        label={t(
                          'AddLocation.tabs.AddPosition.radio.limit_maximum_team_slots',
                        )}
                      />
                      <FormControlLabel
                        value="3"
                        checked={multipleTeamsOptionSelected === 2}
                        onChange={() => setMultipleTeamsOptionSelected(2)}
                        disabled={!selectedTeams || selectedTeams?.length < 1}
                        control={<Radio color="primary" />}
                        label={t(
                          'AddLocation.tabs.AddPosition.radio.not_limit_vacancies',
                        )}
                      />
                    </RadioGroup>
                  </div>
                  {selectedTeams?.length > 1 && multipleTeamsOptionSelected === 1
                    && (
                      <div className="ms-4">
                        <table className="w-100">
                          <thead>
                            <tr>
                              <th>{t('AddLocation.tabs.AddPosition.teams_with_access')}</th>
                              <th>
                                {t('AddLocation.tabs.AddPosition.max_quantity_vacancy')}
                              </th>
                              <th>{t('AddLocation.tabs.AddPosition.headcount')}</th>
                              <th>{t('AddLocation.tabs.AddPosition.max_occupation')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedTeams?.length > 1 && selectedTeams?.map((team: {
                            headcount: number;
                            label: string
                          }, index: number) => (
                            <tr key={index.toString()}>
                              <td>
                                <div>{team.label}</div>
                              </td>
                              <td>
                                <TeamOccupationInput
                                  fieldState={occupations[index]}
                                  index={index}
                                />
                              </td>
                              <td>
                                <div>{team.headcount}</div>
                              </td>
                              <td>
                                <div>
                                  {`${calculateIndividualMaxOccupationPercent(
                                    [{ headcount: Number(occupations?.[index].get()) || 0 }],
                                    limitHeadcount,
                                  )}%`}
                                </div>
                              </td>
                            </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>
              </div>
            )}
            <div className="my-3">
              <h3>
                {t('AddLocation.tabs.AddPosition.identification_of_positions')}
              </h3>
            </div>
            <div className="mt-3">
              <FormControlLabel
                checked={positionsHasId}
                control={<Checkbox name="checkedB" color="primary" />}
                disabled={!selectedType || limitHeadcount === 0 || editMode}
                label={(
                  <>
                    {t('AddLocation.tabs.AddPosition.checkbox.reservations_with_identification')}
                    {!editMode && (

                      <BiInfoCircle
                        size={18}
                        color={COLORS.mutedColor}
                        id="reservations_with_identification"
                        style={{
                          cursor: 'pointer',
                          marginLeft: '10px',
                        }}
                      />
                    )}
                  </>
                )}
                onChange={() => handlePlacementIdentifiedShifts(positionsHasId)}
              />

              {!editMode && (
                <UncontrolledPopover
                  flip
                  target="reservations_with_identification"
                  trigger="hover"
                  placement="bottom"
                >
                  <PopoverBody>
                    {t('AddLocation.tabs.AddPosition.checkbox.reservations_with_identification_info')}
                  </PopoverBody>
                </UncontrolledPopover>
              )}

              {positionsHasId && seatsIdentifiersState.length > 0 && limitHeadcount > 0 && (
                <ButtonsSeatTools>
                  {(defaultMapLink.get() || provisoryMapLink.get()) && (
                    <ButtonLoader
                      transparent
                      uppercase={false}
                      label={t('AddLocation.tabs.AddPosition.add_localization')}
                      onClick={() => setOpenMapSelector(true)}
                      icon={Icons.PushPin}
                      withHover={false}
                    />
                  )}
                  <ButtonLoader
                    transparent
                    uppercase={false}
                    label="Gerar nomes"
                    onClick={() => setPrefixModal(true)}
                    icon={Icons.awesomeMagic}
                    withHover={false}
                  />
                </ButtonsSeatTools>
              )}
            </div>
          </Container>
          <div className="row">
            {seatsIdentifiersState.map(
              (fieldState: State<{ id: number; name: string; position: any }>, index: number) => (
                <div className="col-sm-6 col-lg-4 my-2">
                  <div className="card">
                    <div className="card-body d-flex border-bottom-0">
                      <Input
                        label={t('AddLocation.tabs.AddPosition.table_num')}
                        styledLabel={{ display: 'block', marginBottom: '.5rem' }}
                        key={['seat', index].join('_')}
                        onChange={
                          (e) => fieldState.set((prev: any) => ({ ...prev, name: e.target.value }))
                        }
                        value={`${fieldState.get().name}`}
                        style={{
                          width: '75%',
                        }}
                      />
                      <div>
                        <p className="mb-0">{t('AddLocation.tabs.AddPosition.map_localization')}</p>
                        <ButtonLoader
                          className="mt-3"
                          transparent
                          disabled
                          style={{ padding: '0px', textTransform: 'inherit' }}
                          uppercase={false}
                          label={fieldState.get().position?.positionX
                            ? t('AddLocation.tabs.AddPosition.localized') : t('AddLocation.tabs.AddPosition.not_localized')}
                          icon={
                            fieldState.get().position?.positionX ? Icons.PushPin : Icons.FeatherMap
                          }
                          withHover={false}
                        />
                      </div>
                    </div>
                    <div className="card-footer bg-white border-top-0">
                      {editMode && (
                        <DeleteButton
                          type="button"
                          className="button-trash"
                          disabled={!canShowOptionToDeleteSeat(fieldState.get().id)}
                          style={!canShowOptionToDeleteSeat(fieldState.get().id) ? {
                            opacity: '0.5',
                            cursor: 'not-allowed',
                          } : {}}
                          onClick={() => deleteSeat(fieldState.get().id)}
                        >
                          <img src={Icons.TrashRedLine} alt="" />
                          <span>Excluir</span>
                        </DeleteButton>
                      )}
                    </div>
                  </div>
                </div>
              ),
            )}
          </div>
          <div>
            <Buttons>
              <ButtonLoader
                onClick={() => HandleSubmit()}
                isLoading={isLoadingSubmit}
                label={t('AddLocation.tabs.AddPosition.save_position')}
              />
            </Buttons>
          </div>
        </>
      ) : (
        <div style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        >
          <Loader />
        </div>
      )}
      <ModalMapSelect
        isOpen={openMapSelector}
        seats={seatsIdentifiersState}
        title={`${t('AddLocation.tabs.AddPosition.location_map')}: ${locationName} - ${positionName}`}
        link={editMode ? defaultMapLink.get() : provisoryMapLink.get()}
        handleCloseButton={() => {
          setOpenMapSelector(false);
        }}
      />
      <ModalPrefixGenerator
        isOpen={prefixModal}
        handleCloseButton={() => setPrefixModal(false)}
        handleCreateSuccess={(prefix: string, size: number) => {
          setPrefixModal(false);
          handlePrefixSeats(prefix, size);
        }}
      />
    </div>
  );
};

export default AddPositionTab;
