/* eslint-disable camelcase */
import React, {
  FormEvent, useCallback, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import LogoSvg from 'src/assets/imgs/Logo_Espaços_Login.svg';
import Input from 'src/components/InputWithRef';
import ButtonLoader from 'src/components/ButtonLoader';
import ShowPassword from 'src/components/ShowPassword';
import AuthService from 'src/services/AuthService';
import { useToast } from 'src/hooks/toast';
import { useTranslation } from 'react-i18next';
import {
  Link, useHistory, useLocation,
} from 'react-router-dom';
import { addData } from 'src/services/LocalStorageService';
import {
  startMixpanel,
  mixpanelPeopleSet,
  mixpanelPeopleSetOnce,
  IPeople, mixpanelTrack,
} from 'src/services/MixPanelService';
import {
  Form, FormText,
} from 'reactstrap';
import { ErrorText } from 'src/components/InputWithRef/style';
import Loader from '../../components/Loader';
import {
  Container,
  RowContainer,
  BackgroundContainer,
  LoginContainer,
  LoginContent,
  LogoContainer,
  Logo,
  WelcomeTitle,
  ButtonContainer,
  ForgotPasswordContainer,
  ForgotPassword,
  RegisterContainer,
  RegisterText,
  ItsNotYou,
  ItsNotYouContainer,
  InformationCheckEmail,
  InformationCheckCompany,
  FirstLoginText,
  FirstLoginContainer,
  EnterText,
} from './style';
import { useGlobal } from '../../hooks/useGlobal';
import icons from '../../assets/icons';
import colors from '../../colors';
import { emailOrCode, verifyEmail } from '../../utils/validate';

interface ILoginForm {
  password: string;
}

interface ICheckEmailForm {
  email: string;
}

interface ITempCred extends ILoginForm {
  [x: string]: string;
  temp_access_token: string;
}

interface CheckEmailInformation {
  name: string;
  company: string;
  [x: string]: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const APP_LINKING = process.env.REACT_APP_DEEP_LINKING;

const TwoFactorCodeForm = (
  handleTwoFactorAuthentication?: any,
  handleChangeCodeToken?: any,
  codeToken?: any,
  errorToken?: any,
  isLoading?: any,
  t?: any,
) => (
  <Form onSubmit={handleTwoFactorAuthentication} method="POST" style={{ marginTop: '20px' }}>
    <Input
      type="text"
      label={t('Login.placeholder_input_verify')}
      name="code_of_token"
      onChange={(e) => handleChangeCodeToken(e.target.value)}
      value={codeToken}
      maxLength={6}
      required
    />
    {errorToken && (
      <FormText>
        <ErrorText>
          {errorToken}
        </ErrorText>
      </FormText>
    )}
    <ButtonContainer>
      <ButtonLoader
        type="submit"
        label={t('Login.two_factor_send')}
        isLoading={isLoading}
        width="100%"
      />
    </ButtonContainer>
  </Form>
);

const PasswordFormLogin = (
  twoFactorEnabledProps?: any,
  handleTwoFactorAuthentication?: any,
  handleChangeCodeToken?: any,
  codeToken?: any,
  errorToken?: any,
  isLoading?: any,
  onSubmit?: any,
  checkEmailInformation?: any,
  handleSubmit?: any,
  backToLogin?: any,
  errors?: any,
  register?: any,
  t?: any,
  showPasswordIcon?: any,
  showPassword?: any,
  setShowPassword?: any,
  passwordType?: any,
) => {
  if (!twoFactorEnabledProps) {
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        {checkEmailInformation && (
        <>
          <InformationCheckEmail>
            {t('Login.hello_user', { name: `${checkEmailInformation.name}` })}
          </InformationCheckEmail>
          <InformationCheckCompany style={{ marginTop: 0 }}>
            (
            {checkEmailInformation.company}
            )
          </InformationCheckCompany>
          <ItsNotYouContainer style={{ marginBottom: 0 }}>
            <ItsNotYou onClick={backToLogin}>
              {t('Login.its_not_you')}
            </ItsNotYou>
          </ItsNotYouContainer>
        </>
        )}
        <Input
          key="password"
          error={errors}
          type={passwordType}
          errorMsg={t('Login.password_error')}
          name="password"
          register={register({ required: true })}
          label={t('Login.password_label')}
        />
        <ShowPassword
          src={showPasswordIcon}
          onClick={() => {
            setShowPassword(!showPassword);
            mixpanelTrack('login@see-password', null);
          }}
          error={errors}
        />
        <ForgotPasswordContainer>
          <Link to="/emailPasswordReset">
            <ForgotPassword>
              {t('Login.forgot_password')}
            </ForgotPassword>
          </Link>
        </ForgotPasswordContainer>
        <ButtonContainer>
          <ButtonLoader
            label={t('Login.login')}
            isLoading={isLoading}
            width="100%"
          />
        </ButtonContainer>
      </Form>
    );
  }

  return TwoFactorCodeForm(
    handleTwoFactorAuthentication,
    handleChangeCodeToken,
    codeToken,
    errorToken,
    isLoading,
    t,
  );
};

const FirstLogin = (
  isFirstLogin: boolean,
  checkEmailInformation: any,
  t: any,
  backToLogin: any,
  isLoading: boolean,
  sendActivationEmail: any,
) => (
  <FirstLoginContainer>
    <FirstLoginText>
      {t('FirstLogin.send_email', {
        email: checkEmailInformation.censoredEmail,
      })}
    </FirstLoginText>
    <EnterText onClick={backToLogin}>
      {t('FirstLogin.login')}
    </EnterText>
    <ButtonContainer>
      <ButtonLoader
        label={t('FirstLogin.button')}
        isLoading={isLoading}
        width="100%"
        type="button"
        onClick={() => sendActivationEmail(checkEmailInformation.email)}
      />
    </ButtonContainer>
  </FirstLoginContainer>
);

const EmailFormLogin = (
  handleSubmit?: any,
  onCheckEmailLoginSubmit?: any,
  errors?: any,
  register?: any,
  isLoading?: any,
  t?: any,
) => (
  <Form onSubmit={handleSubmit(onCheckEmailLoginSubmit)}>
    <WelcomeTitle>{t('Login.welcome')}</WelcomeTitle>
    <Input
      id="email-login-form"
      error={errors}
      errorMsg={t('Login.email_error')}
      name="email"
      register={register({ required: true })}
      label={t('Login.login_label')}
    />
    <ForgotPasswordContainer>
      <Link to="/emailPasswordReset">
        <ForgotPassword>
          {t('Login.forgot_password')}
        </ForgotPassword>
      </Link>
    </ForgotPasswordContainer>
    <ButtonContainer>
      <ButtonLoader
        label={t('Login.login')}
        isLoading={isLoading}
        width="100%"
      />
    </ButtonContainer>
    <RegisterContainer>
      <RegisterText>
        {t('Login.not_have_account')}
        <Link style={{ marginLeft: '5px', color: `${colors.blue}` }} to="/register">
          {t('Login.register')}
        </Link>
      </RegisterText>
    </RegisterContainer>
  </Form>
);

const LoginForm = (
  checkEmailInformation?: any,
  isLoadingSSOLogin?: any,
  twoFactorEnabledProps?: any,
  handleTwoFactorAuthentication?: any,
  handleChangeCodeToken?: any,
  codeToken?: any,
  errorToken?: any,
  isLoading?: any,
  onSubmit?: any,
  handleSubmit?: any,
  backToLogin?: any,
  errors?: any,
  register?: any,
  onCheckEmailLoginSubmit?: any,
  t?: any,
  showPasswordIcon?: any,
  showPassword?: any,
  setShowPassword?: any,
  passwordType?: any,
  isFirstLogin?: boolean,
  sendActivationEmail?: any,
) => {
  if (isFirstLogin && checkEmailInformation?.email !== undefined) {
    return FirstLogin(
      isFirstLogin,
      checkEmailInformation,
      t,
      backToLogin,
      isLoading,
      sendActivationEmail,
    );
  }

  if (checkEmailInformation?.email !== undefined
    || checkEmailInformation?.code !== undefined
    || twoFactorEnabledProps
  ) {
    return PasswordFormLogin(
      twoFactorEnabledProps,
      handleTwoFactorAuthentication,
      handleChangeCodeToken,
      codeToken,
      errorToken,
      isLoading,
      onSubmit,
      checkEmailInformation,
      handleSubmit,
      backToLogin,
      errors,
      register,
      t,
      showPasswordIcon,
      showPassword,
      setShowPassword,
      passwordType,
    );
  }

  if (isLoadingSSOLogin) {
    return <Loader />;
  }

  return EmailFormLogin(
    handleSubmit,
    onCheckEmailLoginSubmit,
    errors,
    register,
    isLoading,
    t,
  );
};

const Login: React.FC = () => {
  const { t } = useTranslation('pages');
  const query = useQuery();
  const history = useHistory();
  const { register, errors, handleSubmit } = useForm<FormData>();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSSOLogin, setIsLoadingSSOLogin] = useState(false);
  const { setUser } = useGlobal();
  const [twoFactorEnabled, setTwoFactorEnabled] = useState<boolean>(false);
  const [tmpCredentials, setTempCredentials] = useState<ITempCred>(
    {} as ITempCred,
  );
  const [codeToken, setCodeToken] = useState<string>('');
  const [errorToken, setErrorToken] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [
    checkEmailInformation,
    setCheckEmailInformation,
  ] = useState<CheckEmailInformation | null>(null);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const code = urlSearchParams.get('code');
  const companyId = urlSearchParams.get('company_id');
  const client = urlSearchParams.get('client');
  const redirectURL = urlSearchParams.get('redirect_url');
  const emailParams = verifyEmail(query);
  const [userInfoTwoFactorKeyFromSSO, setUserInfoTwoFactorKeyFromSSO] = useState<any | undefined>();
  const [isFirstLogin, setIsFirstLogin] = useState(false);

  const initMixpanel = (user: any) => {
    const mixPanelPeople: IPeople = {
      id: user.id,
      $created: user.created_at,
      $email: user.email,
      phone: user.tel,
      $avatar: user.photo,
      name: user.name,
      $name: user.name,
      last_login: new Date().toISOString(),
      company: user.company.name,
      company_name: user.company.name,
      company_id: user.company_id,
      is_paying: user.company.payment_type === null,
      email: user.email,
      cpf: user.cpf,
      code: user.code,
      role: user.roles?.some((role: any) => role.code === 'ADMIN')
        ? 'ADMIN'
        : 'COMMOM',
    };
    startMixpanel();
    mixpanelPeopleSet(mixPanelPeople);
    mixpanelPeopleSetOnce({
      first_access: new Date().toISOString(),
    });
  };

  const setUserToStorage = (data: any) => {
    addData('espacos@user', data);
    addData('espacos@guide', data.guide_for_employees);
    addData('espacos@acessToken', data.access_token);
  };

  const setUserInformation = (data: any) => {
    setUserToStorage(data);
    setUser?.(data.user);
    setTempCredentials({} as ITempCred);
  };

  const doLogin = async (param: string, password: string) => {
    const keyName = emailOrCode(param);
    AuthService.login(keyName, param, password)
      .then((response) => {
        setIsLoading(false);
        const { user } = response.data.data;
        setUserInformation(response.data.data);
        initMixpanel(user);
        if (keyName === 'code') {
          mixpanelTrack('user@codeLogin', null);
        }

        if (response.data.data.only_collaborator) {
          mixpanelTrack('login@collaborator', null);
        }
        window.location.href = '/';
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response.data.errorCode === 'Unauthorized') {
          return addToast({
            description: error.response.data.message,
            type: 'error',
          });
        }
        if (error.response.status === 429) {
          return addToast({
            description: t('Login.too_many_error'),
            type: 'error',
          });
        }
        if (error.response.data.errorCode === 'LOGIN_08') {
          setTwoFactorEnabled(true);
          if (checkEmailInformation?.email !== undefined
            || checkEmailInformation?.code !== undefined) {
            const isEmail = checkEmailInformation?.email !== undefined;
            setTempCredentials({
              [isEmail ? 'email' : 'code']: isEmail ? checkEmailInformation?.email : checkEmailInformation?.code,
              password,
              temp_access_token: error.response.data.temp_access_token,
            });
          }
          return addToast({
            description: t('Login.two_factor_required'),
            type: 'warning',
          });
        }
        return addToast({
          description: t('Login.login_error'),
          type: 'error',
        });
      });
  };

  const doCheckLogin = async (param: string) => {
    const keyName = emailOrCode(param);

    if (keyName === 'error') {
      addToast({
        description: t('Login.user_login_error'),
        type: 'error',
      });
    }

    AuthService.checkLogin(param, keyName)
      .then((response) => {
        if (response.data.needs_sso) {
          window.location.href = response.data.redirect_uri;
          return;
        }

        setIsFirstLogin(response.data.isFirstLogin);
        setIsLoading(false);
        setCheckEmailInformation({
          name: response.data.name,
          company: response.data.company,
          censoredEmail: response.data.email,
          [keyName]: param,
        });
      })
      .catch((error) => {
        setIsLoading(false);

        if (error.response.status !== 200) {
          return addToast({
            description: error.response.data.message,
            type: 'error',
          });
        }

        return addToast({
          description: t('Login.login_error'),
          type: 'error',
        });
      }).finally(() => {
        setIsLoading(false);
        setIsLoadingSSOLogin(false);
      });
  };

  const onSubmit = async (credentials: any) => {
    setIsLoading(true);

    if (checkEmailInformation?.email !== undefined || checkEmailInformation?.code !== undefined) {
      const isEmail = checkEmailInformation?.email !== undefined;
      const value = isEmail ? checkEmailInformation?.email : checkEmailInformation?.code;
      await doLogin(value, credentials.password);
    }
  };

  async function onCheckEmailLoginSubmit(credentials: ICheckEmailForm) {
    setIsLoading(true);
    await doCheckLogin(credentials.email);
  };

  const verifyIfLogoutUnauthentcated = () => {
    if (Number(query.get('unauthentcated')) === 1) {
      addToast({
        description: t('Login.unauthentcated_user'),
        type: 'error',
      });
    }
  };

  const tempAccessToken = () => {
    if (userInfoTwoFactorKeyFromSSO !== undefined) {
      return userInfoTwoFactorKeyFromSSO.two_factor_token;
    }

    return tmpCredentials.temp_access_token;
  };

  const validateTokenAndLoginTwoFactor = async () => {
    const tmpAccessToken = tempAccessToken();

    await AuthService.twoFactor(tmpAccessToken, codeToken);

    const { data } = await AuthService.loginWithTwoFactor(
      tmpAccessToken,
      codeToken,
    );

    return data.data;
  };

  useEffect(() => {
    verifyIfLogoutUnauthentcated();

    if (redirectURL !== null) {
      window.location.replace(redirectURL);
      return;
    }

    if (emailParams && emailParams !== null && emailParams !== '') {
      setIsLoadingSSOLogin(true);
      doCheckLogin(emailParams);
      return;
    }

    if (code !== null && companyId !== null) {
      setIsLoadingSSOLogin(true);

      if (client === 'MOBILE') {
        window.location.href = `${APP_LINKING}://email?code=${code}&company_id=${companyId}`;
        return;
      }

      AuthService.validateCodeSSOLogin(code, companyId)
        .then((response) => {
          const { user } = response.data.data;

          if (user.two_factor_key) {
            setIsLoadingSSOLogin(false);
            setTwoFactorEnabled(true);
            setUserInfoTwoFactorKeyFromSSO(response.data);
            return;
          }

          setUserInformation(response.data.data);
          initMixpanel(user);
          setIsLoadingSSOLogin(false);
          window.location.href = '/panel';
        })
        .catch(() => {
          setIsLoadingSSOLogin(false);
          history.push('/login');
        });
    }
  }, []);

  const handleChangeCodeToken = useCallback((value) => {
    setCodeToken(value.replace(/[^\d]+/g, ''));
    setErrorToken(null);
  }, []);

  const handleTwoFactorAuthentication = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const loginData = await validateTokenAndLoginTwoFactor();
      const { user } = loginData;
      setUserInformation(loginData);
      setIsLoading(false);
      initMixpanel(user);
      window.location.href = '/panel';
      return;
    } catch (error) {
      setIsLoading(false);
      setErrorToken(t('Login.token_unvalid'));
    }
  }, [codeToken, tmpCredentials, history]);

  const showPasswordIcon = showPassword ? icons.eyeClosed : icons.eyeOpen;
  const passwordType = showPassword ? 'text' : 'password';

  function backToLogin() {
    setCheckEmailInformation(null);
    setTwoFactorEnabled(false);
    setIsLoadingSSOLogin(false);
    history.push('/login');
  }

  const sendActivationEmail = (email: string) => {
    setIsLoading(true);
    AuthService.sendFirstLoginEmail(email)
      .then(() => {
        setIsLoading(false);

        addToast({
          description: t('FirstLogin.email_send_success'),
          type: 'success',
        });
      })
      .catch(() => {
        setIsLoading(false);
        addToast({
          description: t('FirstLogin.email_send_error'),
          type: 'error',
        });
      });
  };

  return (
    <Container>
      <RowContainer>
        <BackgroundContainer />
        <LoginContainer>
          <LoginContent>
            <LogoContainer>
              <Logo src={LogoSvg} alt="logo" />
            </LogoContainer>
            { LoginForm(
              checkEmailInformation,
              isLoadingSSOLogin,
              twoFactorEnabled,
              handleTwoFactorAuthentication,
              handleChangeCodeToken,
              codeToken,
              errorToken,
              isLoading,
              onSubmit,
              handleSubmit,
              backToLogin,
              errors,
              register,
              onCheckEmailLoginSubmit,
              t,
              showPasswordIcon,
              showPassword,
              setShowPassword,
              passwordType,
              isFirstLogin,
              sendActivationEmail,
            )}
          </LoginContent>
        </LoginContainer>
      </RowContainer>
    </Container>
  );
};

export default Login;
