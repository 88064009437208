import styled from 'styled-components';
import colors from '../../colors';

export const StyledInput = styled.input`
  height: 40px;
  padding: 0 17px;
  background: ${colors.white5} 0% 0% no-repeat padding-box;
  border: 1px solid ${colors.dark90};
  border-radius: 4px;
  opacity: 1;
  color: ${colors.gray};
  font-size: rem;
  vertical-align: 0;
  width: 100%;

  ::placeholder {
    color: #939799;
  }

  :disabled {
    background: ${colors.white10} 0% 0% no-repeat padding-box!important;
    border: 1px solid ${colors.white15};
  }
`;

export const Container = styled.div`
  width: 100%;
  > label {
    color: var(--secondary-grey);
    font-size: 16px
  }
`;

export const SubLabel = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #939799;
`;

export const ErrorText = styled.p`
 color: ${colors.red};
 font-size: 0.775rem;
 margin-top: 3px;
 margin-bottom: 0px;
`;

export const PasswordWeak = styled.p`
 color: #565859;
 font-size: 10px;
 margin-top: 5px;
 margin-bottom: 0px;
`;
