/* eslint-disable import/no-duplicates */
import React, {
  useContext, useEffect, useState, useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import TeamService from 'src/services/TeamService';
import Team from 'src/contracts/models/Team';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { mixpanelTrack } from 'src/services/MixPanelService';
import icons from '../../assets/icons';
import {
  Header,
  Body,
  Resume,
} from './style';
import TeamSelector from '../TeamSelector';
import Option from '../../contracts/models/Option';
import AnnounceService from '../../services/AnnounceService';
import { useToast } from '../../hooks/toast';
import Shift from '../../contracts/models/Shift';
import ButtonLoader from '../ButtonLoader';
import { homeContext } from '../../pages/Home/Context';
import Select from '../Select';

interface AnnounceVacancyProps {
  modalData: {
    shiftData?: Shift | null;
  };
  period: {
    from: Date;
    to: Date;
  };
  onClickBackButton: Function;
  selectedLocationsDefault: Option[];
  handleCloseButton: () => void;
}

const AnnounceVacancy: React.FC<AnnounceVacancyProps> = ({
  onClickBackButton,
  period,
  handleCloseButton,
  modalData,
}) => {
  const { t } = useTranslation('components');
  const notifyTypesOptions: Option[] = [
    {
      label: t('ModalShiftEdit.announcent.all_in_team'),
      value: 'ALL_TEAM',
    },
    {
      label: t('ModalShiftEdit.announcent.all_allocated'),
      value: 'ALL_ALLOCATED',
    },
    {
      label: t('ModalShiftEdit.announcent.nobody'),
      value: 'NONE',
    },
  ];

  const { addToast } = useToast();
  const {
    placement, loadCalendar,
  } = useContext(homeContext);
  const [selectTypesOptions, setSelectTypesOptions] = useState<Option>(notifyTypesOptions[0]);
  const [isLoading, setIsLoading] = useState(false);
  const [availableTeams, setAvailableTeams] = useState<Team[]>([]);
  const [sendToAllTeams, setSendToAllTeams] = useState(true);

  useEffect(() => {
    if (placement?.id) {
      TeamService.getTeamsFromPlacement(placement.id)
        .then((r) => setAvailableTeams?.(r.data))
        .catch(() => setAvailableTeams?.([]));
    }
  }, []);

  const teamOptions = useMemo(
    () => Object.values(availableTeams!).map(
      (team) => ({ value: String(team.id), label: team.name }),
    ),
    [availableTeams],
  );

  const [selectedTeams, setSelectedTeams] = useState(teamOptions);

  useEffect(() => {
    setSelectedTeams(teamOptions);
  }, [availableTeams]);

  const allocatedAtDate = new Date(`${modalData?.shiftData?.allocatedAt}T00:00:00` as string);
  const options = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
  };

  const sendAnnounceVocancy = () => {
    setIsLoading(true);
    const teams = !sendToAllTeams ? selectedTeams.map((team) => team.value).join(',') : undefined;

    const payload = {
      teams_id: teams,
      start: format(period.from, 'yyyy-MM-dd HH:mm:ss'),
      end: format(period.to, 'yyyy-MM-dd HH:mm:ss'),
      placement_id: placement?.id!,
      notification_type: selectTypesOptions.value,
      seat_id: Number(modalData.shiftData?.seatId),
      date: format(allocatedAtDate, 'yyyy-MM-dd HH:mm:ss'),
    };
    AnnounceService.postAnnounceVocancy(payload).then(() => {
      setIsLoading(false);
      mixpanelTrack('announcement@unique-send', null);
      addToast({
        description: t('ModalShiftEdit.announcent.success'),
        type: 'success',
      });
      handleCloseButton();
      loadCalendar?.();
    }).catch(() => {
      setIsLoading(false);
      addToast({
        description: t('ModalShiftEdit.announcent.error'),
        type: 'error',
      });
    });
  };

  return (
    <div>
      <Header className="header-announce mb-4 mt-2 d-flex justify-content-between">
        <button type="button" className="text-primary" onClick={() => onClickBackButton()}>
          <img className="me-3" src={icons.ArrowRoundBack} alt="ArrowRoundBack" />
          {t('ModalShiftEdit.announce_vacancy')}
        </button>
        <button
          className=""
          type="button"
          onClick={handleCloseButton}
        >
          <img src={icons.close} className="logo" alt="" title={t('ModalShiftEdit.announcent.close')} />
        </button>
      </Header>
      <Body className="body-announce p-0">
        <Resume>
          {/* <h5>{selectedLocations[0].label}</h5> */}
          <small className="text-uppercase">{allocatedAtDate.toLocaleDateString('pt-BR', options)}</small>
        </Resume>
        <div>
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                checked={sendToAllTeams}
                onChange={() => setSendToAllTeams((previousState) => !previousState)}
                name="Enviar"
              />
                )}
            label={t('ModalAnnoucement.send_to_all_teams')}
          />
          {
            !sendToAllTeams && (
            <TeamSelector
              allOption={{
                label: t('ModalShiftEdit.announcent.for'),
                value: teamOptions,
              }}
              options={teamOptions}
              defaultValue={selectedTeams}
              onChange={
                (changedSelectedTeams: Option[]) => setSelectedTeams?.(changedSelectedTeams)
              }
            />
            )
          }
        </div>
        <div className="mt-3">
          <Select
            label={t('ModalAnnoucement.notify')}
            options={notifyTypesOptions}
            value={selectTypesOptions}
            onChange={setSelectTypesOptions as any}
          />
        </div>
      </Body>
      <div className="w-100 d-flex justify-content-end mt-4">
        <ButtonLoader
          label={t('ModalShiftEdit.announcent.publish')}
          color="primary"
          className="text-uppercase"
          onClick={sendAnnounceVocancy}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default AnnounceVacancy;
