import React, { FC } from 'react';
import {
  Description, Steps, Title,
} from './style';

interface props {
  translate: Function
  currentStep?: number,
  finishStep?: number,
}
export const GuideLocationSelector:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('Guidance.Teams.location-selector.title')}</Title>
    <Description>
      {translate('Guidance.Teams.location-selector.description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const GuideTeamInfoAccordion:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('Guidance.Teams.team-accordion.title')}</Title>
    <Description>
      {translate('Guidance.Teams.team-accordion.description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const GuideCreateTeamButtom:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('Guidance.Teams.create-buttom.title')}</Title>
    <Description>
      {translate('Guidance.Teams.create-buttom.description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const GuideAddEmployeeTeam:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('Guidance.Teams.add_employee.title')}</Title>
    <Description>
      {translate('Guidance.Teams.add_employee.description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);
