/* eslint-disable react/require-default-props */

import { addMinutes, format } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import Icons from '../../assets/icons';
import convertTimeToSeconds from '../../utils/convertTimeToSeconds';
import {
  Container,
  Row,
  Body,
  Label,
  Input,
  ButtonsContainer,
  Button,
  SelectContainer,
  SelectButton,
} from './style';

interface HoursSelectorProps {
  onChange?: (value: string) => void;
  leftLabel?: string;
  rightLabel?: string;
  hasError?: string;
  defaultValue?: string;
  max?: string;
  min?: string;
  interval?: number;
  enableSelectBox?: boolean;
  disabled?: boolean;
}
function HourSelector({
  onChange,
  leftLabel,
  rightLabel,
  hasError,
  defaultValue,
  max,
  min,
  interval,
  enableSelectBox,
  disabled,
}: HoursSelectorProps) {
  const [hour, setHour] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const reg = new RegExp('^[0-9]+$');
  const handleChangeTime = (value: string, type: string) => {
    let newValue = '';
    if (value && value.length === 3 && value[0] === '0') newValue = `${value[1]}${value[2]}`;

    if ((reg.test(newValue) && newValue.length <= 2) || !newValue) {
      if (type === 'hour' && Number(newValue) <= 23) {
        if (newValue) {
          setHour(Number(newValue));
        } else {
          setHour(0);
        }
      } else if (type === 'minute' && Number(newValue) <= 59) {
        if (newValue) {
          setMinutes(Number(newValue));
        } else {
          setMinutes(0);
        }
      }
    }
  };

  const handleChangeMinutes = (value: number, direction: string) => {
    let newMinutes;
    if (direction === 'up') {
      newMinutes = minutes + value;
    } else {
      newMinutes = minutes - value;
    }

    switch (direction) {
      case 'up':
        if (newMinutes >= 60) {
          const diference = newMinutes - 60;
          setMinutes(diference);
          if (hour < 23) {
            setHour(hour + 1);
          } else {
            setHour(0);
          }
        } else {
          setMinutes(newMinutes);
        }
        break;
      case 'down':
        if (newMinutes < 0) {
          const diference = 60 + newMinutes;
          setMinutes(diference);
          if (hour !== 0) {
            setHour(hour - 1);
          } else {
            setHour(23);
          }
        } else {
          setMinutes(newMinutes);
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (defaultValue) {
      const values = defaultValue.split(':');
      setHour(Number(values[0]));
      setMinutes(Number(values[1]));
    }
  }, []);

  useEffect(() => {
    const formated = `${Number(hour) < 10 ? `0${hour}` : hour}:${
      Number(minutes) < 10 ? `0${minutes}` : minutes
    }:00`;
    onChange?.(formated);
  }, [hour, minutes]);

  const times = useMemo(() => {
    const t : string[] = [];
    if (enableSelectBox) {
      let iterationTime = min || '00:00:00';
      while (
        (convertTimeToSeconds(iterationTime)
          <= convertTimeToSeconds(max || '23:30:00') && !t.includes(iterationTime))
              || (!t.includes(max || '23:30:00'))
      ) {
        t.push(iterationTime);
        iterationTime = format(
          addMinutes(new Date(`0000-01-01T${iterationTime}`), interval || 30),
          'HH:mm:ss',
        );
      }
    }
    return t;
  }, []);

  const onSelectButtonClick = (time: string) => {
    const splittedTime = time.split(':');
    setHour(+splittedTime[0]);
    setMinutes(+splittedTime[1]);
  };
  return (
    <Container aria-disabled={disabled}>
      <Row>
        {leftLabel && <Label left>{leftLabel}</Label>}
        <Body hasError={hasError} disabled={disabled}>

          <Input
            disabled={disabled}
            value={Number(hour) < 10 ? `0${hour}` : hour}
            align="right"
            onChange={(e) => handleChangeTime(e.target.value, 'hour')}
          />
          :
          <Input
            disabled={disabled}
            value={Number(minutes) < 10 ? `0${minutes}` : minutes}
            align="left"
            onChange={(e) => handleChangeTime(e.target.value, 'minute')}
          />

        </Body>
        <ButtonsContainer disabled={disabled}>
          {!disabled && (
          <Button
            aria-disabled={disabled}
            src={Icons.CollapseArrow}
            top
            onClick={!disabled ? () => handleChangeMinutes(30, 'up') : undefined}
          />
          )}
          {!disabled && (
          <Button
            aria-disabled={disabled}
            src={Icons.CollapseArrow}
            bottom
            onClick={!disabled ? () => handleChangeMinutes(30, 'down') : undefined}
          />
          )}
        </ButtonsContainer>
        {rightLabel && <Label right>{rightLabel}</Label>}
      </Row>

      {enableSelectBox && (
        <SelectContainer className="select">
          {times?.map((time) => (
            <SelectButton onClick={() => onSelectButtonClick(time)}>
              {time.slice(0, 5)}
            </SelectButton>
          ))}
        </SelectContainer>
      )}
    </Container>
  );
}

export default HourSelector;
