export const calcRadius = (radius: number, scale: number) => {
  const actualRadius = (radius * scale) + 6;

  if (scale < 1) {
    return (actualRadius) * (2 - scale) * 1.2;
  }
  if (scale >= 1.2) {
    return ((actualRadius) * 1.2) - scale;
  }

  return (actualRadius) * (scale + 1);
};

export const calcFontSize = (fontSize: number, currentScale: number) => {
  if (currentScale < 1) {
    return fontSize * (2 - currentScale + 3);
  }
  if (currentScale >= 1.2) {
    return fontSize * 1.2;
  }
  return fontSize * (currentScale + 1);
};
