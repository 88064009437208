/* eslint-disable camelcase */
/* eslint-disable max-len */
import Placement from '../contracts/models/Placement';
import keysToCamel from '../utils/keysToCamelCase';
import keysToSnake from '../utils/keysToSnakeCase';
import Api from './api';

type PaginatedParams = {
  page: number;
  perPage: number;
  search?: string;
  location_id?: number | string;
};
export default class PlacementService {
  static DEFAULT_URL = 'placements';

  static async getPlacementsFromTeam(query: string) {
    const response = await Api.get(`placements/from/teams?teams_id=${query}`);
    return response;
  }

  static async getPlacementsFromId(query: string) {
    const response = await Api.get(`placements?placement_id=${query}`);
    return response;
  }

  static async createConfig(payload: any) {
    const response = Api.post('placements/configurations', {
      configurations: payload,
    });
    return response;
  }

  static async getConfig(placementId: number) {
    const response = Api.get(
      `placements/configurations?placement_id=${placementId}`,
    );
    return response;
  }

  static async updateConfig(payload: any) {
    const response = Api.put('placements/configurations', {
      configurations: payload,
    });
    return response;
  }

  static async deletePlacement(placement: string, location: string) {
    const response = Api.delete(
      `placements?placement_id=${placement}&location_id=${location}`,
    );
    return response;
  }

  static async editRelayConfiguration(data: any) {
    const response = await Api.post('placements/configurations/relay', data);
    return response;
  }

  static async getPlacementsFromLocation(id: number | string) {
    const response = Api.get(`placements/from/location?location_id=${id}`);
    return response;
  }

  static async createEditMap(formData: FormData) {
    const response = Api.post('placements/map', formData);
    return response;
  }

  static async deleteMap(placementId: number) {
    const response = Api.delete('placements/map', {
      data: {
        placement_id: placementId,
      },
    });
    return response;
  }

  static async getCalendarInformationFromPlacement(data: {
    placementId: string;
    locationId: string;
    weekStart: string;
    weekEnd: string;
    teamsId: string;
  }) {
    return Promise.resolve(
      Api.get('placements/for/calendar', {
        params: {
          placement_id: data.placementId,
          location_id: data.locationId,
          week_start: data.weekStart,
          week_end: data.weekEnd,
          teams_id: data.teamsId,
        },
      })
        .then((response) => {
          const body: Placement = {
            teams: Object.values(response.data.teams).map((item: any) => ({
              id: item.id,
              name: item.name,
              maxVacancy: item.max_vacancy,
              maxReservationPerEmployee: item.team_max_vacancy,
              maxTeamVacancy: item.team_max_reservation_per_employee,
              employeesCount: item.count_employees,
              isRelay: item.team_is_relay as boolean,
            })),
            id: response.data.placement.id,
            start: response.data.placement.start,
            end: response.data.placement.end,
            map: response.data.placement.map,
            name: response.data.placement.name,
            maxVacancy: response.data.placement.max_vacancy,
            color: response.data.placement.color,
            openDays: response.data.placement.open_days,
            allowAbandonmentAt: response.data.placement.allow_abandonment_at,
            allowAbandonmentBefore:
              response.data.placement.allow_abandonment_before,
            allowShiftAt: response.data.placement.allow_shift_at,
            allowShiftBeforeThe: response.data.placement.allow_shift_before_the,
            enableCheckinAfterTime:
              response.data.placement.enable_checkin_after_time,
            enableCheckinBeforeTime:
              response.data.placement.enable_checkin_before_time,
            enableCheckinCheckoutFrom:
              response.data.placement.enable_checkin_checkout_from,
            enableCheckoutAfterTime:
              response.data.placement.enable_checkout_after_time,
            enableCheckoutBeforeTime:
              response.data.placement.enable_checkout_before_time,
            isActivateGeolocation:
              response.data.placement.is_activate_geolocation,
            isAllowAbandonmentShift:
              response.data.placement.is_allow_abandonment_shift,
            isAllowCheckoutWithoutCheckin:
              response.data.placement.is_allow_checkout_without_checkin,
            isAllowFractionalShift:
              response.data.placement.is_allow_fractional_shift,
            isAutomaticallyAnnounceAfterAbandonment:
              response.data.placement
                .is_automatically_announce_after_abandonment,
            isAutomaticPublications:
              response.data.placement.is_automatic_publications,
            isRegisterCheckinCheckout:
              response.data.placement.is_register_checkin_checkout,
            isAutomaticAnnouncements:
              response.data.placement.is_automatic_announcements,
            localIdentifier: response.data.placement.local_identifier,
            shifts: response.data.shifts,
            homeOffice: keysToCamel(response.data.home_office),
            seatsSize: response.data.placement.seats_size,
            seatsName:
              response.data.placement.seats_name[''] !== null
                ? Object.keys(response.data.placement.seats_name).map(
                  (item, index) => ({
                    id: item,
                    name: Object.values(response.data.placement.seats_name)[
                      index
                    ] as string,
                  }),
                )
                : undefined,
            minimumDurationShift:
              response.data.placement.minimum_duration_shift,
          };
          return { failed: false, data: body };
        })
        .catch(() => ({ failed: true, data: [] })),
    );
  }

  static async getRelaysForCalendar({
    placementId,
    weekStart,
    weekEnd,
    teamsId,
  }: {
    placementId: string,
    weekStart: string,
    weekEnd: string,
    teamsId: string,
  }) {
    const response = Api.get('/placements/relays/for/calendar', {
      params: {
        placement_id: placementId,
        date_start: weekStart,
        date_end: weekEnd,
        teams_id: teamsId,
        page: 1,
        per_page: 10,
      },
    });

    return response;
  }

  static async getNotAllocatedEmployeesFromTeam(payload: {
    placementId: number;
    teamsId: string;
    from?: string;
    to?: string;
  }) {
    const response = await Api.request({
      method: 'GET',
      url: `${this.DEFAULT_URL}/not/allocated/employees`,
      params: keysToSnake(payload),
    });

    response.data = keysToCamel(response.data);
    return response;
  }

  static async getMapViewInfo(payload: { placementId: number; date?: string }) {
    const response = await Api.request({
      method: 'GET',
      url: `${this.DEFAULT_URL}/map`,
      params: keysToSnake(payload),
    });

    response.data = keysToCamel(response.data);
    return response;
  }

  static async getPlacementsFromEmployee(
    perPage = 10,
    page = 1,
    search = '',
    locationId: string | number,
  ) {
    const params: PaginatedParams = {
      perPage,
      page,
      search,
    };

    if (locationId !== undefined) {
      params.location_id = locationId;
    }

    const response = Api.get('placements/from/employee', { params });

    return response;
  }
}
