import {
  SingleSelectInput,
} from 'escala-components';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Location from 'src/contracts/models/Location';
import Team from 'src/contracts/models/Team';
import Option from 'src/contracts/models/Option';
import TeamService from 'src/services/TeamService';
import TeamSelector from 'src/components/TeamSelector';
import ButtonLoader from 'src/components/ButtonLoader';
import HourSelector from 'src/components/HourSelector';
import {
  Body, Footer,
  Header,
} from '../style';
import icons from '../../../assets/icons';

interface PlaceAnnouncementProps {
  period: {
    from: Date;
    to: Date;
  };
  selectedTeamsDefault: Option[];
  locations: Location[];
  onClickBackButton: Function;
}
const ModalPlacesAnnoucement: React.FC<PlaceAnnouncementProps> = ({
  onClickBackButton,
  selectedTeamsDefault,
}) => {
  const { t } = useTranslation('components');
  const daysTranslation = useTranslation('generic');
  const [isLoading, setIsLoading] = useState(false);
  const notifyTypesOptions: Option[] = [
    {
      label: t('ModalSchedulePublish.notify_types.all_in_team'),
      value: 'ALL_TEAM',
    },
    {
      label: t('ModalSchedulePublish.notify_types.all_allocated'),
      value: 'ALL_ALLOCATED',
    },
    {
      label: t('ModalSchedulePublish.notify_types.nobody'),
      value: 'NONE',
    },
  ];

  const scheduleTypesOptions: Option[] = [
    {
      label: daysTranslation.t('periods.monthly'),
      value: 'monthly',
    },
    {
      label: daysTranslation.t('periods.weekly'),
      value: 'weekly',
    },
  ];

  const scheduleDayTypesOptions: Option[] = [
    {
      label: daysTranslation.t('schedule.every_start'),
      value: 'ALL_TEAM',
    },
    {
      label: daysTranslation.t('schedule.every_half'),
      value: 'ALL_ALLOCATED',
    },
    {
      label: daysTranslation.t('schedule.every_end'),
      value: 'NONE',
    },
  ];

  const weekDayTypesOptions: Option[] = [
    {
      label: daysTranslation.t('days.monday'),
      value: 'monday',
    },
    {
      label: daysTranslation.t('days.tuesday'),
      value: 'tuesday',
    },
    {
      label: daysTranslation.t('days.wednesday'),
      value: 'wednesday',
    },
    {
      label: daysTranslation.t('days.thusday'),
      value: 'thusday',
    },
    {
      label: daysTranslation.t('days.friday'),
      value: 'friday',
    },
    {
      label: daysTranslation.t('days.saturday'),
      value: 'saturday',
    },
    {
      label: daysTranslation.t('days.sunday'),
      value: 'sunday',
    },
  ];
  const [selectedTeams, setSelectedTeams] = useState(selectedTeamsDefault);
  const [selectedNotifications, setSelectedNotifications] = useState<Option>(notifyTypesOptions[0]);
  const [selectedScheduleType, setSelectedScheduleType] = useState<Option>({ label: '', value: '' });
  const [selectedSchedule, setSelectedSchedule] = useState<Option>({ label: '', value: '' });
  const [availableTeams, setAvailableTeams] = useState<Team[]>([]);

  useEffect(() => {
    TeamService.getTeamFromCompany()
      .then((r) => setAvailableTeams?.(r.data))
      .catch(() => setAvailableTeams?.([]));
  }, []);

  const teamOptions = useMemo(
    () => Object.values(availableTeams!).map(
      (team) => ({ value: String(team.id), label: team.name, id: String(team.id) }),
    ),
    [availableTeams],
  );

  useEffect(() => {
    setSelectedTeams(selectedTeamsDefault);
  }, [selectedTeamsDefault]);

  useEffect(() => {
    setSelectedSchedule({ label: '', value: '' });
  }, [selectedScheduleType]);

  return (
    <div>
      <Header className="d-flex align-items-center py-4 px-5">
        <button type="button" className="btn-link p-0" onClick={() => onClickBackButton()}>
          <img className="me-3" src={icons.ArrowRoundBack} alt="ArrowRoundBack" />
          {t('ModalPlacesAnnoucementAuto.schedule_announcement')}
        </button>
      </Header>
      <hr />
      <Body className="py-4 px-5 justify-content-between flex-column">
        <div>
          <p>{t('ModalPlacesAnnoucementAuto.description_announcement')}</p>
          <div>
            <SingleSelectInput
              label={t('ModalPlacesAnnoucementAuto.announcement')}
              options={scheduleTypesOptions}
              selectedOption={selectedScheduleType}
              onChange={(changeOptions) => setSelectedScheduleType(changeOptions)}
            />
          </div>
          <div>
            {selectedScheduleType && selectedScheduleType.value === 'monthly'
              ? (
                <SingleSelectInput
                  label={t('ModalPlacesAnnoucementAuto.announcement_day')}
                  options={scheduleDayTypesOptions}
                  selectedOption={selectedSchedule}
                  onChange={(changeOptions) => setSelectedSchedule(changeOptions)}
                />
              )
              : (
                <SingleSelectInput
                  label={t('ModalPlacesAnnoucementAuto.announcement_day')}
                  options={weekDayTypesOptions}
                  selectedOption={selectedSchedule}
                  onChange={(changeOptions) => setSelectedSchedule(changeOptions)}
                />
              )}
          </div>
          <div>
            <HourSelector
              leftLabel={t('ModalPlacesAnnoucementAuto.hour')}
              enableSelectBox
            />
          </div>
          <div>
            <TeamSelector
              allOption={{
                label: t('ModalAnnoucement.teams'),
                value: teamOptions,
              }}
              options={teamOptions}
              defaultValue={selectedTeams}
              onChange={
                (changedSelectedTeams: Option[]) => setSelectedTeams?.(changedSelectedTeams)
              }
            />
          </div>
          <div>
            <SingleSelectInput
              label={t('ModalAnnoucement.notify')}
              options={notifyTypesOptions}
              selectedOption={selectedNotifications}
              onChange={(changeOptions) => setSelectedNotifications(changeOptions)}
            />
          </div>
        </div>
        <Footer>
          <ButtonLoader
            isLoading={isLoading}
            width="100%"
            icon={icons.bullhorn}
            label={t('ModalAnnoucement.publish')}
            onClick={() => setIsLoading(true)}
          />
        </Footer>
      </Body>
    </div>
  );
};

export default ModalPlacesAnnoucement;
