import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
  CardFooter,
  ContentFinishedBox,
  ContentFinishedIcon,
  IconsStore, SkeletonContainer, StoreIcon, Text,
  TextAndIconContainer,
} from './style';
import icons from '../../assets/icons';
import ProgressBar from '../ProgressBar';

type StepProgressProps ={
  steps: Array<{
    type: string | null | undefined,
    value: boolean | number | string | object,
    name: string | undefined
  }>
  isLoading: boolean
};

interface StepProps {
  step: any | {
    type: string | null | undefined,
    value: boolean | number | string | object,
    name: string | undefined
  }
  steps: Array<{
    type: string | null | undefined,
    value: boolean | number | string | object,
    name: string | undefined
  }>
}

interface StoreIconProps {
  url: string,
  icon: string,
  alt: string
}

const StoreIconContainer:FC<StoreIconProps> = ({ url, icon, alt }) => (
  <StoreIcon href={url} target="_blank">
    <img src={icon} alt={alt} />
  </StoreIcon>
);

const ItWasFinished:FC<StepProps> = ({ step, steps }) => {
  const { t } = useTranslation('components');
  const isInstallAppName = 'is_install_app';
  const typeIsInvalidOrTerminated = step.type === undefined || step.type === 'finished' || step.type === null;

  const getUrlToStore = (key: string) => {
    const obj = steps.find((o) => o.name === key);
    return typeof obj?.value === 'string' ? obj?.value : '';
  };

  if (typeIsInvalidOrTerminated) {
    return (
      <ContentFinishedBox active={step.value}>
        <ContentFinishedIcon active={step.value}>
          {step.value && <img src={icons.check} alt="" /> }
        </ContentFinishedIcon>
        <TextAndIconContainer>

          {(step.name === isInstallAppName && step.value === false)
            ? (
              <>
                <Text>{t(`StepProgress.${step.name}`)}</Text>
                <IconsStore>
                  <StoreIconContainer
                    url={getUrlToStore('app_android_url')}
                    icon={icons.playStore}
                    alt="Play Store"
                  />
                  <StoreIconContainer
                    url={getUrlToStore('app_ios_url')}
                    icon={icons.apple}
                    alt="Apple Store"
                  />
                </IconsStore>
              </>
            )
            : (
              <span>
                {t(`StepProgress.${step.name}`, {
                  total: step.value ? 1 : 0,
                })}
              </span>
            )}
        </TextAndIconContainer>
      </ContentFinishedBox>
    );
  }
  return <></>;
};

const StepProgress:FC<StepProgressProps> = ({ steps, isLoading }) => {
  const percentTypeName = 'percent';
  return (
    <div className="card border-0" style={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)', borderRadius: '8px' }}>
      <div className="card-body">
        {isLoading ? (
          <SkeletonContainer>
            <Skeleton
              height={35}
              enableAnimation
              style={{ marginBottom: '15px' }}
              count={5}
            />
          </SkeletonContainer>
        )
          : steps && steps.map((step) => (
            <>
              <ItWasFinished step={step} steps={steps} />
              {step.type === percentTypeName && (
              <CardFooter>
                <ProgressBar percentage={step.value} />
              </CardFooter>
              )}
            </>
          ))}
      </div>
    </div>
  );
};

export default StepProgress;
