import React, { useEffect } from 'react';
import {
  Modal,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CustomNavBar from 'src/components/CustomNavBar';
import icons from 'src/assets/icons';
import CreateEmployee from './CreateEmployee';
import EditEmployee from './EditEmployee';
import Permissions from './Permissions';
import Configurations from './Configurations';
import {
  Header,
  Body,
} from './style';

interface ModalUserfirmProps {
  isOpen: boolean;
  employeeId?: number | null;
  handleCloseButton: () => void;
  handleSuccess?: (createdEmployeeId: any, newlyCreatedEmployee: boolean) => void;
  newlyCreatedEmployee: boolean;
}
const ModalUser: React.FC<ModalUserfirmProps> = ({
  isOpen,
  employeeId = null,
  handleCloseButton,
  handleSuccess = () => {},
  newlyCreatedEmployee,
}) => {
  const { t } = useTranslation('components');
  const pagesNew = [
    {
      label: t('ModalAdminUser.new_title_tab'),
      tab: <CreateEmployee handleSuccess={handleSuccess} />,
    },
  ];
  const pagesEdit = [
    {
      label: t('ModalAdminUser.edit_title_tab'),
      tab: <EditEmployee
        handleSuccess={handleSuccess}
        employeeId={employeeId!}
      />,
    },
    {
      label: t('ModalAdminUser.permissions_title_tab'),
      tab: <Permissions employeeId={employeeId!} />,
    },
    {
      label: t('ModalAdminUser.configurations_title_tab'),
      tab: <Configurations employeeId={employeeId!} />,
    },
  ];
  const escFunction = (event: any) => {
    const escKey = 27;
    if (event.keyCode === escKey) {
      handleCloseButton();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  return (
    <Modal
      toggle={handleCloseButton}
      isOpen={isOpen}
      onClosed={handleCloseButton}
      className="modal-min-width-800"
    >
      <Header className="d-flex justify-content-end">
        <button type="button" onClick={handleCloseButton}>
          <img src={icons.close} className="logo" alt="..." />
        </button>
      </Header>
      <Body>
        <CustomNavBar
          pages={employeeId ? pagesEdit : pagesNew}
          page={employeeId && newlyCreatedEmployee ? 1 : 0}
        />
      </Body>
    </Modal>
  );
};

export default ModalUser;
