import React, { useMemo, useState } from 'react';
import useDays from 'src/hooks/useDays';
import { Container, Day, DaysContainer } from './style';

type NumericDay = 0 | 1 | 2 | 3 | 4 | 5 | 6;
interface SelectWeekDayProps {
  allowedDays?: NumericDay[];
  selectedDaysDefault?: NumericDay[];
  onChange?: (days: NumericDay[]) => void;
  size?: 'small' | 'medium';
  bordered?: boolean;
  label?: React.ReactNode;
  disabled?: boolean
}
const SelectWeekDay: React.FC<SelectWeekDayProps> = ({
  allowedDays,
  selectedDaysDefault,
  onChange,
  size,
  bordered,
  label,
  disabled,
}) => {
  const daysFirstLetter = useDays({ onlyFirstLetter: true, capitalize: true });
  const [selectedDays, setSelectedDays] = useState(selectedDaysDefault || []);

  const handleSelectDay = (day: NumericDay) => () => {
    if (disabled) {
      return;
    }
    let days = selectedDays;
    setSelectedDays((prevDays) => {
      if (prevDays.includes(day)) {
        days = prevDays.filter((prevDay) => prevDay !== day);
      } else {
        days = [...prevDays, day];
      }
      days.sort((a, b) => a - b);
      onChange?.(days);
      return days;
    });
  };

  const daysInOrder = useMemo(() => {
    const copyDaysFirstLetter = [...daysFirstLetter];
    return copyDaysFirstLetter.sort((a, b) => a.id - b.id);
  }, [daysFirstLetter]);

  return (
    <Container>
      {label && <label htmlFor="day-1">{label}</label>}
      <DaysContainer disabled={disabled} bordered={bordered}>
        {daysInOrder
          .filter((day) => allowedDays?.includes(day.id as NumericDay))
          .map((day) => (
            <Day
              id={`day-${day.id}`}
              size={size}
              key={day.id}
              type="button"
              isSelected={selectedDays.includes(day.id as NumericDay)}
              onClick={handleSelectDay(day.id as NumericDay)}
            >
              <span>{day.name}</span>
            </Day>
          ))}
      </DaysContainer>
    </Container>
  );
};
SelectWeekDay.defaultProps = {
  allowedDays: [0, 1, 2, 3, 4, 5, 6],
};
export default SelectWeekDay;
