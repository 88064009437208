import React from 'react';
import { useTranslation } from 'react-i18next';
import { ActiveContainer, IsActiveIcon } from './style';

export interface IsActiveLabelProps { active: boolean }

const IsActiveLabel = ({ active }: IsActiveLabelProps) => {
  const { t } = useTranslation('pages');

  return (
    <ActiveContainer>
      <IsActiveIcon active={active} />
      <span>
        {active
          ? t('Administration.tabs.user.is_active')
          : t('Administration.tabs.user.is_not_active')}
      </span>
    </ActiveContainer>
  );
};

export default IsActiveLabel;
