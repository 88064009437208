import React from 'react';
import icons from 'src/assets/icons';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useGlobal } from 'src/hooks/useGlobal';
import Loader from '../Loader';
import images from '../../assets/images';
import {
  SideBar, HideButton, Body, HideButtonIcon, NavItems, NavbarBrand,
} from './style';
import { getGuide, getUser } from '../../services/auth';
import LinksMenu from './links';

interface Props {
  isLoading?: boolean;
}

interface ItensMenuProps {
  path: string;
  Translation: string;
  iconsMenu: string;
  isOpen: boolean;
  needPayment: boolean;
  id: string;
  show?: boolean | undefined;
  access: String[]
}

const Itens: React.FC<ItensMenuProps> = ({
  path, Translation, iconsMenu, isOpen, id, show, access,
}) => {
  const { t } = useTranslation('components');
  const currentRouteInfo = useLocation();
  const user = getUser();
  const guideSteps = getGuide();
  const nextStep = guideSteps?.nextStep;
  if (user?.onlyCollaborator && !user?.isAdmin) {
    if (!access.includes('Collaborator')) {
      return <div />;
    }
  }
  if (!user?.onlyCollaborator && !user?.isAdmin) {
    if (!access.includes('Organizer')) {
      return <div />;
    }
  }
  if (user?.isAdmin && !access.includes('Admin')) {
    return <div />;
  }
  const showAnimation = `/${nextStep}` === path && guideSteps?.steps[nextStep] === false;

  return (
    <NavItems
      className={`my-4 w-100 ${show !== undefined && show === false && 'd-none-mobile'}`}
      isCurrent={currentRouteInfo.pathname === path}
      data-toggle="tooltip"
      data-placement="top"
      title={t(Translation)}
    >
      <Link
        className="w-100 d-flex justify-content-start ps-4"
        to={path}
        style={{ padding: '30px 0' }}
      >
        <img className={showAnimation ? 'animation-focus-item' : ''} src={iconsMenu} alt={t(Translation)} id={id} />
        {isOpen && t(Translation)}
      </Link>
    </NavItems>
  );
};

const SideMenu: React.FC<Props> = ({
  isLoading,
}) => {
  const { changeSideMenuState, sideMenuIsOpen } = useGlobal();

  return (
    <SideBar open={sideMenuIsOpen} className="px-0">
      <HideButton onClick={() => changeSideMenuState()} className="pb-1">
        <HideButtonIcon src={icons.SideBarArrow} open={sideMenuIsOpen} />
      </HideButton>
      <Body open={sideMenuIsOpen}>
        <NavbarBrand className="w-100">
          <Link to="/" title="Calendário" className="nav-link w-100 d-flex justify-content-start ps-4">
            <img src={sideMenuIsOpen ? images.LogoEscalaEspacosBranco : images.LogoEscalaEspacosMin} className="logo" alt="Escala Espaços" />
          </Link>
        </NavbarBrand>
        {LinksMenu.map((menu) => (
          <Itens
            path={menu.path}
            Translation={menu.translation}
            iconsMenu={menu.icons}
            isOpen={sideMenuIsOpen}
            needPayment={menu.needPayment}
            id={menu.id}
            access={menu.access}
          />
        ))}
        {isLoading && <Loader />}
      </Body>
    </SideBar>
  );
};

export default SideMenu;
