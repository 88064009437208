import styled from 'styled-components';

interface Props {
  back?: boolean;
}

export const Skip = styled.button`
  background: transparent;
  padding: 0 10px 0 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2B3263;
  box-sizing: border-box;
  border: 0;
  text-decoration: underline;
  border-radius: 3px;
`;

export const Steps = styled.div`
  text-align: start;
  margin-bottom: 0;
  pointer-events: none;
  position: absolute;
  bottom: 55px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #2B3263;

  }
`;

export const Button = styled.button<Props>`
  background: #000E4C;
  padding: 4px 8px;
  gap: 8px;
  color: ${(props) => (props.back ? '#000E4C' : '#FFFFFF')};
  border: 0;
  border: 1px solid #000E4C;
  border-radius: 3.2px;
`;

export const Description = styled.h5`
  text-align: left;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000B4C;
  br {
    margin-top: 20px;
  }
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
export const ImageFirst = styled.img`
  height: 150px;
  width: 160px;
`;
export const Title = styled.h1`
  text-align: start;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000B4C;
`;
export const ContainerIcon = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  background: #00B38E;
  border-radius: 6px;
  padding: 10px;
  margin-right: 15px;
`;
export const ImageHeader = styled.img`
  width: 76px !important;
  height: 94px !important;
`;
