import styled, { css } from 'styled-components';
import colors from '../../colors';

interface PropsRow {
  selected: boolean;
  align?: 'right' | 'left' | 'center' | 'justify' | 'start';
}

interface PropsTable {
  grid?: boolean;
  striped?: boolean;
  iconled?: boolean;
  layout?: string;
  align?: 'right' | 'left' | 'center' | 'justify' | 'start';
}

const selectedStyle = css`
  background-color: ${colors.white25};
  border-radius: 7px !important;
  td {
      :nth-child(1) {
        border-radius: 7px 0px 0px 7px;
      }
      :last-child {
        border-radius: 0px 7px 7px 0px;
      }
  }
`;

const hoverStyle = css`
  border-radius: 7px !important;
  td {
    background-color: ${colors.white30} !important;
  }
`;

const gridStyle = css`
  border:1px solid ${colors.white15};
  border-radius: 4px;
`;

export const Container = styled.table<PropsTable>`
  width: 100%;
  table-layout: ${(props) => props.layout || 'auto'};
  overflow-x: auto;

  ${(p) => p.grid && gridStyle}

  tr {
    border-bottom: 1px solid ${colors.white15};
    text-align: ${(p) => p.align || 'left'};
  }
  
  thead {
    tr {
      th {
        text-align: ${(p) => p.align || 'center'};
        padding: 0.8rem 3px 0.8rem 0;
        color: ${colors.gray90};
        font-weight: normal;
        :not(:last-child){
          padding-right: 1rem;
        }
      }
    }
  }
  tbody {
    tr {
      ${(p) => p.striped
    && css`
          :nth-child(odd) {
            td {
              background-color: ${colors.white5};
            }
          }
        `}
    }
      ${(p) => p.iconled
    && css`
            td {
              :last-child {
                  text-align: right;
                  padding: 0.3rem 3px;
              }
            }
        `}
    }
  }
`;

export const Row = styled.tr<PropsRow>`
  border-radius: 5px;
  cursor: pointer;
  td {
    text-align: ${(p) => p.align || 'left'};
    padding: 0.8rem 3px 0.8rem 0;
    :not(:last-child){
      padding-right: 1rem;
    }
    color: ${colors.gray};
  }
  &:hover {
    ${(p) => !p.selected && hoverStyle}
  }
  ${(p) => p.selected && selectedStyle}
`;

export const LoaderContainer = styled.div`
  width: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex: 1;  
`;

export const EmptyContainer = styled.div`
  width: 100%;
  min-height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const EmptyContent = styled.div`
  position: absolute;
  left: 50%;
  right:50%;
  min-width: 100px;
`;

export const EmptyText = styled.span`
  text-align: center;
  padding: 0.8rem 3px;
  color: ${colors.gray};
  font-weight: normal;
  font-size: 16px;
`;

export const FloatContainer = styled.div`
  position: absolute;
  left: 50%;
  right:50%;
`;

export default Container;
