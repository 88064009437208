import { weekDaysReq } from 'src/contracts/constants/days.constants';
import Rule from '../contracts/models/Rule';
import keysToCamel from '../utils/keysToCamelCase';
import api from './api';

export default class RuleService {
    static defaultPath = 'rules';

    public static async createRule(payload: any) {
      return api.post(this.defaultPath, payload);
    }

    public static async editRule(payload: any) {
      return api.put(this.defaultPath, payload);
    }

    public static async removeDay(payload: {'rule_id' : number;'date': string;}) {
      return api.post(`${this.defaultPath}/remove/day`, payload);
    }

    public static async getRuleById(ruleId: number) {
      const response = await api.get<Rule>(`${this.defaultPath}/${ruleId}`);
      response.data = keysToCamel<Rule>(response.data);
      const journeys = JSON.parse(response.data.journeys as any)[0];
      response.data.journeys = journeys.map((j: string) => weekDaysReq.indexOf(j));
      return response;
    }
}
