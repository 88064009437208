import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import { useForm } from 'react-hook-form';
import EmployeeService from 'src/services/EmployeeService';
import Employee from 'src/contracts/models/Employee';
import Input from 'src/components/InputWithRef';
import Validator from 'src/utils/input.valitors';
import ButtonLoader from 'src/components/ButtonLoader';
import Loader from 'src/components/Loader';
import Button from '../../Button';
import {
  Container,
  FormChild,
  Forms,
  InputLabel,
  ProfessionalImage,
  ProfessionalNonImage,
  RequiredLabel,
} from './style';
import trimString, { concatenateCompanyCode } from '../../../utils/trimString';
import { useAlert } from '../../../hooks/useAlert';
import { validateCode } from '../../../utils/validate';
import { getUser } from '../../../services/auth';
import removeExif from '../../../utils/removeExif';

interface EditEmployeeProps {
  employeeId: number;
  handleSuccess: (
    updatedEmployeeId: any,
    newlyCreatedEmployee: boolean
  ) => void;
}

const EditEmployee: React.FC<EditEmployeeProps> = ({
  employeeId,
  handleSuccess,
}) => {
  const { addToast } = useToast();
  const { t } = useTranslation('components');
  const {
    register, errors, handleSubmit, watch,
  } = useForm<FormData>({
    reValidateMode: 'onSubmit',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [photoFileState, setPhotoFileState] = useState<any>(undefined);
  const [photoBase64State, setPhotoBase64State] = useState<any>(undefined);

  const { openAlert } = useAlert();

  async function getEmployeeById(id: number) {
    EmployeeService.getById(id)
      .then((res) => {
        const employeeData = res.data;
        setEmployee(employeeData);
        setPhotoBase64State(employeeData.photo);
      })
      .catch(() => {
        addToast({
          description: t('ModalAdminUser.edit_tab.error_load_message'),
          type: 'error',
        });
      });
  }

  useEffect(() => {
    (async () => {
      await getEmployeeById(employeeId);
    })();
  }, []);

  const appendFormData = async (body: FormData, updatedEmployee: Employee) => {
    const CPF = updatedEmployee.cpf || '';
    const PHONE = updatedEmployee.phone || '';

    await body.append('name', updatedEmployee.name);
    await body.append('phone', PHONE.replace(/[^\d]+/g, ''));

    if (updatedEmployee.email && updatedEmployee.email.length > 0) {
      body.append('email', updatedEmployee.email);
    }

    if (CPF && CPF.length > 0) {
      body.append('cpf', CPF.replace(/[^\d]+/g, ''));
    }

    if (updatedEmployee.code && updatedEmployee.code.length > 0) {
      const user = getUser();
      if (user) {
        const { code } = user.employee.company;
        body.append('code', updatedEmployee.code.replace(`${code}-`, '') || '');
      }
    }
    try {
      await EmployeeService.editEmployee(employeeId, body);
      addToast({
        description: t('ModalTeamAddEmployee.new_employee_tab.edit_success'),
        type: 'success',
      });
      handleSuccess(employeeId, false);
    } catch (err: any) {
      if (err.response && err.response.status === 400) {
        addToast({
          description: err.response.data.message,
          type: 'error',
        });
      } else if (err.response && err.response.status === 422) {
        addToast({
          description: t(
            'ModalTeamAddEmployee.new_employee_tab.create_error_exists',
          ),
          type: 'error',
        });
      } else {
        addToast({
          description: t(
            'ModalTeamAddEmployee.new_employee_tab.create_error',
          ),
          type: 'error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmit = (updatedEmployee: Employee) => {
    const onConfirm = async () => {
      setIsLoading(true);
      const body = new FormData();
      if (!photoFileState) appendFormData(body, updatedEmployee);
      else {
        await removeExif(photoFileState, async (params: any) => {
          await body.append('photo', params?.file ?? '');
          await appendFormData(body, updatedEmployee);
        });
      }
    };

    openAlert({
      handleConfirm: onConfirm,
      message: !updatedEmployee?.email
        ? t('ModalAdminUser.no_email')
        : t('ModalAdminUser.confirm_update_user'),
    });
  };

  const getBase64 = (file: Blob) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

  const handleChange = async (target: HTMLInputElement) => {
    const file = target.files![0];
    setPhotoFileState(file);
    getBase64(file).then((result) => setPhotoBase64State(result));
  };

  const buildFiledSelect = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.type = 'file';
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', 'image/*');
    fileSelector.onchange = (e) => {
      const target = e.target as HTMLInputElement;
      handleChange(target);
    };
    return fileSelector;
  };

  const photoSelector = buildFiledSelect();

  function handleFileSelect(e: { preventDefault: () => void }) {
    e.preventDefault();
    photoSelector.click();
  }

  return (
    <Container>
      <div className="employee-info w-100 d-lg-flex p-4">
        {employee ? (
          <>
            <div className="employee-left w-25 text-center">
              {photoBase64State ? (
                <ProfessionalImage src={photoBase64State} />
              ) : (
                <ProfessionalNonImage>US</ProfessionalNonImage>
              )}

              <Button
                type="button"
                onClick={handleFileSelect}
                label={t('ModalProfessional.EditProfessional.edit_photo')}
              />
            </div>
            <div className="employee-body w-75 ps-md-5 ps-0">
              <Forms
                onSubmit={handleSubmit(onSubmit)}
                method="post"
                encType="multipart/form-data"
              >
                <FormChild>
                  <InputLabel>
                    {t('ModalProfessional.EditProfessional.input_name')}
                    *
                  </InputLabel>
                  <Input
                    error={errors}
                    defaultValue={employee?.name}
                    errorMsg={t(
                      'ModalTeamAddEmployee.new_employee_tab.name_error',
                    )}
                    name="name"
                    register={register({
                      required: true,
                      setValueAs: trimString,
                    })}
                  />
                </FormChild>
                <FormChild>
                  <InputLabel>
                    {t('ModalProfessional.EditProfessional.input_email')}
                  </InputLabel>
                  <Input
                    error={errors}
                    defaultValue={employee?.email}
                    errorMsg={t(
                      'ModalTeamAddEmployee.new_employee_tab.email_error',
                    )}
                    name="email"
                    register={register({
                      validate: (value) => !value || Validator.validateEmail(trimString(value)),
                      setValueAs: trimString,
                    })}
                  />
                </FormChild>
                <FormChild>
                  <InputLabel>
                    {t('ModalProfessional.EditProfessional.input_id')}
                  </InputLabel>
                  <Input
                    error={errors}
                    value={watch('code')}
                    defaultValue={employee?.code}
                    name="code"
                    register={register({
                      setValueAs: concatenateCompanyCode,
                      validate: (value) => validateCode(value, addToast, t),
                    })}
                  />
                </FormChild>
                <FormChild>
                  <InputLabel>
                    {t('ModalProfessional.EditProfessional.input_phone')}
                  </InputLabel>
                  <Input
                    error={errors}
                    defaultValue={employee?.phone}
                    name="phone"
                    mask="(99) 99999-9999"
                    register={register}
                  />
                </FormChild>
                <FormChild>
                  <InputLabel>
                    {t('ModalProfessional.EditProfessional.input_cpf')}
                  </InputLabel>
                  <Input
                    error={errors}
                    errorMsg={t(
                      'ModalTeamAddEmployee.new_employee_tab.cpf_error',
                    )}
                    mask="999.999.999-99"
                    defaultValue={employee?.cpf}
                    name="cpf"
                    register={register({
                      validate: (value) => Validator.validateCPF(
                        trimString(value.replace(/[^\d]/g, '')),
                      ),
                      setValueAs: (value) => trimString(value),
                    })}
                  />
                </FormChild>
                <FormChild>
                  <InputLabel />
                  <RequiredLabel>
                    *
                    {t('ModalTeamAddEmployee.required_item')}
                  </RequiredLabel>
                </FormChild>
                <FormChild>
                  <InputLabel />
                  <ButtonLoader
                    label={t('ModalTeamAddEmployee.new_employee_tab.send')}
                    type="submit"
                    isLoading={isLoading}
                  />
                </FormChild>
              </Forms>
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </Container>
  );
};

export default EditEmployee;
