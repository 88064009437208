import styled from 'styled-components';
import colors from '../../colors';

export const DateContainer = styled.div`
  margin: 0 auto;
  
  @media only screen and (max-width: 768px) {
    text-align: center;
  }
  
  button {
    @media only screen and (max-width: 768px) {
      padding: 12px 2px;
    }
  }
`;

export const TitleDate = styled.h3`
  color: rgb(0, 11, 76);
  min-width: 16vw;
`;

export const LocationName = styled.span`
  font: normal normal bolder 18px/21px lato;
  color: ${colors.gray};
`;

export const Options = styled.div`
  @media only screen and (max-width: 768px) {
    margin: auto !important;
  }
`;

export const LocationAddress = styled.p`
  font: normal normal medium 17px/21px lato;
`;

export const Shifts = styled.p`
  margin-bottom: 0;
  color: ${colors.blue};
  `;

export const Label = styled.p`
  color: ${colors.gray90};
`;

export const Seat = styled.span`
  align-items: center;
  justify-content: center;
  padding: 3px 5px;
  height: 25px;
  border: 1px solid ${colors.red};
  border-radius: 50px;
  background-color: ${colors.red};
`;

export const SeatName = styled.span`
  color: ${colors.gray};
  font-size: 13px;
  vertical-align: top;
`;

export const ButtonArea = styled.div`
  width: 100%;
  border-top: 1px !important;
  border-top-color: #f5f5f5 !important;
  border-top-style: solid !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px 17px 25px;
`;

export const StatusName = styled.p`
  margin-top: 10px;
  color: ${colors.gray};
`;

export const UpperContainer = styled.div`
  @media only screen and (max-width: 768px) {
    display: block !important;
  }
`;
