const colors = {
  blue: '#000b4c',
  blue80: '#2B3263',
  blue70: '#464B8933',
  blue50: '#405cff',
  blue30: '#1075CE',
  blue35: '#4B97D9',
  blue40: '#7085ff',
  blue20: '#D3EAFF',
  blue15: '#D3EAFF84',
  blue10: '#f2f4ff',
  blue5: '#d5ecf2',
  green: '#00b38e',
  green30: '#00D4A9',
  green50: '#3be6c3',
  green70: '#00B38E;',
  red: '#fa969a',
  red70: '#80262A',
  red90: '#4D1719',
  red20: '#FFCCCE',
  orange: '#ffcc19',
  orange70: '#cca313',
  orange80: '#80660c',
  white: '#ffffff',
  white5: '#F3F5F7',
  white8: '#E7E8EE',
  white10: '#F8F8F8',
  white13: '#FDFDFE',
  white15: '#E3E5EB',
  white20: '#f2f4f5',
  white25: '#f0f6fc',
  white30: '#f7fafc',
  gray: '#3B4252',
  gray10: '#3C3E3F',
  gray15: '#DAD9DA',
  gray20: '#A8ADC0',
  gray19: '#9ea1aa',
  gray18: '#9EA3B7',
  gray25: '#E3E5EB',
  gray27: '#E5E5E5',
  gray28: '#C1C1C1',
  gray30: '#A8ABB4',
  gray70: '#b9b9b9',
  gray75: '#919191',
  gray80: '#999',
  gray85: '#9EA3B7',
  gray90: '#727789',
  dark: '#000',
  dark95: '#0003',
  dark90: '#0000001F',
  dark85: '#0000000A',
  dark80: '#151E651A',
  dark75: '#00000080',
};

export default colors;
