import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import icon from 'src/assets/icons';
import {
  Body,
  Container,
  Header,
  Overlay,
  CloseButtom,
} from './style';
import AssociateEmployee from './AssociateEmployee';
import CustomNavBar from '../CustomNavBar';
import CreateEmployee from './CreateEmployee';

interface ModalProfessionalProps {
  isOpen: boolean;
  handleCloseButton: () => void;
  handleSuccessEmployee: Function;
  teamId: number;
}
const ModalProfessional: React.FC<ModalProfessionalProps> = ({
  isOpen,
  handleCloseButton,
  handleSuccessEmployee,
  teamId,
}) => {
  const { t } = useTranslation('components');
  const [totalUsers, setTotalUsers] = useState(0);

  const pages = [
    {
      label: t('ModalTeamAddEmployee.page_label_associate'),
      tab: <AssociateEmployee
        teamId={teamId}
        handleSuccess={handleSuccessEmployee}
        setTotalUsers={setTotalUsers}
      />,
    },
    {
      label: t('ModalTeamAddEmployee.page_label_new'),
      tab: <CreateEmployee
        teamId={teamId}
        handleSuccess={handleSuccessEmployee}
        totalUsers={totalUsers}
      />,
    },
  ];

  return (
    <Overlay isOpen={isOpen}>
      <Container
        isOpen={isOpen}
        className="d-flex flex-column justify-content-between"
      >
        <div>
          <Header className="d-flex justify-content-end">
            <CloseButtom onClick={handleCloseButton}>
              <img src={icon.close} alt="..." />
            </CloseButtom>
          </Header>
          <Body>
            <CustomNavBar
              pages={pages}
            />
          </Body>
        </div>
      </Container>
    </Overlay>
  );
};

export default ModalProfessional;
