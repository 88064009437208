import React, { useEffect } from 'react';
import {
  BrowserRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import Home from './pages/Home';
import Panel from './pages/Panel';
import Reservation from './pages/Reservation';
import AddLocation from './pages/AddLocation';
import Teams from './pages/Teams';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import Administration from './pages/Administration';
import Reports from './pages/Reports';
import Page404 from './pages/Page404';
import Register from './pages/Register';
import Plans from './pages/Plans';
import Attendant from './pages/Attendant';
import PrivateRoute from './PrivateRoute';
import Logout from './pages/Logout';
import { ACTUAL_STEP_ONBOARDING_STORAGE, getData, USER_STORAGE } from './services/LocalStorageService';
import { FINISHED } from './components/Onboarding/contracts/constants';
import CheckIn from './pages/CheckIn';
import Dashboard from './pages/Dashboard';

const Router = () => {
  const getUser = () => {
    const userStorage = getData(USER_STORAGE);

    if (userStorage) {
      return userStorage.user;
    }

    return null;
  };

  const getOnlyCollaborator = () => {
    const userStorage = getData(USER_STORAGE);

    if (userStorage) {
      return userStorage.only_collaborator;
    }

    return null;
  };

  const user = getUser();
  const onlyCollaborator = getOnlyCollaborator();
  const pathOnboarding = '/onboarding';

  useEffect(() => {
    if (user) {
      const actualStepStorage = getData(ACTUAL_STEP_ONBOARDING_STORAGE);
      const userHasNotCompletedOnboardingByStorage = 'step_onboarding' in user && (
        user.step_onboarding !== null && user.step_onboarding !== FINISHED
      );

      const checkIfUserDoesNotHaveCurrentStepSavedButHasStepOnboardingValue = (
        actualStepStorage === null
        && userHasNotCompletedOnboardingByStorage
      );

      const checkIfUserHasNotCompletedOnboardingByCurrentSavedActualStep = (
        actualStepStorage !== null && actualStepStorage !== FINISHED
      );

      if (
        (checkIfUserDoesNotHaveCurrentStepSavedButHasStepOnboardingValue
        || checkIfUserHasNotCompletedOnboardingByCurrentSavedActualStep)
        && window.location.pathname !== pathOnboarding
      ) {
        window.location.href = pathOnboarding;
      }
    }
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path="/login/:code?"
          render={() => (user !== null ? <Redirect to="/" /> : <Login />)}
        />
        <PrivateRoute exact path="/" render={() => (!onlyCollaborator ? <Redirect to="/panel" /> : <Redirect to="/calendar" />)} />
        <PrivateRoute exact path="/panel" component={Panel} />
        <PrivateRoute exact path="/calendar" component={Home} />
        <PrivateRoute exact path="/checkin" component={CheckIn} />
        <PrivateRoute path="/spaces/:tab?" component={Reservation} />
        <Route exact path="/emailPasswordReset" component={ForgotPassword} />
        <Route exact path="/resetPassword" component={ResetPassword} />
        <Route exact path="/register" component={Register} />
        <PrivateRoute exact path="/space/:id?" component={AddLocation} />
        <PrivateRoute exact path="/teams" component={Teams} />
        <PrivateRoute exact path="/management" component={Administration} />
        <PrivateRoute exact path="/reports" component={Reports} />
        <PrivateRoute exact path="/plans" component={Plans} />
        <PrivateRoute exact path="/plans/:plan" component={Attendant} />
        <PrivateRoute exact path="/logout" component={Logout} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <Route path="*" component={Page404} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
