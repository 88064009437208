/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import Loader from 'src/components/Loader';
import metabaseService from '../../../../services/metabaseService';
import {
  Container,
  Column,
  Body,
  CardLarge,
} from '../../style';

const OccupationReports = () => {
  const [executedDailyOccupancyRateChart, setExecutedDailyOccupancyRateChart] = useState<string>('');
  const [executedPlannedOccupancyRate, setExecutedPlannedOccupancyRate] = useState<string>('');

  const loadUrls = async () => {
    const executedUrl = await metabaseService.getExecutedDailyOccupancyRateChart();
    const plannedUrl = await metabaseService.getPlannedDailyOccupancyRate();

    setExecutedDailyOccupancyRateChart(executedUrl.data);
    setExecutedPlannedOccupancyRate(plannedUrl.data);
  };

  useEffect(() => {
    loadUrls();
  }, []);

  if (!executedDailyOccupancyRateChart) {
    return <Loader />;
  }

  return (
    <Container>
      {executedDailyOccupancyRateChart && (
        <Body>
          <Column>
            <CardLarge>
              <iframe
                src={executedDailyOccupancyRateChart!}
                frameBorder="0"
                width="100%"
                height="600"
                allowTransparency
              />
            </CardLarge>
            <CardLarge>
              <iframe
                src={executedPlannedOccupancyRate!}
                frameBorder="0"
                width="100%"
                height="600"
                allowTransparency
              />
            </CardLarge>
          </Column>
        </Body>
      )}
    </Container>
  );
};

export default OccupationReports;
