import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/hooks/toast';
import { useForm } from 'react-hook-form';
import { mixpanelTrack } from 'src/services/MixPanelService';
import EmployeeService from 'src/services/EmployeeService';
import Employee from 'src/contracts/models/Employee';
import Input from 'src/components/InputWithRef';
import Validator from 'src/utils/input.valitors';
import ButtonLoader from 'src/components/ButtonLoader';
import {
  Container, DisableLabel,
  FormChild,
  Forms,
  InputLabel,
  ProfessionalImage,
  ProfessionalNonImage,
  RequiredLabel,
} from './style';
import trimString, { concatenateCompanyCode } from '../../../utils/trimString';
import { useAlert } from '../../../hooks/useAlert';
import { validateCode } from '../../../utils/validate';
import { getUser } from '../../../services/auth';
import { getData, USER_STORAGE } from '../../../services/LocalStorageService';
import removeExif from '../../../utils/removeExif';

interface NewEmployeeProps {
  teamId: number;
  handleSuccess: Function;
  totalUsers: number;
}
const CreateEmployee: React.FC<NewEmployeeProps> = ({
  teamId,
  handleSuccess,
  totalUsers,
}) => {
  const { openAlert } = useAlert();
  const { addToast } = useToast();
  const { t } = useTranslation('components');
  const {
    register, errors, handleSubmit, watch,
  } = useForm<FormData>({
    reValidateMode: 'onSubmit',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [photoFileState, setPhotoFileState] = useState<any>(undefined);
  const [photoBase64State, setPhotoBase64State] = useState<any>(undefined);
  const [disabled, setDisabled] = useState(false);

  const appendFormData = async (body: FormData, employee: Employee) => {
    const CPF = employee.cpf || '';
    const PHONE = employee.phone || '';

    await body.append('name', employee.name);
    await body.append('phone', PHONE.replace(/[^\d]+/g, ''));
    await body.append('team_id', String(teamId));

    if (employee.email && employee.email.length > 0) {
      await body.append('email', employee.email);
    }
    if (CPF && CPF.length > 0) {
      await body.append('cpf', CPF.replace(/[^\d]+/g, ''));
    }
    if (employee.code && employee.code.length > 0) {
      const user = getUser();
      if (user) {
        const { code } = user.employee.company;
        await body.append('code', employee.code.replace(`${code}-`, '') || '');
      }
    }
    EmployeeService.createEmployee(body)
      .then((res) => {
        handleSuccess(res);
        mixpanelTrack('user@create', null);

        addToast({
          description: t('ModalTeamAddEmployee.new_employee_tab.create_success'),
          type: 'success',
        });
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          addToast({
            description: err.response.data.message,
            type: 'error',
          });
        } else if (err.response && err.response.status === 422) {
          addToast({
            description: t('ModalTeamAddEmployee.new_employee_tab.create_error_exists'),
            type: 'error',
          });
        } else {
          addToast({
            description: t('ModalTeamAddEmployee.new_employee_tab.create_error'),
            type: 'error',
          });
        }
      }).finally(() => setIsLoading(false));
  };

  const onSubmit = (employee: Employee) => {
    const onConfirm = async () => {
      setIsLoading(true);
      const body = new FormData();
      if (!photoFileState) appendFormData(body, employee);
      else {
        await removeExif(photoFileState, async (params: any) => {
          await body.append('photo', params?.file ?? '');
          await appendFormData(body, employee);
        });
      }
    };

    openAlert({
      handleConfirm: onConfirm,
      message: !employee?.email ? t('ModalAdminUser.no_email') : t('ModalCreateUser.confirm_create_user'),
    });
  };

  const getBase64 = (file: Blob) => new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
  });

  const handleChange = async (e: Event) => {
    // @ts-ignore
    const file = e.target?.files?.[0];
    setPhotoFileState(file);
    getBase64(file).then((result) => setPhotoBase64State(result));
  };

  const buildFiledSelect = () => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', 'image/*');
    fileSelector.onchange = (e) => handleChange(e);
    return fileSelector;
  };

  const photoSelector = buildFiledSelect();

  function handleFileSelect(e: { preventDefault: () => void; }) {
    e.preventDefault();
    photoSelector.click();
  }

  useEffect(() => {
    const userStorage = getData(USER_STORAGE);
    if (userStorage && userStorage.user.company.payment_type) {
      setDisabled(totalUsers > userStorage.employees_limit);
    }
  }, []);

  return (
    <Container>
      <div className="employee-info w-100 d-flex p-5">
        <div className="employee-left w-25 text-center">
          {photoBase64State ? (
            <ProfessionalImage src={photoBase64State} />
          ) : (
            <ProfessionalNonImage>
              US
            </ProfessionalNonImage>
          )}

          <button
            className="btn btn-link mt-2"
            type="button"
            onClick={handleFileSelect}
            disabled={disabled}
          >
            {t('ModalProfessional.EditProfessional.edit_photo')}
          </button>

        </div>
        <div className="employee-body w-75 ps-md-5 ps-0">
          <Forms onSubmit={handleSubmit(onSubmit)} method="post" encType="multipart/form-data">
            <FormChild>
              <InputLabel>
                {t('ModalProfessional.EditProfessional.input_name')}
                *
              </InputLabel>
              <Input
                error={errors}
                errorMsg={t('ModalTeamAddEmployee.new_employee_tab.name_error')}
                name="name"
                register={register({ required: true, setValueAs: trimString })}
                disabled={disabled}
              />
            </FormChild>
            <FormChild>
              <InputLabel>
                {t('ModalProfessional.EditProfessional.input_email')}

              </InputLabel>
              <Input
                error={errors}
                errorMsg={t('ModalTeamAddEmployee.new_employee_tab.email_error')}
                name="email"
                register={register({
                  validate: (value) => !value || Validator.validateEmail(trimString(value)),
                  setValueAs: trimString,
                })}
                disabled={disabled}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_id')}</InputLabel>
              <Input
                error={errors}
                value={watch('code')}
                name="code"
                register={register({
                  setValueAs: concatenateCompanyCode,
                  validate: (value) => validateCode(value, addToast, t),
                })}
                disabled={disabled}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_phone')}</InputLabel>
              <Input
                error={errors}
                name="phone"
                mask="(99) 99999-9999"
                register={register}
                disabled={disabled}
              />
            </FormChild>
            <FormChild>
              <InputLabel>{t('ModalProfessional.EditProfessional.input_cpf')}</InputLabel>
              <Input
                error={errors}
                errorMsg={t('ModalTeamAddEmployee.new_employee_tab.cpf_error')}
                mask="999.999.999-99"
                name="cpf"
                register={register({
                  validate: (value) => !value || Validator.validateCPF(value.replace(/[^\d]/g, '')),
                })}
                disabled={disabled}
              />
            </FormChild>
            <FormChild>
              <InputLabel />
              {disabled
                ? (
                  <DisableLabel>
                    {t('ModalTeamAddEmployee.update_plan')}
                  </DisableLabel>
                )
                : (
                  <RequiredLabel>
                    *
                    {' '}
                    {t('ModalTeamAddEmployee.required_item')}
                  </RequiredLabel>
                )}
            </FormChild>
            <FormChild>
              <InputLabel />
              <ButtonLoader
                type="submit"
                label={t('ModalTeamAddEmployee.new_employee_tab.send')}
                isLoading={isLoading}
                disabled={disabled}
              />
            </FormChild>
          </Forms>
        </div>
      </div>
    </Container>
  );
};

export default CreateEmployee;
