/* eslint-disable import/no-cycle */
import mixpanel from 'mixpanel-browser';
import {
  getUser,
} from './auth';

export interface IPeople {
  id: number;
  company: string;
  'company_name': string;
  'company_id': number;
  email: string;
  '$email': string;
  '$avatar': string;
  '$created': string;
  name: string;
  cpf: string;
  code: string;
  role: string;
  '$name': string;
  'last_login': string;
  phone: string;
  'is_paying': boolean;
}

const mixpanelToken = process.env.REACT_APP_MIXPANEL_TOKEN as string;
const ENV = 'PROD';

export const startMixpanel = () => {
  if (process.env.REACT_APP_ENV === ENV) {
    mixpanel.init(mixpanelToken, {
      debug: true,
    });

    const user = getUser();
    if (user) {
      try {
        mixpanel.identify(user.employee.id);
      } catch (e) {
        console.log('Not a valid JWT token found. Proceeding without mixpanel');
      }
    }
  }
};

export const mixpanelPeopleSet = (prop: IPeople) => {
  try {
    mixpanel.people.set(prop);
  } catch (error) {
    console.log('mixpanelPeopleSet error');
  }
};

export const mixpanelReset = () => {
  try {
    mixpanel.reset();
  } catch (error) {
    console.log('mixpanelReset error');
  }
};

export const mixpanelPeopleSetOnce = (prop: any) => {
  try {
    mixpanel.people.set_once(prop);
  } catch (error) {
    console.log('mixpanelPeopleSetOnce error');
  }
};

export const mixpanelTrack = (event: string, props: any) => {
  try {
    if (process.env.REACT_APP_ENV === ENV) {
      mixpanel.init(mixpanelToken, {
        debug: true,
      });
      mixpanel.track(event, props);
    }
  } catch (error) {
    console.log('mixpanelTrack error', error);
  }
};

export const mixpanelIncrement = (event: string, value: number | null) => {
  try {
    if (value === null) {
      mixpanel.people.increment(event, 1);
      return;
    }
    mixpanel.people.increment(event, value);
  } catch (error) {
    console.log('mixpanelTrack error');
  }
};
