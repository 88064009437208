import styled from 'styled-components';
import colors from '../../../colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Avatar = styled.img`
    border-radius: 40px;    
    width: 40px;
    margin-right: 10px;
    height: 40px;
    object-fit: cover;
    border: solid 2px ${colors.white};
`;

export const AvatarNonImage = styled.div`
    border-radius: 40px;    
    margin-right: 10px;
    width: 40px;
    height: 40px;
    background-color: ${colors.gray18};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 10px;
`;

export const NameText = styled.span`
    font-weight: bold;
`;

export const EmailText = styled.span`
    font-size: 10px;
`;
