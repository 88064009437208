import Api from './api';

export default class PlacementService {
  static DEFAULT_URL = 'jobs';

  static STATUS = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    PENDING: 'PENDING',
  };

  static async getJobStatus(jobId: number) {
    const response = await Api.get(`${this.DEFAULT_URL}/${jobId}`);
    return response;
  }
}
