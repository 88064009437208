import styled, { css } from 'styled-components';
import { lighten } from 'polished';
import colors from '../../colors';

interface Props {
    isLoading?: boolean;
    uppercase?: boolean;
    transparent?: boolean;
    bordered?: boolean;
    icon?: boolean;
    label?: boolean;
    width?: string;
    withHover?: boolean;
}

const hoverLabelStyle = css`
    &:hover {
        background-color: ${lighten(0.1, `${colors.blue}`)};
    }
`;

const hoverIconStyle = css`
    &:hover {
        background-color: ${lighten(0.1, `${colors.blue}`)};
    }
`;

const hoveButtonStyle = css`
    &:hover {
        background-color: inherit;
    }
`;

const borderedStyle = css`
    color: ${colors.blue};
    background-color: transparent;
    border: 1px solid ${colors.blue};
`;

const iconledStyle = css`
    min-width: 0px;
    span {
        margin-left: 10px;
    }
`;

export const Button = styled.button<Props>`
    padding: 12px 14px;
    ${(props) => (props.uppercase && 'text-transform: uppercase;')}
    font-size: 14px;
    color: ${colors.white};
    outline: 0;
    height: auto;
    text-align: center;
    border-radius: 5px;
    border: 1px solid ${colors.blue};
    background-color: ${(props) => ((props.isLoading || props.disabled) ? lighten(0.09, `${colors.blue}`) : `${colors.blue}`)};
    border: none;
    ${(props) => props.transparent && `background-color: transparent; color: ${colors.blue};`}
    ${(props) => props.bordered && borderedStyle}
    transition: 0.5s;
    ${(props) => (props.icon && iconledStyle)}
    ${(props) => (props.label && 'min-width: 130px;')}
    ${(props) => ((props.icon || props.transparent) && !props.isLoading && !props.disabled && hoverIconStyle)}
    ${(props) => (props.label && !props.isLoading && !props.transparent && !props.disabled && hoverLabelStyle)}
    ${(props) => (props.width && `width:${props.width};`)}
    ${(props) => (props.color && `background-color:${props.color};!important`)}
    ${(props) => (!props.withHover && hoveButtonStyle)}

    p {
        margin: 0;
        padding: 0;
    }
`;

export const LoaderButton = styled.img`
    height: 25px;
    width: 25px;
`;

export const Row = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

export const Icon = styled.img<{size :number}>`
    height: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
`;
