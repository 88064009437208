import styled from 'styled-components';
import colors from '../../colors';

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${(p) => (p.isOpen ? 'block' : 'none')};
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: #0002;
  left: 0;
  top: 0;
  z-index: 98;
`;
export const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  padding: 1rem;
  top: 30px;
  left: 225px;
  width: 500px;
  background-color: white;
  box-shadow: 0px 3px 6px #00000027;
  transition: 1s ease-in-out;
  border-radius: 2px;
`;
export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  h3 {
    font-size: 1.3rem;
  }
  button {
    position: absolute;
    background-color: transparent;
    font-size: 1.2rem;
    border: none;
    top: 20px;
    right: 25px;
  }
`;
export const Title = styled.h3`
  font-weight: bold;
  font-family: lato;
  color: var(--primary-gray);
  text-align: center;
  margin-top: 25px;
`;
export const IconModal = styled.img`
  height: 55px;
  width: auto;
`;
export const Message = styled.span`
  font-size: 15px;
  font-weight: 500;
  font-family: lato;
  color: var(--primary-gray);
  text-align: center;
`;
export const Body = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;
export const ContainerButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  button:first-child {
    margin-right: 16px;
  }
`;
export const CancelButton = styled.button`
  height: 45px;
  font-family: lato;
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 5px;
  width: 110px;
  cursor: pointer;
  background-color: white;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    border: none !important;
  }
  &:focus {
    border: none !important;
    outline: none !important;
  }
`;
export const CloseButton = styled.button`
  height: 26px;
  width: 26px;
  transition: 0.4s;
  cursor: pointer;
  padding: 5px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--primary-gray);
  &:hover {
    background-color: rgb(33, 74, 47, 0.2);
  }
`;
export const ConfirmButton = styled.button`
  height: 35px;
  margin-left: 20px;
  font-family: lato;
  color: white;
  border: none;
  height: 45px;
  width: 138px;
  margin-right: 20px;
  background: ${colors.blue} 0% 0% no-repeat padding-box;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    border: none !important;
  }
  &:focus {
    border: none !important;
    outline: none !important;
  }
`;
