import React, { useState } from 'react';
import COLORS from 'src/contracts/constants/color.picker.constants';
import { Container, ColorOption } from './style';
import ColorModal from './ColorModal';
import './arrowPosition.css';

interface ColorPickerProps {
    color: string | null;
    onChangeColor: Function;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, onChangeColor }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleSelect = (newColor: string) => {
    setModalOpen(false);
    onChangeColor(newColor);
  };

  return (
    <Container>
      {color ? <ColorOption color={color} id="selector" onClick={() => setModalOpen(true)} />
        : (
          <ColorOption color="#fff" id="selector" onClick={() => setModalOpen(true)}>
            X
          </ColorOption>
        )}

      {modalOpen && (
        <ColorModal
          colors={COLORS}
          selectedColor={color}
          onClose={() => setModalOpen(false)}
          onSelect={handleSelect}
        />
      )}
    </Container>
  );
};

export default ColorPicker;
