/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable import/no-duplicates */
import {
  addDays,
  areIntervalsOverlapping,
  endOfWeek,
  format,
  isBefore,
  parse,
} from 'date-fns';
import React, {
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FiMap } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { homeContext, SelectedUser } from 'src/pages/Home/Context';
import Employee from 'src/contracts/models/Employee';
import { v4 } from 'uuid';
import { mixpanelTrack } from 'src/services/MixPanelService';
import {
  buildCalendarWeekdayHeaderValue, filterShifts, DayAdapter, OpenDays, CalendarProps,
} from 'src/utils/calendarFunctions';
import ShiftsEnum from '../../contracts/constants/shifts.types.enum';
import {
  Container,
  DaysName,
  Day,
  Location,
  TotalEmployees,
  Shifts,
  DayShifts,
  SubtitleContainer,
  CalendarOverlay as CalendarOverlayStyled,
  MapButtom,
} from './style';
import Shift from './Shift';
import ModalShiftEdit, { ModalEdiShiftDataAttr } from '../ModalShiftEdit';
import ModalDeleteAnnouncement from '../ModalDeleteAnnouncement';
import ModalMapView from '../ModalMapView';
import NulledCell from './NulledCell';
import Announcement from './Announcement';
import { useToast } from '../../hooks/toast';
import keysToCamel from '../../utils/keysToCamelCase';
import { DEFAULT_END_TIME } from '../../contracts/constants/times.constants';
import CalendarContextProvider, { useCalendarContext } from './context';
import { useAlert } from '../../hooks/useAlert';
import ShiftService from '../../services/ShiftService';
import ShiftModel from '../../contracts/models/Shift';
import Placement from '../../contracts/models/Placement';
import getControlOrMetaKey from '../../utils/getControlOrMetaKey';
import Loader from '../Loader';
import useDebounce from '../../hooks/useDebounce';
import TeamService from '../../services/TeamService';
import { useGlobal } from '../../hooks/useGlobal';

export type ModalDeleteAnnocuementAttr = {
  announcement?: ShiftModel | null;
  dayName?: string;
};

const CalendarOverlay = () => (
  <CalendarOverlayStyled>
    <div>
      <Loader />
    </div>
  </CalendarOverlayStyled>
);

const DEFAULT_TEAMS_TO_SHOW = 3;
const Calendar: React.FC<CalendarProps> = ({
  date,
  isOpenEmployeeEdit,
  selectedTeamsId,
}) => {
  const {
    placement,
    isCalendarLoading,
    loadCalendar,
    selectedUserId,
    setSelectedUserId,
    setPlacement,
  } = useContext(homeContext);
  const [collapsedPlacements, setCollapsedPlacements] = useState<boolean>(
    false,
  );
  const { user } = useGlobal();
  const weekEnd = useMemo(() => endOfWeek(date, { weekStartsOn: 1 }), [date]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [modalEditShiftData, setModalEditShiftData] = useState<
    ModalEdiShiftDataAttr
  >({});
  const [modalMapView, setModalMapView] = useState<boolean>(false);
  const [teamHasEmployee, setTeamHasEmployee] = useState<any[]>([]);
  const [modalDeleteAnnoucement, setModalDeleteAnnoucement] = useState<
    ModalDeleteAnnocuementAttr
  >({});

  const [totalTeamsToShow, setTotalTeamsToShow] = useState(
    DEFAULT_TEAMS_TO_SHOW,
  );
  const { t } = useTranslation('components');

  const { addToast } = useToast();

  const debounceSearch = useDebounce(searchInput, 700);

  const calendarData = useMemo(() => {
    if (
      !placement
      || !placement?.shifts
      || !placement?.shifts?.dates
      || !placement?.shifts?.weekday
      || !placement?.teams
      || !placement?.openDays
    ) {
      return {
        name: [],
        vacancy: [],
        shifts: [],
        openDays: {} as OpenDays,
      };
    }
    const buildOpenDays = (): OpenDays => {
      const openDaysValues = Object.values(placement?.openDays as object);

      function getDaysFromOpenDayInRange(openDay: any, dateEnd: Date) {
        const openDayStart = parse(openDay.start, 'yyyy-MM-dd', new Date());
        let currentDate = openDayStart;
        const dateEndCopy = openDay.end && dateEnd.toJSON().slice(0, 10) > openDay.end
          ? parse(openDay.end, 'yyyy-MM-dd', new Date())
          : dateEnd;

        const days: any = {};
        while (isBefore(currentDate, dateEndCopy)) {
          const dayName = format(currentDate, 'E').toUpperCase();
          if (openDay?.days?.[dayName]) {
            days[dayName] = openDay?.days?.[dayName];
          }
          currentDate = addDays(currentDate, 1);
        }

        return days;
      }

      let openDaysObject: any = {};
      try {
        const currentDate = new Date();
        const openDaysFiltered = openDaysValues
          .filter(
            (openDay) => (openDay.start <= date.toJSON() && !openDay.end)
              || !openDay.end
              || (openDay.end
                && areIntervalsOverlapping(
                  {
                    start: new Date(`${openDay.start}T00:00:00`),
                    end: new Date(`${openDay.end}T00:00:00`),
                  },
                  { start: date, end: endOfWeek(date, { weekStartsOn: 1 }) },
                )),
          )
          .map((openDayFiltered) => ({
            ...openDayFiltered,
            weekDayStart: format(
              parse(openDayFiltered.start, 'yyyy-MM-dd', currentDate),
              'E',
            ).toUpperCase(),
          }));
        openDaysFiltered.forEach((openDay) => {
          const days = getDaysFromOpenDayInRange(openDay, weekEnd);
          Object.keys(days).forEach((dayKey) => {
            openDaysObject = {
              ...openDaysObject,
              [dayKey]: {
                maxVacancy: openDay.days?.[dayKey]?.max_vacancy,
                weekDay: openDay.days?.[dayKey]?.weekday,
                openDayId: openDay.id,
                dateStart: openDay.start,
                dateEnd: openDay.end,
                timeStart: openDay?.days[dayKey]?.start,
                timeEnd: openDay?.days?.[dayKey]?.end || DEFAULT_END_TIME,
              },
            };
          });
        });
      } catch (error) {
        addToast({
          type: 'error',
          description: t('Calendar.placement_open_day_error'),
        });
      }

      return openDaysObject;
    };
    const openDays = buildOpenDays();

    const buildCalendarVacancyHeaderValue = (
      placementMaxVacancy: any,
      dayEmployees: any,
    ) => {
      if (
        dayEmployees
        && dayEmployees.allocated_employees
        && dayEmployees.shifts
      ) {
        return {
          available:
            placementMaxVacancy - Object.values(dayEmployees.shifts).length,
          filled: Object.values(dayEmployees.allocated_employees).length,
        };
      }
      return {
        available: placementMaxVacancy,
        filled: 0,
      };
    };

    const buildCalendarDayShiftColumnToUnnamedPosition = (
      placementMaxVacancy: any,
      dayEmployees: any,
      day: string,
    ) => {
      const data = [];
      if (!dayEmployees?.shifts && !dayEmployees?.announcements) {
        for (let i = 0; i < placementMaxVacancy; i += 1) {
          data.push({
            type: ShiftsEnum.types.empty,
            id: `${i}_${day}`,
            code: `${day}_empty${i}`,
            allocatedAt: day,
          });
        }
        return data;
      }
      if (dayEmployees.shifts) {
        const allocationData: any = Object.values(dayEmployees.shifts);
        for (let i = 0; i < placementMaxVacancy; i += 1) {
          if (allocationData[i]) {
            const allocatedEmployees: any[] = Object.values(
              allocationData[i].allocated_employees_this_shift,
            );
            data.push({
              type: ShiftsEnum.types.allocated,
              id: allocationData[i].id,
              code: `${day}_${allocationData[i].id}`,
              isManageable: true,
              employees: allocatedEmployees.map(keysToCamel),
              allocatedAt: day,
              ruleId: allocationData[i].rule_id,
              isPublished: allocationData[i]?.is_published,
            });
          } else {
            break;
          }
        }
      }
      if (dayEmployees.announcements) {
        const allocationData: any = Object.values(dayEmployees.announcements);
        const allocationIds: any = Object.keys(dayEmployees.announcements);
        const { length } = data;
        for (let i = 0; i < placementMaxVacancy - length; i += 1) {
          if (allocationData[i]) {
            data.push({
              type: ShiftsEnum.types.announcement,
              id: Number(allocationIds[i]),
              allocatedAt: day,
              teams: allocationData[i].teams,
              placementId: placement?.id as number,
              code: `${day}_announcement${allocationIds[i]}`,
            });
          } else {
            break;
          }
        }
      }
      if (data.length < placementMaxVacancy) {
        const { length } = data;
        for (let i = 0; i < placementMaxVacancy - length; i += 1) {
          data.push({
            type: ShiftsEnum.types.empty,
            id: `${i}_${day}`,
            allocatedAt: day,
            placementId: placement?.id as number,
            code: `${day}_empty${i}`,
          });
        }
      }
      return data;
    };

    const buildCalendarDayShiftColumnToNamedPosition = (
      shiftDateValue: any,
      dateString: string,
    ) => {
      const { seatsName } = placement!;
      if (!seatsName) return;
      const { shifts, announcements } = shiftDateValue || {};
      let positionedShifts = Array(seatsName.length).fill(false);

      if (!shifts && !announcements) {
        positionedShifts = positionedShifts.map((p, i) => ({
          type: ShiftsEnum.types.empty,
          id: `${i}_${dateString}`,
          allocatedAt: dateString,
          placementId: placement?.id as number,
          code: `${dateString}_empty${i}`,
          seatId: seatsName[i].id,
        }));
        return positionedShifts;
      }
      const shiftsValues = Object.values(shifts || {});
      const announcementValues = Object.values((announcements as any[]) || {});
      const announcementIds: any = Object.keys(announcements || {});
      announcementIds.forEach((id: any, index: number) => {
        announcementValues[index].id = Number(id);
      });

      positionedShifts = positionedShifts.map((_, positionIndex) => {
        const shift: any = shiftsValues.find(
          (s: any) => s.seat_id === +seatsName[positionIndex].id,
        );
        const annoucement: any = announcementValues.find(
          (a: any) => a.seat_id === +seatsName[positionIndex].id,
        );
        if (shift) {
          const { allocated_employees_this_shift: allocatedEmployees } = shift;
          return {
            ...shift,
            type: ShiftsEnum.types.allocated,
            id: shift.id,
            code: `${dateString}_${shift.id}`,
            isManageable: true,
            employees: Object.values(allocatedEmployees),
            allocatedAt: dateString,
            ruleId: shift.rule_id,
            seatId: seatsName[positionIndex].id,
            isPublished: shift?.is_published,
          };
        }
        if (annoucement) {
          return {
            ...annoucement,
            type: ShiftsEnum.types.announcement,
            id: annoucement.id,
            code: `${dateString}_announcement${annoucement.id}`,
            allocatedAt: dateString,
            teams: annoucement.teams,
            seatId: seatsName[positionIndex].id,
          };
        }
        return {
          type: ShiftsEnum.types.empty,
          id: `${positionIndex}_${dateString}`,
          allocatedAt: dateString,
          placementId: placement?.id as number,
          code: `${date}_empty${positionIndex}`,
          seatId: seatsName[positionIndex].id,
        };
      });
      return positionedShifts;
    };

    const getTheBusiestDay = (days: any[]) => {
      let maxOcupation = 0;
      days.forEach((day: any) => {
        if (Array.isArray(day)) {
          return;
        }
        const announcementsCount = Object.values(day.announcements || {})
          .length;
        const shiftsCount = Object.values(day.shifts || {}).length;
        const occupation = announcementsCount + shiftsCount;
        if (maxOcupation < occupation) {
          maxOcupation = occupation;
        }
      });
      return maxOcupation;
    };
    const name: {
      dayName: string;
      isAllowed: boolean;
      isWeekend?: boolean;
      day: Date;
      formattedDay: string;
      dayFormatted: string;
    }[] = [];
    let filteredDates = placement.shifts.dates;
    if (searchInput.length > 0) {
      filteredDates = filterShifts(placement, searchInput);
    }
    const vacancy: any[] = [];
    const shifts: any[] = [];
    const shiftDatesValues = Object.values(filteredDates);
    const shiftDateskey = Object.keys(filteredDates);
    const unfilteredDates = Object.values(placement.shifts.dates);
    const weekDays = Object.values(placement.shifts.weekday);

    let totalOccupation = getTheBusiestDay(shiftDatesValues);

    totalOccupation = totalOccupation === placement.maxVacancy
      ? totalOccupation
      : totalOccupation + 1;
    shiftDatesValues.forEach((shiftDateValue, index) => {
      name.push(buildCalendarWeekdayHeaderValue(shiftDateskey[index], openDays));

      const vacancyCalculation = buildCalendarVacancyHeaderValue(
        placement.maxVacancy,
        unfilteredDates[index],
      );
      vacancy.push({
        filled: vacancyCalculation.filled,
        available: vacancyCalculation.available,
      });

      const dayName = weekDays[index];
      if (placement.seatsName?.length) {
        shifts.push({
          data: buildCalendarDayShiftColumnToNamedPosition(
            shiftDateValue,
            shiftDateskey[index],
          ),
          day: shiftDateskey[index],
          dayName: DayAdapter[String(dayName)],
        });
      } else {
        shifts.push({
          data: buildCalendarDayShiftColumnToUnnamedPosition(
            totalOccupation,
            shiftDateValue,
            shiftDateskey[index],
          ),
          day: shiftDateskey[index],
          dayName: DayAdapter[String(dayName)],
        });
      }
    });

    return {
      name,
      vacancy,
      shifts,
      openDays,
    };
  }, [placement, date, debounceSearch]);

  const {
    selectedShifts,
    selectedNulledShifts,
    setSelectedShifts,
    setSelectedNulledShifts,
  } = useCalendarContext();
  const { openAlert } = useAlert();

  useEffect(() => {
    if (placement?.teams && placement.teams.length < DEFAULT_TEAMS_TO_SHOW) {
      setTotalTeamsToShow(placement.teams.length);
    }
  }, [placement?.teams]);

  const removeSelecteds = () => {
    setSelectedShifts({});
    setSelectedNulledShifts({});
    setSelectedUserId?.(null);
  };

  useEffect(() => {
    if (selectedUserId) {
      const shiftsWithSelectedUser: { [x: string]: ShiftModel } = {};
      calendarData.shifts.forEach((shift: any) => {
        shift?.data?.forEach((shiftData: any) => {
          if (
            shiftData?.employees?.some(
              (employee: Employee) => employee.id === selectedUserId.id,
            )
          ) {
            shiftsWithSelectedUser[shiftData.id] = shiftData;
          }
        });
      });

      setSelectedShifts(shiftsWithSelectedUser);
      mixpanelTrack('shifts@multiselect', null);
    } else {
      setSelectedNulledShifts({});
    }
  }, [selectedUserId, calendarData]);

  useEffect(() => {
    if (Object.keys(selectedNulledShifts).length === 1) {
      setSelectedShifts({});
    }
  }, [selectedNulledShifts]);

  useEffect(() => {
    TeamService.getTeamHasEmployee().then((res) => setTeamHasEmployee(res.data));
  }, []);
  const cleanDomEvents = () => {
    document.onkeydown = null;
  };

  const addDOMEvents = () => {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    document.onkeydown = handleCalendarKeyDown as any;
  };

  const deleteBatchShifts = () => {
    const selectedShiftsIds = Object.keys(selectedShifts);
    const selectedShiftsValues = Object.values(keysToCamel(selectedShifts));
    if (selectedShiftsIds.length) {
      cleanDomEvents();
      const onConfirm = async () => {
        addDOMEvents();
        try {
          const payload = {
            shifts: selectedShiftsValues
              .map((shift) => shift.employees?.map((employee) => ({
                id: shift.id as number,
                teamId: employee.teamId,
              })))
              .flat() as { teamId: number; id: number }[],
            placementId: placement?.id as number,
          };
          const response = await ShiftService.batchDeleteShifts(payload);
          if (response.status === 200) {
            mixpanelTrack('shift@batch-delete', null);
            addToast({
              type: 'success',
              description: t('Calendar.success_shift_batch_delete'),
            });
            removeSelecteds();
          }

          await loadCalendar?.();
        } catch (error) {
          addToast({
            type: 'error',
            description: t('Calendar.error_shift_batch_delete'),
          });
        }
      };
      openAlert({
        handleConfirm: onConfirm,
        handleCloseButton: addDOMEvents,
        title: t('Calendar.confirm_shift_batch_delete_title'),
        message: t('Calendar.confirm_shift_batch_delete_message'),
      });
    }
  };
  const addShiftsInCalendar = (
    shifts: {
      employeeId: number;
      start: string;
      end: string;
      allocatedAt: string;
      teamId: number;
      seatId: number | null;
      id: string;
    }[],
    employee: SelectedUser,
  ) => {
    setPlacement?.((prev) => {
      const copyPrev: any = { ...prev };
      shifts.forEach((shift) => {
        copyPrev.shifts.dates[shift.allocatedAt] = {
          allocated_employees: {
            ...copyPrev.shifts.dates[shift.allocatedAt].allocated_employees,
            [shift.employeeId]: shift.employeeId,
          },
          shifts: {
            ...copyPrev.shifts.dates[shift.allocatedAt].shifts,
            [shift.id]: {
              id: shift.id,
              rule_id: null,
              seat_id: null,
              is_published: 0,
              allocated_employees_this_shift: {
                [shift.employeeId]: {
                  id: shift.employeeId,
                  name: selectedUserId?.name,
                  team_id: shift.teamId,
                  team_name: selectedUserId?.teamName,
                },
              },
            },
          },
        };
        const teamId = shift.teamId as any;
        const allocatedAt = shift.allocatedAt as any;
        const employeeId = shift.employeeId as any;
        const teamRelay = copyPrev.teams.find((team: any) => team.id === teamId)
          .team_is_relay;

        if (teamRelay) {
          // delete copyPrev.homeOffice![allocatedAt][teamId][employeeId];
        }
      });
      return copyPrev;
    });
  };

  const removeShiftsFromCalendar = (
    shifts: {
      id: string | number;
      employeeId: number;
      start: string;
      end: string;
      allocatedAt: string;
      teamId: number;
      seatId: number | null;
    }[],
    employee: SelectedUser,
  ) => {
    setPlacement?.((prev) => {
      const copyPrev: Placement = { ...prev };
      shifts.forEach((shift) => {
        delete copyPrev.shifts.dates[shift.allocatedAt].shifts[shift.id];
      });
      return copyPrev;
    });
  };

  const createBatchShifts = () => {
    if (Object.keys(selectedShifts).length) {
      addToast({
        type: 'error',
        description: t('Calendar.validations.not_selected_created_shifts'),
      });
      return;
    }

    if (!Object.keys(selectedNulledShifts).length || !selectedUserId) {
      return;
    }
    const shiftsPayload = Object.values(selectedNulledShifts).map(
      (nulledShift) => ({
        employeeId: selectedUserId.id,
        start: `${nulledShift.allocatedAt} ${nulledShift.start}`,
        end: `${nulledShift.allocatedAt} ${nulledShift.end}`,
        allocatedAt: nulledShift.allocatedAt,
        teamId: selectedUserId.teamId,
        seatId: nulledShift.seatId || null,
        id: v4(),
      }),
    );
    const payload = {
      placementId: placement?.id as number,
      shifts: shiftsPayload,
    };

    cleanDomEvents();
    const onConfirm = async () => {
      addDOMEvents();

      ShiftService.batchCreateShifts(payload)
        .then(async () => {
          try {
            mixpanelTrack('shift@batch-create', null);
            await loadCalendar?.(false);
            removeSelecteds();
            addToast({
              type: 'success',
              description: t('Calendar.success_shift_batch_create'),
            });
          } catch (error) {
            addToast({
              type: 'error',
              description: t('Calendar.load_calendar_error'),
            });
          }
        })
        .catch((error) => {
          removeShiftsFromCalendar(shiftsPayload, selectedUserId);
          if (error?.response?.data?.message) {
            addToast({
              type: 'error',
              description: error?.response?.data?.message,
            });
          } else {
            addToast({
              type: 'error',
              description: t('Calendar.error_shift_batch_create'),
            });
          }
        });
      setSelectedNulledShifts({});
      addShiftsInCalendar(shiftsPayload, selectedUserId);
    };
    openAlert({
      handleConfirm: onConfirm,
      handleCloseButton: addDOMEvents,
      message: t('Calendar.confirm_shift_batch_create_message'),
      title: t('Calendar.confirm_shift_batch_create_title'),
    });
  };

  useEffect(() => {
    if (isOpenEmployeeEdit) {
      setSelectedShifts({});
    }
  }, [isOpenEmployeeEdit]);

  const handleCalendarKeyDown = (event: KeyboardEvent) => {
    if (!['Escape', 'Delete', 'Enter', 'Backspace'].includes(event.key)) return;
    switch (event.key) {
      case 'Delete':
      case 'Backspace': {
        deleteBatchShifts();
        break;
      }
      case 'Escape': {
        removeSelecteds();
        break;
      }
      case 'Enter': {
        createBatchShifts();
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    document.onkeydown = handleCalendarKeyDown as any;

    return () => {
      document.onkeydown = null;
    };
  }, [selectedShifts, selectedNulledShifts]);

  const handleShiftClick = (shift: ShiftModel, dayName: string) => (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
  ) => {
    if (getControlOrMetaKey(event)) {
      return;
    }
    let isEnableDeleteButton = true;
    if (!user?.isAdmin) {
      if (teamHasEmployee.length > 0) {
        const isOrganizer = selectedTeamsId.map((id) => !!teamHasEmployee.find((team) => team.team_id === id));
        if (isOrganizer.includes(false)) {
          // @ts-ignore
          const emplyeesAllocated = shift.allocated_employees_this_shift?.map((employee) => !!teamHasEmployee.find((team) => team.team_id === employee.team_id));
          isEnableDeleteButton = !emplyeesAllocated.includes(false);
        }
      } else {
        isEnableDeleteButton = false;
      }
    }
    setModalEditShiftData({
      shiftData: shift,
      type: 'edit',
      dayName: dayName.slice(0, 3),
      openDay: calendarData.openDays[dayName?.slice(0, 3)],
      isEnableDeleteButton,
    });
  };

  const handleNulledCellClick = (shift: ShiftModel, dayName: string) => (
    event: React.MouseEvent<any, any>,
  ) => {
    if (getControlOrMetaKey(event)) {
      return;
    }
    setModalEditShiftData({
      shiftData: shift,
      type: 'create',
      dayName: dayName?.slice(0, 3),
      defaultEmployee: undefined,
      openDay: calendarData?.openDays[dayName?.slice(0, 3)],
    });
  };

  const handleAnnouncementClick = (
    shift: ShiftModel,
    dayName: string,
  ) => () => {
    setModalDeleteAnnoucement({
      announcement: shift,
      dayName: dayName.slice(0, 3),
    });
  };

  const handleFractionalShiftDrop = (shift: ShiftModel, dayName: string) => (
    defaultEmployee: any,
  ) => {
    setModalEditShiftData({
      shiftData: shift,
      type: 'create',
      dayName: dayName.slice(0, 3),
      defaultEmployee: [defaultEmployee],
      openDay: calendarData.openDays[dayName.slice(0, 3)],
    });
  };

  const canRenderColumn = useCallback(
    (columnIndex: number) => {
      const column = calendarData.name[columnIndex];
      return Boolean(
        column
          && ((column.isWeekend && column.isAllowed)
            || (!column.isWeekend && column.isAllowed)
            || !column.isWeekend),
      );
    },
    [calendarData],
  );

  const isAllowedColumn = useCallback(
    (columnIndex: number) => {
      const column = calendarData.name[columnIndex];
      const openDay = calendarData.openDays[column.dayName];
      return (
        Boolean(openDay)
        && openDay.dateStart <= column.day.toJSON()
        && (openDay.dateEnd
          ? openDay.dateEnd >= column.day.toJSON().slice(0, 10)
          : true)
      );
    },
    [calendarData],
  );

  const getTotalEmployeesTitleHeader = (totalEmployees: number) => {
    switch (totalEmployees) {
      case 0:
        return t('Calendar.without_reservation');
      default:
        return ` ${t('Calendar.reservations')}: ${totalEmployees}`;
    }
  };

  const compareDates = (firstDate: any, secondDate: any) => {
    const date1 = new Date(firstDate).getTime();
    const date2 = new Date(secondDate).getTime();

    if (date1 < date2) {
      return false;
    } if (date1 > date2) {
      return false;
    }
    return true;
  };

  const isNow = (reservationDate: any) => {
    const d = new Date();
    d.setHours(0, 0, 0, 0);
    return compareDates(d, reservationDate);
  };

  return (
    <Container id="calendar-container">
      {isCalendarLoading && (
        <>
          <CalendarOverlay />
        </>
      )}
      {placement?.map && (
        <MapButtom
          className="my-3"
          id="open-map-button"
          aria-hidden="true"
          onClick={() => {
            mixpanelTrack('map@open-view', null);
            setModalMapView(true);
          }}
        >
          <FiMap />
          <span>{t('Calendar.map')}</span>
        </MapButtom>
      )}
      <table>
        <tbody>
          <>
            <DaysName>
              {calendarData?.name.map(
                (item, calendarDataIndex) => canRenderColumn(calendarDataIndex) && (
                <Day
                  aria-disabled={!isAllowedColumn(calendarDataIndex)}
                  key={item.dayName}
                  isNow={isNow(item.day)}
                >
                  {item.dayFormatted}
                  {' '}
                  {isNow(item.day) && ('(Hoje)')}
                </Day>
                ),
              )}
            </DaysName>
            <Location>
              {calendarData?.vacancy.map((item, calendarDataIndex) => (canRenderColumn(calendarDataIndex) ? (
                <TotalEmployees
                  key={calendarData.name[calendarDataIndex].dayName}
                  aria-disabled={!isAllowedColumn(calendarDataIndex)}
                >
                  {isAllowedColumn(calendarDataIndex) ? (
                    <>
                      <div style={{ padding: '5px 10px 5px' }}>
                        <span style={{ fontSize: '12px' }}>
                          {getTotalEmployeesTitleHeader(item.filled)}
                        </span>
                      </div>
                    </>
                  ) : (
                    <div style={{ paddingRight: '0' }}>{t('Calendar.not_allowed')}</div>
                  )}
                </TotalEmployees>
              ) : (
                <></>
              )))}
            </Location>
            {!collapsedPlacements && (
              <Shifts id="show-shifts">
                {calendarData.shifts.map(
                  (shift, calendarDataIndex) => canRenderColumn(calendarDataIndex) && (
                  <DayShifts key={shift.day}>
                    {shift.data.map((shiftData: any, shiftIndex: number) => {
                      if (!isAllowedColumn(calendarDataIndex)) {
                        return <></>;
                      }
                      if (shiftData.type === ShiftsEnum.types.allocated) {
                        return (
                          <div id={shiftData.code}>
                            <SubtitleContainer>
                              {'Posição: '}
                              {placement?.seatsName ? placement?.seatsName[shiftIndex]?.name : shiftIndex}
                            </SubtitleContainer>
                            <Shift
                              key={shiftData.id}
                              color={
                                    placement?.color
                                      ? `#${placement?.color}`
                                      : 'transparent'
                                  }
                              shiftData={shiftData}
                              day={shift.day}
                              dayName={shift.dayName}
                              onDoubleClick={handleShiftClick(
                                shiftData,
                                shift.dayName,
                              )}
                              isAllowFractionalShift={placement?.isAllowFractionalShift === 1}
                            />
                          </div>
                        );
                      }
                      if (shiftData.type === ShiftsEnum.types.empty) {
                        return (
                          <div id={shiftData.code}>
                            <SubtitleContainer>
                              {'Posição: '}
                              {placement?.seatsName ? placement?.seatsName[shiftIndex]?.name : shiftIndex}
                            </SubtitleContainer>
                            <NulledCell
                              key={shiftData.id ?? shiftData.code}
                              openDay={
                                    calendarData.openDays[
                                      shift.dayName.slice(0, 3)
                                    ]
                                  }
                              day={shift.day}
                              dayName={shift.dayName}
                              placement={placement!}
                              section="alocation"
                              isAutoAnnouncement={Boolean(
                                placement?.isAutomaticAnnouncements,
                              )}
                              onDoubleClick={handleNulledCellClick(
                                shiftData,
                                shift.dayName,
                              )}
                              handleFractionalShiftDrop={handleFractionalShiftDrop(
                                shiftData,
                                shift.dayName,
                              )}
                              data={{
                                seatId: shiftData?.seatId,
                                id: shiftData?.id,
                              }}
                            />
                          </div>
                        );
                      }
                      if (
                        shiftData.type === ShiftsEnum.types.announcement
                      ) {
                        return (
                          <div id={shiftData.code}>
                            <Announcement
                              key={Math.random()}
                              shiftData={shiftData}
                              onDoubleClick={handleAnnouncementClick(
                                shiftData,
                                shift.dayName,
                              )}
                            />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </DayShifts>
                  ),
                )}
              </Shifts>
            )}
          </>
        </tbody>
      </table>
      {modalEditShiftData.shiftData && (
        <ModalShiftEdit
          modalData={modalEditShiftData as any}
          onClose={setModalEditShiftData}
          placement={placement}
          calendarData={calendarData}
          type={modalEditShiftData?.type}
        />
      )}
      <ModalDeleteAnnouncement
        modalData={modalDeleteAnnoucement as any}
        onClose={setModalDeleteAnnoucement}
        onSuccess={() => setModalDeleteAnnoucement({})}
      />
      {modalMapView && (
        <ModalMapView
          title={`${placement?.name!}`}
          isOpen={modalMapView}
          handleCloseButton={() => setModalMapView(false)}
        />
      )}
    </Container>
  );
};

export default (props: CalendarProps) => (
  <CalendarContextProvider>
    <Calendar {...props} />
  </CalendarContextProvider>
);
