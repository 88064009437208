/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const ProfessionalWithoutProfileImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: brown;
  color: white;
  font-size: 0.8rem;
  margin-right: 1rem;
  text-transform: uppercase;
`;
