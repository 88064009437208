import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContainer, ButtonInvite } from './style';
import icons from '../../assets/icons';

interface props {
  employeeName?: string
  employeePhoto?: string
}

const UserPanel:FC<props> = () => {
  const history = useHistory();
  const { t } = useTranslation('components');

  return (
    <div className="d-flex justify-content-between">
      <UserContainer>
        <ButtonInvite type="button" onClick={() => history.push('/management?newUser=new_user')}>
          {t('User_panel')}
          <img src={icons.email} alt="email" />
        </ButtonInvite>
      </UserContainer>
    </div>
  );
};
export default UserPanel;
