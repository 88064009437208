import styled from 'styled-components';

const Container = styled.div`
  z-index: 1051;
  position: fixed;
  right: 0;
  top: 15px;
  padding: 30px;
  overflow: hidden;
`;

export default Container;
