import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
  size?: string;
};

const Loader: React.FC<Props> = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      width: '100%',
      flex: 1,
      height: '100vh',
    }}
  >
    <Spinner color="primary" animation="border" />
  </div>
);

export default Loader;
