import styled from 'styled-components';
import colors from '../../../../colors';

export const CheckBoxText = styled.span`
  font-weight: bold;
  font-size: 1.1rem;
  font-family: lato;
`;

export const FloatLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`;

export const Title = styled.h1`
  font-size: 18px;
  color: ${colors.blue};
  font-weight: 600;
  font-family: lato;
  margin-top: 30px;
  @media only screen and (max-width: 768px) {
    margin-top: 95px !important;
  }
`;

export const HeaderContainer = styled.div`
  width: 100%;
  padding: 0 2rem;
  min-height: 80px;
  margin: 0 auto;
  background-color: ${colors.white10};
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Container = styled.div`
  margin-left: 10px;
  padding: 2rem 1rem;
  flex: 1;

  button {
    text-transform: initial;
  }
`;

export const Content = styled.div`
  display: flex;
  padding: 0 2rem;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const CheckboxSubtitle = styled.span`
  font: normal normal medium 15px/25px lato;
  color: ${colors.gray90};
  white-space: pre-line;
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SendImage = styled.button`
  padding: 12px 14px;
  text-transform: uppercase;
  font-size: 14px;
  color: rgb(255, 255, 255);
  outline: 0px;
  height: auto;
  text-align: center;
  border-radius: 5px;
  background-color: rgb(0, 11, 76);
  border: none;
  transition: all 0.5s ease 0s;
  min-width: 130px;
  margin-top: 15px;
`;
