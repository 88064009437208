/* eslint-disable camelcase */
import Employee from 'src/contracts/models/Employee';

export const employees2array = (data: any): Employee[] => {
  if (!data) return [];
  if (Array.isArray(data)) return data;
  return Object.values(data);
};

export const teams2array = (data: any): number[] => {
  if (Array.isArray(data)) return data;
  return Object.values(data);
};

interface EmployeeResponde extends Employee {
  employee_roles?: Array<any>
}

export const formatEmployee = (data: any) => {
  const employees = employees2array(data);
  const formattedEmployees = employees.map((employee: EmployeeResponde) => {
    const teamsIds: number[] = teams2array(employee.teams);
    const newEmployee: any = {
      id: employee.id,
      name: employee.name,
      email: employee.email,
      photo: employee.photo,
      teams: teamsIds,
      role: employee.role,
    };
    return newEmployee;
  });
  return formattedEmployees;
};
