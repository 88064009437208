import styled from 'styled-components';
import colors from '../../colors';

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1px 8px;
  gap: 4px;

  width: 67px;
  height: 23px;

  background: #FFFFFF;

  border: 1px solid ${colors.green30};
  border-radius: 4px;



  flex: none;
  order: 0;
  flex-grow: 0;
`;

export const Text = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #00B38E;
  flex: none;
  order: 0;
  flex-grow: 0;
`;
