import React, { useEffect, useState } from 'react';
import { DayKeys } from 'src/contracts/models/Day';
import Location from 'src/contracts/models/Location';
import Employee from 'src/contracts/models/Employee';
import {
  endOfWeek, format, startOfWeek,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import Placement from '../../contracts/models/Placement';
import Option from '../../contracts/models/Option';
import { getData } from '../../services/LocalStorageService';
import { useToast } from '../../hooks/toast';
import PlacementService from '../../services/PlacementService';
import JobService from '../../services/JobService';
import ShiftService from '../../services/ShiftService';
import Team from '../../contracts/models/Team';

export type SelectedUser = {
  id: number;
  teamId: number;
  name: string;
  teamName: string
};
export const homeContext = React.createContext<{
  locations: Location[];
  setLocations?: React.Dispatch<React.SetStateAction<Location[]>>;
  placement?: Placement;
  setPlacement?: React.Dispatch<React.SetStateAction<Placement | undefined>>;
  availableTeams?: Team[];
  setAvailableTeams?: React.Dispatch<React.SetStateAction<Team[]>>;
  showLabels?: boolean;
  setShowLabels?: React.Dispatch<React.SetStateAction<boolean>>;
  teamsMaxVacancy?: any;
  selectedTeams: Option[];
  setSelectedTeams?: React.Dispatch<React.SetStateAction<Option[]>>;
  selectedLocations: Option | null;
  shiftLoading?: boolean;
  setShiftLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  submitShiftAllocation: Function;
  deleteShiftAllocation: Function;
  setSelectedLocations?: React.Dispatch<React.SetStateAction<Option | null>>;
  getParamsCalendar?: {} | any;
  setTeamsMaxVacancy?: React.Dispatch<React.SetStateAction<any>>;
  inHomeOffice: {
    dayName: DayKeys;
    employees: Employee[];
  }[];
  setInHomeOffice?: React.Dispatch<
    React.SetStateAction<
      {
        dayName: DayKeys;
        employees: Employee[];
      }[]
    >
  >;
  week: Date;
  setWeek?: React.Dispatch<React.SetStateAction<Date>>;
  loadCalendar?:(shouldLoadCalendar?:boolean) => Promise<void>;
  waitForPublishConclusion?:(jobId: number) => Promise<void>;
  waitForAnnouncementBatchConclusion?:(jobId: number) => Promise<void>;
  isCalendarLoading: boolean,
  isPublishLoading: boolean,
  isAnnouncementLoading: boolean,
  selectedUserId: SelectedUser | null,
  sideBarTeams: any,
  setSideBarTeams?: React.Dispatch<React.SetStateAction<any>>
  isOpenEmployeeEdit?: boolean,
  setIsOpenEmployeeEdit?: React.Dispatch<React.SetStateAction<boolean>>,
  setSelectedUserId?: React.Dispatch<React.SetStateAction<SelectedUser | null>>;
  updateLocalEmployeesOnTeamsSideBar: Function,
    }>({
      locations: [],
      placement: {},
      shiftLoading: false,
      availableTeams: [],
      teamsMaxVacancy: {},
      submitShiftAllocation: Function,
      deleteShiftAllocation: Function,
      showLabels: false,
      inHomeOffice: [],
      selectedTeams: [],
      selectedLocations: { label: '', value: '' },
      week: startOfWeek(new Date(), { weekStartsOn: 1 }),
      isCalendarLoading: false,
      isPublishLoading: false,
      isAnnouncementLoading: false,
      selectedUserId: null,
      sideBarTeams: [],
      updateLocalEmployeesOnTeamsSideBar: Function,
    });

const HomeContextProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const { t } = useTranslation('pages');
  const generic = useTranslation('components');
  const [shiftLoading, setShiftLoading] = useState(false);
  const [locations, setLocations] = useState<Location[]>([]);
  const [availableTeams, setAvailableTeams] = useState<Team []>([]);
  const [teamsMaxVacancy, setTeamsMaxVacancy] = useState<any>({});
  const [placement, setPlacement] = useState<Placement>();
  const [showLabels, setShowLabels] = useState<boolean>(false);
  const [selectedTeams, setSelectedTeams] = useState<Option[]>(getData('selected_teams') || []);
  const [selectedLocations, setSelectedLocations] = useState<Option | null>(
    getData('selected_teams') ? getData('selected_locations') : { label: '', value: '' },
  );
  const [inHomeOffice, setInHomeOffice] = useState<
    {
      dayName: DayKeys;
      employees: Employee[];
    }[]
  >([]);
  const [week, setWeek] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 }),
  );
  const [selectedUserId, setSelectedUserId] = useState<SelectedUser | null>(null);
  const [isCalendarLoading, setIsCalendarLoading] = useState(false);
  const [isPublishLoading, setIsPublishLoading] = useState(false);
  const [isAnnouncementLoading, setIsAnnouncementLoading] = useState(false);
  const [sideBarTeams, setSideBarTeams] = useState<any>([]);
  const [isOpenEmployeeEdit, setIsOpenEmployeeEdit] = useState(false);

  async function loadCalendar(shouldLoadCalendar = true) {
    if (selectedLocations?.id) {
      const ids = selectedLocations?.id.split('_');
      const teamIds = selectedTeams.map((item) => item.id).toString();

      const data = {
        weekStart: format(week, 'yyyy-MM-dd'),
        weekEnd: format(endOfWeek(week, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
        placementId: ids[0],
        locationId: ids[1],
        teamsId: teamIds,
      };
      if (setPlacement) {
        if (shouldLoadCalendar) {
          setIsCalendarLoading(true);
        }
        PlacementService.getCalendarInformationFromPlacement(data).then((response: any) => {
          if (!response.failed && response?.data?.id) {
            setPlacement(response.data);
          } else {
            setSelectedLocations(null);
          }
        }).finally(() => {
          if (shouldLoadCalendar) {
            setIsCalendarLoading(false);
          }
        });
      }
    }
  }

  function updateLocalEmployeesOnTeamsSideBar(receivedEmployee: Employee) {
    const newData = sideBarTeams.map((item: any) => {
      const newEmployees = item.employees.map((employee: any) => {
        if (employee.id === receivedEmployee.id) {
          return { ...employee, ...receivedEmployee };
        }
        return employee;
      });
      return ({ ...item, employees: newEmployees });
    });
    setSideBarTeams(newData);
  }

  function waitForCompletion(
    waitForSeconds: number,
    onComplete: Function,
    onError: Function,
    jobIb: number,
  ) {
    setTimeout(async () => {
      try {
        const response = await JobService.getJobStatus(jobIb);
        if (String(response.data.status).toUpperCase() !== JobService.STATUS.SUCCESS) {
          const newTimer = waitForSeconds < 32 ? waitForSeconds * 2 : waitForSeconds;
          waitForCompletion(newTimer, onComplete, onError, jobIb);
        } else {
          onComplete();
        }
      } catch (error) {
        onError(error);
      }
    }, waitForSeconds * 1000);
  }

  async function waitForPublishConclusion(jobId: number) {
    setIsCalendarLoading(true);
    setIsPublishLoading(true);
    waitForCompletion(2, () => {
      setIsCalendarLoading(false);
      setIsPublishLoading(false);
      addToast({
        description: generic.t('ModalSchedulePublish.success'),
        type: 'success',
      });
      loadCalendar();
    },
    (error: any) => {
      setIsCalendarLoading(false);
      setIsPublishLoading(false);
      if (error?.response?.data?.message) {
        addToast({
          type: 'error',
          description: error?.response?.data?.message,
        });
      }
    },
    jobId);
  }

  async function waitForAnnouncementBatchConclusion(jobId: number) {
    setIsCalendarLoading(true);
    setIsAnnouncementLoading(true);
    waitForCompletion(2, () => {
      setIsCalendarLoading(false);
      setIsAnnouncementLoading(false);
      addToast({
        description: generic.t('ModalPlacesAnnoucementLot.success_create_message'),
        type: 'success',
      });
      loadCalendar();
    },
    (error: any) => {
      setIsCalendarLoading(false);
      setIsAnnouncementLoading(false);
      if (error?.response?.data?.message) {
        addToast({
          type: 'error',
          description: error?.response?.data?.message,
        });
      }
    },
    jobId);
  }

  async function submitShiftAllocation(payload: any) {
    ShiftService.saveShift(payload).then(() => {
      addToast({
        description: t('Home.shift_allocation.success'),
        type: 'success',
      });
      loadCalendar();
    }).catch((error) => {
      if (error?.response?.data?.message) {
        addToast({
          description: error.response.data?.message,
          type: 'error',
        });
      } else {
        addToast({
          description: t('Home.shift_allocation.error'),
          type: 'error',
        });
      }
      setShiftLoading(false);
    });
  };

  async function deleteShiftAllocation(payload: any) {
    ShiftService.deleteShift(payload).then(() => {
      addToast({
        description: t('Home.shift_deallocation.success'),
        type: 'success',
      });
      loadCalendar();
    }).catch(() => {
      addToast({
        description: t('Home.shift_deallocation.error'),
        type: 'error',
      });
      setShiftLoading(false);
    });
  };

  useEffect(() => {
    loadCalendar();
  }, [week, selectedLocations]);

  useEffect(() => {
    if (!placement?.seatsName?.[0].name) setShowLabels(false);
  }, [placement]);

  function getParamsCalendar() {
    if (selectedLocations?.id) {
      const ids = selectedLocations?.id.split('_');
      const teamIds = selectedTeams.map((item) => item.id).toString();

      return {
        weekStart: format(week, 'yyyy-MM-dd'),
        weekEnd: format(endOfWeek(week, { weekStartsOn: 1 }), 'yyyy-MM-dd'),
        placementId: ids[0],
        locationId: ids[1],
        teamsId: teamIds,
      };
    }

    return {};
  }

  return (
    <homeContext.Provider
      value={{
        locations,
        setLocations,
        selectedTeams,
        setSelectedTeams,
        selectedLocations,
        availableTeams,
        setAvailableTeams,
        setSelectedLocations,
        showLabels,
        setShowLabels,
        submitShiftAllocation,
        deleteShiftAllocation,
        shiftLoading,
        placement,
        setPlacement,
        teamsMaxVacancy,
        setTeamsMaxVacancy,
        inHomeOffice,
        setInHomeOffice,
        week,
        setWeek,
        loadCalendar,
        waitForPublishConclusion,
        waitForAnnouncementBatchConclusion,
        isCalendarLoading,
        isPublishLoading,
        isAnnouncementLoading,
        selectedUserId,
        setSelectedUserId,
        sideBarTeams,
        setSideBarTeams,
        updateLocalEmployeesOnTeamsSideBar,
        isOpenEmployeeEdit,
        setIsOpenEmployeeEdit,
        getParamsCalendar,
      }}
    >
      {children}
    </homeContext.Provider>
  );
};

export default HomeContextProvider;
