/* eslint-disable camelcase */
import Api from './api';

export default class LocationService {
  static async getAll(page = 1, perPage = 10, search = '') {
    const params = {
      page,
      perPage,
      search,
    };
    const response = Api.get('locations/all', { params });
    return response;
  }

  static async create(payload : {
        name: string,
        address: string,
        complement: string,
        latitude: string,
        longitude: string,
        radius: string,
        google_place_id: string
  }) {
    const response = Api.post('locations', payload);
    return response;
  }

  static async editGeneralInformation(payload : {
        id: number,
        name?: string,
        address?: string,
        complement?: string,
        latitude?: string,
        longitude?: string,
        google_place_id?: string
    }) {
    const response = Api.post('locations/general/information', payload);
    return response;
  }

  static async getLocation(id :string) {
    const response = Api.get(`locations/general/information/${id}`);
    return response;
  }

  static getAllLocations(perPage: number, page: number) {
    return Api.get('/locations/all/names', { params: { perPage, page } });
  }

  static deleteLocation(id :string) {
    return Api.delete(`locations/${id}`);
  }

  static async getLocationFromEmployee(perPage = 10, page = 1, search = '') {
    const response = await Api.request({
      method: 'GET',
      url: '/locations/from/employee',
      params: { perPage, page, search },
    });
    return response;
  }
}
