import styled from 'styled-components';
import colors from '../../colors';

interface SideBarProps {
  isOpen?: boolean;
}

interface CalendarProps {
  selected?: boolean;
}

export const Container = styled.main<SideBarProps>`
  height: calc(100vh - 64px);
`;
export const TeamSideBarContainer = styled.div`
  position: fixed;
  grid-area: pb;
  height: calc(100vh);
  z-index: 98;
  @media only screen and (max-width: 768px) {
    padding-top: 64px;
  }
`;

export const MapButtom = styled.button`
  color: ${colors.blue};
  font-family: lato;
  font-size: 15px;
  border: none;
  background-color: ${colors.green30};
  font-weight: bold;
  width: 137px;
  height: 32px;
  border-radius: 4px;
  svg {
    margin-right: 16px;
  }
`;

export const CalendarContainer = styled.div`
  margin-left: 15px;
  padding-top: 30px;
  @media only screen and (max-width: 768px) {
    padding-top: 120px;
  }
`;

export const TopCalendarBar = styled.div`
  padding: 40px 32px 0px 37px;
  display: flex;
  flex-direction: column;

  > div:first-child {
    text-align: right;
    margin-bottom: 28px;

    button {
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      }
    }

    button {
      border: none;
      padding: 0;
      background-color: transparent;

      :not(:first-child) {
        margin-left: 18px;
      }
    }
  }

  > div:last-child {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;

    > div:first-child {
      margin-top: -40px;
      display: flex;
      align-items: flex-end;

      > div:nth-child(1),
      > div:nth-child(2),
      > div:nth-child(3) {
        width: 300px;
        margin: 0 20px 0 0;
      }

      > div:nth-child(3) {
        margin-left: 20px;
      }

      @media (max-width: 1366px) {
        > div:nth-child(1),
        > div:nth-child(2),
        > div:nth-child(3) {
          width: 235px;
        }
      }
      @media (max-width: 1167px) {
        > div:nth-child(1),
        > div:nth-child(2) {
          width: 170px;
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 268px;
      margin: 0 0 0 13px;

      button {
        &:first-child {
          display: none;
        }
      }

      @media (max-width: 1810px) {
        button {
          span {
            display: none;
          }
        }
      }
      @media (max-width: 1500px) {
        justify-content: flex-start;
        button {
          padding: .5rem .85rem;

          img {
            margin: 0;
          }
        }
      }
    }

    > div:last-child {
      button:not(:first-child) {
        margin-left: 7px;
      }

      width: 38%;
      display: flex;
      justify-content: flex-start;
      @media (max-width: 1366px) {
        width: 20%;
      }
    }
  }
`;

export const LabelButton = styled.button<CalendarProps>`
  font: normal normal medium 5px/5px lato;
  font-size: 13px !important;
  color: #727789;
  img {
    margin-left: 15px;
    width: 30px;
    height: 30px; 
  }
  filter: ${(props) => props.selected && 'invert(35%) sepia(72%) saturate(3499%) hue-rotate(192deg) brightness(89%) contrast(87%)'};
`;
export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70%;
  border-top: 1px solid ${colors.gray30};
`;

export const EmptyMessage = styled.span`
  text-align: center;
  font: normal normal 600 20px lato;
  margin-bottom: 50px;
  color: ${colors.gray};
  @media (max-width: 1366px) {
    margin-bottom: 30px;
    font-size: 16px;
  }

  #middle {
    color: ${colors.blue};
  }
`;

export const EmptyIcon = styled.img`
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
`;

export const Tabs = styled.div`
`;
