/* eslint-disable import/no-cycle */
import { getData, deleteData } from 'src/services/LocalStorageService';
import { mixpanelReset } from 'src/services/MixPanelService';
import Roles from 'src/contracts/constants/employee.roles.constants';

export const getToken = () => {
  const token = getData('espacos@acessToken');
  if (token !== null) {
    return token;
  }
  return null;
};

export const getUser = () => {
  const user = getData('espacos@user');
  if (user !== null) {
    return {
      employee: user.user,
      isAdmin: Boolean(user.user.roles.find((role: any) => role.id === Roles.ADMIN)),
      onlyCollaborator: user.only_collaborator,
      isSupport: user.is_support,
    };
  }
  return null;
};

export const getGuide = () => {
  const guide = getData('espacos@guide');
  if (guide !== null && guide !== undefined) {
    return {
      nextStep: guide.next_step,
      steps: guide,
    };
  }
  return null;
};

export const removeJWTToken = () => {
  deleteData('espacos@acessToken');
  deleteData('espacos@user');
  deleteData('espacos@guide');
  deleteData('espacos@tutorial_done');
};

export const removeLocalStorageInfoAndResetMixPanelAndZendesk = () => {
  removeJWTToken();
  deleteData('selected_locations');
  deleteData('selected_teams');
  deleteData('espacos@actualStep');
  mixpanelReset();

  const movideskScript = document.getElementById('movidesk-script');
  const movideskSnippet = document.getElementById('movidesk-snippet');
  const movideskLogin = document.getElementById('movidesk-login');

  if (movideskScript && movideskSnippet && movideskLogin) {
    movideskScript.remove();
    movideskSnippet.remove();
    movideskLogin.remove();
  }
};

export const logout = (unauthentcated = false) => {
  removeLocalStorageInfoAndResetMixPanelAndZendesk();

  window.location.replace(unauthentcated ? '/login?unauthentcated=1' : '/login?unauthentcated=0');
};

export const getCustomLogoTypeIsEnable = () => {
  try {
    const token = getToken();
    const jwtObject = JSON.parse(atob(token.split('.')[1]));

    return jwtObject.metadata_session.feature.custom_logotype;
  } catch (e) {
    return false;
  }
};

export const isLogged = () => getData('espacos@acessToken') !== null;
export const awaitingPayment = () => getData('PAYMENT_REQUIRED') !== null;
