import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToast } from 'src/hooks/toast';
import LogoSvg from 'src/assets/imgs/Logo_Espaços_Login.svg';
import Input from 'src/components/InputWithRef';
import AuthService from 'src/services/AuthService';
import { useTranslation } from 'react-i18next';
import PassStrength from 'src/components/PassStrength';
import { useLocation, Link } from 'react-router-dom';
import ButtonLoader from 'src/components/ButtonLoader';
import ShowPassword from 'src/components/ShowPassword';
import {
  Container,
  RowContainer,
  LoginContainer,
  LoginContent,
  LogoContainer,
  Logo,
  SuccessText,
  ErrorText,
  InfoText,
  ButtonContainer,
  GoBackLoginContainer,
  GoBackLogin,
} from './style';

import {
  BackgroundContainer,
} from '../Login/style';
import { mixpanelTrack } from '../../services/MixPanelService';
import icons from '../../assets/icons';

interface IResetPasswordForm {
  password: string
  passwordConfirmation: string
}

const ResetPassword: React.FC = () => {
  const { addToast } = useToast();
  const { t } = useTranslation('pages');
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const {
    register, errors, handleSubmit, reset,
  } = useForm<FormData>();
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState('');
  const [text, setText] = useState(null);
  const [isError, setIsError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const showPasswordIcon = showPassword ? icons.eyeClosed : icons.eyeOpen;
  const passwordType = showPassword ? 'text' : 'password';

  const onSubmit = (credentials: IResetPasswordForm) => {
    if (!query.get('email') || !query.get('token')) {
      addToast({
        description: t('ResetPassword.error_ivalid_token'),
        type: 'error',
      });
      return;
    }
    setIsLoading(true);
    const resetInfo = {
      ...credentials,
      email: query.get('email')!,
      token: query.get('token')!,
    };
    AuthService.resetPassword(resetInfo).then((response) => {
      if (response.data) {
        setText(t('ResetPassword.success_update'));
        setIsLoading(false);
        reset({});
      }
    }).catch(() => {
      setIsLoading(false);
      setIsError(true);
    });
  };

  return (
    <Container>
      <RowContainer>
        <BackgroundContainer />
        <LoginContainer>
          <LoginContent>
            <LogoContainer>
              <Logo src={LogoSvg} />
            </LogoContainer>
            <SuccessText>{text}</SuccessText>
            {text && (
              <GoBackLoginContainer>
                <Link to="/login">
                  <GoBackLogin>{t('ResetPassword.go_back_login')}</GoBackLogin>
                </Link>
              </GoBackLoginContainer>
            )}
            {text === null && <InfoText>{t('ResetPassword.info_text')}</InfoText>}
            {text === null && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Input
                error={errors}
                errorMsg={t('ResetPassword.password_error')}
                name="password"
                onChange={(event) => setPassword(event.target.value)}
                label={t('ResetPassword.password_field')}
                type={passwordType}
                register={register({ required: true, minLength: 6 })}
              />
              <PassStrength password={password} />
              <Input
                error={errors}
                errorMsg={t('ResetPassword.no_match_password_error')}
                name="passwordConfirmation"
                label={t('ResetPassword.password_confirm_field')}
                type={passwordType}
                register={register({
                  required: true,
                  validate: (value) => value === password,
                })}
              />
              <ShowPassword
                src={showPasswordIcon}
                onClick={() => {
                  setShowPassword(!showPassword);
                  mixpanelTrack('login@see-password', null);
                }}
                error={errors}
              />
              {isError && <ErrorText>{t('ResetPassword.token_expirated_text')}</ErrorText>}
              <ButtonContainer>
                <ButtonLoader
                  label={t('ResetPassword.update_password')}
                  isLoading={isLoading}
                  width="100%"
                />
              </ButtonContainer>
            </form>
            )}
          </LoginContent>
        </LoginContainer>
      </RowContainer>
    </Container>
  );
};

export default ResetPassword;
