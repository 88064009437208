import React, {
  useEffect, useMemo, useContext, useState,
} from 'react';
import { Stage, Layer } from 'react-konva';
import URLImage from 'src/components/CanvasComponents/URLImage';
import SeatEditableCellCanvas from 'src/components/CanvasComponents/SeatEditableCellCanvas';
import Konva from 'konva';
import SeatModel from 'src/contracts/models/Seats';
import { State } from '@hookstate/core';
import { AddLocationContext } from 'src/pages/AddLocation/Context';
import { ModalMapSelectorContext } from './Context';

interface Marker {
  id: string | number,
  x: number,
  y: number,
  name: string;
  positioned: boolean,
}
interface CanvasProps {
  src: string;
  scale: number;
  seats: State<SeatModel>[];
  onDragMarker: (isActive: boolean) => void;
}

const Canvas: React.FC<CanvasProps> = ({
  src, scale, seats, onDragMarker,
}) => {
  const {
    selectedSeatId,
    setSelectedSeatId,
  } = useContext(ModalMapSelectorContext);
  const [imageSize, setImageSize] = useState<{ width: number; height: number }>({
    width: 0, height: 0,
  });

  const { radius } = useContext(AddLocationContext);

  const formatedMarkers = useMemo(() => seats.map((seatState) => {
    const seat = seatState.get();
    const marker: Marker = {
      id: seat.id,
      name: seat.name,
      x: seat.position?.positionX!,
      y: seat.position?.positionY!,
      positioned: !!seat.position?.positionX,
    };
    return marker;
  }), [seats]);

  const onImageClick = (event: Konva.KonvaEventObject<MouseEvent>) => {
    if (selectedSeatId) {
      const stage = event.target.getStage();
      const pointerPosition = stage?.getPointerPosition();
      const offset = { x: event.target.attrs.x, y: event.target.attrs.y };

      const imageClickX = (pointerPosition?.x! - offset.x);
      const imageClickY = (pointerPosition?.y! - offset.y);
      seats.forEach((seatState) => {
        if (seatState.get().id === selectedSeatId) {
          seatState.set((prev: any) => ({
            ...prev,
            position: {
              positionX: imageClickX,
              positionY: imageClickY,
            },
          }));
        }
      });
      setSelectedSeatId?.(null);
    }
  };

  const onDragSeatEnd = (event: Konva.KonvaEventObject<DragEvent>) => {
    const stage = event.target.getStage();
    const pointerPosition = stage?.getPointerPosition();

    seats.forEach((seatState) => {
      if (seatState.get().id === selectedSeatId) {
        seatState.set((prev: any) => ({
          ...prev,
          position: {
            positionX: pointerPosition?.x!,
            positionY: pointerPosition?.y!,
          },
        }));
      }
    });
    setSelectedSeatId?.(null);
  };

  const deleteSeatPosition = () => {
    seats.forEach((seatState) => {
      if (seatState.get().id === selectedSeatId) {
        seatState.set((prev: any) => ({
          ...prev,
          position: {
            positionX: null,
            positionY: null,
          },
        }));
      }
    });
    setSelectedSeatId?.(null);
  };

  const handleDeleteKeyDown = (event: KeyboardEvent) => {
    const cleanDomEvents = () => {
      document.onkeydown = null;
    };

    if (selectedSeatId === null) {
      cleanDomEvents();
      return;
    }

    if (!['Delete', 'Backspace'].includes(event.key)) return;
    cleanDomEvents();
    switch (event.key) {
      case 'Delete':
      case 'Backspace': {
        deleteSeatPosition();
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    document.onkeydown = handleDeleteKeyDown as any;

    return () => {
      document.onkeydown = null;
    };
  }, [selectedSeatId]);

  return (
    <Stage width={imageSize.width} height={imageSize.height}>
      <Layer>
        <URLImage
          src={src}
          onLoadImage={(newImageSize) => {
            setImageSize(newImageSize);
          }}
          onClick={onImageClick}
        />

        {formatedMarkers.map((seat, index) => {
          if (seat.positioned) {
            return (
              <SeatEditableCellCanvas
                index={index}
                seat={seat}
                scale={scale + (window.innerHeight / imageSize.height)}
                radius={radius}
                onClick={() => {
                  if (selectedSeatId === seat.id) {
                    setSelectedSeatId?.(null);
                    return;
                  }
                  setSelectedSeatId?.(seat.id);
                }}
                selected={selectedSeatId === seat.id}
                onDragMarker={onDragMarker}
                onDragMarkerEnd={onDragSeatEnd}
              />
            );
          }
          return null;
        })}
      </Layer>
    </Stage>
  );
};

export default Canvas;
