import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AuthService from 'src/services/AuthService';
import { getUser, removeLocalStorageInfoAndResetMixPanelAndZendesk } from 'src/services/auth';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { mixpanelTrack } from 'src/services/MixPanelService';
import icons from '../../assets/icons';
import {
  NavBar,
  NavItems,
  ProfileNav,
  ProfileImage,
  ProfileNonImage,
  TestDaysRemaining,
  CompanyLogo,
  Dropdown,
  OpenOnboarding, ButtonLogout,
} from './style';
import ModalEditUser from '../ModalEditUser';
import { useGlobal } from '../../hooks/useGlobal';
import ModalConfirm from '../ConfirmModal';
import { homeContext } from '../../pages/Home/Context';
import abbreviate from '../../utils/abbreviate';
import SideMenu from '../SideMenu';
import GuidedOnboarding from '../GuidedOnboarding';

interface MainNavBarProps {}
const MainNavBar: React.FC<MainNavBarProps> = () => {
  const { setIsOpenEmployeeEdit } = useContext(homeContext);
  const { isFreemium } = useGlobal();
  const { t } = useTranslation('components');
  const [employeeName, setEmployeeName] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [employeePhoto, setEmployeePhoto] = useState('');
  const [companyLogo, setCompanyLogo] = useState('');
  const [runOnboarding, setRunOnboarding] = useState(false);
  const [isModalEditUserOpen, setIsModalEditUserOpen] = useState(
    false,
  );

  const { user } = useGlobal();
  const getLoggedUser = () => {
    const localStorageUser = localStorage.getItem('espacos@user');
    if (localStorageUser) {
      const employeeData = JSON.parse(localStorageUser);
      setEmployeeName(employeeData.user.name);
      setEmployeePhoto(employeeData.user.photo);
    }
  };

  useEffect(() => {
    getLoggedUser();
  }, []);

  const navbarLogout = async () => {
    if (user) {
      await AuthService.logout().then((response) => {
        removeLocalStorageInfoAndResetMixPanelAndZendesk();

        if (response.data.url_logout !== null) {
          setTimeout(() => {
            window.location.href = response.data.url_logout;
          }, 10);

          return false;
        }

        return true;
      });

      user?.logout?.();
    }
  };

  const getProfileInitials = () => {
    const splited = employeeName.split(' ');
    const firstLetter = splited[0].substr(0, 1);
    const secondLetter = splited[1] ? splited[1].substr(0, 1) : '';
    return firstLetter + secondLetter;
  };

  const handleModalEditUserClick = () => {
    setIsModalEditUserOpen(true);
  };

  useEffect(() => {
    if (setIsOpenEmployeeEdit) {
      setIsOpenEmployeeEdit(isModalEditUserOpen);
    }
  }, [isModalEditUserOpen]);

  useEffect(() => {
    const userInfo = getUser();
    if (userInfo) {
      setCompanyLogo(userInfo.employee.company.logotype);
      const tutorialDone = !(userInfo.employee?.tutorial_done === 1 || localStorage.getItem('espacos@tutorial_done'));
      setRunOnboarding(tutorialDone);
    }
  }, []);

  return (
    <>
      <GuidedOnboarding run={runOnboarding} setRun={setRunOnboarding} />
      <SideMenu />
      <NavBar className="navbar-inverse navbar-fixed-top">
        {isOpen && (
        <ModalConfirm
          isOpen={isOpen}
          handleConfirm={navbarLogout}
          handleCloseButton={() => setIsOpen(false)}
          message={t('MainNavBar.logout_confirm')}
        />
        )}
        <div className="container-fluid">
          <div className="d-flex w-auto justify-content-end">
            <div className="d-flex">
              {isFreemium && (
                <TestDaysRemaining>
                  <span>{t('MainNavBar.freemium')}</span>
                </TestDaysRemaining>
              )}
              {companyLogo && (
                <CompanyLogo>
                  <img src={companyLogo} alt="" />
                </CompanyLogo>
              )}
              <NavItems>
                <Dropdown
                  className="dropdown show"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={() => {
                    mixpanelTrack('manual@open,', null);
                  }}
                >
                  <span role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <img id="manual-user-header" src={icons.question} alt={t('MainNavBar.manual')} />
                  </span>

                  <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuLink">
                    <a
                      href="https://escalaapp.notion.site/Manual-Escala-Espa-os-4e40fb37f6974ec8830f4765f45ca83d"
                      target="_blank"
                      rel="noopener noreferrer"
                      id="manualTooltip"
                      className="dropdown-item"
                    >
                      Manual do usúario
                    </a>
                    <OpenOnboarding className={`${user?.isAdmin ? 'dropdown-item w-auto ' : 'd-none'}`} onClick={() => setRunOnboarding(true)}>
                      Tour guiado
                    </OpenOnboarding>
                  </div>
                </Dropdown>
                <UncontrolledPopover
                  flip
                  target="manualTooltip"
                  trigger="hover"
                  placement="bottom"
                >
                  <PopoverBody>
                    {t('MainNavBar.manual')}
                  </PopoverBody>
                </UncontrolledPopover>
                <ProfileNav onClick={handleModalEditUserClick}>
                  <div className="avatar-wrapper me-2">
                    {employeePhoto ? (
                      <ProfileImage
                        src={employeePhoto}
                        alt={employeeName}
                      />
                    ) : (
                      <ProfileNonImage>
                        {getProfileInitials()}
                      </ProfileNonImage>
                    )}
                  </div>
                  <div className="profile-header-name">
                    {abbreviate(employeeName, 15, { showLastWorld: true })}
                  </div>
                </ProfileNav>
                <ButtonLogout onClick={() => setIsOpen(true)} type="button" className="exit-button d-none">
                  {t('MainNavBar.logout')}
                </ButtonLogout>
              </NavItems>
            </div>
          </div>
        </div>
      </NavBar>
      <ModalEditUser
        onCloseModal={() => {
          setIsModalEditUserOpen(false);
          getLoggedUser();
        }}
        isOpen={isModalEditUserOpen}
        refresh={getLoggedUser}
      />
    </>
  );
};

export default MainNavBar;
