import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Option from 'src/contracts/models/Option';
import { Component, Selector, Label } from './style';
import icons from '../../assets/icons';

interface TeamSelectorProps {
  onChange(team: Option[], event: ChangeEvent): void | Promise<void>;
  options: Option[]
  selectedOptions?: Option[]
  allOption: {label: string, value: Option[]}
  defaultValue: any
  isLoading?: boolean
  className?: string
  tooltipLabel?: string
}

const TeamSelector: React.FC<TeamSelectorProps> = ({
  onChange,
  options,
  selectedOptions,
  allOption,
  defaultValue,
  isLoading,
  className,
  tooltipLabel,
}) => {
  const { t } = useTranslation('components');
  const [defaultValueState, setDefaultValueState] = useState(defaultValue);

  const styles = {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    control: (styles: any) => ({ ...styles, backgroundColor: 'red' }),
  };

  useEffect(() => {
    if (options.length > 0) setDefaultValueState(defaultValue);
  }, [defaultValue, options]);

  return (
    <Component className={className} id="team-selector">
      <Label>
        {t('TeamSelector.teams')}
        {tooltipLabel && <img className="ms-2" src={icons.Info} alt={tooltipLabel} data-toggle="tooltip" data-placement="top" title={tooltipLabel} />}
      </Label>
      <Selector
        isMulti
        closeMenuOnSelect={false}
        placeholder={t('TeamSelector.placeholder')}
        value={defaultValueState}
        options={options}
        isLoading={isLoading}
        onChange={onChange}
        selectedOptions={selectedOptions}
        allOption={allOption}
        style={styles}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </Component>
  );
};

export default TeamSelector;
