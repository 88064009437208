import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'src/components/ButtonLoader';
import icons from 'src/assets/icons';
import { ReactComponent as FileIcon } from 'src/assets/icons/metro-file-upload.svg';
import { CSSProperties } from 'styled-components';
import CircleInformation from '../CircleInformation';
import {
  Container,
  Label,
  DropZone,
  DropContainer,
  UploadMessage,
  LinkLabel,
  Row, LinkContainer,
} from './style';

type UploadCustomType = {
    label: string;
    isDragActiveMessage?: string;
    isDragRejectMessage?: string;
    deafaultMessage?: string;
    accept?: string[];
    defaultLink?: string | null;
    onChange: Function;
    onDelete: Function;
    onLinkClick?: Function;
    popoverLabelInfo?: string
    style?: CSSProperties;
    className?: string;
    disabled?: boolean
};

const UploadCustom: React.FC<UploadCustomType> = ({
  label,
  isDragActiveMessage,
  isDragRejectMessage,
  deafaultMessage,
  defaultLink = null,
  onChange,
  onDelete,
  onLinkClick,
  accept = ['*'],
  popoverLabelInfo,
  style,
  className,
  disabled = false,
  ...rest
}) => {
  const { t } = useTranslation('components');
  const [defaultFile, setDefaultFile] = useState<File | null>(null);

  const onDrop = useCallback(
    (files) => {
      setDefaultFile(files[0]);
      onChange(files[0]);
    },
    [onChange],
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept,
    multiple: false,
    onDrop,
  });

  const renderDragMessage = useCallback(() => {
    if (isDragReject) {
      return (
        <UploadMessage type="error">
          { isDragRejectMessage || t('UploadCustom.is_drag_reject_message')}
        </UploadMessage>
      );
    }

    if (defaultFile && !(defaultFile && defaultFile.size / 1048576 > 5)) {
      return <UploadMessage>{defaultFile.name}</UploadMessage>;
    }

    if (!isDragActive) {
      return (
        <UploadMessage>
          <FileIcon fill="#A8ADC0" color="#A8ADC0" />
          {isDragActiveMessage || t('UploadCustom.is_drag_active_message')}
        </UploadMessage>
      );
    }

    return (
      <UploadMessage type="success">
        { deafaultMessage || t('UploadCustom.default_message')}
      </UploadMessage>
    );
  }, [isDragActive, isDragReject, defaultFile]);

  return (
    <Container style={style} className={className} {...rest}>
      <Row>
        <Label>{label}</Label>
        {popoverLabelInfo && (
          <CircleInformation
            text={popoverLabelInfo}
            popoverPlacement="right"
            id="upload-label"
          />
        )}
      </Row>

      <DropContainer>
        <DropZone
          {...getRootProps()}
        >
          {defaultLink
            ? <LinkLabel onClick={() => onLinkClick?.()}>{t('UploadCustom.default_file')}</LinkLabel>
            : (
              <>
                <input style={{ height: '170px' }} {...getInputProps()} disabled={disabled} />
                {renderDragMessage()}
              </>
            )}
        </DropZone>
        {(defaultFile || defaultLink) && !(defaultFile && defaultFile.size / 1048576 > 5) && (
          <LinkContainer className="bg-light d-flex my-2">
            <a
              href={defaultLink ?? ''}
              target="_blank"
              rel="noreferrer"
              className="m-auto ms-2"
            >
              Vizualizar mapa
            </a>
            <ButtonLoader
              label={t('UploadCustom.remove')}
              uppercase={false}
              type="button"
              icon={icons.TrashRed}
              transparent
              withHover={false}
              style={{ minWidth: '90px' }}
              onClick={() => {
                setDefaultFile(null);
                onDelete(null);
              }}
              disabled={disabled}
            />
          </LinkContainer>
        )}
      </DropContainer>
    </Container>
  );
};

export default UploadCustom;
