import React, { useEffect, useState } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import { BiInfoCircle } from 'react-icons/bi';
import COLORS from 'src/contracts/constants/colors.constants';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import UploadService from 'src/services/UploadService';
import {
  CheckBoxText,
  HeaderContainer,
  FloatLeft,
  Title,
  Container,
  Content,
  CheckBoxContainer,
  CheckboxSubtitle, SendImage,
} from './style';
import CompanyService from '../../../../services/companyService';
import { getData } from '../../../../services/LocalStorageService';
import Company from '../../../../contracts/models/Company';
import keysToCamel from '../../../../utils/keysToCamelCase';
import Loader from '../../../../components/Loader';
import { useToast } from '../../../../hooks/toast';
import UploadCustom from '../../../../components/UploadCustom';
import { MAP_LIMIT_MB } from '../../../../components/Onboarding/contracts/constants';
import { getCustomLogoTypeIsEnable, getUser, logout } from '../../../../services/auth';
import ModalConfirm from '../../../../components/ConfirmModal';

interface LogoInterface {
  uri: string | null;
  file: File | null;
}

const ConfigTab = () => {
  const { t } = useTranslation('pages');
  const { addToast } = useToast();
  const userStorage = getUser();
  const [companyId, setCompanyId] = useState(0);
  const [isLoading, setIdLoading] = useState(true);
  const [isDeleteCompany, setIsDeleteCompany] = useState(false);
  const [companyData, setCompanyData] = useState<Company | null>(null);
  const [paymentType, setPaymentType] = useState<string | null>('LEAD');
  const [logo, setLogo] = useState<LogoInterface>();
  const { user } = getData('espacos@user');

  const loadCompanyData = async () => {
    try {
      const response = await CompanyService.getCompanyData(user.company_id);
      setCompanyData(keysToCamel(response.data));
      setPaymentType(response.data.payment_type);
    } catch (e) {
      setCompanyData(null);
    }

    setCompanyId(user.company_id);
    setIdLoading(false);
  };

  const constUpdateCompanyId = async () => {
    try {
      await CompanyService.updateCompanyData(companyId, {
        is_employee_transparency: companyData?.isEmployeeTransparency,
      });

      addToast({
        description: t('Administration.tabs.configs.success'),
        type: 'success',
      });
    } catch (e) {
      addToast({
        description: t('Administration.tabs.configs.error'),
        type: 'error',
      });
    }

    setIdLoading(false);
  };

  useEffect(() => {
    if (companyData !== null && companyId) {
      constUpdateCompanyId();
    }
  }, [companyData]);

  useEffect(() => {
    loadCompanyData();
  }, []);

  const handleSetMap = (file: File | null) => {
    if (file && file?.size / MAP_LIMIT_MB > 5) {
      setLogo({ file: null, uri: null });

      addToast({
        description: t('Administration.tabs.configs.upload.error'),
        type: 'error',
      });

      return false;
    }

    if (file) {
      setLogo({ file, uri: URL.createObjectURL(file) });
    }

    return true;
  };

  const submitLogo = () => {
    if (logo && logo.file !== null) {
      const form = new FormData();
      form.append('file', logo.file);
      UploadService.sendLogo(form)
        .then(() => addToast({
          description: t('Administration.tabs.configs.upload.success'),
          type: 'success',
        }))
        .catch(() => addToast({
          description: t('Administration.tabs.configs.upload.exception'),
          type: 'error',
        }));
    }
  };

  const paymentTypeToBool = () => {
    if (paymentType === 'LEAD' || paymentType === 'FREE') return true;
    return false;
  };

  const changePaymentType = async (value: any) => {
    await setPaymentType(paymentTypeToBool() ? null : 'FREE');
    CompanyService.updateCompanyPaymentType({ payment: !value })
      .then(() => addToast({
        description: t('Administration.tabs.configs.active.success'),
        type: 'success',
      }))
      .catch(() => addToast({
        description: t('Administration.tabs.configs.active.exception'),
        type: 'error',
      }));
  };

  if (isLoading) {
    return <Loader />;
  }
  const cancelDelete = () => {
    setIsDeleteCompany(false);
  };

  const handleConfirm = async () => {
    await CompanyService.deleteCompany().then(() => logout());
  };

  return (
    <Container>
      <ModalConfirm
        handleConfirm={handleConfirm}
        isOpen={isDeleteCompany}
        handleCloseButton={cancelDelete}
        title="Você tem certeza que deseja deletar essa company?"
        message="Após confirmar você será deslogado."
        messageConfirm="Não é possivel reverter essa ação."
      />
      <HeaderContainer className="clearfix">
        <FloatLeft>
          <Title>{t('Administration.tabs.configs.label')}</Title>
        </FloatLeft>
      </HeaderContainer>
      <Content>
        <CheckBoxContainer>
          <FormControlLabel
            control={(
              <Checkbox
                checked={companyData?.isEmployeeTransparency}
                color="primary"
                onChange={() => setCompanyData((prevState) => ({
                  name: prevState?.name!,
                  isEmployeeTransparency: !companyData?.isEmployeeTransparency!,
                }))}
              />
            )}
            label={(
              <CheckBoxText>
                {t('Administration.tabs.configs.enable_mobile_full_view')}
                <BiInfoCircle
                  size={18}
                  color={COLORS.mutedColor}
                  id="isEmployeeTransparency"
                  style={{
                    cursor: 'pointer',
                    marginLeft: '10px',
                  }}
                />
              </CheckBoxText>
            )}
          />
          <CheckboxSubtitle>
            <UncontrolledPopover
              flip
              target="isEmployeeTransparency"
              trigger="hover"
              placement="bottom"
            >
              <PopoverBody>
                {t(
                  'Administration.tabs.configs.enable_mobile_full_view_subtitle',
                )}
              </PopoverBody>
            </UncontrolledPopover>
          </CheckboxSubtitle>
        </CheckBoxContainer>
        {userStorage?.isSupport && (
          <>
            <CheckBoxContainer>
              <FormControlLabel
                control={(
                  <Checkbox
                    onChange={(e) => changePaymentType(e.currentTarget.checked)}
                    checked={!paymentTypeToBool()}
                    color="primary"
                  />
              )}
                label={(
                  <CheckBoxText>
                    {t('Administration.tabs.configs.enable_company')}
                    <BiInfoCircle
                      size={18}
                      color={COLORS.mutedColor}
                      id="companyIsActive"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    />
                  </CheckBoxText>
              )}
              />
              <CheckboxSubtitle>
                <UncontrolledPopover
                  flip
                  target="companyIsActive"
                  trigger="hover"
                  placement="bottom"
                >
                  <PopoverBody>
                    {t(
                      'Administration.tabs.configs.enable_company_subtitle',
                    )}
                  </PopoverBody>
                </UncontrolledPopover>
              </CheckboxSubtitle>
            </CheckBoxContainer>
            <button
              type="button"
              className="btn btn-danger text-white btn-lg"
              onClick={() => setIsDeleteCompany(true)}
            >
              excluir company
            </button>
          </>
        )}
        {getCustomLogoTypeIsEnable() && (
          <>
            <UploadCustom
              label={t('Administration.tabs.configs.upload.label')}
              className="map-input"
              defaultLink={logo?.uri}
              accept={['image/jpeg', 'image/jpg']}
              style={{
                flex: 3,
                fontWeight: 'bold',
                fontSize: '1.1rem',
                fontFamily: 'lato',
                marginTop: '15px',
              }}
              onChange={(file: File | null) => { handleSetMap(file); }}
              onLinkClick={() => { window.open(logo?.uri!, 'Image'); }}
              onDelete={() => { setLogo({ file: null, uri: null }); }}
            />
            <SendImage
              disabled={!logo}
              type="button"
              data-toggle="tooltip"
              data-placement="top"
              title={t('Administration.tabs.configs.upload.tooltip')}
              onClick={() => submitLogo()}
            >
              {t('Administration.tabs.configs.upload.button')}
            </SendImage>
          </>
        )}
      </Content>
    </Container>
  );
};
export default ConfigTab;
