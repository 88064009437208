import React, { FC } from 'react';
import { Container, Text } from './style';

const Badge:FC<{text: string}> = ({ text }) => (
  <Container>
    <Text>{text}</Text>
  </Container>
);

export default Badge;
