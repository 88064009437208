import styled from 'styled-components';
import colors from '../../colors';

export const Screen = styled.div`
  transition: 0.3s;
  height: calc(100vh - 64px);
  @media only screen and (max-width: 768px) {
    padding-top: 99px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 107px;
  border: 1px solid ${colors.white20};
  padding: 0 6rem;
  @media (max-width: 1024px) {
    font-size: 9px;
    padding: 10rem 2rem;
  }
`;

export const Filters = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  button {
    &:nth-child(1) {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    button {
      > span {
        font-size: 10px !important;
      }
    }
  }
`;

export const LoaderContainer = styled.div`
  padding: 0 6rem 6rem;
  background-color: ${colors.white10};
  width: 100%;
  min-height: 100vh;
  display: flex;

  @media (max-width: 1024px) {
    padding: 0 2rem;
  }
`;

export const Body = styled.div`
  padding: 0 6rem 6rem;
  background-color: ${colors.white10};
  width: 100%;
  height: calc(100vh - 171px);
  > div {
    margin-top: 1.3rem;
    border: 1px solid ${colors.gray25};
  }

  @media (max-width: 1024px) {
    padding: 0 2rem;
  }
`;

export const BodyPaginate = styled.div`
  border: 0 !important;

  > div {
    border: 0 !important;
  }
`;
