import styled from 'styled-components';
import Select from 'react-select';
import colors from '../../colors';

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  >span{
    font: normal normal medium 5px/5px lato;
    font-size: 13px !important;
    color: ${colors.gray90};
    margin-bottom: 5px;
  }
`;

export const Selector = styled(Select)`
  >div{
    border-color: ${colors.gray28} !important; 
    min-height: 50px;
    background-color: ${colors.white};
    :nth-child(1){
      box-shadow: none;
      >div{ 
        min-height: 50px;
      }
    }
    :nth-child(2){
      box-shadow: none;
      >div{ 
        min-height: 50px;
      }
    }
  }
`;
