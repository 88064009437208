import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  addDays,
  format, isToday, parse, subDays,
} from 'date-fns';
import pt from 'date-fns/locale/pt';
import MainNavBar from '../../components/MainNavBar';
import { ContainerLocation, IconTitle, Title } from '../Reservation/style';
import Icons from '../../assets/icons';
import { useGlobal } from '../../hooks/useGlobal';
import ButtonLoader from '../../components/ButtonLoader';
import {
  ButtonArea,
  DateContainer,
  Label,
  LocationAddress,
  LocationName,
  Options,
  Seat,
  SeatName,
  Shifts, StatusName,
  TitleDate, UpperContainer,
} from './style';
import CheckInService from '../../services/CheckInService';
import { useToast } from '../../hooks/toast';
import {
  LoaderContainer,
} from '../Reservation/ManageLocation/LocationListing/style';
import Loader from '../../components/Loader';
import colors from '../../colors';
import { Empty, EmptyIcon, EmptyMessage } from '../Home/style';

export default function CheckIn() {
  const { t } = useTranslation('pages');
  const { sideMenuIsOpen } = useGlobal();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [date, setDate] = useState<Date>(new Date());
  const [checkInArray, setCheckInArray] = useState(Array);

  const getCheckIn = async () => {
    setIsLoading(true);
    try {
      const response = await CheckInService.getCheckIn(format(date, 'yyyy-MM-dd'));

      setCheckInArray(response.data);
      setIsLoading(false);
    } catch (error) {
      addToast({
        description: error.response.data.message,
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCheckIn();
  }, []);

  useEffect(() => {
    getCheckIn();
  }, [date]);

  const parseDateCheckIn = (
    parseDate: Date,
    parsePattern: string = 'yyyy-MM-dd',
    pattern: string = 'yyyy-MM-dd',
  ) => {
    const formattedDate = format(parseDate, 'yyyy-MM-dd');
    const newDay = parse(formattedDate, parsePattern, new Date());

    if (isToday(newDay)) {
      return t('CheckIn.today');
    }

    return format(newDay, pattern, { locale: pt });
  };

  const parseAndformatDate = (
    shiftDate: string,
    parsePattern: string,
    pattern: string,
  ) => {
    const newDay = parse(shiftDate, parsePattern, new Date());
    return format(newDay, pattern, { locale: pt });
  };

  const doCheckIn = async (shiftEmployeeId : number) => {
    setIsLoadingButton(true);
    try {
      await CheckInService.doCheckIn(shiftEmployeeId);

      addToast({
        description: t('CheckIn.success'),
        type: 'success',
      });
    } catch (error) {
      addToast({
        description: error.response.data.message,
        type: 'error',
      });
    } finally {
      setIsLoadingButton(false);
      getCheckIn();
    }
  };

  const doCheckOut = async (shiftEmployeeId : number) => {
    setIsLoadingButton(true);
    try {
      await CheckInService.doCheckOut(shiftEmployeeId);

      addToast({
        description: t('CheckIn.checkOut_success'),
        type: 'success',
      });
    } catch (error) {
      addToast({
        description: error.response.data.message,
        type: 'error',
      });
    } finally {
      setIsLoadingButton(false);
      getCheckIn();
    }
  };

  return (
    <>
      <MainNavBar />
      <ContainerLocation className={`row px-2 me-0 ${sideMenuIsOpen ? 'margin-menu-open' : 'margin-menu-close'}`}>
        <UpperContainer className="col-12 d-flex mb-2">
          <IconTitle src={Icons.locationCheckin} alt="Location" className="mt-1" />
          <Title>{t('CheckIn.checkIn')}</Title>
          <DateContainer>
            <ButtonLoader
              style={{ marginRight: 5 }}
              transparent
              withHover={false}
              isLoading={isLoading}
              iconSize={16}
              icon={Icons.materialChevronLeft}
              onClick={() => {
                setDate((current) => subDays(current, 1));
              }}
            />
            <TitleDate className="d-inline-block min-vw-25 text-center">
              {parseDateCheckIn(
                date,
                'yyyy-MM-dd',
                'd MMMM, EEEE',
              )}
            </TitleDate>
            <ButtonLoader
              style={{ marginLeft: 5 }}
              transparent
              withHover={false}
              isLoading={isLoading}
              iconSize={16}
              icon={Icons.materialChevronRight}
              onClick={() => {
                setDate((current) => addDays(current, 1));
              }}
            />
          </DateContainer>
        </UpperContainer>
        {isLoading ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        ) : checkInArray.map((checkIn: any) => (
          <div className="card my-3 shadow border-0" style={{ borderRadius: '10px' }}>
            <div className="card-header d-flex justify-content-between bg-white border-0" style={{ borderRadius: '10px' }}>
              <div className="w-75">
                <Label>
                  {parseAndformatDate(
                    checkIn.shift_allocated_at,
                    'yyyy-MM-dd',
                    'd MMM, eeee',
                  )}
                </Label>
                <Shifts>
                  {parseAndformatDate(
                    checkIn.shift_start,
                    'yyyy-MM-dd HH:mm:ss',
                    'HH:mm',
                  )}
                  {' '}
                  -
                  {' '}
                  {parseAndformatDate(
                    checkIn.shift_end,
                    'yyyy-MM-dd HH:mm:ss',
                    'HH:mm',
                  )}
                </Shifts>
                <LocationName>
                  {checkIn.location_name}
                  {' '}
                  -
                  {' '}
                  {checkIn.placement_name}
                </LocationName>
                <LocationAddress>{checkIn.location_address}</LocationAddress>
              </div>
              <Options className="d-flex m-0">
                {checkIn.seat_name && (
                  <Seat>
                    <SeatName>
                      {checkIn.seat_name}
                    </SeatName>
                  </Seat>
                )}
              </Options>
            </div>
            <ButtonArea className="d-flex justify-content-between bg-white border-0">
              <div className="w-75">
                <Label>{t('CheckIn.checkIn')}</Label>
                <StatusName>
                  {checkIn.checkin ? (
                    <>
                      {parseAndformatDate(
                        checkIn.checkin.split('.')[0].replace('T', ' '),
                        'yyyy-MM-dd HH:mm:ss',
                        "  HH:mm 'de' dd/MM/yyyy",
                      )}
                    </>
                  ) : (
                    t('CheckIn.no_reg')
                  )}
                </StatusName>
              </div>
              {!checkIn.checkin && (
                <Options className="d-flex m-0">
                  <ButtonLoader
                    isLoading={isLoadingButton && !checkIn.checkin}
                    disabled={Boolean(checkIn.checkin)}
                    icon={Icons.checkin}
                    onClick={() => doCheckIn(checkIn.shift_employee_id)}
                    label={t('CheckIn.checkIn')}
                    color={colors.green30}
                    className="button-min-width"
                  />
                </Options>
              )}
            </ButtonArea>

            <ButtonArea className="d-flex justify-content-between bg-white border-0">
              <div className="w-75">
                <Label>{t('CheckIn.checkOut')}</Label>
                <StatusName>
                  {/* eslint-disable-next-line no-nested-ternary */}
                  {checkIn.checkin ? (
                    checkIn.checkout ? (
                      <>
                        {parseAndformatDate(
                          checkIn.checkin.split('.')[0].replace('T', ' '),
                          'yyyy-MM-dd HH:mm:ss',
                          "  HH:mm 'de' dd/MM/yyyy",
                        )}
                      </>
                    ) : (
                      t('CheckIn.no_reg')
                    )
                  ) : (
                    t('CheckIn.waiting_reg_checkin')
                  )}
                </StatusName>
              </div>
              {!checkIn.checkout && (
                <Options className="d-flex m-0">
                  <ButtonLoader
                    isLoading={isLoadingButton && !checkIn.checkout}
                    disabled={!checkIn.checkin || Boolean(checkIn.checkout)}
                    icon={Icons.checkout}
                    onClick={() => doCheckOut(checkIn.shift_employee_id)}
                    label={t('CheckIn.checkOut')}
                    color={colors.red}
                    className="button-min-width"
                  />
                </Options>
              )}
            </ButtonArea>

          </div>
        ))}
      </ContainerLocation>
      {checkInArray.length === 0 && (
        <div className="d-flex justify-content-center">
          <Empty>
            <EmptyMessage>
              <span>{t('CheckIn.empty_message')}</span>
            </EmptyMessage>
            <EmptyIcon src={Icons.Empty} />
          </Empty>
        </div>
      )}
    </>
  );
}
