const removeExif = (file: File, setNewImage: Function) => {
  const img = new Image();

  if (!file) return;

  img.src = URL.createObjectURL(file);
  img.onload = () => {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      ctx.drawImage(img, 0, 0);
      const blob = canvas.toDataURL(file.type);
      const newFile = new Blob([Uint8Array.from(atob(blob.split(',')[1]), (c) => c.charCodeAt(0))], { type: file.type });
      setNewImage({ file: newFile, uri: URL.createObjectURL(newFile) });
    }
  };
  img.onerror = () => console.error('The file is not an image');
};

export default removeExif;
