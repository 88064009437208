import React, { ButtonHTMLAttributes } from 'react';
import ReactTooltip, { Type } from 'react-tooltip';
import Spinner from 'react-bootstrap/Spinner';
import {
  Button, Row, Icon,
} from './style';

import colors from '../../colors';

export interface ButtonLoaderProps extends ButtonHTMLAttributes<HTMLButtonElement>{
    isLoading?: boolean;
    label?: string;
    iconSize?: number;
    icon? :string;
    color? :string;
    transparent? :boolean;
    uppercase? :boolean;
    bordered?: boolean;
    width?: string;
    tooltipText?: string;
    tooltipStyle?: Type;
    withHover?: boolean;
    iconClass?: string;
  }

const ButtonLoader: React.FC<ButtonLoaderProps> = ({
  isLoading = false,
  label,
  onClick,
  icon,
  color,
  iconSize = 20,
  transparent,
  uppercase = true,
  bordered,
  width,
  tooltipText,
  tooltipStyle,
  withHover = true,
  iconClass,
  ...rest
}) => {
  const getLabel = () => {
    if (icon && label) {
      return (
        <Row className={iconClass ?? ''}>
          <Icon size={iconSize} src={icon} alt="" />
          <span>{label}</span>
        </Row>
      );
    }
    if (icon) return <Icon size={iconSize} src={icon} alt="" />;
    return label;
  };

  return (
    <>
      <Button
        bordered={bordered}
        transparent={transparent}
        label={!!label}
        icon={!!icon}
        disabled={isLoading || rest.disabled}
        width={width}
        color={color}
        onClick={onClick}
        isLoading={isLoading}
        uppercase={uppercase}
        withHover={withHover}
        {...rest}
      >
        {isLoading ? (
          <Spinner size="sm" animation="border" color={colors.blue} />
        ) : (<p style={{ marginBottom: '0' }} data-tip={tooltipText}>{getLabel()}</p>)}
      </Button>
      {tooltipStyle && (
        <ReactTooltip
          place="right"
          type={tooltipStyle}
          effect="solid"
        />
      )}
    </>
  );
};

export default ButtonLoader;
