import styled from 'styled-components';

const Container = styled.div<{ disabled?: boolean }>`
  opacity: ${(p) => (p.disabled ? '.5' : '1')};
    width: max-content;
  label {
    font-size: 0.875rem;
    line-height: 0;
  }
  > div {
    display: flex;
    align-items: flex-end;
    margin-top: .5rem;
    button {
      height: 38px;
      line-height: 0;
      background-color: #f5f6f8;
      border: 1px solid #0000001f;
      width: 34px;
      cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
      :first-child {
        border-radius: 4px 0px 0px 4px;
      }

      :last-child {
        border-radius: 0px 4px 4px 0px;
      }
    }
    input {
      width: 5rem;
      height: 38px;
      text-align: center;
    }
  }
`;

export default Container;
