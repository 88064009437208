/* eslint-disable @typescript-eslint/no-unused-vars,max-len,camelcase,no-nested-ternary */
import React, { useEffect, useState, FC } from 'react';
import { Checkbox, FormControlLabel, Radio } from '@material-ui/core';
import { BiInfoCircle } from 'react-icons/bi';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useState as HookUseState } from '@hookstate/core';
import { useHistory } from 'react-router-dom';
import PlacementService from '../../services/PlacementService';
import {
  Buttons,
  Column,
  HourLabels,
  OptionSelectorSpace,
  Row,
  Section,
  SectionTitle,
  Space,
  Subtitle,
} from './tabs/PositionsList/style';
import COLORS from '../../contracts/constants/colors.constants';
import BreakText from '../../components/BreakText';
import Input from '../../components/Input';
import Select from '../../components/Select';
import ButtonLoader from '../../components/ButtonLoader';
import { useToast } from '../../hooks/toast';
import Loader from '../../components/Loader';

interface ConfigVacanciesProps {
  placementId: number;
  config: any;
  vacancie: any;
}

interface SubmitInterface {
  id?: number | undefined | null;
  location_id?: number;
  placement_id: number | undefined;
  is_allow_fractional_shift: boolean;
  is_allow_shift_after_placement_start: boolean;
  minimum_duration_shift: string | undefined;
  allow_shift_at: string | undefined;
  allow_shift_before_the: string | undefined;
  is_allow_abandonment_shift: boolean;
  allow_abandonment_at: string | undefined;
  allow_abandonment_before: string | undefined;
  allow_abandonment_same_day: boolean;
  is_automatically_announce_after_abandonment: boolean | undefined;
  is_register_checkin_checkout: boolean;
  is_activate_geolocation: boolean | undefined;
  enable_checkin_checkout_from: number | undefined;
  enable_checkin_before_time: string | undefined;
  enable_checkin_after_time: string | undefined;
  enable_checkout_before_time: string | undefined;
  enable_checkout_after_time: string | undefined;
  is_allow_checkout_without_checkin: boolean | undefined;
  is_automatic_publications: boolean;
  is_automatic_announcements: boolean;
}

const ConfigVacancies: FC<ConfigVacanciesProps> = ({ placementId, config, vacancie }) => {
  const { t } = useTranslation('pages');
  const history = useHistory();
  const { addToast } = useToast();
  const editedData: any = HookUseState<any>({});
  const fractionalReservation = HookUseState<boolean>(true);
  const fractionalReservationMinDuration = HookUseState<string>('00:15:00');
  const reservationPlanning = HookUseState<boolean>(true);
  const reservationPlanningAllowTime = HookUseState<string>('');
  const reservationPlanningBeforeThe = HookUseState<string>('DAY');
  const reservationAbandonment = HookUseState<boolean>(false);
  const reservationAbandonmentSameDay = HookUseState<boolean>(false);
  const reservationAbandonmentAllowTime = HookUseState<string>('');
  const reservationAbandonmentBeforeThe = HookUseState<string>('');
  const reservationAbandonmentAutomaticAnnouncement = HookUseState<boolean>(false);
  const checkinAndCheckout = HookUseState<boolean>(false);
  const isAutomaticPublications = HookUseState<boolean>(false);
  const isAutomaticAnnouncements = HookUseState<boolean>(false);
  const checkinAndCheckoutGeolocation = HookUseState<boolean>(false);
  const checkinAndCheckoutRadius = HookUseState<number>(0);
  const checkinBeforeTime = HookUseState<string>('');
  const checkinAfterTime = HookUseState<string>('');
  const checkoutBeforeTime = HookUseState<string>('');
  const checkoutAfterTime = HookUseState<string>('');
  const allowCheckinWithoutCheckout = HookUseState<boolean>(false);
  const [isLoading, setIsloading] = useState(true);

  const [identifiedShifts, setIdentifiedShifts] = useState(false);

  useEffect(() => {
    if (config.hasConfig) {
      setIsloading(true);
      PlacementService.getConfig(placementId).then((response) => {
        fractionalReservation.set(
          !!response.data.is_allow_fractional_shift,
        );
        fractionalReservationMinDuration.set(
          response.data.minimum_duration_shift || '00:00:00',
        );
        reservationPlanning.set(
          response.data.is_allow_shift_after_placement_start,
        );
        reservationPlanningAllowTime.set(
          response.data.allow_shift_at || '00:00:00',
        );
        reservationPlanningBeforeThe.set(
          response.data.allow_shift_before_the
            ? response.data.allow_shift_before_the
            : 'DAY',
        );
        reservationAbandonment.set(
          !!response.data.is_allow_abandonment_shift,
        );
        reservationAbandonmentSameDay.set(
          !!response.data.allow_abandonment_same_day,
        );
        reservationAbandonmentAllowTime.set(
          response.data.allow_abandonment_at || '00:00:00',
        );
        reservationAbandonmentBeforeThe.set(
          response.data.allow_abandonment_before
            ? response.data.allow_abandonment_before
            : 'DAY',
        );
        reservationAbandonmentAutomaticAnnouncement.set(
          !!response.data.is_automatically_announce_after_abandonment,
        );
        checkinAndCheckout.set(
          !!response.data.is_register_checkin_checkout,
        );
        checkinAndCheckoutGeolocation.set(
          !!response.data.is_activate_geolocation,
        );
        checkinAndCheckoutRadius.set(
          response.data.enable_checkin_checkout_from,
        );
        checkinBeforeTime.set(response.data.enable_checkin_before_time);
        checkinAfterTime.set(response.data.enable_checkin_after_time);
        checkoutBeforeTime.set(
          response.data.enable_checkout_before_time,
        );
        checkoutAfterTime.set(response.data.enable_checkout_after_time);
        allowCheckinWithoutCheckout.set(
          !!response.data.is_allow_checkout_without_checkin,
        );
        isAutomaticPublications.set(
          response.data.is_automatic_publications,
        );
        isAutomaticAnnouncements.set(
          response.data.is_automatic_announcements,
        );
        setIdentifiedShifts(!response.data.identified_shifts);
        editedData.set(response.data);
      })
        .finally(() => setIsloading(false));
    }
  }, []);

  const tootip = !identifiedShifts ? {}
    : {
      'data-toggle': 'tooltip',
      'data-placement': 'top',
      title: t('AddLocation.tabs.ReservationsConfig.option_available_only_for_identified_positions'),
    };

  const creationSubmit = (payload: SubmitInterface[]) => {
    PlacementService.createConfig(payload)
      .then(() => {
        addToast({
          description: t('AddLocation.tabs.ReservationsConfig.success'),
          type: 'success',
        });
        history.goBack();
      })
      .catch(() => {
        addToast({
          description: t(
            'AddLocation.tabs.ReservationsConfig.errors.apifailed',
          ),
          type: 'error',
        });
      });
  };

  const editSubmit = (payload: SubmitInterface[]) => {
    PlacementService.updateConfig(payload)
      .then(() => {
        addToast({
          description: t('AddLocation.tabs.ReservationsConfig.success'),
          type: 'success',
        });
        history.push('/spaces');
      })
      .catch(() => {
        addToast({
          description: t(
            'AddLocation.tabs.ReservationsConfig.errors.apifailed',
          ),
          type: 'error',
        });
      });
  };

  const handleSubmit = () => {
    let hasError = false;

    const createBody = [];
    const editBody = [];

    if (
      (config.hasConfig && editedData?.get())
      || !config.hasConfig
    ) {
      if (
        fractionalReservation?.get()
        && !reservationPlanningBeforeThe?.get()
      ) {
        hasError = true;
        addToast({
          description: t('AddLocation.tabs.ReservationsConfig.errors.day'),
          type: 'error',
        });
      }

      if (fractionalReservation.get()) {
        if (
          !fractionalReservationMinDuration.get()
          || fractionalReservationMinDuration.get().includes('_')
        ) {
          hasError = true;
          addToast({
            description: t(
              'AddLocation.tabs.AddPosition.error.missing_hour_value',
            ),
            type: 'error',
          });
        }
      }

      if (!reservationPlanning.get()) {
        if (
          !reservationPlanningAllowTime.get()
          || reservationPlanningAllowTime.get().includes('_')
        ) {
          hasError = true;
          addToast({
            description: t(
              'AddLocation.tabs.AddPosition.error.missing_hour_value',
            ),
            type: 'error',
          });
        }
      }

      if (
        reservationAbandonment?.get()
        && !reservationAbandonmentBeforeThe?.get()
      ) {
        hasError = true;
        addToast({
          description: t(
            'AddLocation.tabs.ReservationsConfig.errors.abandonment_day',
          ),
          type: 'error',
        });
      }

      if (
        reservationAbandonment.get()
        && (!reservationAbandonmentAllowTime.get()
          || reservationAbandonmentAllowTime.get()?.includes('_'))
      ) {
        hasError = true;
        addToast({
          description: t(
            'AddLocation.tabs.AddPosition.error.missing_hour_value',
          ),
          type: 'error',
        });
      }

      if (!hasError) {
        const payload: SubmitInterface = {
          id: config?.configId,
          placement_id: placementId,
          is_allow_fractional_shift: fractionalReservation.get(),
          is_allow_shift_after_placement_start: reservationPlanning.get(),
          minimum_duration_shift: undefined,
          allow_shift_at: undefined,
          allow_shift_before_the: undefined,
          is_allow_abandonment_shift: reservationAbandonment.get(),
          allow_abandonment_at: undefined,
          allow_abandonment_before: undefined,
          allow_abandonment_same_day: reservationAbandonmentSameDay.get(),
          is_automatically_announce_after_abandonment: undefined,
          is_register_checkin_checkout: checkinAndCheckout.get(),
          is_activate_geolocation: undefined,
          enable_checkin_checkout_from: undefined,
          enable_checkin_before_time: undefined,
          enable_checkin_after_time: undefined,
          enable_checkout_before_time: undefined,
          enable_checkout_after_time: undefined,
          is_allow_checkout_without_checkin: undefined,
          is_automatic_publications: isAutomaticPublications.get(),
          is_automatic_announcements: isAutomaticAnnouncements.get(),
        };

        if (fractionalReservation.get()) {
          payload.minimum_duration_shift = fractionalReservationMinDuration.get();
          payload.allow_shift_at = reservationPlanningAllowTime.get();
          payload.allow_shift_before_the = reservationPlanningBeforeThe.get();
        }

        if (!reservationPlanning.get()) {
          payload.allow_shift_at = reservationPlanningAllowTime.get();
          payload.allow_shift_before_the = reservationPlanningBeforeThe.get();
        }

        if (reservationAbandonment.get()) {
          payload.allow_abandonment_at = reservationAbandonmentAllowTime.get();
          payload.allow_abandonment_before = reservationAbandonmentBeforeThe.get();
          payload.is_automatically_announce_after_abandonment = reservationAbandonmentAutomaticAnnouncement.get();
          payload.allow_abandonment_same_day = reservationAbandonmentSameDay.get();
        }

        if (checkinAndCheckout.get()) {
          payload.is_activate_geolocation = false;
          payload.enable_checkin_checkout_from = 0;
          payload.enable_checkin_before_time = '00:00:00';
          payload.enable_checkin_after_time = '00:00:00';
          payload.enable_checkout_before_time = '00:00:00';
          payload.enable_checkout_after_time = '00:00:00';
          payload.is_allow_checkout_without_checkin = false;
        }

        if (config.hasConfig && editedData?.get()) {
          payload.location_id = vacancie.locationId;
          editBody.push(payload);
        } else {
          createBody.push(payload);
        }
      }
    }

    if (!hasError) {
      if (createBody.length) return creationSubmit(createBody);
      if (editBody.length) return editSubmit(editBody);
      return history.goBack();
    }
    return null;
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Section className="mt-4">
        <Row center spaced>
          <Column>
            <SectionTitle>
              {t('AddLocation.tabs.ReservationsConfig.planning')}
            </SectionTitle>
            <Row center>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="checkedA"
                    color="primary"
                    checked={reservationPlanning.get()}
                  />
                )}
                label={(
                  <>
                    {t(
                      'AddLocation.tabs.ReservationsConfig.planning_check_box',
                    )}
                    <BiInfoCircle
                      size={18}
                      color={COLORS.mutedColor}
                      id="reservationPlanningText"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    />
                  </>
                )}
                onChange={() => {
                  reservationPlanning.set(
                    !reservationPlanning.get(),
                  );
                }}
              />

              <UncontrolledPopover
                flip
                target="reservationPlanningText"
                trigger="hover"
                placement="bottom"
              >
                <PopoverBody>
                  <BreakText
                    text={t(
                      'AddLocation.tabs.ReservationsConfig.planning_subtitle',
                    )}
                  />
                </PopoverBody>
              </UncontrolledPopover>
            </Row>
          </Column>
          <Space />
          <Space />
          {!reservationPlanning.get() && (
            <>
              <Column>
                <Subtitle noMargin style={{ marginBottom: '28px' }}>
                  {t(
                    'AddLocation.tabs.ReservationsConfig.reservation_planning',
                  )}
                </Subtitle>
                <Row center>
                  <HourLabels left>
                    {t(
                      'AddLocation.tabs.ReservationsConfig.allow_reservation_until',
                    )}
                  </HourLabels>
                  <Input
                    style={{ maxWidth: 75 }}
                    mask="99:99"
                    placeholder="00:00"
                    defaultValue={reservationPlanningAllowTime.get()}
                    onChange={(event) => {
                      reservationPlanningAllowTime.set(
                        `${event.target.value}:00`,
                      );
                    }}
                  />
                  <HourLabels right>
                    {t(
                      'AddLocation.tabs.ReservationsConfig.before_the',
                    )}
                  </HourLabels>
                  <OptionSelectorSpace>
                    <Select
                      width="200px"
                      defaultValue={
                        reservationPlanningBeforeThe.get()
                        === 'HOUR'
                          ? {
                            label: t(
                              'AddLocation.tabs.ReservationsConfig.before_hour',
                            ),
                            value: 'HOUR',
                          }
                          : {
                            label: t(
                              'AddLocation.tabs.ReservationsConfig.before_day',
                            ),
                            value: 'DAY',
                          }
                      }
                      options={[
                        {
                          label: t(
                            'AddLocation.tabs.ReservationsConfig.before_day',
                          ),
                          value: 'DAY',
                        },
                        {
                          label: t(
                            'AddLocation.tabs.ReservationsConfig.before_hour',
                          ),
                          value: 'HOUR',
                        },
                      ]}
                      onChange={(
                        event: { label: string; value: string } | any,
                      ) => {
                        reservationPlanningBeforeThe.set(
                          event.value,
                        );
                      }}
                    />
                  </OptionSelectorSpace>
                </Row>
              </Column>
              <Space />
              <Space />
            </>
          )}
        </Row>
      </Section>
      <Section>
        <Row>
          <Column>
            <SectionTitle>
              {t(
                'AddLocation.tabs.ReservationsConfig.fractional_reserve',
              )}
            </SectionTitle>
            <Row>
              <Column>
                <Row center>
                  <FormControlLabel
                    {...tootip}
                    control={(
                      <Checkbox
                        disabled={identifiedShifts}
                        name="checkedA"
                        color="primary"
                        checked={fractionalReservation.get()}
                      />
                    )}
                    label={(
                      <>
                        {t(
                          'AddLocation.tabs.ReservationsConfig.allow_fractional_reserve',
                        )}
                        <BiInfoCircle
                          size={18}
                          color={COLORS.mutedColor}
                          id="fractionalReservationText"
                          style={{
                            cursor: 'pointer',
                            marginLeft: '10px',
                          }}
                        />
                      </>
                    )}
                    onChange={() => {
                      fractionalReservation.set(
                        !fractionalReservation.get(),
                      );
                    }}
                  />
                  <UncontrolledPopover
                    flip
                    target="fractionalReservationText"
                    trigger="hover"
                    placement="bottom"
                  >
                    <PopoverBody>
                      <BreakText
                        text={t(
                          'AddLocation.tabs.ReservationsConfig.active_fractional_reserve_subtitle',
                        )}
                      />
                    </PopoverBody>
                  </UncontrolledPopover>
                  {fractionalReservation.get() && (
                    <Column>
                      <Input
                        style={{ maxWidth: 75 }}
                        mask="99:99"
                        placeholder="00:00"
                        defaultValue={fractionalReservationMinDuration.get()}
                        onChange={(event) => {
                          fractionalReservationMinDuration.set(
                            `${event.target.value}:00`,
                          );
                        }}
                      />
                    </Column>
                  )}
                </Row>
              </Column>
            </Row>
          </Column>
        </Row>
      </Section>
      <Section>
        <Row>
          <Column flex>
            <SectionTitle>
              {t(
                'AddLocation.tabs.ReservationsConfig.reservation_cancel',
              )}
            </SectionTitle>
            <Row
              center
              style={{
                marginBottom: reservationAbandonment.get() ? '35px' : '0',
              }}
            >
              <FormControlLabel
                control={(
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    checked={reservationAbandonment.get()}
                  />
                )}
                label={(
                  <>
                    {t(
                      'AddLocation.tabs.ReservationsConfig.allow_abandonment',
                    )}
                    <BiInfoCircle
                      size={18}
                      color={COLORS.mutedColor}
                      id="reservationAbandonmentText"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    />
                  </>
                )}
                onChange={() => {
                  reservationAbandonment.set(
                    !reservationAbandonment.get(),
                  );
                }}
              />
              <UncontrolledPopover
                flip
                target="reservationAbandonmentText"
                trigger="hover"
                placement="bottom"
              >
                <PopoverBody>
                  <BreakText
                    text={t(
                      'AddLocation.tabs.ReservationsConfig.secondary_subtitle',
                    )}
                  />
                </PopoverBody>
              </UncontrolledPopover>
              <Space />
            </Row>
          </Column>

          {reservationAbandonment.get() && (
            <>
              <Space />
              <Space />
              <Column content="flex-start" flex>
                <Subtitle noMargin style={{ marginBottom: '28px' }}>
                  {t('AddLocation.tabs.ReservationsConfig.dealt')}
                </Subtitle>
                <Row center>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        name="checkedC"
                        color="primary"
                        checked={reservationAbandonmentAutomaticAnnouncement.get()}
                      />
                    )}
                    label={(
                      <>
                        {t(
                          'AddLocation.tabs.ReservationsConfig.automatically_advertise',
                        )}
                        <BiInfoCircle
                          size={18}
                          color={COLORS.mutedColor}
                          id="reservationAdvertiseText"
                          style={{
                            cursor: 'pointer',
                            marginLeft: '10px',
                          }}
                        />
                      </>
                    )}
                    onChange={() => {
                      reservationAbandonmentAutomaticAnnouncement.set(
                        !reservationAbandonmentAutomaticAnnouncement.get(),
                      );
                    }}
                  />
                  <UncontrolledPopover
                    flip
                    target="reservationAdvertiseText"
                    trigger="hover"
                    placement="bottom"
                  >
                    <PopoverBody>
                      <BreakText
                        text={t(
                          'AddLocation.tabs.ReservationsConfig.automatically_advertise_subtitle',
                        )}
                      />
                    </PopoverBody>
                  </UncontrolledPopover>
                </Row>
              </Column>
            </>
          )}
        </Row>
        <Row>
          {reservationAbandonment.get() && (
            <Column>
              <Row center>
                <Column>
                  <FormControlLabel
                    style={{ width: '100%' }}
                    control={(
                      <Radio
                        name="reservationAbandonmentSameDay"
                        color="primary"
                        checked={
                          !reservationAbandonmentSameDay.get()
                        }
                      />
                    )}
                    label={t(
                      'AddLocation.tabs.ReservationsConfig.allow_abandonment_until',
                    )}
                    onChange={() => {
                      reservationAbandonmentSameDay.set(false);
                    }}
                  />
                </Column>
                <Column>
                  <Row center>
                    <Input
                      style={{ maxWidth: 75 }}
                      mask="99:99"
                      placeholder="00:00"
                      defaultValue={reservationAbandonmentAllowTime.get()}
                      disabled={
                        !!reservationAbandonmentSameDay.get()
                      }
                      onChange={(event) => {
                        reservationAbandonmentAllowTime.set(
                          `${event.target.value}:00`,
                        );
                      }}
                    />
                    <HourLabels right>
                      {t(
                        'AddLocation.tabs.ReservationsConfig.before_the',
                      )}
                    </HourLabels>
                    <OptionSelectorSpace>
                      <Select
                        width="200px"
                        isDisabled={
                          !!reservationAbandonmentSameDay.get()
                        }
                        defaultValue={
                          reservationAbandonmentBeforeThe.get() === 'HOUR'
                            ? {
                              label: t(
                                'AddLocation.tabs.ReservationsConfig.before_hour',
                              ),
                              value: 'HOUR',
                            }
                            : {
                              label: t(
                                'AddLocation.tabs.ReservationsConfig.before_day',
                              ),
                              value: 'DAY',
                            }
                        }
                        options={[
                          {
                            label: t(
                              'AddLocation.tabs.ReservationsConfig.before_day',
                            ),
                            value: 'DAY',
                          },
                          {
                            label: t(
                              'AddLocation.tabs.ReservationsConfig.before_hour',
                            ),
                            value: 'HOUR',
                          },
                        ]}
                        onChange={(
                          event:
                            | { label: string; value: string }
                            | any,
                        ) => {
                          reservationAbandonmentBeforeThe.set(
                            event.value,
                          );
                        }}
                      />
                    </OptionSelectorSpace>
                  </Row>
                </Column>
              </Row>
            </Column>
          )}
        </Row>
        <Row>
          {reservationAbandonment.get() && (
            <Column>
              <Row>
                <FormControlLabel
                  control={(
                    <Radio
                      name="reservationAbandonmentSameDay"
                      color="primary"
                      checked={reservationAbandonmentSameDay.get()}
                    />
                  )}
                  label={t(
                    'AddLocation.tabs.ReservationsConfig.allow_abandonment_same_day',
                  )}
                  onChange={() => {
                    reservationAbandonmentSameDay.set(true);
                  }}
                />
              </Row>
            </Column>
          )}
        </Row>
      </Section>
      <Section className="d-none">
        <SectionTitle>
          {t(
            'AddLocation.tabs.ReservationsConfig.publications_and_announcements',
          )}
        </SectionTitle>
        <Row>
          <Column flex>
            <Row>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="checkedC"
                    color="primary"
                    checked={isAutomaticPublications.get()}
                  />
                )}
                label={(
                  <>
                    {t(
                      'AddLocation.tabs.ReservationsConfig.publications_checkbox',
                    )}
                    <BiInfoCircle
                      size={18}
                      color={COLORS.mutedColor}
                      id="isAutomaticPublicationsText"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    />
                  </>
                )}
                onChange={() => {
                  isAutomaticPublications.set(
                    !isAutomaticPublications.get(),
                  );
                }}
              />
              <UncontrolledPopover
                flip
                target="isAutomaticPublicationsText"
                trigger="hover"
                placement="bottom"
              >
                <PopoverBody>
                  <BreakText
                    text={t(
                      'AddLocation.tabs.ReservationsConfig.publications_subtitle',
                    )}
                  />
                </PopoverBody>
              </UncontrolledPopover>
            </Row>
          </Column>
          <Space />
          <Space />
          <Column flex>
            <Row>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="checkedC"
                    color="primary"
                    checked={isAutomaticAnnouncements.get()}
                  />
                )}
                label={(
                  <>
                    {t(
                      'AddLocation.tabs.ReservationsConfig.announcements_checkbox',
                    )}
                    <BiInfoCircle
                      size={18}
                      color={COLORS.mutedColor}
                      id="isAutomaticAnnouncementsText"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    />
                  </>
                )}
                onChange={() => {
                  isAutomaticAnnouncements.set(
                    !isAutomaticAnnouncements.get(),
                  );
                }}
              />

              <UncontrolledPopover
                flip
                target="isAutomaticAnnouncementsText"
                trigger="hover"
                placement="bottom"
              >
                <PopoverBody>
                  <BreakText
                    text={t(
                      'AddLocation.tabs.ReservationsConfig.announcements_subtitle',
                    )}
                  />
                </PopoverBody>
              </UncontrolledPopover>
            </Row>
          </Column>
        </Row>
      </Section>
      <Section>
        <SectionTitle>
          {t(
            'AddLocation.tabs.ReservationsConfig.checkin_and_checkout',
          )}
        </SectionTitle>
        <Row>
          <Column>
            <Row center>
              <FormControlLabel
                control={(
                  <Checkbox
                    name="checkedD"
                    color="primary"
                    checked={checkinAndCheckout.get()}
                  />
                )}
                label={(
                  <>
                    {t(
                      'AddLocation.tabs.ReservationsConfig.checkin_and_checkout_register',
                    )}
                    <BiInfoCircle
                      size={18}
                      color={COLORS.mutedColor}
                      id="checkinAndCheckoutText"
                      style={{
                        cursor: 'pointer',
                        marginLeft: '10px',
                      }}
                    />
                  </>
                )}
                onChange={() => {
                  checkinAndCheckout.set(
                    !checkinAndCheckout.get(),
                  );
                }}
              />
              <UncontrolledPopover
                flip
                target="checkinAndCheckoutText"
                trigger="hover"
                placement="bottom"
              >
                <PopoverBody>
                  <BreakText
                    text={t(
                      'AddLocation.tabs.ReservationsConfig.check_in',
                    )}
                  />
                </PopoverBody>
              </UncontrolledPopover>
            </Row>
          </Column>
        </Row>
      </Section>
      <Buttons>
        <ButtonLoader
          uppercase={false}
          onClick={() => handleSubmit()}
          label={`${t('Location.all_positions.conclude')}`}
          color="primary"
        />
      </Buttons>
    </>
  );
};

export default ConfigVacancies;
