import Api from 'src/services/api';

export default class CompanyService {
  static DEFAULT_URL = 'companies';

  static async getCompanyData(companyId: number) {
    const response = await Api.get(`${this.DEFAULT_URL}/${companyId}/settings`);
    return response;
  }

  static async updateCompanyData(companyId: number, data : any) {
    const response = await Api.post(`${this.DEFAULT_URL}/${companyId}/settings`, data);
    return response;
  }

  static async updateCompanyPaymentType(data : any) {
    const response = await Api.post(`${this.DEFAULT_URL}/payment`, data);
    return response;
  };

  static async deleteCompany() {
    const response = await Api.delete(`${this.DEFAULT_URL}/delete`);
    return response;
  }
}
