import React, { InputHTMLAttributes } from 'react';
import InputMask from 'react-input-mask';
import { CSSProperties } from 'styled-components';
import {
  Container, StyledInput, ErrorText, PasswordWeak, SubLabel,
} from './style';
import icons from '../../assets/icons';

interface ValidatorPassword {
  type: string | undefined;
  style?: CSSProperties;
};

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: React.ReactNode;
  required?: boolean;
  register?: any;
  error?: any;
  name: string;
  mask?: string;
  errorMsg?: string;
  styleContainer?: CSSProperties;
  validatorPassword?: ValidatorPassword;
  marginTop?: string;
  tooltipLabel?: string
  subLabel?: string;
}

const Input: React.FC<InputProps> = ({
  label,
  id,
  register,
  errorMsg = '',
  error,
  name,
  mask,
  required,
  styleContainer,
  validatorPassword,
  marginTop = '10px',
  tooltipLabel,
  subLabel,
  ...rest
}) => (
  <Container style={styleContainer}>
    {label && (
    <label htmlFor={id} style={{ marginTop }}>
      {label}
      {' '}
      {required ? '*' : ''}
      {tooltipLabel && <img src={icons.Info} alt={tooltipLabel} data-toggle="tooltip" data-placement="top" title={tooltipLabel} />}
    </label>
    )}
    {subLabel && (
      <SubLabel>{subLabel}</SubLabel>
    )}
    <div>
      {!mask
        ? (
          <StyledInput id={id} name={name} ref={register} {...rest} />
        )
        : (
          <InputMask
            inputRef={register}
            {...rest}
            mask={mask!}
          >
            {() => (
              <StyledInput id={id} name={name} ref={register} {...rest} />
            )}
          </InputMask>
        )}
      {error?.[name] && <ErrorText>{errorMsg}</ErrorText>}
      {
        validatorPassword && validatorPassword.type !== undefined
        && <PasswordWeak style={validatorPassword.style}>{validatorPassword.type}</PasswordWeak>
      }
    </div>
  </Container>
);

export default Input;
