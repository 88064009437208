import React from 'react';
import getNameInitials from 'src/utils/getNameInitials';
import Employee from 'src/contracts/models/Employee';
import {
  ProfessionalWithoutProfileImage,
} from './style';

export interface UserPictureProps {
  user: Employee
}

const UserPicture = ({ user }: UserPictureProps) => {
  if (!user.photo) {
    return (
      <ProfessionalWithoutProfileImage>
        {getNameInitials(user.name)}
      </ProfessionalWithoutProfileImage>
    );
  }

  return <img src={user.photo} alt={user.name} />;
};

export default UserPicture;
