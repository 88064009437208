import React, { FC } from 'react';
import {
  Description, Steps, Title,
} from './style';

interface props {
  translate: Function
  currentStep?: number,
  finishStep?: number,
}
export const GuideTeamAndPosition:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('Guidance.Calendar.team-position-selector.title')}</Title>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const GuideTeamSelectDate:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('Guidance.Calendar.select-date.title')}</Title>
    <Description>
      {translate('Guidance.Calendar.select-date.description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const GuideUsersSelect:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Title>{translate('Guidance.Calendar.select-user.title')}</Title>
    <Description>
      {translate('Guidance.Calendar.select-user.description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const GuideEmptyCalendar:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Description>
      {translate('Guidance.Calendar.select-empty-day.description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);

export const GuideOpenMapButton:FC<props> = ({ translate, currentStep, finishStep }) => (
  <div className="p-2">
    <Description>
      {translate('Guidance.Calendar.open-map-buttom.description')}
    </Description>
    <Steps>
      <span>
        Passo
        {' '}
        {currentStep}
        {' '}
        de
        {' '}
        {finishStep}
      </span>
    </Steps>
  </div>
);
