import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Option from 'src/contracts/models/Option';
import { Component, Selector } from './style';
import OpitionEmployee from './OptionEmployee';

interface EmployeeSelectorProps {
  onChange(team: Option[], event: ChangeEvent): void | Promise<void>;
  onInputChange?: Function;
  options: Option[];
  isLoading?: boolean;
  value: any;
}

const EmployeeSelector: React.FC<EmployeeSelectorProps> = ({
  onChange,
  onInputChange,
  options,
  isLoading,
  value,
}) => {
  const { t } = useTranslation('components');

  return (
    <Component>
      <span>{t('EmployeeSelector.employee')}</span>
      <Selector
        isMulti
        noOptionsMessage={() => <span>{t('EmployeeSelector.no_options_message')}</span>}
        placeholder={t('EmployeeSelector.placeholder')}
        value={value}
        closeMenuOnSelect={false}
        selectedOptions={value}
        formatOptionLabel={OpitionEmployee}
        options={options}
        onChange={onChange}
        onInputChange={onInputChange}
        styles={{
          control: (base :any) => ({
            ...base,
            position: 'relative',
            maxHeight: '110px',
            overflowY: 'scroll',
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            borderRadius: ' 4px',
          }),
        }}
        isLoading={isLoading}
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </Component>
  );
};

export default EmployeeSelector;
