import React from 'react';
import Team from 'src/contracts/models/Team';
import { useTranslation } from 'react-i18next';
import ButtonLoader from 'src/components/ButtonLoader';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import {
  Title, Message, IconModal, ContainerButtons,
} from './style';
import icons from '../../assets/icons';
import colors from '../../colors';

interface ModalTeamProps {
  isOpen: boolean;
  team?: Team | null;
  message?: string;
  messageConfirm?: string;
  title?: string;
  isLoading: boolean;
  onDelete(): Promise<void>;
  handleCloseButton: () => void;
}
const ModalTeamDelete: React.FC<ModalTeamProps> = ({
  isOpen,
  message,
  messageConfirm,
  title,
  onDelete,
  handleCloseButton,
  isLoading,
}) => {
  const { t } = useTranslation('components');
  return (
    <Modal
      isOpen={isOpen}
      onClosed={handleCloseButton}
      style={{
        width: 500,
        borderRadius: '4px',
      }}
      centered
    >
      <ModalBody
        style={{
          borderTop: `6px solid ${colors.orange}`,
          borderWidth: '100%',
          borderRadius: '3px',
          paddingTop: '32px',
        }}
      >
        <IconModal src={icons.Error} alt="" />
        <Title>{title || t('ModalConfirm.default-confirm-action')}</Title>
        <Message>{message || ''}</Message>
        {messageConfirm && <Message className="mt-4">{messageConfirm}</Message>}
      </ModalBody>
      <ModalFooter style={{ border: 'none', paddingBottom: '32px' }}>
        <ContainerButtons>
          <ButtonLoader
            label={t('ModalConfirm.cancel')}
            bordered
            transparent
            onClick={handleCloseButton}
            withHover={false}
          />
          <ButtonLoader
            label={t('ModalConfirm.confirm')}
            isLoading={isLoading}
            onClick={onDelete}
          />
        </ContainerButtons>
      </ModalFooter>
    </Modal>
  );
};
export default ModalTeamDelete;
