import styled, { css } from 'styled-components';
import colors from '../../colors';

interface Props {
    align?: string;
    left?: boolean;
    right?: boolean;
    top?: boolean;
    bottom?: boolean;
    hasError?: string;
    disabled?: boolean;
}
export const Container = styled.div`
    position: relative;

    &:hover > .select {
        display: block;
    }
    > .select {
        display: none;
    }

    ${(p) => p['aria-disabled'] && css`
        img{
            cursor: default;
        }
    `}
`;

export const Row = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;

    
`;

export const Body = styled.div<Props>`
    width: 83px;
    height: 43px;
    border: 1px solid ${colors.white13};
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-color: ${(props) => props.hasError && `${colors.red}`};
    box-shadow: ${(props) => props.hasError && `0 0 4px ${colors.red}`};
    background-color: ${(props) => (props.disabled ? `${colors.white10}` : `${colors.white}`)};
`;

export const Input = styled.input<Props>`
    width: 90%;
    height: 90%;
    border: none;
    color: ${colors.blue80};
    font: normal normal medium 15px/18px lato;
    text-align: ${(props) => props.align};
    background-color: ${(props) => (props.disabled ? `${colors.white10}` : `${colors.white}`)};
    :disabled{
        opacity: 1;
    }
`;

export const Label = styled.span<Props>`
    font: normal normal medium 15px/18px lato;
    color: ${colors.blue80};
    margin-right: ${(props) => (props.left ? '10px' : '0')};
    margin-left: ${(props) => (props.right ? '10px' : '0')};
    white-space: nowrap;
`;

export const ButtonsContainer = styled.div<Props>`
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 43px;
    margin-left: 9px;
    width: ${(props) => props.disabled && '20px'};
`;

export const Button = styled.img<Props>`
    display: flex;
    flex: 1;
    height: auto;
    width: 20px;
    cursor: pointer;
    transform: ${(props) => props.top && 'rotate(180deg)'};
    filter: invert(49%) sepia(7%) saturate(778%) hue-rotate(190deg) brightness(94%) contrast(90%);
`;

export const ErrorMessage = styled.div`
  margin-top: 11px;
  color: ${colors.red};
`;

export const SelectContainer = styled.div`
    z-index: 99;
    position: absolute;
    top: 100%;
    width: 100%;
    /* height: 100%; */
    background-color: ${colors.white};
    max-height: 150px;
    overflow-x: hidden;
    overflow-y: scroll;
    box-shadow: 0 0 5px ${colors.dark90};
`;

export const SelectButton = styled.button`
    width: 100%;
    background-color: ${colors.white};
    border: none;
    padding:10px;
    :hover{
        background-color: ${colors.white5};
    }
`;
