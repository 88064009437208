import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ConfirmButton, RefuseButton } from './style';
import { deleteData } from '../../services/LocalStorageService';

interface ConfirmExitModalInterface{
    state: boolean,
    setState: Function,
    route: string,
}

function ConfirmExitModal({ state, setState, route }: ConfirmExitModalInterface) {
  const toggle = () => setState(!state);
  const history = useHistory();
  const { t } = useTranslation('components');
  const exit = () => {
    deleteData('location');
    history.replace(route);
  };
  return (
    <Modal isOpen={state} toggle={toggle} style={{ width: 400 }}>
      <ModalHeader toggle={toggle} style={{ border: 'none', height: 45 }}>{t('ExitModal.sure')}</ModalHeader>
      <ModalBody style={{ border: 'none', height: 40 }}>
        {t('ExitModal.message')}
      </ModalBody>
      <ModalFooter style={{ border: 'none' }}>
        <RefuseButton onClick={toggle}>{t('ExitModal.cancel')}</RefuseButton>
        <ConfirmButton onClick={() => exit()}>{t('ExitModal.confirm')}</ConfirmButton>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmExitModal;
