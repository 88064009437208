import styled from 'styled-components';
import colors from 'src/colors';

export const ProfessionalDetail = styled.div`
  > img {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    margin-right: 1rem;
    object-fit: cover;
  }
  span {
    background-color: ${colors.white5};
  }
  p {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
