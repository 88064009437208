import styled from 'styled-components';
import Select from 'react-select';

export const Component = styled.div`
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    background: transparent;
    border-radius: 150px;
    width: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondary-gray);
    border-radius: 150px;
  }
`;

export const Label = styled.span`
    font: normal normal medium 5px/5px lato;
    font-size: 13px !important;
    color: #727789;
    margin-bottom: 5px;
`;

export const Selector = styled(Select)`
  >div{
    
   
    
    border-color: #CCCCCC !important; 
    :nth-child(2){
      box-shadow: none;
    }

    > div{
      > div{
        span {
            height: 5px;
            width: 5px;
        }
        > div{
          padding-top: 2px;
          div{
            &:nth-child(1){
              margin-top: -20px;
            }
          }
        }
      }
    }
  }


`;

export const GroupHeader = styled.div`
  display: flex;
  >span{
    font: normal normal bold 13px/16px lato;
    letter-spacing: 0.23px;
    color: var(--primary);
    text-transform: uppercase;
  }
`;
