import styled, { css } from 'styled-components';

const animationFade = css`
    @-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    -webkit-animation-duration: .5s;
    animation-duration: .5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeIn;
     animation-name: fadeIn;
`;

export const Body = styled.div`
  width: 100%;
  height: 300px;
  padding-top: 20px;
  overflow-y: scroll;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 45px;
  width: 100%;
`;

export const DetailsContainer = styled.div`
  display: flex;
  position: absolute;
  width: 350px;
  top: 30px;
  left: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: #fff;
  border-radius: 12px;
  z-index: 1;
  padding: 10px 15px;
  -webkit-box-shadow: 0px 5px 15px 2px #A5A5A5; 
  box-shadow: 0px 5px 15px 2px #A5A5A5;
  ${animationFade}
`;

export const Title = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-family: lato;
  color: var(--primary-gray);
  text-align: left;
  font-size: 18px;
  text-transform: capitalize;
`;

export const Tag = styled.div<{localized?: boolean}>`
  background-color: ${(props) => (props.localized ? 'var(--primary)' : '#e78')};
  padding: 2px 4px;
  color: #fff;
  font-size: 12px;
  border-radius: 5px;
  margin-bottom: 5px;
`;

export const SubTitle = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  font-family: lato;
  color: var(--primary-gray);
  text-align: left;
  font-size: 14px;
`;

export const EmployeeItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--quaternary-gray);
  padding: 8px 0px;

  p {
    margin: 0;
  }

  span {
    margin: 0;
    color: var(--primary);
  }
`;

export const EmployeeInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const EmployeePhoto = styled.img`
    width: 24px;
    height: 24px;
    border-radius: 18px;
    margin-right: 1rem;
    object-fit: cover;
`;

export const EmployeeWithoutProfileImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: brown;
  color: white;
  font-size: 0.8rem;
  margin-right: 1rem;
  text-transform: uppercase;
`;
