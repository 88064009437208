import React, { useState } from 'react';
import SeatModel from 'src/contracts/models/Seats';

export const ModalMapViewContext = React.createContext<{
  selectedSeat: SeatModel | null,
  setSelectedSeat?: React.Dispatch<React.SetStateAction<SeatModel | null>>;
    }>({
      selectedSeat: null,
    });
const MapViewProvider: React.FC = ({ children }) => {
  const [selectedSeat, setSelectedSeat] = useState<SeatModel | null>(null);

  return (
    <ModalMapViewContext.Provider
      value={{
        selectedSeat,
        setSelectedSeat,
      }}
    >
      {children}
    </ModalMapViewContext.Provider>
  );
};

export default MapViewProvider;
