import React from 'react';
import ButtonLoader from 'src/components/ButtonLoader';
import icons from 'src/assets/icons';

const MARGIN_RIGHT = 10;

export interface EditAndDeleteButtonsProps {
  isFilteringByInactiveRole: boolean,
  onEdit: () => void;
  onDelete: () => void;
  onRestore: () => void;
}

const EditAndDeleteButtons = ({
  isFilteringByInactiveRole, onEdit, onRestore, onDelete,
}: EditAndDeleteButtonsProps) => {
  if (!isFilteringByInactiveRole) {
    return (
      <>
        <ButtonLoader
          withHover={false}
          transparent
          icon={icons.EditBlueDark}
          onClick={onEdit}
          style={{ marginRight: MARGIN_RIGHT }}
        />
        <ButtonLoader
          withHover={false}
          transparent
          icon={icons.TrashRed}
          onClick={onDelete}
        />
      </>
    );
  }
  return (
    <ButtonLoader
      withHover={false}
      transparent
      icon={icons.Restore}
      onClick={onRestore}
    />
  );
};

export default EditAndDeleteButtons;
