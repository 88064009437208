import styled from 'styled-components';
import colors from '../../../../colors';

interface InputProps {
  maxwidth: boolean,
  strong: boolean,
}

interface Props {
  open: boolean;
}

interface CustomSpaceProps {
  size: number;
}

export const Screen = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  height: 100px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.white10};
  border: 1px solid ${colors.gray25};
  padding: 0 50px 0 95px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 50px 60px 0;
  height: 100%;
  width: 100%;
`;

export const Labels = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Exit = styled.h2`
  color: ${colors.gray85};
  font: normal normal 600 lato;
`;

export const Title = styled.span`
  color: var(--primary-gray);
  font: normal normal bold 20px/29px lato;
`;

export const Subtitle = styled.span`
  color: ${colors.blue};
  font: normal normal bold 13px/19px lato;
`;
export const FormLabel = styled.span`
  color: ${colors.gray};
  font: normal normal bold 17px lato;
  margin-bottom: 40px;
`;

export const Forms = styled.form`
  display: flex;
  flex-direction: column;
  width: 900px;
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
  margin-bottom: 15px;
`;

export const FormChild = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputLabel = styled.span`
  color: ${colors.gray90};
  font: normal normal 600 12px/19px lato;
  margin-bottom: 2px;
`;

export const Input = styled.input<InputProps>`
  max-width: ${(props) => (props.maxwidth ? '600px' : '421px')};
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
  height: 35px;
  color: ${colors.gray};
  text-align: left;
  font: normal normal ${(props) => (props.strong ? 'medium' : 'normal')}18px/22px lato;
  padding-left: 10px;
  border: 1px solid ${colors.dark90};
  border-radius: 4px;

  :invalid {
    box-shadow: 0 0 0.5em red;
    border-color: red;
  }
`;

export const AddressOptions = styled.div<Props>`
  display: ${(props) => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  min-height: 45px;
  max-height: 80px;
  width: 600px;
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
  z-index: 2;
  border: 1px solid ${colors.dark90};
  border-radius: 4px;
  background-color: ${colors.white};
  padding: 5px 10px 5px 10px;
  overflow: auto;

  &::-webkit-scrollbar {
    background: transparent;
    border-radius: 150px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${colors.gray90};
    border-radius: 150px;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 40px;
  width: 100%;

  :not(:last-child) {
    border-bottom: 1px solid ${colors.dark85};
  }
`;

export const GoogleContainer = styled.div`
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  height: 20px;
  width: 600px;
  @media only screen and (max-width: 768px) {
    width: 100% !important;
  }
  > img {
    height: 80%;
    width: auto;
  }
`;
export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  Button {
    &:nth-child(1) {
      text-transform: uppercase;

      span {
        font-size: 12px;
      }
    }
  }
`;

export const Cancel = styled.div`
  text-align: left;
  font: normal normal normal 14px lato;
  letter-spacing: 0px;
  color: ${colors.gray};
  opacity: 1;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;
export const CustomSpace = styled.div<CustomSpaceProps>`
  display: flex;
  min-height: ${(props) => `${props.size}px`};
  min-width: ${(props) => `${props.size}px`};
`;
